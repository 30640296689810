import { Box, Card, CardActionArea, Typography } from "@mui/material";
import React from "react";

const ButtonReports = ({ children }) => {
  return (
    <Box
      component={Card}
      borderRadius={2}
      width={"fit-content"}
      // marginRight="15px"
      data-aos={"fade-up"}
      minWidth={"150px"}
      maxHeight={"120px"}
      minHeight={"120px"}
      maxWidth={"150px"}
      sx={{ backgroundColor: (theme) => theme.palette.background.default }}
    >
      <CardActionArea sx={{height: "100%"}}>{children}</CardActionArea>
    </Box>
  );
};

export default ButtonReports;
