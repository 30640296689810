import React, { useEffect, useState, useContext } from 'react';
// Components/ui
import {
  Box,
  Typography,
  IconButton,
  Button,
  Fade,
  TextField,
  ButtonGroup,
} from '@mui/material';
// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Cancel, Save } from '@mui/icons-material';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

interface NotesSectionProps {
  description: string;
  noteId: string;
  handleDeleteNote: (noteId: string) => void;
}

const NotesSection = ({
  description,
  noteId,
  handleDeleteNote,
}: NotesSectionProps) => {
  const { updateNote } = useContext(conversationsContext);
  const conversationSelected = useSelector(selectConversationSelected);

  const [expand, setExpand] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(description);

  const handleChangeBoolean = (
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setState((state) => !state);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleUpdateNote = () => {
    const data = {
      contactId: conversationSelected.contactInfo.id,
      noteId,
      text: value,
    };
    updateNote(data);
    setIsEdit(false);
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    setValue(description);
  };

  useEffect(() => {
    if (description) {
      setValue(description);
    }
  }, [description]);

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          p: 0.1,
        }}
      >
        {isEdit ? (
          <TextField
            fullWidth
            multiline
            maxRows={4}
            onChange={handleChange}
            value={value}
            size="small"
          />
        ) : (
          <Button
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#2d3748' : '#e2e8f0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              // height: expand ? '7rem' : '2.5rem',
              width: '-webkit-fill-available',
              transition: 'height 0.5s ease-in-out',
              height: 'auto'
            }}
            onClick={() => handleChangeBoolean(setExpand)}
          >
            <Typography
              sx={{
                userSelect: 'none',
                fontSize: 14,
                color: (theme) =>
                  theme.palette.mode === 'dark' ? '#e2e8f0' : '#475569',
                overflowY: 'auto',
                maxHeight: '100%',
                textTransform: 'none',
                textAlign: 'justify',
                whiteSpace: 'break-spaces'
              }}
              variant="body1"
              noWrap={!expand}
            >
              {value}
            </Typography>
          </Button>
        )}
        <Box display={'flex'} gap={0.2}>
          {isEdit ? (
            <ButtonGroup>
              <IconButton
                size="small"
                onClick={handleUpdateNote}
                disabled={value === description}
              >
                <Save
                  sx={{
                    fontSize: 19,
                    color: value !== description ? 'primary.main' : 'text.disabled',
                  }}
                />
              </IconButton>
              <IconButton size="small" onClick={handleCancelEdit}>
                <Cancel
                  sx={{
                    fontSize: 19,
                    color: 'text.disabled',
                  }}
                />
              </IconButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <IconButton
                size="small"
                onClick={() => handleChangeBoolean(setIsEdit)}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{
                    fontSize: 19,
                    color: 'primary.main',
                  }}
                />
              </IconButton>
              <IconButton size="small" onClick={() => handleDeleteNote(noteId)}>
                <DeleteOutlineOutlinedIcon
                  color="error"
                  sx={{
                    fontSize: 19,
                  }}
                />
              </IconButton>
            </ButtonGroup>
          )}
        </Box>
      </Box>
    </Fade>
  );
};

export default NotesSection;
