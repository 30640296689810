import React from 'react';
// Components/ui
import {
  FormControlLabel,
  Stack,
  Typography,
  Switch,
} from '@mui/material';

interface Props {
  label: string;
  checked: boolean;
  onChange: () => void;
}

const ChannelNamedOptionSwitch = ({
  label,
  checked,
  onChange,
}: Props) => {
  return (
    <FormControlLabel
      control={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontSize={14}>No</Typography>
          <Switch onChange={onChange} checked={checked} />
          <Typography fontSize={14}>Si</Typography>
        </Stack>
      }
      label={label}
      labelPlacement="start"
      sx={{
        width: '55%',
        justifyContent: 'space-between',
      }}
    />
  );
};

export default ChannelNamedOptionSwitch;
