import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectEmailsFetchStatus,
  selectAllEmails,
} from 'redux/features/emailSlice/emailSlice';
// Components/ui
import { Box } from '@mui/material';
import { EmailItem } from './components';
import { ListItemSkeleton } from 'components';

const ListBody = () => {
  const allEmails = useSelector(selectAllEmails);
  const emailsFetchStatus: RequestStatus = useSelector(selectEmailsFetchStatus);

  const Emails = allEmails.map((email) => <EmailItem key={email.id} data={email} />);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {emailsFetchStatus === 'succeeded' ? Emails : <ListItemSkeleton />}
    </Box>
  );
};

export default ListBody;
