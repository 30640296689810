import { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Interface
import { NodeConditionalOperator } from '@trii/types/dist/Conversations/Flows/Nodes';
// Utils
import operatorList from './utils';

const Condition = () => {
  const { conditionalNode, setConditionalNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [operator, setOperator] = useState<NodeConditionalOperator>(
    NodeConditionalOperator.NULL
  );
  const [firstValue, setFirstValue] = useState<string>('');
  const [secondValue, setSecondValue] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.conditional) {
      const conditional = selectNode.data.conditional;
      const defaultOperator =
        conditional.operator && (conditional.operator as NodeConditionalOperator);
      const defaultFirstValue = conditional.value1 ? conditional.value1 : '';
      const defaultSecondValue = conditional.value2 ? conditional.value2 : '';
      setFirstValue(defaultFirstValue);
      setSecondValue(defaultSecondValue);
      setOperator(defaultOperator);
    }
  }, [selectNode]);

  const handleChangeOperator = (operator: NodeConditionalOperator) => {
    setOperator(operator);
    setConditionalNode({ ...conditionalNode, operator });
  };

  const handleChangeFirstValue = (value1: string) => {
    setFirstValue(value1);
    setConditionalNode({ ...conditionalNode, value1 });
  };

  const handleChangeSecondValue = (value2: string) => {
    setSecondValue(value2);
    setConditionalNode({ ...conditionalNode, value2 });
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={firstValue}
        label={t("editFlowsView.conditional.firstValue")}
        onChange={(e) => handleChangeFirstValue(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ marginBottom: "5px" }}
      />
      <FormControl fullWidth size="small" sx={{ marginBottom: "5px" }}>
        <InputLabel id="operator" shrink={true}>
          {t("editFlowsView.conditional.operator.title")}
        </InputLabel>
        <Select
          labelId="operator"
          id="demo-simple-select"
          value={operator}
          label={t("editFlowsView.conditional.operator.title")}
          onChange={(e) =>
            handleChangeOperator(e.target.value as NodeConditionalOperator)
          }
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {operatorList &&
            operatorList?.map((operator) => (
              <MenuItem key={operator.id} value={operator.id}>
                {t(operator.label)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={secondValue}
        label={t("editFlowsView.conditional.secondValue")}
        onChange={(e) => handleChangeSecondValue(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default Condition;
