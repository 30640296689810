import React from 'react';
// Types
import { IChannel } from '@trii/types/dist/Common/Channels';
// Redux
import { useSelector } from 'react-redux';
import {
  selectInstagramsFetchStatus,
  selectAllInstagrams,
  selectNewInstagrams,
  selectInstagramCreateStatus,
} from 'redux/features/instagramSlice/instagramSlice';
// Components/ui
import { Box, Skeleton } from '@mui/material';
import { ListItemSkeleton } from 'components';
import InstagramItem from './components/InstagramItem/InstagramItem';

const ListBody = () => {
  const [instagramList, setInstagramList] = React.useState<IChannel[]>([]);
  const allInstagrams = useSelector(selectAllInstagrams);
  const instagramsFetchStatus = useSelector(selectInstagramsFetchStatus);
  const isLoading = instagramsFetchStatus === 'loading';
  const newInstagrams = useSelector(selectNewInstagrams);
  const instagramCreateStatus = useSelector(selectInstagramCreateStatus);
  const isCreating = instagramCreateStatus === 'loading';

  React.useEffect(() => {
    if (allInstagrams) {
      setInstagramList(allInstagrams);
    }
  }, [allInstagrams]);

  React.useEffect(() => {
    if (newInstagrams) {
      setInstagramList([...instagramList, ...newInstagrams]);
    }
  }, [newInstagrams]);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {
        isLoading ? (
          <ListItemSkeleton />
        ) : (
          instagramList.length > 0 && instagramList.map((instagram) => (
            <InstagramItem key={instagram.id} data={instagram} />
          ))
        )
      }
      {
        isCreating && <Skeleton
          variant={'rounded'}
          width={'100%'}
          height={37}
        />
      }
    </Box>
  );
};

export default ListBody;
