import Box from '@mui/material/Box';
// import { useTranslation } from 'react-i18next';
import PublicationChat from './PublicationChat';
import PublicationSidePanel from './PublicationSidePanel';
import { Card } from '@mui/material';

export default function PublicationContent() {
  return (
    <Box
      component={Card}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor='background.default'
      borderRadius="0px"
      height="calc(100% - 3rem)"
    >
      <Box
        display="flex"
        height={"100%"}
        width="100%"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <PublicationChat />
        <PublicationSidePanel />
      </Box>
    </Box>
  );
}
