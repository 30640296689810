import React, { useContext, useEffect } from "react";
import { Card, Metric, Title } from "@tremor/react";
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const AgentsCard = ({quantityData}) => {
  const { title, value } = quantityData;

  const {
    setModalRegisterTitle,
    setModalRegisterOpen,
    obtainRegister
  } = useContext(DashboardContext)
  const handleOpenModalRegister = () => {
    if (quantityData.type) {
      obtainRegister(quantityData.type)
      setModalRegisterTitle(quantityData.type);
      setModalRegisterOpen(true);
    }
  }
  const theme = useTheme();
  const cardStyle = {
    //@ts-ignore
    backgroundColor: theme.palette.background.panel,
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
  };
  const {t} = useTranslation()
  return (
    <Card className="p-2" style={cardStyle}>
      <Box className="truncate" style={{ color: theme.palette.text.primary }}>
        <Title className="text-center">
          {/* {quantityData?.type == 0 ? t("dashboard.contacts") : quantityData?.type == 1 ? t("dashboard.finished") : quantityData?.type == 2 ? t("dashboard.onlineAgents") : quantityData?.type == 3 ? t("dashboard.agentsGroups") : null } */}
          {title}
        </Title>
        <Metric
          className="text-center cursor-pointer text-2xl"
          onClick={handleOpenModalRegister}
        >
          {/* {quantityData?.quantity} */}
          {value}
        </Metric>
      </Box>
    </Card>
  );
};

export default AgentsCard;
