import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { RequestStatus } from 'redux/types/RequestStatus';
import { UpdateChannelThunk } from '../../redux/types/UpdateChannelThunk';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { IconButton, Box, Typography, Button, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  channelFetchStatus: RequestStatus;
  channelName: string;
  channelUpdateStatus: RequestStatus;
  updateChannel: UpdateChannelThunk;
  children: React.ReactNode;
}

const Header = ({
  channelFetchStatus,
  channelName,
  channelUpdateStatus,
  updateChannel,
  children,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const isLoading = channelUpdateStatus === 'loading';
  const isDisabled = channelFetchStatus === 'loading' || isLoading;
  const Title =
    channelFetchStatus === 'loading' ? (
      <Skeleton variant="rounded" width={110} height={20} />
    ) : (
      <Typography color={'text.primary'}>{channelName}</Typography>
    );

  const handleSave = () => {
    dispatch(updateChannel());
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      bgcolor={'dropdownBox'}
      px={2}
      py={1.5}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <IconButton size="small" onClick={navigateBack}>
          <ArrowBackIcon
            sx={{
              fontSize: 20,
              color: 'text.primary',
            }}
          />
        </IconButton>
        <Box display={'flex'} gap={0.5} alignItems={'center'}>
          {children}
          {Title}
        </Box>
      </Box>
      <Box display={'flex'} gap={1}>
        <Button variant="outlined" size="small" onClick={navigateBack} sx={{
          color:"text.primary" 
        }}>
          {t('global.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          loadingPosition="start"
          loading={isLoading}
          startIcon={<SaveAsIcon />}
          disabled={isDisabled}
          onClick={handleSave}
        >
          {t('global.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Header;
