export const style = {
  container: {
    fontSize: '13px',
    wordBreak: 'break-word',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};
