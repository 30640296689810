import { useContext } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectAllEndings,
  selectEndingsFetchStatus,
} from 'redux/features/endingSlice/endingSlice';
import { finalizeConversation } from 'redux/features/conversationsSlice/conversationsSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// Icons
import { Bookmark } from '@mui/icons-material';
import { style } from '../../style';

const EndConversations = () => {
  const dispatch = useAppDispatch();

  const { conversationId, setConversationId, setOpenModalList } =
    useContext(conversationsContext);

  const endings = useSelector(selectAllEndings);
  const endingsFetchStatus = useSelector(selectEndingsFetchStatus);

  const isLoading = endingsFetchStatus === 'loading';

  const handleSelectLabel = (endId?: string) => {
    dispatch(
      finalizeConversation({
        conversationId,
        endId: endId || null,
      })
    );

    setOpenModalList(false);
    setConversationId('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        overflow: 'auto',
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        endings?.length > 0 &&
        endings.map((ending, i) => (
          <ListItem
            key={ending.id}
            onClick={() => handleSelectLabel(ending.id)}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <ListItemIcon>
                <Bookmark />
              </ListItemIcon>
              <ListItemText primary={ending.name} sx={style.nameFinal} />
            </ListItemButton>
          </ListItem>
        ))
      )}
      <ListItem
        onClick={() => handleSelectLabel()}
        sx={{
          padding: 0,
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <Bookmark />
          </ListItemIcon>
          <ListItemText
            primary={'No asignar final a la conversacion'}
            sx={style.nameFinal}
          />
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default EndConversations;
