import { useState, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import {
  fetchGroups,
  selectConversationsFetchStatus,
  selectGroups,
  selectGroupsFetchStatus,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { fetchUsers, selectUsers } from 'redux/features/userSlice/userSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  Box,
} from '@mui/material';
// Icons
import { Group, Send } from '@mui/icons-material';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { IGroup } from '@trii/types/dist/Conversations/Groups/Group';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { StatusUserAvatar } from './components';
import { styled } from '@mui/system';

const USER_TAB = 'userId';
const GROUP_TAB = 'groupId';

type UserGroupSelectorProps = {
  onAccept: (user: UserInfo | null, group: IGroup | null) => void;
  handleCloseModal: () => void;
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow: auto;
`;

const Header = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
`;

const SelectedInfo = styled(Box)`
  display: flex;
  width: 80%;
  padding: 1em 1.5em;
  justify-content: space-between;
  align-content: center;
`;

const LoadingBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1em;
`;

const UserGroupSelector = ({
  onAccept,
  handleCloseModal,
}: UserGroupSelectorProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string>(GROUP_TAB);
  const [listToRender, setListToRender] = useState<UserInfo[] | IGroup[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserInfo>(null);
  const [selectedGroup, setSelectedGroup] = useState<IGroup>(null);

  const users = useSelector(selectUsers);
  const usersStatus = useSelector(selectConversationsFetchStatus);
  const groups = useSelector(selectGroups);
  const groupsStatus = useSelector(selectGroupsFetchStatus);

  const isLoadingUsers = usersStatus === 'loading' && value === USER_TAB;
  const isLoadingGroups = groupsStatus === 'loading' && value === GROUP_TAB;

  const handleChange = (tab: string) => {
    setValue(tab);
  };

  const getGroups = async () => {
    await dispatch(fetchGroups());
  };

  const getUsers = async () => {
    await dispatch(fetchUsers());
  };

  const handleSelect = (data: UserInfo | IGroup) => {
    if (value === USER_TAB) {
      setSelectedUser(data as UserInfo);
    } else {
      setSelectedGroup(data as IGroup);
      setSelectedUser(null);
      setValue(USER_TAB);
    }
  };

  const handleSend = async () => {
    await onAccept(selectedUser, selectedGroup);
    handleCloseModal();
  };

  useEffect(() => {
    if (value === USER_TAB) {
      if (selectedGroup) {
        const usersInGroup = users.filter((user) => {
          return selectedGroup.users.some((u) => u.id === user.id);
        });

        setListToRender(usersInGroup);
      } else {
        setListToRender(users);
      }
    } else {
      setListToRender(groups);
    }
  }, [value, users, groups]);

  useEffect(() => {
    getUsers();
    getGroups();
  }, []);

  return (
    <Container>
      <Header>
        <SelectedInfo>
          {selectedGroup && (
            <Typography fontWeight={'bold'} variant="body2">
              {selectedGroup?.name}
            </Typography>
          )}
          {selectedUser && (
            <Typography fontWeight={'bold'} variant="body2">
              {selectedUser?.name}
            </Typography>
          )}
        </SelectedInfo>
        <IconButton
          onClick={handleSend}
          disabled={!selectedGroup && !selectedUser}
          color="primary"
        >
          <Send />
        </IconButton>
      </Header>
      <Tabs
        value={value}
        onChange={(e, tab) => handleChange(tab)}
        aria-label="basic tabs example"
        variant="fullWidth"
      >
        <Tab label={t('settingsView.groups.groups')} value={GROUP_TAB} />
        <Tab label={t('global.users')} value={USER_TAB} />
      </Tabs>
      {isLoadingUsers || isLoadingGroups ? (
        <LoadingBox>
          <CircularProgress color="primary" size={30} />
        </LoadingBox>
      ) : (
        listToRender &&
        listToRender.length > 0 &&
        listToRender.map((data: UserInfo | IGroup, i: number) => (
          <ListItem
            key={data?.id}
            onClick={() => handleSelect(data)}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              sx={{
                borderBottom: (theme) =>
                  i === listToRender.length - 1
                    ? 'none'
                    : `1px solid ${theme.palette.divider}`,
              }}
            >
              {value === USER_TAB ? (
                <StatusUserAvatar data={data as UserInfo} />
              ) : (
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
              )}
              <ListItemText
                primary={data?.name || ''}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))
      )}
    </Container>
  );
};

export default UserGroupSelector;
