import React, { useEffect } from 'react';
import GoogleBusinessProvider from './context/GoogleBusinessProvider';
// Redux
import { useDispatch } from 'react-redux';
import { fetchGoogleBusiness } from 'redux/features/googleBusinessSlice/googleBusinessSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, CrudPanel } from './components';

const GoogleBusinessContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchGoogleBusiness());
  }, [dispatch]);

  return (
    <GoogleBusinessProvider>
      <Box display={'flex'}>
        <AppPanel />
        <CrudPanel />
      </Box>
    </GoogleBusinessProvider>
  );
};

export default GoogleBusinessContainer;
