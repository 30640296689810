import { useState } from "react"
// Context
import { QuickResponseContext } from "./QuickResponseContext"
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Slice
import {
  // Quick Response
  fetchQuickResponses,
  fetchQuickResponseById,
  fetchCreateQuickResponse,
  fetchUpdateQuickResponse,
  fetchDeleteQuickResponse,
  // Category
  fetchCategories,
  fetchCategoryById,
  fetchCreateCategory,
  fetchUpdateCategory,
  fetchDeleteCategory,
} from 'redux/features/quickResponseSlice/quickResponseSlice';
// Group
import { fetchGroups } from "redux/features/conversationsSlice/conversationsSlice";
// Types
import { EditionType } from "./types/EditionType";
import { CreateCategory } from "redux/features/quickResponseSlice/types/CreateCategory";
import { IQuickResponse, IQuickResponseCategory } from "@trii/types/dist/Conversations";
import { CreateQuickResponse } from "redux/features/quickResponseSlice/types/CreateQuickResponse";

export const QuickResponseProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  // General State
  const [editionType, setEditionType] = useState<EditionType>(null)
  // QuickResponse State
  const [quickResponseList, setQuickResponseList] = useState([])
  const [quickResponse, setQuickResponse] = useState(null)
  const [quickResponseSelected, setQuickResponseSelected] = useState<IQuickResponse>(null)
  // Category State
  const [categoryList, setCategoryList] = useState([])
  const [category, setCategory] = useState(null)
  const [categorySelected, setCategorySelected] = useState<IQuickResponseCategory>(null)
  // Group State
  const [groupList, setGroupList] = useState([])

  // Quick Response Actions
  const getQuickResponses = async () => {
    await dispatch(fetchQuickResponses());
  }

  const getQuickResponseById = async (id: string) => {
    await dispatch(fetchQuickResponseById(id));
  }

  const setNewQuickResponse = async (data: CreateQuickResponse): Promise<IQuickResponse> => {
    const response = await dispatch(fetchCreateQuickResponse(data));
    return response.payload;
  }

  const updateQuickResponse = async (data: IQuickResponse) => {
    await dispatch(fetchUpdateQuickResponse(data));
  }

  const deleteQuickResponse = async (id: string) => {
    await dispatch(fetchDeleteQuickResponse(id));
  }

  // Category Actions
  const getCategories = async () => {
    await dispatch(fetchCategories());
  }

  const getCategoryById = async (id: string) => {
    await dispatch(fetchCategoryById(id));
  }

  const setNewCategory = async (data: CreateCategory): Promise<IQuickResponseCategory> => {
    const response = await dispatch(fetchCreateCategory(data));
    return response.payload;
  }

  const updateCategory = async (data: IQuickResponseCategory) => {
    await dispatch(fetchUpdateCategory(data));
  }

  const deleteCategory = async (id: string) => {
    await dispatch(fetchDeleteCategory(id));
  }

  // Group Actions
  const getGroups = async () => {
    await dispatch(fetchGroups());
  }

  return (
    <QuickResponseContext.Provider
      value={{
        // General State
        editionType,
        setEditionType,
        // Quick Response State
        quickResponseList,
        setQuickResponseList,
        quickResponse,
        setQuickResponse,
        quickResponseSelected,
        setQuickResponseSelected,
        // Category State
        categoryList,
        setCategoryList,
        category,
        setCategory,
        categorySelected,
        setCategorySelected,
        // Group State
        groupList,
        setGroupList,
        // Quick Response Actions
        getQuickResponses,
        getQuickResponseById,
        setNewQuickResponse,
        updateQuickResponse,
        deleteQuickResponse,
        // Category Actions
        getCategories,
        getCategoryById,
        setNewCategory,
        updateCategory,
        deleteCategory,
        // Group Actions
        getGroups,
      }}
    >
      {children}
    </QuickResponseContext.Provider>
  )
}