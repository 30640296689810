import { createContext, useState, useRef, useEffect } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Slice
import {
  editFlows,
  selectFlow,
} from '../../../../redux/features/editFlowSlice/editFlowSlice';
// Types
import { EditFlowsContext, EditFlowsProviderProps } from './types';
// Interfaces
import {
  IFlow,
  Node as INode,
  Edge as IEdge,
  NodeData,
} from '@trii/types/dist/Conversations/Flows';
import {
  NodeStart,
  NodeMsgText,
  NodeMsgTextMode,
  NodeMsgTextMessageType,
  NodeMsgWaitResponse,
  NodeMsgWaitResponseTimeType,
  NodeSqlQuery,
  SqlQueryDbType,
  NodeSendEmail,
  NodeConversationAssignTo,
  NodeFillForm,
  NodeRedirect,
  NodeFillTicket,
  NodeFillTicketPriority,
  NodeContactSearch,
  NodeContactUpdate,
  NodeContactUpdateAction,
  NodeConversationUpdate,
  NodeContactUpdateConverFields,
  NodeConversationUpdateAction,
  NodeApi,
  NodeTimeScheduleControl,
  NodeTimeTimer,
  NodeTimeWaitUntil,
  NodeConditional,
  NodeConditionalOperator,
  NodeConversationFinalize,
} from '@trii/types/dist/Conversations/Flows/Nodes';
import { Method, AuthType } from '@trii/types/dist/Common/API';
// Components
import {
  useNodesState,
  useEdgesState,
  ReactFlowInstance,
  Node,
  Edge,
} from 'reactflow';
import { fetchScheduleById } from 'redux/features/scheduleSlice/scheduleSlice';
// Hooks
import useField from 'hooks/useField';
import { fetchSms } from 'redux/features/smsSlice/smsSlice';
import { fetchFacebooks } from 'redux/features/facebookSlice/facebookSlice';
import { fetchWebChats } from 'redux/features/webChatSlice/webChatSlice';
import { fetchWhatsApps } from 'redux/features/whatsAppSlice/whatsAppSlice';
import { fetchInstagrams } from 'redux/features/instagramSlice/instagramSlice';

export const InitEditFlowsContext = createContext<EditFlowsContext>({
  fetchChannelList: () => {},
  showService: false,
  setShowService: () => {},
  nodes: [],
  setNodes: () => {},
  edges: [],
  setEdges: () => {},
  reactFlowWrapper: null,
  reactFlowInstance: null,
  setReactFlowInstance: () => {},
  editPanelOpen: false,
  setEditPanelOpen: () => {},
  selectNode: null,
  setSelectNode: () => {},
  startNode: null,
  setStartNode: () => {},
  textNode: null,
  setTextNode: () => {},
  sendEditFlows: () => {},
  resetEditFields: () => {},
  resetTextField: () => {},
  resetStartField: () => {},
  msgWaitResponseNode: null,
  setMsgWaitResponseNode: () => {},
  resetMsgWaitResponseField: () => {},
  sqlQueryNode: null,
  setSqlQueryNode: () => {},
  resetSqlQueryField: () => {},
  sendEmailNode: null,
  setSendEmailNode: () => {},
  resetSendEmailField: () => {},
  assignToNode: null,
  setAssignToNode: () => {},
  resetAssignToField: () => {},
  fillFormNode: null,
  setFillFormNode: () => {},
  resetFillFormField: () => {},
  redirectNode: null,
  setRedirectNode: () => {},
  resetRedirectField: () => {},
  fillTicketNode: null,
  setFillTicketNode: () => {},
  resetFillTicketField: () => {},
  contactSearchNode: null,
  setContactSearchNode: () => {},
  resetContactSearchField: () => {},
  contactUpdateNode: null,
  setContactUpdateNode: () => {},
  resetContactUpdateField: () => {},
  conversationUpdateNode: null,
  setConversationUpdateNode: () => {},
  resetConversationUpdateField: () => {},
  apiNode: null,
  setApiNode: () => {},
  resetApiField: () => {},
  timeScheduleControlNode: null,
  setTimeScheduleControlNode: () => {},
  resetTimeScheduleControlField: () => {},
  timeTimerNode: null,
  setTimeTimerNode: () => {},
  resetTimeTimerField: () => {},
  timeWaitUntilNode: null,
  setTimeWaitUntilNode: () => {},
  resetTimeWaitUntilField: () => {},
  conditionalNode: null,
  setConditionalNode: () => {},
  resetConditionalField: () => {},
  selectNodesId: null,
  setSelectNodesId: () => {},
  selectEdgesId: null,
  setSelectEdgesId: () => {},
  endNode: null,
  setEndNode: () => {},
  resetEndField: () => {},
  // Get Functions
  getScheduleById: (scheduleId: string) => {
    return new Promise(() => {});
  },
  flowName: null,
});

export const EditFlowsProvider = ({ children }: EditFlowsProviderProps) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const flow: IFlow = useSelector(selectFlow);
  // Edit state
  const id = Math.random().toString(36).substr(2, 9);
  const flowName = useField('text');
  const [showService, setShowService] = useState<boolean>(false);
  const [editPanelOpen, setEditPanelOpen] = useState<boolean>(false);
  const [selectNode, setSelectNode] = useState<Node<NodeData>>(null);
  const [selectNodesId, setSelectNodesId] = useState<string[]>(null);
  const [selectEdgesId, setSelectEdgesId] = useState<string[]>(null);
  const [nodes, setNodes] = useNodesState<Node<NodeData>[]>([]);
  const [edges, setEdges] = useEdgesState<Edge[]>([]);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>(null);
  const [startNode, setStartNode] = useState<NodeStart>({
    id: id,
    name: '',
    trigger: 0,
    filters: [],
    onlyOnePerContact: false,
    onlyOnePerContactAddress: false,
  });
  const [textNode, setTextNode] = useState<NodeMsgText>({
    id: id,
    name: '',
    enableStatics: false,
    channelId: '',
    mode: NodeMsgTextMode.MANUAL,
    template: {
      id: '',
      // @ts-ignore
      namespace: '',
    },
    message: {
      type: NodeMsgTextMessageType.TEXT,
      text: '',
      caption: '',
      filename: '',
      fileUrl: '',
      buttons: [],
    },
  });
  const [msgWaitResponseNode, setMsgWaitResponseNode] =
    useState<NodeMsgWaitResponse>({
      id: '',
      name: '',
      enableStatics: false,
      conditions: [],
      autoResponseOnNoConditionMatchMessage: '',
      autoResponseOnTimeOutMinutes: 0,
      autoResponseOnTimeOutMessage: '',
      continueAfterEnabled: false,
      continueAfterTime: 0,
      continueAfterTimeType: NodeMsgWaitResponseTimeType.MINUTES,
      saveAs: '',
    });
  const [sqlQueryNode, setSqlQueryNode] = useState<NodeSqlQuery>({
    id: '',
    name: '',
    enableStatics: false,
    dbType: SqlQueryDbType.NONE,
    connectionString: '',
    query: '',
    saveAs: '',
  });
  const [sendEmailNode, setSendEmailNode] = useState<NodeSendEmail>({
    id: '',
    name: '',
    enableStatics: false,
    channelId: '',
    to: '',
    subject: '',
    body: '',
  });
  const [assignToNode, setAssignToNode] = useState<NodeConversationAssignTo>({
    id: '',
    name: '',
    enableStatics: false,
    groupId: '',
  });
  const [fillFormNode, setFillFormNode] = useState<NodeFillForm>({
    id: '',
    name: '',
    enableStatics: false,
    formId: '',
    responses: [],
  });

  const [redirectNode, setRedirectNode] = useState<NodeRedirect>({
    id: '',
    name: '',
    flowId: '',
    nodeId: '',
    nodeName: '',
  });

  const [fillTicketNode, setFillTicketNode] = useState<NodeFillTicket>({
    id: '',
    name: '',
    enableStatics: false,
    ticketContactSearchValue: '',
    ticketContactSearchFieldId: '',
    ticketContactName: '',
    ticketGroupId: '',
    ticketStatusId: '',
    ticketCategoryId: '',
    ticketPriority: NodeFillTicketPriority.LOW,
    ticketSendEmailToContact: false,
    ticketDescription: '',
    ticketAssociateMessages: false,
    saveAs: '',
  });

  const [contactSearchNode, setContactSearchNode] = useState<NodeContactSearch>({
    id: '',
    name: '',
    enableStatics: false,
    contactFieldId: '',
    valueToSearch: '',
    saveAs: '',
  });

  const [contactUpdateNode, setContactUpdateNode] = useState<NodeContactUpdate>({
    id: '',
    name: '',
    enableStatics: false,
    contactFieldId: '',
    action: NodeContactUpdateAction.REPLACE,
    valueToSave: '',
  });

  const [conversationUpdateNode, setConversationUpdateNode] =
    useState<NodeConversationUpdate>({
      id: '',
      name: '',
      enableStatics: false,
      fieldId: NodeContactUpdateConverFields.TAG,
      action: NodeConversationUpdateAction.REPLACE,
      valueToSave: '',
    });
  const [apiNode, setApiNode] = useState<NodeApi>({
    id: '',
    name: '',
    enableStatics: false,
    apiUrl: '',
    apiMethod: Method.GET,
    apiAuthType: AuthType.NONE,
    apiAuthBasic: null,
    apiAuthApiKey: null,
    apiAuthBearer: null,
    apiBody: '',
    apiContentType: '',
  });

  const [timeScheduleControlNode, setTimeScheduleControlNode] =
    useState<NodeTimeScheduleControl>({
      id: '',
      name: '',
      enableStatics: false,
      scheduleId: '',
    });

  const [timeTimerNode, setTimeTimerNode] = useState<NodeTimeTimer>({
    id: '',
    name: '',
    enableStatics: false,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeWaitUntilNode, setTimeWaitUntilNode] = useState<NodeTimeWaitUntil>({
    id: '',
    name: '',
    enableStatics: false,
    scheduleId: '',
  });

  const [conditionalNode, setConditionalNode] = useState<NodeConditional>({
    id: '',
    name: '',
    enableStatics: false,
    value1: '',
    operator: NodeConditionalOperator.NULL,
    value2: '',
  });

  const [endNode, setEndNode] = useState<NodeConversationFinalize>({
    id: '',
    name: '',
  });

  useEffect(() => {
    if (flow) {
      const newNodes: Node[] = flow.nodes.map((node) => {
        return {
          ...node,
          type: node.type.toString(),
        };
      });
      setNodes(newNodes);
      setEdges(flow.edges);
      flowName.actions.changeValue(flow.name);
    }
  }, [flow]);

  // Edit functions
  const resetStartField = () =>
    // @ts-ignore
    setStartNode({
      id: '',
      name: '',
      trigger: 0,
      filters: [
        {
          id: '',
          field: '',
          condition: '',
          value: '',
        },
      ],
      onlyOnePerContact: false,
      onlyOnePerContactAddress: false,
    });
  const resetTextField = () =>
    setTextNode({
      id: '',
      name: '',
      enableStatics: false,
      channelId: '',
      mode: NodeMsgTextMode.MANUAL,
      template: {
        id: '',
        // @ts-ignore
        namespace: '',
      },
      message: {
        type: NodeMsgTextMessageType.TEXT,
        text: '',
        caption: '',
        filename: '',
        fileUrl: '',
        buttons: [],
      },
    });
  const resetMsgWaitResponseField = () =>
    setMsgWaitResponseNode({
      id: '',
      name: '',
      enableStatics: false,
      conditions: [],
      autoResponseOnNoConditionMatchMessage: '',
      autoResponseOnTimeOutMinutes: 0,
      autoResponseOnTimeOutMessage: '',
      continueAfterEnabled: false,
      continueAfterTime: 0,
      continueAfterTimeType: NodeMsgWaitResponseTimeType.MINUTES,
      saveAs: '',
    });
  const resetSqlQueryField = () =>
    setSqlQueryNode({
      id: '',
      name: '',
      enableStatics: false,
      dbType: SqlQueryDbType.NONE,
      connectionString: '',
      query: '',
      saveAs: '',
    });
  const resetSendEmailField = () =>
    setSendEmailNode({
      id: '',
      name: '',
      enableStatics: false,
      channelId: '',
      to: '',
      subject: '',
      body: '',
    });
  const resetAssignToField = () =>
    setAssignToNode({
      id: '',
      name: '',
      enableStatics: false,
      groupId: '',
    });
  const resetFillFormField = () =>
    setFillFormNode({
      id: '',
      name: '',
      enableStatics: false,
      formId: '',
      responses: [],
    });
  const resetRedirectField = () =>
    setRedirectNode({
      id: '',
      name: '',
      flowId: '',
      nodeId: '',
      nodeName: '',
    });
  const resetFillTicketField = () =>
    setFillTicketNode({
      id: '',
      name: '',
      enableStatics: false,
      ticketContactSearchValue: '',
      ticketContactSearchFieldId: '',
      ticketContactName: '',
      ticketGroupId: '',
      ticketStatusId: '',
      ticketCategoryId: '',
      ticketPriority: NodeFillTicketPriority.LOW,
      ticketSendEmailToContact: false,
      ticketDescription: '',
      ticketAssociateMessages: false,
      saveAs: '',
    });

  const resetContactSearchField = () =>
    setContactSearchNode({
      id: '',
      name: '',
      enableStatics: false,
      contactFieldId: '',
      valueToSearch: '',
      saveAs: '',
    });

  const resetContactUpdateField = () =>
    setContactUpdateNode({
      id: '',
      name: '',
      enableStatics: false,
      contactFieldId: '',
      action: NodeContactUpdateAction.REPLACE,
      valueToSave: '',
    });

  const resetConversationUpdateField = () =>
    setConversationUpdateNode({
      id: '',
      name: '',
      enableStatics: false,
      fieldId: NodeContactUpdateConverFields.TAG,
      action: NodeConversationUpdateAction.REPLACE,
      valueToSave: '',
    });

  const resetApiField = () =>
    setApiNode({
      id: '',
      name: '',
      enableStatics: false,
      apiUrl: '',
      apiMethod: Method.GET,
      apiAuthType: AuthType.NONE,
      apiAuthBasic: null,
      apiAuthApiKey: null,
      apiAuthBearer: null,
      apiBody: '',
      apiContentType: '',
    });
  const resetTimeScheduleControlField = () =>
    setTimeScheduleControlNode({
      id: '',
      name: '',
      enableStatics: false,
      scheduleId: '',
    });

  const resetTimeTimerField = () =>
    setTimeTimerNode({
      id: '',
      name: '',
      enableStatics: false,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

  const resetTimeWaitUntilField = () =>
    setTimeWaitUntilNode({
      id: '',
      name: '',
      enableStatics: false,
      scheduleId: '',
    });

  const resetConditionalField = () =>
    setConditionalNode({
      id: '',
      name: '',
      enableStatics: false,
      value1: '',
      operator: NodeConditionalOperator.NULL,
      value2: '',
    });

  const resetEndField = () =>
    setEndNode({
      id: '',
      name: '',
    });

  const resetEditFields = () => {
    setShowService(false);
    setEditPanelOpen(false);
    setSelectNode(null);
    setNodes([]);
    setEdges([]);
    setReactFlowInstance(null);
    resetStartField();
    resetTextField();
    resetMsgWaitResponseField();
    resetSqlQueryField();
    resetSendEmailField();
    resetAssignToField();
    resetFillFormField();
    resetRedirectField();
    resetFillTicketField();
    resetContactSearchField();
    resetContactUpdateField();
    resetApiField();
    resetTimeScheduleControlField();
    resetTimeTimerField();
    resetTimeWaitUntilField();
    resetConditionalField();
    resetEndField();
    flowName.actions.changeValue('');
  };

  // Get Functions
  const getScheduleById = async (scheduleId: string) => {
    const response = await dispatch(fetchScheduleById(scheduleId));
    return response.payload;
  };

  const sendEditFlows = async (id: string) => {
    // @ts-ignore
    const newNodes: INode[] = nodes.map((node) => {
      return { ...node, type: Number(node.type) };
    });
    const newName =
      flowName.attributes.value !== flow.name
        ? flowName.attributes.value
        : undefined;
    await dispatch(
      editFlows({
        id,
        nodes: newNodes,
        edges,
        ...(newName !== undefined && { name: newName }),
      })
    );
  };

  async function fetchChannelList() {
    await Promise.all([
      dispatch(fetchSms()),
      dispatch(fetchFacebooks()),
      dispatch(fetchWebChats()),
      dispatch(fetchWhatsApps()),
      dispatch(fetchInstagrams()),
    ]);
  }

  return (
    <InitEditFlowsContext.Provider
      value={{
        fetchChannelList,
        flowName,
        showService,
        setShowService,
        nodes,
        setNodes,
        edges,
        setEdges,
        reactFlowWrapper,
        reactFlowInstance,
        setReactFlowInstance,
        editPanelOpen,
        setEditPanelOpen,
        selectNode,
        setSelectNode,
        startNode,
        setStartNode,
        textNode,
        setTextNode,
        sendEditFlows,
        resetEditFields,
        resetTextField,
        resetStartField,
        msgWaitResponseNode,
        setMsgWaitResponseNode,
        resetMsgWaitResponseField,
        sqlQueryNode,
        setSqlQueryNode,
        resetSqlQueryField,
        sendEmailNode,
        setSendEmailNode,
        resetSendEmailField,
        assignToNode,
        setAssignToNode,
        resetAssignToField,
        fillFormNode,
        setFillFormNode,
        resetFillFormField,
        redirectNode,
        setRedirectNode,
        resetRedirectField,
        fillTicketNode,
        setFillTicketNode,
        resetFillTicketField,
        contactSearchNode,
        setContactSearchNode,
        resetContactSearchField,
        contactUpdateNode,
        setContactUpdateNode,
        resetContactUpdateField,
        conversationUpdateNode,
        setConversationUpdateNode,
        resetConversationUpdateField,
        apiNode,
        setApiNode,
        resetApiField,
        timeScheduleControlNode,
        setTimeScheduleControlNode,
        resetTimeScheduleControlField,
        timeTimerNode,
        setTimeTimerNode,
        resetTimeTimerField,
        timeWaitUntilNode,
        setTimeWaitUntilNode,
        resetTimeWaitUntilField,
        conditionalNode,
        setConditionalNode,
        resetConditionalField,
        selectNodesId,
        setSelectNodesId,
        endNode,
        setEndNode,
        resetEndField,
        selectEdgesId,
        setSelectEdgesId,
        // GetFunctions
        getScheduleById,
      }}
    >
      {children}
    </InitEditFlowsContext.Provider>
  );
};

export default EditFlowsProvider;
