import React, { useContext, useEffect } from 'react';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { pinConversation } from 'redux/features/conversationsSlice/conversationsSlice';
import { useTranslation } from 'react-i18next';
import { Menu, Box } from '@mui/material';
import MenuItem from './components/MenuItem';
import { HideSource, Outbox, PushPin } from '@mui/icons-material';
import { IConversation } from '@trii/types/dist/Conversations';

interface ContactActionsProps {
  conversationId: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  menuPosition: { mouseX: number | null; mouseY: number | null };
  handleSelectConversation: (incognito: boolean) => void;
}

const ContactActions = ({
  conversationId,
  anchorEl,
  onClose,
  menuPosition,
  handleSelectConversation,
}: ContactActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { conversations, handleFinalizeConversation } =
    useContext(conversationsContext);

  const conversation = conversations?.find(
    (conversation) => conversation.id === conversationId
  );

  const openMenu = Boolean(anchorEl);

  const handlePinConversation = (event: React.MouseEvent) => {
    event.stopPropagation();
    const data = {
      conversationId,
      pinned: !conversation?.pinned,
    };

    dispatch(pinConversation(data));
    onClose();
  };

  const handleIncognitoMode = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleSelectConversation(true);
  };

  const handleFinalize = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleFinalizeConversation(conversationId);
    onClose();
  };

  useEffect(() => {
    const handleClickAway = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    document.addEventListener('contextmenu', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
      document.removeEventListener('contextmenu', handleClickAway);
    };
  }, [anchorEl, onClose, openMenu]);

  return (
    <Box onContextMenu={(e) => e.preventDefault()}>
      <Menu
        id={conversationId}
        anchorEl={anchorEl}
        open={openMenu}
        onClick={onClose}
        onClose={onClose}
        hideBackdrop={true}
        MenuListProps={{ 'aria-labelledby': 'lock-button', role: 'listbox' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorReference="anchorPosition"
        anchorPosition={
          menuPosition.mouseY !== null && menuPosition.mouseX !== null
            ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
            : undefined
        }
        sx={{ position: 'unset' }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              backgroundColor: 'background.panel',
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            },
          },
          root: {
            hideBackdrop: true,
          },
        }}
      >
        <MenuItem
          onClick={handlePinConversation}
          icon={<PushPin fontSize="small" />}
          text={
            conversation?.pinned
              ? t('conversations.actions.unpinContact')
              : t('conversations.actions.pinContact')
          }
        />
        <MenuItem
          onClick={handleIncognitoMode}
          icon={<HideSource fontSize="small" />}
          text={t('conversations.actions.incognitoMode')}
        />
        <MenuItem
          onClick={handleFinalize}
          icon={<Outbox fontSize="small" />}
          text={t('conversations.actions.finalizeConversation')}
        />
      </Menu>
    </Box>
  );
};

export default ContactActions;
