import React, { createContext, useState, PropsWithChildren } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { startOfWeek } from 'date-fns';
import { fetchJsReport } from 'redux/features/reportsSlice/reportsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  jsReportDataTemplateNameTypeGuard,
  JsReportDataTimeRange,
} from 'redux/features/reportsSlice/types/JsReportData';
import { ChannelType } from '@trii/types/dist/Common/Channels';
interface MyReportsStates {
  openModalProgramSend: boolean;
  setOpenModalProgramSend: (value: boolean) => void;
  openSidebarReport: boolean;
  setOpeSidebarReport: (value: boolean) => void;
  selectedPeriodContext: [Dayjs, Dayjs] | null;
  setSelectedPeriodContext: (value: [Dayjs, Dayjs] | null) => void;

  selectedDate: Dayjs;
  setSelectedDate: (value: Dayjs) => void;
  setFilter: (value: Dayjs) => void;
  selectedChannels: number[] | undefined;
  setSelectedChannels: (channelIds: number[]) => void;
  appliedPeriod: [Dayjs, Dayjs] | null;
  appliedChannels: number[] | undefined;
  applyFilters: () => void;
  selectedPeriodType: JsReportDataTimeRange | null;
  setSelectedPeriodType: (value: JsReportDataTimeRange) => void;
  appliedPeriodType: JsReportDataTimeRange | null;
  setAppliedPeriodType: (value: JsReportDataTimeRange) => void;
  selectedContactName: string | undefined;
  setSelectedContactName: (value: string) => void;
  appliedContactName: string | undefined;
  selectedUsers: string[];
  setSelectedUsers: (value: string[]) => void;
  appliedUsers: string[];
  setAppliedUsers: (value: string[]) => void;
  selectedGroups: string[];
  setSelectedGroups: (value: string[]) => void;
  appliedGroups: string[];
  setAppliedGroups: (value: string[]) => void;
}

export const MyReportContext = createContext<MyReportsStates | undefined>(undefined);

const MyReportContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useAppDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get('r');

  const [openModalProgramSend, setOpenModalProgramSend] = useState<boolean>(false);
  const [openSidebarReport, setOpeSidebarReport] = useState<boolean>(true);

  const currentDate = dayjs();
  const startOfWeekDate = startOfWeek(currentDate.toDate(), { weekStartsOn: 1 });
  const startOfWeekDayjs = dayjs(startOfWeekDate);

  const [selectedPeriodContext, setSelectedPeriodContext] = useState<[Dayjs, Dayjs]>(
    [startOfWeekDayjs, currentDate]
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [selectedChannels, setSelectedChannels] = useState<ChannelType[]>();
  const [selectedPeriodType, setSelectedPeriodType] =
    useState<JsReportDataTimeRange>();
  const [selectedContactName, setSelectedContactName] = useState<string>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const [appliedPeriod, setAppliedPeriod] = useState<[Dayjs, Dayjs]>([
    startOfWeekDayjs,
    currentDate,
  ]);
  const [appliedPeriodType, setAppliedPeriodType] =
    useState<JsReportDataTimeRange>(null);
  const [appliedContactName, setAppliedContactName] = useState<string>('');
  const [appliedChannels, setAppliedChannels] = useState<ChannelType[]>([]);
  const [appliedUsers, setAppliedUsers] = useState<string[]>([]);
  const [appliedGroups, setAppliedGroups] = useState<string[]>([]);

  const applyFilters = () => {
    setAppliedPeriod(selectedPeriodContext);
    setAppliedChannels(selectedChannels);
    setAppliedPeriodType(selectedPeriodType);
    setAppliedContactName(selectedContactName);
    setAppliedUsers(selectedUsers);
    setAppliedGroups(selectedGroups);

    const isTemplateName = jsReportDataTemplateNameTypeGuard(paramValue);
    if (isTemplateName) {
      console.log({
        templateName: paramValue,
        moduleName: 'conversations',
        filter: {
          timeRange: selectedPeriodType,
          channelType: selectedChannels,
          contactName: selectedContactName,
          users: selectedUsers,
          groups: selectedGroups,
          timeStart: startOfWeekDayjs.format('YYYY-MM-DD'),
          timeEnd: currentDate.format('YYYY-MM-DD'),
        },
      });
      // dispatch(
      //   fetchJsReport({
      //     templateName: paramValue,
      //     moduleName: 'conversations',
      //     filter: {
      //       timeRange: selectedPeriodType,
      //       channelType: selectedChannels,
      //       contactName: selectedContactName,
      //       users: selectedUsers,
      //       groups: selectedGroups,
      //       timeStart: startOfWeekDayjs.format('YYYY-MM-DD'),
      //       timeEnd: currentDate.format('YYYY-MM-DD'),
      //     },
      //   })
      // );
    }
  };

  const setFilter = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  return (
    <MyReportContext.Provider
      value={{
        openModalProgramSend,
        setOpenModalProgramSend,
        openSidebarReport,
        setOpeSidebarReport,
        selectedPeriodContext,
        setSelectedPeriodContext,
        selectedDate,
        setFilter,
        setSelectedDate,
        setSelectedChannels,
        selectedChannels,
        appliedPeriod,
        appliedChannels,
        applyFilters,
        appliedPeriodType,
        setAppliedPeriodType,
        selectedPeriodType,
        setSelectedPeriodType,
        selectedContactName,
        setSelectedContactName,
        appliedContactName,
        selectedUsers,
        setSelectedUsers,
        appliedUsers,
        setAppliedUsers,
        selectedGroups,
        setSelectedGroups,
        appliedGroups,
        setAppliedGroups,
      }}
    >
      {children}
    </MyReportContext.Provider>
  );
};

export default MyReportContextProvider;
