// Components/ui
import { Box, Fade, useTheme } from '@mui/material';

interface CollapseAnimationProps {
  children: React.ReactNode;
  openPanel: boolean;
  childrenType: 'service' | 'node';
}

const CollapseAnimation = ({
  children,
  openPanel,
  childrenType,
}: CollapseAnimationProps) => {
  const theme = useTheme();

  return (
    <Fade in={openPanel} unmountOnExit>
      <Box
        sx={{
          display: openPanel ? 'flex' : 'none',
          flexDirection: 'column',
          border: `1px solid ${theme.palette.divider}`,
          height: '100%',
          maxWidth: `${childrenType === 'service' ? '12rem' : '40rem'}`,
          width: `${childrenType === 'service' ? '12rem' : '40rem'}`,
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Fade>
  );
};

export default CollapseAnimation;
