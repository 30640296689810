import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
import {
  fetchEmail,
  selectEmailName,
  selectEmailUpdateStatus,
  selectEmailFetchStatus,
  updateEmail,
  selectEmail,
} from 'redux/features/emailEditSlice/emailEditSlice';
// Components/ui
import { Body } from './components';
import { Divider, Box } from '@mui/material';
import { ViewContainer, ChannelEditHeader } from 'components';
import EmailIcon from '@mui/icons-material/Email';

const EmailEditContainer = () => {
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const { id } = useParams();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const email = useSelector(selectEmail);
  const emailName = useSelector(selectEmailName);
  const emailUpdateStatus = useSelector(selectEmailUpdateStatus);
  const emailFetchStatus = useSelector(selectEmailFetchStatus);

  useEffect(() => {
    dispatch(fetchEmail(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      emailFetchStatus === 'succeeded' &&
      setAllFetched(true);
  }, [groupFetchStatus, scheduleFetchStatus, emailFetchStatus]);

  return (
    <ViewContainer>
      <Box >
        <ChannelEditHeader
          channelName={emailName}
          channelUpdateStatus={emailUpdateStatus}
          updateChannel={updateEmail}
          channelFetchStatus={emailFetchStatus}
        >
          <EmailIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>
        <Divider />
        <Body data={email} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default EmailEditContainer;
