import { createTheme } from '@mui/material';

const MuiComponentsConfig = (themeRedux) => {
  return createTheme({
    ...themeRedux,
    components: {
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: themeRedux.palette.background.panel,
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            zIndex: 1,
            '& div': {
              backgroundColor: themeRedux.palette.background.panel,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: themeRedux.palette.background.panel,
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: themeRedux.palette.background.panel,
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            background: 'transparent',
            '& .MuiBackdrop-root': {
              background: 'transparent',
              border: 'none',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: themeRedux.palette.text.primary,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: themeRedux.palette.text.primary,
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            height: '20px',
            fontSize: '0.7125rem',
          },
        },
      },
      // Añadir el estilo para MuiListSubheader
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: themeRedux.palette.background.accordionHeader, // Aplicar el color personalizado
          },
        },
      },
    },
  });
};

export default MuiComponentsConfig;
