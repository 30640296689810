import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const fetchWhatsApp = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/whatsapp/${id}`, config);
  return response.data;
};
const updateWhatsApp = async (jwt: string, URL: string, whatsApp: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const whatsAppJson = JSON.stringify(whatsApp);

  const response = await axios.put(
    `${URL}/channels/whatsapp/${whatsApp.id}`,
    whatsAppJson,
    config
  );
  return response.data;
};

async function syncTemplates(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/TemplateMessages/${channelId}/synctemplates`,
    {},
    config
  );

  return response;
}

async function resetApiQrConnection(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/channels/whatsapp/${channelId}/reload`,
    {},
    config
  );

  return response;
}

async function unlinkWhatsApp(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/channels/whatsapp/${channelId}/signout`,
    {},
    config
  );

  return response;
}

const whatsAppEditService = {
  fetchWhatsApp,
  updateWhatsApp,
  syncTemplates,
  resetApiQrConnection,
  unlinkWhatsApp,
};

export default whatsAppEditService;
