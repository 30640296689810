import React, { useContext, useState, useEffect } from 'react';
// Dispatch
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Service
import { fetchChannels } from 'redux/features/editFlowSlice/editFlowSlice';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import { TextField, Divider, FormControlLabel, Checkbox, Box } from '@mui/material';
//Components
import { Message, ActionButtons, Channel } from './components';
import { TemplatesProvider } from './context/TemplatesProvider';

const EditTextNode = () => {
  const { textNode, setTextNode, selectNode, fetchChannelList } =
    useContext(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [enableStatics, setEnableStatics] = useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    fetchChannelList();
  }, []);

  useEffect(() => {
    if (selectNode && selectNode.data.msgText) {
      const defaultId =
        selectNode && selectNode?.data && selectNode?.data?.msgText
          ? selectNode.data.msgText?.id
          : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName =
        selectNode && selectNode?.data
          ? selectNode?.data?.msgText?.name
          : ('' as string);
      const defaultEnableStatics =
        selectNode && selectNode?.data
          ? selectNode?.data?.msgText?.enableStatics
          : (false as boolean);
      setId(defaultId);
      setName(defaultName);
      setEnableStatics(defaultEnableStatics);
    }
  }, [selectNode]);

  const handleChangeName = (value: string) => {
    setName(value);
    setTextNode({
      ...textNode,
      id,
      name: value,
    });
  };

  const handleChangeEnableStatics = (value: boolean) => {
    setEnableStatics(value);
    setTextNode({
      ...textNode,
      enableStatics: value,
    });
  };

  return (
    <TemplatesProvider>
      <Box display="flex" flexDirection="column" height="100%">
        <TextField
          className="fadein"
          variant="outlined"
          size="small"
          label={t('editFlowsView.name')}
          fullWidth
          value={name}
          onChange={(e) => handleChangeName(e.target.value as string)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box sx={{ marginTop: '5px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enableStatics}
                onChange={(e) => handleChangeEnableStatics(e.target.checked)}
              />
            }
            slotProps={{
              typography: {
                variant: 'caption',
                color: 'text.primary',
              },
            }}
            label={t('editFlowsView.enableStatics')}
          />
        </Box>
        <Divider sx={{ margin: '1rem 0rem' }} />
        <Channel />
        <Divider sx={{ margin: '1rem 0rem' }} />
        <Message />
        <ActionButtons />
      </Box>
    </TemplatesProvider>
  );
};

export default EditTextNode;
