import { useContext, useEffect, useState } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useSASAvatarURL from 'hooks/useSASAvatarURL';
// Slice
import { fetchUpdateField } from 'redux/features/contactInfoSlice/contactInfoSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import { Box, Avatar, IconButton, Stack, Badge } from '@mui/material';
import { ContactName, ImageProfileViewer, MoreActions } from './components';
// Utils
import { icons } from './utils/Icons';
// Icons
import spamIcon from './utils/assets/spam_icon.png';
import BusinessIcon from '@mui/icons-material/Business';

const Header = () => {
  const dispatch = useAppDispatch();

  const { contactInfo, setContactInfo } = useContext(conversationsContext);

  const [showImage, setShowImage] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSpam, setIsSpam] = useState(false);
  const [animate, setAnimate] = useState(false);

  const imageURLWithAccess = useSASAvatarURL(contactInfo?.imageUrl);

  const handleClickImg = () => {
    setShowImage(!showImage);
  };

  const handleOpenMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSpamContact = async () => {
    setIsSpam(!isSpam);
    setAnimate(true);
    const data = {
      contactId: contactInfo?.id,
      field: 'isSpam',
      value: !isSpam,
    };
    await dispatch(fetchUpdateField(data));
  };

  useEffect(() => {
    if (contactInfo) {
      setAnimate(false);
      setIsSpam(contactInfo?.isSpam);
    }
  }, [contactInfo]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
          }}
        >
          {icons.map((icon, index) => (
            <IconButton
              disabled={contactInfo.deletedAt !== null}
              key={index}
              size="small"
              sx={{
                color: "text.disabled",
              }}
              onClick={
                icon.handler === "more"
                  ? handleOpenMore
                  : icon.handler === "block"
                  ? handleSpamContact
                  : () => {}
              }
            >
              <icon.icon sx={{ fontSize: 18 }} />
            </IconButton>
          ))}
        </Stack>
        {isSpam && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              width: "8rem",
              height: "8rem",
              top: "1rem",
              userSelect: "none",
              animation: animate && "zoomout 0.3s ease-in-out",
              "@keyframes zoomout": {
                "0%": {
                  transform: "scale(3)",
                  rotate: "45deg",
                  opacity: 0,
                },
                "100%": {
                  transform: "scale(1)",
                  rotate: "0deg",
                  opacity: 1,
                },
              },
            }}
          >
            <img src={spamIcon} alt="This contact is spam" />
          </Box>
        )}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={"10px"}
          padding={"0 .5rem"}
          width={"100%"}
        >
          {
            //@ts-ignore
            contactInfo?.isBusiness === false ? (
              <Avatar
                sx={{
                  width: 90,
                  height: 90,
                  cursor: "pointer",
                }}
                onClick={handleClickImg}
                src={imageURLWithAccess}
                alt={contactInfo?.name}
              />
            ) : (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={<BusinessIcon />}
              >
                <Avatar
                  sx={{
                    width: 90,
                    height: 90,
                    cursor: "pointer",
                  }}
                  onClick={handleClickImg}
                  src={imageURLWithAccess}
                  alt={contactInfo?.name}
                >
                  <BusinessIcon sx={{ width: "2em", height: "2em" }} />
                </Avatar>
              </Badge>
            )
          }
          <ContactName
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
          />
        </Box>
        <ImageProfileViewer
          avatar={imageURLWithAccess}
          name={contactInfo?.name}
          open={showImage}
          handleClose={handleClickImg}
        />
        <MoreActions
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleClose={handleClosePopover}
          contactInfo={contactInfo}
          setContactInfo={setContactInfo}
        />
      </Box>
    </>
  );
};

export default Header;
