import React from 'react';
// Components/ui
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  className: string;
}

const ChannelEditBodyContainer = ({ children, className }: Props) => {
  return (
    <Box
      display={'flex'}
      py={3}
      sx={{
        px: {
          xs: 2,
          lg: 14,
        },
      }}
      height={'calc(100vh - 125px)'}
      flexDirection={'column'}
      gap={1}
      width={'100%'}
      bgcolor={'background.default'}
      overflow={'auto'}
      className={className}
    >
      {children}
    </Box>
  );
};

export default ChannelEditBodyContainer;
