import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeWidgetCalloutStatus } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import { Switch, FormControlLabel } from '@mui/material';
import OptionsContainer from '../../../OptionsContainer';

interface Props {
  calloutEnabled: boolean;
}

const EnableCalloutOptions = ({ calloutEnabled }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleChangeWidgetCalloutStatus = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeWidgetCalloutStatus(e.target.checked));
  };

  return (
    <OptionsContainer
      title={t('webChatEditView.designPanel.widgetOptionSection.callout')}
    >
      <FormControlLabel
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
        control={<Switch checked={calloutEnabled} />}
        label={t('global.enable')}
        labelPlacement="start"
        onChange={handleChangeWidgetCalloutStatus}
      />
    </OptionsContainer>
  );
};

export default EnableCalloutOptions;
