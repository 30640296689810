// Components ui
import {
  Box,
  Divider,
  Skeleton as MuiSkeleton,
} from '@mui/material'
// Components
import { Table } from './components'

export const Skeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <MuiSkeleton
        variant="text"
        width="100%"
        sx={{
          fontSize: '1.5rem',
        }}
        animation="wave"
      />
      <MuiSkeleton
        variant="text"
        width="100%"
        sx={{
          fontSize: '1.5rem',
        }}
        animation="wave"
      />
      <Divider />
      <Table />
      <Divider />
      <MuiSkeleton
        variant="rectangular"
        width="100%"
        height={90}
        sx={{
          borderRadius: '5px',
        }}
      />
    </Box>
  )
}

export default Skeleton