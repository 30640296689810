import { Avatar, Badge, ListItemAvatar } from '@mui/material';
// Types
import { UserInfo, UserStatus } from '@trii/types/dist/Users';

type StatusUserAvatarProps = {
  data: UserInfo;
};

const StatusUserAvatar = ({ data }: StatusUserAvatarProps) => {
  const getBadgeStyle = () => {
    const numberStatus = Number(data.status);

    switch (numberStatus) {
      case UserStatus.ONLINE:
        return {
          '& .MuiBadge-dot': {
            backgroundColor: '#4caf50', // success color
          },
        };
      case UserStatus.AWAY:
        return {
          '& .MuiBadge-dot': {
            backgroundColor: '#ff9800', // warning color
          },
        };
      case UserStatus.BUSY:
        return {
          '& .MuiBadge-dot': {
            backgroundColor: '#f44336', // error color
          },
        };
      case UserStatus.OFFLINE:
      default:
        return {
          '& .MuiBadge-dot': {
            backgroundColor: 'lightgray', // Custom color for offline status
          },
        };
    }
  };

  const badgeStyle = getBadgeStyle();

  return (
    <ListItemAvatar>
      <Badge
        overlap="circular"
        badgeContent=" "
        variant="dot"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={badgeStyle} // Apply sx styles directly
      >
        <Avatar alt={data?.name} src={data.imageUrl}>
          {data ? null : data?.name ? data.name.charAt(0).toUpperCase() : ''}
        </Avatar>
      </Badge>
    </ListItemAvatar>
  );
};

export default StatusUserAvatar;
