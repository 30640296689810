import React, { useContext } from 'react';
// Components/ui
import { Divider, Box } from '@mui/material';
import { Inputs, ActionButtons } from './components';
import PanelContainer from '../SideContainer/SideContainer';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { useTranslation } from 'react-i18next';
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
// @ts-ignore

const CreatePanel = () => {
  const { closeCreatePanel } = useContext(InitScheduleContext);
  const {t} = useTranslation()
  return (
    <PanelContainer>
     <PanelHeader
        title={t('scheduleView.createSchedule')}
        handleCloseButton={closeCreatePanel}
      />
      <Divider />
      {/* <CreateInputs createEmailState={createEmailState} /> */}
      <Inputs/>
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <ActionButtons />
    </PanelContainer>
  );
};

export default CreatePanel;
