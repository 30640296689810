import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { googleBusinessContext } from 'features/Views/GoogleBusiness/context/GoogleBusinessProvider';
// Components/ui
import { NavLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { GoogleLogInButton } from './components';

const ListHeader = () => {
  const { crudPanelState, openCreate } = useContext(googleBusinessContext);
  const { t } = useTranslation();
  const disabled = crudPanelState === 'create';

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/googlebusiness">
      <Typography color="secondary">
        {t('googleBusinessView.googleBusiness')}
      </Typography>
    </NavLink>,
  ];

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
      // className="m-0"
      gap={'1rem'}
    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <GoogleLogInButton />
    </Box>
  );
};

export default ListHeader;
