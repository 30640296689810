import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Types
import type { ChangeScheduleFn } from 'redux/types/ChangeScheduleFn';
import { InstagramConfig } from '@trii/types/dist/Common/Channels';
// Components/ui
import { ChannelNewConversationsConfig, ChannelPanel } from 'components';
import { Box, TextField, Switch, FormControlLabel } from '@mui/material';
import { switchStyle, inputFieldStyle } from './styles';
import {
  ChannelNamedOptionSwitch,
  ChannelGroupSelect,
  ChannelScheduleSelect,
} from './components/index.js';
import { AssignMethod, ConversationAssigned } from '@trii/types/dist/Conversations';
import { changeAssignTo } from 'redux/features/instagramEditSlice/instagramEditSlice';
import { UserInfo } from '@trii/types/dist/Users';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';

interface Props {
  data: InstagramConfig;
  id: string;
  name: string;
  changeName: (payload: string) => void;
  changeToken: (payload: string) => void;
  changeReassign: (payload: boolean) => void;
  changeReassignGroup: (payload: string) => void;
  changeAutoResponseBusinessHours: (payload: boolean) => void;
  changeAutoResponseOutOfBusinessHours: (payload: boolean) => void;
  changeAutoResponseHoliday: (payload: boolean) => void;
  changeSchedule: ChangeScheduleFn;
  assignMethod: AssignMethod;
  changeAssignMethod: (payload: AssignMethod) => void;
  assignTo: ConversationAssigned;
}

const DetailsPanel = ({
  id,
  name,
  data,
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  assignMethod,
  changeAssignMethod,
  assignTo,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    dispatch(changeName(e.target.value));
  };

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    dispatch(changeToken(e.target.value));
  };

  const handleReassignChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    dispatch(changeReassign(e.target.checked));
  };
  const handleAutoResponseBussinessHoursChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    dispatch(changeAutoResponseBusinessHours(e.target.checked));
  };
  const handleAutoResponseOutOfBussinessHoursChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    dispatch(changeAutoResponseOutOfBusinessHours(e.target.checked));
  };
  const handleAutoResponseHolidaysChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    dispatch(changeAutoResponseHoliday(e.target.checked));
  };

  const handleAssignToChange = (user: UserInfo | null, group: GroupInfo | null) => {
    dispatch(changeAssignTo({ user, group }));
  };

  return (
    <ChannelPanel title="Detalles">
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        sx={{ gap: 2, justifyContent: 'center' }}
        alignItems={'center'}
      >
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          label={'Id'}
          disabled
          value={id}
          sx={inputFieldStyle}
        />
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          label={t('global.name')}
          value={name}
          sx={inputFieldStyle}
          onChange={handleNameChange}
        />
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          label={'Token'}
          sx={inputFieldStyle}
          value={data.token}
          onChange={handleTokenChange}
        />
        <FormControlLabel
          control={<Switch color={'primary'} />}
          label={t('channelEditView.detailsPanel.reasignConversation')}
          labelPlacement="start"
          sx={{
            ...switchStyle,
            mb: 0,
            mt: 1.5,
          }}
          checked={data.reassign}
          onChange={handleReassignChange}
        />
        <ChannelGroupSelect
          disabled={!data.reassign}
          reassignGroupId={data.reassignGroupId}
          changeReassignGroupAction={changeReassignGroup}
        />
        <ChannelNamedOptionSwitch
          checked={data.autoResponseBusinessHours}
          label={t('channelEditView.detailsPanel.autoResponseBusinessHours')}
          onChange={handleAutoResponseBussinessHoursChange}
        />
        <ChannelNamedOptionSwitch
          checked={data.autoResponseOutOfBusinessHours}
          label={t('channelEditView.detailsPanel.autoResponseOutOfBusinessHours')}
          onChange={handleAutoResponseOutOfBussinessHoursChange}
        />
        <ChannelNamedOptionSwitch
          checked={data.autoResponseHoliday}
          label={t('channelEditView.detailsPanel.autoResponseHolidays')}
          onChange={handleAutoResponseHolidaysChange}
        />
        <ChannelScheduleSelect
          changeSchedule={changeSchedule}
          selectedSchedule={data.schedule}
        />
        <ChannelNewConversationsConfig
          changeAssignMethod={changeAssignMethod}
          assignMethodValue={assignMethod}
          assignToValue={assignTo}
          handleUserGroupSelectorAccept={handleAssignToChange}
        />
      </Box>
    </ChannelPanel>
  );
};

export default DetailsPanel;
