import { useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, CircularProgress, InputAdornment, TextField } from '@mui/material';
// Icons
import { Search } from '@mui/icons-material';
// Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
// Components
import { ItemList } from './components';
// Selector
import { selectPostsStatus } from 'redux/features/socialWallSlice/socialWallSlice';
import { TreeView } from '@mui/x-tree-view';

const PublicationList = () => {
  const { t } = useTranslation();
  const { posts: unsortedPosts } = useContext(socialWallsContext);
  const postsStatus = useSelector(selectPostsStatus);

  const isLoading = postsStatus === 'loading';

  const posts = [...unsortedPosts].sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth={'25%'}
      maxWidth={'25%'}
      flexGrow={1}
      height={'100%'}
      bgcolor="background.default"
      boxShadow={'0px 1px 2px rgba(0,0,0,0.2)'}
      sx={{
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        '@media (max-width: 1024px)': {
          minWidth: '240px',
        },
      }}
    >
      {/* Filtro */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          height: '3rem',
          width: '100%',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <TextField
          id="input-with-icon-textfield"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ fontSize: '12px' }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiInputBase-input': { height: '0.8375em' },
            width: '100%',
          }}
          size="small"
          placeholder={t('inputSearch.searchBy')}
        />
      </Box>

      {/* Lista de publicaciones */}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          height: 'calc(100% - 4rem)',
        }}
      >
        <TreeView
          sx={{
            height: 'auto',
            flexGrow: 1,
            maxWidth: 500,
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          {isLoading && posts.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1rem',
                height: '100%',
                width: '100%',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          ) : (
            posts &&
            posts.length > 0 &&
            posts.map((post) => <ItemList key={post.id} post={post} />)
          )}
        </TreeView>
      </Box>
    </Box>
  );
};

export default PublicationList;
