import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $getRoot,
  $insertNodes,
  TextNode,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';
// Redux
import { useSelector } from 'react-redux';
// Hooks
import useModal from '../../hooks/useModal';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Slice
import {
  fetchQuickResponses,
  selectQuickResponses,
} from 'redux/features/quickResponseSlice/quickResponseSlice';
// Components/ui
import { Box, IconButton, List, ListItemButton, ListItemText, Typography } from '@mui/material';
// Icons
import { Settings } from '@mui/icons-material';
// Translations
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from './style/CustomTooltip';

const SLASH_REGEX = /\/([^\/]*)\s*$/g;

class ComponentPickerOption extends MenuOption {
  // Title of Response
  title: string;
  bodyHTML: string;
  id: string;
  // For extra searching.
  keywords: Array<string>;
  // TBD
  keyboardShortcut?: string;
  // What happens when you select this option?
  onSelect: (queryString: string) => void;

  constructor(
    title: string,
    options: {
      bodyHTML: string;
      id: string;
      keywords?: Array<string>;
      keyboardShortcut?: string;
      onSelect: (queryString: string) => void;
    },
  ) {
    super(title);
    this.title = title;
    this.bodyHTML = options.bodyHTML;
    this.keywords = options.keywords || [];
    this.id = options.id;
    this.keyboardShortcut = options.keyboardShortcut;
    this.onSelect = options.onSelect.bind(this);
  }
}

function QuickResponseList({
  onClick,
  option,
}: {
  onClick: () => void;
  option: ComponentPickerOption;
}) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(option.bodyHTML, 'text/html');
  const text = dom.body.textContent || dom.body.innerText || '';
  return (
    <ListItemButton
      disableRipple
      key={option.key}
      tabIndex={-1}
      ref={option.setRefElement}
      onClick={onClick}
      dense
      divider
    >
      <CustomTooltip
        title={text}
        placement='top'
      >
        <ListItemText
          sx={{
            color: theme => theme.palette.text.primary,
            fontSize: 12,
          }}
        >
          {option.title}
        </ListItemText>
      </CustomTooltip>
    </ListItemButton>
  );
}

export default function QuickResponse(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const quickResponses = useSelector(selectQuickResponses);
  const [editor] = useLexicalComposerContext();
  const [modal, showModal] = useModal();
  const [queryString, setQueryString] = useState<string | null>(null);

  function checkSlashAction(text: string): MenuTextMatch | null {
    const responseMatch = text.match(SLASH_REGEX)
    let match: MenuTextMatch | null = null;

    if (responseMatch) {
      responseMatch[0].replace(SLASH_REGEX, (fullMatch, response) => {
        match = {
          leadOffset: fullMatch?.length,
          matchingString: response,
          replaceableString: fullMatch,
        }
        return response;
      });
    }
    return match;
  }

  const getQuickResponses = async () => {
    await dispatch(fetchQuickResponses());
  }

  const options = useMemo(() => {
    if (quickResponses && quickResponses.length > 0) {
      const baseOptions = quickResponses.map((quickResponse) => {
        return new ComponentPickerOption(`${quickResponse.categoryName} - ${quickResponse.title}`, {
          id: quickResponse.id,
          bodyHTML: quickResponse.bodyHTML,
          keywords: [quickResponse.title, quickResponse.categoryName, quickResponse.bodyHTML],
          onSelect: () =>
            editor.update(() => {
              // In the browser you can use the native DOMParser API to parse the HTML string.
              const parser = new DOMParser();
              const dom = parser.parseFromString(quickResponse.bodyHTML, 'text/html');

              // Once you have the DOM instance it's easy to generate LexicalNodes.
              const nodes = $generateNodesFromDOM(editor, dom);

              // Select the root
              $getRoot().select();

              // Insert them at a selection.
              $insertNodes(nodes);
            }),
        });
      });
      return queryString
        ? [
          ...baseOptions.filter((option) => {
            return new RegExp(queryString, 'gi').exec(option.title) ||
              option.keywords != null
              ? option.keywords.some((keyword) =>
                new RegExp(queryString, 'gi').exec(keyword),
              )
              : false;
          }),
        ]
        : baseOptions;
    }
  }, [editor, queryString, showModal]);

  const onSelectOption = useCallback(
    (
      selectedOption: ComponentPickerOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
      matchingString: string,
    ) => {
      editor.update(() => {
        if (nodeToRemove) {
          nodeToRemove.remove();
        }
        selectedOption.onSelect(matchingString);
        closeMenu();
      });
    },
    [editor],
  );

  const handleClick = () => {
    window.open('/a/conversations/quick-responses', '_blank');
  }

  useEffect(() => {
    if (quickResponses && quickResponses.length === 0) {
      getQuickResponses();
    }
  }, [])

  return (
    <>
      {modal}
      <LexicalTypeaheadMenuPlugin<ComponentPickerOption>
        onQueryChange={setQueryString}
        onSelectOption={onSelectOption}
        triggerFn={checkSlashAction}
        options={options}
        menuRenderFn={(
          anchorElementRef,
          { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
        ) =>
          anchorElementRef.current && options.length
            ? ReactDOM.createPortal(
              <Box
                sx={{
                  backgroundColor: theme => theme.palette.background.paper,
                  width: 200,
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                  borderRadius: 1,
                  mt: '25px',
                  position: 'relative',
                  zIndex: 1300,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                    padding: '0 1rem',
                  }}
                >
                  <Typography
                    variant='body2'
                    color='text.primary'
                  >
                    {t('quickResponse.quickResponses')}
                  </Typography>
                  <IconButton
                    onClick={handleClick}
                    size='small'
                  >
                    <Settings />
                  </IconButton>
                </Box>
                <List
                  sx={{
                    maxHeight: 200,
                    overflowY: 'auto',
                  }}
                >
                  {options.map((option, i: number) => (
                    <QuickResponseList
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
                </List>
              </Box>,
              anchorElementRef.current,
            )
            : null
        }
      />
    </>
  );
}
