import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { FormItemResponseItem } from '@trii/types/dist/Common/Channels';
import { RemoveOption } from './types/RemoveOption';
import { AddOption } from './types/AddOption';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { Box, Button, TextField, Typography } from '@mui/material';
import { OptionItem } from './components';

interface Props {
  optionsData: FormItemResponseItem[];
  questionId: string;
  addOption: ({ id, option }: AddOption) => void;
  removeOption: ({ optionId, questionId }: RemoveOption) => void;
  icon: React.ReactNode;
}

const MultipleOptionsInput = ({
  optionsData,
  questionId,
  addOption,
  removeOption,
  icon,
}: Props) => {
  const [optionText, setOptionText] = useState<string>('');
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleAddOption = () => {
    // @ts-ignore
    dispatch(addOption({ id: questionId, option: optionText }));
    setOptionText('');
  };
  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionText(e.target.value);
  };

  const MenuItems = optionsData.map((option) => (
    <OptionItem
      removeOption={removeOption}
      key={option.id}
      questionId={questionId}
      optionData={option}
      icon={icon}
    />
  ));

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} pt={3}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <TextField
          label={t('formEditView.designPanel.optionText')}
          sx={{ width: '80%' }}
          size="small"
          value={optionText}
          onChange={handleOptionChange}
        />
        <Button onClick={handleAddOption}>
          {t('formEditView.designPanel.addOption')}
        </Button>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant={'subtitle2'}>
          {t('formEditView.designPanel.currentOptions')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {MenuItems}
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleOptionsInput;
