import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import { Box, CircularProgress, IconButton, Slide } from '@mui/material';
import { Mood, Send } from '@mui/icons-material';
// Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
// Components
import { Comment } from './components';
import { Emojis, Input } from 'components';
import {
  selectComments,
  selectCommentsStatus,
  setNewComments,
} from 'redux/features/socialWallSlice/socialWallSlice';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';

const ChatContainer = () => {
  const dispatch = useAppDispatch();
  const { postSelected, messages, isNewMessage, setIsNewMessage, sendMessage, backgroundImage } =
    useContext(socialWallsContext);
  console.log('messages', messages);
  const newComments = useSelector(selectComments);
  const newCommentsStatus = useSelector(selectCommentsStatus);
  const isLoading = newCommentsStatus === 'loading';

  const [value, setValue] = useState<string>('');
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [newEmoji, setNewEmoji] = useState<string>('');
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleEmojiSelect = (emoji) => {
    setNewEmoji(emoji.native);
  };

  const handleShowEmojis = () => {
    setShowEmojis(!showEmojis);
  };

  const handleSendMessage = () => {
    if (value) {
      const data = {
        postId: postSelected.id,
        message: value,
        parentId: postSelected.id,
      };
      sendMessage(data);
      setValue('');
      setNewEmoji('');
      setShowEmojis(false);
      setIsNewMessage(true);
    }
  };

  const keepScrollAtBottom = () => {
    if (containerRef.current && (isNewMessage || isFirstRender)) {
      setIsFirstRender(false);
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    keepScrollAtBottom();
  }, [messages, isNewMessage, containerRef?.current?.scrollHeight]);

  useEffect(() => {
    if (newComments) {
      dispatch(setNewComments(newComments));
      setIsFirstRender(true);
    }
  }, [newComments]);

  useEffect(() => {
    if (postSelected) {
      setShowEmojis(false);
      setValue('');
    }
  }, [postSelected, messages]);


  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        height="100%"
        px={1}
        py={1}
        sx={{
          maxHeight: 'calc(100vh - 215px)',
          overflow: 'auto',
          gap: 1,
          backgroundImage: `url(${backgroundImage})`,
        }}
        ref={containerRef}  

      >
        {/* map item */}
        {isLoading ? (
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress size={20} />
          </Box>
        ) : (
          messages.map((newComment) => (
            <Comment
              key={newComment.id}
              message={newComment}
              keepScrollAtBottom={keepScrollAtBottom}
              postIsMercadoLibre={
                postSelected?.channelInfo.type === ChannelType.MERCADOLIBRE
              }
              postId={postSelected?.id}
              postIsParent={true}
            />
          ))
        )}
      </Box>
      {showEmojis && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxHeight: '20rem',
            backgroundColor: 'background.default',
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          }}
        >
          <Slide direction="up" in={showEmojis} container={containerRef.current}>
            <Box height="100%" width="100%" maxHeight="inherit">
              <Emojis handleEmojiSelect={handleEmojiSelect} />
            </Box>
          </Slide>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="4rem"
        width="100%"
        px={1}
        py={1}
        gap={1}
        borderTop={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box
          display="flex"
          alignItems="end"
          maxWidth="100%"
          width="inherit"
          flexWrap="wrap"
          margin="auto"
        >
          <Input
            value={value}
            setValue={setValue}
            newEmoji={newEmoji}
            setNewEmoji={setNewEmoji}
            handleSendMessage={handleSendMessage}
            contactId=""
            conversationId=""
            disabled={
              postSelected?.channelInfo.type === ChannelType.MERCADOLIBRE ||
              postSelected?.channelInfo.type === ChannelType.FACEBOOKPAGE
            }
          />
        </Box>
        <IconButton
          disabled={
            postSelected?.channelInfo.type === ChannelType.MERCADOLIBRE ||
            postSelected?.channelInfo.type === ChannelType.FACEBOOKPAGE
          }
          onClick={handleShowEmojis}
          sx={{ color: 'text.disabled' }}
        >
          <Mood color={showEmojis ? 'primary' : 'inherit'} />
        </IconButton>
        <IconButton
          disabled={
            postSelected?.channelInfo.type === ChannelType.MERCADOLIBRE ||
            postSelected?.channelInfo.type === ChannelType.FACEBOOKPAGE
          }
        >
          <Send
            sx={{
              color: 'text.disabled',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatContainer;
