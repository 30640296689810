import React from 'react';
// redux
import { useSelector } from 'react-redux';
// import { selectFilterPanelAnimate } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// components/ui
import { Collapse } from '@mui/material';

const CollapseAnimation = ({ children, openPanel }) => {
  // const isFilterPanelAnimate = useSelector(selectFilterPanelAnimate);

  return (
    <Collapse
      // timeout={isFilterPanelAnimate ? 300 : 0}
      in={openPanel}
      orientation={"horizontal"}
      sx={{
        maxWidth: "250px",
        overflow: "auto",
        backgroundColor: "#fff",
        borderRadius: "2px",
        boxShadow: "0px 1px 2px rgba(0,0,0,0.2)",
        width: "-webkit-fill-available",
      }}
      className="width-available"
    >
      {children}
    </Collapse>
  );
};

export default CollapseAnimation;
