import { useTranslation } from 'react-i18next';
// Types
import { InstagramConfig } from '@trii/types/dist/Common/Channels';
// Components/ui
import { ChannelPanel } from 'components';
import { Box } from '@mui/material';
import { ChannelNamedOptionSwitch } from './components';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Slice
import { changeSubscribedInstagramEvents } from 'redux/features/instagramEditSlice/instagramEditSlice';

interface Props {
  data: InstagramConfig;
}
type SubscriptionEventState = 'messages' | 'feed';

const SubscriptionPanel = ({
  data,
}: Props) => {
  const { t } = useTranslation();
  const [subscribeMessages, setSubscribeMessages] = useState<boolean>(false);
  const [subscribePages, setSubscribePages] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleChange = (state: SubscriptionEventState) => {
    const { igSubscribedEvents } = data;
    if (igSubscribedEvents?.length > 0) {
      const isSubscribed = igSubscribedEvents.includes(state);
      if (isSubscribed) {
        const newSubscribedEvents = igSubscribedEvents.filter(
          (item) => item !== state
        );
        dispatch(changeSubscribedInstagramEvents(newSubscribedEvents));
      } else {
        const newSubscribedEvents = [...data.igSubscribedEvents, state];
        dispatch(changeSubscribedInstagramEvents(newSubscribedEvents));
      }
    } else {
      dispatch(changeSubscribedInstagramEvents([state]));
    }
  }

  useEffect(() => {
    if (data) {
      const isMessagesSubscribed = data.igSubscribedEvents.includes('messages');
      const isPagesSubscribed = data.igSubscribedEvents.includes('feed');
      setSubscribeMessages(isMessagesSubscribed);
      setSubscribePages(isPagesSubscribed);
    }
  }, [data]);

  return (
    <ChannelPanel title={t('channelEditView.subscriptionPanel.title')}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        sx={{ gap: 2, justifyContent: 'center' }}
        alignItems={'center'}
      >
        <ChannelNamedOptionSwitch
          checked={subscribeMessages}
          label={t('channelEditView.subscriptionPanel.messages')}
          onChange={() => handleChange('messages')}
        />
        <ChannelNamedOptionSwitch
          checked={subscribePages}
          label={t('channelEditView.subscriptionPanel.pages')}
          onChange={() => handleChange('feed')}
        />
      </Box>
    </ChannelPanel>
  );
};

export default SubscriptionPanel;
