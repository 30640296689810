import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography } from '@mui/material';

const SaveAs = () => {
  const { selectNode, setContactUpdateNode, contactUpdateNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [valueToSave, setValueToSave] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.contactUpdate) {
      setValueToSave(
        selectNode.data.contactUpdate.valueToSave
          ? selectNode.data.contactUpdate.valueToSave
          : ''
      );
    }
  }, [selectNode]);

  const handleChange = (valueToSave: string) => {
    setValueToSave(valueToSave);
    setContactUpdateNode({
      ...contactUpdateNode,
      valueToSave,
    });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.contactUpdate.valueToSave')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={valueToSave}
        onChange={(e) => handleChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default SaveAs;
