import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectGoogleBusinessFetchStatus,
  selectAllGoogleBusiness,
} from 'redux/features/googleBusinessSlice/googleBusinessSlice';
// Components/ui
import { Box } from '@mui/material';
import { ListItemSkeleton } from 'components';
import GoogleBusinessItem from './components/GoogleBusinessItem/GoogleBusinessItem';

const ListBody = () => {
  const allGoogleBusiness = useSelector(selectAllGoogleBusiness);
  const googleBusinessFetchStatus: RequestStatus = useSelector(
    selectGoogleBusinessFetchStatus
  );

  const GoogleBusiness = allGoogleBusiness.map((googleBusiness) => (
    <GoogleBusinessItem key={googleBusiness.id} data={googleBusiness} />
  ));

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {googleBusinessFetchStatus === 'succeeded' ? (
        GoogleBusiness
      ) : (
        <ListItemSkeleton />
      )}
    </Box>
  );
};

export default ListBody;
