import React from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';
import { CopyClipboardButton } from 'components';

interface Props {
  boxLabel: string;
  content: string;
}

const CopyBox = ({ boxLabel, content }: Props) => {
  return (
    <Box
      px={2}
      py={3}
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      border={'1px solid'}
      borderColor={theme => theme.palette.divider}
      borderRadius={2}
    >
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignContent={'center'}
        >
          <Typography variant="subtitle2">{boxLabel}</Typography>
          <CopyClipboardButton text={content} />
        </Box>
      </Box>
      <Box
        px={3}
        py={5}
        border={'1px solid'}
      borderColor={theme => theme.palette.divider}

        borderRadius={2}
        //@ts-ignore
        bgcolor={(theme) => theme.palette.background.dropdownMenu}
      >
        <Typography variant="subtitle2" fontWeight={400}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

export default CopyBox;
