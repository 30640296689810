const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '900px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.dropdownMenu',
  borderRadius: '3px',
  border: '1px solid',
  borderColor: 'divider',
  boxShadow: 24,
  pt: 6,
  color: 'text.primary'
};

export default style;
