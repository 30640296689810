import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography, Box, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// Icons
import InfoIcon from '@mui/icons-material/Info';

const SendTo = () => {
  const { selectNode, setSendEmailNode, sendEmailNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [to, setTo] = useState<string>('');
  const TooltipPers = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // @ts-ignore
      backgroundColor: theme.palette.background.tooltip,
    },
    [`& .${tooltipClasses.arrow}`]: {
      // @ts-ignore
      color: theme.palette.background.tooltip,
    },
  })) as typeof Tooltip;
  useEffect(() => {
    if (selectNode && selectNode.data.sendEmail) {
      setTo(selectNode.data.sendEmail.to ? selectNode.data.sendEmail.to : '');
    }
  }, [selectNode]);

  const handleChangeConnection = (to: string) => {
    setTo(to);
    setSendEmailNode({
      ...sendEmailNode,
      to,
    });
  };

  return (
    <>
      <Box display="flex" gap={0.2} alignItems="center">
        <TooltipPers
          arrow
          title={t('editFlowsView.sendEmail.sendToTooltip')}
          placement="top"      
        >
          <InfoIcon
            style={{ fontSize: 'medium' }}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </TooltipPers>
        <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
          {t('editFlowsView.sendEmail.sendTo')}
        </Typography>
      </Box>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={to}
        onChange={(e) => handleChangeConnection(e.target.value)}
      />
    </>
  );
};

export default SendTo;
