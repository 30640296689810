import React from 'react';
// Component/ui
import { TextField, InputAdornment } from '@mui/material';

interface Props {
  label: string;
  icon?: React.ReactNode;
}

const BasicAnswerInput = ({ label, icon }: Props) => {
  return (
    <TextField
      disabled
      sx={{
        width: '65%',
        '& .MuiInputLabel-root': {
          fontSize: '0.8rem',
          color: 'text.secondary',
        },
        mt: 1,
      }}
      variant="standard"
      defaultValue={label}
      size="small"
      InputProps={{
        startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
      }}
    />
  );
};

export default BasicAnswerInput;
