import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { BasicInputContainer } from '../../layout';
import { TextField } from '@mui/material';
// Types
import { UseFieldType } from 'hooks/useField';
// Context
import { ActivitiesCardContext } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/context/ActivitiesCardContext';

type TitleTextFieldProps = {
  titleField: UseFieldType;
};

const TitleTextField = ({ titleField }: TitleTextFieldProps) => {
  const { isEditing, showDetails } = useContext(ActivitiesCardContext);
  const { t } = useTranslation();
  return (
    <BasicInputContainer name={t('global.title')}>
      <TextField
        disabled={!isEditing && showDetails}
        autoFocus
        type="text"
        fullWidth
        variant="outlined"
        size="small"
        {...titleField.attributes}
      />
    </BasicInputContainer>
  );
};

export default TitleTextField;
