import { useEffect, useState, useContext, useMemo } from "react";
// Moment
import moment from "moment";
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import {
  Box,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material"
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
// Selector
import { dashboardSubmittedForms, dashboardSubmittedFormsStatus } from "redux/features/dashboardSlice/dashboardSlice";
// Components
import Card from "../Card";
import { Tags } from "./components";
// Types
import { DashBoardFormsSubmittedFormRecord, DashBoardFormsSubmittedFormsInfo } from "@trii/types/dist/Conversations";

const SubmittedForms = () => {
  const {
    form,
    formIndex,
    setCompletedForms
  } = useContext(DashboardContext);
  const { t } = useTranslation();
  const newSubmittedForms = useSelector(dashboardSubmittedForms);
  const summaryStatus = useSelector(dashboardSubmittedFormsStatus);
  const isLoading = summaryStatus === "loading";
  const [submittedForms, setSubmittedForms] = useState<DashBoardFormsSubmittedFormsInfo>();
  const [formToRender, setFormToRender] = useState<DashBoardFormsSubmittedFormRecord>();

  useEffect(() => {
    if (newSubmittedForms) {
      setSubmittedForms(newSubmittedForms);
      setCompletedForms(newSubmittedForms.completedForms);
    }
  }, [newSubmittedForms])

  useEffect(() => {
    if (submittedForms) {
      setFormToRender(submittedForms.records[formIndex]);
      console.log('submittedForms', submittedForms.records[formIndex])
    }
  }, [formIndex, submittedForms])

  return useMemo(() => (
    <>
      {/* Render Labels */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Tags
          tags={formToRender?.tags}
          max={3}
        />
        <Chip
          label={moment(formToRender?.createdAt).format('DD/MM/YYYY HH:mm')}
          variant="filled"
          size="small"
          sx={{
            color: theme => theme.palette.text.primary,
            backgroundColor: 
          //@ts-ignore
          (theme) => theme.palette.background.dropdownMenu,
          }}
        />
      </Box>
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : (
          formToRender && formToRender?.responses.length > 0 && formToRender.responses.map((item) => (
            <Card
              key={item.questionId}
              title={t('dashboard.forms.submittedForms.question', { question: item.question })}
              subTitle={t('dashboard.forms.submittedForms.response', { response: item.response })}
              isQuestion={true}
              questionType={item.type}
              color={form?.form?.color}
            />
          ))
        )
      }
    </>
  ), [formIndex, formToRender, form])
}

export default SubmittedForms