import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography } from '@mui/material';

const Subject = () => {
  const { selectNode, setSendEmailNode, sendEmailNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [subject, setSubject] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.sendEmail) {
      setSubject(
        selectNode.data.sendEmail.subject ? selectNode.data.sendEmail.subject : ''
      );
    }
  }, [selectNode]);

  const handleChangeConnection = (subject: string) => {
    setSubject(subject);
    setSendEmailNode({
      ...sendEmailNode,
      subject,
    });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.sendEmail.subject')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={subject}
        onChange={(e) => handleChangeConnection(e.target.value)}
      />
    </>
  );
};

export default Subject;
