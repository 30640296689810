import { useContext } from "react"
// Transition
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Button } from "@mui/material"
// Icons
import { AddBox } from "@mui/icons-material"
// Context
import { conversationsContext } from "features/Views/Conversations/context/ConversationsProvider/ConversationsProvider"

const AddFile = () => {
  const {
    handleUploadFile,
  } = useContext(conversationsContext)
  const { t } = useTranslation()

  const handleUploadClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.addEventListener('change', handleUploadFile.bind(this));
    inputElement.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Button
        startIcon={<AddBox />}
        variant="contained"
        size="small"
        sx={{
          display: 'flex',
          width: '100%',
          textTransform: 'none',
          fontSize: '12px',
          fontWeight: 'bold',
          animation: 'fadeIn 0.5s ease-in-out forwards',
        }}
        onClick={handleUploadClick}
      >
        {t('conversations.sidebar.files.add')}
      </Button>
    </Box>
  )
}

export default AddFile