import { NodeMsgWaitResponseConditionOperator } from "@trii/types/dist/Conversations/Flows/Nodes";

type ConditionSource = {
    id: number;
    label: string;
};

const conditionSource: ConditionSource[] = [
    {
        id: NodeMsgWaitResponseConditionOperator.NULL,
        label: "editFlowsView.waitResponse.null"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.LESS_THAN,
        label: "editFlowsView.waitResponse.lessThan"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.LESS_THAN_EQUAL,
        label: "editFlowsView.waitResponse.lessThanEqual"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.EQUAL,
        label: "editFlowsView.waitResponse.equal"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.GREATER_THAN_EQUAL,
        label: "editFlowsView.waitResponse.greaterThanEqual"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.GREATER_THAN,
        label: "editFlowsView.waitResponse.greaterThan"
    },  
    {
        id: NodeMsgWaitResponseConditionOperator.START_WITH,
        label: "editFlowsView.waitResponse.startWith"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.END_WITH,
        label: "editFlowsView.waitResponse.endWith"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.CONTAINS,
        label: "editFlowsView.waitResponse.contains"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.NOT_CONTAINS,
        label: "editFlowsView.waitResponse.notContains"
    },
    {
        id: NodeMsgWaitResponseConditionOperator.ANY_VALUE,
        label: "editFlowsView.waitResponse.anyValue"
    }
];

export default conditionSource;