import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { Branch } from "./components";
import { TemplatesContext } from "features/Views/Templates/context/TemplatesContext";
import { EditionType } from "features/Views/Templates/context/types/EditionType";
import { useTranslation } from "react-i18next";

const TreeCategories = () => {
  const { t } = useTranslation();
  const { setSelectedChannel, setSelectedTemplate, setEditionType, channelList, templateList } = useContext(TemplatesContext);
  useEffect(() => {
    console.log("Templates", templateList, channelList);
  }, [templateList, channelList]);
  const isLoading = false;


  const handleClick = (
    type: EditionType,
    channel,
    template
  ) => {
    if (channel) {
      setSelectedChannel(channel);
    } else {
      setSelectedChannel(null);
    }
    if (template) {
      setSelectedTemplate(template);
    } else {
      setSelectedTemplate(null);
    }
    setEditionType(type);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "inherit",
        color: (theme) => theme.palette.text.primary,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
          mb: 1,
          height: "3rem",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography>{t("templates.wspMsgTemplates")}</Typography>
      </Box>

      {isLoading ? (
        <CircularProgress
          size={24}
          sx={{
            margin: "auto",
          }}
        />
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          sx={{
            width: "100%",
            overflowY: "auto",
          }}
        >
          {channelList.map((channel) => (
            <TreeItem
              key={channel.id}
              nodeId={channel.id}
              label={
                <Branch
                  title={channel.title}
                />
              }
              onClick={() => handleClick("channel", channel, null)}
            >
              {templateList.map((template) => (
                <TreeItem
                  key={template.id}
                  nodeId={template.id}
                  label={
                    <Branch
                      title={template.title}
                    />
                  }
                  onClick={() => handleClick("template", null, template)}
                />
              ))}
            </TreeItem>
          ))}
        </TreeView>
      )}
    </Box>
  );
};

export default TreeCategories;
