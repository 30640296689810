// Provider
import { TemplatesProvider } from "./context/TemplatesProvider"
// Components
import { Templates } from "./components"

const TemplatesContainer = () => {
  return (
    <TemplatesProvider>
      <Templates />
    </TemplatesProvider>
  )
}

export default TemplatesContainer