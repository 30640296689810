import useField from 'hooks/useField';
import useSelect from 'hooks/useSelect';
// Types
import { UseCreateWebHook } from './types';
import { IWebhook } from '@trii/types/dist/Conversations';
import { 
  Method,
  AuthType,
  AuthApiKey,
  AuthBasic,
  AuthBearer,
  
} from '@trii/types/dist/Common/API';
import { NewWebHook } from 'redux/features/webHooksSlice/types/NewWebHook';

const useCreateWebHook = (): UseCreateWebHook => {
  // General section
  const nameField = useField('text');
  // Api section
  const methodSelect = useSelect(Method.GET);
  const urlField = useField('text');
  const authTypeSelect = useSelect(AuthType.NONE);
  //  "Basic" security inputs
  const usernameField = useField('text');
  const passwordField = useField('text');
  //  "Bearer Token" security inputs
  const fixedTokenField = useField('text');
  const dynamicUrlField = useField('text');
  const bearerTokenBodyField = useField('text');
  //  "API Key" security inputs
  const apiKeyField = useField('text');
  const apiValueField = useField('text');
  //  "Params"
  const paramsBodyField = useField('text');
  const paramsContetTypeField = useField('text');

  // Getters
  const getApiMethod = (): Method => {
    return Method[methodSelect.attributes.value];
  };
  const getAuthType = (): AuthType => {
    return authTypeSelect.attributes.value as AuthType;
  };
  const getApiAuthBasic = (): AuthBasic | null => {
    return getAuthType() === AuthType.BASIC
      ? {
          username: usernameField.attributes.value,
          password: passwordField.attributes.value,
        }
      : null;
  };
  const getApiAuthApiKey = (): AuthApiKey | null => {
    return getAuthType() === AuthType.APIKEY
      ? {
          headers: {
            [apiKeyField.attributes.value]: apiValueField.attributes.value,
          },
        }
      : null;
  };
  const getApiAuthBearer = (): AuthBearer | null => {
    return getAuthType() === AuthType.BEARER
      ? {
          fixToken: fixedTokenField.attributes.value,
          dynamicUrl: dynamicUrlField.attributes.value,
          dynamicBody: bearerTokenBodyField.attributes.value,
        }
      : null;
  };
  //

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    urlField.actions.resetValue();
    methodSelect.actions.resetValue();
    authTypeSelect.actions.resetValue();
    usernameField.actions.resetValue();
    passwordField.actions.resetValue();
    fixedTokenField.actions.resetValue();
    dynamicUrlField.actions.resetValue();
    bearerTokenBodyField.actions.resetValue();
    apiKeyField.actions.resetValue();
    apiValueField.actions.resetValue();
    paramsBodyField.actions.resetValue();
    paramsContetTypeField.actions.resetValue();
  };
  const getNewWebHook = (): NewWebHook => {
    const apiMethod = getApiMethod();
    const apiAuthType = getAuthType();
    const apiAuthBasic = getApiAuthBasic();
    const apiAuthApiKey = getApiAuthApiKey();
    const apiAuthBearer = getApiAuthBearer();

    return {
      name: nameField.attributes.value,
      apiMethod,
      apiUrl: urlField.attributes.value,
      apiAuthType,
      apiAuthBasic,
      apiAuthApiKey,
      apiAuthBearer,
      apiBody: paramsBodyField.attributes.value,
      apiContentType: paramsContetTypeField.attributes.value,
    };
  };
  const setToBeEditedWebHook = (webhook: IWebhook) => {
    const apiKey = webhook.apiAuthApiKey
      ? Object.keys(webhook.apiAuthApiKey?.headers)[0]
      : '';
    const apiValue = webhook.apiAuthApiKey
      ? webhook.apiAuthApiKey?.headers[apiKey]
      : '';

    nameField.actions.changeValue(webhook.name ? webhook.name : '');
    urlField.actions.changeValue(webhook.apiUrl ? webhook.apiUrl : '');
    methodSelect.actions.changeValue(
      webhook.apiMethod ? webhook.apiMethod : Method.GET
    );
    authTypeSelect.actions.changeValue(
      webhook.apiAuthType ? webhook.apiAuthType : AuthType.NONE
    );
    usernameField.actions.changeValue(
      webhook.apiAuthBasic ? webhook.apiAuthBasic.username : ''
    );
    passwordField.actions.changeValue(
      webhook.apiAuthBasic ? webhook.apiAuthBasic.password : ''
    );
    fixedTokenField.actions.changeValue(
      webhook.apiAuthBearer ? webhook.apiAuthBearer.fixToken : ''
    );
    dynamicUrlField.actions.changeValue(
      webhook.apiAuthBearer ? webhook.apiAuthBearer.dynamicUrl : ''
    );
    bearerTokenBodyField.actions.changeValue(
      webhook.apiAuthBearer ? webhook.apiAuthBearer.dynamicBody : ''
    );
    apiKeyField.actions.changeValue(apiKey);
    apiValueField.actions.changeValue(apiValue);
    paramsBodyField.actions.changeValue(webhook.apiBody ? webhook.apiBody : '');
    paramsContetTypeField.actions.changeValue(
      webhook.apiContentType ? webhook.apiContentType : ''
    );
  };
  //

  return {
    action: {
      resetAll,
      getNewWebHook,
      setToBeEditedWebHook,
    },
    input: {
      nameField,
      urlField,
      methodSelect,
      authTypeSelect,
      usernameField,
      passwordField,
      fixedTokenField,
      dynamicUrlField,
      bearerTokenBodyField,
      apiKeyField,
      apiValueField,
      paramsBodyField,
      paramsContetTypeField,
    },
  };
};

export default useCreateWebHook;
