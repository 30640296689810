export const marks = [
  {
    value: 0,
    label: '00:00',
  },
  {
    value: 3600,
    label: '01:00',
  },
  {
    value: 7200,
    label: '02:00',
  },
  {
    value: 10800,
    label: '03:00',
  },
  {
    value: 14400,
    label: '04:00',
  },
  {
    value: 18000,
    label: '05:00',
  },
  {
    value: 21600,
    label: '06:00',
  },
  {
    value: 25200,
    label: '07:00',
  },
  {
    value: 28800,
    label: '08:00',
  },
  {
    value: 32400,
    label: '09:00',
  },
  {
    value: 36000,
    label: '10:00',
  },
  {
    value: 39600,
    label: '11:00',
  },
  {
    value: 43200,
    label: '12:00',
  },
  {
    value: 46800,
    label: '13:00',
  },
  {
    value: 50400,
    label: '14:00',
  },
  {
    value: 54000,
    label: '15:00',
  },
  {
    value: 57600,
    label: '16:00',
  },
  {
    value: 61200,
    label: '17:00',
  },
  {
    value: 64800,
    label: '18:00',
  },
  {
    value: 68400,
    label: '19:00',
  },
  {
    value: 72000,
    label: '20:00',
  },
  {
    value: 75600,
    label: '21:00',
  },
  {
    value: 79200,
    label: '22:00',
  },
  {
    value: 82800,
    label: '23:00',
  },
  {
    value: 86400,
    label: '24:00',
  },
]