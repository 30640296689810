import { useEffect, useState } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setAccountData } from 'redux/features/googleBusinessSlice/googleBusinessSlice';
import { connectGoogle, fetchEmail } from 'redux/features/emailEditSlice/emailEditSlice';
// Types
import { IGoogleAccountData } from 'redux/features/googleBusinessSlice/types/IGoogleAccountData';
import { GoogleAccountInfo } from '@trii/types/dist/Contacts';
// Components/ui
import { Fade } from '@mui/material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import GoogleIcon from './google-icon.png';
import { useParams } from 'react-router-dom';
import { fetchGoogleBusiness } from 'redux/features/googleBusinessEditSlice/googleBusinessEditSlice';

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4285F4',
  color: '#fff',
  textTransform: 'none',
  fontSize: 15,
  fontWeight: 'bold',
  padding: '2px 10px 2px 2px',
  border: 'none',
  borderRadius: 3,
  boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
  '&:hover': {
    backgroundColor: '#357ae8',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 3,
  padding: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function GoogleLogInButton() {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams(); // Obtén el channelId desde la URL

  const CLIENT_ID =
    '243763149644-8s1804pp8594vcpntn44pe0862vpm5bm.apps.googleusercontent.com';

  async function handleCallback(response: any) {
    const code = response.code;
    console.log('Authorization code: ', code);

    // Simula la obtención de datos del usuario (puedes ajustar esto según tus necesidades)
    const googleAccount: GoogleAccountInfo = {
      name: '', 
      imageUrl: '', 
      email: '', 
    };

    const googleAccountData: IGoogleAccountData = {
      googleAccount,
      code: code, // Asegúrate de pasar el código aquí
    };

    // Dispara la acción para conectar Google usando el código y el channelId
    await dispatch(connectGoogle({ channelId: id, data: googleAccountData })); 
  }

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      const google: any = window.google;
      const client = google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: 'email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send',
        ux_mode: 'popup',
        callback: handleCallback,
      });

      document.getElementById('google-button').addEventListener('click', () => {
        client.requestCode();
      });
    };

    if (window.google) {
      initializeGoogleSignIn();
      setShow(true);
    } else {
      window.addEventListener('google-loaded', initializeGoogleSignIn);
    }

    return () => {
      window.removeEventListener('google-loaded', initializeGoogleSignIn);
    };
  }, [window.google]);

  return (
    <Fade in={show}>
      <GoogleButton id="google-button">
        <IconWrapper>
          <img src={GoogleIcon} alt="Google logo" width="24" height="24" />
        </IconWrapper>
        Sign in with Google
      </GoogleButton>
    </Fade>
  );
}

export default GoogleLogInButton;
