import React, { useContext, useState, useEffect } from 'react';
// Dispatch
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
// Context
import { InitEditFlowsContext } from '../../../../../../../../context/EditFlowsContext';
import { EditFlowsContext } from '../../../../../../../../context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Service
import { fetchForms } from '../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';
// components/ui
import { TextField, Divider, FormControlLabel, Checkbox, Box } from '@mui/material';
//Components
import { ActionButtons, Forms } from './components';

const EditFillFormNode = () => {
  const { fillFormNode, setFillFormNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [enableStatics, setEnableStatics] = useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  useEffect(() => {
    if (selectNode && selectNode.data.fillForm) {
      const fillForm = selectNode && selectNode?.data && selectNode?.data?.fillForm;
      const defaultId = fillForm.id
        ? fillForm?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = fillForm.name ? fillForm?.name : ('' as string);
      const defaultEnableStatics = fillForm.enableStatics
        ? fillForm?.enableStatics
        : (false as boolean);
      setId(defaultId);
      setName(defaultName);
      setEnableStatics(defaultEnableStatics);
    }
  }, [selectNode]);

  const handleChangeName = (name: string) => {
    setName(name);
    setFillFormNode({
      ...fillFormNode,
      id,
      name,
    });
  };

  const handleChangeEnableStatics = (enableStatics: boolean) => {
    setEnableStatics(enableStatics);
    setFillFormNode({
      ...fillFormNode,
      enableStatics,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStatics}
            onChange={(e) => handleChangeEnableStatics(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.enableStatics')}
      />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Forms />
      <ActionButtons />
    </Box>
  );
};

export default EditFillFormNode;
