import { useEffect, useState, useContext } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import {
  Box,
  CircularProgress,
  Typography,
} from "@mui/material"
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
// Selector
import { dashboardFormSummary, dashboardFormSummaryStatus } from "redux/features/dashboardSlice/dashboardSlice";
// Components
import Card from "../Card";
// Types
import { DashBoardFormsSummaryInfo } from "@trii/types/dist/Conversations";

const Symmary = () => {
  const { form } = useContext(DashboardContext);
  const { t } = useTranslation();
  const newSummary = useSelector(dashboardFormSummary);
  const summaryStatus = useSelector(dashboardFormSummaryStatus);
  const isLoading = summaryStatus === "loading";
  const [summary, setSummary] = useState<DashBoardFormsSummaryInfo>();

  useEffect(() => {
    if (newSummary) {
      setSummary(newSummary);
    }
  }, [newSummary])

  return (
    <>
      <Typography
        sx={{
          color: theme => theme.palette.text.primary,
          width: "100%",
        }}
        variant="body1"
      >
        {t('dashboard.forms.summary.responses', { responses: summary?.completedForms || 0 })}
      </Typography>
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : (
          summary && summary.records && summary.records.length > 0 && summary.records.map((item) => (
            <Card
              key={item.questionId}
              title={t('dashboard.forms.summary.question', { question: item.question })}
              subTitle={t('dashboard.forms.summary.responses', { responses: item.count })}
              questionType={item.type}
              list={item.responses}
              color={form?.form?.color}
              showGraphic={true}
            />
          ))
        )
      }
    </>
  )
}

export default Symmary