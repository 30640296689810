import { socketContext } from 'context/WebSocketProvider/SocketProvider';
import { useContext, useEffect, useState } from 'react';

export interface UseDateTime {
  getDateTime: () => Date;
}

export const useDateTimeInitialState: UseDateTime = {
  getDateTime: () => new Date(),
};

const useDateTime = (): UseDateTime => {
  const [serverTime, setServerTime] = useState<Date | null>(null);
  const [timeOffset, setTimeOffset] = useState<number | null>(null);

  const { subscribeEvent, socketConnection } = useContext(socketContext);

  function getDateTime(): Date {
    if (timeOffset !== null) {
      const localTime = new Date();
      const correctedTime = new Date(localTime.getTime() - timeOffset);

      return correctedTime;
    }

    return new Date();
  }

  useEffect(() => {
    if (socketConnection) {
      subscribeEvent('datetime', (data: { datetime: number }) => {
        if (data.datetime) {
          const serverDateTime = new Date(data.datetime);
          setServerTime(serverDateTime);
          const localDateTime = new Date();
          const offset = localDateTime.getTime() - serverDateTime.getTime();
          setTimeOffset(offset);
        }
      });
    }
  }, [socketConnection]);

  return {
    getDateTime,
  };
};

export default useDateTime;
