import { useContext, useEffect, useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Box,
  Button,
  Chip,
  FormControl,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
// Icons
import { CalendarMonth } from "@mui/icons-material";
// Dayjs
import dayjs, { Dayjs } from "dayjs";
// Context
import { DashboardContext } from "../../../../context/DashboardContext";
// Utils
import { periodList } from "./utils/periodList";
// Hooks
import { useDispatch } from "react-redux";
// Slice
import { setDateOption, setSelectedDate } from "redux/features/dashboardSlice/dashboardSlice";
// Components
import { CustomDateRange } from "components";
// Types
import { TimeRange } from "@trii/types/dist/Conversations";

interface SummaryData {
  timeRange: TimeRange;
  timeStart: Date;
  timeEnd: Date;
}

interface Props {
  getSummarySelectedData: (data: SummaryData) => void;
  dashboardType: string

}

const Period = ({ getSummarySelectedData, dashboardType }: Props) => {
  const dispatch = useDispatch();
  const defaultTitle = periodList.find((item) => item.value === "month")?.label || "";
  const { setUserDashboard } = useContext(DashboardContext);
  const [startRangeDate, setStartRangeDate] = useState<Date>(new Date());
  const [endRangeDate, setEndRangeDate] = useState<Date>(new Date());
  const [timeRange, setTimeRange] = useState<TimeRange>(TimeRange.MONTH);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  // const [group, setGroup] = useState(() => localStorage.getItem('selectedPeriodDashboard') || "month");
  const [group, setGroup] = useState(() => localStorage.getItem(`${dashboardType}-selectedPeriod`) || "month");
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState(defaultTitle);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChange = async (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setGroup(selectedValue);

    let newTimeRange = timeRange;
    let newStartRangeDate = startRangeDate;
    let newEndRangeDate = endRangeDate;

    if (selectedValue !== "custom") {
      const currentDate = dayjs();
      switch (selectedValue) {
        case "day":
          newTimeRange = TimeRange.DAY;
          newStartRangeDate = currentDate.toDate();
          newEndRangeDate = currentDate.toDate();
          break;
        case "week":
          const dayOfWeek = currentDate.day();
          const startOfWeek = currentDate.subtract(dayOfWeek === 0 ? 6 : dayOfWeek - 1, "day");
          newTimeRange = TimeRange.WEEK;
          newStartRangeDate = startOfWeek.toDate();
          newEndRangeDate = currentDate.toDate();
          break;
        case "month":
          newTimeRange = TimeRange.MONTH;
          newStartRangeDate = currentDate.startOf("month").toDate();
          newEndRangeDate = currentDate.endOf("month").toDate();
          break;
        case "year":
          newTimeRange = TimeRange.YEAR;
          newStartRangeDate = currentDate.startOf("year").toDate();
          newEndRangeDate = currentDate.toDate();
          break;
        case "last7days":
          newTimeRange = TimeRange.LAST_7_DAYS;
          newStartRangeDate = currentDate.subtract(7, "days").toDate();
          newEndRangeDate = currentDate.toDate();
          break;
        case "last30days":
          newTimeRange = TimeRange.LAST_30_DAYS;
          newStartRangeDate = currentDate.subtract(30, "days").toDate();
          newEndRangeDate = currentDate.toDate();
          break;
        case "last60days":
          newTimeRange = TimeRange.LAST_60_DAYS;
          newStartRangeDate = currentDate.subtract(60, "days").toDate();
          newEndRangeDate = currentDate.toDate();
          break;
        default:
          break;
      }

      setStartRangeDate(newStartRangeDate);
      setEndRangeDate(newEndRangeDate);

      await getSummarySelectedData({
        timeRange: newTimeRange,
        timeStart: newStartRangeDate,
        timeEnd: newEndRangeDate,
      });

      localStorage.setItem(`${dashboardType}-selectedPeriod`, selectedValue);
      handleClose();
    } else {
      localStorage.setItem(`${dashboardType}-selectedPeriod`, "custom");
    }
  };

  const ApplyCustomDate = async () => {
    const start = startDate.toDate();
    const end = endDate.toDate();
  
    // Guardar las fechas en localStorage
    localStorage.setItem(`${dashboardType}-customStartDate`, start.toString());
    localStorage.setItem(`${dashboardType}-customEndDate`, end.toString());
  
    setStartRangeDate(start);
    setEndRangeDate(end);
    setTimeRange(TimeRange.CUSTOM);
    dispatch(setSelectedDate([start, end]));
    dispatch(setDateOption(0));
    localStorage.setItem(`${dashboardType}-selectedPeriod`, "custom");
  
    await getSummarySelectedData({
      timeRange: TimeRange.CUSTOM,
      timeStart: start,
      timeEnd: end,
    });
  
    handleClose();
  };
  
  useEffect(() => {
    const savedStartDate = localStorage.getItem(`${dashboardType}-customStartDate`);
    const savedEndDate = localStorage.getItem(`${dashboardType}-customEndDate`);
  
    if (savedStartDate && savedEndDate) {
      setStartDate(dayjs(new Date(savedStartDate)));
      setEndDate(dayjs(new Date(savedEndDate)));
    }
  }, [dashboardType]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    const newTitle = periodList.find(item => item.value === group)?.label || "";
    setTitle(newTitle);
  }, [group]);

  useEffect(() => {
    const storedGroup = localStorage.getItem(`${dashboardType}-selectedPeriod`) || "month";
    setGroup(storedGroup);
  }, [dashboardType]);

  useEffect(() => {
    const fetchMessages = async () => {
      await getSummarySelectedData({
        timeRange: timeRange,
        timeStart: startRangeDate,
        timeEnd: endRangeDate,
      });
    };
    fetchMessages();
  }, []);
  


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Chip
        label={`: ${t(title)}`}
        icon={<CalendarMonth />}
        variant="outlined"
        onClick={handleClick}
        sx={{ zIndex: 0, cursor: "pointer" }}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disablePortal={false}
        sx={{
          mt: "5px"
        }}
      >
        <Box
          sx={{
            p: 2,
            pt: 1,
            mt: 0,
            boxShadow: "rgb(0 0 0 / 25%) 0px 1px 6px 0px !important",
            width: 200,

            backgroundColor:
            //@ts-ignore
              (theme) => theme.palette.background.panel,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {/* title */}
            <Typography sx={{ color: "text.primary" }}>
              {t("global.period")}:
            </Typography>
            {/* select */}
            <FormControl size="small" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={group}
                label="Periodo"
                onChange={handleChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                {periodList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {group === "custom" ? (
              <Box sx={{ width: "100%" }}>
                <CustomDateRange
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 1, float: "inline-end" }}
                  onClick={ApplyCustomDate}
                >
                  {t('dashboard.apply')}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Period;
