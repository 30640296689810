import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { GroupContext } from 'features/Views/Groups/context/types';
// Components/ui
import { Box, TextField } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { editGroupNameField, editGroupDescriptionField } =
    useContext<GroupContext>(InitGroupContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('settingsView.groups.groupName')}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          marginBottom: '15px',
        }}
        {...editGroupNameField.attributes}
      />
      <TextField
        id="outlined-multiline-static"
        multiline
        label={t('settingsView.groups.groupDescription')}
        rows={4}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...editGroupDescriptionField.attributes}
      />
    </Box>
  );
};

export default Inputs;
