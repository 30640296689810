import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectActivitiesAddStatus,
  selectActivitiesUpdateStatus,
  selectActivitiesUpdateEndingStatus,
} from 'redux/features/activitiesSlice/utils/selectors';
// Components/ui
import { DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Icons
import { EditCalendar, EventAvailable } from '@mui/icons-material';
// Context
import { ActivitiesCardContext } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/context/ActivitiesCardContext';

type ActionButtonsProps = {
  isFinalized: boolean;
};
const ActionButtons = ({
  isFinalized,
}: ActionButtonsProps) => {
  const {
    closeActivityPopUp,
    handleCreateActivity,
    handleUpdateActivity,
    handleEndActivity,
    isEditing,
    setIsEditing,
    showDetails,
    selectedActivity,
  } = useContext(ActivitiesCardContext);
  const { t } = useTranslation();
  const activitiesAddStatus = useSelector(selectActivitiesAddStatus);
  const activitiesUpdateStatus = useSelector(selectActivitiesUpdateStatus);
  const activitiesUpdateEndingStatus = useSelector(selectActivitiesUpdateEndingStatus);
  const isLoadingAdd = activitiesAddStatus === 'loading';
  const isLoadingUpdate = activitiesUpdateStatus === 'loading';
  const isLoadingUpdateEnding = activitiesUpdateEndingStatus.includes(selectedActivity?.id);
  const handleEditing = () => {
    setIsEditing(true);
  }
  const onClick = (showDetails && !isEditing && handleEditing) ||
    (isEditing && showDetails && handleUpdateActivity) ||
    (!isEditing && !showDetails && handleCreateActivity);

  const ButtonText = (showDetails && !isEditing && t('conversations.sidebar.events.editEvent')) ||
    (isEditing && showDetails && t('conversations.sidebar.events.updateEvent')) ||
    (!isEditing && !showDetails && t('conversations.sidebar.events.createEvent'));

  return (
    <DialogActions>
      <Button variant="outlined" size="small" onClick={closeActivityPopUp}>
        {t('global.cancel')}
      </Button>
      {
        (showDetails || isEditing) && (
          <LoadingButton
            startIcon={<EventAvailable />}
            size="small"
            disabled={isLoadingUpdateEnding || isFinalized}
            onClick={() => handleEndActivity(selectedActivity.id)}
            variant="contained"
          >
            {t('conversations.sidebar.events.finalizeEvent')}
          </LoadingButton>
        )
      }
      <LoadingButton
        startIcon={<EditCalendar />}
        size="small"
        loading={isLoadingAdd || isLoadingUpdate}
        disabled={isLoadingAdd || isLoadingUpdate || isFinalized}
        onClick={onClick}
        variant="contained"
      >
        {ButtonText}
      </LoadingButton>
    </DialogActions>
  );
};

export default ActionButtons;
