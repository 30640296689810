// Translations
import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
  useTheme,
} from "@mui/material";
// Types
import { ChannelType } from "@trii/types/dist/Common/Channels";

interface ButtonProps {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
  channelSelected: ChannelType;
  type: ChannelType;
}

const Button = ({
  title,
  icon,
  onClick,
  channelSelected,
  type,
}: ButtonProps) => {
  const { t } = useTranslation();
  const theme = useTheme()

  return (
    <MuiButton
      variant={channelSelected === type ? "contained" : "outlined"}
      startIcon={icon}
      sx={{
        minWidth: "max-content",
        height: 30,
        "& span svg": {
          color:
            channelSelected === type
              ? theme.palette.text.disabled
              : theme.palette.primary.main,
        },
      }}
      onClick={onClick}
    >
      {t(title)}
    </MuiButton>
  );
}

export default Button;