import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

export const icons = [
  {
    icon: MoreVertOutlinedIcon,
    handler: 'more',
  },
  {
    icon: DoDisturbAltOutlinedIcon,
    handler: 'block',
  },
];
