import React, { useContext } from 'react';
import { endingContext } from 'features/Views/Ending/context/EndingProvider';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectEndingUpdateStatus } from 'redux/features/endingSlice/endingSlice';
// Components/ui
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';

function EditButtons() {
  const { t } = useTranslation();
  const { endEditing, handleEditEnding } = useContext(endingContext);
  const webHookEditStatus = useSelector(selectEndingUpdateStatus);
  const isLoading = webHookEditStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
      height='4rem'
      width="100%"
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endEditing}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        startIcon={<SaveAsIcon />}
        loading={isLoading}
        disabled={isLoading}
        variant="contained"
        size="small"
        onClick={handleEditEnding}
     
      >
        {t('global.save')}
      </LoadingButton>
    </Box>
  );
}

export default EditButtons;
