import React from 'react';
// Redux
import { useDispatch } from 'react-redux';
import {
  changeNotimationServiceId,
  changeNotimationToken,
} from 'redux/features/smsEditSlice/smsEditSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';

interface Props {
  data: {
    notimationToken: string;
    notimationServiceId: string;
  };
}

const NotimationInputs = ({ data }: Props) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { notimationToken, notimationServiceId } = data;

  const handleNotimationTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeNotimationToken(event.target.value));
  };

  const handleNotimationServiceIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeNotimationServiceId(event.target.value));
  };

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label="Token"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={notimationToken}
        onChange={handleNotimationTokenChange}
      />
      <TextField
        size="small"
        label="ServiceId"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={notimationServiceId}
        onChange={handleNotimationServiceIdChange}
      />
    </ChannelInputsContainer>
  );
};

export default NotimationInputs;
