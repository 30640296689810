import React, { useContext } from 'react';
import { webHooksContext } from '../../context/WebHooksProvider';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components';

function CrudPanel() {
  const { crudPanelState } = useContext(webHooksContext);

  return (
    <>
      {crudPanelState === 'create' && <CreatePanel />}
      {crudPanelState === 'delete' && <DeletePanel />}
      {crudPanelState === 'edit' && <EditPanel />}
    </>
  );
}

export default CrudPanel;
