import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";

// import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import EmojiPicker from "emoji-picker-react";
import $ from "jquery";
// switch
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
//lexical import
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";

import { $getRoot, $getSelection } from "lexical";

import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { AutoLinkNode, LinkNode } from "@lexical/link";
import { useDispatch, useSelector } from "react-redux";
import {
  switchAutoResponseBusinessHours,
  inputAutoResponseBusinessHoursMessage,
  switchAutoResponseHoliday,
  inputAutoResponseHolidayMessage,
  switchAutoResponseOutOfBusinessHours,
  inputAutoResponseOutOfBusinessHoursMessage,
  responseTitle,
  updateResponses,
  responseId,
  setSwitchBusinessHours,
  setSwitchOutOfBusinessHours,
  setSwitchHolidaysHours,
  setInputAutoResponseBusinessHoursMessage,
  setInputAutoResponseOutOfBusinessHoursMessage,
  setInputAutoResponseHolidayMessage,
  responseSelected,
  getResponses,
  responseGetStatus,
  responseUpdateStatus,
} from "redux/features/responsesSlice/responsesSlice";
import Spinner from "components/Spinners/Spinner";
import { LoadingButton } from "@mui/lab";
import Loading2 from "components/Spinners/Spinner2";
import SpinnerTransparent from "components/Spinners/SpinnerTransparent";
window.jQuery = $;
require("emojionearea");
const DetailsMenu = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showEmojis, setShowEmojis] = React.useState(false);
  const [saveEmoji, setSaveEmoji] = React.useState("");

  const responseStatus = useSelector(responseGetStatus);
  const responseUpdatedStatus = useSelector(responseUpdateStatus);

  // Valores de mensajes en inputs
  const BusinessHoursMessage = useSelector(
    inputAutoResponseBusinessHoursMessage
  );
  const OutOfBusinessHoursMessage = useSelector(
    inputAutoResponseOutOfBusinessHoursMessage
  );
  const HolidaysHoursMessage = useSelector(inputAutoResponseHolidayMessage);

  // Valores de switch
  const BusinessHoursSwitch = useSelector(switchAutoResponseBusinessHours);
  const OutOfBusinessHoursSwitch = useSelector(
    switchAutoResponseOutOfBusinessHours
  );
  const HolidaySwitch = useSelector(switchAutoResponseHoliday);

  const titleResponse = useSelector(responseTitle);
  console.log(titleResponse)

  const idResponse = useSelector(responseId);
  // Canal seleccionado
  const channelRes = useSelector(responseSelected);

  const [boolBusinessHour, setBoolBusinessHour] = React.useState(false);
  const [boolOutOfBusinessHour, setBoolOutOfBusinessHour] =
    React.useState(false);
  const [boolHoliday, setBoolHoliday] = React.useState(false);

  // Valores al cargar
  const [inputOnTimeValue, setInputOnTimeValue] = useState("");
  const [inputOutTimeValue, setInputOutTimeValue] = useState("");
  const [inputHolidayValue, setInputHolidayValue] = useState("");

  useEffect(() => {
    setBoolBusinessHour(BusinessHoursSwitch);
    setBoolOutOfBusinessHour(OutOfBusinessHoursSwitch);
    setBoolHoliday(HolidaySwitch);

    setInputOnTimeValue(BusinessHoursMessage);
    setInputOutTimeValue(OutOfBusinessHoursMessage);
    setInputHolidayValue(HolidaysHoursMessage);
  }, [
    BusinessHoursSwitch,
    OutOfBusinessHoursSwitch,
    HolidaySwitch,
    BusinessHoursMessage,
    OutOfBusinessHoursMessage,
    HolidaysHoursMessage,
  ]);

  // Lexical config
  const editorConfig = {
    // The editor theme
    // Handling of errors during update
    theme: {
      root: "h-24",
      text: {
        bold: "font-semibold",
        underline: "underline",
        italic: "italic",
        strikethrough: "line-through",
        underlineStrikethrough: "underlined-line-through",
      },
    },
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };
  const handleShowEmojis = () => {
    // inputRef.current.focus();
    setShowEmojis(!showEmojis);
  };
  function onChange(editorState) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      const root = $getRoot();
      const selection = $getSelection();
    });
  }
  function MyCustomAutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
      // Focus the editor when the effect fires!
      editor.focus();
    }, [editor]);

    return null;
  }
  const PickEmoji = useCallback(({ unified, emoji }, e) => {
    // const emojiElem = `<img src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${unified}.png" alt="${names[0]}" class="__EmojiPicker__ epr-emoji-img" loading="eager" style="font-size: 32px; height: 32px; width: 32px;">`
    const emojiElem = emoji;
    setSaveEmoji(emojiElem);
  }, []);

  // MUI switch
  const SwitchResponse = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  //Manejador de switch "On/Off"
  const handleShowInputHour = () => {
    setBoolBusinessHour(!boolBusinessHour);
  };
  const handleShowInputOutHour = () => {
    setBoolOutOfBusinessHour(!boolOutOfBusinessHour);
  };
  const handleShowInputHoliday = () => {
    setBoolHoliday(!boolHoliday);
  };

  // Accion de guardar
  // const saveChangesResponse = useCallback(() => {
  const saveChangesResponse = () => {
    try {
      dispatch(
        updateResponses({
          id: idResponse,
          config: {
            autoResponseBusinessHours: boolBusinessHour,
            autoResponseOutOfBusinessHours: boolOutOfBusinessHour,
            autoResponseHoliday: boolHoliday,
            autoResponseBusinessHoursMessage: inputOnTimeValue,
            autoResponseOutOfBusinessHoursMessage: inputOutTimeValue,
            autoResponseHolidayMessage: inputHolidayValue,
          },
        })
      );
    } catch (err) {}
  }; //,[dispatch,idResponse, boolBusinessHour, boolOutOfBusinessHour, boolHoliday, inputOnTimeValue, inputOutTimeValue, inputHolidayValue]);

  // Onchanges de inputs
  const changeInputOnTimeValue = (event) => {
    setInputOnTimeValue(event.target.value);
  };
  const changeInputOutTimeValue = (event) => {
    setInputOutTimeValue(event.target.value);
  };
  const changeInputHolidayValue = (event) => {
    setInputHolidayValue(event.target.value);
  };
  
  return (
    <Box
      sx={{
        overflow: "auto",
        minWidth: "85%",
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        position: "relative",
      }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
    >
      {responseStatus === "loading" && (
        <SpinnerTransparent></SpinnerTransparent>
      )}
      <Box
        display={"flex"}
        flexDirection="column"
        alignItems={"start"}
        p={2}
        sx={{ height: "100%", position: "relative" }}
      >
        <Box display={"flex"}>
          <Typography
            fontWeight={"500"}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {t("responsesView.automaticMessages")}
          </Typography>
          <Box ml={2}></Box>
          <Typography color="text.primary">{titleResponse}</Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          py={2}
          pb={1}
          flexWrap={"wrap"}
          style={{ gap: 10 }}
          ml={"5%"}
          width={"90%"}
        >
          {/* Switch Business hour*/}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "40px",
            }}
          >
            <Typography color="text.primary">
              {t("responsesView.withinBusinessHours")}:
            </Typography>
            {idResponse !== "*" && (
              <FormControlLabel
                control={
                  <SwitchResponse
                    checked={boolBusinessHour}
                    onChange={handleShowInputHour}
                  />
                }
              />
            )}
          </Box>
          <Box display="flex" position="relative">
            {/* <LexicalComposer initialConfig={editorConfig}>
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable
                        className="editor-input"
                      />
                    }
                    // placeholder={<Placeholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <OnChangePlugin onChange={onChange} />
                  <MyCustomAutoFocusPlugin />
                </LexicalComposer> */}
            {/* TextArea Business hour*/}
            <TextField
              multiline
              fullWidth
              rows={4}
              value={inputOnTimeValue}
              onChange={changeInputOnTimeValue}
              placeholder={
                idResponse == "*" ? "" : t("responsesView.useMessageDefinedIn")
              }
              sx={{
                marginBottom: "15px",
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            />
            {/* <Box ml={1} mr={1} sx={{}} className="emojiIcon">
                  {" "}
                  <IconButton onClick={handleShowEmojis}>
                    <InsertEmoticonIcon></InsertEmoticonIcon>
                  </IconButton>
                </Box>
                <Box
                  sx={
                    !showEmojis
                      ? { display: "none" }
                      : {
                          display: "block",
                          position: "absolute",
                          bottom: "60px",
                          right: "0",
                        }
                  }
                >
                  <EmojiPicker onEmojiClick={PickEmoji}></EmojiPicker>
                </Box> */}
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          py={2}
          pb={1}
          flexWrap={"wrap"}
          style={{ gap: 10 }}
          ml={"5%"}
          width={"90%"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "40px",
            }}
          >
            {/* Switch out of business */}
            <Typography color="text.primary">
              {t("responsesView.outOfBusinessHours")}:
            </Typography>
            {idResponse !== "*" && (
              <FormControlLabel
                control={
                  <SwitchResponse
                    checked={boolOutOfBusinessHour}
                    onChange={handleShowInputOutHour}
                  />
                }
              />
            )}
          </Box>
          {/* TextArea out of business */}
          <Box display="flex" position="relative">
            {/* <LexicalComposer initialConfig={editorConfig}>
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable
                        className="editor-input"
                        // innerRef={inputRef}
                      />
                    }
                    // placeholder={<Placeholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                </LexicalComposer> */}
            <TextField
              multiline
              fullWidth
              rows={4}
              value={inputOutTimeValue}
              onChange={changeInputOutTimeValue}
              placeholder={
                idResponse == "*" ? "" : t("responsesView.useMessageDefinedIn")
              }
              sx={{
                marginBottom: "15px",
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            />
            {/* <Box ml={1} mr={1} sx={{}} className="emojiIcon">
                  {" "}
                  <IconButton>
                    <InsertEmoticonIcon></InsertEmoticonIcon>
                  </IconButton>
                </Box> */}
            {/* <Box
                  sx={
                    !showEmojis
                      ? { display: "none" }
                      : {
                          display: "block",
                          position: "absolute",
                          bottom: "60px",
                          right: "0",
                        }
                  }
                >
                  <EmojiPicker onEmojiClick={PickEmoji}></EmojiPicker>
                </Box> */}
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          py={2}
          pb={1}
          flexWrap={"wrap"}
          style={{ gap: 10 }}
          ml={"5%"}
          width={"90%"}
        >
          {/* Switch Holidays */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "40px",
            }}
          >
            <Typography color="text.primary">
              {t("responsesView.holidays")}:
            </Typography>
            {idResponse !== "*" && (
              <FormControlLabel
                control={
                  <SwitchResponse
                    checked={boolHoliday}
                    onChange={handleShowInputHoliday}
                  />
                }
              />
            )}
          </Box>
          {/* TextArea Holidays*/}
          <Box display="flex" position="relative">
            {/* <LexicalComposer initialConfig={editorConfig}>
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable
                        className="editor-input"
                        // innerRef={inputRef}
                      />
                    }
                    // placeholder={<Placeholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                </LexicalComposer> */}
            <TextField
              multiline
              fullWidth
              rows={4}
              value={inputHolidayValue}
              onChange={changeInputHolidayValue}
              placeholder={
                idResponse == "*" ? "" : t("responsesView.useMessageDefinedIn")
              }
              sx={{
                marginBottom: "15px",
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            />
            {/* <Box ml={1} mr={1} sx={{}} className="emojiIcon">
                  {" "}
                  <IconButton>
                    <InsertEmoticonIcon></InsertEmoticonIcon>
                  </IconButton>
                </Box> */}
            {/* <Box
                  sx={
                    !showEmojis
                      ? { display: "none" }
                      : {
                          display: "block",
                          position: "absolute",
                          bottom: "60px",
                          right: "0",
                        }
                  }
                >
                  <EmojiPicker onEmojiClick={PickEmoji}></EmojiPicker>
                </Box> */}
          </Box>
        </Box>
        {/* Button Save */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          py={2}
          pb={2}
          flexWrap={"wrap"}
          style={{ gap: 10 }}
          ml={"5%"}
          width={"90%"}
        >
          <LoadingButton
            disabled={responseUpdatedStatus === "loading"}
            loading={responseUpdatedStatus === "loading"}
            loadingPosition="start"
            startIcon={<SaveAsIcon />}
            variant="contained"
            onClick={saveChangesResponse}
          >
            {t("global.save")}
          </LoadingButton>
        </Box>
      </Box>
      {/* <Card sx={{ margin: 2 }}>
      </Card> */}
    </Box>
  );
};

export default DetailsMenu;
