import useField from 'hooks/useField';
// Types
import { UseCreateGoogleBusiness } from './types';
import { NewGoogleBusiness } from 'redux/features/googleBusinessSlice/types/NewGoogleBusiness';

const useCreateGoogleBusiness = (): UseCreateGoogleBusiness => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewGoogleBusiness = (): NewGoogleBusiness => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewGoogleBusiness,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateGoogleBusiness;
