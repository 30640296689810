import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// Selector
import { useSelector } from 'react-redux';
// Context
import { InitEditFlowsContext } from '../../../../../../../../../../context/EditFlowsContext';
import { EditFlowsContext } from '../../../../../../../../../../context/types';
// Slice
import {
  selectSearchBy,
  selectSearchByStatus,
} from '../../../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';

const FieldUpdate = () => {
  const { contactUpdateNode, setContactUpdateNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [contactFieldId, setContactFieldId] = useState<string>('');
  const contacts = useSelector(selectSearchBy);
  const editStatus = useSelector(selectSearchByStatus);
  const isLoading: boolean = editStatus === 'loading';
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.contactUpdate) {
      const contactUpdate = selectNode.data.contactUpdate;
      const defaultContactId =
        contactUpdate.contactFieldId && (contactUpdate.contactFieldId as string);
      setContactFieldId(defaultContactId);
    }
  }, [selectNode]);

  const handleChangeContact = (contactFieldId: string) => {
    setContactFieldId(contactFieldId);
    setContactUpdateNode({ ...contactUpdateNode, contactFieldId });
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="contact" shrink={true}>
          {t('editFlowsView.contactUpdate.updateField')}
        </InputLabel>
        <Select
          labelId="contact"
          id="demo-simple-select"
          value={contactFieldId}
          label={t('editFlowsView.contactUpdate.updateField')}
          onChange={(e) => handleChangeContact(e.target.value)}
          disabled={isLoading}
          sx={{
            '& legend': {
              maxWidth: 'fit-content',
              //   padding: '0 10px'
            },
            '& .MuiSvgIcon-root': {
              color: 'text.disabled',
            },
          }}
        >
          <MenuItem value={'FirstName'}>FirstName</MenuItem>
          <MenuItem value={'LastName'}>LastName</MenuItem>
          <MenuItem value={'Tags'}>Tags</MenuItem>
          {contacts &&
            contacts?.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default FieldUpdate;
