import { Box, Typography } from '@mui/material';
import React from 'react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';

const Flows = () => {
  const { t } = useTranslation();

  return (
    <Button>
      <NavLink to="/a/conversations/system/flows">
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'center'}
          padding={2}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <Box marginBottom={4}>
            <AccountTreeIcon sx={{ fontSize: '2.5rem' }} />
          </Box>
          <Typography variant={'body2'}>{t('settingsView.flows')}</Typography>
        </Box>
      </NavLink>
    </Button>
  );
};

export default Flows;
