import React, { useEffect } from 'react'

import {
  Card,
  Text,
  Title,
  Metric,
  Grid,
  Col
} from '@tremor/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const TimesCard = ({data, title}) => {
  const theme = useTheme();
  const cardStyle = {
    //@ts-ignore
    backgroundColor: theme.palette.background.panel,
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
  };
  const {t} = useTranslation()
  return (
    <Card
      className="p-4"
      style={cardStyle}
    >
      <Grid numCols={2} className="mt-3 gap-6">
        <Col numColSpan={1}>
          <Card className="max-w-md shadow-none p-4" style={{ 
            //@ts-ignore
            backgroundColor: theme.palette.background.panel}}>
          <div className="truncate" style={{ color: theme.palette.text.primary }}>
              <Text className="text-center">{t("dashboard.averageFirstAnswer")}</Text>
              <Metric className="mt-2 text-2xl">{data ? data.averageFirstResponseTime : 'N/A'}</Metric>
            </div>
          </Card>
        </Col>
        <Col numColSpan={1}>
          <Card className="max-w-md shadow-none p-4" style={{ 
            //@ts-ignore
            backgroundColor: theme.palette.background.panel}} >
          <div className="truncate" style={{ color: theme.palette.text.primary }}>            
              <Text className="text-center">{t("dashboard.averageCompletion")}</Text>
              <Metric className="mt-2 text-2xl">{data ? data.finalizedTime : 'N/A'}</Metric>
            </div>
          </Card>
        </Col>
      </Grid>
    </Card>
  );
};

export default TimesCard