import { useContext, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
// Components/ui
import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  Popper,
  Typography,
} from '@mui/material';
// Icons
import { Mood, Send } from '@mui/icons-material';
import { EmojiPopup, Input } from 'components';

interface Props {
  parentId: string;
}

const Reply = ({ parentId }: Props) => {
  const { postSelected, sendMessage, setIsNewMessage } =
    useContext(socialWallsContext);
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState<HTMLElement>(null);
  const [newEmoji, setNewEmoji] = useState<string>('');
  const open = Boolean(anchorEl);

  const handleClick =
    (setState: React.Dispatch<React.SetStateAction<HTMLElement>>) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setState(event.currentTarget);
    };

  const handleClose = () => {
    if (emojiAnchorEl) {
      setEmojiAnchorEl(null);
      return;
    }
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleEmojiSelect = (emoji) => {
    setNewEmoji(emoji.native);
    setEmojiAnchorEl(null);
  };

  const handleSendMessage = () => {
    if (value) {
      const data = {
        postId: postSelected.id,
        message: value,
        parentId,
      };
      sendMessage(data);
      setValue('');
      setNewEmoji('');
      setAnchorEl(null);
      setIsNewMessage(false);
    }
  };

  return (
    <>
      <Typography
        variant="caption"
        sx={{ cursor: 'pointer' }}
        onClick={handleClick(setAnchorEl)}
      >
        {t('socialWalls.reply')}
      </Typography>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        disablePortal
        sx={{
          zIndex: 1,
          maxHeight: 'max-content',
          width: '30vw',
          overflow: 'hidden',
          gap: 1,
          backgroundColor: 'background.default',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: '0.5rem',
          p: 1,
          mt: 1,
          boxShadow: (theme) => theme.shadows[5],
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={value}
                    setValue={setValue}
                    newEmoji={newEmoji}
                    setNewEmoji={setNewEmoji}
                    handleSendMessage={() => {}}
                    conversationId=""
                    contactId=""
                    disableEnterKeyAction
                  />
                  <IconButton
                    onClick={handleClick(setEmojiAnchorEl)}
                    sx={{ color: 'text.disabled' }}
                  >
                    <Mood color={Boolean(emojiAnchorEl) ? 'primary' : 'inherit'} />
                  </IconButton>
                  <EmojiPopup
                    open={Boolean(emojiAnchorEl)}
                    anchorEl={emojiAnchorEl}
                    onClose={handleClose}
                    onSelect={handleEmojiSelect}
                  />
                  <IconButton onClick={handleSendMessage}>
                    <Send
                      sx={{
                        color: 'text.disabled',
                      }}
                    />
                  </IconButton>
                </Box>
              </ClickAwayListener>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default Reply;
