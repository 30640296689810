import { useContext, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectChannels,
  selectChannelsFetchStatus,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  Card,
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';

interface Props {
  channelSelected: string;
  setChannelSelected: (value: string) => void;
}

const OriginChannel = ({ channelSelected, setChannelSelected }) => {
  const { channel } = useContext(conversationsContext);
  const { t } = useTranslation();

  const channels = useSelector(selectChannels);
  const channelsFetchStatus = useSelector(selectChannelsFetchStatus);

  const isLoading = channelsFetchStatus === 'loading';

  const handleSelectChannel = (channel: string) => {
    setChannelSelected(channel);
  };
  const theme = useTheme();

  return (
    <Box display="flex" width="100%" height="100%" flexDirection="column">
      <Typography variant="subtitle2" color="textSecondary" fontSize="1rem">
        {t('conversations.message.originChannel')}
      </Typography>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '.5rem',
          flexDirection: 'column',
          backgroundColor:
            //@ts-ignore
            theme.palette.background.panel,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '3rem',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            paddingLeft: '.5rem',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                color: 'text.primary',
                '& svg': {
                  fontSize: '1rem',
                },
              }}
            >
              {channel.icon}
            </Box>
            <Typography variant="body2">{t(channel.title)}</Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontSize: '.75rem',
              width: '100%',
            }}
          >
            {t('conversations.message.helperTextChannels')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <List
              component="nav"
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
              }}
            >
              {channels?.map((channel) => (
                <ListItemButton
                  key={channel.id}
                  sx={{
                    width: '100%',
                  }}
                  onClick={() => handleSelectChannel(channel.id)}
                  selected={channelSelected === channel.id}
                >
                  <ListItemText primary={t(channel.name)} />
                  <ListItemIcon>
                    <Radio
                      checked={channelSelected === channel.id}
                      color="primary"
                    />
                  </ListItemIcon>
                </ListItemButton>
              ))}
            </List>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default OriginChannel;
