import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewMercadoLibre } from 'redux/features/mercadoLibreSlice/types/NewMercadoLibre';

const createMercadoLibreInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewMercadoLibre: () => ({} as NewMercadoLibre),
  },
};
export default createMercadoLibreInitialState;
