import { Dispatch } from 'redux';
import { RootState } from 'redux/rootReducer';
import type { ChangeScheduleFn } from 'redux/types/ChangeScheduleFn';

const setChannelSchedule =
  (scheduleId: string, changeSchedule: ChangeScheduleFn) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const scheduleSliceState = getState().Schedule;
    const schedule = scheduleSliceState.data.find((s) => s.id === scheduleId);

    dispatch(changeSchedule(schedule));
  };

export default setChannelSchedule;
