import { Box } from '@mui/material'
import { MessageCdr } from '@trii/types/dist/Common/Messages'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface cdrProps {
  cdr: MessageCdr
}

const Cdr = ({cdr}:cdrProps ) => {
    const {t} = useTranslation()
    const formatDuration = (totalSeconds) => {
      const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
      const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
      const seconds = String(totalSeconds % 60).padStart(2, '0');
    
      return `${hours}:${minutes}:${seconds}`;
    };
  return (
    <Box
    display={"flex"}
    flexDirection={"column"}
    color={(theme) => theme.palette.text.primary}
  >
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} alignItems={"center"}>
        <Box>{t('conversations.message.cdr.number')}:</Box>
        <Box sx={{ fontWeight: "bold", ml: 0.5 }}>
          {cdr.callerId}{" "}
        </Box>{" "}
      </Box>
      {cdr.trunk !== "" && (<Box>{`${t('conversations.message.cdr.trunk')}: ${cdr.trunk}`}</Box>)}
      {cdr.ringTime > 0 && (
        <Box>
          {" "}
          {`${t('conversations.message.cdr.ringTime')}: ${formatDuration(cdr.ringTime)}`}
        </Box>
      )}
      <Box>
        {" "}
        {`${t('conversations.message.cdr.connectionTime')}: ${formatDuration(cdr.connectedTime)}`}
      </Box>
      <Box> {`${t('conversations.message.cdr.duration')}: ${formatDuration(cdr.totalTime)}`}</Box>
    </Box>
  </Box>
  )
}

export default Cdr