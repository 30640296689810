import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { webHooksContext } from 'features/Views/WebHooks/context/WebHooksProvider';
// Components/ui
import { NavLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const ListHeader = () => {
  const { crudPanelState, openCreate } = useContext(webHooksContext);
  const { t } = useTranslation();
  const disabled = crudPanelState === 'create';

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/webhooks">
      <Typography color="secondary">{t('webHooksView.webHooks')}</Typography>
    </NavLink>,
  ];

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
      // className="m-0"
      gap={'1rem'}

    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={openCreate}
        disabled={disabled}
      >
        {t('webHooksView.addWebHook')}
      </Button>
    </Box>
  );
};

export default ListHeader;
