import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changePreChatUserInfoEnabled,
  changePreChatUserInfoEmailTitle,
  changePreChatUserInfoNameTitle,
  changePreChatDataPrivacyPolicyEnabled,
  changePreChatDataPrivacyPolicyDescription,
  changePreChatDataPrivacyPolicyUrl,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import DesignSection from '../DesignSection';
import OptionsContainer from '../OptionsContainer';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Switch, FormControlLabel, TextField } from '@mui/material';

interface Props {
  preChatUserInfoEnabled: boolean;
  preChatUserInfoEmailTitle: string;
  preChatUserInfoNameTitle: string;
  preChatDataPrivacyPolicyEnabled: boolean;
  preChatDataPrivacyPolicyDescription: string;
  preChatDataPrivacyPolicyLink: string;
}

const PreChatSection = ({
  preChatUserInfoEnabled,
  preChatUserInfoEmailTitle,
  preChatUserInfoNameTitle,
  preChatDataPrivacyPolicyEnabled,
  preChatDataPrivacyPolicyDescription,
  preChatDataPrivacyPolicyLink,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handlePreChatUserInfoEnabledChange = () => {
    dispatch(changePreChatUserInfoEnabled(!preChatUserInfoEnabled));
  };
  const handlePreChatUserInfoEmailTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changePreChatUserInfoEmailTitle(event.target.value));
  };
  const handlePreChatUserInfoNameTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changePreChatUserInfoNameTitle(event.target.value));
  };
  const handlePreChatDataPrivacyPolicyEnabledChange = () => {
    dispatch(
      changePreChatDataPrivacyPolicyEnabled(!preChatDataPrivacyPolicyEnabled)
    );
  };
  const handlePreChatDataPrivacyPolicyDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changePreChatDataPrivacyPolicyDescription(event.target.value));
  };
  const handlePreChatDataPrivacyPolicyLinkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changePreChatDataPrivacyPolicyUrl(event.target.value));
  };

  return (
    <DesignSection
      title={t('webChatEditView.designPanel.preChatSection.title')}
      icon={
        <TextsmsIcon
          sx={{
            fontSize: 17,
            color: 'primary.main',
          }}
        />
      }
    >
      <OptionsContainer
        title={t('webChatEditView.designPanel.preChatSection.userDataEntry')}
      >
        <FormControlLabel
          control={
            <Switch
              onChange={handlePreChatUserInfoEnabledChange}
              checked={preChatUserInfoEnabled}
            />
          }
          label={`${t('global.enable')} ${t(
            'webChatEditView.designPanel.preChatSection.fieldsWillBeEnabled'
          )}`}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          labelPlacement="start"
        />
      </OptionsContainer>
      <OptionsContainer
        title={t('webChatEditView.designPanel.preChatSection.emailTitle')}
      >
        <TextField
          value={preChatUserInfoEmailTitle}
          size="small"
          label={t('global.title')}
          fullWidth
          onChange={handlePreChatUserInfoEmailTitleChange}
        />
      </OptionsContainer>
      <OptionsContainer
        title={t('webChatEditView.designPanel.preChatSection.nameTitle')}
      >
        <TextField
          value={preChatUserInfoNameTitle}
          size="small"
          label={t('global.title')}
          fullWidth
          onChange={handlePreChatUserInfoNameTitleChange}
        />
      </OptionsContainer>
      <OptionsContainer
        title={t('webChatEditView.designPanel.preChatSection.dataPrivacyPolicy')}
      >
        <FormControlLabel
          control={
            <Switch
              onChange={handlePreChatDataPrivacyPolicyEnabledChange}
              checked={preChatDataPrivacyPolicyEnabled}
            />
          }
          label={`${t('global.enable')}`}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          labelPlacement="start"
        />
      </OptionsContainer>
      <OptionsContainer
        title={t(
          'webChatEditView.designPanel.preChatSection.dataPrivacyPolicyDescription'
        )}
      >
        <TextField
          multiline
          rows={3}
          size="small"
          label={t('global.description')}
          fullWidth
          value={preChatDataPrivacyPolicyDescription}
          onChange={handlePreChatDataPrivacyPolicyDescriptionChange}
        />
      </OptionsContainer>
      <OptionsContainer
        title={t('webChatEditView.designPanel.preChatSection.dataPrivacyPolicyLink')}
      >
        <TextField
          size="small"
          value={preChatDataPrivacyPolicyLink}
          label={'URL'}
          fullWidth
          onChange={handlePreChatDataPrivacyPolicyLinkChange}
        />
      </OptionsContainer>
    </DesignSection>
  );
};

export default PreChatSection;
