// Components/ui
import InboxIcon from '@mui/icons-material/Inbox';
import Badge from '@mui/material/Badge';
// DB
import { useLiveQuery } from 'dexie-react-hooks';
import db from 'db/db';
// Redux
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';

const ConversationsIcon = () => {
  const spaceInfo = useSelector(selectSpaceInfo);

  const conversationsDb = useLiveQuery(
    () =>
      db.conversations
        .where('spaceId')
        .equals(spaceInfo.id)
        .and((conversation) => conversation.newMessagesCount > 0)
        .toArray(),
    [spaceInfo, db]
  );

  const hasNewMessages = conversationsDb && conversationsDb.length > 0;

  return (
    <Badge color="warning" variant="dot" invisible={!hasNewMessages}>
      <InboxIcon />
    </Badge>
  );
};

export default ConversationsIcon;
