import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';

// Components/ui
import { Fade } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import GoogleImage from './utils/google.png';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { disconnectGoogle, fetchEmail } from 'redux/features/emailEditSlice/emailEditSlice';
import { useParams } from 'react-router-dom';
import { EmailConfigOutboundStatus } from '@trii/types/dist/Common/Channels';
import { fetchGoogleBusiness } from 'redux/features/googleBusinessEditSlice/googleBusinessEditSlice';

interface Props {
  outboundGoogleStatus: EmailConfigOutboundStatus;
}

const GoogleLogOutButton = ({outboundGoogleStatus}: Props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const googleAccountStatus = useSelector(selectGoogleAccountStatus);
  const isLoading = outboundGoogleStatus === EmailConfigOutboundStatus.NOT_CONFIGURED;

  const { id } = useParams();

  const handleLogOut = async () => {
    await dispatch(disconnectGoogle(id));
    // await dispatch(fetchEmail(id));
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <LoadingButton
        onClick={handleLogOut}
        loading={isLoading}
        disabled={isLoading}
        variant="contained"

        startIcon={
          <img
            src={GoogleImage}
            style={{ height: 'auto', width: '14px' }}
            alt="logout"
          />
        }
        size="small"
      >
        {t('global.logout')}
      </LoadingButton>
    </Fade>
  );
};

export default GoogleLogOutButton;
