import React from 'react';
// Components/ui
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const ChannelPanelInnerContainer = ({ children }: Props) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box width={'50%'} display={'flex'} flexDirection={'column'} gap={1}>
        {children}
      </Box>
    </Box>
  );
};

export default ChannelPanelInnerContainer;
