import { NodeType } from '@trii/types/dist/Conversations/Flows';

type ServiceSelector = {
  id: string;
  name: string;
  type: number;
};

const serviceSelector: ServiceSelector[] = [
  {
    id: '1',
    name: 'editFlowsView.start.title',
    type: NodeType.START,
  },
  {
    id: '2',
    name: 'editFlowsView.text.title',
    type: NodeType.MSG_TEXT,
  },
  {
    id: '3',
    name: 'editFlowsView.waitResponse.title',
    type: NodeType.MSG_WAIT_RESPONSE,
  },
  {
    id: '4',
    name: 'editFlowsView.sqlQuery.title',
    type: NodeType.SQL_QUERY,
  },
  {
    id: '5',
    name: 'editFlowsView.sendEmail.title',
    type: NodeType.SEND_EMAIL,
  },
  {
    id: '6',
    name: 'editFlowsView.assignTo.title',
    type: NodeType.CONVERSATION_ASSIGN_TO,
  },
  {
    id: '7',
    name: 'editFlowsView.fillForm.title',
    type: NodeType.FILL_FORM,
  },
  {
    id: '8',
    name: 'editFlowsView.redirect.title',
    type: NodeType.REDIRECT,
  },
  {
    id: '9',
    name: 'editFlowsView.fillTicket.title',
    type: NodeType.FILL_TICKET,
  },
  {
    id: '10',
    name: 'editFlowsView.end.title',
    type: NodeType.CONVERSATION_FINALIZE,
  },
  {
    id: '11',
    name: 'editFlowsView.contactSearch.title',
    type: NodeType.CONTACT_SEARCH,
  },
  {
    id: '12',
    name: 'editFlowsView.contactUpdate.title',
    type: NodeType.CONTACT_UPDATE,
  },
  {
    id: '13',
    name: 'editFlowsView.conversationUpdate.title',
    type: NodeType.CONVERSATION_UPDATE,
  },
  {
    id: '14',
    name: 'editFlowsView.conditional.title',
    type: NodeType.CONDITIONAL,
  },
  {
    id: '15',
    name: 'editFlowsView.api.title',
    type: NodeType.API,
  },
  {
    id: '16',
    name: 'editFlowsView.timeScheduleControl.title',
    type: NodeType.TIME_SCHEDULE_CONTROL,
  },
  {
    id: '17',
    name: 'editFlowsView.timeTimer.title',
    type: NodeType.TIME_TIMER,
  },
  {
    id: '18',
    name: 'editFlowsView.timeWaitUntil.title',
    type: NodeType.TIME_WAIT_UNTIL,
  },
];

export default serviceSelector;
