import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  fetchForms,
  selectFormsFetchStatus,
} from 'redux/features/formSlice/formSlice';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
import {
  fetchWebChat,
  selectWebChatName,
  selectWebChatUpdateStatus,
  selectWebChatFetchStatus,
  updateWebChat,
  selectWebChat,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import { Body } from './components';
import { Divider, Box } from '@mui/material';
import { ViewContainer, ChannelEditHeader } from 'components';
import ForumIcon from '@mui/icons-material/Forum';

const WebChatEditContainer = () => {
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const { id } = useParams();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const formsFetchStatus = useSelector(selectFormsFetchStatus);
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const webChat = useSelector(selectWebChat);
  const webChatName = useSelector(selectWebChatName);
  const webChatUpdateStatus = useSelector(selectWebChatUpdateStatus);
  const webChatFetchStatus = useSelector(selectWebChatFetchStatus);

  useEffect(() => {
    dispatch(fetchWebChat(id));
    dispatch(fetchGroup());
    dispatch(fetchForms());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      webChatFetchStatus === 'succeeded' &&
      formsFetchStatus === 'succeeded' &&
      setAllFetched(true);
  }, [groupFetchStatus, scheduleFetchStatus, webChatFetchStatus, formsFetchStatus]);

  return (
    <ViewContainer>
      <Box boxShadow={1}>
        <ChannelEditHeader
          channelName={webChatName}
          channelUpdateStatus={webChatUpdateStatus}
          updateChannel={updateWebChat}
          channelFetchStatus={webChatFetchStatus}
        >
          <ForumIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>
        <Divider />
        <Body data={webChat} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default WebChatEditContainer;
