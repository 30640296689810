import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';

const DeletedContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 35,
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: '5px',
  color: theme.palette.error.main,
}));

const DeletedNotification = () => {
  const { t } = useTranslation();
  const title = t('global.deleted').toUpperCase();

  return <DeletedContainer>{title}</DeletedContainer>;
};

export default DeletedNotification;
