import React, { useEffect } from 'react';
// Components
import { SidebarSelector, Flow, EditNodePanel } from './components';
import { Box, useTheme } from '@mui/material';

export default function Body() {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      mt={1}
      height={'calc(100% - 63px)'}
      // maxHeight={'95%'}
      width="100%"
      border={`1px solid ${theme.palette.divider}`}
      borderRadius={'0.2rem'}
    >
      <SidebarSelector />
      <Flow />
      <EditNodePanel />
    </Box>
  );
}
