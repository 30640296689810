import React from "react";
import {
  Card,
  List,
  ListItem,
  Text,
  Bold,
  Flex,
  Title,
} from "@tremor/react";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { ListItemText, Typography } from "@mui/material";
import { ChannelType } from '@trii/types/dist/Common/Channels';
import getImage from "functions/getImage";

const SimpleListCard = ({ id, title, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  console.log("SimpleData", data);

  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
      }}
    >
      <Title style={{ color: theme.palette.text.primary }}>{title}</Title>
      <List className="mt-4 max-h-72 overflow-y-auto pr-2 max-h-380 min-h-382 position-relative">
        {data && data.length > 0 ? (
          <>
            {data.map((item, index) => {
              const channelIcon = getImage(item.channelType, 15);

              return item.channelName ? (
                <ListItem key={index}>
                  <Flex className="justify-start truncate">
                    {channelIcon.icon}
                    <div style={{ width: "80%", color: theme.palette.text.primary, marginLeft: "10px" }}>
                      <Text className="truncate">
                        <Bold>
                          {item.channelName}
                        </Bold>
                      </Text>
                    </div>
                  </Flex>
                  <Text style={{ color: theme.palette.text.primary }}>
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      {item.quantity}
                    </Typography>
                  </Text>
                </ListItem>
              ) : item.labelId ? (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        {item.name}
                      </Typography>
                    }
                    secondary={`Quantity: ${item.quantity}`}
                  />
                </ListItem>
              ) : null;
            })}
          </>
        ) : (
          <p style={{ color: theme.palette.text.primary, textAlign:"center" }}>No data</p>
        )}
      </List>
    </Card>
  );
};

export default SimpleListCard;
