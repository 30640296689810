export const icons = [
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/backhand-index-pointing-right_1f449.png',
    name: 'microsoft-teams/backhand-index-pointing-right-1',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/backhand-index-pointing-right_light-skin-tone_1f449-1f3fb_1f3fb.png',
    name: 'microsoft-teams/backhand-index-pointing-right-2',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/backhand-index-pointing-right_medium-light-skin-tone_1f449-1f3fc_1f3fc.png',
    name: 'microsoft-teams/backhand-index-pointing-right-3',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/backhand-index-pointing-right_medium-skin-tone_1f449-1f3fd_1f3fd.png',
    name: 'microsoft-teams/backhand-index-pointing-right-4',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/backhand-index-pointing-right_medium-dark-skin-tone_1f449-1f3fe_1f3fe.png',
    name: 'microsoft-teams/backhand-index-pointing-right-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/backhand-index-pointing-right_dark-skin-tone_1f449-1f3ff_1f3ff.png',
    name: 'microsoft-teams/backhand-index-pointing-right-6',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/person-raising-hand_1f64b.png',
    name: 'microsoft-teams/person-raising-hand-1',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/person-raising-hand_light-skin-tone_1f64b-1f3fb_1f3fb.png',
    name: 'microsoft-teams/person-raising-hand-2',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/person-raising-hand_medium-light-skin-tone_1f64b-1f3fc_1f3fc.png',
    name: 'microsoft-teams/person-raising-hand-3',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/person-raising-hand_medium-skin-tone_1f64b-1f3fd_1f3fd.png',
    name: 'microsoft-teams/person-raising-hand-4',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/person-raising-hand_medium-dark-skin-tone_1f64b-1f3fe_1f3fe.png',
    name: 'microsoft-teams/person-raising-hand-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/person-raising-hand_dark-skin-tone_1f64b-1f3ff_1f3ff.png',
    name: 'microsoft-teams/person-raising-hand-6',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/robot_1f916.png',
    name: 'microsoft-teams/robot',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/waving-hand_1f44b.png',
    name: 'microsoft-teams/waving-hand-1',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/waving-hand_light-skin-tone_1f44b-1f3fb_1f3fb.png',
    name: 'microsoft-teams/waving-hand-2',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/waving-hand_medium-light-skin-tone_1f44b-1f3fc_1f3fc.png',
    name: 'microsoft-teams/waving-hand-3',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/waving-hand_medium-skin-tone_1f44b-1f3fd_1f3fd.png',
    name: 'microsoft-teams/waving-hand-4',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/waving-hand_medium-dark-skin-tone_1f44b-1f3fe_1f3fe.png',
    name: 'microsoft-teams/waving-hand-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/microsoft-teams/waving-hand_dark-skin-tone_1f44b-1f3ff_1f3ff.png',
    name: 'microsoft-teams/waving-hand-6',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand_1f64b-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-1',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand_1f64b-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-1',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-medium-light-skin-tone_1f64b-1f3fc-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-2',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-light-skin-tone_1f64b-1f3fb-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-3',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-medium-skin-tone_1f64b-1f3fd-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-4',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-medium-dark-skin-tone_1f64b-1f3fe-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-5',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-dark-skin-tone_1f64b-1f3ff-200d-2640-fe0f.png',
    name: 'microsoft-teams/woman-raising-hand-6',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/bust-in-silhouette_1f464.png',
    name: 'whatsapp/bust-in-silhouette',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/busts-in-silhouette_1f465.png',
    name: 'whatsapp/busts-in-silhouette',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_1f44f.png',
    name: 'whatsapp/clapping-hands-1',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_light-skin-tone_1f44f-1f3fb_1f3fb.png',
    name: 'whatsapp/clapping-hands-2',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_medium-light-skin-tone_1f44f-1f3fc_1f3fc.png',
    name: 'whatsapp/clapping-hands-3',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_medium-skin-tone_1f44f-1f3fd_1f3fd.png',
    name: 'whatsapp/clapping-hands-4',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_medium-dark-skin-tone_1f44f-1f3fe_1f3fe.png',
    name: 'whatsapp/clapping-hands-5',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_dark-skin-tone_1f44f-1f3ff_1f3ff.png',
    name: 'whatsapp/clapping-hands-6',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/eye-in-speech-bubble_1f441-fe0f-200d-1f5e8-fe0f.png',
    name: 'whatsapp/eye-in-speech-bubble',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/footprints_1f463.png',
    name: 'whatsapp/footprints',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-showing-americas_1f30e.png',
    name: '>whatsapp/globe-showing-americas',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-showing-asia-australia_1f30f.png',
    name: 'whatsapp/globe-showing-asia-australia',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-showing-europe-africa_1f30d.png',
    name: 'whatsapp/globe-showing-europe-africa',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-with-meridians_1f310.png',
    name: 'whatsapp/globe-with-meridians',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/hugging-face_1f917.png',
    name: '>whatsapp/hugging-face',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/left-speech-bubble_1f5e8-fe0f.png',
    name: 'whatsapp/left-speech-bubble',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand_1f64b-200d-2642-fe0f.png',
    name: 'whatsapp/man-raising-hand-1',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-light-skin-tone_1f64b-1f3fb-200d-2642-fe0f.png',
    name: 'whatsapp/man-raising-hand-2',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-medium-light-skin-tone_1f64b-1f3fc-200d-2642-fe0f.png',
    name: 'whatsapp/man-raising-hand-3',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-medium-skin-tone_1f64b-1f3fd-200d-2642-fe0f.png',
    name: 'whatsapp/man-raising-hand-4',
  },
  {
    value:
      '1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-medium-dark-skin-tone_1f64b-1f3fe-200d-2642-fe0f.png',
    name: 'whatsapp/man-raising-hand-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-dark-skin-tone_1f64b-1f3ff-200d-2642-fe0f.png',
    name: 'whatsapp/man-raising-hand-6',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/people-hugging_1fac2.png',
    name: 'whatsapp/people-hugging',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_1f64b.png',
    name: 'whatsapp/person-raising-hand-1',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_dark-skin-tone_1f64b-1f3ff_1f3ff.png',
    name: 'whatsapp/person-raising-hand-6',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_light-skin-tone_1f64b-1f3fb_1f3fb.png',
    name: 'whatsapp/person-raising-hand-2',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_medium-dark-skin-tone_1f64b-1f3fe_1f3fe.png',
    name: 'whatsapp/person-raising-hand-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_medium-light-skin-tone_1f64b-1f3fc_1f3fc.png',
    name: 'whatsapp/person-raising-hand-3',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_medium-skin-tone_1f64b-1f3fd_1f3fd.png',
    name: 'whatsapp/person-raising-hand-4',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_1f64c.png',
    name: 'whatsapp/raising-hands-1',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_light-skin-tone_1f64c-1f3fb_1f3fb.png',
    name: 'whatsapp/raising-hands-2',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_medium-light-skin-tone_1f64c-1f3fc_1f3fc.png',
    name: 'whatsapp/raising-hands-3',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_medium-skin-tone_1f64c-1f3fd_1f3fd.png',
    name: 'whatsapp/raising-hands-4',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_medium-dark-skin-tone_1f64c-1f3fe_1f3fe.png',
    name: 'whatsapp/raising-hands-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_dark-skin-tone_1f64c-1f3ff_1f3ff.png',
    name: 'whatsapp/raising-hands-6',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/right-anger-bubble_1f5ef-fe0f.png',
    name: 'whatsapp/right-anger-bubble',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/robot_1f916.png',
    name: 'whatsapp/robot',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/saluting-face_1fae1.png',
    name: 'whatsapp/saluting-face',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/speaking-head_1f5e3-fe0f.png',
    name: 'whatsapp/speaking-head',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/speech-balloon_1f4ac.png',
    name: 'whatsapp/speech-balloon',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/thought-balloon_1f4ad.png',
    name: 'whatsapp/thought-balloon',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_1f44b.png',
    name: 'whatsapp/waving-hand-1',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_light-skin-tone_1f44b-1f3fb_1f3fb.png',
    name: 'whatsapp/waving-hand-2',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_medium-light-skin-tone_1f44b-1f3fc_1f3fc.png',
    name: 'whatsapp/waving-hand-3',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_medium-skin-tone_1f44b-1f3fd_1f3fd.png',
    name: 'whatsapp/waving-hand-4',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_medium-dark-skin-tone_1f44b-1f3fe_1f3fe.png',
    name: 'whatsapp/waving-hand-5',
  },
  {
    value:
      'https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_dark-skin-tone_1f44b-1f3ff_1f3ff.png',
    name: 'whatsapp/waving-hand-6',
  },
];
// <option value='1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand_1f64b-200d-2640-fe0f.png' >microsoft-teams/woman-raising-hand-1</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-medium-light-skin-tone_1f64b-1f3fc-200d-2640-fe0f.png' >microsoft-teams/woman-raising-hand-2</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-light-skin-tone_1f64b-1f3fb-200d-2640-fe0f.png' >microsoft-teams/woman-raising-hand-3</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-medium-skin-tone_1f64b-1f3fd-200d-2640-fe0f.png' >microsoft-teams/woman-raising-hand-4</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-medium-dark-skin-tone_1f64b-1f3fe-200d-2640-fe0f.png' >microsoft-teams/woman-raising-hand-5</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/microsoft-teams/woman-raising-hand-dark-skin-tone_1f64b-1f3ff-200d-2640-fe0f.png' >microsoft-teams/woman-raising-hand-6</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/bust-in-silhouette_1f464.png' >whatsapp/bust-in-silhouette</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/busts-in-silhouette_1f465.png' >whatsapp/busts-in-silhouette</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_1f44f.png' >whatsapp/clapping-hands-1</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_light-skin-tone_1f44f-1f3fb_1f3fb.png' >whatsapp/clapping-hands-2</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_medium-light-skin-tone_1f44f-1f3fc_1f3fc.png' >whatsapp/clapping-hands-3</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_medium-skin-tone_1f44f-1f3fd_1f3fd.png' >whatsapp/clapping-hands-4</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_medium-dark-skin-tone_1f44f-1f3fe_1f3fe.png' >whatsapp/clapping-hands-5</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/clapping-hands_dark-skin-tone_1f44f-1f3ff_1f3ff.png' >whatsapp/clapping-hands-6</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/eye-in-speech-bubble_1f441-fe0f-200d-1f5e8-fe0f.png' >whatsapp/eye-in-speech-bubble</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/footprints_1f463.png' >whatsapp/footprints</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-showing-americas_1f30e.png' >whatsapp/globe-showing-americas</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-showing-asia-australia_1f30f.png' >whatsapp/globe-showing-asia-australia</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-showing-europe-africa_1f30d.png' >whatsapp/globe-showing-europe-africa</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/globe-with-meridians_1f310.png' >whatsapp/globe-with-meridians</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/hugging-face_1f917.png' >whatsapp/hugging-face</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/left-speech-bubble_1f5e8-fe0f.png' >whatsapp/left-speech-bubble</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand_1f64b-200d-2642-fe0f.png' >whatsapp/man-raising-hand-1</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-light-skin-tone_1f64b-1f3fb-200d-2642-fe0f.png' >whatsapp/man-raising-hand-2</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-medium-light-skin-tone_1f64b-1f3fc-200d-2642-fe0f.png' >whatsapp/man-raising-hand-3</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-medium-skin-tone_1f64b-1f3fd-200d-2642-fe0f.png' >whatsapp/man-raising-hand-4</option>
// <option value='1https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-medium-dark-skin-tone_1f64b-1f3fe-200d-2642-fe0f.png' >whatsapp/man-raising-hand-5</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/man-raising-hand-dark-skin-tone_1f64b-1f3ff-200d-2642-fe0f.png' >whatsapp/man-raising-hand-6</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/people-hugging_1fac2.png' >whatsapp/people-hugging</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_1f64b.png' >whatsapp/person-raising-hand-1</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_dark-skin-tone_1f64b-1f3ff_1f3ff.png' >whatsapp/person-raising-hand-6</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_light-skin-tone_1f64b-1f3fb_1f3fb.png' >whatsapp/person-raising-hand-2</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_medium-dark-skin-tone_1f64b-1f3fe_1f3fe.png' >whatsapp/person-raising-hand-5</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_medium-light-skin-tone_1f64b-1f3fc_1f3fc.png' >whatsapp/person-raising-hand-3</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/person-raising-hand_medium-skin-tone_1f64b-1f3fd_1f3fd.png' >whatsapp/person-raising-hand-4</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_1f64c.png' >whatsapp/raising-hands-1</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_light-skin-tone_1f64c-1f3fb_1f3fb.png' >whatsapp/raising-hands-2</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_medium-light-skin-tone_1f64c-1f3fc_1f3fc.png' >whatsapp/raising-hands-3</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_medium-skin-tone_1f64c-1f3fd_1f3fd.png' >whatsapp/raising-hands-4</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_medium-dark-skin-tone_1f64c-1f3fe_1f3fe.png' >whatsapp/raising-hands-5</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/raising-hands_dark-skin-tone_1f64c-1f3ff_1f3ff.png' >whatsapp/raising-hands-6</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/right-anger-bubble_1f5ef-fe0f.png' >whatsapp/right-anger-bubble</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/robot_1f916.png' >whatsapp/robot</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/saluting-face_1fae1.png' >whatsapp/saluting-face</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/speaking-head_1f5e3-fe0f.png' >whatsapp/speaking-head</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/speech-balloon_1f4ac.png' >whatsapp/speech-balloon</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/thought-balloon_1f4ad.png' >whatsapp/thought-balloon</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_1f44b.png' >whatsapp/waving-hand-1</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_light-skin-tone_1f44b-1f3fb_1f3fb.png' >whatsapp/waving-hand-2</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_medium-light-skin-tone_1f44b-1f3fc_1f3fc.png' >whatsapp/waving-hand-3</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_medium-skin-tone_1f44b-1f3fd_1f3fd.png' >whatsapp/waving-hand-4</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_medium-dark-skin-tone_1f44b-1f3fe_1f3fe.png' >whatsapp/waving-hand-5</option>
// <option value='https://triicommon.blob.core.windows.net/emojis/whatsapp/waving-hand_dark-skin-tone_1f44b-1f3ff_1f3ff.png' >whatsapp/waving-hand-6</option>
