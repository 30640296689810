// Components/ui
import { AvatarGroup, Chip } from "@mui/material"
// Types
import { FormTag } from "@trii/types/dist/Conversations"

interface TagsProps {
  tags: FormTag[];
  max: number;
}
const Tags = ({
  tags,
  max
}: TagsProps) => {
  return (
    <>
      <AvatarGroup
        max={max}
        total={tags?.length}
        sx={{
          "& .MuiAvatar-root": {
            width: 20,
            height: 20,
            fontSize: "0.7rem",
          },
          "&:hover": {
            cursor: "pointer",
          },
          // Add some spacing between avatars
          "& .MuiAvatar-groupAvatar": {
            marginRight: "5px",
          },
          alignItems: "center",
          maxWidth: "100%"
        }}
        spacing={"medium"}
      >
        {tags && tags?.length > 0 && tags?.map((tag: FormTag, i) => (
          <Chip
            key={i}
            label={`${tag.name}: ${tag.value}`}
            size="small"
            variant="filled"
            sx={{
              color: (theme) => theme.palette.grey[50],
              minWidth: "100%",
            }}
          />
        ))}
      </AvatarGroup>
    </>
  );
}

export default Tags