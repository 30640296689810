import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { Box, IconButton, useTheme } from '@mui/material';
// Icons
import { InsertEmoticon } from '@mui/icons-material';
// Component Emoji
import { Emojis, Input } from 'components';
import { useTranslation } from 'react-i18next';

const TextFormat = () => {
  const { textNode, setTextNode, selectNode} = useContext<EditFlowsContext>(InitEditFlowsContext);
  const theme = useTheme();
const {t} = useTranslation()
  const [text, setText] = useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [newEmoji, setNewEmoji] = useState<string>('');
  useEffect(() => {
    if (selectNode) {
      const defaultText =
        selectNode.data?.msgText?.message?.text && selectNode.data.msgText.message
          ? selectNode.data.msgText.message.text
          : ('' as string);
      setText(defaultText);
    }
  }, [selectNode]);

  const handleChange = (value: string) => {
    setText(value);
    setTextNode({ ...textNode, message: { ...textNode.message, text: value } });
  }
  const handleEmojiSelect = (emoji) => {
    setNewEmoji(emoji.native);
  };
  const defaultResponseLabel = t('editFlowsView.text.manualMessage.text');

  const InputStyle = {
    marginTop: '15px',
      "&::before": {
        content: `"${defaultResponseLabel}"`,
        position: "absolute",
        top: "-10px",  
        left: "10px",  
        backgroundColor: theme.palette.background.default,
        padding: "0 4px",
        color: theme.palette.text.primary,
        fontSize: "12px",
        zIndex: 1,
        transition: "color 0.3s ease",  // Transición suave
      },
      "& div:first-of-type": {
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.action.disabled}`,
        borderRadius: "4px",
        borderTopLeftRadius: "0px", // Cortar el borde en la parte superior izquierda
        borderTopRightRadius: "0px", // Cortar el borde en la parte superior derecha
        transition: "border-color 0.3s ease",  
        maxHeight: "150px",
        minHeight: "150px",
        paddingRight: "30px"

      },
      "&:focus-within div:first-of-type": {
        borderColor: theme.palette.primary.main,  // Cambia el color del borde al hacer foco
      },
      "&:focus-within::before": {
        color: theme.palette.primary.main,  // Cambia el color del texto al hacer foco
      },
    
  }
  return (
    <>
      <Box position="relative" sx={InputStyle}>
        <Input
          value={text}
          newEmoji={newEmoji}
          maxHeigth="inherit"
          conversationId={""}
          contactId={""}
          setValue={handleChange}
          setNewEmoji={setNewEmoji}
          handleSendMessage={() => {}}
          disableEnterKeyAction={true}

        />
        <Box
          position="absolute"
          sx={{
            top: "3px",
            right: 0,
          }}
        >
          <IconButton
            size="small"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            sx={{
              color: "text.disabled",
            }}
          >
            <InsertEmoticon />
          </IconButton>
        </Box>
      </Box>
      {showEmojiPicker && (
        <Box
          height="100%"
          width="100%"
          maxHeight="200px"
          position="relative"
          sx={{
            "& div": {
              marginTop: "5px",
              marginBottom: "35px",

            },
            "& div div em-emoji-picker": {
              //@ts-ignore
              background: theme.palette.background.dropdownMenu,
            },
          }}
        >
          <Emojis handleEmojiSelect={handleEmojiSelect} />
        </Box>
      )}
    </>
  );
};

export default TextFormat;
