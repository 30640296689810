import React from 'react';
// Redux
import { useSelector } from 'react-redux';
import {
  selectFacebooksFetchStatus,
  selectAllFacebooks,
  selectNewFacebook,
  selectFacebookCreateStatus,
} from 'redux/features/facebookSlice/facebookSlice';
// Components/ui
import { Box, Skeleton } from '@mui/material';
import { ListItemSkeleton } from 'components';
import FacebookItem from './components/FacebookItem/FacebookItem';
// Types
import { IChannel } from '@trii/types/dist/Common/Channels';

const ListBody = () => {
  const [facebookList, setFacebookList] = React.useState<IChannel[]>([]);
  const allFacebooks = useSelector(selectAllFacebooks);
  const newFacebook = useSelector(selectNewFacebook);
  const facebookCreateStatus = useSelector(selectFacebookCreateStatus);
  const isCreating = facebookCreateStatus === 'loading';
  const facebooksFetchStatus = useSelector(selectFacebooksFetchStatus);
  const isLoading = facebooksFetchStatus === 'loading';

  React.useEffect(() => {
    if (allFacebooks) {
      setFacebookList(allFacebooks);
    }
  }, [allFacebooks]);

  React.useEffect(() => {
    if (newFacebook) {
      setFacebookList((prev) => [...prev, ...newFacebook]);
    }
  }, [newFacebook]);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {
        isLoading ? (
          <ListItemSkeleton />
        ) : (
          facebookList && facebookList.length && facebookList?.map(
            (facebook) => (
              <FacebookItem key={facebook.id} data={facebook} />
            ))
        )
      }
      {
        isCreating && <Skeleton
          variant={'rounded'}
          width={'100%'}
          height={37}
        />
      }
    </Box>
  );
};

export default ListBody;
