import { useState, useContext, useEffect } from "react";
// Redux
import { useSelector } from "react-redux";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
// Components
import { FormHeader, Summary, SubmittedForms } from "./components";
// Selector
import { selectForm, selectFetchFormStatus } from "redux/features/formEditSlice/formEditSlice";

const Forms = () => {
  const {
    form,
    startDate,
    endDate,
    printRef,
    setForm,
    getSummary,
    getSubmittedForms,
  } = useContext(DashboardContext);
  const { t } = useTranslation();
  const newForm = useSelector(selectForm);
  const formStatus = useSelector(selectFetchFormStatus);
  const isLoading = formStatus === "loading";
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const data = {
      formId: form.id,
      timeStart: startDate.toDate(),
      timeEnd: endDate.toDate(),
      userId: "",
    }
    if (newValue === 0) {
      getSummary(data);
    } else if (newValue === 1) {
      getSubmittedForms(data);
    }
    setTabValue(newValue);
  }

  useEffect(() => {
    if (newForm && newForm.form) {
      setForm(newForm);
    }
  }, [newForm])

  return (
    <Box
      sx={{
        display: "flex",
        with: "100%",
        height: "calc(100% - 3rem)",
        justifyContent: "flex-start",
        gap: ".5rem",
        flexDirection: "column",
        borderRadius: "1px",
        boxShadow: (theme) => theme.shadows[4],
      }}
    >
      <Tabs value={tabValue} onChange={handleChange} variant="fullWidth"
       sx={{
       
        "& .MuiTab-root.Mui-selected": {
          color: theme => theme.palette.mode === 'light' ? 'primary' : "text.primary", 
        },
        "& .MuiTabs-indicator": {
          backgroundColor: theme => theme.palette.mode === 'light' ? 'primary' : "text.primary",
        },
      }}>
        <Tab          
          label={t("dashboard.forms.summary.title")}
        />
        <Tab label={t("dashboard.forms.submittedForms.title")} />
      </Tabs>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "calc(100vh - 16rem)",
          // overflow: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={printRef}
      >
        {isLoading ? (
          <Box>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <FormHeader
            title={form?.form?.title}
            subTitle={form?.form?.subTitle}
            color={form?.form?.color}
            isLoading={false}
            showActions={tabValue === 1}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: `calc(100% - ${tabValue === 0 ? "10rem" : "11rem"})`,
                overflow: "auto",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
                p: 2,
              }}
            >
              {(tabValue === 0 && <Summary />) ||
                (tabValue === 1 && <SubmittedForms />)}
            </Box>
          </FormHeader>
        )}
      </Box>
    </Box>
  );

}

export default Forms;