// Types
import { IChannel } from '@trii/types/dist/Common/Channels';
// Redux
import {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  addPermission,
  removePermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  changeAssignMethod,
} from 'redux/features/emailEditSlice/emailEditSlice';
//Components/ui
import { ChannelEditBodyContainer, ChannelPermissionsPanel } from 'components';
import {
  DetailsPanel,
  PanelsSkeleton,
} from './components';
import ConnectedEmailAddress from './components/ConnectedEmailAddress/ConnectedEmailAddress';

interface Props {
  allFetched: boolean;
  data: IChannel;
}

const Body = ({ allFetched, data }: Props) => {
  const isLoading = data === null || !allFetched;

  return (
    <ChannelEditBodyContainer>
      {isLoading ? (
        <PanelsSkeleton />
      ) : (
        <>
          <DetailsPanel
            changeName={changeName}
            changeToken={changeToken}
            changeReassign={changeReassign}
            changeReassignGroup={changeReassignGroup}
            changeAssignMethod={changeAssignMethod}
            data={data.email}
            id={data.id}
            name={data.name}
            assignMethod={data.assignMethod}
            assignTo={data.assignTo}
          />
          <ConnectedEmailAddress connectedEmail={data.email} connectionType={data.email.outboundConnectionType}/>
          <ChannelPermissionsPanel
            addPermission={addPermission}
            removePermission={removePermission}
            changePermissionReadMessages={changePermissionReadMessages}
            changePermissionStartConversation={changePermissionStartConversation}
            data={data.permissions}
          />
        </>
      )}
    </ChannelEditBodyContainer>
  );
};

export default Body;
