import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeDialog360AuthToken,
  changeDialog360NameSpace,
  changeDialog360Number,
} from 'redux/features/whatsAppEditSlice/whatsAppEditSlice';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import InputsContainer from '../InputsContainer/InputsContainer';

interface Props {
  data: {
    dialog360AuthToken: string;
    dialog360NameSpace: string;
    dialog360Number: string;
  };
}

const DialogInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { dialog360AuthToken, dialog360NameSpace, dialog360Number } = data;

  const handleDialog360AuthTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeDialog360AuthToken(event.target.value));
  };
  const handleDialog360NameSpaceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeDialog360NameSpace(event.target.value));
  };
  const handleDialog360NumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeDialog360Number(event.target.value));
  };
  return (
    <InputsContainer>
      <TextField
        size="small"
        label="Token"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={dialog360AuthToken}
        onChange={handleDialog360AuthTokenChange}
      />
      <TextField
        size="small"
        label={t('global.number')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={dialog360Number}
        onChange={handleDialog360NumberChange}
      />
      <TextField
        size="small"
        label="Namespace"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        onChange={handleDialog360NameSpaceChange}
        value={dialog360NameSpace}
      />
    </InputsContainer>
  );
};

export default DialogInputs;
