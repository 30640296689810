import { useEffect, useState, useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  fetchQuickResponses,
  selectQuickResponses,
  selectQuickResponsesFetchStatus,
} from 'redux/features/quickResponseSlice/quickResponseSlice';
// Components/ui
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
// Types
import { Settings } from '@mui/icons-material';
import { IQuickResponse } from '@trii/types/dist/Conversations';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Styles
import { CustomTooltip } from './style/CustomTooltip';

type VariableProps = {
  variable: string,
  anchorEl: HTMLDivElement | null,
  caretPosition: number,
  autoFocus: boolean,
  handleClose: () => void,
  onSelect: (variable: string) => void,
  handleFocus: () => void,
}

interface PopoverPosition {
  top: number,
  left: number,
}

const Response = ({
  variable,
  anchorEl,
  caretPosition,
  autoFocus,
  handleClose,
  onSelect,
  handleFocus,
}: VariableProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const quickResponses = useSelector(selectQuickResponses);
  const quickResponsesFetchStatus = useSelector(selectQuickResponsesFetchStatus);
  const isLoading = quickResponsesFetchStatus === 'loading';
  const [popoverPosition, setPopoverPosition] = useState<PopoverPosition>(null)
  const [quickResponseList, setQuickResponseList] = useState<IQuickResponse[]>([])
  const [openTooltip, setOpenTooltip] = useState<string>('')

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' || event.key === 'Tab') {
      event.preventDefault()
      event.stopPropagation()
      handleFocus()
    }
  }

  const handleAddQuickResponse = () => {
    window.open('/a/conversations/quick-responses', '_blank')
  }

  const getQuickResponses = async () => {
    await dispatch(fetchQuickResponses());
  }

  const handleOpenTooltip = (id: string) => {
    setOpenTooltip(id)
  }

  const handleCloseTooltip = () => {
    setOpenTooltip('')
  }

  useEffect(() => {
    getQuickResponses()
    if (caretPosition > 0) {
      const sel = window.getSelection()
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0)
        const rect = range.getBoundingClientRect()
        const left = rect.left - anchorEl?.getBoundingClientRect().left
        const top = rect.top - anchorEl?.getBoundingClientRect().top
        setPopoverPosition({ top, left })
      }
    }
  }, [anchorEl, variable])

  useEffect(() => {
    if (variable) {
      const filterResponse = quickResponseList?.filter(
        (item) =>
          item.title.toLocaleLowerCase()
            .includes(variable.toLocaleLowerCase()) ||
          item.categoryName.toLocaleLowerCase()
            .includes(variable.toLocaleLowerCase()) ||
          item.bodyIM.toLocaleLowerCase()
            .includes(variable.toLocaleLowerCase())
      )
      setQuickResponseList(filterResponse)
    } else {
      setQuickResponseList(quickResponseList)
    }
  }, [quickResponseList, variable]);

  useEffect(() => {
    if (quickResponses) {
      setQuickResponseList(quickResponses)
    }
  }, [quickResponses])

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      anchorOrigin={{
        vertical: popoverPosition ? popoverPosition.top : 0,
        horizontal: popoverPosition ? popoverPosition.left : 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: '100%',
          maxWidth: 'max-content',
          maxHeight: 300,
          minWidth: 100,
        },
      }}
      onKeyDown={handleKeyDown}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            padding: '0 1rem',
          }}
        >
          <Typography
            variant='body2'
          >
            {t('quickResponse.quickResponses')}
          </Typography>
          <IconButton
            onClick={handleAddQuickResponse}
            size='small'
          >
            <Settings />
          </IconButton>
        </Box>
        <MenuList
          sx={{
            width: '100%',
            bgcolor: (theme) => theme.palette.background.default,
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
            maxHeight: 200,
          }}
          autoFocus={autoFocus}
        >
          {
            isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  margin: '1rem',
                }}
              />
            ) : (
              quickResponseList && quickResponseList.length > 0 ? (
                quickResponseList.map((response, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                    onClick={() => onSelect(response.bodyIM)}
                    onMouseEnter={() => handleOpenTooltip(response.id)}
                    onMouseLeave={handleCloseTooltip}
                    onFocus={() => handleOpenTooltip(response.id)}
                    onBlur={handleCloseTooltip}
                  >
                    <CustomTooltip
                      title={response.bodyIM}
                      placement='top'
                      open={openTooltip === response.id}
                    >
                      <Typography
                        variant='body2'
                        noWrap
                        sx={{
                          width: '100%',
                          height: '100%',
                          p: 1,
                        }}
                      >
                        {response.categoryName} - {response.title}
                      </Typography>
                    </CustomTooltip>
                  </MenuItem>
                ))
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    width: '100%',
                    height: '100%',
                    padding: '1rem',
                  }}
                >
                  {t('conversations.message.variableNoResults')}
                </Typography>
              )
            )
          }
        </MenuList>
      </Box>
    </Popover>
  );
}

export default Response;