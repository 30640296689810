import { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import { Box, Typography } from '@mui/material';
//Components
import { ManualMessage, TemplateMessage } from './components';
// Interface
import { NodeMsgTextMode } from '@trii/types/dist/Conversations/Flows/Nodes';

const Message = () => {
  const { selectNode, setTextNode, textNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [showTemplateMessage, setShowTemplateMessage] = useState<boolean>(false);
  const [showManualMessage, setShowManualMessage] = useState<boolean>(true);

  useEffect(() => {
    if (selectNode && selectNode.data.msgText) {
      const mode = selectNode.data.msgText.mode
        ? selectNode.data.msgText.mode
        : NodeMsgTextMode.MANUAL;
      handleMode(mode);
    }
  }, [selectNode]);

  const handleMode = (mode: number) => {
    setTextNode({ ...textNode, mode });
    setShowTemplateMessage(mode === NodeMsgTextMode.TEMPLATE);
    setShowManualMessage(mode === NodeMsgTextMode.MANUAL);
  };

  return (
    <Box width={'100%'} marginBottom={'10px'}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            cursor: 'pointer',
            borderBottom: (theme) =>
              `2px solid ${
                showTemplateMessage
                  ? theme.palette.primary.light
                  : 'rgba(0, 0, 0, 0.1)'
              }`,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: 'text.disabled',
          }}
          onClick={() => handleMode(NodeMsgTextMode.TEMPLATE)}
        >
          {t('editFlowsView.text.templateMessage.title')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: (theme) => theme.typography.fontWeightBold,
            cursor: 'pointer',
            borderBottom: (theme) =>
              `2px solid ${
                showManualMessage
                  ? theme.palette.primary.light
                  : 'rgba(0, 0, 0, 0.1)'
              }`,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: 'text.disabled',
          }}
          onClick={() => handleMode(NodeMsgTextMode.MANUAL)}
        >
          {t('editFlowsView.text.manualMessage.title')}
        </Typography>
      </Box>
      {showManualMessage && <ManualMessage />}
      {showTemplateMessage && <TemplateMessage />}
    </Box>
  );
};

export default Message;
