
//components/ui
import {
  Box,
  Typography,
} from "@mui/material";

interface BranchProps {
  title: string;

}
const Branch = ({
  title,
}: BranchProps) => {

  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "inherit",
          flexGrow: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Branch;
