import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewPbx } from 'redux/features/pbxSlice/types/NewPbx';

const createPbxInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewPbx: () => ({} as NewPbx),
  },
};
export default createPbxInitialState;
