import { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Interface
import { Method } from '@trii/types/dist/Common/API';

const ApiMethod = () => {
  const { apiNode, setApiNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [apiMethod, setApiMethod] = useState<Method>(Method.GET);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.api) {
      const api = selectNode.data.api;
      const defaultContactId = api.apiMethod && (api.apiMethod as Method);
      setApiMethod(defaultContactId);
    }
  }, [selectNode]);

  const handleChangeContact = (apiMethod: Method) => {
    setApiMethod(apiMethod);
    setApiNode({ ...apiNode, apiMethod });
  };

  return (
    <>
      <FormControl fullWidth size="small" sx={{ marginBottom: "5px" }}>
        <InputLabel id="contact" shrink={true}>
          {t("editFlowsView.api.apiMethod.title")}
        </InputLabel>
        <Select
          labelId="contact"
          id="demo-simple-select"
          value={apiMethod}
          label={t("editFlowsView.api.apiMethod.title")}
          onChange={(e) => handleChangeContact(e.target.value as Method)}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          <MenuItem key={Method.GET} value={Method.GET}>
            {t("editFlowsView.api.apiMethod.get")}
          </MenuItem>
          <MenuItem key={Method.POST} value={Method.POST}>
            {t("editFlowsView.api.apiMethod.post")}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default ApiMethod;
