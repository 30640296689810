import { useContext, useRef } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, ButtonGroup, IconButton, Tooltip, Typography } from "@mui/material"
// Icons
import {
  Download,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Print
} from "@mui/icons-material";
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
import ReactToPrint from "react-to-print";

const Actions = () => {
  const {
    formIndex,
    completedForms,
    printRef,
    setFormIndex,
  } = useContext(DashboardContext);
  const { t } = useTranslation();

  const handleChangeForm = (isNext: boolean, isAll: boolean) => {
    if (isNext) {
      if (formIndex === completedForms - 1) {
        return
      }
      if (isAll) {
        return setFormIndex(completedForms - 1);
      }
      return setFormIndex(formIndex + 1);
    }
    if (formIndex === 0) {
      return
    }
    if (isAll) {
      return setFormIndex(0);
    }
    return setFormIndex(formIndex - 1);
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "flex-end",
        alignItems: "flex-end",
      }}
      className="form-actions"
    >
      <style>
        {`
          @media print {
            .form-actions {
              display: none;
            }
          }
        `}
      </style>
      <ButtonGroup
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactToPrint
          content={() => printRef?.current}
          trigger={() => (
            <Tooltip arrow title={t('dashboard.forms.submittedForms.print')}>
              <IconButton
                size="small"
                sx={{
                  color: (theme) => theme.palette.text.primary
                }}
              >
                <Print />
              </IconButton>
            </Tooltip>
          )}
        />
        <IconButton
          size="small"
          onClick={() => handleChangeForm(false, true)}
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          <KeyboardDoubleArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => handleChangeForm(false, false)}
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        {/* Quantity responses */}
        <Typography
          sx={{
            color: theme => theme.palette.text.primary,
          }}
          variant="body1"
        >
          {t('dashboard.forms.submittedForms.totalResponses', { index: formIndex + 1, allForms: completedForms })}
        </Typography>
        <IconButton
          size="small"
          onClick={() => handleChangeForm(true, false)}
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => handleChangeForm(true, true)}
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
      </ButtonGroup>
    </Box>
  )
}

export default Actions