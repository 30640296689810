import React, { useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translations
import { useTranslation } from 'react-i18next';
// components/ui
import { Box, Button } from '@mui/material';
const ActionButtons = () => {
  const {
    setEditPanelOpen,
    selectNode,
    timeScheduleControlNode,
    setNodes,
    resetTimeScheduleControlField,
    setSelectNode,
  } = useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setEditPanelOpen(false);
    setNodes((prevNodes) => {
      const newNodes = prevNodes.map((node) => {
        if (node.id === selectNode.id) {
          return {
            ...node,
            selected: false,
          };
        }
        return node;
      });
      return newNodes;
    });
    resetTimeScheduleControlField();
    setSelectNode(null);
  };

  const handleSave = () => {
    setNodes((prevNodes) => {
      const newNodes = prevNodes.map((node) => {
        if (node.id === selectNode.id) {
          return {
            ...node,
            data: {
              ...node.data,
              timeScheduleControl: {
                ...timeScheduleControlNode,
              },
            },
            selected: false,
          };
        }
        return node;
      });
      return newNodes;
    });
    setEditPanelOpen(false);
    setSelectNode(null);
    resetTimeScheduleControlField();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'flex-end',
        height: '100%',
        alignItems: 'end',
      }}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.dark,
          },
        }}
        onClick={() => handleClose()}
      >
        {t('global.cancel')}
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          '&:hover': {
            backgroundColor: (theme) => theme.palette.success.dark,
          },
        }}
        onClick={() => handleSave()}
      >
        {t('global.save')}
      </Button>
    </Box>
  );
};

export default ActionButtons;
