import { Box, Card, Divider, Skeleton } from "@mui/material"

const ContactInfoSkeleton = () => {
  const actionButtons = [1, 2, 3]
  const information = [1, 2, 3, 4, 5, 6, 7, 8]
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        position: "relative",
        backgroundColor: 'unset'
      }}
    >
      <Skeleton
        variant="rectangular"
        width={'30%'}
        height={'2rem'}
        sx={{
          margin: '1rem',
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            gap: ".5rem",
            padding: "1rem",
            position: "absolute",
            top: '2.5rem',
            left: 0,
            flexDirection: 'column',
          }}
        >
          {
            actionButtons.map(button => (
              <Skeleton
                key={button}
                variant={button !== 4 ? "circular" : "rectangular"}
                width={button !== 4 ? '1rem' : '.5rem'}
                height={'1rem'}
              />
            ))
          }
        </Box>
        {/* Avatar */}
        <Box
          sx={{
            width: "100%",
            height: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: ".5rem",
            padding: "0 1rem",
          }}
        >
          <Skeleton
            variant="circular"
            width={90}
            height={90}
          />
          <Skeleton
            variant="rectangular"
            width={'6rem'}
            height={'1.5rem'}
          />
        </Box>
        <Divider
          sx={{
            width: "calc(100% - 2rem)",
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: ".5rem",
            padding: "1rem",
          }}
        >
          {
            information.map(info => (
              <Skeleton
                key={info}
                variant="rectangular"
                width={'100%'}
                height={'1.5rem'}
              />
            ))
          }
        </Box>
      </Box>
    </Card>
  )
}

export default ContactInfoSkeleton