import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { emailContext } from 'features/Views/Email/context/EmailProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectEmailCreateStatus } from 'redux/features/emailSlice/emailSlice';
// Components/ui
import { Box, Button, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

function CreateButtons() {
  const { t } = useTranslation();
  const { endCreating, handleCreateEmail } = useContext(emailContext);
  const emailCreateStatus = useSelector(selectEmailCreateStatus);
  const isLoading = emailCreateStatus === 'loading';
  const createSuccess = emailCreateStatus === 'succeeded';

  const handleCreateButton = async () => {
    await handleCreateEmail();
  };

  useEffect(() => {
    if (createSuccess) {
      endCreating();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);
  const theme = useTheme()
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}

      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endCreating}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        disabled={isLoading}
        loading={isLoading}  
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        //@ts-ignore
        // color={theme.palette.text.primary}
        onClick={handleCreateButton}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
