import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components/ui
import { NavLink } from 'react-router-dom';
import { Box, Button, Card } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
// Redux
import { useDispatch } from 'react-redux';
import { fetchWhatsApps } from 'redux/features/whatsAppSlice/whatsAppSlice';
import { fetchWebChats } from 'redux/features/webChatSlice/webChatSlice';
import { fetchFacebooks } from 'redux/features/facebookSlice/facebookSlice';
import { fetchInstagrams } from 'redux/features/instagramSlice/instagramSlice';

import { DetailsMenu, SectionsMenu } from './componentes';

const ResponsesContainer = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWebChats());
    dispatch(fetchWhatsApps());
    dispatch(fetchInstagrams());

    dispatch(fetchFacebooks());
  }, [dispatch]);
  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/responses">
      <Typography color="secondary">{t('responsesView.responses')}</Typography>
    </NavLink>,
  ];

  return (
    <Box
      mt={1}
      height={'calc(95% - 11px)'}
      maxHeight={'95%'}
      width="100%"
      padding="50px 30px 0px 30px"
      position={"relative"}
    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          height: 'calc(100vh - 190px)',
        }}
        display={'flex'}
      >
        {/* SectionLeftSide */}
        <SectionsMenu></SectionsMenu>
        {/* BodyRightSide */}
        <DetailsMenu></DetailsMenu>
      </Box>
    </Box>
  );
};

export default ResponsesContainer;
