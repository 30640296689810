import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { Permission } from '@trii/types/dist/Common/Channels';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectGroupData } from '../../../../redux/features/groupSlice/groupSlice';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Typography,
  IconButton,
  ListSubheader,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { selectUsers } from 'redux/features/userSlice/userSlice';

interface Props {
  data: Permission[];
  addPermission: (permissionData: {
    groupId?: string;
    userId?: string;
  }) => ThunkAction<void, RootState, unknown, AnyAction>;
}

const GroupSelect = ({ data, addPermission }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const [selectedId, setSelectedId] = useState<string>('');

  const groupData = useSelector(selectGroupData);
  const users = useSelector(selectUsers);

  const filteredGroupData = groupData.filter((group) => {
    const isGroupInData = data?.find(
      (permission) => permission.groupId === group.id
    );

    return !isGroupInData;
  });

  const handleSelectionChange = (e: SelectChangeEvent<string>) => {
    setSelectedId(e.target.value);
  };

  const handleAddSelection = () => {
    if (!selectedId) return;

    const permissionData = !users.find((user) => user.id === selectedId)
      ? {
          groupId: selectedId,
        }
      : {
          userId: selectedId,
        };

    dispatch(addPermission(permissionData));
    setSelectedId('');
  };

  return (
    <Box display={'flex'} ml={2} gap={2} alignItems={'center'} width={'55%'}>
      <Typography>{t('global.add')}</Typography>
      <Select
        onChange={handleSelectionChange}
        fullWidth
        value={selectedId}
        size="small"
    
      >
        <ListSubheader>{t('global.groups')}</ListSubheader>
        {filteredGroupData.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
        <ListSubheader>{t('global.users')}</ListSubheader>
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        sx={{
          backgroundColor: '#f3f4f6',
        }}
        size="small"
        color="primary"
        onClick={handleAddSelection}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default GroupSelect;
