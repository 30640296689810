import React, { useMemo, useState } from 'react';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
// Components/ui
import { Box } from '@mui/material';
import { ConversationCard } from './components';
import { ContactActions } from './components/ConversationCard/components/Body/components';

interface Props {
  conversation: IConversation;
  handleSelectConversation: (
    conversation: IConversation,
    incognito?: boolean
  ) => void;
}

const ConversationItem = ({ conversation, handleSelectConversation }: Props) => {
  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [menuPosition, setMenuPosition] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({ mouseX: null, mouseY: null });

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // Close the previous menu if it's already open
    if (contextMenuAnchor) {
      handleCloseContextMenu();
      return;
    }
    // Set new coordinates for the context menu
    setContextMenuAnchor(event.currentTarget);
    setMenuPosition({ mouseX: event.clientX, mouseY: event.clientY });
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
    setMenuPosition({ mouseX: null, mouseY: null });
  };

  const handleClick = (incognito?: boolean) => {
    // Close the context menu and trigger the selection of the conversation
    handleCloseContextMenu();
    handleSelectConversation(conversation, incognito);
  };

  const memoizedItem = useMemo(
    () => (
      <Box
        onClick={() => handleClick(false)}
        onContextMenu={handleContextMenu}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <ConversationCard conversation={conversation} />
        <ContactActions
          handleSelectConversation={handleClick}
          conversationId={conversation.id}
          anchorEl={contextMenuAnchor}
          onClose={handleCloseContextMenu}
          menuPosition={menuPosition}
        />
      </Box>
    ),
    [conversation, contextMenuAnchor, menuPosition]
  );

  return memoizedItem;
};
export default ConversationItem;
