import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseFieldType } from 'hooks/useField.js';
// Components/ui
import { Box, TextField } from '@mui/material';

interface CreateEditPanelProps {
  endingNameState: UseFieldType;
}

const CreateEditPanel = ({ endingNameState }: CreateEditPanelProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="center"
      alignItems='center'
      p={1}
      height='4rem'
    >
      <TextField
        {...endingNameState.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        label={t('global.name')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default CreateEditPanel;
