import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { AccessTime, Check, DoneAll } from '@mui/icons-material';
// Types
import { MessageAck, ackLog } from '@trii/types/dist/Common/Messages';
import moment from 'moment';

type StatusProps = {
  data: ackLog;
};

const Status = ({ data }: StatusProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getStatus = (ack: number) => {
    const style = {
      fontSize: 'small',
      color: 'text.disabled',
    };

    switch (ack) {
      case MessageAck.ACK_PENDING:
        return {
          title: t('conversations.message.status.pending'),
          icon: <AccessTime sx={style} />,
        };
      case MessageAck.ACK_SERVER:
        return {
          title: t('conversations.message.status.sent'),
          icon: <Check sx={style} />,
        };
      case MessageAck.ACK_DEVICE:
        return {
          title: t('conversations.message.status.delivered'),
          icon: <DoneAll sx={style} />,
        };
      case MessageAck.ACK_READ:
        return {
          title: t('conversations.message.status.read'),
          icon: <DoneAll sx={{ ...style, color: '#2196f3' }} />,
        };
      case MessageAck.ACK_ERROR:
        return {
          title: t('conversations.message.status.failed'),
          icon: (
            <Check sx={{ ...style, color: theme.palette.error.main }} />
          ),
        };
      default:
        return {
          title: t('conversations.message.status.pending'),
          icon: <AccessTime sx={style} />,
        };
    }
  };

  const status = getStatus(data.ack);

  return (
    <ListItem
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start', // Cambiado a flex-start para alinear la parte superior
        flexDirection: 'column', // Usar dirección de columna
        gap: 0.5, // Espaciado entre elementos
      }}
    >
      <Box display="flex" width="100%" alignItems="center">
        <ListItemIcon>{status.icon}</ListItemIcon>
        <Box flexGrow={1} overflow="hidden">
          <ListItemText
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {status.title}
          </ListItemText>
        </Box>
          <ListItemText
          sx={{
            color: (theme) => theme.palette.text.disabled,
            textAlign: 'right',
          }}
        >
          {moment(data.timestamp).format('DD/MM/YYYY HH:mm:ss')}
        </ListItemText>
      </Box>

      {data?.errorMessage !== '' && (
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Typography
          fontSize={'11px'}
          sx={{
            color: (theme) => theme.palette.error.main,
            overflow: 'auto', 
            wordBreak: 'break-word', 
            width: '100%', 
            paddingLeft: '55px'
          }}
        >
          {data.errorMessage}
        </Typography>
      </Box>
      )}
       
    </ListItem>
  );
};

export default Status;
