import React, { useContext, useEffect } from 'react';
import { endingContext } from 'features/Views/Ending/context/EndingProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { CreateButtons } from './components';
import CrudContainer from '../CrudContainer';
import PanelHeader from '../PanelHeader';
import CreateEditPanel from '../CreateEditPanel';
import GroupList from '../GroupList';

const CreatePanel = () => {
  const { endCreating, createEndingName } = useContext(endingContext);
  const { t } = useTranslation();

  useEffect(() => {
    const cleanUp = () => {
      createEndingName.actions.resetValue();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('endingsView.addEnding')}
        handleCloseButton={endCreating}
      />
      <CreateEditPanel endingNameState={createEndingName} />
      <GroupList />
      <CreateButtons />
    </CrudContainer>
  );
};

export default CreatePanel;
