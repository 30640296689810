import React, { useState, useEffect, useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography, TextField } from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';

const SaveAs = () => {
  const { selectNode, msgWaitResponseNode, setMsgWaitResponseNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [result, setResult] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode) {
      setResult(msgWaitResponseNode.saveAs ? msgWaitResponseNode.saveAs : '');
    }
  }, [selectNode]);

  const handleChange = (value: string) => {
    setResult(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      saveAs: value,
    });
  };

  return (
    <Box>
      <Typography variant="subtitle2" fontWeight={"bold"} color="text.primary">
        {t('editFlowsView.waitResponse.saveAs')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        placeholder={t('editFlowsView.waitResponse.saveAsPlaceholder')}
        helperText={t('editFlowsView.waitResponse.saveAsHelperText')}
        value={result}
        onChange={(e) => handleChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          "& .MuiFormHelperText-root": {
            variant: "caption",
            fontStyle: "italic",
            opacity: 0.7,
          },
        }}
      />
    </Box>
  );
};

export default SaveAs;
