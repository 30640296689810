import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectFormsFetchStatus,
  selectAllForms,
} from 'redux/features/formSlice/formSlice';
// Components/ui
import { Box } from '@mui/material';
import { FormItem } from './components';
import { ListItemSkeleton } from 'components';

const ListBody = () => {
  const allForms = useSelector(selectAllForms);
  const formsFetchStatus: RequestStatus = useSelector(selectFormsFetchStatus);

  const Forms = allForms.map((form) => <FormItem key={form.id} data={form} />);
  console.log("forms",allForms);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {formsFetchStatus === 'succeeded' ? Forms : <ListItemSkeleton />}
    </Box>
  );
};

export default ListBody;
