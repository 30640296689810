import { useTranslation } from 'react-i18next';
// Types
import { WebChatChannel } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  addHomeChannelsWhatsApp,
  removeHomeChannelsWhatsApp,
  changeHomeChannelsWhatsAppName,
  changeHomeChannelsWhatsAppDetail,
  changeHomeChannelsWhatsAppNumber,
  addHomeChannelsMessenger,
  removeHomeChannelsMessenger,
  changeHomeChannelsMessengerName,
  changeHomeChannelsMessengerDetail,
  changeHomeChannelsMessengerFacebookId,
  addHomeChannelsPhone,
  removeHomeChannelsPhone,
  changeHomeChannelsPhoneName,
  changeHomeChannelsPhoneDetail,
  changeHomeChannelsPhoneNumber,
  changeHomeChannelsWhatsAppImageUrl,
  changeHomeChannelsMessengerImageUrl,
  changeHomeChannelsPhoneImageUrl,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import { WebChatChannelCard } from './components';
import DesignSection from '../DesignSection';
import OptionsContainer from '../OptionsContainer';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Button } from '@mui/material';

interface Props {
  homeChannelsWhatsApp: WebChatChannel[];
  homeChannelsMessenger: WebChatChannel[];
  homeChannelsPhone: WebChatChannel[];
}

const HomeOtherChannelsSection = ({
  homeChannelsWhatsApp,
  homeChannelsMessenger,
  homeChannelsPhone,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleAddWhatsApp = () => {
    dispatch(addHomeChannelsWhatsApp());
  };
  const handleAddMessenger = () => {
    dispatch(addHomeChannelsMessenger());
  };
  const handleAddPhone = () => {
    dispatch(addHomeChannelsPhone());
  };

  const WhatsAppHomeChannels = homeChannelsWhatsApp.map((webChatChannelData) => (
    <WebChatChannelCard
      key={webChatChannelData.id}
      webChatChannelData={webChatChannelData}
      deleteAction={removeHomeChannelsWhatsApp}
      changeTitleAction={changeHomeChannelsWhatsAppName}
      changeDetailAction={changeHomeChannelsWhatsAppDetail}
      changeAddressAction={changeHomeChannelsWhatsAppNumber}
      changeWebChatChannelLogoUrl={changeHomeChannelsWhatsAppImageUrl}
      type="whatsapp"
    />
  ));
  const MessengerHomeChannels = homeChannelsMessenger.map((webChatChannelData) => (
    <WebChatChannelCard
      key={webChatChannelData.id}
      webChatChannelData={webChatChannelData}
      deleteAction={removeHomeChannelsMessenger}
      changeTitleAction={changeHomeChannelsMessengerName}
      changeDetailAction={changeHomeChannelsMessengerDetail}
      changeAddressAction={changeHomeChannelsMessengerFacebookId}
      changeWebChatChannelLogoUrl={changeHomeChannelsMessengerImageUrl}
      type="messenger"
    />
  ));
  const PhoneHomeChannels = homeChannelsPhone.map((webChatChannelData) => (
    <WebChatChannelCard
      key={webChatChannelData.id}
      webChatChannelData={webChatChannelData}
      deleteAction={removeHomeChannelsPhone}
      changeTitleAction={changeHomeChannelsPhoneName}
      changeDetailAction={changeHomeChannelsPhoneDetail}
      changeAddressAction={changeHomeChannelsPhoneNumber}
      changeWebChatChannelLogoUrl={changeHomeChannelsPhoneImageUrl}
      type="phone"
    />
  ));

  return (
    <DesignSection
      title={t('webChatEditView.designPanel.homeOtherChannelsSection.title')}
      icon={
        <HomeWorkIcon
          sx={{
            fontSize: 17,
            color: 'primary.main',
          }}
        />
      }
    >
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {t('webChatEditView.designPanel.homeOtherChannelsSection.description')}
      </Typography>
      <OptionsContainer title="WhatsApp">
        {WhatsAppHomeChannels}
        <Button onClick={handleAddWhatsApp} startIcon={<AddIcon />}>
          {t('webChatEditView.designPanel.homeOtherChannelsSection.addWhatsApp')}
        </Button>
      </OptionsContainer>
      <OptionsContainer title="Messenger">
        {MessengerHomeChannels}
        <Button onClick={handleAddMessenger} startIcon={<AddIcon />}>
          {t('webChatEditView.designPanel.homeOtherChannelsSection.addMessenger')}
        </Button>
      </OptionsContainer>
      <OptionsContainer title={t('global.phone')}>
        {PhoneHomeChannels}
        <Button onClick={handleAddPhone} startIcon={<AddIcon />}>
          {t('webChatEditView.designPanel.homeOtherChannelsSection.addPhone')}
        </Button>
      </OptionsContainer>
    </DesignSection>
  );
};

export default HomeOtherChannelsSection;
