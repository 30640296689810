import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseCreateEmail } from 'features/Views/Email/hooks/useCreateEmail/types';
// Components/ui
import { Box, TextField, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';

interface CreateInputsProps {
  createEmailState: UseCreateEmail;
}

const CreateInputs = ({ createEmailState }: CreateInputsProps) => {
  const { t } = useTranslation();
  const spaceInfo = useSelector(selectSpaceInfo);
  const createError = createEmailState.attributes.creationError;

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
      gap={2}
    >
      <TextField
        {...createEmailState.input.nameField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        label={t('global.name')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        {...createEmailState.input.emailField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        label={t('global.email')}
        helperText={createError && t('global.emailAlreadyExists')}
        error={createError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">@{spaceInfo.domain}</InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiFormHelperText-root': {
            variant: 'caption',
            fontStyle: 'italic',
            opacity: 0.7,
          },
        }}
      />
      <TextField
        {...createEmailState.input.descriptionField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={8}
        label={t('global.description')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default CreateInputs;
