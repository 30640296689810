import React from 'react';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PanelHeaderProps {
  title: string;
  handleCloseButton: () => void;
}

function PanelHeader({ title, handleCloseButton }: PanelHeaderProps) {
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
      height='2.5rem'
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Typography variant="subtitle2" sx={{ color: (color) => color.palette.text.primary }}>{title}</Typography>
      <IconButton onClick={handleCloseButton}>
        <CloseIcon
          sx={{
            fontSize: '20px',
            color: (color) => color.palette.text.disabled
          }}
        />
      </IconButton>
    </Box>
  );
}

export default PanelHeader;
