import { useState, useContext, useEffect } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Menu } from '@mui/material';
// Icons
import {
  AddComment,
  ConfirmationNumber,
  KeyboardArrowDown,
  Reply,
  Star,
} from '@mui/icons-material';
// Components
import { Item } from './components';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import { dbWorker } from 'db/db';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { EmailMessageContext } from 'features/Views/Conversations/components/components/Conversation/components/Footer/components/EmailMode/components/EmailWriter/context/EmailMessageContext';

type Props = {
  over: boolean;
  setOver: (value: boolean) => void;
  message: IMessage;
  showReply: boolean;
};

const MessageActions = ({ over, setOver, message, showReply }: Props) => {
  const {
    highlightMessage,
    setMessage,
    setIsReplying,
    setMessageReply,
    resetEmailFields,
    setBodyDraft,
    setSubjectDraft,
    handleOpenEmailMode,
    setTo,
    handleResponse,
  } = useContext(messagesContext);
  const {
    setModalTitle,
    setOpenModalList,
    handleOpenForwardModal,
    conversationSelected,
  } = useContext(conversationsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOver(false);
    setAnchorEl(null);
  };

  const handleReply = (type: 'reply' | 'forward') => {
    if (message.email?.to) {
      const { email } = message;

      const body =
        email.bodyHtml === ''
          ? email.bodyText.replace(/(?:\r\n|\r|\n)/g, '<br>')
          : email.bodyHtml;

      resetEmailFields();
      setBodyDraft(body);
      setSubjectDraft(`RE: ${email.subject}`);

      if (type === 'reply') {
        setTo(email.to);
      }

      handleOpenEmailMode();
    } else {
      setMessageReply(message);
      setIsReplying(true);
    }
  };

  const handleAddComment = () => {
    setModalTitle(t('conversations.message.actions.addComment'));
    setOpenModalList(true);
    setMessage(message);
  };

  const handleResend = () => {
    if (conversationSelected.channelInfo.type === ChannelType.EMAIL) {
      handleResponse('forward', message.email);
    } else {
      handleOpenForwardModal();
      setMessage(message);
    }
  };

  const handleHighlight = async () => {
    const { id: messageId, shardKey } = message;
    const data = {
      messageId,
      shardKey,
      isHighlighted: !message.isHighlighted,
    };
    const response = await highlightMessage(data);
    await dbWorker.postMessage({
      action: 'updateMessage',
      data: response,
    });

    handleClose();
  };

  return (
    <Box
      display={`${over || anchorEl ? 'flex' : 'none'}`}
      position="absolute"
      right="0"
      top="0"
    >
      <IconButton
        onClick={handleOpen}
        aria-describedby={'popupPrincipal'}
        size="small"
        sx={{
          color: 'text.disabled',
        }}
      >
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openMenu}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
          },
        }}
      >
        {showReply && (
          <Item
            title={t('conversations.message.actions.reply')}
            icon={<Reply fontSize="small" />}
            onClick={() => handleReply('reply')}
            disabled={
              conversationSelected?.channelInfo.type === ChannelType.FACEBOOK ||
              !conversationSelected
            }
          />
        )}
        <Item
          title={t('conversations.message.actions.forward')}
          icon={<Reply fontSize="small" sx={{ transform: 'rotateY(180deg)' }} />}
          onClick={handleResend}
        />
        <Item
          title={t('conversations.message.actions.createTicket')}
          icon={<ConfirmationNumber fontSize="small" />}
          onClick={() => console.log('createTicket')}
        />
        <Item
          title={t(
            `conversations.message.actions.${
              message.isHighlighted ? 'doNotHighlightMessage' : 'highlightMessage'
            }`
          )}
          icon={<Star fontSize="small" />}
          onClick={handleHighlight}
        />
        <Item
          title={t('conversations.message.actions.addComment')}
          icon={<AddComment fontSize="small" />}
          onClick={handleAddComment}
        />
      </Menu>
    </Box>
  );
};

export default MessageActions;
