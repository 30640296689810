// Components/ui
import { Typography, Box } from '@mui/material';
import { ConversationBotVar } from '@trii/types/dist/Conversations';
import { style } from './style';

type DataItemProps = {
  data: ConversationBotVar;
};
const DataItem = ({ data: { title, value } }: DataItemProps) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Typography
        sx={{
          color: (theme) => theme.palette.text.primary,
          ...style.title,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: (theme) => theme.palette.text.primary,
          ...style.value,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default DataItem;
