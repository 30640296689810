import axios from 'axios';
import { IFlow } from '@trii/types/dist/Conversations/Flows';
import getRequestConfig from '../../functions/getRequestConfig';

const fetchFlow = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/flows/${id}`, config);
  return response.data;
};

const updateFlow = async (jwt: string, URL: string, id: string, flow: IFlow) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const flowJson = JSON.stringify(flow);

  const response = await axios.put(`${URL}/flows/${id}`, flowJson, config);
  return response.data;
};

const fetchChannels = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(`${URL}/channels/whatsapp`, config);
  return response.data;
};

const fetchEndings = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(`${URL}/endings`, config);
  return response.data;
};

const fetchLabels = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(`${URL}/labels`, config);
  return response.data;
};

const fetchContactDates = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  const response = await axios.get(`${URL}/ContactFields?filter=dates`, config);
  return response.data;
};

const fetchChargeFile = async (jwt: string, URL: string, file: FormData) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.post(`${URL}/upload`, file, config);
  return response.data;
};

const fetchEmailChannels = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/channels/email`, config);
  return response.data;
};

const fetchGroups = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/groups`, config);
  return response.data;
};

const fetchForms = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/channels/form`, config);
  return response.data;
};

const fetchFlows = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/flows/nodes`, config);
  return response.data;
};

const fetchSearchBy = async (jwt: string, URL: string, searchBy: string) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(
    `${URL}/ContactFields?filter=${searchBy}&for=contact`,
    config
  );
  console.log(`${URL}/ContactFields?filter=${searchBy}&for=contact`);
  console.log('Response: ', response.data);
  return response.data;
};

const fetchSchedules = async (jwt: string, URL: string) => {
  const config = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/schedules`, config);
  return response.data;
};

const flowService = {
  fetchFlow,
  updateFlow,
  fetchChannels,
  fetchEndings,
  fetchLabels,
  fetchContactDates,
  fetchChargeFile,
  fetchEmailChannels,
  fetchGroups,
  fetchForms,
  fetchFlows,
  fetchSearchBy,
  fetchSchedules,
};

export default flowService;
