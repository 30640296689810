import React, { RefObject, forwardRef } from 'react';
// components/ui
import { Button, Box } from '@mui/material';
import TicketSection from './components/TicketSection/TicketSection';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { SectionCard } from '../../layout';
import { ContactProps } from '../../types/ContactProps';

const ContactTicket = forwardRef((props: ContactProps, ref: RefObject<HTMLElement>) => {
  return (
    <SectionCard
      title={'Tickets'}
      onClick={props.handleClick}
      tabName='contactTicket'
    >
      <Box ref={ref}>
        <Button
          startIcon={<AddBoxIcon />}
          variant="contained"
          size="small"
          sx={{
            width: '100%',
            textTransform: 'none',
            fontSize: '12px',
            fontWeight: 'bold',
            mb: 3,
          }}
        >
          Crear Ticket
        </Button>
        <TicketSection title="Reclamos Activos">
          Este contacto no tiene reclamos activos
        </TicketSection>
        <TicketSection title="Historial"></TicketSection>
      </Box>
    </SectionCard>
  );
});

export default ContactTicket;
