import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseCreateMercadoLibre } from 'features/Views/MercadoLibre/hooks/useCreateMercadoLibre/types';
// Components/ui
import { Box, TextField } from '@mui/material';

interface CreateInputsProps {
  createMercadoLibreState: UseCreateMercadoLibre;
}

const CreateInputs = ({ createMercadoLibreState }: CreateInputsProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
      gap={2}
    >
      <TextField
        {...createMercadoLibreState.input.nameField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        label={t('global.name')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        {...createMercadoLibreState.input.descriptionField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={8}
        label={t('global.description')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default CreateInputs;
