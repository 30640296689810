import React, { useContext } from 'react';
// Redux
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
// Types
import { GroupContext } from 'features/Views/Groups/context/types';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components/index';

const SidePanel = () => {
  const { createPanelOpen, deletePanelOpen, editPanelOpen } =
    useContext<GroupContext>(InitGroupContext);

  return (
    <>
      {(createPanelOpen && <CreatePanel />) ||
        (deletePanelOpen && <DeletePanel />) ||
        (editPanelOpen && <EditPanel />)}
    </>
  );
};

export default SidePanel;
