import { useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Fade, Icon, Typography, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// Redux
import { useSelector } from 'react-redux';
// Icons
import { PushPin, Autorenew, Label, TrendingFlat } from '@mui/icons-material';
// moment
import moment from 'moment';
// Types
import { ConversationAssigned, IConversation, ILabel } from '@trii/types/dist/Conversations';
// Slice
import { selectUser } from 'redux/features/userSlice/userSlice';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
import { ContactActions } from '../Body/components';

interface HeaderProps {
  pinned: boolean;
  transferTo: ConversationAssigned;
  transferBy: string;
  expired: boolean;
  updatedAt: Date;
  labels: ILabel[];
  conversation: IConversation;
}

const DATE_FORMAT = 'DD/MM/YYYY';

const Header = ({
  pinned,
  transferTo,
  transferBy,
  expired,
  updatedAt,
  labels,
  conversation,
}: HeaderProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [date, setDate] = useState<string>('');
  const [transferIn, setTransferIn] = useState<boolean>(false);
  const [transferName, setTransferName] = useState<string>('');
  const { getUserInfo } = useUserInfo();
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);

  const IconTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      marginBottom: '5px!important' as any,
    },
  })) as typeof Tooltip;

  const getUser = async (userId: string) => {
    const userInfo = await getUserInfo(userId);
    setTransferName(userInfo?.name);
  };

  useEffect(() => {
    const updateDate = moment(updatedAt).format(DATE_FORMAT);
    const today = moment().format(DATE_FORMAT);
    const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT);

    if (updateDate === today) {
      setDate(`${t('conversations.today')} ${moment(updatedAt).format('HH:mm')}`);
    } else if (updateDate === yesterday) {
      setDate(`${t('conversations.yesterday')} ${moment(updatedAt).format('HH:mm')}`);
    } else {
      setDate(updateDate);
    }
  }, [updatedAt, t]);

  useEffect(() => {
    if (transferTo) {
      if (transferTo.userInfo) {
        if (transferTo.userInfo.id !== user.uid) {
          setTransferIn(true);
          setTransferName(transferTo.userInfo.name);
        } else {
          setTransferIn(false);
          getUser(transferBy);
        }
      } else if (transferTo.userIds && transferTo.userIds.length > 0) {
        if (!transferTo.userIds.includes(user.uid)) {
          setTransferIn(true);
          setTransferName(transferTo.groupInfo.name);
        } else {
          setTransferIn(false);
          getUser(transferBy);
        }
      }
    }
  }, [transferTo, user.uid]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuAnchor(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      flexDirection={"column"}
      onContextMenu={handleContextMenu} // Add right-click event handler
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          transition: "all 0.3s ease",
        }}
      >
        {labels &&
          labels.length > 0 &&
          labels.map((label) => (
            <Fade key={label.id} in={labels && labels.length > 0}>
              <Label
                fontSize="small"
                sx={{
                  fontSize: "13px",
                  color: label.color,
                }}
              />
            </Fade>
          ))}
        <Typography variant="caption" color="text.primary">
          {date}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          color: "text.primary",
          alignItems: "center",
          minHeight: "15px",
        }}
      >
        {pinned && (
          <IconTooltip title={t("conversations.icons.pinned")} arrow>
            <PushPin
              sx={{
                rotate: "45deg",
                fontSize: "13px",
              }}
            />
          </IconTooltip>
        )}
        {expired && (
          <IconTooltip title={t("conversations.icons.expired")} arrow>
            <Autorenew
              fontSize="small"
              sx={{
                fontSize: "13px",
              }}
            />
          </IconTooltip>
        )}
        {transferTo && (
          <IconTooltip
            title={t(
              `conversations.icons.${
                transferIn ? "transferIn" : "transferOut"
              }`,
              { name: transferName }
            )}
            arrow
          >
            {transferIn ? (
              <Icon
                sx={{ color: "#007bff", rotate: "320deg", display: "flex" }}
              >
                <TrendingFlat
                  sx={{
                    fontSize: "13px",
                  }}
                />
              </Icon>
            ) : (
              <Icon
                sx={{ color: "#0aa709", rotate: "140deg", display: "flex" }}
              >
                <TrendingFlat
                  sx={{
                    fontSize: "13px",
                  }}
                />
              </Icon>
            )}
          </IconTooltip>
        )}
        {/* <ContactActions
          conversationId={conversation?.id}
        /> */}
      </Box>
    </Box>
  );
};

export default Header;
