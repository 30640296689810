import { useContext, useEffect, useState } from 'react';
// Moment
import moment from 'moment';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Divider, Typography } from '@mui/material';
// Icons
import { ThumbUp } from '@mui/icons-material';
// Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';

const FORMAT_DATE = 'DD/MM/YYYY';

const ItemDetails = () => {
  const { t } = useTranslation();
  const { postSelected } = useContext(socialWallsContext);
  const [likes, setLikes] = useState<number>(0);
  const { title, details, imageUrl, createdAt } = postSelected || {};

  useEffect(() => {
    if (postSelected && postSelected.counters) {
      const { likes } = postSelected.counters;
      setLikes(likes || 0);
    }
  }, [postSelected]);

  return (
    <>
      {/* imagen */}
      {imageUrl && (
        <Box
          sx={{
            maxHeight: '20rem',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={imageUrl}
            alt={title}
            width="100%"
            height="100%"
            style={{
              aspectRatio: '4/3',
              objectFit: 'cover',
            }}
          />
        </Box>
      )}
      {/* detalles */}
      <Box
        sx={{
          height: 'calc(100% - 20rem)',
          borderTop: imageUrl
            ? (theme) => `1px solid ${theme.palette.divider}`
            : 'none',
        }}
        px={3}
        py={1}
        my={'.1rem'}
      >
        {/* me gusta y fecha */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="2rem"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <ThumbUp fontSize="small" color="primary" />
            <Typography
              color={'text.primary'}
              variant="subtitle2"
              sx={{
                userSelect: 'none',
              }}
            >
              {t('socialWalls.likes', { likes })}
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              userSelect: 'none',
            }}
          >
            {moment(createdAt).format(FORMAT_DATE)}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          sx={{ mt: 1 }}
          height="calc(100% - 3rem)"
          overflow={'auto'}
        >
          {details}
        </Typography>
      </Box>
    </>
  );
};
export default ItemDetails;
