import { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createInstagram,
  deleteInstagram,
} from 'redux/features/instagramSlice/instagramSlice';
// Types
import { InstagramContext } from './types';
import { UseFieldType } from 'hooks/useField';
import { NewInstagram } from 'redux/features/instagramSlice/types/NewInstagram';
import { IChannel } from '@trii/types/dist/Common/Channels';

export const instagramContext = createContext<InstagramContext>({
  deletePanelState: false,
  handleCreateInstagram: (data: NewInstagram) => { },
  openDelete: () => { },
  endDeleting: () => { },
  selectedInstagram: null,
  deleteInstagramName: fieldInitialDef,
  handleDeleteInstagram: () => { },
  navigateToEdit: () => { },
});

const InstagramProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedInstagram, setSelectedInstagram] = useState<any>(null);
  // Delete state
  const deleteInstagramName: UseFieldType = useField('text');
  // Drawer state
  const [deletePanelState, setDeletePanelState] = useState<boolean>(false);

  // Create functions
  const handleCreateInstagram = async (data: NewInstagram) => {
    await dispatch(createInstagram(data));
  };
  // end Create functions

  // Delete functions
  const handleDeleteInstagram = async () => {
    const { id } = selectedInstagram;

    await dispatch(deleteInstagram(id));
    endDeleting();
  };

  const openDelete = (instagram: IChannel) => {
    setSelectedInstagram(instagram);
    setDeletePanelState(true);
  };
  const endDeleting = () => {
    deleteInstagramName.actions.resetValue();
    setSelectedInstagram(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (instagramId: string) => {
    navigate(`/a/conversations/system/instagram/${instagramId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setDeletePanelState(false);
  };
  // end Drawer functions

  return (
    <instagramContext.Provider
      value={{
        deletePanelState,
        handleCreateInstagram,
        openDelete,
        endDeleting,
        deleteInstagramName,
        selectedInstagram,
        handleDeleteInstagram,
        navigateToEdit,
      }}
    >
      {children}
    </instagramContext.Provider>
  );
};

export default InstagramProvider;
