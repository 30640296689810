import { RefObject, forwardRef, useEffect, useState } from 'react';
// Transition
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import { Box, CircularProgress } from '@mui/material';
import { EventItem, EventSection } from './components';
import { SectionCard } from '../../layout';
import { AddEvent, CreateEvent } from './components';
// Slice
import {
  selectActivities,
  selectActivitiesFetchStatus,
  selectActivitiesAddStatus,
} from 'redux/features/activitiesSlice/utils/selectors';
// Types
import { IEvent } from '@trii/types/dist/Calendar';
import { ContactProps } from '../../types/ContactProps';

const ContactEvent = forwardRef(
  (props: ContactProps, ref: RefObject<HTMLElement>) => {
    const { t } = useTranslation();

    const [events, setEvents] = useState<IEvent[]>([]);

    const activities = useSelector(selectActivities);
    const activitiesFetchStatus = useSelector(selectActivitiesFetchStatus);
    const activitiesAddStatus = useSelector(selectActivitiesAddStatus);

    const isLoading =
      activitiesFetchStatus === 'loading' || activitiesAddStatus === 'loading';

    const Events = events.map((event) => (
      <EventItem key={event.title} event={event} />
    ));

    useEffect(() => {
      if (activities) {
        setEvents(activities);
      }
    }, [activities]);

    return (
      <SectionCard
        title={t('conversations.sidebar.events.title')}
        onClick={props.handleClick}
        tabName="contactEvent"
      >
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            height: '100%',
            width: '100%',
          }}
        >
          <AddEvent />
          <EventSection title={t('conversations.sidebar.events.inProgress')}>
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  margin: '.5rem auto',
                }}
              />
            )}
            {Events}
          </EventSection>
          <CreateEvent />
        </Box>
      </SectionCard>
    );
  }
);

export default ContactEvent;
