import * as React from 'react';
// components/ui
import { Fade, Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  const [inProp, setInProp] = React.useState(false);

  React.useEffect(() => {
    setInProp(value === index);
  }, [value, index]);

  return (
    <Fade in={inProp}>
      <Box
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ padding: '14px 16px', overflow: 'auto' }}
        sx={{
          height: '100%',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        {...other}
      >
        {value === index && children}
      </Box>
    </Fade>
  );
}

export default TabPanel;
