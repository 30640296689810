import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
import {
  fetchForm,
  selectFormName,
  selectUpdateFormStatus,
  selectFetchFormStatus,
  updateForm,
  selectForm,
} from 'redux/features/formEditSlice/formEditSlice';
// Components/ui
import { Body } from './components';
import { Divider, Box } from '@mui/material';
import { ViewContainer, ChannelEditHeader } from 'components';
import AssignmentIcon from '@mui/icons-material/Assignment';

const FormEditContainer = () => {
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const { id } = useParams();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const form = useSelector(selectForm);
  const formName = useSelector(selectFormName);
  const formUpdateStatus = useSelector(selectUpdateFormStatus);
  const formFetchStatus = useSelector(selectFetchFormStatus);

  useEffect(() => {
    dispatch(fetchForm(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      formFetchStatus === 'succeeded' &&
      setAllFetched(true);
  }, [groupFetchStatus, scheduleFetchStatus, formFetchStatus]);

  return (
    <ViewContainer>
      <Box boxShadow={1}>
        <ChannelEditHeader
          channelName={formName}
          channelUpdateStatus={formUpdateStatus}
          updateChannel={updateForm}
          channelFetchStatus={formFetchStatus}
        >
          <AssignmentIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>

        <Divider />
        <Body data={form} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default FormEditContainer;
