import { useTranslation } from 'react-i18next';
// Components/ui
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Box, Typography } from '@mui/material';
import { style } from './style';
import { DataItem } from './components';
// Types
import { ConversationBotVar } from '@trii/types/dist/Conversations';
import { ChatBotIcon } from 'components';

type BotsInfoProps = {
  conversationBotVars: ConversationBotVar[];
};
const BotsInfo = ({ conversationBotVars }: BotsInfoProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        boxShadow: (theme) => theme.shadows[3],
      }}
    >
      <Box
        sx={{
          ...style.headerContainer,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
       
        <Typography
          fontWeight={600}
          fontSize={16}
          color="text.primary"
          sx={{
            userSelect: 'none',
            
          }}
        >
          {t('conversations.sidebar.responsesBot')}
        </Typography>
        <ChatBotIcon
          color="black"
        />
      </Box>

      <Box sx={style.dataContainer}>
        {conversationBotVars.map((item, index) => (
          <DataItem key={index} data={item} />
        ))}
      </Box>
    </Box>
  );
};

export default BotsInfo;
