import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
// Selector
import { useSelector } from 'react-redux';
// Context
import { InitEditFlowsContext } from '../../../../../../../../../../context/EditFlowsContext';
import { EditFlowsContext } from '../../../../../../../../../../context/types';
import { StyleContext } from '../../../../../../../../../../../../../style/styleProvider';
// Slice
import {
  selectSearchBy,
  selectSearchByStatus,
} from '../../../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';
import { Box } from 'victory';

const Contact = () => {
  const { fillTicketNode, setFillTicketNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { fontWeight } = useContext(StyleContext);
  const [ticketContactSearchFieldId, setTicketContactSearchFieldId] =
    useState<string>('');
  const [ticketContactSearchValue, setTicketContactSearchValue] =
    useState<string>('');
  const [ticketContactName, setTicketContactName] = useState<string>('');
  const contacts = useSelector(selectSearchBy);
  const editStatus = useSelector(selectSearchByStatus);
  const isLoading: boolean = editStatus === 'loading';
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.fillTicket) {
      const fillTicket = selectNode.data.fillTicket;
      const defaultContactId =
        fillTicket.ticketContactSearchFieldId &&
        (fillTicket.ticketContactSearchFieldId as string);
      const defaultContactValue =
        fillTicket.ticketContactSearchValue &&
        (fillTicket.ticketContactSearchValue as string);
      const defaultContactName =
        fillTicket.ticketContactName && (fillTicket.ticketContactName as string);
      setTicketContactSearchFieldId(defaultContactId);
      setTicketContactSearchValue(defaultContactValue);
      setTicketContactName(defaultContactName);
    }
  }, [selectNode]);

  const handleChangeContact = (ticketContactSearchFieldId: string) => {
    setTicketContactSearchFieldId(ticketContactSearchFieldId);
    setFillTicketNode({ ...fillTicketNode, ticketContactSearchFieldId });
  };
  const handleChangeValue = (ticketContactSearchValue: string) => {
    setTicketContactSearchValue(ticketContactSearchValue);
    setFillTicketNode({ ...fillTicketNode, ticketContactSearchValue });
  };
  const handleChangeName = (ticketContactName: string) => {
    setTicketContactName(ticketContactName);
    setFillTicketNode({ ...fillTicketNode, ticketContactName });
  };

  return (
    <>
      <Typography
        variant="caption"
        color={(theme) => theme.palette.text.disabled}
        fontWeight={fontWeight.bold}
        sx={{ marginBottom: "10px" }}
      >
        {t("editFlowsView.fillTicket.contact")}
      </Typography>
      <FormControl fullWidth size="small" sx={{ marginBottom: "5px" }}>
        <InputLabel id="contact" shrink={true}>
          {t("editFlowsView.fillTicket.searchBy")}
        </InputLabel>
        <Select
          labelId="contact"
          id="demo-simple-select"
          value={ticketContactSearchFieldId}
          label={t("editFlowsView.fillTicket.searchBy")}
          onChange={(e) => handleChangeContact(e.target.value)}
          disabled={isLoading}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              // padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {contacts &&
            contacts?.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t("editFlowsView.fillTicket.valueToSearch")}
        fullWidth
        value={ticketContactSearchValue}
        onChange={(e) => handleChangeValue(e.target.value as string)}
        sx={{
          marginTop: ".5rem",
          marginBottom: ".5rem",
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Typography
        variant="caption"
        color={(theme) => theme.palette.text.disabled}
        fontWeight={fontWeight.bold}
        sx={{ marginBottom: "10px" }}
      >
        {t("editFlowsView.fillTicket.replaceContactName")}
      </Typography>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t("editFlowsView.fillTicket.contactName")}
        fullWidth
        value={ticketContactName}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default Contact;
