import { useState, useContext, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Interface
import {
  NodeStartCondition,
  NodeStartConstants,
} from '@trii/types/dist/Conversations/Flows/Nodes';
// Selector
import { useSelector } from 'react-redux';
// Slice
import {
  selectChannelsData,
  selectEndingsData,
  selectSearchBy,
  selectLabelsData,
} from 'redux/features/editFlowSlice/editFlowSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Select, MenuItem, IconButton, TableRow, TableCell } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
// dayjs
import dayjs from 'dayjs';
// Icons
import { Delete } from '@mui/icons-material';

const Filter = ({
  id,
  handleDelete,
  typeTrigger,
}: {
  id: string;
  handleDelete: (id: string) => void;
  typeTrigger: number;
}) => {
  const { startNode, setStartNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const filterFields = NodeStartConstants.get_filter_fields(typeTrigger);
  const channels = useSelector(selectChannelsData);
  const endings = useSelector(selectEndingsData);
  const contactTags = useSelector(selectSearchBy);
  const labels = useSelector(selectLabelsData);
  console.log('labels', labels);
  const [field, setField] = useState<string>(
    filterFields.fields.length === 1 ? filterFields.fields[0] : ('' as string)
  );
  const [condition, setCondition] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [conditionList, setConditionList] = useState<any[]>([]);
  const [valuesList, setValuesList] = useState<any[]>([]);
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const { t } = useTranslation();
  console.log('field', field);
  useEffect(() => {
    const { conditions, values } = NodeStartConstants.get_filter_conditions(
      typeTrigger,
      field
    );
    setConditionList(conditions);
    setValuesList(values);
  }, [field, typeTrigger, selectNode]);

  useEffect(() => {
    const filters = selectNode?.data?.start?.filters?.find(
      (filter) => filter.id === id
    );
    if (filters) {
      setField(filters.field ? filters.field : ('' as string));
      setCondition(filters.condition ? filters.condition : ('' as string));
      if (
        filters.field &&
        filters.field === 'FLOW_NODE_START_FILTER_DATEFIELDUPDATED'
      ) {
        const newHour = filters.value.split(':')[0];
        const newMinute = filters.value.split(':')[1];
        setHour(newHour);
        setMinute(newMinute);
      } else {
        setValue(filters.value ? filters.value : ('' as string));
      }
    }
  }, [id, selectNode]);

  const handleFirstHour = (hour: any) => {
    const newHour = hour?.$H;
    const newMinute = hour?.$m;
    setHour(newHour);
    setMinute(newMinute);
    setValue(`${newHour}:${newMinute === 0 ? '00' : newMinute}`);
  };

  useEffect(() => {
    if (field && condition && value) {
      const conditionList: NodeStartCondition = {
        id,
        field,
        condition,
        value,
      };
      setStartNode((prevStartNode) => {
        if (prevStartNode?.filters?.length) {
          const index = prevStartNode.filters.findIndex(
            (condition) => condition.id === id
          );
          if (index !== -1) {
            const newFilters = [...prevStartNode.filters];
            newFilters[index] = conditionList;
            return { ...prevStartNode, filters: newFilters };
          } else {
            return {
              ...prevStartNode,
              filters: [...prevStartNode?.filters, conditionList],
            };
          }
        } else {
          return { ...prevStartNode, filters: [conditionList] };
        }
      });
    }
  }, [field, condition, value, id]);

  const handleDeleteCondition = () => {
    setStartNode({
      ...startNode,
      filters: startNode.filters.filter((condition) => condition.id !== id),
    });
    handleDelete(id);
  };

  return (
    <>
      <TableRow
        key={id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          width: '100%',
        }}
      >
        <TableCell align="center">
          <Select
            sx={{
              width: 'max-content',
              '& .MuiSvgIcon-root': {
                color: 'text.disabled',
              },
            }}
            size="small"
            value={field}
            defaultValue={field}
            onChange={(e) => setField(e.target.value)}
          >
            {filterFields?.fields?.map((filter) => (
              <MenuItem value={filter} key={filter}>
                {t(`editFlowsView.start.filter.${filter}`)}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            sx={{
              width: '100%',
              '& .MuiSvgIcon-root': {
                color: 'text.disabled',
              },
            }}
            size="small"
            value={condition}
            defaultValue={condition}
            onChange={(e) => setCondition(e.target.value)}
          >
            {conditionList?.map((condition) => (
              <MenuItem
                sx={{ display: 'flex', justifyContent: 'center' }}
                value={condition}
                key={condition}
              >
                {t(`editFlowsView.start.condition.${condition}`)}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center" width="100%">
          {field === 'FLOW_NODE_START_FILTER_DATEFIELDUPDATED' ||
          field === 'FLOW_NODE_START_FILTER_EVENTDATE' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                label="hora"
                size="small"
                ampm={false}
                sx={{ width: '5rem' }}
                defaultValue={dayjs().set('hour', 0).set('minute', 0)}
                value={dayjs().set('hour', hour).set('minute', minute)}
                format="HH:mm"
                onChange={(e) => handleFirstHour(e)}
              />
            </LocalizationProvider>
          ) : (
            <Select
              sx={{
                width: '100%',
                '& .MuiSvgIcon-root': {
                  color: 'text.disabled',
                },
              }}
              size="small"
              value={value}
              defaultValue={value}
              onChange={(e) => setValue(e.target.value)}
            >
              {field === 'FLOW_NODE_START_FILTER_ORIGIN' &&
                valuesList?.map((value) => (
                  <MenuItem value={value} key={value}>
                    {t(`editFlowsView.start.value.${value}`)}
                  </MenuItem>
                ))}
              {field === 'FLOW_NODE_START_FILTER_CHANNEL' &&
                channels?.map((channel) => (
                  <MenuItem value={channel.id} key={channel.id}>
                    {channel.name}
                  </MenuItem>
                ))}
              {field === 'FLOW_NODE_START_FILTER_END' &&
                endings?.map((ending) => (
                  <MenuItem value={ending.id} key={ending.id}>
                    {ending.name}
                  </MenuItem>
                ))}
              {field === 'FLOW_NODE_START_FILTER_FIELDUPDATED' &&
                contactTags?.map((contact) => (
                  <MenuItem value={contact.id} key={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              {field === 'FLOW_NODE_START_FILTER_TAGUPDATED' &&
                labels?.map((label) => (
                  <MenuItem value={label.id} key={label.id}>
                    {label.name}
                  </MenuItem>
                ))}
              {
                field === 'FLOW_NODE_START_FILTER_EVENTTYPE' && (
                  //eventTypes?.map((eventType) => (
                  <MenuItem value={1} key={1}>
                    Proximamente
                  </MenuItem>
                )
                //))
              }
            </Select>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 'max-content' }}>
          <IconButton
            onClick={() => handleDeleteCondition()}
            sx={{ color: 'text.disabled' }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Filter;
