import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewSip } from 'redux/features/sipSlice/types/NewSip';

const createSipInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewSip: () => ({} as NewSip),
  },
};
export default createSipInitialState;
