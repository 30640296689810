import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonReports from 'features/Views/MyReports/components/ButtonReports';
import ContactPageIcon from '@mui/icons-material/ContactPage';
const UserPerformance = () => {
  const { t } = useTranslation();



  return (
    <ButtonReports>
      <NavLink to="/a/conversations/MyReports/view?r=userperformance">
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'center'}
          padding={2}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <Box marginBottom={2}>
            <ContactPageIcon sx={{ fontSize: '1.5rem' }} />
          </Box>
          <Typography variant={'body2'} sx={{ textAlign: 'center' }}>
            {t('myReports.userPerformance')}
          </Typography>
        </Box>
      </NavLink>
    </ButtonReports>
  );
};

export default UserPerformance;
