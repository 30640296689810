import { useEffect } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Router
import { useParams } from 'react-router-dom';
// Selectors
import { fetchScheduleById } from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { ViewContainer } from 'components';
import { Body, Header } from './components';
import { Divider } from '@mui/material';
import InitScheduleEditContext from './context/ScheduleEditContext';

const SchedulesEditContainer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchScheduleById(id));
  }, []);

  return (
    <InitScheduleEditContext>
      <ViewContainer padding={0}>
        <Header />
        <Divider />
        <Body />
      </ViewContainer>
    </InitScheduleEditContext>
  );
};

export default SchedulesEditContainer;
