import { useEffect, useState } from "react"
// Components/ui
import { AvatarGroup, Box, Chip, Fade, Tooltip, useTheme } from "@mui/material"
// Types
import { ILabel } from "@trii/types/dist/Conversations"
// Components
import { LabelModal } from "./components"

interface LabelsProps {
  labels: ILabel[];
  max: number;
}
const Labels = ({
  labels,
  max
}: LabelsProps) => {
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    setOpen(true)
    return () => {
      setOpen(false)
    }
  }, [])
  const theme = useTheme();

  return (
    <>
      <AvatarGroup
        max={max}
        total={labels?.length}
        sx={{
          "& .MuiAvatar-root": {
            width: 20,
            height: 20,
            fontSize: "0.7rem",
          },
          "&:hover": {
            cursor: "pointer",
          },
          // Add some spacing between avatars
          "& .MuiAvatar-groupAvatar": {
            marginRight: "5px",
          },
          alignItems: "center",
          maxWidth: "100%"
        }}
        spacing={"medium"}
        onClick={handleOpen}
      >
        {labels && labels?.length > 0 && labels?.map((label, i) => (
          <Fade key={i} in={open}>
            <Box sx={{ minWidth: "45px", maxWidth: "100px" }}>
              <Chip
                label={label.name}
                size="small"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  height: "20px",
                  minWidth: "100%",
                  fontSize: "0.7125rem",
                  boxShadow:
                    i === 0
                      ? "none"
                      : //@ts-ignore
                      `-3px 0px 1px 0px ${theme.palette.boxShadow}`,
                }}
              />
            </Box>
          </Fade>
        ))}
      </AvatarGroup>
      <LabelModal labels={labels} open={openModal} handleClose={handleClose} />
    </>
  );
}

export default Labels