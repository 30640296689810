import React, { useContext } from 'react';
// components/ui
import { IconButton } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';

const FilterButton = () => {
  const { openSidebarReport, setOpeSidebarReport } = useContext(MyReportContext);
  const handleClick = () => {
    setOpeSidebarReport(!openSidebarReport);
  };

  const FilterIcon = openSidebarReport ? FilterAltIcon : FilterAltOutlinedIcon;
  // const FilterIcon = FilterAltIcon

  return (
    <IconButton onClick={handleClick}>
      {<FilterIcon color={'secondary'} fontSize="medium" />}
    </IconButton>
  );
};

export default FilterButton;
