import React, { useEffect } from 'react';
import InstagramProvider from './context/InstagramProvider';
// Redux
import { useDispatch } from 'react-redux';
import { fetchInstagrams } from 'redux/features/instagramSlice/instagramSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, DeletePanel } from './components';

const InstagramContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchInstagrams());
  }, [dispatch]);

  return (
    <InstagramProvider>
      <Box display={'flex'}>
        <AppPanel />
        <DeletePanel />
      </Box>
    </InstagramProvider>
  );
};

export default InstagramContainer;
