// Components/ui
import { style } from './style';
import { Box, Typography, useTheme } from '@mui/material';
// Types
import { MessageDirection, MessageReaction } from '@trii/types/dist/Common/Messages';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { useContext } from 'react';
import { Reaction } from './components';

type ReactionsProps = {
  data: MessageReaction[];
  direction: MessageDirection;
};
const Reactions = ({ data, direction }: ReactionsProps) => {
  const theme = useTheme();

  const {
    messageReactions: { countReactions },
  } = useContext(messagesContext);

  const reactionsWithCount = countReactions(data);

  return (
    <Box
      sx={{
        ...style.container,
        backgroundColor:
          direction === MessageDirection.IN
          //@ts-ignore
            ? (theme) => theme.palette.background.dropdownMenu
            : //@ts-ignore
              theme.palette.messageBox,
              right: direction === MessageDirection.OUT && "10px"
      }}
    >
      {reactionsWithCount.map((reaction, i) => (
        <Reaction
          key={i}
          data={reaction}
          onlyOneReaction={reactionsWithCount.length === 1 && reaction.amount === 1}
        />
      ))}
    </Box>
  );
};

export default Reactions;
