// Components/ui
import { Box, ListItemButton, ListItemText, Radio, Typography } from '@mui/material';
// Types
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { IContactAddress } from '@trii/types/dist/Contacts';
// Redux
import { useSelector } from 'react-redux';
import { selectChannels } from 'redux/features/conversationsSlice/conversationsSlice';

interface ListSelectorProps {
  list: IContactAddress[];
  selectedId: string;
  onSelect: (item: string[]) => void;
  channelSelectedType:
    | ChannelType.FACEBOOK
    | ChannelType.INSTAGRAM
    | ChannelType.WEBCHAT;
}

const NonCrossOriginListSelector = ({
  list,
  selectedId,
  onSelect,
  channelSelectedType,
}: ListSelectorProps) => {
  const channels = useSelector(selectChannels);

  function getChannelName(contactAddress: IContactAddress) {
    switch (channelSelectedType) {
      case ChannelType.FACEBOOK:
        const channelFacebook = channels.find(
          (channel) => channel.facebook.fbPageId === contactAddress.channelId
        );

        return channelFacebook?.name;

      case ChannelType.INSTAGRAM:
        const channelInstagram = channels.find(
          (channel) => channel.instagram.igAccountId === contactAddress.channelId
        );

        return channelInstagram?.name;
      case ChannelType.WEBCHAT:
        const channelWebchat = channels.find(
          (channel) => channel.webChat.token === contactAddress.channelId
        );

        return channelWebchat?.name;

      default:
        return null;
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
    >
      {list?.map((item) => {
        const channelName = getChannelName(item);

        return (
          <ListItemButton
            key={item.id}
            sx={{
              width: '100%',
            }}
            onClick={() => onSelect([item.id])}
            selected={selectedId === item.id}
            disabled={!channelName}
          >
            <ListItemText primary={item.profileName} />
            <Box>
              <Typography variant="body2">
                {channelName ? channelName : ''}
              </Typography>
              <Radio
                checked={selectedId === item.id}
                disabled={!channelName}
                color="primary"
                inputProps={{ 'aria-label': item.profileName }}
              />
            </Box>
          </ListItemButton>
        );
      })}
    </Box>
  );
};

export default NonCrossOriginListSelector;
