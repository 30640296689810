import React, { useContext } from 'react';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
import { GroupContext } from 'features/Views/Groups/context/types';
import { Group } from 'redux/features/groupSlice/types';
// Redux
import { useSelector } from 'react-redux';
import {
  selectGroupEditStatus,
  selectSelectedGroup,
} from 'redux/features/groupSlice/groupSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeEditPanel, sendEditedGroup, editGroupNameField } =
    useContext<GroupContext>(InitGroupContext);
  const selectedGroup: Group = useSelector(selectSelectedGroup);
  const editStatus: RequestStatus = useSelector(selectGroupEditStatus);
  const isLoading: boolean = editStatus === 'loading';
  const isDisabled: boolean =
    editStatus === 'loading' || !editGroupNameField.attributes.value;
  
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeEditPanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        size="small"
        // color="success"
        // sx={{ 
        //   backgroundColor: (theme) => theme.palette.success.main,
        //   '&:hover': {
        //     backgroundColor: (theme) => theme.palette.success.dark,
        //   }
        // }}
        disabled={isDisabled}
        onClick={() => sendEditedGroup(selectedGroup.id)}
      >
        {t('global.save')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
