import { useEffect, useState, useContext } from 'react';
// components/ui
import { Typography, useTheme, Button, Box, IconButton } from '@mui/material';
// Icons
import { Delete } from '@mui/icons-material';
// Types
import { IEvent } from '@trii/types/dist/Calendar';
// Moment
import moment from 'moment';
// Context
import { ActivitiesCardContext } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/context/ActivitiesCardContext';
const FORMAT_DATE = 'DD/MM/YYYY HH:mm';

interface EventItemProps {
  event: IEvent;
}

const EventItem = ({ event }: EventItemProps) => {
  const {
    openEditActivityPopUp,
    handleDeleteActivity,
  } = useContext(ActivitiesCardContext);
  const theme = useTheme()
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');

  const handleEdit = () => {
    openEditActivityPopUp(event);
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleDeleteActivity(event.id);
  }

  useEffect(() => {
    const newStartDate = moment(event.startAt).format(FORMAT_DATE);
    const newEndDate = moment(event.endAt).format(FORMAT_DATE);
    setStart(newStartDate);
    setEnd(newEndDate);
  })

  return (
    <Button
      sx={{
        display: 'flex',
        gap: 0.5,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
      }}
      onClick={handleEdit}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            mb: 0.5,
            fontSize: 12,
            color: 'text.primary',
          }}
        >
          {event.title}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            color: 'text.primary',
          }}
        >
          {start} - {end}
        </Typography>
      </Box>
      <IconButton
        onClick={handleDelete}
      >
        <Delete />
      </IconButton>
    </Button>
  );
};

export default EventItem;
