import React, { useEffect } from 'react';
import FlowProvider from './context/FlowProvider';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchFlows } from 'redux/features/flowSlice/flowSlice';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, CrudPanel } from './components';

const FlowContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchFlows());
  }, [dispatch]);

  return (
    <FlowProvider>
      <Box display={'flex'}>
        <AppPanel />
        <CrudPanel />
      </Box>
    </FlowProvider>
  );
};

export default FlowContainer;
