import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField } from '@mui/material';

const ApiUrl = () => {
  const { selectNode, setApiNode, apiNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [apiUrl, setApiUrl] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.api) {
      setApiUrl(selectNode.data.api.apiUrl ? selectNode.data.api.apiUrl : '');
    }
  }, [selectNode]);

  const handleChange = (apiUrl: string) => {
    setApiUrl(apiUrl);
    setApiNode({
      ...apiNode,
      apiUrl,
    });
  };

  return (
    <>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label={t('editFlowsView.api.apiUrl')}
        value={apiUrl}
        sx={{
          mt: 1,
        }}
        onChange={(e) => handleChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default ApiUrl;
