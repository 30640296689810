import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewEmail } from 'redux/features/emailSlice/types/NewEmail';

const createEmailInitialState = {
  input: {
    nameField: fieldInitialDef,
    emailField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    sendEmail: () => {},
    resetAll: () => {},
    getNewEmail: () => ({} as NewEmail),
  },
  attributes: {
    creationError: false,
  },
};
export default createEmailInitialState;
