import React, { useContext } from 'react';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
// Types
import { GroupContext } from 'features/Views/Groups/context/types';
import { Group } from 'redux/features/groupSlice/types';
// Components/ui
import { Box, Stack, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IActionButtonsProps {
  group: Group;
}

const ActionButtons = ({ group }: IActionButtonsProps) => {
  const { openDeletePanel, openEditPanel } =
    useContext<GroupContext>(InitGroupContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      justifyContent="space-evenly"
    >
      <Stack direction="row">
        <IconButton
          sx={{ color: (theme) => theme.palette.primary.main }}
          onClick={() => openEditPanel(group)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => openDeletePanel(group.id)}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ActionButtons;
