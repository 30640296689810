import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  FormControlLabel,
} from '@mui/material';
import {
  StyledReminderItemContainer,
  StyledReminderInputsContainer,
  StyledReminderTextField,
  StyledReminderSelect,
  StyledReminderActionsContainer,
  StyledReminderDeleteIconButton,
} from './style';
import DeleteIcon from '@mui/icons-material/Delete';
// Utils
import { selectOptions } from './utils/selectOptions';
// Types
import { EventNotificationFront } from 'redux/features/activitiesSlice/types/EventNotificationFront';
import { ChangeEvent } from 'react';
// Context
import { ActivitiesCardContext } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/context/ActivitiesCardContext';

type ReminderItemProps = {
  data: EventNotificationFront;
  timeValueHandler: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    notificationId: string
  ) => void;
  timePeriodHandler: (
    event: SelectChangeEvent<unknown>,
    notificationId: string
  ) => void;
  sendToContactHandler: (
    event: ChangeEvent<HTMLInputElement>,
    notificationId: string
  ) => void;
  deleteHandler: (notificationId: string) => void;
};

const ReminderItem = ({
  data,
  timeValueHandler,
  timePeriodHandler,
  sendToContactHandler,
  deleteHandler,
}: ReminderItemProps) => {
  const { isEditing, showDetails } = useContext(ActivitiesCardContext);
  const { t } = useTranslation();

  return (
    <StyledReminderItemContainer>
      <StyledReminderInputsContainer>
        {t('conversations.sidebar.events.sendReminder.pt1')}
        <StyledReminderTextField
          disabled={!isEditing && showDetails}
          onChange={(e) => timeValueHandler(e, data.id)}
          value={data.timeValue}
          type="text"
          variant="outlined"
          size="small"
        />
        <StyledReminderSelect
          disabled={!isEditing && showDetails}
          onChange={(e) => timePeriodHandler(e, data.id)}
          value={data.timePeriod}
          variant="outlined"
          size="small"
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {t(`conversations.sidebar.events.timePeriod.${option.title}`)}
            </MenuItem>
          ))}
        </StyledReminderSelect>
        {t('conversations.sidebar.events.sendReminder.pt2')}
      </StyledReminderInputsContainer>
      <StyledReminderActionsContainer>
        <FormControlLabel
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          disabled={!isEditing && showDetails}
          label={t(`conversations.sidebar.events.sendReminderToContact`)}
          control={
            <Checkbox
              checked={data.sendToContact}
              onChange={(e) => sendToContactHandler(e, data.id)}
            />
          }
        />
        <StyledReminderDeleteIconButton
          disabled={!isEditing && showDetails}
          onClick={() => deleteHandler(data.id)}
          sx={{
            ml: 'auto',
            color: 'text.disabled',
          }}
        >
          <DeleteIcon />
        </StyledReminderDeleteIconButton>
      </StyledReminderActionsContainer>
    </StyledReminderItemContainer>
  );
};

export default ReminderItem;
