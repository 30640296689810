import { useTranslation } from 'react-i18next';
// Functions
import setChannelSchedule from '../../../../redux/functions/setChannelSchedule';
// Types
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import { ChangeScheduleFn } from '../../../../redux/types/ChangeScheduleFn';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { selectScheduleData } from '../../../../redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  selectedSchedule: ISchedule | null;
  changeSchedule: ChangeScheduleFn;
}

const ChannelScheduleSelect = ({ selectedSchedule, changeSchedule }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scheduleData = useSelector(selectScheduleData);

  const handleScheduleChange = (e: SelectChangeEvent<string>) => {
    // @ts-ignore
    dispatch(setChannelSchedule(e.target.value, changeSchedule));
  };

  const MenuItems = scheduleData.map((schedule) => (
    <MenuItem key={schedule.id} value={schedule.id}>
      {schedule.name}
    </MenuItem>
  ));

  return (
    <FormControl sx={{ mb: 1.5, mt: 0, ml: 1.5, width: '55%' }}>
      <Typography fontSize={14} sx={{ mb: 0.5 }}>
        {t('channelEditView.detailsPanel.schedule')}
      </Typography>
      <Select
        onChange={handleScheduleChange}
        value={selectedSchedule?.id || ''}
        size="small"
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
            },
          },
        }}
      >
        {MenuItems}
      </Select>
    </FormControl>
  );
};

export default ChannelScheduleSelect;
