import { useContext } from "react"
// Transition
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Button, ButtonGroup, IconButton, TextField } from "@mui/material"
// Icons
import { AddBox, Cancel } from "@mui/icons-material"
import { useState } from "react"
// Context
import { conversationsContext } from "features/Views/Conversations/context/ConversationsProvider/ConversationsProvider"

const AddNote = () => {
  const {
    contactInfo,
    setNewNote
  } = useContext(conversationsContext)
  const [addNote, setAddNote] = useState(false)
  const [value, setValue] = useState('')
  const { t } = useTranslation()

  const handleAddNote = () => {
    setAddNote(!addNote)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleSaveNote = () => {
    const data = {
      contactId: contactInfo.id,
      text: value,
    }
    setNewNote(data)
    setValue('')
    setAddNote(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {
        addNote && (
          <TextField
            sx={{
              width: '100%',
              height: '100%',
              '& .MuiInputBase-root': {
                height: '100%',
                '& .MuiInputBase-input': {
                  height: '100%',
                },
              },
            }}
            onChange={handleChange}
            value={value}
            variant="outlined"
            multiline
            autoFocus
            placeholder={t('conversations.sidebar.notes.create')}
            size="small"
            maxRows={4}
          />
        )
      }
      {
        !addNote ? (
          <Button
            startIcon={<AddBox />}
            variant="contained"
            size="small"
            sx={{
              display: !addNote ? 'flex' : 'none',
              width: '100%',
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 'bold',
              animation: 'fadeIn 0.5s ease-in-out forwards',
            }}
            onClick={handleAddNote}
          >
            {t('conversations.sidebar.notes.create')}
          </Button>
        ) : (
          <ButtonGroup
            size="small"
          >
            <IconButton
              disabled={!value}
              onClick={handleSaveNote}
            >
              <AddBox
                sx={{
                  color: value ? 'primary.main' : 'text.disabled',
                }}
              />
            </IconButton>
            <IconButton
              onClick={handleAddNote}
            >
              <Cancel
                sx={{
                  color: 'text.disabled',
                }}
              />
            </IconButton>
          </ButtonGroup>
        )
      }
    </Box>
  )
}

export default AddNote