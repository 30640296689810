import React, { useContext } from 'react';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
import { GroupContext } from 'features/Views/Groups/context/types';
// Redux
import { useSelector } from 'react-redux';
import { selectGroupCreateStatus } from 'redux/features/groupSlice/groupSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeCreatePanel, sendGroup, createGroupNameField } =
    useContext<GroupContext>(InitGroupContext);
  const createStatus: RequestStatus = useSelector(selectGroupCreateStatus);
  const isLoading: boolean = createStatus === 'loading';
  const isDisabled: boolean =
    createStatus === 'loading' || !createGroupNameField.attributes.value;
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeCreatePanel}

      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        // sx={{ 
        //   backgroundColor: (theme) => theme.palette.success.main,
        //   '&:hover': {
        //     backgroundColor: (theme) => theme.palette.success.dark,
        //   }
        // }}
        disabled={isDisabled}
        onClick={sendGroup}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
