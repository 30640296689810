import { useContext, useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { TreeView, TreeItem } from '@mui/x-tree-view';
// Icons
import { ChevronRight, ExpandMore } from "@mui/icons-material";
// Context
import { QuickResponseContext } from "features/Views/QuickResponse/context/QuickResponseContext";
// Types
import { EditionType } from "features/Views/QuickResponse/context/types/EditionType";
// Slice
import {
  selectCategories,
  selectQuickResponses,
  selectCategoriesFetchStatus,
  selectQuickResponsesFetchStatus,
} from "redux/features/quickResponseSlice/quickResponseSlice";
import { IQuickResponse, IQuickResponseCategory } from "@trii/types/dist/Conversations";
import { Branch } from "./components";

const TreeCategories = () => {
  const { t } = useTranslation();
  const {
    deleteCategory,
    deleteQuickResponse,
    getCategories,
    getQuickResponses,
    setCategorySelected,
    setEditionType,
    setQuickResponseSelected,
  } = useContext(QuickResponseContext)
  const categories = useSelector(selectCategories);
  const categoriesFetchStatus = useSelector(selectCategoriesFetchStatus);
  const quickResponses = useSelector(selectQuickResponses);
  const quickResponsesFetchStatus = useSelector(selectQuickResponsesFetchStatus);
  const isLoading = categoriesFetchStatus === 'loading' || quickResponsesFetchStatus === 'loading';
  console.log('isLoadincategoriesFetchStatusg', categoriesFetchStatus, 'quickResponsesFetchStatus', quickResponsesFetchStatus)
  const [categoryList, setCategoryList] = useState<IQuickResponseCategory[]>([])
  const [quickResponseList, setQuickResponseList] = useState<IQuickResponse[]>([])

  const handleClick = (
    type: EditionType,
    category: IQuickResponseCategory,
    response: IQuickResponse
  ) => {
    if (category) {
      setCategorySelected(category)
    } else {
      setCategorySelected(null)
    }
    if (response) {
      setQuickResponseSelected(response)
    } else {
      setQuickResponseSelected(null)
    }
    setEditionType(type)
  }

  useEffect(() => {
    getCategories()
    getQuickResponses()
  }, [])

  useEffect(() => {
    if (categories) {
      setCategoryList(categories)
    }
  }, [categories])

  useEffect(() => {
    if (quickResponses) {
      setQuickResponseList(quickResponses)
    }
  }, [quickResponses])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'inherit',
        color: (theme) => theme.palette.text.primary,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          mb: 1,
          height: '3rem',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography>
          {t('quickResponse.categories.title')}
        </Typography>
        <Button
          size="small"
          variant="contained"
          onClick={() => handleClick('category', null, null)}
        >
          {t('quickResponse.categories.add')}
        </Button>
      </Box>

      {
        isLoading ? (
          <CircularProgress
            size={24}
            sx={{
              margin: 'auto',
            }}
          />
        ) : (
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            sx={{
              width: '100%',
              overflowY: 'auto',
            }}
          >
            {
              categoryList.map((category) => (
                <TreeItem
                  key={category.id}
                  nodeId={category.id}
                  label={
                    <Branch
                      title={category.name}
                      handleAdd={() => handleClick('quickResponse', category, null)}
                      handleDelete={() => deleteCategory(category.id)}
                    />
                  }
                  onClick={() => handleClick('category', category, null)}
                >
                  {
                    quickResponseList.map((response) => response.categoryId === category.id && (
                      <TreeItem
                        key={response.id}
                        nodeId={response.id}
                        label={
                          <Branch
                            title={response.title}
                            hideAdd
                            handleDelete={() => deleteQuickResponse(response.id)}
                          />
                        }
                        onClick={() => handleClick('quickResponse', null, response)}
                      />
                    ))
                  }
                </TreeItem>
              ))
            }
          </TreeView>
        )
      }
    </Box>
  )
}

export default TreeCategories