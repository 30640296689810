import React from 'react';
import mercadoLibreLogoImage from '../utils/images/mercadoLibreLogo.png';

interface Props {
  size: number;
}

function MercadoLibreLogoIcon({ size }: Props) {
  return <img src={mercadoLibreLogoImage} alt="Mercado Libre Logo" width={size} />;
}

export default MercadoLibreLogoIcon;
