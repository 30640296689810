import React, { useContext, useEffect } from 'react';
import { smsContext } from 'features/Views/Sms/context/SmsProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { CreateButtons } from './components';
import { CrudContainer } from 'components';
import { PanelHeader } from 'components';
import CreateInputs from './components/CreateInputs';

const CreatePanel = () => {
  const { endCreating, createSmsState } = useContext(smsContext);
  const { t } = useTranslation();

  useEffect(() => {
    const cleanUp = () => {
      createSmsState.actions.resetAll();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader title={t('smsView.addSms')} handleCloseButton={endCreating} />
      <Divider />
      <CreateInputs createSmsState={createSmsState} />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <CreateButtons />
    </CrudContainer>
  );
};

export default CreatePanel;
