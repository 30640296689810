import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  fetchSms,
  selectSmsFetchStatus,
  selectSms,
  selectSmsUpdateStatus,
  updateSms,
  selectSmsName,
} from 'redux/features/smsEditSlice/smsEditSlice';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { ViewContainer, ChannelEditHeader } from 'components';
import { Body } from './components';
import { Box, Divider } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';

const SmsEditContainer = () => {
  const { id } = useParams<{ id: string }>();
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const smsFetchStatus = useSelector(selectSmsFetchStatus);
  const smsUpdateStatus = useSelector(selectSmsUpdateStatus);
  const sms = useSelector(selectSms);
  const smsName = useSelector(selectSmsName);

  useEffect(() => {
    dispatch(fetchSms(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      smsFetchStatus === 'succeeded'
    ) {
      setAllFetched(true);
    }
  }, [groupFetchStatus, scheduleFetchStatus, smsFetchStatus]);

  return (
    <ViewContainer>
      <Box boxShadow={1}>
        <ChannelEditHeader
          channelName={smsName}
          updateChannel={updateSms}
          channelUpdateStatus={smsUpdateStatus}
          channelFetchStatus={smsFetchStatus}
        >
          <SmsIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>
        <Divider />
        <Body data={sms} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default SmsEditContainer;
