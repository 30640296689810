import React, { useEffect } from 'react';
import WebChatProvider from './context/WebChatProvider';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchWebChats } from 'redux/features/webChatSlice/webChatSlice';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, CrudPanel } from './components/index.js';

const WebChatContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchWebChats());
  }, [dispatch]);

  return (
    <WebChatProvider>
      <Box display={'flex'}>
        <AppPanel />
        <CrudPanel />
      </Box>
    </WebChatProvider>
  );
};

export default WebChatContainer;
