import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography, Box, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// Icons
import InfoIcon from '@mui/icons-material/Info';

const Query = () => {
  const { selectNode, setSqlQueryNode, sqlQueryNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');

  const TooltipPers = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // @ts-ignore
      backgroundColor: theme.palette.background.tooltip,
    },
    [`& .${tooltipClasses.arrow}`]: {
      // @ts-ignore
      color: theme.palette.background.tooltip,
    },
  })) as typeof Tooltip;


  useEffect(() => {
    if (selectNode && selectNode.data.sqlQuery) {
      setQuery(selectNode.data.sqlQuery.query ? selectNode.data.sqlQuery.query : '');
    }
  }, [selectNode]);

  const handleChangeQuery = (query: string) => {
    setQuery(query);
    setSqlQueryNode({
      ...sqlQueryNode,
      query,
    });
  };

  return (
    <>
      <Box display="flex" gap={0.2} alignItems="center">
        <TooltipPers
          arrow
          title={t('editFlowsView.sqlQuery.queryTooltip')}
          placement="top"        
        >
          <InfoIcon
            style={{ fontSize: 'medium' }}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </TooltipPers>
        <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
          {t('editFlowsView.sqlQuery.query')}
        </Typography>
      </Box>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={query}
        onChange={(e) => handleChangeQuery(e.target.value)}
        multiline
        rows={4}
      />
    </>
  );
};

export default Query;
