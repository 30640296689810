import React from 'react';
// Components/ui
import { Box } from '@mui/material';
import { Header, ScheduleTable } from './components';

const Holidays = () => {
    return (
        <Box>
            <Header />
            <ScheduleTable />
        </Box>
    );
};

export default Holidays;
