import { useTranslation } from 'react-i18next';
// Types
import { WebChatWidgetPosition } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeWidgetPosition } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import OptionsContainer from '../../../OptionsContainer';

interface Props {
  widgetPosition: WebChatWidgetPosition;
}

const AlignWidgetOptions = ({ widgetPosition }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleChangeWidgetPositionLeft = () => {
    dispatch(changeWidgetPosition(0));
  };
  const handleChangeWidgetPositionRight = () => {
    dispatch(changeWidgetPosition(1));
  };

  return (
    <OptionsContainer
      title={t('webChatEditView.designPanel.widgetOptionSection.alignWidget')}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <FormControlLabel
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          control={<Checkbox checked={widgetPosition === 0} size="small" />}
          label={t('global.left')}
          labelPlacement="start"
          onChange={handleChangeWidgetPositionLeft}
        />
        <FormControlLabel
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          control={<Checkbox checked={widgetPosition === 1} size="small" />}
          label={t('global.right')}
          labelPlacement="start"
          onChange={handleChangeWidgetPositionRight}
        />
      </Box>
    </OptionsContainer>
  );
};

export default AlignWidgetOptions;
