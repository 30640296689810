import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
//Types
import { IChannel } from '@trii/types/dist/Common/Channels';
import { SendVerCodeData } from './types/SendVerCodeData';
import { VerifyEmailData } from './types/VerifyEmailData';
import { VerifyEmailStatus } from './types/VerifyEmailStatus';
import { IGoogleAccountData } from '../googleBusinessSlice/types/IGoogleAccountData';

const fetchEmail = async (
  jwt: string,
  URL: string,
  id: string
): Promise<IChannel> => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/email/${id}`, config);
  return response.data;
};
const updateEmail = async (jwt: string, URL: string, email: IChannel) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const emailJson = JSON.stringify(email);

  const response = await axios.put(
    `${URL}/channels/email/${email.id}`,
    emailJson,
    config
  );
  return response.data;
};
async function sendVerCode(jwt: string, URL: string, data: SendVerCodeData) {
  const config = getRequestConfig.contentTypeJson(jwt);
  const { channelId, email } = data;
  await axios.post(
    `${URL}/channels/email/${channelId}/sendVerificationCode`,
    { email },
    config
  );
}

async function verifyEmail(
  jwt: string,
  URL: string,
  channelId: string,
  data: { email: string; code: number }
) {
  const config = getRequestConfig.contentTypeJson(jwt);

  const res = await axios.post<{
    status: VerifyEmailStatus;
    message: string;
  }>(`${URL}/channels/email/${channelId}/verifyCode`, data, config);

  return res.data;
}
async function testSendEmail(jwt: string, URL: string, channelId: string,) {
  const config = getRequestConfig.basic(jwt);

  try {
    const res = await axios.post(`${URL}/channels/email/${channelId}/testSendEmail`, {}, config);
    return res;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
async function testSMTP(jwt: string, URL: string, channelId: string, smtpData: {
  outboundSmtSecurityType: number;
  outboundSmtpServer: string;
  outboundSmtpPort: string;
  outboundSmtpUser: string;
  outboundSmtPasword: string;
}) {
  const config = getRequestConfig.basic(jwt);

  try {
    // Envia los datos smtpData en el cuerpo de la solicitud
    const res = await axios.post(`${URL}/channels/email/${channelId}/testSmtp`, smtpData, config);
    return res;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
async function testTrii(jwt: string, URL: string, channelId: string,) {
  const config = getRequestConfig.basic(jwt);

  try {
    const res = await axios.post(`${URL}/channels/email/${channelId}/testTrii`, {}, config);
    return res;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
async function disconnectGoogle(jwt: string, URL: string, channelId: string) {
  const config = getRequestConfig.basic(jwt);

  try {
    const res = await axios.post(
      `${URL}/channels/email/${channelId}/disconnectGoogle`,
      {},
      config
    );
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
async function connectGoogle(
  jwt: string,
  URL: string,
  channelId: string,
  data: IGoogleAccountData,
) {
  const config = getRequestConfig.basic(jwt);

  try {
    const res = await axios.post(
      `${URL}/channels/email/${channelId}/connectGoogle`,
      data,
      config
    );
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

const emailEditService = {
  fetchEmail,
  sendVerCode,
  updateEmail,
  verifyEmail,
  testSendEmail,
  testSMTP,
  testTrii,
  disconnectGoogle,
  connectGoogle,
};

export default emailEditService;
