import React, { useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Redux
import { useSelector } from 'react-redux';
import { selectAllFacebooks } from 'redux/features/facebookSlice/facebookSlice';
import { selectAllSms } from 'redux/features/smsSlice/smsSlice';
import { selectAllInstagrams } from 'redux/features/instagramSlice/instagramSlice';
import { selectAllWebChats } from 'redux/features/webChatSlice/webChatSlice';
import { selectAllWhatsApps } from 'redux/features/whatsAppSlice/whatsAppSlice';
import {
  selectChannelSelectValue,
  setChannelSelectValue,
} from 'redux/features/editFlowSlice/editFlowSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ListSubheader,
} from '@mui/material';
import { fetchTemplates } from 'redux/features/templatesSlice/templatesSlice';

const Channel = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { textNode, setTextNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);

  const channelSelectValue = useSelector(selectChannelSelectValue);

  const facebooks = useSelector(selectAllFacebooks);
  const sms = useSelector(selectAllSms);
  const instagrams = useSelector(selectAllInstagrams);
  const webChats = useSelector(selectAllWebChats);
  const whatsApps = useSelector(selectAllWhatsApps);

  const groupedChannels = {
    Facebook: facebooks,
    SMS: sms,
    Instagram: instagrams,
    WebChat: webChats,
    WhatsApp: whatsApps,
  };

  const renderSelectGroup = (group, channels) => {
    const items = channels.map((channel) => (
      <MenuItem
        key={channel.id}
        value={channel.id}
      
      >
        {channel.name}
      </MenuItem>
    ));
    return [
      <ListSubheader sx={{ height: '30px', lineHeight: '30px', 
       }} key={group}>
        {group}
      </ListSubheader>,
      ...items,
    ];
  };

  // useEffect(() => {
  //   if (
  //     selectNode &&
  //     selectNode.data &&
  //     selectNode.data.msgText &&
  //     selectNode.data.msgText.channelId
  //   ) {
  //     const defaultChannelId = selectNode.data.msgText.channelId as string;
  //     setChannelId(defaultChannelId);
  //   }
  // }, [selectNode]);

  const handleChangeChannel = (value: string) => {
    // setChannelId(value);
    setTextNode({ ...textNode, channelId: value });

    dispatch(setChannelSelectValue(value));
    dispatch(fetchTemplates(value));
  };

  return (
    <Box>
      <FormControl fullWidth size="small">
        <InputLabel id="channel" shrink={true}>
          {t("editFlowsView.text.channel")}
        </InputLabel>
        <Select
          labelId="channel"
          id="demo-simple-select"
          value={channelSelectValue}
          label={t("editFlowsView.text.channel")}
          onChange={(e) => handleChangeChannel(e.target.value)}
          MenuProps={{
            PaperProps: {
              sx: {
                "& ul": {
                  paddingY: "0",
                  backgroundColor:
                    //@ts-ignore
                  theme => theme.palette.background.panel,
                },
                "& ul li.MuiListSubheader-root": {
                  backgroundColor:(
                    //@ts-ignore
                    theme => theme.palette.background.mainCenter),
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))' 
                },
              },
            },
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
          }}
        >
          {Object.entries(groupedChannels).flatMap(([group, channels]) =>
            renderSelectGroup(group, channels)
          )}
        </Select>
      </FormControl>
      <Box mt={1}>
        <Typography
          variant="caption"
          fontStyle="italic"
          sx={{ opacity: 0.7 }}
          color={(theme) => theme.palette.text.disabled}
        >
          {t("editFlowsView.text.channelDescription")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Channel;
