import { Box, Typography } from '@mui/material';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

const Facebook = () => {
  const { t } = useTranslation();

  return (
    <Button>
      <NavLink to="/a/conversations/system/facebook">
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'center'}
          padding={2}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <Box marginBottom={4}>
            <FacebookIcon sx={{ fontSize: '2.5rem' }} />
          </Box>
          <Typography variant={'body2'}>{t('settingsView.facebook')}</Typography>
        </Box>
      </NavLink>
    </Button>
  );
};

export default Facebook;
