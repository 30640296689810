import * as React from 'react';
// Components/ui
import { SetUpStepper } from './components';
import style from './style';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

function SetUpModal({ isOpen, handleClose }: Props) {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <SetUpStepper handleClose={handleClose} />
      </Box>
    </Modal>
  );
}

export default SetUpModal;
