import React, { useContext } from 'react';
import { formContext } from '../../context/FormProvider';
// Components/ui
import { CreatePanel, DeletePanel } from './components/index.js';

function CrudPanel() {
  const { crudPanelState } = useContext(formContext);

  return (
    <>
      {crudPanelState === 'create' && <CreatePanel />}
      {crudPanelState === 'delete' && <DeletePanel />}
    </>
  );
}

export default CrudPanel;
