import React, { useContext, useEffect } from 'react';
import { endingContext } from '../../context/EndingProvider';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components';

function CrudPanel() {
  const {
    crudPanelState,
    getGroups,
  } = useContext(endingContext);

  useEffect(() => {
    getGroups();
  }, [])

  return (
    <>
      {crudPanelState === 'create' && <CreatePanel />}
      {crudPanelState === 'delete' && <DeletePanel />}
      {crudPanelState === 'edit' && <EditPanel />}
    </>
  );
}

export default CrudPanel;
