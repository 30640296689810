import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import MyReportContextProvider, {
  MyReportContext,
} from '../../context/MyReportContext';
// Components/ui
import { Box, IconButton, Breadcrumbs, Typography } from '@mui/material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { FilterButton, SidebarFilter } from './components';
import ModalProgramSend from './components/ModalProgramSend/ModalProgramSend';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
// Redux
import {
  fetchJsReport,
  selectJsReport,
} from 'redux/features/reportsSlice/reportsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
// Typeguards
import { jsReportDataTemplateNameTypeGuard } from 'redux/features/reportsSlice/types/JsReportData';

const ReportDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const jsReport = useSelector(selectJsReport);
  console.log(jsReport);

  const [pdfUrl, setPdfUrl] = useState('');

  const componentRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get('r');

  let breadcrumbParam = '';

  useEffect(() => {
    if (jsReport) {
      const url = URL.createObjectURL(jsReport);
      setPdfUrl(url);

      // Cleanup to revoke the object URL when the component unmounts
      return () => URL.revokeObjectURL(url);
    }
  }, [jsReport]);

  switch (paramValue) {
    case 'responsetimes':
      breadcrumbParam = t('myReports.responseTimes');
      break;
    case 'registers':
      breadcrumbParam = t('myReports.registers');
      break;
    case 'satisfactionsurvey':
      breadcrumbParam = t('myReports.satisfactionSurvey');
      break;
    case 'flowstatistic':
      breadcrumbParam = t('myReports.flowStatistic');
      break;
    case 'channeldetails':
      breadcrumbParam = t('myReports.detailsPerChannel');
      break;
    case 'userperformance':
      breadcrumbParam = t('myReports.userPerformance');
      break;
    case 'summary':
      breadcrumbParam = t('myReports.summary');
      break;
    case 'finals':
      breadcrumbParam = t('myReports.finals');
      break;
    case 'detailsbyusergroup':
      breadcrumbParam = t('myReports.detailsUserGroup');
      break;
    case 'timedistribution':
      breadcrumbParam = t('myReports.timeDistribution');
      break;
    default:
      breadcrumbParam = '';
      break;
  }

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/MyReports">
      <Typography variant="body2" fontStyle="italic" color="secondary">
        {t('header.myReports')}
      </Typography>
    </NavLink>,
    <Typography
      variant="body2"
      fontStyle="italic"
      color="secondary"
      sx={{ cursor: 'default' }}
    >
      {breadcrumbParam}
    </Typography>,
  ];
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const isTemplateName = jsReportDataTemplateNameTypeGuard(paramValue);
    if (isTemplateName) {
      dispatch(
        fetchJsReport({
          templateName: paramValue,
          moduleName: 'conversations',
          filter: {
            timeRange: 1,
            timeStart: '2021-09-01T00:00:00.000Z',
            timeEnd: '2021-09-30T23:59:59.999Z',
            channelType: [],
            contactName: '',
            users: [],
            groups: [],
          },
        })
      );
    }
  }, []);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  return (
    <MyReportContextProvider>
      <MyReportContext.Consumer>
        {(myReportContext) => {
          if (!myReportContext) {
            throw new Error(
              'ReportDetails must be used within a MyReportContextProvider'
            );
          }

          const {
            openModalProgramSend,
            setOpenModalProgramSend,
            openSidebarReport,
            appliedPeriod,
            appliedChannels,
            appliedPeriodType,
            appliedContactName,
            appliedUsers,
            appliedGroups,
          } = myReportContext;

          const formatPeriod =
            appliedPeriodType === 0 ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';

          const desde = appliedPeriod ? appliedPeriod[0].format(formatPeriod) : '';
          const hasta = appliedPeriod ? appliedPeriod[1].format(formatPeriod) : '';

          const [fromDate, toDate] = appliedPeriod;

          const fromUnix = fromDate.unix();
          const toUnix = toDate.unix();

          const openModal = () => {
            setOpenModalProgramSend(true);
          };

          const closeModal = () => {
            setOpenModalProgramSend(false);
          };

          const usersString = appliedUsers.join(', ');
          const groupsString = appliedGroups.join(', ');
          const channelsString = appliedChannels?.join(', ');

          return (
            <Box
              display="flex"
              width="100%"
              height="100%"
              flexDirection="column"
              className="conversations-fadeinup"
            >
              {/* Header */}
              <Box
                display="flex"
                gap={12}
                alignItems="center"
                justifyContent="space-between"
                bgcolor="background.default"
                color="text.primary"
                sx={{
                  backgroundImage:
                    'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                }}
                boxShadow="0px 1px 2px rgba(0,0,0,0.2)"
                borderRadius={0.6}
                height="5%"
                py={1}
                px={0}
              >
                <Box display="flex" alignItems="center">
                  {/* FILTRO ICONO */}
                  <FilterButton />
                  {/* BREADCRUMB */}
                  <Breadcrumbs
                    separator="-"
                    aria-label="breadcrumb"
                    sx={{ marginLeft: 1 }}
                  >
                    {breadcrumbs.map((breadcrumb, index) =>
                      // Check if breadcrumb is defined before rendering it
                      breadcrumb ? <Box key={index}>{breadcrumb}</Box> : null
                    )}
                  </Breadcrumbs>
                </Box>
                {/* PERIODO */}
                <Box display="flex" alignItems="baseline">
                  {t('myReports.period')}:{' '}
                  <Typography
                    ml={1}
                    variant="body2"
                    color="text.primary"
                    fontWeight={500}
                  >
                    {desde} - {hasta}
                  </Typography>
                </Box>
                <Box>
                  {/* DOWNLOAD ICON */}
                  <IconButton sx={{ color: 'text.disabled' }}>
                    <SimCardDownloadIcon />
                  </IconButton>
                  {/* PRINT ICON */}
                  <ReactToPrint
                    trigger={() => (
                      <IconButton
                        onClick={handlePrint}
                        sx={{ color: 'text.disabled' }}
                      >
                        <PrintIcon />
                      </IconButton>
                    )}
                    content={() => componentRef?.current}
                  />
                  {/* CONFIG ICON */}
                  <IconButton onClick={openModal} sx={{ color: 'text.disabled' }}>
                    <ScheduleSendIcon />
                  </IconButton>
                  <ModalProgramSend
                    open={openModalProgramSend}
                    handleClose={closeModal}
                  />
                </Box>
              </Box>
              {/* Body */}
              <Box
                display="flex"
                mt={1}
                height="calc(95% - 11px)"
                maxHeight="95%"
                width="100%"
              >
                <SidebarFilter isFilterPanelOpen={openSidebarReport} />
                <Box
                  sx={{
                    flexGrow: 1,
                    height: '100%',
                    width: '85%',
                    overflow: 'auto',
                    backgroundColor: 'background.default',
                    backgroundImage:
                      'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                    borderRadius: '2px',
                    boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
                    marginLeft: openSidebarReport ? '8px' : '0px',
                  }}
                  p={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {pdfUrl ? (
                    <iframe
                      src={pdfUrl}
                      title="PDF Viewer"
                      width="100%"
                      height="600px"
                      style={{ border: 'none' }}
                    />
                  ) : (
                    <p>Loading PDF...</p>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      </MyReportContext.Consumer>
    </MyReportContextProvider>
  );
};

export default ReportDetails;
