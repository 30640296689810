import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Box } from '@mui/material';

const BearerToken = () => {
  const { selectNode, setApiNode, apiNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [fixToken, setFixToken] = useState<string>('');
  const [dynamicUrl, setDynamicUrl] = useState<string>('');
  const [dynamicBody, setDynamicBody] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.api && selectNode.data.api.apiAuthBearer) {
      setFixToken(selectNode.data.api.apiAuthBearer.fixToken);
      setDynamicUrl(selectNode.data.api.apiAuthBearer.dynamicUrl);
      setDynamicBody(selectNode.data.api.apiAuthBearer.dynamicBody);
    }
  }, [selectNode]);

  useEffect(() => {
    if (fixToken && dynamicBody && dynamicUrl) {
      setApiNode({
        ...apiNode,
        apiAuthBearer: {
          fixToken,
          dynamicUrl,
          dynamicBody,
        },
      });
    }
  }, [fixToken, dynamicBody, dynamicUrl]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={fixToken}
        onChange={(e) => setFixToken(e.target.value)}
        label={t('editFlowsView.api.authType.bearer.fixToken')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={dynamicUrl}
        onChange={(e) => setDynamicUrl(e.target.value)}
        label={t('editFlowsView.api.authType.bearer.dynamicUrl')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={dynamicBody}
        onChange={(e) => setDynamicBody(e.target.value)}
        label={t('editFlowsView.api.authType.bearer.dynamicBody')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default BearerToken;
