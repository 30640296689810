import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
// Components/ui
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// Redux
import { useSelector } from 'react-redux';
import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';
import { selectGroups } from 'redux/features/conversationsSlice/conversationsSlice';

const GroupSelectorFilter = () => {
  const { t } = useTranslation();

  const groupsSelector = useSelector(selectGroups);

  const { setSelectedGroups } = useContext(MyReportContext);

  const [groups, setGroups] = useState([]);

  const handleChange = (event) => {
    setSelectedGroups(event.target.value);
    setGroups(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} id="group-selector-label">
        {t('global.groups')}{' '}
      </InputLabel>
      <Select
        labelId="group-selector-label"
        label={t('global.groups')}
        id="group-selector"
        multiple
        value={groups}
        onChange={handleChange}
        size="small"
        fullWidth
        sx={{
          '& legend': {
            maxWidth: '100%',
          },
        }}
      >
        {groupsSelector?.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GroupSelectorFilter;
