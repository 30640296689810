// Components/ui
import {
  Box,
  CircularProgress,
} from "@mui/material"
// Components
import Card from "../Card";

interface Props {
  children: React.ReactNode;
  title: string;
  subTitle: string;
  color?: string;
  isLoading?: boolean;
  showActions?: boolean;
}

const FormHeader = ({
  children,
  title,
  subTitle,
  color = null,
  isLoading = false,
  showActions = true,
}: Props) => (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      p: 2,
      gap: "1rem",
    }}
  >
    {
      isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Card
          title={title}
          subTitle={subTitle}
          color={color}
          isTitle={true}
          showActions={showActions}
        />
      )
    }
    {
      children
    }
  </Box>
)

export default FormHeader