// Translate
import { useTranslation } from 'react-i18next';
// Inteface
import {
    NodeMsgWaitResponseConditionSource,
    NodeMsgWaitResponseConditionOperator,
} from '@trii/types/dist/Conversations/Flows/Nodes';
// Components/ui
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Divider,
    Typography,
    IconButton,
    Modal,
} from '@mui/material';
// Icons
import { Add, Delete } from '@mui/icons-material';
// Utils
import { conditionSource, conditionOperator } from '../../utils';
// Components
import { ActionButtons } from './components';

const AddCondition = ({
    source,
    setSource,
    operator,
    setOperator,
    valueList,
    setValueList,
    outputValue,
    setOutputValue,
    handleSaveCondition,
    conditionId,
    handleCancel,
    open
}: {
    source: NodeMsgWaitResponseConditionSource,
    setSource: (value: NodeMsgWaitResponseConditionSource) => void,
    operator: NodeMsgWaitResponseConditionOperator,
    setOperator: (value: NodeMsgWaitResponseConditionOperator) => void,
    valueList: string[],
    setValueList: (value: string[]) => void,
    outputValue: string,
    setOutputValue: (value: string) => void,
    handleSaveCondition: (id: string) => void,
    conditionId: string,
    handleCancel: () => void,
    open: boolean,
}) => {
    const { t } = useTranslation();
    const handleChange = (index: number, value: string) => {
        const newAddValue = [...valueList];
        newAddValue[index] = value;
        setValueList(newAddValue);
    }
    const handleDelete = (index: number) => {
        const newAddValue = [...valueList];
        newAddValue.splice(index, 1);
        setValueList(newAddValue);
    }

    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //@ts-ignore
            bgcolor: (theme) => theme.palette.background.dropdownMenu,
          
            boxShadow: 10,
            p: 2,
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "50%",
            width: "40%",
          }}
        >
          <Box display="flex" gap={1} width="100%">
            <FormControl fullWidth size="small">
              <InputLabel id="source">
                {t("editFlowsView.waitResponse.source")}
              </InputLabel>
              <Select
                labelId="source"
                id="demo-simple-select"
                value={source}
                label={t("editFlowsView.waitResponse.source")}
                onChange={(e) =>
                  setSource(
                    e.target.value as NodeMsgWaitResponseConditionSource
                  )
                }
                sx={{
                  "& legend": {
                    maxWidth: "fit-content",
                    //   padding: '0 10px'
                  },
                  "& .MuiSvgIcon-root": {
                    color: "text.disabled",
                  },
                }}
              >
                {conditionSource?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="operator">
                {t("editFlowsView.waitResponse.operator")}
              </InputLabel>
              <Select
                labelId="operator"
                id="demo-simple-select"
                value={operator}
                label={t("editFlowsView.waitResponse.operator")}
                onChange={(e) =>
                  setOperator(
                    e.target.value as NodeMsgWaitResponseConditionOperator
                  )
                }
                sx={{
                  "& legend": {
                    maxWidth: "fit-content",
                    //   padding: '0 10px'
                  },
                  "& .MuiSvgIcon-root": {
                    color: "text.disabled",
                  }
                }}
              >
                {conditionOperator?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className="fadein"
              variant="outlined"
              size="small"
              label={t("editFlowsView.waitResponse.outputValue")}
              fullWidth
              value={outputValue}
              onChange={(e) => setOutputValue(e.target.value as string)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              alignItems: "center",
              width: "100%",
              margin: ".5rem 0",
            }}
          >
            <Typography variant="subtitle2" color="text.primary">
              {t("editFlowsView.waitResponse.addValues")}
            </Typography>
            <IconButton
              size="small"
              onClick={() => setValueList([...valueList, ""])}
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <Add />
            </IconButton>
          </Box>
          <TableContainer sx={{ height: "100%",  "& table thead tr th": {
            zIndex: "2",
            //@ts-ignore
            bgcolor: (theme) => theme.palette.background.dropdownMenu,
          }, }}>
            {valueList.length ? (
              <Table aria-label="simple table" stickyHeader size="small" >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {t("editFlowsView.waitResponse.value")}
                    </TableCell>
                    <TableCell align="center">{t("global.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                {valueList.map((value, index) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell align="center">
                        <TextField
                          id="value"
                          className="fadein"
                          variant="outlined"
                          size="small"
                          label={t("editFlowsView.waitResponse.value")}
                          fullWidth
                          value={value}
                          onChange={(e) => {
                            handleChange(index, e.target.value as string);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(index)}
                          sx={{ color: "text.disabled" }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            ) : (
              <></>
            )}
          </TableContainer>
          <ActionButtons
            handleCancel={handleCancel}
            handleSaveCondition={handleSaveCondition}
            conditionId={conditionId}
          />
        </Box>
      </Modal>
    );
};

export default AddCondition;