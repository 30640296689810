import React, { ReactNode } from 'react';
// components/ui
import { Box, Typography, Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface HistorySectionProps {
  children: ReactNode;
}

const HistorySection = ({  children }: HistorySectionProps) => {
  return (
      <Box display={'flex'} gap={0.5} flexDirection={'column'} >
        {children}
      </Box>
  );
};

export default HistorySection;
