// Components/ui
import { Box, Typography } from '@mui/material';
import { MessageReactionWithCount } from 'features/Views/Conversations/context/MessagesProvider/types/MessageReactionWithCount';
import { Markdown } from 'functions/Markdown';

type ReactionProps = {
  data: MessageReactionWithCount;
  onlyOneReaction: boolean;
};

const Reaction = ({ data, onlyOneReaction }: ReactionProps) => {
  const markdown = Markdown(data?.emoji, false);
  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          fontSize: '12px',
        }}
        dangerouslySetInnerHTML={{ __html: markdown }}
      >    
      </Box>
      {!onlyOneReaction && (
        <Typography variant="caption" color="textSecondary">
          {data.amount}
        </Typography>
      )}
    </>
  );
};

export default Reaction;
