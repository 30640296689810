import { fieldInitialDef } from 'utils/fieldInitialDef';
import selectColorInitialDef from 'utils/selectColorInitialDef';
import { ILabel } from '@trii/types/dist/Conversations';
import { NewLabel } from 'redux/features/labelSlice/types/NewLabel';

const createLabelInitialState = {
  input: {
    nameField: fieldInitialDef,
    colorSelect: selectColorInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewLabel: () => ({} as NewLabel),
    setToBeEditedLabel: (label: ILabel) => {},
  },
};
export default createLabelInitialState;
