import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography } from '@mui/material';

const SaveAs = () => {
  const { selectNode, setApiNode, apiNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [saveAs, setSaveAs] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.api) {
      setSaveAs(selectNode.data.api.saveAs ? selectNode.data.api.saveAs : '');
    }
  }, [selectNode]);

  const handleChange = (saveAs: string) => {
    setSaveAs(saveAs);
    setApiNode({
      ...apiNode,
      saveAs,
    });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.api.saveAs.title')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={saveAs}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={t('editFlowsView.api.saveAs.placeholder')}
        helperText={t('editFlowsView.api.saveAs.helperText')}
        sx={{
          "& .MuiFormHelperText-root": {
            variant: "caption",
            fontStyle: "italic",
            opacity: 0.7,
          },
        }}
      />
    </>
  );
};

export default SaveAs;
