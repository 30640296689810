import useField from 'hooks/useField';
import useColor from 'hooks/useColor';
// Types
import { UseCreateLabel } from './types';
import { NewLabel } from 'redux/features/labelSlice/types/NewLabel';
import { ILabel } from '@trii/types/dist/Conversations';

const useCreateLabel = (): UseCreateLabel => {
  const nameField = useField('text');
  const colorSelect = useColor();

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    colorSelect.actions.resetValue();
  };
  const getNewLabel = (): NewLabel => {
    return {
      name: nameField.attributes.value,
      color: colorSelect.attributes.value,
    };
  };
  const setToBeEditedLabel = (label: ILabel) => {
    nameField.actions.changeValue(label.name ? label.name : '');
    colorSelect.actions.changeValue(label.color ? label.color : 'ffffff');
  };
  //

  return {
    actions: {
      resetAll,
      getNewLabel,
      setToBeEditedLabel,
    },
    input: {
      nameField,
      colorSelect,
    },
  };
};

export default useCreateLabel;
