import { useContext, useEffect } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import { Box } from "@mui/material";
// Components
import {
  AgentsCard,
  GraphicFinalsCard,
  GroupUserCard,
  MembersCard,
  RecordsCard,
  RegisterModal,
  SimpleListCard,
  TimesCard
} from "./components";
// Slice
import { dashboardData } from "redux/features/dashboardSlice/dashboardSlice";
// Context
import { DashboardContext } from "../../context/DashboardContext";
// Types
import { TimeRange } from "@trii/types/dist/Conversations";

const GeneralDashboard = () => {
  const {
    userDashboard,
    getAllDataDashboard,
  } = useContext(DashboardContext);

  const { t } = useTranslation();
  const dataRedux = useSelector(dashboardData);

  const [
    generalData,
    recordsData,
    endingsData,
    membersData,
    channelsData,
    quantityData,
    timesData,
    labelsData,
  ] = dataRedux;

  useEffect(() => {
    if (userDashboard && (
      userDashboard.userId && userDashboard.groupId &&
      userDashboard.timeRange
    )) {
      //@ts-ignore
      if (userDashboard.timeRange === TimeRange.CUSTOM) {
        if (userDashboard.timeStart && userDashboard.timeEnd) {
          getAllDataDashboard(userDashboard.groupId);
        }
      } else {
        getAllDataDashboard(userDashboard.groupId);
      }
    }
  }, [userDashboard]);

  return (
    <>
      {/* cards */}
      <Box className="card-container">
        <Box
          className="conversations-row"
          sx={{ justifyContent: "center", pt: "10px" }}
        >
          <Box className="conversations-col-lg-12">
            <GroupUserCard generalData={generalData} />
          </Box>
        </Box>
        <Box
          className="conversations-row"
          sx={{ justifyContent: "center", pt: "10px" }}
        >
          {Array.isArray(recordsData) &&
            recordsData.map((item, index) => {
              return (
                <Box className="conversations-col-3" key={index}>
                  <RecordsCard recordsData={item} />
                </Box>
              );
            })}
        </Box>
        <Box
          className="conversations-row"
          sx={{ justifyContent: "center", pt: "10px" }}
        >
          <Box className="conversations-col-6">
            <Box
              className="conversations-row"
              sx={{ justifyContent: "center" }}
            >
              <Box className="conversations-col-12">
                <GraphicFinalsCard chartData={endingsData} />
              </Box>
            </Box>
            <Box
              className="conversations-row"
              sx={{ justifyContent: "center", pt: "10px" }}
            >
              <Box className="conversations-col-6" pt={"10px"}>
                <SimpleListCard
                  id="channels"
                  title={t("dashboard.channels.title")}
                  data={channelsData}
                />
              </Box>
              <Box className="conversations-col-6" pt={"10px"}>
                <SimpleListCard
                  id="tagList"
                  title={t("dashboard.tagList")}
                  data={labelsData}
                />
              </Box>
            </Box>
          </Box>
          <Box className="conversations-col-6">
            {membersData && (
              <MembersCard
                title={t("dashboard.members")}
                data={membersData}
              />
            )}
            <Box className="conversations-row">
              {quantityData &&
                Object.entries(quantityData).map(([index, value]) => {
                  const title = index.replace("Quantity", "");
                  return (
                    <Box
                      key={index}
                      className="conversations-col-6"
                      pt={"10px"}
                    >
                      <AgentsCard quantityData={{ title, value }} />
                    </Box>
                  );
                })}

              <Box className="conversations-col-12" pt={"10px"}>
                <TimesCard
                  title={t("dashboard.time")}
                  data={timesData}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <RegisterModal />
    </>
  )
}

export default GeneralDashboard