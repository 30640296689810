import { Box, Card, CardActionArea, Typography, useTheme } from '@mui/material'
import React from 'react'

const Button = ({children}) => {
    const theme = useTheme()
    return (
        <Box
            component={Card}
            borderRadius={2}
            width={"fit-content"}
            // marginRight="15px"
            data-aos={'fade-up'}
            minWidth={"200px"}
            backgroundColor={theme.palette.background.default}
         
        >
            <CardActionArea>
                {children}
            </CardActionArea>
        </Box>
    )
}

export default Button