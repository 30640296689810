import React, { useContext, useEffect } from "react";
// Router
import { useParams, useNavigate } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import { Box, CircularProgress, IconButton } from "@mui/material";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Context
import { DashboardProvider } from "./context/DashboardContext";
import { DataContext } from "context/contextApi";
// Components
import {
  Channels,
  DashboardSelector,
  Forms,
  GeneralDashboard,
  HeaderActions,
  Messages,
} from "./components";
import { getSpaces } from "redux/features/spacesSlice/spacesSlice";
// Slice
import { dashboardStatus } from "redux/features/dashboardSlice/dashboardSlice";
import { useOpenCallPanelWidth } from "features/Layout/MainLayout/utils/useOpenCallPanelWidth";

const LOCAL_STORAGE_KEY = "keepSelection";

const Dashboard = () => {
  const { dashboardType } = useParams<{ dashboardType: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dashboardDataStatus = useSelector(dashboardStatus);
  const { isMobile } = useContext(DataContext);
  const { boxDashboardFormsWidth, boxDashboardGeneralWidth } =
    useOpenCallPanelWidth();

  const handleBack = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    navigate("/a/conversations/Dashboard");
  };

  useEffect(() => {
    dispatch(getSpaces());
  }, [dispatch]);

  return (
    <DashboardProvider>
      {!dashboardType ? (
        <DashboardSelector />
      ) : (
        <Box
          p={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: "100%",
          }}
        >
          <Box
            sx={{
              maxWidth:
                dashboardType === "general"
                  ? boxDashboardGeneralWidth
                  : '100vw'
            }}
          >
            <HeaderActions
              dashboardType={dashboardType}
              handleBack={() => handleBack()}
            />
            {dashboardDataStatus === "loading" ? (
              <Box sx={{ position: "relative", height: "100%" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            ) : (
              (dashboardType === "general" && <GeneralDashboard />) ||
              (dashboardType === "forms" && <Forms />) ||
              (dashboardType === "channels" && <Channels />) ||
              (dashboardType === "messages" && <Messages />)
            )}
          </Box>
        </Box>
      )}
    </DashboardProvider>
  );
};

export default React.memo(Dashboard);
