import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initRequestData } from 'redux/functions/initRequestData';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import dashboardService from './dashboardService';
import { RootState } from 'redux/store';
import { DashboardSlice } from './types/DashboardSlice';
import { BodyDashboard } from '@trii/types/dist/Conversations';
import { DataDashboard } from './types/DataDashboard';
import { DashboardForm } from './types/DashboardForm';

const initialState: DashboardSlice = {
  userData: {    
    timeStart: null,
    timeEnd: null,
    userId: null,
    timeRange: null,
    memberId: null,
    groupId: null
  },
  data: [],
  selectedDate: [],
  dateOption: 1,
  logs: [],
  formsSummary: null,
  channelsSummary: null,
  channelsLogs: null,
  submittedForms: null,
  messagesSummary: null,
  messagesLogs: null,
  downloadUrl: '',
  status: {
    fetch: 'idle',
    update: 'idle',
    logs: 'idle',
    formsSummary: 'idle',
    channelsSummary: 'idle',
    channelsLogs: 'idle',
    messagesSummary: 'idle',
    messagesLogs: 'idle',
    submittedForms: 'idle',
    download: 'idle',
  },
};

export const postAll = createAsyncThunk(
  "dashboard/postAll",
  async (data: BodyDashboard, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.postAll(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }

);
export const postGeneral = createAsyncThunk(
  "dashboard/postGeneral",
  async (data: BodyDashboard, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.postGeneral(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);

export const fetchLogs = createAsyncThunk(
  "dashboard/fetchLogs",
  async (data: DataDashboard, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchLogs(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);

export const fetchSummary = createAsyncThunk(
  "dashboard/fetchSummary",
  async (data: DashboardForm, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchSummary(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);

export const fetchSubmittedForms = createAsyncThunk(
  "dashboard/fetchSubmittedForms",
  async (data: DashboardForm, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchSubmittedForms(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);

export const fetchDownloadForms = createAsyncThunk(
  "dashboard/fetchDownloadForms",
  async (data: DashboardForm, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchDownloadForms(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);

export const fetchSummaryChannels = createAsyncThunk(
  "dashboard/fetchSummaryChannels",
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchSummaryChannels(jwtToken, URL_CONVERSATIONS);
    return response;
  }
);

export const fetchLogsChannels = createAsyncThunk(
  "dashboard/fetchLogsChannels",
  async (chanelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchLogsChannels(jwtToken, URL_CONVERSATIONS, chanelId);
    return response;
  }
);

export const fetchSummaryMessages = createAsyncThunk(
  "dashboard/fetchSummaryMessages",
  async (data: BodyDashboard, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchSummaryMessages(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);
export const fetchSummaryGeneral = createAsyncThunk(
  "dashboard/fetchSummaryMessages",
  async (data: BodyDashboard, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchSummaryGeneral(jwtToken, URL_CONVERSATIONS, data);
    return response;
  }
);

export const fetchLogsMessages = createAsyncThunk(
  "dashboard/fetchLogsMessages",
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await dashboardService.fetchLogsMessages(jwtToken, URL_CONVERSATIONS, channelId);
    return response;
  }
);  

const dashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {
    setSelectedDate: (state, action: PayloadAction<Date[]>) => {
      if (state.userData) {
        state.userData.timeStart = action.payload[0];
        state.userData.timeEnd = action.payload[1];
      } else {
        console.warn('userData is null, cannot set timeStart and timeEnd.');
      }
    },
    setDateOption: (state, action) => {
      state.userData.timeRange = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postGeneral.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        if (state.data.length > 0) {
          const dataCopy = { ...state.data[0] };
          dataCopy.generalDashboards = action.payload;
          state.data[0] = dataCopy;
        }
      })
      .addCase(postGeneral.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      .addCase(postAll.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      })
      .addCase(postAll.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchLogs.pending, (state) => {
        state.status.logs = 'loading';
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.status.logs = 'succeeded';
        state.logs = action.payload;
        console.log('Logs: ', action.payload)
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.status.logs = 'rejected';
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchSummary.pending, (state) => {
        state.status.formsSummary = 'loading';
      })
      .addCase(fetchSummary.fulfilled, (state, action) => {
        state.status.formsSummary = 'succeeded';
        state.formsSummary = action.payload;
        console.log('Summary: ', action.payload)
      })
      .addCase(fetchSummary.rejected, (state, action) => {
        state.status.formsSummary = 'rejected';
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchSubmittedForms.pending, (state) => {
        state.status.submittedForms = 'loading';
      })
      .addCase(fetchSubmittedForms.fulfilled, (state, action) => {
        state.status.submittedForms = 'succeeded';
        state.submittedForms = action.payload;
        console.log('Submitted Forms: ', action.payload)
      })
      .addCase(fetchSubmittedForms.rejected, (state, action) => {
        state.status.submittedForms = 'rejected';
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchDownloadForms.pending, (state) => {
        state.status.download = 'loading';
      })
      .addCase(fetchDownloadForms.fulfilled, (state, action) => {
        state.status.download = 'succeeded';
        state.downloadUrl = action.payload;
        console.log('Download Url: ', action.payload)
      })
      .addCase(fetchDownloadForms.rejected, (state, action) => {
        state.status.download = 'rejected';
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchSummaryChannels.pending, (state) => {
        state.status.channelsSummary = 'loading';
      })
      .addCase(fetchSummaryChannels.fulfilled, (state, action) => {
        state.status.channelsSummary = 'succeeded';
        state.channelsSummary = action.payload;
        console.log('Channels Summary: ', action.payload)
      })
      .addCase(fetchSummaryChannels.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
        state.status.channelsSummary = 'rejected';
      })
      .addCase(fetchLogsChannels.pending, (state) => {
        state.status.channelsLogs = 'loading';
      })
      .addCase(fetchLogsChannels.fulfilled, (state, action) => {
        state.status.channelsLogs = 'succeeded';
        state.channelsLogs = action.payload;
        console.log('Channels Logs: ', action.payload)
      })
      .addCase(fetchLogsChannels.rejected, (state, action) => {
        state.status.channelsLogs = 'rejected';
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchSummaryMessages.pending, (state) => {
        state.status.messagesSummary = 'loading';
      })
      .addCase(fetchSummaryMessages.fulfilled, (state, action) => {
        state.status.messagesSummary = 'succeeded';
        state.messagesSummary = action.payload;
        console.log('Messages Summary: ', action.payload)
      })
      .addCase(fetchSummaryMessages.rejected, (state, action) => {
        state.status.messagesSummary = 'rejected';
        console.log('Error: ', action.error.message);
      })
      .addCase(fetchLogsMessages.pending, (state) => {
        state.status.messagesLogs = 'loading';
      })
      .addCase(fetchLogsMessages.fulfilled, (state, action) => {
        state.status.messagesLogs = 'succeeded';
        state.messagesLogs = action.payload;
        console.log('Messages Logs: ', action.payload)
      })
      .addCase(fetchLogsMessages.rejected, (state, action) => {
        state.status.messagesLogs = 'rejected';
        console.log('Error: ', action.error.message);
      });
  },
});

export const { actions, reducer } = dashboardSlice;
const dashboardState = (state: RootState) => state.Dashboard;
export const dashboardData = createSelector(
  dashboardState,
  (dashboard) => dashboard.data
);
export const dashboardUserData = createSelector(
  dashboardState,
  (dashboard) => dashboard.userData
);
export const dashboardStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.fetch
);
export const dashboardLogs = createSelector(
  dashboardState,
  (dashboard) => dashboard.logs
);
export const dashboardLogsStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.logs
);
export const dashboardFormSummary = createSelector(
  dashboardState,
  (dashboard) => dashboard.formsSummary
);
export const dashboardFormSummaryStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.formsSummary
);
export const dashboardSubmittedForms = createSelector(
  dashboardState,
  (dashboard) => dashboard.submittedForms
);
export const dashboardSubmittedFormsStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.submittedForms
);
export const dashboardDownloadUrl = createSelector(
  dashboardState,
  (dashboard) => dashboard.downloadUrl
);
export const dashboardDownloadFromStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.download
);
export const dashboardChannelsSummary = createSelector(
  dashboardState,
  (dashboard) => dashboard.channelsSummary
);
export const dashboardChannelsSummaryStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.channelsSummary
);
export const dashboardChannelsLogs = createSelector(
  dashboardState,
  (dashboard) => dashboard.channelsLogs
);
export const dashboardChannelsLogsStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.channelsLogs
);
export const dashboardMessagesSummary = createSelector(
  dashboardState,
  (dashboard) => dashboard.messagesSummary
);
export const dashboardMessagesSummaryStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.messagesSummary
);
export const dashboardMessagesLogs = createSelector(
  dashboardState,
  (dashboard) => dashboard.messagesLogs
);
export const dashboardMessagesLogsStatus = createSelector(
  dashboardState,
  (dashboard) => dashboard.status.messagesLogs
);

// Actions
export const { setSelectedDate, setDateOption } = dashboardSlice.actions;
export default dashboardSlice.reducer;