import React from 'react';
//components/ui
import { Box, Typography, Divider } from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';

interface RepairSectionProps {
  title: string;
  children?: React.ReactNode;
}

const RepairSection = ({ title, children }: RepairSectionProps) => {
  return (
    <Box marginBottom={2.5}>
      <Box display={'flex'} alignItems={'center'} gap={0.5}>
        <HandymanIcon sx={{ fontSize: 16, color: 'text.primary' }} />
        <Typography
          sx={{ fontWeight: 600, mb: 0.5, fontSize: 13, color: 'text.primary' }}
        >
          {title}
        </Typography>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box display={'flex'} gap={0.5} flexDirection={'column'} px={0.7}>
        <Typography fontSize={15} color='text.primary'>
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export default RepairSection;
