import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectWebChatsFetchStatus,
  selectAllWebChats,
} from 'redux/features/webChatSlice/webChatSlice';
// Components/ui
import { Box } from '@mui/material';
import { WebChatItem } from './components';
import { ListItemSkeleton } from 'components';

const ListBody = () => {
  const allWebChats = useSelector(selectAllWebChats);
  const webChatsFetchStatus: RequestStatus = useSelector(selectWebChatsFetchStatus);

  const WebChats = allWebChats.map((webChat) => (
    <WebChatItem key={webChat.id} data={webChat} />
  ));

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {webChatsFetchStatus === 'succeeded' ? WebChats : <ListItemSkeleton />}
    </Box>
  );
};

export default ListBody;
