// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
// Icons
import { Campaign, Star, StarBorder } from "@mui/icons-material";
// Types
import {
  IBusiness,
  IContactAddress,
  IContactInfo,
} from "@trii/types/dist/Contacts";
import { useNavigate } from "react-router-dom";

interface ContactProps {
  memberList: IContactInfo[];
  icon: React.ReactNode;
  title: string;
  showCampaign?: boolean;
  showTitle?: boolean;
}

const Members = ({
  memberList,
  icon,
  title,
  showCampaign = false,
  showTitle = true,
}: ContactProps) => {
  const { t } = useTranslation();
  const iconStyle = { color: "text.disabled" };
  const navigate = useNavigate();
  
  const handleNavigateToContact = (contactId) => {
    navigate(`/a/conversations/conversations/${contactId}`)
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "max-content",
        alignItems: "center",
        gap: 1,
      }}
    >
      {showTitle && (
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="semi-bold"
            color={"text.disabled"}
          >
            {t(title)}
          </Typography>
        </Box>
      )}

      {memberList.length > 0 ? (
        memberList.map((contact: IContactInfo) => (
          <Box
            key={contact.id}
            sx={{
              width: "100%",
              height: "max-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "max-content",
                display: "flex",
                alignItems: "center",
                gap: 1,
                maxWidth: "100%",
              }}

            >
              {icon}
              <Tooltip title={contact.name} arrow>
                <Typography
                  variant="subtitle1"
                  fontWeight="semi-bold"
                  fontSize={14}
                  color={"text.disabled"}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavigateToContact(contact.id)}
                >
                  {contact.name}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        ))
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="semi-bold"
            color={"text.disabled"}
          >
            {t('conversations.contactInfo.noContacts')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Members;
