import React from 'react';
import { useTranslation } from 'react-i18next';
//Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeStatusShowWidget } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import DesignSection from '../DesignSection/DesignSection';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Switch, FormControlLabel, Typography } from '@mui/material';

interface Props {
  webChatShowWidget: boolean;
}

const WidgetStateSection = ({ webChatShowWidget }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleShowWidgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeStatusShowWidget(event.target.checked));
  };

  return (
    <DesignSection
      title={t('webChatEditView.designPanel.widgetStateSection.title')}
      icon={<RemoveRedEyeIcon sx={{ fontSize: 17, color: 'primary.main' }} />}
    >
      <FormControlLabel
        sx={{
          width: '50%',
          justifyContent: 'space-between',
        }}
        labelPlacement="start"
        control={<Switch size="small" />}
        label={
          <Typography sx={{ marginRight: 10 }} variant="subtitle2">
            {t('webChatEditView.designPanel.widgetStateSection.show')}
          </Typography>
        }
        onChange={handleShowWidgetChange}
        checked={webChatShowWidget}
      />
    </DesignSection>
  );
};

export default WidgetStateSection;
