import { useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { UserInfoContext } from 'context/UserInfoProvider/UserInfoContext';
// Types
import { UserInfo } from '@trii/types/dist/Users';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchUserById } from 'redux/features/userSlice/userSlice';

export interface UseUserInfoType {
  resetValue: () => void;
  getUserInfo: (userId: string) => Promise<UserInfo>;
}

export const useUserInfo = (): UseUserInfoType => {
  const { usersInfo, setUsersInfo, usersIds, setUsersIds } =
    useContext(UserInfoContext);
  const dispatch = useAppDispatch();

  const resetValue = () => {
    setUsersInfo([]);
    setUsersIds([]);
  };

  const getUserInfo = async (userId: string): Promise<UserInfo> => {
    if (usersInfo && usersInfo.length > 0 && usersIds.includes(userId)) {
      const userInfo = usersInfo.find((user) => user?.id === userId);
      return userInfo;
    }

    usersIds.push(userId);
    const response = await dispatch(fetchUserById(userId));
    if (response && response.payload) {
      const userInfo = response.payload as UserInfo;
      setUsersInfo((prev) => {
        const ids = prev.map((user) => user?.id);
        if (!ids.includes(userInfo.id)) {
          return [...prev, userInfo];
        }
        return prev;
      });
      return userInfo;
    }
  };

  return {
    resetValue,
    getUserInfo,
  };
};
