import React from 'react';
// components/ui
import { Button } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

interface AddButtonProps {
  label: string;
}

const AddButton = ({ label }: AddButtonProps) => {
  return (
    <Button
      startIcon={<AddBoxIcon />}
      variant="contained"
      size="small"
      sx={{
        width: '100%',
        textTransform: 'none',
        fontSize: '12px',
        fontWeight: 'bold',
        mb: 3,
      }}
    >
      {label}
    </Button>
  );
};

export default AddButton;
