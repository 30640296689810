import { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import {
  Box,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@mui/material';
// Icons
import { Add } from '@mui/icons-material';
//Components
import { Filter } from './components';
// Interfaces
import { NodeStartTrigger } from '@trii/types/dist/Conversations/Flows/Nodes';

type ConditionComponent = {
  id: string;
  filter: React.ReactNode;
};

const FieldsCondition = ({ typeTrigger }: { typeTrigger: number }) => {
  const { selectNode } = useContext<EditFlowsContext>(InitEditFlowsContext);
  const [disableAddCondition, setDisableAddCondition] = useState<boolean>(true);
  const [filters, setFilters] = useState<ConditionComponent[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      selectNode?.data?.start?.filters?.length &&
      selectNode.data.start?.trigger === typeTrigger
    ) {
      const newFilters = selectNode?.data?.start?.filters.map((filter) => {
        return {
          id: filter.id,
          filter: (
            <Filter
              handleDelete={() => handleDeleteFilters(filter.id)}
              id={filter.id}
              typeTrigger={typeTrigger}
            />
          ),
        };
      });
      setFilters(newFilters);
    } else {
      setFilters([]);
    }
    setDisableAddCondition(typeTrigger === NodeStartTrigger.NULL);
  }, [selectNode, typeTrigger]);

  const handleDeleteFilters = (id: string) => {
    setFilters((prevFilters) => {
      const newFilter = prevFilters?.filter((filter) => filter.id !== id);
      return newFilter;
    });
  };

  const handleAddCondition = () => {
    const id = Math.random().toString(36).substr(2, 9);
    const newFilter = {
      id: id,
      filter: (
        <Filter
          handleDelete={() => handleDeleteFilters(id)}
          id={id}
          typeTrigger={typeTrigger}
        />
      ),
    };
    setFilters([...filters, newFilter]);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: '600', color: 'text.primary' }}
        >
          {t('editFlowsView.start.field')}
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={() => handleAddCondition()}
          disabled={disableAddCondition}
        >
          <Add />
        </IconButton>
      </Box>
      <TableContainer
        sx={{
          maxHeight: '15rem',
          height: '15rem',
          '& table thead tr th': {
            zIndex: '2',
          },
        }}
      >
        {filters.length ? (
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t('editFlowsView.start.field')}
                </TableCell>
                <TableCell align="center">
                  {t('editFlowsView.start.condition.title')}
                </TableCell>
                <TableCell align="center">
                  {t('editFlowsView.start.value.title')}
                </TableCell>
                <TableCell align="center">{t('global.action')}</TableCell>
              </TableRow>
            </TableHead>
            {filters.map((filter) => (
              <TableBody key={filter.id}>{filter.filter}</TableBody>
            ))}
          </Table>
        ) : (
          <></>
        )}
      </TableContainer>
    </Box>
  );
};

export default FieldsCondition;
