// Types
import { FormItemLinearScale } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeQuestionLinearScaleInputFrom,
  changeQuestionLinearScaleInputTo,
  changeQuestionLinearScaleInputFromTitle,
  changeQuestionLinearScaleInputToTitle,
} from 'redux/features/formEditSlice/formEditSlice';
// Components/ui
import { Box, Select, Typography, MenuItem, TextField } from '@mui/material';

const scale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface Props {
  linearScaleData: FormItemLinearScale;
  questionId: string;
}

const LinearScaleInput = ({ linearScaleData, questionId }: Props) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(e.target.value);

    dispatch(
      changeQuestionLinearScaleInputFrom({
        id: questionId,
        value: numericValue,
      })
    );
  };
  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(e.target.value);

    dispatch(
      changeQuestionLinearScaleInputTo({
        id: questionId,
        value: numericValue,
      })
    );
  };
  const handleFromTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeQuestionLinearScaleInputFromTitle({
        id: questionId,
        value: e.target.value,
      })
    );
  };
  const handleToTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeQuestionLinearScaleInputToTitle({
        id: questionId,
        value: e.target.value,
      })
    );
  };

  const FromMenuItems = scale.slice(0, 2).map((item) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  ));
  const ToMenuItems = scale.slice(2, 11).map((item) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  ));

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} pt={3}>
      <Box display={'flex'} gap={3} alignItems={'center'}>
        <Select
          sx={{
            width: '7%',
            textAlign: 'center',
          }}
          variant="standard"
          value={linearScaleData.from}
          onChange={handleFromChange}
        >
          {FromMenuItems}
        </Select>
        <Typography>a</Typography>
        <Select
          sx={{
            width: '7%',
            textAlign: 'center',
          }}
          variant="standard"
          value={linearScaleData.to}
          onChange={handleToChange}
        >
          {ToMenuItems}
        </Select>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} gap={4} alignItems={'flex-end'}>
          <Typography variant={'subtitle1'}>{linearScaleData.from}</Typography>
          <TextField
            variant={'standard'}
            size={'small'}
            sx={{
              width: 260,
            }}
            label={'Etiqueta (opcional)'}
            value={linearScaleData.fromTitle}
            onChange={handleFromTitleChange}
          />
        </Box>
        <Box display={'flex'} gap={4} alignItems={'flex-end'}>
          <Typography variant={'subtitle1'}>{linearScaleData.to}</Typography>
          <TextField
            variant={'standard'}
            size={'small'}
            sx={{
              width: 260,
            }}
            label={'Etiqueta (opcional)'}
            value={linearScaleData.toTitle}
            onChange={handleToTitleChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LinearScaleInput;
