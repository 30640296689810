import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
import {
  selectMercadoLibre,
} from "redux/features/mercadoLibreEditSlice/mercadoLibreEditSlice";
// Components/ui
import { Fade, Chip } from "@mui/material";
import moment from "moment";

function StateChip() {
  const { t } = useTranslation();
  const mercadoLibreAccount = useSelector(selectMercadoLibre);
  const expirationDate =
    mercadoLibreAccount.mercadoLibre.mlRefreshTokenExpiration;
  const [statusLabel, setStatusLabel] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);

    if (expirationDate) {
      const currentDate = moment();
      const expirationMoment = moment(expirationDate);

      if (currentDate.isBefore(expirationMoment)) {
        setStatusLabel(t("global.enabled"));
      } else {
        setStatusLabel(t("global.disabled"));
      }
    } else {
      setStatusLabel(t("global.disabled"));
    }
  }, [expirationDate, t]);

  const enabled = statusLabel === t("global.enabled");

  return (
    <Fade in={show}>
      <Chip
        size="small"
        label={statusLabel}
        sx={{
          backgroundColor: enabled ? "#4ade80" : "#fca5a5",
        }}
      />
    </Fade>
  );
}

export default StateChip;
