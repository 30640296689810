import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from '@mui/material';
import ActionButtons from '../ActionButtons';
import { EmojiPopup, Input } from 'components';
import { Mood } from '@mui/icons-material';
import { TemplatesContext } from 'features/Views/Templates/context/TemplatesContext';
import {
  selectCreateFetchStatus,
  selectUpdateFetchStatus,
} from 'redux/features/quickResponseSlice/quickResponseSlice';
import { SelectChangeEvent } from '@mui/material/Select';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';
import { QuickResponseContext } from 'features/Views/QuickResponse/context/QuickResponseContext';
import {
  fetchGroups,
  selectGroups,
  selectGroupsFetchStatus,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const TemplateEdit = () => {
  const { t } = useTranslation();
  const { setEditionType, selectedTemplate, updateTemplate } =
    useContext(TemplatesContext);
  const theme = useTheme();
  const { getGroups } = useContext(QuickResponseContext);
  const createFetchStatus = useSelector(selectCreateFetchStatus);
  const updateFetchStatus = useSelector(selectUpdateFetchStatus);
  const isCreating = createFetchStatus === 'loading';
  const isUpdating = updateFetchStatus === 'loading';
  const [title, setTitle] = useState<string>('');
  const [messageInput, setMessageInput] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [languageSelected, setLanguageSelected] = useState<string>('');
  const [messageStatus, setMessageStatus] = useState<string>('');
  const [actions, setActions] = useState<string>('');

  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [sharedGroupIds, setSharedGroupIds] = useState<string[]>([]);
  const [groupList, setGroupList] = useState<GroupInfo[]>([]);
  const [name, setName] = useState<string>('');
  const groupsFetchStatus = useSelector(selectGroupsFetchStatus);
  const isLoading = groupsFetchStatus === 'loading';
  const dispatch = useAppDispatch();
  const groups = useSelector(selectGroups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTemplate) {
      setTitle(selectedTemplate.title);
      setLanguageSelected(selectedTemplate.language);
      setMessageStatus(selectedTemplate.messageStatus);
      setActions(selectedTemplate.action);
      setMessageInput(selectedTemplate.content.message);
    } else {
      setTitle('');
      setLanguageSelected('');
      setMessageStatus('');
      setActions('');
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (groups) {
      setGroupList(groups);
    }
  }, [groups]);

  const [newEmoji, setNewEmoji] = useState<string>('');

  const handleClear = () => {
    setEditionType(null);
    setTitle('');
    setMessageInput('');
    setTabValue(0);
  };

  const handleSave = () => {
    if (selectedTemplate) {
      const data = {
        ...selectedTemplate,
        title,
        message: messageInput,
        language: languageSelected,
        messageStatus,
        action: actions,
        isPublic,
        groupSelected: sharedGroupIds,
      };

      updateTemplate(data.id, data);

      return;
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!checked) {
      setSharedGroupIds([]);
    }
    setIsPublic(checked);
  };

  const handleSelect =
    (setState: React.Dispatch<React.SetStateAction<string[]>>) =>
    (value: SelectChangeEvent<string[]>) => {
      const { value: newValue } = value.target;
      setState(typeof newValue === 'string' ? newValue.split(',') : newValue);
    };

  const getGroupName = (id: string) => {
    const group = groupList.find((group) => group.id === id);
    if (group) {
      return group.name;
    }
    return id;
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      setName(selectedTemplate.title);
      setIsPublic(selectedTemplate.isPublic);
      setSharedGroupIds(selectedTemplate.groupSelected);
    } else {
      setName('');
      setIsPublic(false);
      setSharedGroupIds([]);
    }
  }, [selectedTemplate]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100% - 2rem)',
          justifyContent: 'flex-start',
          alignItems: 'start',
          gap: 1,
        }}
      >
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
          <TextField
            label={t('quickResponse.response.title')}
            value={title}
            onChange={handleTitleChange}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{
              height: '2rem',
              width: '100%',
              '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            disabled
          />
          <TextField
            label={t('templates.messageLanguage')}
            value={languageSelected}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{
              height: '2rem',
              width: '100%',
              marginLeft: '30px',
              '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            disabled
          />
          <TextField
            label={t('templates.messageTemplateStatus')}
            value={messageStatus}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{
              height: '2rem',
              width: '100%',
              marginLeft: '30px',
              '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            disabled
          />
          <TextField
            label={t('templates.actions')}
            value={actions}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            sx={{
              height: '2rem',
              width: '100%',
              marginLeft: '30px',
              '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            disabled
          />
        </Box>
        <Tabs
          value={tabValue}
          // onChange={handleTab}
          sx={{
            height: '3rem',
          }}
        >
          <Tab label={t('templates.message')} />
        </Tabs>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
          }}
        >
          {tabValue === 0 && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Input
                  value={messageInput}
                  newEmoji={newEmoji}
                  maxHeigth="inherit"
                  conversationId={''}
                  contactId={''}
                  setValue={setMessageInput}
                  setNewEmoji={() => {}}
                  handleSendMessage={() => {}}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  gap: 1,
                  marginTop: '10px',
                }}
              >
                <Select
                  displayEmpty
                  multiple
                  size="small"
                  value={sharedGroupIds}
                  onChange={handleSelect(setSharedGroupIds)}
                  disabled={!isPublic}
                  input={<OutlinedInput label="Chip" />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      if (isPublic) {
                        return t('templates.selectGroup');
                      } else {
                        return t('templates.private');
                      }
                    }

                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => (
                          <Chip key={value} label={getGroupName(value)} />
                        ))}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    sx: {
                      maxHeight: 300,
                    },
                  }}
                  sx={{
                    width: 'calc(100% - 5rem)',
                  }}
                >
                  <MenuItem disabled value={t('quickResponse.categories.private')}>
                    {t('templates.public')}
                  </MenuItem>
                  {isLoading && (
                    <MenuItem disabled>
                      <CircularProgress
                        size={24}
                        sx={{
                          margin: 'auto',
                        }}
                      />
                    </MenuItem>
                  )}
                  {groupList.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormControlLabel
                  control={<Checkbox checked={isPublic} />}
                  onChange={handleCheck}
                  sx={{
                    width: '5rem',
                  }}
                  label={t('quickResponse.categories.public')}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <ActionButtons
        handleClear={handleClear}
        handleSave={handleSave}
        isLoading={isCreating || isUpdating}
      />
    </>
  );
};

export default TemplateEdit;
