import { useContext, useEffect, useState } from "react"
// Translations
import { useTranslation } from "react-i18next"
// Redux
import { useSelector } from "react-redux"
// Components/ui
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material"
// Icons
import { ArrowBack, Download } from "@mui/icons-material"
// Context
import { DashboardContext } from "../../context/DashboardContext"
// Selector
import { selectAllForms, selectFormsFetchStatus } from "redux/features/formSlice/formSlice"
// Components
import { Filters, GroupSelector, Period, Selector } from "./components"
// Types
import { DashboardForm } from "redux/features/dashboardSlice/types/DashboardForm"

interface Props {
  dashboardType: string
  handleBack: () => void
}

const HeaderActions = ({
  dashboardType,
  handleBack,
}: Props) => {
  const { t } = useTranslation()
  const {
    forms,
    form,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setFormIndex,
    setForms,
    setForm,
    getForms,
    getForm,
    getSummary,
    getSubmittedForms,
    getDownloadUrl,
    getUsers,
    getSummaryMessages,
    getSummaryGeneral
  } = useContext(DashboardContext);
  const newForms = useSelector(selectAllForms);
  const formsStatus = useSelector(selectFormsFetchStatus);
  const isLoading = formsStatus === "loading";
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState<DashboardForm>();
  const [userId, setUserId] = useState("");

  const handleSummary = (formId: string) => {
    if (dashboardType === "forms") {
      const newData = {
        formId,
        timeStart: startDate.toDate(),
        timeEnd: endDate.toDate(),
        userId,
      }
      setData(newData);
      getSummary(newData);
      getSubmittedForms(newData);
    }
  }

  const handleSelectForm = (formId: string) => {
    getForm(formId);
    handleSummary(formId);
    setFormIndex(0);
  }

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGetUrl = async () => {
    setIsDownloading(true);
    const response = await getDownloadUrl(data);
    if (response) {
      handleDownload(response);
    }
    setIsDownloading(false);
  }

  useEffect(() => {
    if (userId) {
      handleSummary(form.id);
    }
  }, [userId]);

  useEffect(() => {
    getForms();
    getUsers();
  }, []);

  useEffect(() => {
    if (newForms && newForms.length > 0) {
      setForms(newForms);
      const firstForm = newForms[0];
      setForm(firstForm);
      handleSummary(firstForm.id);
    }
  }, [newForms]);

  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={'20px'}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          onClick={handleBack}
        >
          <ArrowBack
            sx={{
              color: theme => theme.palette.text.primary,
            }}
          />
        </IconButton>
        {
          dashboardType === "forms" && form && (
            <Selector
              list={forms}
              isLoading={isLoading}
              width="20rem"
              handleSelect={handleSelectForm}
              value={form?.id}
            />
          )
        }
      </Box>
      <Box display={'flex'} >
        {
          dashboardType === "general" && (
            <>
              <GroupSelector />
              <Period getSummarySelectedData={getSummaryGeneral} dashboardType={"general"}/>
            </>
          )
        }
        {
          dashboardType === "messages" && (
            <>
              <Period getSummarySelectedData={getSummaryMessages} dashboardType={"messages"}/>
            </>
          )
        }
        {
          dashboardType === "forms" && (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              {
                isDownloading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Tooltip arrow title={t('dashboard.forms.download')}>
                    <IconButton
                      size="small"
                      sx={{ color: theme => theme.palette.text.primary }}
                      onClick={handleGetUrl}
                    >
                      <Download />
                    </IconButton>
                  </Tooltip>
                )
              }
              <Filters
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setUserId={setUserId}
              />
            </Box>
          )
        }
      </Box>
    </Box >
  )
}

export default HeaderActions