import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectContactData,
  selectContactsStatus,
} from 'redux/features/groupSlice/groupSlice';
import { useTranslation } from 'react-i18next';
// Style
import { StyleContext } from 'style/styleProvider';
// Context
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { GroupContext } from 'features/Views/Groups/context/types';
// Components/ui
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Tooltip,
  Badge,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import BackspaceIcon from '@mui/icons-material/Backspace';
// Types
import { Contact } from 'redux/features/groupSlice/types';

const Contacts = () => {
  const defaultContacts = useSelector(selectContactData);
  const contactStatus = useSelector(selectContactsStatus);
  const { setCreateGroupUsers } =
    useContext<GroupContext>(InitGroupContext);
  const { fontSize, fontWeight } = useContext(StyleContext);
  const [contacts, setContacts] = useState(defaultContacts);
  const [search, setSearch] = useState('');
  const [contactsAdded, setContactsAdded] = useState<Contact[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setCreateGroupUsers(contactsAdded);
  }, [contactsAdded]);

  const handleMouseOver = (id: string) => {
    setContactsAdded((prevContacts) =>
      prevContacts?.map((contact) =>
        contact.id === id ? { ...contact, showDeleteIcon: true } : contact
      )
    );
  };

  const handleMouseOut = (id: string) => {
    setContactsAdded((prevContacts) =>
      prevContacts?.map((contact) =>
        contact.id === id ? { ...contact, showDeleteIcon: false } : contact
      )
    );
  };

  const handleDelete = (id: string) => {
    setContactsAdded((prevContacts) =>
      prevContacts.filter((contact) => contact.id !== id)
    );
  };

  const handleAdd = (id: string) => {
    if (contactsAdded?.find((contact) => contact.id === id)) return;
    const user = contacts.find((contact) => contact.id === id);
    const userObj = {
      ...user,
      showDeleteIcon: false,
    };
    setContactsAdded((prevContacts) => [...prevContacts, userObj]);
  };

  const handleFilter = (value: string) => {
    setSearch(value);
    if (value === '') {
      setContacts(defaultContacts);
      return;
    }
    const filteredContacts = defaultContacts.filter((contact) =>
      contact.name.toLowerCase().includes(value.toLowerCase())
    );
    setContacts(filteredContacts);
  };

  const hadnleClearInput = () => {
    setContacts(defaultContacts);
    setSearch('');
  };

  return (
    <Box display={'flex'} flexDirection="column" alignItems={'start'} pl={2} pr={'15px'}>
      <Typography variant="subtitle2" sx={{color: theme=>theme.palette.text.primary}}>
        {t('settingsView.groups.addParticipants')}
      </Typography>
      {contactsAdded.length ? (
        <Box
          border="1px solid"
          borderColor={(theme) => theme.palette.divider}
          width="100%"
          display="flex"
          overflow="auto"
        >
          {contactsAdded?.map((contact: Contact) => (
            <Tooltip key={contact.id} title={contact.name} arrow>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                invisible={!contact.showDeleteIcon}
                onMouseOver={() => handleMouseOver(contact.id)}
                onMouseOut={() => handleMouseOut(contact.id)}
                badgeContent={
                  <IconButton onClick={() => handleDelete(contact.id)}>
                    <CloseIcon
                      sx={{
                        fontSize: '0.8rem',
                      }}
                    />
                  </IconButton>
                }
              >
                <Avatar
                  sx={{ width: 25, height: 25, margin: 0.5 }}
                  key={contact.id}
                  alt={contact.name}
                  src={contact.imageUrl}
                />
              </Badge>
            </Tooltip>
          ))}
        </Box>
      ) : (
        <></>
      )}
      <Divider sx={{ width: '100%', my: 1 }} />
      <TextField
        id="outlined-basic"
        label={t('settingsView.groups.searchContacts')}
        variant="outlined"
        onChange={(e) => handleFilter(e.target.value)}
        value={search}
        size="small"

        sx={{ width: '100%', marginTop: "5px" }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('settingsView.groups.clear')} arrow>
                <IconButton onClick={() => hadnleClearInput()}>
                  <BackspaceIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Box
        display={'flex'}
        overflow={'scroll'}
        flexDirection="column"
        sx={{ width: '100%', my: 1, overflow: 'auto' }}
      >
        {contactStatus ? (
          contacts?.map((contact, i) => (
            <Box
              display="flex"
              alignItems={'center'}
              justifyContent={'space-between'}
              key={i}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Avatar
                  sx={{ width: 25, height: 25, margin: 0.5 }}
                  alt={contact.name}
                  src={contact.imageUrl}
                />
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.semibold}
                  fontSize={fontSize.xs}
                  sx={{color: theme=>theme.palette.text.secondary}}
                >
                  {contact.name}
                </Typography>
              </Box>
              <IconButton onClick={() => handleAdd(contact.id)}>
                {contactsAdded?.find(
                  (contactAdded) => contactAdded.id === contact.id
                ) ? (
                  <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />
                ) : (
                  <Tooltip
                    title={t('settingsView.groups.add')}
                    arrow
                    placement="left"
                  >
                    <Add sx={{ color: (theme) => theme.palette.primary.main }} />
                  </Tooltip>
                )}
              </IconButton>
            </Box>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Contacts;
