import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useColor from 'hooks/useColor';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeFormColor,
  changeFormDescription,
  changeFormSuccessMessage,
  changeFormTitle,
  addQuestionItem,
} from 'redux/features/formEditSlice/formEditSlice';
// Types
import { FormConfig } from '@trii/types/dist/Common/Channels';
// Components/ui
import { Box, TextField, Button } from '@mui/material';
import { Questions } from './components';
import { ChannelPanel } from 'components';
import { MuiColorInput } from 'mui-color-input';

interface Props {
  formData: FormConfig;
}

const DesignPanel = ({ formData }: Props) => {
  const { t } = useTranslation();
  const colorSelect = useColor(formData.color);
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const createNewQuestion = () => {
    dispatch(addQuestionItem());
  };
  const handleNameTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFormTitle(e.target.value));
  };
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFormDescription(e.target.value));
  };
  const handleSuccessMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFormSuccessMessage(e.target.value));
  };
  const handleColorChange = () => {
    dispatch(changeFormColor(colorSelect.attributes.value));
  };

  useEffect(() => {
    handleColorChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorSelect.attributes.value]);

  return (
    <ChannelPanel title={t('formEditView.designPanel.title')}>
      <Box display={'flex'} width={'100%'} justifyContent={'center'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          sx={{
            width: {
              xs: '100%',
              xl: '60%',
            },
          }}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            label={t('global.title')}
            fullWidth
            value={formData.title}
            onChange={handleNameTitleChange}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            label={t('global.description')}
            fullWidth
            value={formData.subTitle}
            onChange={handleDescriptionChange}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            label={t('formEditView.designPanel.successMessage')}
            fullWidth
            value={formData.successMessage}
            onChange={handleSuccessMessageChange}
          />
          <MuiColorInput
            label={t('labelsView.createEditPanel.color')}
            fullWidth
            size="small"
            {...colorSelect.attributes}
          />
          <Questions questions={formData.items} />
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} py={2}>
            <Button size="small" variant="outlined" onClick={createNewQuestion}>
              {t('formEditView.designPanel.createQuestion')}
            </Button>
          </Box>
        </Box>
      </Box>
    </ChannelPanel>
  );
};

export default DesignPanel;
