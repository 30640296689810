import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeHomeTitle } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import OptionsContainer from '../../../OptionsContainer';
import { TextField } from '@mui/material';

interface Props {
  homeTitle: string;
}

const TitleOptions = ({ homeTitle }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleHomeTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeHomeTitle(event.target.value));
  };

  return (
    <OptionsContainer title={t('global.title')}>
      <TextField
        fullWidth
        size="small"
        label={t('webChatEditView.designPanel.homeSection.addTitle')}
        value={homeTitle}
        onChange={handleHomeTitleChange}
      />
    </OptionsContainer>
  );
};

export default TitleOptions;
