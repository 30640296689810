import { useTranslation } from 'react-i18next';
// Types
import {
  WhatsAppConfig,
  WhatsAppConectionType,
} from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeConectionType } from 'redux/features/whatsAppEditSlice/whatsAppEditSlice';
// Components/ui
import { ChannelPanel } from 'components';
import { Box, Select, Typography, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  TwiloInputs,
  ApiInputs,
  DialogInputs,
  WhatsAppCloudAPIInputs,
} from './components';

interface Props {
  data: WhatsAppConfig;
}

const ConectionPanel = ({ data }: Props) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();

  const { conectionType, status } = data;

  const {
    twilioAccountSid,
    twilioAuthToken,
    twilioNumber,
    apiqrId,
    apiqrToken,
    dialog360AuthToken,
    dialog360NameSpace,
    dialog360Number,
    cloudApiAppToken,
    cloudApiMetaDeveloperAppId,
    cloudApiNameSpace,
    cloudApiSystemUserAccessToken,
    cloudApiWhatsAppBusinessAccountId,
    cloudApiWhatsAppPhoneNumberId,
  } = data;
  const twiloData = {
    twilioAccountSid,
    twilioAuthToken,
    twilioNumber,
  };
  const dialogData = {
    dialog360AuthToken,
    dialog360NameSpace,
    dialog360Number,
  };
  const apiData = {
    apiqrId,
    apiqrToken,
  };
  const cloudApiWhatsAppData = {
    cloudApiAppToken,
    cloudApiMetaDeveloperAppId,
    cloudApiNameSpace,
    cloudApiSystemUserAccessToken,
    cloudApiWhatsAppBusinessAccountId,
    cloudApiWhatsAppPhoneNumberId,
  };

  const handleTypeChange = (event: SelectChangeEvent<WhatsAppConectionType>) => {
    const newValue = event.target.value;

    dispatch(changeConectionType(newValue as WhatsAppConectionType));
  };

  return (
    <ChannelPanel title="Conexion">
      <Box display={'flex'} flexDirection={'column'} gap={4} alignItems={'center'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width="55%"
          ml={2}
        >
          <Typography>Tipo Conexion</Typography>
          <Select
            value={conectionType}
            onChange={handleTypeChange}
            sx={{ width: '70%' }}
            size="small"
            MenuProps={{
              PaperProps: {
                sx: {
                  //@ts-ignore
                  backgroundColor: (theme) => theme.palette.background.panel,
                },
              },
            }}
          >
            <MenuItem value={WhatsAppConectionType.NULL}>
              {t('global.none')}
            </MenuItem>
            <MenuItem value={WhatsAppConectionType.TWILIO}>Twilo</MenuItem>
            <MenuItem value={WhatsAppConectionType.DIALOG360}>360Dialog</MenuItem>
            <MenuItem value={WhatsAppConectionType.APIQR}>api-qr</MenuItem>
            <MenuItem value={WhatsAppConectionType.CLOUDAPI}>
              WhatsApp CloudAPI
            </MenuItem>
          </Select>
        </Box>
        {conectionType === 2 && <TwiloInputs data={twiloData} />}
        {conectionType === 6 && <DialogInputs data={dialogData} />}
        {conectionType === 7 && (
          <WhatsAppCloudAPIInputs data={cloudApiWhatsAppData} />
        )}
        {conectionType === 1 && <ApiInputs status={status} data={apiData} />}
      </Box>
    </ChannelPanel>
  );
};

export default ConectionPanel;
