import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewGoogleBusiness } from 'redux/features/googleBusinessSlice/types/NewGoogleBusiness';

const createGoogleBusinessInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewGoogleBusiness: () => ({} as NewGoogleBusiness),
  },
};
export default createGoogleBusinessInitialState;
