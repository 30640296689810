import { useTranslation } from 'react-i18next';
// Types
import { WebChatWidgetShape } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeWidgetShape,
  changeWidgetTitle,
  changeWidgetSubtitle,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import { Box, FormControlLabel, Checkbox, Skeleton, TextField } from '@mui/material';
import OptionsContainer from '../../../OptionsContainer';

interface Props {
  widgetShape: WebChatWidgetShape;
  widgetTitle: string;
  widgetSubtitle: string;
}

const WidgetDesignOptions = ({
  widgetShape,
  widgetTitle,
  widgetSubtitle,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleChangeWidgetShapeIcon = () => {
    dispatch(changeWidgetShape(WebChatWidgetShape.ONLY_ICON));
  };
  const handleChangeWidgetShapeIconText = () => {
    dispatch(changeWidgetShape(WebChatWidgetShape.ICON_AND_TEXT));
  };
  const handleChangeWidgetTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeWidgetTitle(e.target.value));
  };
  const handleChangeWidgetSubtitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeWidgetSubtitle(e.target.value));
  };

  return (
    <OptionsContainer
      title={t('webChatEditView.designPanel.widgetOptionSection.widgetDesign')}
    >
      <FormControlLabel
        control={<Checkbox checked={widgetShape === 0} size="small" />}
        label={
          <Box
            height={20}
            width={20}
            borderRadius={'50%'}
            bgcolor={'primary.main'}
          />
        }
        labelPlacement="start"
        onChange={handleChangeWidgetShapeIcon}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      />
      <FormControlLabel
        control={<Checkbox checked={widgetShape === 1} size="small" />}
        label={
          <Skeleton
            height={35}
            variant="text"
            width={80}
            sx={{ bgcolor: 'primary.main' }}
            animation={false}
          />
        }
        labelPlacement="start"
        onChange={handleChangeWidgetShapeIconText}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        label={t('webChatEditView.designPanel.widgetOptionSection.widgetTitle')}
        sx={{ width: '100%' }}
        value={widgetTitle}
        onChange={handleChangeWidgetTitle}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        label={t('webChatEditView.designPanel.widgetOptionSection.widgetDetail')}
        sx={{ width: '100%' }}
        value={widgetSubtitle}
        onChange={handleChangeWidgetSubtitle}
      />
    </OptionsContainer>
  );
};

export default WidgetDesignOptions;
