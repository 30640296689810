import React, { useState, useEffect } from 'react';
// Components/ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Fade,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

const Panel = ({ title, children, defaultExpanded }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);
  const theme = useTheme()
  return (
    <Fade in={show}>
      <Accordion defaultExpanded={defaultExpanded} sx={{ backgroundColor: "unset" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "background.accordionHeader" }}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            py: 2.5,
            px: 6,
            //@ts-ignore
            //  backgroundColor: `${theme.palette.background.accordionHeader}`,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Fade>
  );
};

export default Panel;
