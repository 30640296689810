import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

const SpinnerTransparent = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        // backgroundColor: "rgba(255,255,255,0.5)",
        backgroundColor: `${theme.palette.background.default}80`,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        zIndex: "999",
      }}
    >
      <Box sx={{ position: "absolute", top: "45%", right: "47%" }}>
        {/* <Box className="conversations-centered-element-spinner-panel">
        <Box className="spinner-border" role="status"></Box>
      </Box> */}
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default SpinnerTransparent;
