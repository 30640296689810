// Translations
import { useTranslation } from 'react-i18next';
// components/ui
import { Box, Button } from '@mui/material';
const ActionButtons = ({
    handleSaveCondition,
    handleCancel,
    conditionId,
}: {
    handleSaveCondition: (id: string) => void,
    handleCancel: () => void,
    conditionId: string,
}) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'flex-end',
                alignItems: 'end',
                width: '100%',
                marginTop: '1rem',
            }}
        >
            <Button
                variant="contained"
                size="small"
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.dark,
                    }
                }}
                onClick={() => handleCancel()}
            >
                {t('global.cancel')}
            </Button>
            <Button
                variant="contained"
                size="small"
                sx={{
                    backgroundColor: (theme) => theme.palette.success.main,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.success.dark,
                    }
                }}
                onClick={() => handleSaveCondition(conditionId)}
            >
                {t('global.save')}
            </Button>
        </Box>
    );
};

export default ActionButtons 
