import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeSynwayUser,
  changeSynwayPass,
  changeSynwayPort,
  changeSynwayUrl,
} from 'redux/features/smsEditSlice/smsEditSlice';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';

interface Props {
  data: {
    synwayUser: string;
    synwayPass: string;
    synwayUrl: string;
    synwayPort: string;
  };
}

const SynwayInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { synwayUser, synwayPass, synwayUrl, synwayPort } = data;

  const handleSynwayUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSynwayUser(event.target.value));
  };
  const handleSynwayPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSynwayPass(event.target.value));
  };
  const handleSynwayUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSynwayUrl(event.target.value));
  };
  const handleSynwayPortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSynwayPort(event.target.value));
  };

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label={t('global.user')}
        InputLabelProps={{
          shrink: true,
        }}
        value={synwayUser}
        sx={inputFieldStyle}
        onChange={handleSynwayUserChange}
      />
      <TextField
        size="small"
        label={t('global.password')}
        InputLabelProps={{
          shrink: true,
        }}
        value={synwayPass}
        sx={inputFieldStyle}
        onChange={handleSynwayPassChange}
      />
      <TextField
        size="small"
        label={'URL'}
        InputLabelProps={{
          shrink: true,
        }}
        value={synwayUrl}
        sx={inputFieldStyle}
        onChange={handleSynwayUrlChange}
      />
      <TextField
        size="small"
        label={t('global.port')}
        InputLabelProps={{
          shrink: true,
        }}
        value={synwayPort}
        sx={inputFieldStyle}
        onChange={handleSynwayPortChange}
      />
    </ChannelInputsContainer>
  );
};

export default SynwayInputs;
