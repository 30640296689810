import { Box, Typography } from '@mui/material';
import React from 'react';
import WebhookIcon from '@mui/icons-material/Webhook';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import {Button as ButtonDisabled} from '@mui/material'

const WebHook = () => {
  const { t } = useTranslation();

  return (
    <ButtonDisabled disabled    sx={{
      backgroundColor: (theme) => theme.palette.background.default,
      '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
        padding: 0
      }
    }}>
      <Button>
        <NavLink to="/a/conversations/webhooks">
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
            padding={2}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            <Box marginBottom={4}>
              <WebhookIcon sx={{ fontSize: "2.5rem" }} />
            </Box>
            <Typography variant={"body2"}>
              {t("settingsView.webHook")}
            </Typography>
          </Box>
        </NavLink>
      </Button>
    </ButtonDisabled>
  );
};

export default WebHook;
