import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography, Box, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// Icons
import InfoIcon from '@mui/icons-material/Info';

const SqlConnection = () => {
  const { selectNode, setSqlQueryNode, sqlQueryNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [connection, setConnection] = useState<string>('');

  const TooltipPers = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // @ts-ignore
      backgroundColor: theme.palette.background.tooltip,
    },
    [`& .${tooltipClasses.arrow}`]: {
      // @ts-ignore
      color: theme.palette.background.tooltip,
    },
  })) as typeof Tooltip;


  useEffect(() => {
    if (selectNode && selectNode.data.sqlQuery) {
      setConnection(
        selectNode.data.sqlQuery.connectionString
          ? selectNode.data.sqlQuery.connectionString
          : ''
      );
    }
  }, [selectNode]);

  const handleChangeConnection = (value: string) => {
    setConnection(value);
    setSqlQueryNode({
      ...sqlQueryNode,
      connectionString: value,
    });
  };

  return (
    <>
      <Box display="flex" gap={0.2} alignItems="center">
        <TooltipPers
          arrow
          title={t('editFlowsView.sqlQuery.connectionStringTooltip')}
          placement="top"
          // sx={{
          //   maxWidth: '1rem',
          // }}
        >
          <InfoIcon
            style={{ fontSize: 'medium' }}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </TooltipPers>
        <Typography variant="subtitle2" fontWeight={'bold'} color='text.primary'>
          {t('editFlowsView.sqlQuery.connectionString')}
        </Typography>
      </Box>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={connection}
        onChange={(e) => handleChangeConnection(e.target.value)}
        helperText={
          <a
            href="https://www.connectionstrings.com/"
            target="_blank"
            rel="noreferrer"
          >
            {t('editFlowsView.sqlQuery.connectionHelperText')}
          </a>
        }
        sx={{
          "& .MuiFormHelperText-root": {
            variant: "caption",
            fontStyle: "italic",
            opacity: 0.7,
          },
        }}
      />
    </>
  );
};

export default SqlConnection;
