import React, { useRef, RefObject } from 'react';

export interface SectionRefs {
  contactDataRef: RefObject<HTMLElement | null>;
  contactConversationRef: RefObject<HTMLElement | null>;
  contactTicketRef: RefObject<HTMLElement | null>;
  contactNotesRef: RefObject<HTMLElement | null>;
  contactRepairsRef: RefObject<HTMLElement | null>;
  contactFilesRef: RefObject<HTMLElement | null>;
  contactHistoryRef: RefObject<HTMLElement | null>;
  contactEventRef: RefObject<HTMLElement | null>;
  contactButtonsRef: RefObject<HTMLElement | null>[];
}

function useSectionRefs(buttonsData?: any[] | null): SectionRefs {
  const contactDataRef = useRef<HTMLElement | null>(null);
  const contactConversationRef = useRef<HTMLElement | null>(null);
  const contactTicketRef = useRef<HTMLElement | null>(null);
  const contactNotesRef = useRef<HTMLElement | null>(null);
  const contactRepairsRef = useRef<HTMLElement | null>(null);
  const contactFilesRef = useRef<HTMLElement | null>(null);
  const contactHistoryRef = useRef<HTMLElement | null>(null);
  const contactEventRef = useRef<HTMLElement | null>(null);

  const contactButtonsRef = useRef<RefObject<HTMLElement | null>[]>([]);

  if (buttonsData && contactButtonsRef.current.length !== buttonsData.length) {
    contactButtonsRef.current = buttonsData.map(() =>
      React.createRef<HTMLElement | null>()
    );
  }

  return {
    contactDataRef,
    contactConversationRef,
    contactTicketRef,
    contactNotesRef,
    contactRepairsRef,
    contactFilesRef,
    contactHistoryRef,
    contactEventRef,
    contactButtonsRef: contactButtonsRef.current,
  };
}

export default useSectionRefs;
