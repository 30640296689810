import { useTranslation } from 'react-i18next';
//Types
import { Permission } from '@trii/types/dist/Common/Channels';
import {
  ChangePermissionReadMessages,
  ChangePermissionStartConversation,
} from './types';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { getGroup } from '../../../../redux/features/groupSlice/groupSlice';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import {
  Box,
  Card,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUserById, selectUserById } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface Props {
  data: Permission;
  changePermissionReadMessages: ({
    permissionGroupId,
    readMessages,
  }: ChangePermissionReadMessages) => ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
  >;
  changePermissionStartConversation: ({
    permissionGroupId,
    startConversations,
  }: ChangePermissionStartConversation) => ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
  >;
  removePermission: (data: {
    groupId?: string;
    userId?: string;
  }) => ThunkAction<void, RootState, unknown, AnyAction>;
}

const GroupCard = ({
  data,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const group = useSelector(getGroup(data.groupId));
  const user = useSelector(getUserById(data.userId));

  const handleRemovePermission = () => {
    dispatch(removePermission({ groupId: data.groupId, userId: data.userId }));
  };
  const handlePermissionReadMessagesChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      changePermissionReadMessages({
        permissionGroupId: data.groupId,
        permissionContactId: data.userId,
        readMessages: e.target.checked,
      })
    );
  };
  const handlePermissionStartConversationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      changePermissionStartConversation({
        permissionGroupId: data.groupId,
        permissionContactId: data.userId,
        startConversations: e.target.checked,
      })
    );
  };

  return (
    <Card
      sx={{
        width: '55%',
        pb: 1.5,
        ml: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Box
        display={'flex'}
        bgcolor={(theme) => theme.palette.background.default}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={2}
      >
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <GroupsIcon color="primary" />
          <Typography variant={'subtitle2'}>{group?.name || user?.name}</Typography>
        </Box>
        <IconButton
          sx={{
            color: 'error.main',
          }}
          onClick={handleRemovePermission}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box display={'flex'} justifyContent={'space-evenly'}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handlePermissionReadMessagesChange}
              checked={data.readMessages}
            />
          }
          label={t('channelEditView.permissionsPanel.readMessages')}
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={handlePermissionStartConversationChange}
              checked={data.startConversations}
            />
          }
          label={t('channelEditView.permissionsPanel.startConversation')}
          labelPlacement="start"
        />
      </Box>
    </Card>
  );
};

export default GroupCard;
