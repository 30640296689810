import { Box, Card, CardActionArea, Typography } from '@mui/material';
import React from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

const Forms = () => {
  const { t } = useTranslation();

  return (
    <Button>
      <NavLink to="/a/conversations/system/forms">
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'center'}
          padding={2}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <Box marginBottom={4}>
            <ListAltIcon sx={{ fontSize: '2.5rem' }} />
          </Box>
          <Typography variant={'body2'}>{t('settingsView.forms')}</Typography>
        </Box>
      </NavLink>
    </Button>
  );
};

export default Forms;
