import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectLabelsFetchStatus,
  selectAllLabels,
} from 'redux/features/labelSlice/labelSlice';
// Components/ui
import { Box } from '@mui/material';
import { LabelItemSkeleton, LabelItem } from './components';

const ListBody = () => {
  const allLabels = useSelector(selectAllLabels);
  const labelsFetchStatus: RequestStatus = useSelector(selectLabelsFetchStatus);

  const Labels = allLabels.map((label) => <LabelItem key={label.id} data={label} />);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {labelsFetchStatus === 'succeeded' ? Labels : <LabelItemSkeleton />}
    </Box>
  );
};

export default ListBody;
