import React from 'react';
import { Box, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
// import GoogleBusinessIcon from '../../../../../utils/icons/GoogleBusinessIcon';
import GoogleIcon from '@mui/icons-material/Google';
const GoogleBusiness = () => {
  const { t } = useTranslation();
  return (
    <Button>
      <NavLink to="/a/conversations/system/googlebusiness">
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'center'}
          padding={2}
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          <Box marginBottom={4}>
            {/* <GoogleBusinessIcon size={40} /> */}
            <GoogleIcon sx={{ fontSize: '2.5rem' }}/>

          </Box>
          <Typography variant={'body2'}>
            {t('settingsView.googleBusiness')}
          </Typography>
        </Box>
      </NavLink>
    </Button>
  );
};

export default GoogleBusiness;
