import React, { useContext, useEffect } from 'react';
import { endingContext } from 'features/Views/Ending/context/EndingProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, InputLabel, TextField, Divider } from '@mui/material';
import CrudContainer from '../CrudContainer';
import PanelHeader from '../PanelHeader';
import { DeleteButtons } from './components';

function DeletePanel() {
  const { t } = useTranslation();
  const { deleteEndingName, endDeleting, selectedEnding } =
    useContext(endingContext);

  useEffect(() => {
    const cleanUp = () => {
      deleteEndingName.actions.resetValue();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('endingsView.deletionPanel.title')}
        handleCloseButton={endDeleting}
      />
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'center'}
        p={2}
      >
        <InputLabel
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {t('endingsView.deletionPanel.description')}
          <strong style={{ marginLeft: 3 }}>{selectedEnding?.name}</strong>
        </InputLabel>
        <TextField
          {...deleteEndingName.attributes}
          className="fadein"
          variant="outlined"
          size="small"
          fullWidth
          label={t('global.name')}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            marginTop: '15px',
          }}
        />
      </Box>
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <DeleteButtons />
    </CrudContainer>
  );
}

export default DeletePanel;
