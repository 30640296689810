import React, { useContext } from 'react';
import { webHooksContext } from 'features/Views/WebHooks/context/WebHooksProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { CreateButtons } from './components';
import CrudContainer from '../CrudContainer';
import PanelHeader from '../PanelHeader';
import CreateEditPanel from '../CreateEditPanel';

const CreatePanel = () => {
  const { endCreating, createWebHookState } = useContext(webHooksContext);
  const { t } = useTranslation();

  return (
    <CrudContainer>
      <PanelHeader
        title={t('webHooksView.addWebHook')}
        handleCloseButton={endCreating}
      />
      <Divider />
      <CreateEditPanel webHookState={createWebHookState} />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <CreateButtons />
    </CrudContainer>
  );
};

export default CreatePanel;
