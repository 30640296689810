import { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Redux
import { useSelector } from 'react-redux';
// User Selector
import { selectUser } from 'redux/features/userSlice/userSlice';
import moment from 'moment';
import { Box, Checkbox, FormControl } from '@mui/material';
// Components
import { FormType, InfoType, TextType, NoteType, CallType } from './components';
import { IMessage, MessageType } from '@trii/types/dist/Common/Messages';
import { style } from './style';

type MessageItemProps = {
  message: IMessage;
  selectMessagesMode: boolean;
  selectedMessages: IMessage[];
  setUnreadMessagesIds?: (value: SetStateAction<string[]>) => void;
  handleSelectMessage: (message: IMessage) => void;
  setFilesDoneLoading: (value: SetStateAction<boolean>) => void;
};

const MessageItem = ({
  message,
  selectMessagesMode,
  selectedMessages,
  handleSelectMessage,
  setUnreadMessagesIds,
  setFilesDoneLoading,
}: MessageItemProps) => {
  const user = useSelector(selectUser);
  const userId = user?.uid;

  const messageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          'read' in message &&
          message.read === false
        ) {
          // Remove the id from the unread essages ids array
          setUnreadMessagesIds((unreadMessagesIds) =>
            unreadMessagesIds.filter((id) => id !== message.id)
          );
        }
      },
      { threshold: 1.0 }
    );

    if (messageRef.current) {
      observer.observe(messageRef.current);
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
      }
    };
  }, []);

  return (
    <Box key={message.id} id={message.id} ref={messageRef} sx={style.container}>
      <FormControl
        sx={{
          ...style.formControl,
          cursor:
            message.type !== MessageType.N2B && selectMessagesMode && 'pointer',
          backgroundColor: selectedMessages.some(
            (messageSelected) => messageSelected.id === message.id
          )
            ? (theme) => `${theme.palette.primary.light}20`
            : 'transparent',
          '&:hover': {
            backgroundColor:
              selectMessagesMode &&
              message.type !== MessageType.N2B &&
              ((theme) => `${theme.palette.primary.light}20`),
          },
        }}
        onClick={() => handleSelectMessage(message)}
      >
        {message.type !== MessageType.N2B && selectMessagesMode && (
          <Checkbox
            checked={selectedMessages.some(
              (messageSelected) => messageSelected.id === message.id
            )}
          />
        )}
        <Box
          sx={{
            ...style.messageContainer,
            justifyContent:
              message.to !== userId ||
              message.type === MessageType.NOTE ||
              message.type === MessageType.N2B ||
              message.form !== null
                ? 'flex-start'
                : 'space-between',
          }}
        >
          <Box width="100%" sx={style.typeContainer}>
            {message.type !== MessageType.INFO &&
              message.type !== MessageType.CALL && (
                <TextType
                  setFilesDoneLoading={setFilesDoneLoading}
                  message={message}
                />
              )}
            {message.type === MessageType.NOTE && (
              <NoteType text={message.text.body} />
            )}
            {message.form && (
              <FormType form={message.form} timestamp={message.timestamp} />
            )}
            {message.type === MessageType.INFO && (
              <InfoType data={message.info} message={message} />
            )}
            {message.type === MessageType.CALL && <CallType message={message} />}
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
};

export default MessageItem;
MessageItem;
