import { createContext, useState, useEffect } from 'react';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Slice
import {
  editSchedule,
  selectScheduleById,
} from 'redux/features/scheduleSlice/scheduleSlice';
// Types
import { ScheduleEditContext, ScheduleProviderProps } from './types';
import { UseFieldType } from 'hooks/useField';
import { ISchedule, IHoliday, ITimeRange } from '@trii/types/dist/Common/Schedules';
import { useNavigate } from 'react-router-dom';

export const InitScheduleEditContext = createContext<ScheduleEditContext>({
  scheduleNameField: fieldInitialDef,
  scheduleDescriptionField: fieldInitialDef,
  holidays: [],
  setHolidays: () => { },
  timeRanges: [],
  setTimeRanges: () => { },
  sendSchedule: () => { },
  openPanelNewHoliday: false,
  setOpenPanelNewHoliday: () => { },
  handleOpenPanelNewHoliday: () => { },
  handleClosePanelNewHoliday: () => { },
  closeEditPanel: () => { },
});

export const ScheduleEditProvider = ({ children }: ScheduleProviderProps) => {
  const navigate = useNavigate();
  const scheduleData = useSelector(selectScheduleById);
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // Edit state
  const scheduleNameField: UseFieldType = useField('text');
  const scheduleDescriptionField: UseFieldType = useField('text');
  const [holidays, setHolidays] = useState<IHoliday[]>([]);
  const [timeRanges, setTimeRanges] = useState<ITimeRange[]>([]);
  const [openPanelNewHoliday, setOpenPanelNewHoliday] = useState<boolean>(false);

  // Edit functions
  const chargeEditFields = (schedule: ISchedule) => {
    scheduleNameField.actions.changeValue(schedule.name);
    scheduleDescriptionField.actions.changeValue(schedule.description);
    setTimeRanges(schedule.timeRanges)
    setHolidays(schedule.holidays ? schedule.holidays : []);
  };

  // Edit functions
  const resetEditFields = () => {
    scheduleNameField.actions.resetValue();
    scheduleDescriptionField.actions.resetValue();
    setTimeRanges([]);
  };

  const closeEditPanel = () => {
    resetEditFields();
    navigate(-1);
  };

  const sendSchedule = async (scheduleId: string, spaceId: string) => {
    const editedSchedule = {
      id: scheduleId,
      spaceId,
      name: scheduleNameField.attributes.value,
      description: scheduleDescriptionField.attributes.value,
      holidays: holidays,
      timeRanges,
    };
    const response = await dispatch(editSchedule({ scheduleId, editedSchedule }));
    if (response && response.payload) {
      closeEditPanel();
    }
  };

  const handleOpenPanelNewHoliday = () => {
    setOpenPanelNewHoliday(true);
  };

  const handleClosePanelNewHoliday = () => {
    setOpenPanelNewHoliday(false);
  };

  useEffect(() => {
    if (scheduleData) {
      chargeEditFields(scheduleData);
    }
  }, [scheduleData]);

  return (
    <InitScheduleEditContext.Provider
      value={{
        sendSchedule,
        scheduleNameField,
        scheduleDescriptionField,
        holidays,
        setHolidays,
        timeRanges,
        setTimeRanges,
        openPanelNewHoliday,
        setOpenPanelNewHoliday,
        handleOpenPanelNewHoliday,
        handleClosePanelNewHoliday,
        closeEditPanel,
      }}
    >
      {children}
    </InitScheduleEditContext.Provider>
  );
};

export default ScheduleEditProvider;
