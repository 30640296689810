import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { WebChatChannel } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { uploadWebChatImage } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import { Card, TextField, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  webChatChannelData: WebChatChannel;
  deleteAction: (id: string) => void;
  changeTitleAction: (payload: { channelId: string; newValue: string }) => void;
  changeDetailAction: (payload: { channelId: string; newValue: string }) => void;
  changeAddressAction: (payload: { channelId: string; newValue: string }) => void;
  changeWebChatChannelLogoUrl: (payload: {
    channelId: string;
    newValue: string;
  }) => void;
  type: 'whatsapp' | 'messenger' | 'phone';
}

const WebChatChannelCard = ({
  webChatChannelData,
  changeWebChatChannelLogoUrl,
  deleteAction,
  changeTitleAction,
  changeDetailAction,
  changeAddressAction,
  type,
}: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleDelete = () => {
    // @ts-ignore
    dispatch(deleteAction(webChatChannelData.id));
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      // @ts-ignore
      changeTitleAction({
        channelId: webChatChannelData.id,
        newValue: event.target.value,
      })
    );
  };
  const handleDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      // @ts-ignore
      changeDetailAction({
        channelId: webChatChannelData.id,
        newValue: event.target.value,
      })
    );
  };
  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      // @ts-ignore
      changeAddressAction({
        channelId: webChatChannelData.id,
        newValue: event.target.value,
      })
    );
  };
  const handleFileUpload = async (event) => {
    setIsUploading(true);

    const file = event.target.files[0];
    const fileURL = await dispatch(uploadWebChatImage(file));

    dispatch(
      // @ts-ignore
      changeWebChatChannelLogoUrl({
        channelId: webChatChannelData.id,
        newValue: fileURL.payload,
      })
    );
    setIsUploading(false);
  };

  return (
    <Card
      sx={{
        pt: '0.5rem',
        pb: '1rem',
        px: '1rem',
        width: '100%',
        //@ts-ignore
        backgroundColor: theme => theme.palette.background.panel
      }}
    >
      <Box display={'flex'} width={'100%'} justifyContent={'flex-end'}>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box width={'100%'} display={'flex'} gap={1}>
          <TextField
            fullWidth
            size="small"
            label="URL"
            value={webChatChannelData.imageUrl}
          />
          <LoadingButton
            loading={isUploading}
            disabled={isUploading}
            size="small"
            variant="outlined"
            component="label"
          >
            {t('webChatEditView.designPanel.homeSection.upload')}
            <input onChange={handleFileUpload} hidden accept="image/*" type="file" />
          </LoadingButton>
        </Box>
        <TextField
          onChange={handleTitleChange}
          value={webChatChannelData.title}
          label={t('global.title')}
          size="small"
        />
        <TextField
          onChange={handleDetailChange}
          value={webChatChannelData.detail}
          label={t('global.detail')}
          size="small"
        />
        <TextField
          onChange={handleAddressChange}
          value={webChatChannelData.address}
          label={
            type === 'messenger'
              ? t('webChatEditView.designPanel.homeOtherChannelsSection.facebookId')
              : t('global.phone')
          }
          size="small"
        />
      </Box>
    </Card>
  );
};

export default WebChatChannelCard;
