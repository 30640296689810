import { useEffect, useState } from 'react';
// Components/ui
import { AdvancedFilter, CollapseAnimation } from './components';
import { Fade, Box } from '@mui/material';
import { style } from './style';
import ApplyFilterButton from './components/AdvancedFilter/components/ApplyFilterButton/ApplyFilterButton';
// Redux
import { fetchUsers } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchGroups } from 'redux/features/conversationsSlice/conversationsSlice';

const SidebarFilter = ({ isFilterPanelOpen }) => {
  const dispatch = useAppDispatch();
  const [openPanel, setOpenPanel] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    isFilterPanelOpen
      ? setOpenPanel(true)
      : setTimeout(() => {
          setOpenPanel(false);
        }, 50);

    !showContent
      ? setTimeout(() => {
          setShowContent(isFilterPanelOpen);
        }, 85)
      : setShowContent(isFilterPanelOpen);
  }, [isFilterPanelOpen, showContent]);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchGroups());
  }, []);

  return (
    <Box sx={style.sidePanelReport}>
      <CollapseAnimation openPanel={openPanel}>
        <Box sx={style.container}>
          <Fade in={showContent}>
            <Box width={'100%'}>
              <AdvancedFilter />
              {/* <TagList /> */}
            </Box>
          </Fade>
          <ApplyFilterButton />
        </Box>
      </CollapseAnimation>
    </Box>
  );
};

export default SidebarFilter;
