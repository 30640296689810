import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';
// Types
import { SendMessage } from './types/SendMessage';
import { PostAction } from './types/PostAction';

// Posts
const fetchPosts = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/Walls`, requestConfig);

  return response.data;
};

const fetchComments = async (jwt: string, URL: string, postId: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/WallMessages/${postId}`, requestConfig);

  return response.data;
};

const setMessage = async (data: SendMessage, jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.post(
    `${URL}/WallMessages/${data.postId}`,
    data,
    requestConfig
  );

  return response.data;
};

const markAsAnswered = async (
  jwt: string,
  URL: string,
  data: { commentId: string; postId: string }
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const { commentId, postId } = data;

  const response = await axios.put(
    `${URL}/WallMessages/${postId}/toggle-read-comment`,
    { commentId },
    requestConfig
  );

  return response.data;
};

// Actions
const postAction = async (jwt: string, URL: string, data: PostAction) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const { postId, action } = data;
  const response = await axios.put(
    `${URL}/Walls/${postId}/action/${action}`,
    {},
    requestConfig
  );

  return response.data;
};

export default {
  fetchComments,
  fetchPosts,
  setMessage,

  postAction,
  markAsAnswered,
};
