import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Box } from '@mui/material';

const Basic = () => {
  const { selectNode, setApiNode, apiNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.api && selectNode.data.api.apiAuthBasic) {
      setUsername(selectNode.data.api.apiAuthBasic.username);
      setPassword(selectNode.data.api.apiAuthBasic.password);
    }
  }, [selectNode]);

  useEffect(() => {
    if (username && password) {
      setApiNode({
        ...apiNode,
        apiAuthBasic: {
          username,
          password,
        },
      });
    }
  }, [username, password]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        label={t('editFlowsView.api.authType.basic.username')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label={t('editFlowsView.api.authType.basic.password')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default Basic;
