import { useState, useContext, useRef, useEffect, forwardRef } from 'react';
// Context
import { conversationsContext } from '../../../context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import {
  Box,
  CardMedia,
  Dialog,
  DialogContent,
  Fade,
  IconButton,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
// Components
import { Image, PrintButton, Video } from './components';
import { Markdown } from 'functions/Markdown';

const Transition = forwardRef(function Transition(
  props: any,
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

const ImageViewer = () => {
  const {
    openImageViewer,
    setOpenImageViewer,
    imageViewerSrc,
    currentIndex,
    setCurrentIndex,
  } = useContext(conversationsContext);
  const { getFileType } = useContext(messagesContext);
  const [url, setUrl] = useState<string>('');
  const [caption, setCaption] = useState<string>('');
  const [type, setType] = useState<string>('');

  useEffect(() => {
    if (imageViewerSrc && imageViewerSrc.length > 0) {
      const { url, caption, mimeType } = imageViewerSrc[currentIndex];
      const type = getFileType(mimeType) || 'image';
      setUrl(url);
      setCaption(caption);
      setType(type);
    }
  }, [imageViewerSrc, currentIndex]);

  const handleClose = () => {
    setOpenImageViewer(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageViewerSrc?.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageViewerSrc?.length - 1 : prevIndex - 1
    );
  };

  return (
    <Dialog
      open={openImageViewer}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="lg"
      fullWidth
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      fullScreen
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "transparent",
        }}
      >
        <PrintButton imageUrl={url} />
        <IconButton
          color="inherit"
          onClick={handleClose}
          size="large"
          sx={{
            position: "absolute",
            top: "10px",
            right: "15px",
            zIndex: 1,
            color: "primary.contrastText",
          }}
        >
          <CloseIcon
            sx={{
              fontSize: 28,
            }}
          />
        </IconButton>
        <IconButton
          onClick={handlePrevious}
          size="large"
          sx={{
            bgcolor: "primary.light",
            color: "primary.contrastText",
            "&&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          <ChevronLeftIcon
            sx={{
              fontSize: 28,
            }}
          />
        </IconButton>
        <Box>
          <Box
            sx={{
              maxHeight: "80vh",
              maxWidth: "80vw",
              overflow: "hidden",
              width: "auto",
              height: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {type === "image" ? <Image url={url} /> : <Video url={url} />}
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "primary.contrastText",
              textAlign: "center",
              mt: 1,
              whiteSpace: "pre-line",
            }}
            dangerouslySetInnerHTML={{ __html: Markdown(caption || "", false) }}
          ></Typography>
        </Box>
        <IconButton
          onClick={handleNext}
          size="large"
          sx={{
            bgcolor: "primary.light",
            color: "primary.contrastText",
            "&&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          <ChevronRightIcon
            sx={{
              fontSize: 28,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewer;
