import { useTranslation } from 'react-i18next';
import { useContext, forwardRef, RefObject, useState, useEffect } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import { SectionCard } from '../../layout';
import { Divider } from '@mui/material';
import {
  Address,
  BusinessMember,
  Contact,
  DeletedNotification,
  Duplicates,
  Header,
  Properties,
} from './components';
import { Labels } from 'components';
// Icons
import {
  Email,
  Facebook,
  Instagram,
  Person,
  PhoneEnabled,
  WhatsApp,
} from '@mui/icons-material';
import ContactImg from '../../../../../../../../../../../public/img/Icons/SVG/TriiContacts.svg';
import { StyledContactDataContainer } from './styles';
// Types
import { ContactProps } from '../../types/ContactProps';
import Members from './components/Members';
import { useSelector } from 'react-redux';
import { selectBusinessData } from 'redux/features/businessSlice/selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchBusinessById } from 'redux/features/businessSlice/businessSlice';
import { ChannelType } from '@trii/types/dist/Common/Channels';

const ContactData = forwardRef(
  (props: ContactProps, ref: RefObject<HTMLElement>) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { contactInfo, setContactInfo } = useContext(conversationsContext);

    const businessData = useSelector(selectBusinessData);

    const [isDuplicates, setIsDuplicates] = useState<boolean>(false);

    const iconStyle = { color: (theme) => theme.palette.primary.dark };
    const businessId = contactInfo.id;

    useEffect(() => {
      if (businessId) {
        dispatch(fetchBusinessById(businessId));
      }
    }, [dispatch, businessId]);

    useEffect(() => {
      if (contactInfo) {
        //@ts-ignore
        setIsDuplicates(contactInfo.hasDuplicates);
      }
    }, [contactInfo]);

    return (
      <SectionCard
        ref={ref}
        title={t('global.contact')}
        defaultExpanded
        onClick={props.handleClick}
        tabName="contactData"
      >
        <StyledContactDataContainer>
          {contactInfo.deletedAt !== null && <DeletedNotification />}
          {isDuplicates && <Duplicates contactId={contactInfo?.id} />}
          <Header />
          <Labels labels={contactInfo.tags} max={3} />
          {contactInfo?.businessId !== null &&
            contactInfo?.businessName !== null && (
              <BusinessMember business={contactInfo} />
            )}
          <Divider variant="middle" />
          {/* Address */}
          <Address address={contactInfo?.address1} />
          {contactInfo?.address2 && (
            <Address address={contactInfo?.address2} showTitle={false} />
          )}
          {/* Phone */}
          <Contact
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            icon={<PhoneEnabled fontSize="small" sx={iconStyle} />}
            title="conversations.contactInfo.phone"
            contactList={contactInfo?.phones || []}
            contactId={contactInfo?.id}
            type={ChannelType.PHONE}
          />
          {/* Email */}
          <Contact
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            icon={<Email fontSize="small" sx={iconStyle} />}
            title="conversations.contactInfo.email"
            contactList={contactInfo?.emails || []}
            showCampaign
            contactId={contactInfo?.id}
            type={ChannelType.EMAIL}
          />
          {/* WhatsApp */}
          <Contact
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            icon={<WhatsApp fontSize="small" sx={iconStyle} />}
            title="conversations.contactInfo.im"
            contactList={contactInfo?.ims_whatsapp || []}
            showCampaign
            contactId={contactInfo?.id}
            type={ChannelType.WHATSAPP}
          />
          {/* Facebook */}
          <Contact
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            icon={<Facebook fontSize="small" sx={iconStyle} />}
            title="conversations.contactInfo.im"
            contactList={contactInfo?.ims_facebook || []}
            showCampaign
            showTitle={false}
            type={ChannelType.FACEBOOK}
            contactId={contactInfo?.id}
          />
          {/* Instagram */}
          <Contact
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
            icon={<Instagram fontSize="small" sx={iconStyle} />}
            title="conversations.contactInfo.im"
            contactList={contactInfo?.ims_instagram || []}
            showCampaign
            showTitle={false}
            contactId={contactInfo?.id}
            type={ChannelType.INSTAGRAM}
          />
          {/* Members */}
          {
            //@ts-ignore
            contactInfo?.isBusiness === true && (
              <Members
                icon={<Person fontSize="small" sx={iconStyle} />}
                title="conversations.businessInfo.members"
                memberList={businessData?.members || []}
              />
            )
          }
          {/* Properties */}
          <Properties properties={contactInfo.properties} />
        </StyledContactDataContainer>
      </SectionCard>
    );
  }
);

export default ContactData;
