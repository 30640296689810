import {
  createContext,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  useContext,
} from 'react';
// Context
import { DateTimeContext } from 'context/DateTime/DateTimeContext';
import { conversationsContext } from '../ConversationsProvider/ConversationsProvider';
// Theme
import { useTheme } from '@mui/material';
// Redux
import { PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  fetchContactsData,
  fetchFields,
  resetContacts,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import {
  fetchAddComment,
  fetchDeleteComment,
  fetchForwardMessage,
  fetchHighlightMessage,
  fetchVariables,
  getMessages,
  getNextMessages,
  sendMessageThunk,
  setEmailMessageStatus,
  setIsReplyingEmail,
  uploadMedia,
} from 'redux/features/messagesSlice/messagesSlice';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
// ID
import ObjectID from 'bson-objectid';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useFormMessage from './hooks/useFormMessage';
import useImagePreloader from 'hooks/useImagePreloader';
import useFilterMessage, {
  useFilterMessageInitialDef,
} from './hooks/useFilterMessage';
import { useReactions, useReactionsInitialState } from './hooks/useReactions';
import useInfoMessages, {
  useInfoMessagesInitialState,
} from '../../hooks/useInfoMessages';
// Types
import { IGif } from '@giphy/js-types';
import { FormattedFormMessage } from 'redux/features/messagesSlice/types/FormattedFormMessage';
import { MessagesContext } from './types/MessagesContext';
import { MessagesProviderProps } from './types/MessagesProviderProps';
import FileType from './types/FileType';
import {
  EmailDocument,
  IMessage,
  MessageEmail,
  MessageHeaderType,
  MessageLocation,
} from '@trii/types/dist/Common/Messages';
import MediaFile from './types/MediaFile';
import {
  MessageAck,
  MessageDirection,
  MessageType,
} from '@trii/types/dist/Common/Messages';
import { NextMessages } from 'redux/features/messagesSlice/types/NextMessages';
import { Media } from 'redux/features/messagesSlice/types/Media';
import { Result } from 'redux/types/Gif';
import { DocumentType } from './types/DocumentType';
import { IContactAddress } from '@trii/types/dist/Contacts';
import { FieldsData } from 'redux/features/contactInfoSlice/types/FieldsData';
import { Variable } from 'redux/features/messagesSlice/types/Variable';
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';
import { UserTrii } from '@trii/types/dist/Users';
import { ForwardMessage } from 'redux/features/messagesSlice/types/ForwardMessage';
import { Comment } from 'redux/features/messagesSlice/types/Comment';
import { Message } from './types/Message';
import { ContactData } from 'redux/features/contactInfoSlice/types/ContactData';
import { HighlightMessage } from 'redux/features/messagesSlice/types/HighlightMessage';
// DB
import { dbWorker } from 'db/db';
// Images
import bgLight from './assets/images/bg-light.png';
import bgDark from './assets/images/bg-dark.png';
import { ChatType } from '@trii/types/dist/Conversations';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { GetMessagesData } from 'redux/features/messagesSlice/types/GetMessagesData';
import { FolderType } from 'redux/features/messagesSlice/types/UploadURLParams';
import { EmailResponse } from './types/EmailResponse';

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;

const MESSAGE_TEMPLATE = {
  id: '',
  spaceId: '',
  conversationId: '',
  timestamp: new Date(),
  author: null,
  from: null,
  to: '',
  mentions: null,
  direction: MessageDirection.OUT,
  ack: MessageAck.ACK_PENDING,
  ackLogs: [],
  forwarded: false,
  remoteDeleted: false,
  type: null,
  context: '',
  text: null,
  audio: null,
  contacts: [],
  messageReference: null,
  deleted: false,
  isLoaded: false,
  documents: null,
  isHighlighted: false,
  updatedAt: new Date(),
  updatedBy: null,
};

export const messagesContext = createContext<MessagesContext>({
  handleResponse: () => {},
  handleOpenEmailFooter: () => {},
  handleDeleteEmailDraft: () => {},
  setEmailConversationHasDraft: (value: boolean) => {},
  emailConversationHasDraft: false,
  subjectDraft: '',
  setSubjectDraft: (value: string) => {},
  bodyDraft: null,
  setBodyDraft: (value: string) => {},
  textDraft: '',
  setTextDraft: (text: string) => {},
  handleSaveTextDraftInDB: () => {},
  showSavedEmailDraftMessage: false,
  handleOpenEmailMode: () => {},
  handleSaveEmailDraftInDB: () => {},
  infoMessagesHandler: useInfoMessagesInitialState,
  getTemplateHeaderFileType: (type: MessageHeaderType) => {
    return 'image';
  },
  highlightMessages: (data: HighlightMessage[]) => {
    return new Promise(() => {});
  },
  messageReactions: useReactionsInitialState,
  chatFilter: useFilterMessageInitialDef,
  highligthedMessageId: null,
  handleNavigateToRepliedMessage: () => {},
  messageContainerRef: null,
  messages: [],
  setMessages: (messages: Message[]) => {},
  showActions: false,
  setShowActions: (showActions: boolean) => {},
  // Audio state
  uploadAudiosRejected: [],
  setUploadAudiosRejected: (rejected: string[]) => {},
  recordAudioMode: false,
  sendMessage: () => {},
  startRecordAudioMode: () => {},
  recordAudioStream: null,
  endRecordAudioMode: () => {},
  // File selector
  fileSelectorMode: null,
  setFileSelectorMode: (mode: boolean) => {},
  files: [],
  selectedFile: null,
  handleFileSelect: (fileId: string) => {},
  endFileSelectorMode: () => {},
  handleFileUpload: (event: ChangeEvent<HTMLInputElement>) => {},
  handleFileRemove: (fileId: string) => {},
  // handleFileMsgChange: (event: ChangeEvent<HTMLInputElement>, fileId: string) => {},
  handleUpload: (
    file: MediaFile[],
    messageId: string
  ): Promise<MediaFile[] | DocumentType[]> => {
    return new Promise(() => {});
  },
  //
  filesToUpload: [],
  setFilesToUpload: (files: MediaFile[] | DocumentType[]) => {},
  messageLoading: [],
  setMessageLoading: (messageId: string[]) => {},
  sendImages: (files: MediaFile[], messageId: string) => {},
  handleUploadGif: (gif: Result) => {},
  handleLocation: (locations: MessageLocation[]) => {},
  handleSendSticker: (gif: IGif, conversationId: string) => {},
  getFileType: (type: string): FileType => {
    return 'image';
  },
  endDocumentSelectorMode: () => {},
  handleDocumentUpload: (event: ChangeEvent<HTMLInputElement>) => {},
  handleDocumentRemove: (documentId: string) => {},
  handleDocumentSelect: (documentId: string) => {},
  documents: [],
  selectedDocument: null,
  sendDocuments: (documents: DocumentType[], conversationId: string) => {},
  isSearching: false,
  setIsSearching: (isSearching: boolean) => {},
  userLocationContext: [],
  setUserLocationContext: (userLocation: MessageLocation[]) => {},
  message: null,
  setMessage: (message: IMessage | null) => {},
  isReplying: false,
  setIsReplying: (isReplying: boolean) => {},
  messageReply: null,
  setMessageReply: (message: IMessage | null) => {},
  getNewMessages: (conversationId: string) => {
    return new Promise(() => {});
  },
  getNextNewMessages: (data: NextMessages) => {
    return new Promise(() => {});
  },
  footerSize: 'auto',
  setFooterSize: (size: string) => {},
  handleUploadFile: (e: ChangeEvent<HTMLInputElement>) => {
    return new Promise(() => {});
  },
  from: '',
  setFrom: (value: string) => {},
  to: [],
  setTo: (user: IContactAddress[]) => {},
  isCc: false,
  setIsCc: (value: boolean) => {},
  cc: [],
  setCc: (user: IContactAddress[]) => {},
  isBcc: false,
  setIsBcc: (value: boolean) => {},
  bcc: [],
  setBcc: (user: IContactAddress[]) => {},
  // body: null,
  // setBody: (value: string) => {},
  attachments: null,
  setAttachments: (attachments: EmailDocument[]) => {},
  handleSearchContact: (data: Pagination) => {
    return new Promise(() => {});
  },
  sendEmail: (conversationId: string, newBody: string, subject: string) => {},
  openEmailMode: false,
  setOpenEmailMode: (value: boolean) => {},
  resetEmailFields: () => {},
  openEmailModal: false,
  setOpenEmailModal: (value: boolean) => {},
  getContactFields: (data: FieldsData) => {},
  getVariableInfo: (data: Variable) => {
    return new Promise(() => {});
  },
  editorState: '',
  setEditorState: (editorState: string) => {},
  // Background Image
  backgroundImage: '',
  // Forward Message
  forwardMessage: (data: ForwardMessage) => {
    return new Promise(() => {});
  },
  // Comment Message
  addComment: (data: Comment) => {
    return new Promise(() => {});
  },
  deleteComment: (data: Comment) => {
    return new Promise(() => {});
  },
  // Highlight Message
  highlightMessage: (data: HighlightMessage) => {
    return new Promise(() => {});
  },
  // Form message
  sendFormMessage: (formId: string) => {
    return new Promise(() => {});
  },
  formMessageLoading: [],
  setFormMessageLoading: () => {},
});

const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const conversationSelected = useSelector(selectConversationSelected);
  const { setOpenModalList, contactInfo } = useContext(conversationsContext);
  const { datetime } = useContext(DateTimeContext);
  // Chat filter
  const infoMessagesHandler = useInfoMessages();
  // Message reactions
  const messageReactions = useReactions();
  // Theme
  const theme = useTheme();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [textDraft, setTextDraft] = useState<string>('');
  const [showActions, setShowActions] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [messageReply, setMessageReply] = useState<IMessage | null>(null);
  const [footerSize, setFooterSize] = useState<string>('auto');
  const [message, setMessage] = useState<IMessage | null>(null);
  const [messageLoading, setMessageLoading] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [userLocationContext, setUserLocationContext] = useState<MessageLocation[]>(
    []
  );
  const [highligthedMessageId, setHighligthedMessageId] = useState<string | null>(
    null
  );
  // Chat state -> File selector
  const [fileSelectorMode, setFileSelectorMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState<MediaFile | null>(null);
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<MediaFile[] | DocumentType[]>(
    []
  );
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null
  );
  // Chat state -> Audio
  const [uploadAudiosRejected, setUploadAudiosRejected] = useState<string[]>([]);
  const [recordAudioMode, setRecordAudioMode] = useState(false);
  const [recordAudioStream, setRecordAudioStream] = useState<null | MediaStream>(
    null
  );
  // Chat state -> Email
  const [openEmailMode, setOpenEmailMode] = useState<boolean>(false);
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<IContactAddress[]>([]);
  const [isCc, setIsCc] = useState<boolean>(false);
  const [cc, setCc] = useState<IContactAddress[]>([]);
  const [isBcc, setIsBcc] = useState<boolean>(false);
  const [bcc, setBcc] = useState<IContactAddress[]>([]);
  // const [body, setBody] = useState<string>(null);
  const [bodyDraft, setBodyDraft] = useState<string>(null);
  const [subjectDraft, setSubjectDraft] = useState<string>('');
  const [attachments, setAttachments] = useState<EmailDocument[]>([]);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [editorState, setEditorState] = useState<string>('');
  const [showSavedEmailDraftMessage, setShowSavedEmailDraftMessage] =
    useState<boolean>(false);
  const [emailConversationHasDraft, setEmailConversationHasDraft] =
    useState<boolean>(false);
  // Messages Background Image
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const imageToPreload = theme.palette.mode === 'dark' ? bgDark : bgLight;
  const { imagesPreloaded } = useImagePreloader([imageToPreload]);
  // Form message
  const formMessage = useFormMessage();
  const [formMessageLoading, setFormMessageLoading] = useState<string[]>([]);
  // Chat state -> Filter
  const chatFilter = useFilterMessage(conversationSelected?.id);

  const spaceInfo = useSelector(selectSpaceInfo);
  const userInfo: UserTrii = useSelector(selectUser);
  const user = {
    ...userInfo,
    id: userInfo.uid,
    name: userInfo.username,
    isActive: true,
  };
  const dispatch = useAppDispatch();

  // Chat -> General functions
  const getNewMessages = async (conversationId: string): Promise<IMessage[]> => {
    const getMessagesData: GetMessagesData = {
      conversationId,
      contactId: conversationSelected.contactInfo.id,
    };

    const result = await dispatch(getMessages(getMessagesData));

    return result.payload;
  };

  function handleSaveTextDraftInDB(conversationId: string, text: string) {
    const draft = {
      text,
      conversationId: conversationId,
    };

    dbWorker.postMessage({
      action: 'setDraftForConversation',
      data: draft,
    });
  }

  const getNextNewMessages = async (fetchData: NextMessages) => {
    const response = await dispatch(getNextMessages(fetchData));
    // @ts-ignore
    const data = response.payload.data as IMessage[];

    return data;
  };

  const sendMessage = async (message: IMessage | FormattedFormMessage) => {
    // Instantly show the message client side if its not a form, if its a form it will only be showed when the form is sent
    // @ts-ignore
    message.contactId = conversationSelected.contactInfo.id;
    // @ts-ignore
    if (!message.form?.id) {
      await dbWorker.postMessage({
        action: 'setMessage',
        data: message,
      });
    }

    setMessageLoading([...messageLoading, message.id]);

    const response = await dispatch(sendMessageThunk(message));

    setMessageLoading(messageLoading.filter((msg) => msg !== message.id));

    if (response.payload) {
      await dbWorker.postMessage({
        action: 'setMessage',
        data: response.payload,
      });
    }
  };

  async function sendFormMessage(formId: string) {
    const formattedFormMessage = formMessage.getFormattedMessage(formId);
    setFormMessageLoading([...formMessageLoading, formId]);
    await sendMessage(formattedFormMessage);

    setFormMessageLoading(formMessageLoading.filter((msg) => msg !== formId));
    setOpenModalList(false);
  }

  const getFileType = (type: string): FileType => {
    if (type) {
      if (type.startsWith('text/') || type.startsWith('application/')) {
        return 'text';
      } else if (type.startsWith('image/')) {
        return 'image';
      } else if (type.startsWith('video/')) {
        return 'video';
      } else {
        return 'other';
      }
    }
  };

  const getTemplateHeaderFileType = (type: MessageHeaderType): FileType => {
    switch (type) {
      case MessageHeaderType.IMAGE:
        return 'image';
      case MessageHeaderType.VIDEO:
        return 'video';
      case MessageHeaderType.TEXT:
        return 'text';
      default:
        return 'other';
    }
  };

  const verifyAllLoaded = (files: MediaFile[]) => {
    return files.every((file) => {
      if (file) {
        const splitBase64 = file.url.split(',')[0];
        const isBase64 = REGEX_BASE64.test(splitBase64);
        return !isBase64;
      }
    });
  };

  // Scroll to a message and change its color
  function handleNavigateToRepliedMessage(messageId: string) {
    const element = document.getElementById(messageId);

    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'center' });
      setHighligthedMessageId(messageId);
      setTimeout(() => setHighligthedMessageId(null), 450); // Remove the change its color after 0.45 seconds
    }
  }

  // Chat -> Audio  functions
  const startRecordAudioMode = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      setRecordAudioStream(stream);
      setRecordAudioMode(true);
    });
  };
  const endRecordAudioMode = () => {
    setRecordAudioMode(false);
    if (recordAudioStream) {
      recordAudioStream.getTracks().forEach((track) => track.stop());
    }
  };

  // Chat -> File selector functions
  const endFileSelectorMode = () => {
    setFiles([]);
    setSelectedFile(null);
    setDocuments([]);
    setSelectedDocument(null);
    setFileSelectorMode(null);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);

    if (newFiles.length === 0) {
      return;
    }

    const newFilesWithId = newFiles.map((file) => {
      const url = URL.createObjectURL(file);
      return {
        id: ObjectID().toString(),
        url,
        caption: '',
        mimeType: file.type,
        filename: file.name,
        file,
      };
    });

    setSelectedFile(newFilesWithId[0]);
    setFiles((prevFiles) => [...prevFiles, ...newFilesWithId]);
    setFileSelectorMode(true);
  };

  const handleFileRemove = (fileId: string) => {
    const newFiles = files.filter((file) => file.id !== fileId);

    if (newFiles.length === 0) {
      setFiles([]);
      setFileSelectorMode(null);
      setSelectedFile(null);

      return;
    }

    if (fileId === selectedFile?.id) {
      setSelectedFile(newFiles[0]);
    }

    setFiles(newFiles);
  };

  const handleFileSelect = (fileId: string) => {
    const file = files.find((file) => file.id === fileId);

    if (file) {
      setSelectedFile(file);
    }
  };

  // Chat -> Document functions
  const endDocumentSelectorMode = () => {
    setDocuments([]);
    setSelectedDocument(null);
    setFileSelectorMode(false);
  };

  const handleDocumentUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);

    if (documents.length === 0) {
      return;
    }

    const newDocuments = documents.map((file) => {
      const id = ObjectID().toString();
      const { type, name } = file;
      return {
        id,
        url: '',
        mimeType: type,
        filename: name,
        file,
        caption: '',
      };
    });

    setSelectedDocument(newDocuments[0]);
    setDocuments((prevFiles) => [...prevFiles, ...newDocuments]);
    setFileSelectorMode(true);
  };

  const handleDocumentRemove = (documentId: string) => {
    const newDocuments = documents.filter((file) => file.id !== documentId);

    if (newDocuments.length === 0) {
      endDocumentSelectorMode();
      return;
    }

    if (documentId === selectedFile?.id) {
      setSelectedDocument(newDocuments[0]);
    }

    setDocuments(newDocuments);
  };

  const handleDocumentSelect = (documentId: string) => {
    const document = documents.find((document) => document.id === documentId);

    if (document) {
      setSelectedDocument(document);
    }
  };

  const sendDocuments = async (
    documents: DocumentType[],
    conversationId: string
  ) => {
    const messageId = ObjectID().toString();
    const newMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: datetime.getDateTime(),
      userId: user.uid,
      type: MessageType.CHAT,
      documents,
    };

    await dbWorker.postMessage({
      action: 'setMessage',
      data: newMessage,
    });
    const newDocuments = (await handleUpload(
      documents,
      messageId
    )) as DocumentType[];
    const verifyAllLoaded = newDocuments.every((document) => {
      if (document) {
        return document.url !== '';
      }
    });
    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));
    if (newDocuments && verifyAllLoaded) {
      sendMessage({
        ...newMessage,
        documents: newDocuments,
        isLoaded: true,
      });
    }
  };

  // Chat -> Gif functions
  const handleUploadGif = async (gif: Result) => {
    const { media_formats } = gif;
    const url = media_formats.gif.url;
    const filename = url.split('/').pop() || '';
    const newGif = {
      id: ObjectID().toString(),
      url,
      caption: '',
      mimeType: 'image/gif',
      type: 'image',
      filename,
      previewUrl: media_formats.gifpreview.url,
    };
    setSelectedFile(newGif);
    setFiles((prevFiles) => [...prevFiles, newGif]);
    setFileSelectorMode(true);
  };

  // Chat -> Stickers functions
  const handleSendSticker = (sticker: IGif, conversationId: string) => {
    const url = sticker.images.original.url;
    const filename = url.split('/').pop() || '';
    const newSticker = {
      id: ObjectID().toString(),
      url,
      caption: '',
      mimeType: 'image/sticker',
      type: 'image',
      filename,
    };
    setSelectedFile(newSticker);
    setFiles((prevFiles) => [...prevFiles, newSticker]);
    setFileSelectorMode(true);
  };

  // Chat -> Media functions
  const handleUpload = async (
    files: MediaFile[] | DocumentType[],
    messageId: string
  ): Promise<MediaFile[] | DocumentType[]> => {
    const newFiles = [...files] as MediaFile[] | DocumentType[];
    setFilesToUpload(files);

    files.map((file: MediaFile | DocumentType) => {
      if (file && file.id && !messageLoading.includes(messageId)) {
        setMessageLoading([...messageLoading, messageId]);
      }
    });

    for (const data of files) {
      if (data && data.file) {
        const { file, filename, id } = data;
        const formData = new FormData();

        formData.append('file', file, filename);

        const result = (await dispatch(
          uploadMedia({
            file: formData,
            name: filename,
            id,
          })
        )) as PayloadAction<Media>;
        if (result && result.payload) {
          const { url, id } = result.payload;
          newFiles.forEach((file: MediaFile | DocumentType) => {
            if (file.id === id) {
              file.url = url;
            }
          });
        }
      }
    }
    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));
    return newFiles;
  };

  const sendImages = async (files: MediaFile[], conversationId: string) => {
    const messageId = ObjectID().toString();
    const images = files.filter((file) => {
      if (file) {
        const type = getFileType(file.mimeType);
        if (type === 'image') {
          return file;
        }
      }
    });
    const videos = files.filter((file) => {
      if (file) {
        const type = getFileType(file.mimeType);
        if (type === 'video') {
          return {
            ...file,
            animated: false,
          };
        }
      }
    });
    const newMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: datetime.getDateTime(),
      userId: user.uid,
      type: MessageType.CHAT,
      images,
      videos,
    };
    const newImages = ((images.length > 0 &&
      (await handleUpload(images, messageId))) ||
      []) as MediaFile[];
    const newVideos = ((videos.length > 0 &&
      (await handleUpload(videos, messageId))) ||
      []) as MediaFile[];
    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));
    const isAllImagesCharged = images && verifyAllLoaded(images);
    const isAllVideosCharged = videos && verifyAllLoaded(videos);
    if (
      newImages &&
      newImages.length > 0 &&
      newVideos &&
      isAllImagesCharged &&
      isAllVideosCharged
    ) {
      sendMessage({
        ...newMessage,
        images: newImages,
        videos: newVideos,
        isLoaded: true,
      });
    } else if (newImages && newImages.length > 0 && isAllImagesCharged) {
      sendMessage({
        ...newMessage,
        images: newImages,
        isLoaded: true,
      });
    } else if (newVideos && newVideos.length > 0 && isAllVideosCharged) {
      sendMessage({
        ...newMessage,
        videos: newVideos,
        isLoaded: true,
      });
    }
  };

  // Chat -> Email functions
  const resetEmailFields = () => {
    setOpenEmailMode(false);
    setFooterSize('3rem');
    setTo([]);
    setCc([]);
    setIsCc(false);
    setBcc([]);
    setIsBcc(false);
    setSubjectDraft('');
    // setBody('');
    setBodyDraft(null);
    setAttachments([]);
    setOpenEmailModal(false);
    setEditorState(null);
  };

  function getFolderType(mimeType: string): FolderType {
    console.log('mimeType: ', mimeType);
    if (mimeType.startsWith('image/')) {
      return 'images';
    } else if (mimeType.startsWith('video/')) {
      return 'videos';
    } else if (mimeType.startsWith('audio/')) {
      return 'audios';
    } else if (mimeType.startsWith('application/')) {
      return 'documents';
    } else {
      return 'documents';
    }
  }

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);
    if (documents.length === 0) {
      return;
    }

    const newDocuments = documents.map((file) => {
      const id = ObjectID().toString();
      setMessageLoading((prev) => [...prev, id]);
      const { type, name } = file;
      const data = {
        id,
        url: '',
        mimeType: type,
        filename: name,
        file,
        caption: '',
      };
      setFilesToUpload((prevFiles) => [...prevFiles, data]);
      return data;
    });

    newDocuments.map(async (file) => {
      const formData = new FormData();
      formData.append('file', file.file);
      const { mimeType } = file;

      await dispatch(
        uploadMedia({
          file: formData,
          name: file.id,
          id: file.id,
          URLParams: {
            module: 'messages',
            folderType: getFolderType(mimeType),
          },
        })
      );
    });
  };

  const handleSearchContact = async (data: Pagination) => {
    dispatch(resetContacts());

    const response = await dispatch(fetchContactsData(data));
    return response.payload as ContactData;
  };

  const sendEmail = async (
    conversationId: string,
    newBody: string,
    subject: string
  ) => {
    const messageId = ObjectID().toString();

    const newMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: datetime.getDateTime(),
      type: MessageType.CHAT_EXTERNAL,
      userId: user.uid,
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      email: {
        from,
        to,
        cc,
        bcc,
        subject,
        preHeader: '',
        bodyHtml: newBody,
        bodyText: newBody,
        attachments,
        timestamp: new Date(),
        emailAccount: '',
      },
    };

    resetEmailFields();
    handleDeleteEmailDraft();

    sendMessage(newMessage);
  };

  async function handleSaveEmailDraftInDB(
    conversationId: string,
    subject: string,
    body: string
  ) {
    const draft = {
      from,
      to,
      cc,
      bcc,
      subject,
      body,
      attachments,
      timestamp: new Date(),
      conversationId: conversationId,
    };

    await dbWorker.postMessage({
      action: 'setDraftForConversation',
      data: draft,
    });

    setBodyDraft(body);
    setSubjectDraft(subject);

    setEmailConversationHasDraft(true);
    setShowSavedEmailDraftMessage(true); // Set the setShowSavedEmailDraftMessage to true to show the message in the UI only for 5 seconds
    setTimeout(() => setShowSavedEmailDraftMessage(false), 5000);
  }

  async function handleDeleteEmailDraft() {
    setEmailConversationHasDraft(false);
    setTo([]);
    setCc([]);
    setIsCc(false);
    setBcc([]);
    setIsBcc(false);
    setSubjectDraft('');
    setBodyDraft(null);
    setAttachments([]);

    dbWorker.postMessage({
      action: 'deleteDraft',
      data: conversationSelected.id,
    });
  }

  const handleOpenEmailMode = () => {
    handleOpenEmailFooter();

    if (contactInfo && contactInfo.emails && contactInfo.emails.length > 0) {
      const getEmail = contactInfo.emails.find(
        (email) => email.id === conversationSelected.remoteAddressId
      );
      setTo([getEmail]);
    }
  };

  const handleOpenEmailFooter = () => {
    setOpenEmailMode(true);
    setFooterSize('30rem');
  };

  const getContactFields = async (data: FieldsData) => {
    await dispatch(fetchFields(data));
  };

  const getVariableInfo = async (data: Variable): Promise<string> => {
    const response = await dispatch(fetchVariables(data));
    return response.payload as string;
  };

  // Chat -> Forward Message functions
  const forwardMessage = async (data: ForwardMessage) => {
    const response = await dispatch(fetchForwardMessage(data));
    return response.payload;
  };

  const updateMessage = (newMessage: IMessage) => {
    return messages.map((dateGroupedMessages) => {
      const updateMessages = dateGroupedMessages.messages.map((message) => {
        if (message.id === newMessage.id) {
          return newMessage;
        }

        return message;
      });

      return {
        ...dateGroupedMessages,
        messages: updateMessages,
      };
    });
  };

  const getUpdatedMessages = (newMessages: IMessage[]) => {
    return messages.map((dateGroupedMessages) => {
      const updatedMessages = dateGroupedMessages.messages.map((message) => {
        const newMessage = newMessages.find((newMsg) => newMsg.id === message.id);
        if (newMessage) {
          return newMessage;
        }
        return message;
      });

      return {
        ...dateGroupedMessages,
        messages: updatedMessages,
      };
    });
  };
  // Chat -> Add Comment
  const addComment = async (data: Comment) => {
    const response = await dispatch(fetchAddComment(data));
    const newMessage = response.payload;
    const updateMessages = updateMessage(newMessage);
    setMessages(updateMessages);
    return newMessage;
  };

  const deleteComment = async (data: Comment) => {
    const response = await dispatch(fetchDeleteComment(data));
    const newMessage = response.payload;
    const updateMessages = updateMessage(newMessage);
    setMessages(updateMessages);
    return newMessage;
  };

  // Chat -> Highlight Message
  const highlightMessage = async (data: HighlightMessage) => {
    const response = await dispatch(fetchHighlightMessage(data));

    const newMessage = response.payload;

    const updateMessages = updateMessage(newMessage);

    setMessages(updateMessages);

    return newMessage;
  };

  const highlightMessages = async (dataArray: HighlightMessage[]) => {
    const newMessages = [];

    for (const data of dataArray) {
      const response = await dispatch(fetchHighlightMessage(data));
      const newMessage = response.payload;

      newMessages.push(newMessage);
    }

    const updatedMessages = getUpdatedMessages(newMessages);

    setMessages(updatedMessages);

    return newMessages;
  };

  //Chat -> Send Location
  const handleLocation = (locations: MessageLocation[], conversationId: string) => {
    const messageId = ObjectID().toString();

    const newMessage = {
      ...MESSAGE_TEMPLATE,
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: datetime.getDateTime(),
      userId: user.uid,
      type: MessageType.CHAT,
      locations: locations,
    };
    sendMessage(newMessage);
  };

  const handleResponse = async (
    type: EmailResponse,
    email: MessageEmail,
    onClose?: () => void
  ) => {
    dispatch(setIsReplyingEmail(true));

    const { bodyHtml, bodyText } = email;
    const body =
      bodyHtml === '' ? bodyText.replace(/(?:\r\n|\r|\n)/g, '<br>') : bodyHtml;
    const formatted = body;

    resetEmailFields();
    setSubjectDraft(`RE: ${email.subject}`);

    if (type === 'reply') {
      dispatch(setEmailMessageStatus('replying'));

      setTo([{ address: email.from } as IContactAddress]);
    } else if (type === 'replyAll') {
      const filteredTo = email.to?.filter(
        (contactAddress) => contactAddress.address !== email.emailAccount
      );
      const filteredCC = email.cc?.filter(
        (contactAddress) => contactAddress.address !== email.emailAccount
      );

      dispatch(setEmailMessageStatus('replyingAll'));

      setTo([...filteredTo, { address: email.from } as IContactAddress]);

      if (email.cc?.length > 0) {
        setIsCc(true);
        setCc(filteredCC);
      }
      if (email.bcc?.length > 0) {
        setIsBcc(true);
        setBcc(email.bcc);
      }
    } else if (type === 'forward') {
      dispatch(setEmailMessageStatus('forwarding'));
      setSubjectDraft(`FWD: ${email.subject}`);
      setAttachments(email.attachments);
    }

    onClose && onClose();

    if (type !== 'forward') {
      setBodyDraft(formatted);
      handleOpenEmailMode();
    } else {
      setBodyDraft(`
      <div>
          <p>-------- Forwarded message --------</p>
          <p>From: &lt;${email.from}&gt;</p>
          <p>Date: ${email.timestamp}</p>
          <p>Subject: ${email.subject}</p>
          <p>To: ${email.to
            .map((contactAddress) => contactAddress.address)
            .join(', ')}</p>
          ${formatted}
      </div>
      `);
      handleOpenEmailFooter();
    }
  };

  useEffect(() => {
    if (imagesPreloaded) {
      setBackgroundImage(imageToPreload);
    }
  }, [imagesPreloaded, theme.palette.mode, imageToPreload]);

  useEffect(() => {
    if (conversationSelected) {
      dbWorker.postMessage({
        action: 'getDraft',
        data: conversationSelected.id,
      });
    }

    return () => setTextDraft('');
  }, [conversationSelected]);

  return (
    <messagesContext.Provider
      value={{
        handleResponse,
        handleOpenEmailFooter,
        handleDeleteEmailDraft,
        setEmailConversationHasDraft,
        emailConversationHasDraft,
        bodyDraft,
        setBodyDraft,
        textDraft,
        setTextDraft,
        handleSaveTextDraftInDB,
        showSavedEmailDraftMessage,
        handleOpenEmailMode,
        infoMessagesHandler,
        getTemplateHeaderFileType,
        highlightMessages,
        messageReactions,
        chatFilter,
        highligthedMessageId,
        handleNavigateToRepliedMessage,
        messageContainerRef,
        messages,
        setMessages,
        showActions,
        setShowActions,
        // Audio state
        recordAudioMode,
        uploadAudiosRejected,
        setUploadAudiosRejected,
        sendMessage,
        startRecordAudioMode,
        recordAudioStream,
        endRecordAudioMode,
        fileSelectorMode,
        // Files
        setFileSelectorMode,
        endFileSelectorMode,
        handleFileRemove,
        handleFileSelect,
        selectedFile,
        handleFileUpload,
        files,
        // handleFileMsgChange,
        handleUpload,
        filesToUpload,
        setFilesToUpload,
        //
        messageLoading,
        setMessageLoading,
        sendImages,
        handleUploadGif,
        handleSendSticker,
        getFileType,
        endDocumentSelectorMode,
        handleDocumentUpload,
        handleDocumentRemove,
        handleDocumentSelect,
        selectedDocument,
        documents,
        sendDocuments,
        isSearching,
        setIsSearching,
        userLocationContext,
        setUserLocationContext,
        message,
        setMessage,
        isReplying,
        setIsReplying,
        messageReply,
        setMessageReply,
        getNewMessages,
        getNextNewMessages,
        footerSize,
        setFooterSize,
        handleUploadFile,
        from,
        setFrom,
        to,
        setTo,
        isCc,
        setIsCc,
        cc,
        setCc,
        isBcc,
        setIsBcc,
        bcc,
        setBcc,
        subjectDraft,
        setSubjectDraft,
        handleSaveEmailDraftInDB,
        // body,
        // setBody,
        attachments,
        setAttachments,
        handleSearchContact,
        sendEmail,
        openEmailMode,
        setOpenEmailMode,
        resetEmailFields,
        openEmailModal,
        setOpenEmailModal,
        getContactFields,
        getVariableInfo,
        editorState,
        setEditorState,
        // Background Image
        backgroundImage,
        // Forward Message
        forwardMessage,
        // Comment Message
        addComment,
        deleteComment,
        // Highlight Message
        highlightMessage,
        handleLocation,
        // Form message
        sendFormMessage,
        formMessageLoading,
        setFormMessageLoading,
      }}
    >
      {children}
    </messagesContext.Provider>
  );
};

export default MessagesProvider;
