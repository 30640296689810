// Types
import { FormItemResponseItem } from '@trii/types/dist/Common/Channels';
import { RemoveOption } from '../../types/RemoveOption';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props {
  optionData: FormItemResponseItem;
  questionId: string;
  removeOption: ({ optionId, questionId }: RemoveOption) => void;
  icon: React.ReactNode;
}

const OptionItem = ({ optionData, questionId, removeOption, icon }: Props) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleRemoveOption = () => {
    dispatch(
      // @ts-ignore
      removeOption({
        optionId: optionData.id,
        questionId: questionId,
      })
    );
  };

  return (
    <Box
      display={'flex'}
      borderRadius={2}
      //@ts-ignore
      bgcolor={theme => theme.palette.background.dropdownMenu}
      py={1}
      px={0.5}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} gap={1} alignItems={'center'}>
        {icon}
        <Typography>{optionData.value}</Typography>
      </Box>
      <IconButton onClick={handleRemoveOption} size={'small'}>
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default OptionItem;
