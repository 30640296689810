import { useEffect, useRef, useState } from 'react';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';
import useCheckbox, { UseCheckboxType } from 'hooks/useCheckbox';
import useField from 'hooks/useField';
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { useSelector } from 'react-redux';
import {
  resetSearchMessages,
  searchMessage,
  selectSearchMessages,
  selectSearchMessagesStatus,
} from 'redux/features/messagesSlice/messagesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { SearchMessageData } from 'redux/features/messagesSlice/types/SearchMessageData';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

/**
 * Custom hook to filter messages based on a search field and a checkbox.
 * @param conversationId - The ID of the conversation for which the messages need to be filtered.
 * @returns An object containing the filtered messages, loading state, search field state/handler and checkbox state/handler.
 */

export interface UseFilterMessage {
  filterLoading: boolean;
  cantFindAny: boolean;
  // filteredMessages: IMessage[];
  searchField: ReturnType<typeof useField>;
  highligthMessagesCheckbox: UseCheckboxType;
  resetAll: () => void;
}

export const useFilterMessageInitialDef = {
  filterLoading: false,
  filteredMessages: [],
  searchField: fieldInitialDef,
  highligthMessagesCheckbox: {
    actions: { resetValue: () => {} },
    attributes: { onChange: () => {}, checked: false },
  },
  cantFindAny: false,
  resetAll: () => {},
};

const useFilterMessage = (conversationId: string): UseFilterMessage => {
  const dispatch = useAppDispatch();

  const highligthMessagesCheckbox = useCheckbox();
  const searchField = useField('text');

  const conversationSelected = useSelector(selectConversationSelected);
  const searchMessagesStatus = useSelector(selectSearchMessagesStatus);
  const foundMessages = useSelector(selectSearchMessages);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [filterLoading, setFilterLoading] = useState(
    searchMessagesStatus === 'loading'
  );
  const [cantFindAny, setCantFindAny] = useState(false);

  async function searchAllHighlightedMessages() {
    setCantFindAny(false);

    try {
      if (!foundMessages.length) {
        setCantFindAny(true);
      }
    } catch (error) {
      console.error('Error filtering highligthed messages:', error);
    }
  }

  async function searchMessages() {
    const filterValue = searchField.attributes.value.toLowerCase();
    // If the search field is empty, set the filtered messages to [] and return
    setCantFindAny(false);

    if (!filterValue) {
      dispatch(resetSearchMessages());

      return;
    }

    try {
      const searchData: SearchMessageData = {
        channelType: conversationSelected.channelInfo.type,
        isHighlight: highligthMessagesCheckbox.attributes.checked,
        text: filterValue,
        limit: 50,
        pos: '0',
        contactId: conversationSelected.contactInfo.id,
      };
      const searchMessagesRes = await dispatch(searchMessage(searchData));

      // If there are no messages, set the `cantFindAny` state to true
      if (!(searchMessagesRes.payload as any[]).length) {
        setCantFindAny(true);
      }
    } catch (error) {
      console.error('Error filtering messages:', error);
    }
  }

  function resetAll() {
    highligthMessagesCheckbox.actions.resetValue();
    searchField.actions.resetValue();
    // setFilteredMessages([]);
    setFilterLoading(false);
    setCantFindAny(false);
  }

  useEffect(() => {
    // Search the messages every time the checkbox is checked or unchecked
    if (
      !searchField.attributes.value &&
      highligthMessagesCheckbox.attributes.checked
    ) {
      searchAllHighlightedMessages();
    } else {
      searchMessages();
    }
  }, [highligthMessagesCheckbox.attributes.checked]);

  useEffect(() => {
    // Clear the previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer to delay the filtering process
    timerRef.current = setTimeout(async () => {
      await searchMessages();
    }, 150);

    // Clean up the timer when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchField.attributes.value]);

  // Use effect state reset
  useEffect(() => {
    return () => resetAll();
  }, []);

  return {
    cantFindAny,
    filterLoading,
    // filteredMessages,
    searchField,
    highligthMessagesCheckbox,
    resetAll,
  };
};

export default useFilterMessage;
