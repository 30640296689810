import React from "react";
//components/ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
const ModalProgramSend = ({ open, handleClose }) => {
  const handleAccept = () => {
    handleClose();
  };
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "390px",
          height: "100%",
          maxHeight: "750px",
          borderRadius: "3px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          border: "1px solid",
          borderColor:
            //@ts-ignore
            (theme) => theme.palette.divider,
          padding: "6px",
          background:
            //@ts-ignore
            (theme) => theme.palette.background.panel,
        },
      }}
    >
      <DialogTitle>{t("myReports.scheduleShipping")}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}></Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          {t("global.cancel")}
        </Button>
        <Button variant="contained" size="small" onClick={handleAccept}>
          {t("global.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalProgramSend;
