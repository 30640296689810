import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeHomeLogoStatus,
  changeHomeLogoUrl,
  uploadWebChatImage,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import OptionsContainer from '../../../OptionsContainer';
import { TextField, Switch, FormControlLabel, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  homeLogoEnabled: boolean;
  homeLogoUrl: string;
}

const LogoOptions = ({ homeLogoEnabled, homeLogoUrl }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleHomeLogoStatusChange = () => {
    dispatch(changeHomeLogoStatus(!homeLogoEnabled));
  };
  const handleHomeLogoUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeHomeLogoUrl(event.target.value));
  };
  const handleFileUpload = async (event) => {
    setIsUploading(true);

    const file = event.target.files[0];
    const fileURL = await dispatch(uploadWebChatImage(file));
    // @ts-ignore
    dispatch(changeHomeLogoUrl(fileURL.payload));
    setIsUploading(false);
  };

  return (
    <OptionsContainer title="Logo">
      <FormControlLabel
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
        control={<Switch checked={homeLogoEnabled} />}
        label={t('webChatEditView.designPanel.homeSection.showLogo')}
        labelPlacement="start"
        onChange={handleHomeLogoStatusChange}
      />
      <TextField
        fullWidth
        size="small"
        label={t('webChatEditView.designPanel.homeSection.addLogo')}
        value={homeLogoUrl}
        onChange={handleHomeLogoUrlChange}
      />
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'subtitle1'}>
          {t('webChatEditView.designPanel.homeSection.uploadLogo')}
        </Typography>
        <LoadingButton
          loading={isUploading}
          disabled={isUploading}
          size="small"
          variant="outlined"
          component="label"
        >
          {t('webChatEditView.designPanel.homeSection.upload')}
          <input onChange={handleFileUpload} hidden accept="image/*" type="file" />
        </LoadingButton>
      </Box>
    </OptionsContainer>
  );
};

export default LogoOptions;
