// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectFlowsFetchStatus,
  selectAllFlows,
} from 'redux/features/flowSlice/flowSlice';
// Components/ui
import { Box } from '@mui/material';
import { FlowItemSkeleton, FlowItem } from './components';

const ListBody = () => {
  const allFlows = useSelector(selectAllFlows);
  const flowsFetchStatus: RequestStatus = useSelector(selectFlowsFetchStatus);

  const Flows = allFlows?.map((flow) => <FlowItem key={flow.id} data={flow} />);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {flowsFetchStatus === 'succeeded' ? Flows : <FlowItemSkeleton />}
    </Box>
  );
};

export default ListBody;
