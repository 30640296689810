import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, styled } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface ExternalLinkProps {
  url: string;
}

const StyledButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  padding: theme.spacing(1),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const ExternalLink: React.FC<ExternalLinkProps> = ({ url }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <StyledButton onClick={handleClick}>
      <Typography variant="body1">
        {t('conversations.sidebar.buttons.openInExternal')}
      </Typography>
      <OpenInNewIcon fontSize="small" />
    </StyledButton>
  );
};

export default ExternalLink;
