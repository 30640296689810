import React from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  children: React.ReactNode;
}

const OptionsContainer = ({ title, children }: Props) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1.5}
      alignItems={'center'}
      sx={{
        width: { xs: '100%', lg: '50%' },
      }}
      pb={4}
      borderBottom={'1px solid #E0E0E0'}
    >
      <Typography variant="subtitle2">{title}</Typography>
      {children}
    </Box>
  );
};

export default OptionsContainer;
