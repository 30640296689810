// Translations
import { useTranslation } from "react-i18next";
// Components
import {
  Select as MuiSelect,
  MenuItem,
  CircularProgress,
  Box,
  FormControl,
  InputLabel
} from "@mui/material"
import { IChannelInfo } from "@trii/types/dist/Common/Channels";
import { useMemo } from "react";
// Types

interface Props {
  list: IChannelInfo[];
  isLoading: boolean;
  handleSelect: (id: string) => void;
  value: string;
}

const Selector = ({
  list,
  isLoading,
  handleSelect,
  value,
}: Props) => {
  const { t } = useTranslation();
  return useMemo(() => (
    <FormControl
      size="small"
      fullWidth
      sx={{
        color: theme => theme.palette.text.primary,
      }}
    >
      <InputLabel id="select-label">{t('dashboard.channels.registers.select')}</InputLabel>
      <MuiSelect
        size="small"
        labelId="select-label"
        label={t('dashboard.channels.registers.select')}
        sx={{
          width: '30%',
          color: theme => theme.palette.text.primary,
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '20rem',
              width: 'max-content',
              overflowY: 'auto',
            }

          }
        }}
        onChange={(e) => handleSelect(e.target.value as string)}
        defaultValue={value}
        value={value}
      >
        {
          isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          ) : list && list.length > 0 && list.map((item: any) => (
            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
          ))
        }
      </MuiSelect>
    </FormControl>
  ), [list, isLoading, value])
}

export default Selector