import React, { useState, useEffect } from 'react';
// Components/ui
import { Box, Fade } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const ChannelInputsContainer = ({ children }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <Fade in={show}>
      <Box
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        flexDirection={'column'}
        gap={1.5}
      >
        {children}
      </Box>
    </Fade>
  );
};

export default ChannelInputsContainer;
