import React, { useState, useContext, useEffect } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Context
import { InitEditFlowsContext } from "features/Views/EditFlows/context/EditFlowsContext";
import { EditFlowsContext } from "features/Views/EditFlows/context/types";
// Components/ui
import { Box, Typography, Divider, IconButton, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers/TimeField";
// dayjs
import dayjs from "dayjs";
// Custom Components
import { Emojis, Input } from "components";
import { InsertEmoticon } from "@mui/icons-material";

const AutomaticResponses = () => {
  const { selectNode, msgWaitResponseNode, setMsgWaitResponseNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [defaultResponse, setDefaultResponse] = useState<string>("");
  const [minute, setMinute] = useState<number | null>(0);
  const [reminderMessage, setReminderMessage] = useState<string>("");
  const [newEmojiDefaultResponse, setNewEmojiDefaultResponse] =
    useState<string>("");
  const [newEmojiReminderMessage, setNewEmojiReminderMessage] =
    useState<string>("");
  const [activeEmojiPicker, setActiveEmojiPicker] = useState<string | null>(
    null
  );
  const theme = useTheme();

  useEffect(() => {
    if (selectNode) {
      setDefaultResponse(
        msgWaitResponseNode.autoResponseOnNoConditionMatchMessage
          ? msgWaitResponseNode.autoResponseOnNoConditionMatchMessage
          : ""
      );
      setMinute(
        msgWaitResponseNode.autoResponseOnTimeOutMinutes
          ? msgWaitResponseNode.autoResponseOnTimeOutMinutes
          : 0
      );
      setReminderMessage(
        msgWaitResponseNode.autoResponseOnTimeOutMessage
          ? msgWaitResponseNode.autoResponseOnTimeOutMessage
          : ""
      );
    }
  }, [selectNode]);

  const handleChangeDefaultResponse = (value: string) => {
    setDefaultResponse(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      autoResponseOnNoConditionMatchMessage: value,
    });
  };

  const handleChangeReminderMessage = (value: string) => {
    setReminderMessage(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      autoResponseOnTimeOutMessage: value,
    });
  };

  const handleChangeHour = (minute: any) => {
    const newMinute = minute?.$m || 0;
    setMinute(newMinute);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      autoResponseOnTimeOutMinutes: newMinute,
    });
  };

  const handleEmojiSelect = (emoji) => {
    if (activeEmojiPicker === "defaultResponse") {
      handleChangeDefaultResponse(defaultResponse);
      setNewEmojiDefaultResponse(emoji.native)
      
    } else if (activeEmojiPicker === "reminderMessage") {
      handleChangeReminderMessage(reminderMessage  );
      setNewEmojiReminderMessage(emoji.native)
    }
    };

  const defaultResponseLabel = t(
    "editFlowsView.waitResponse.defaultResponsePlaceholder"
  );

  const renderEmojiPicker = (inputName) => {
    if (activeEmojiPicker === inputName) {
      return (
        <Box
          height="100%"
          width="100%"
          maxHeight="200px"
          position="relative"
          sx={{
            "& div": {
              marginTop: "5px",
              marginBottom: "35px",
            },
            "& div div em-emoji-picker": {
              //@ts-ignore
              background: theme.palette.background.dropdownMenu,
            },
          }}
        >
          <Emojis handleEmojiSelect={handleEmojiSelect} />
        </Box>
      );
    }
    return null;
  };
  const InputStyle = {
    marginTop: "15px",
    "&.input-container::before": {
      content: `"${defaultResponseLabel}"`,
      position: "absolute",
      top: "-10px",
      left: "10px",
      backgroundColor: theme.palette.background.default,
      padding: "0 4px",
      color: theme.palette.text.primary,
      fontSize: "12px",
      zIndex: 1,
      transition: "color 0.3s ease",
    },
    "&.input-container div:first-of-type": {
      color: theme.palette.text.primary,
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.action.disabled}`,
      borderRadius: "4px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      transition: "border-color 0.3s ease",
      maxHeight: "150px",
      minHeight: "150px",
      paddingRight: "30px",
    },
    "&.input-container:focus-within div:first-of-type": {
      borderColor: theme.palette.primary.main,
    },
    "&.input-container:focus-within::before": {
      color: theme.palette.primary.main,
    },
  };
  return (
    <>
      <Box>
        <Box>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ marginY: "5px", color: "text.primary" }}
          >
            {t("editFlowsView.waitResponse.defaultResponse")}
          </Typography>

          <Box position="relative" className="input-container" sx={InputStyle}>
            <Input
              value={defaultResponse}
              newEmoji={newEmojiDefaultResponse}
              maxHeigth="inherit"
              conversationId={""}
              contactId={""}
              setValue={handleChangeDefaultResponse}
              setNewEmoji={setNewEmojiDefaultResponse}
              handleSendMessage={() => {}}
              disableEnterKeyAction={true}
            />
            <Box
              position="absolute"
              sx={{
                top: "3px",
                right: 0,
              }}
            >
              <IconButton
                size="small"
                onClick={() =>
                  setActiveEmojiPicker(
                    activeEmojiPicker === "defaultResponse"
                      ? null
                      : "defaultResponse"
                  )
                }
                sx={{
                  color: "text.disabled",
                }}
              >
                <InsertEmoticon />
              </IconButton>
            </Box>
          </Box>
          {renderEmojiPicker("defaultResponse")}
        </Box>
        <Divider sx={{ my: ".5rem" }} />
        <Box>
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ marginY: "5px", color: "text.primary" }}
          >
            {t("editFlowsView.waitResponse.reminderMessage")}
          </Typography>
          <Box sx={{ marginTop: "10px", marginBottom: "5px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                label={t("editFlowsView.waitResponse.inputMinutes")}
                size="small"
                ampm={false}
                sx={{ width: "100%", mb: ".1rem" }}
                defaultValue={
                  minute
                    ? dayjs().set("minute", minute)
                    : dayjs().set("minute", 0)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                value={dayjs().set("minute", minute)}
                format="mm"
                onChange={(e) => handleChangeHour(e)}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginTop: "15px", marginBottom: "5px" }}>
            <Box
              position="relative"
              className="input-container"
              sx={InputStyle}
            >
              <Input
                newEmoji={newEmojiReminderMessage}
                maxHeigth="inherit"
                conversationId={""}
                contactId={""}
                setNewEmoji={setNewEmojiReminderMessage}
                handleSendMessage={() => {}}
                value={reminderMessage}
                setValue={handleChangeReminderMessage}
                disableEnterKeyAction={true}
              />
              <Box
                position="absolute"
                sx={{
                  top: "3px",
                  right: 0,
                }}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    setActiveEmojiPicker(
                      activeEmojiPicker === "reminderMessage"
                        ? null
                        : "reminderMessage"
                    )
                  }
                  sx={{
                    color: "text.disabled",
                  }}
                >
                  <InsertEmoticon />
                </IconButton>
              </Box>
            </Box>
            {renderEmojiPicker("reminderMessage")}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AutomaticResponses;
