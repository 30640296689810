import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';
// Components/ui
import { Box, Typography, Divider, Button } from '@mui/material';
import { StyleContext } from 'style/styleProvider';
import ScheduleDays from './components';
// ID
import { v4 as uuidv4 } from 'uuid';

const ScheduleList = () => {
  const { t } = useTranslation();
  const {
    timeRanges,
    setTimeRanges,
  } = useContext(InitScheduleEditContext);
  const { fontSize } = useContext(StyleContext);

  const handleAddTimeRange = () => {
    const newTimeRange = {
      id: uuidv4(),
      name: '',
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      start: '',
      end: '',
      startSeconds: 28800,
      endSeconds: 32400,
    };
    if (timeRanges && timeRanges.length > 0) {
      setTimeRanges((prev) => [...prev, newTimeRange]);
    } else {
      setTimeRanges([newTimeRange]);
    }
  }

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <Box
        display="flex"
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}
      >
        <Typography variant="subtitle2" fontSize={fontSize.sm} sx={{ color: 'text.primary' }}>
          {t('scheduleView.workingHours')}
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={handleAddTimeRange}
          sx={{
            color: 'text.primary',
          }}
        >
          {t('scheduleView.addTimeRange')}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {
          timeRanges && timeRanges.length > 0 && timeRanges.map((item) => (
            <ScheduleDays
              key={item.id}
              timeRange={item}
            />
          ))
        }
      </Box>
      <Divider sx={{ margin: '8px' }} />
    </Box>
  );
};

export default ScheduleList;
