// Components/ui
import { Skeleton } from '@mui/material';

const skeletons = [1, 2, 3];

const PanelsSkeleton = () => {
  return (
    <>
      {skeletons.map((skeleton) => (
        <Skeleton
          key={skeleton}
          variant="rectangular"
          width={'100%'}
          height={50}
          sx={{
            borderRadius: 1,
          }}
        />
      ))}
    </>
  );
};

export default PanelsSkeleton;
