import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeConnectedEmail } from 'redux/features/emailEditSlice/emailEditSlice';
// Components/ui
import { ChannelPanel, ChannelPanelInnerContainer } from 'components';
import { TextField, Button } from '@mui/material';
import { SetUpModal } from './components';
import SectionContainer from './components/SectionContainer/SectionContainer';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { EmailConfig } from '@trii/types/dist/Common/Channels';

interface Props {
  connectedEmail: EmailConfig;
}

const IncomingEmailPanel = ({ connectedEmail }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [setUpModalOpen, setSetUpModalOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeConnectedEmail(event.target.value));
  };
  const handleOpenSetUpModal = () => setSetUpModalOpen(true);
  const handleCloseSetUpModal = () => setSetUpModalOpen(false);

  return (
    <>
      <TextField
        size="small"
        label={t("emailEditView.incomingEmailPanel.connectedEmailAddress")}
        fullWidth
        onChange={handleChange}
        value={connectedEmail.inboundConnectedEmail}
        InputLabelProps={{
          shrink: true,
        }}
        disabled
      />
      <Button
        variant="outlined"
        sx={{ alignSelf: "flex-end" }}
        color="primary"
        size="small"
        onClick={handleOpenSetUpModal}
      >
        {t("global.setUp")}
      </Button>
      <SetUpModal isOpen={setUpModalOpen} handleClose={handleCloseSetUpModal} />
      <SectionContainer status={connectedEmail.inboundConnectedEmailStatus} title={t('emailEditView.incomingEmailPanel.title')}>
        <></>
      </SectionContainer>
    </>
  );
};

export default IncomingEmailPanel;
