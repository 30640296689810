// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
// Icons
import { Person } from '@mui/icons-material';
import { UIEvent } from 'react';
import { IContactInfo } from '@trii/types/dist/Contacts';

interface Props {
  handleScroll: (e: UIEvent<HTMLElement>) => void;
  contactList: IContactInfo[];
  handleSelectContact: (contact: IContactInfo) => void;
  contactSelectedId: string;
  search: string;
}

const ContactSearchResults = ({
  handleScroll,
  contactList,
  handleSelectContact,
  contactSelectedId,
  search,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <List
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        border: `1px solid ${theme.palette.divider}`,
        borderTop: 'none',
      }}
      onScroll={handleScroll}
    >
      {search &&
        contactList?.map((business) => (
          <ListItemButton
            key={business.id}
            sx={{
              width: '100%',
            }}
            onClick={() => handleSelectContact(business)}
            selected={contactSelectedId === business.id}
          >
            <ListItemIcon>
              <Person
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </ListItemIcon>
            <ListItemText primary={t(business.name)} secondary={business?.phone} />
          </ListItemButton>
        ))}
    </List>
  );
};

export default ContactSearchResults;
