import { useContext } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Components
import { Card, Metric, Title } from "@tremor/react";
import { Box } from "@mui/material";
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
// Types
import { DataDashboard } from "redux/features/dashboardSlice/types/DataDashboard";
import { RecordsDashboardType } from "@trii/types/dist/Conversations";

const RecordsCard = ({ recordsData }) => {
  const { t } = useTranslation()
  const {
    userDashboard,
    setModalRegisterTitle,
    setModalRegisterOpen,
    getLogs,
  } = useContext(DashboardContext)

  const getTitleName = (type: RecordsDashboardType) => {
    switch (type) {
      case RecordsDashboardType.ALL:
        return 'dashboard.records.all'
      case RecordsDashboardType.EXPIRED:
        return 'dashboard.records.expired'
      case RecordsDashboardType.IN_PROGRESS:
        return 'dashboard.records.inProgress'
      case RecordsDashboardType.NEW:
        return 'dashboard.records.new'
      default:
        return 'dashboard.records.all'
    }
  }

  const handleOpenModalRegister = () => {
    if (recordsData.type !== null) {
      setModalRegisterTitle(getTitleName(recordsData.type));
      setModalRegisterOpen(true);
      const data = {
        ...userDashboard,
        cardType: recordsData.type,
      } as DataDashboard;
      getLogs(data);
    }
  }

  return (
    <Box
      component={Card}
      className="p-2"
      sx={{
        // @ts-ignore
        backgroundColor: theme => theme.palette.background.panel,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
      }}
    >
      <Box
        className="truncate"
        sx={{
          color: theme => theme.palette.text.primary,
        }}
      >
        <Title className="text-center">
          {recordsData?.type == 0 ? t("dashboard.conversations") : recordsData?.type == 1 ? t("dashboard.news/inLine") : recordsData?.type == 2 ? t("dashboard.inProgress/inAgents") : recordsData?.type == 3 ? t("dashboard.expired") : null}
        </Title>
        <Metric
          className="text-center cursor-pointer text-2xl"
          onClick={handleOpenModalRegister}
        >
          {recordsData.quantity}
        </Metric>
      </Box>
    </Box>
  );
};

export default RecordsCard;
