import { useContext, useEffect, useState } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
// Icons
import {
  Adb,
  Done,
  Label,
  Search,
  SyncAlt,
  TrendingFlat,
} from '@mui/icons-material';
// Components
import { MoreActions } from './components';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
import { ChatBotIcon } from 'components';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const ConversationActions = () => {
  const { t } = useTranslation();
  const conversationSelected = useSelector(selectConversationSelected);
  const {
    handleFinalizeConversation,
    handleTransferTo,
    getUsers,
    getGroups,
    getLabels,
    setOpenModalList,
    setModalTitle,
  } = useContext(conversationsContext);
  const { setIsSearching } = useContext(messagesContext);
  const user = useSelector(selectUser);
  const [transferIn, setTransferIn] = useState<boolean>(false);
  const [showActions, setShowActions] = useState(false);
  const [cancelTransfer, setCancelTransfer] = useState<boolean>(false);

  const handleOpenTransferTo = () => {
    getUsers();
    getGroups();
  };

  const handleClick = (title: string, handle: () => void) => {
    setOpenModalList(true);
    setModalTitle(t(title));
    handle();
  };

  const handleSearchMessage = () => {
    setIsSearching(true);
  };
  const handleModalBot = (title: string) => {
    setOpenModalList(true);
    setModalTitle(t(title));
  };

  const handleCancel = () => {
    const data = {
      conversationId: conversationSelected.id,
      userId: '',
      groupId: '',
    };
    handleTransferTo(data);
    setCancelTransfer(false);
  };

  useEffect(() => {
    setShowActions(
      conversationSelected && conversationSelected?.type === ChatType.EXTERNAL
    );
  }, [conversationSelected?.type]);

  useEffect(() => {
    const getTransferTo = conversationSelected?.transferTo;
    if (getTransferTo) {
      setCancelTransfer(true);
      if (getTransferTo.userInfo) {
        setTransferIn(getTransferTo.userInfo.id !== user.uid);
      } else if (getTransferTo.userIds && getTransferTo.userIds.length > 0) {
        setTransferIn(!getTransferTo.userIds.includes(user.uid));
      }
    } else {
      setCancelTransfer(false);
    }
  }, [conversationSelected]);
  return (
    <Box display="flex" alignItems={'center'} height="100%">
      {showActions && (
        <>
          {/* Bot */}
          {/* <Tooltip title={t("conversations.actions.cancelTransfer")} arrow> */}
          {conversationSelected?.botStatus !== undefined &&
            conversationSelected?.botStatus !== 0 && (
              <IconButton onClick={() => handleModalBot('conversations.botAnswers')}>
                <ChatBotIcon
                  color={
                    conversationSelected.botStatus === 4 ? '#0aa709' : 'inherit'
                  }
                />
              </IconButton>
            )}
          {/* </Tooltip> */}

          {cancelTransfer ? (
            <Tooltip title={t('conversations.actions.cancelTransfer')} arrow>
              <IconButton
                sx={{
                  color: transferIn ? '#007bff' : '#0aa709',
                  rotate: transferIn ? '320deg' : '140deg',
                }}
                onClick={handleCancel}
              >
                <TrendingFlat />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('conversations.actions.transfer')} arrow>
              <IconButton
                onClick={() =>
                  handleClick('conversations.actions.transfer', handleOpenTransferTo)
                }
                sx={{ color: 'text.disabled', rotate: '140deg' }}
              >
                <SyncAlt />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t('conversations.actions.label')} arrow>
            <IconButton
              onClick={() => handleClick('conversations.actions.label', getLabels)}
              sx={{ color: 'text.disabled' }}
            >
              <Label />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip title={t('conversations.actions.search')} arrow>
        <IconButton sx={{ color: 'text.disabled' }} onClick={handleSearchMessage}>
          <Search />
        </IconButton>
      </Tooltip>
      {showActions && (
        <>
          <Button
            size="small"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.dark,
              },
              height: '25px',
              paddingRight: '10px',
            }}
            onClick={() => handleFinalizeConversation(conversationSelected.id)}
          >
            <Done fontSize="small" />
            <Typography fontSize="small" color="inherit">
              {t('conversations.actions.done')}
            </Typography>
          </Button>
          <MoreActions showActions={showActions} />
        </>
      )}
    </Box>
  );
};

export default ConversationActions;
