import React from 'react';
// Components/ui
import { Skeleton } from '@mui/material';
const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ListItemSkeleton = () => {
  const Skeletons = skeletons.map((skeleton) => (
    <Skeleton key={skeleton} variant={'rounded'} width={'100%'} height={37} />
  ));

  return <>{Skeletons}</>;
};

export default ListItemSkeleton;
