import { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateFlow from '../hooks/useCreateLabel/useCreateFlow';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createFlowInitialState from '../hooks/useCreateLabel/createFlowInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { createFlow, deleteFlow } from 'redux/features/flowSlice/flowSlice';
// Types
import type { CrudPanelState } from './types';
import { FlowContext } from './types';
import { IFlow } from '@trii/types/dist/Conversations/Flows';
import { UseFieldType } from 'hooks/useField';

export const flowContext = createContext<FlowContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateFlow: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedFlow: null,
  deleteFlowName: fieldInitialDef,
  createFlowState: createFlowInitialState,
  handleDeleteFlow: () => {},
  navigateToEdit: () => {},
});

const FlowProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedFlow, setSelectedFlow] = useState<IFlow>(null);
  // Create state
  const createFlowState = useCreateFlow();
  // Delete state
  const deleteFlowName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateFlow = async () => {
    const newFlow = createFlowState.actions.getNewFlow();
    await dispatch(createFlow(newFlow));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createFlowState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteFlow = async () => {
    const { id } = selectedFlow;

    await dispatch(deleteFlow(id));
    endDeleting();
  };

  const openDelete = (flow: IFlow) => {
    setSelectedFlow(flow);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteFlowName.actions.resetValue();
    setSelectedFlow(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (flowId: string) => {
    navigate(`/a/conversations/system/flows/${flowId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <flowContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateFlow,
        openDelete,
        endDeleting,
        deleteFlowName,
        createFlowState,
        selectedFlow,
        handleDeleteFlow,
        navigateToEdit,
      }}
    >
      {children}
    </flowContext.Provider>
  );
};

export default FlowProvider;
