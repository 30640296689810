import { useState, useContext, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Divider } from '@mui/material';
// Slice
import { selectCreteConversationStatus } from 'redux/features/conversationsSlice/conversationsSlice';
// Components
import { Alert } from 'components';
import { ActionButtons, AssignTo, ChannelCard, SearchContact } from './components';
// Types
import { IContactAddress, IContactInfo } from '@trii/types/dist/Contacts';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { LoadingButton } from '@mui/lab';

const CreateConversation = () => {
  const { t } = useTranslation();

  const {
    originSelected,
    destinationSelected,
    assignedTo,
    contactSelected,
    error,
    errorMessage,
    getUserInfo,
    setOpenModalList,
    setConversation,
    setContactSelected,
    handleCloseAlert,
  } = useContext(conversationsContext);

  const createConversationStatus = useSelector(selectCreteConversationStatus);

  const isLoading = createConversationStatus === 'loading';

  const [list, setList] = useState<IContactAddress[]>([]);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [channelSelected, setChannelSelected] = useState<ChannelType>(
    ChannelType.NULL
  );

  const handleSelectContact = (contact: IContactInfo) => {
    setContactSelected(contact);
    if (contact) {
      getUserInfo(contact.id);
    }
  };

  const handleClose = () => {
    setContactSelected(null);
    setChannelSelected(ChannelType.NULL);
    setList([]);
    setOpenModalList(false);
  };

  const handleSave = async () => {
    const data = {
      contactId: contactSelected.id,
      contactAddressId: destinationSelected,
      channelType: channelSelected,
      channelId: originSelected,
      assignedTo,
    };
    const response = await setConversation(data);
    if (response) {
      handleClose();
    }
  };

  useEffect(() => {
    if (contactSelected) {
      setChannelSelected(ChannelType.NULL);
      setList([]);
    }
  }, [contactSelected]);

  useEffect(() => {
    if (
      channelSelected !== ChannelType.NULL &&
      contactSelected &&
      destinationSelected?.length > 0 &&
      originSelected &&
      assignedTo
    ) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [
    channelSelected,
    contactSelected,
    destinationSelected,
    originSelected,
    assignedTo,
  ]);

  return (
    <Box
      width="100%"
      height="100%"
      p={1}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Box width="100%" height="100%" display="flex" flexDirection="column" gap={1}>
        <SearchContact
          contactTitle={t('conversations.createConversation.contact')}
          searchTitle={t('conversations.createConversation.search')}
          contactSelected={contactSelected}
          handleSelectContact={handleSelectContact}
        />
        <AssignTo />
        <ActionButtons
          contactSelected={contactSelected}
          channelSelected={channelSelected}
          setChannelSelected={setChannelSelected}
          setList={setList}
        />
        {channelSelected !== ChannelType.NULL && (
          <ChannelCard channelSelected={channelSelected} list={list} />
        )}
      </Box>
      <Divider />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
        }}
      >
        <Button size="small" onClick={handleClose} variant="outlined" color="error">
          {t('global.cancel')}
        </Button>
        <LoadingButton
          size="small"
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!enableSave}
          loading={isLoading}
        >
          {t('global.create')}
        </LoadingButton>
      </Box>
      {error && (
        <Alert
          open={error}
          severity={'error'}
          onClose={handleCloseAlert}
          errorMessage={errorMessage}
        />
      )}
    </Box>
  );
};

export default CreateConversation;
