import { useContext } from "react"
// Translations
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import {  TemplateEdit } from "./components"
import { TemplatesContext } from "features/Views/Templates/context/TemplatesContext"

const EditPanel = () => {
  const { t } = useTranslation();
  const {
    editionType,
  } = useContext(TemplatesContext);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'inherit',
        justifyContent: 'flex-start',
        alignItems: 'start',
        gap: 1,
        p: 2,
      }}
    >

      {/* {editionType === 'channel' && <Category />} */}
      {editionType === 'template'  && <TemplateEdit />}
      {(!editionType || editionType === 'channel') && (
        <Typography
          variant="h6"
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            color: (theme) => theme.palette.text.primary,
          }}
          fontWeight="bold"
        >
          {t('templates.select')}
        </Typography>
      )}
    </Box>
  );
};

export default EditPanel;
