import { memo, useContext } from 'react';
import { Handle, Position, NodeToolbar, NodeProps } from 'reactflow';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
import { StyleContext } from 'style/styleProvider';
// Components/ui
import { Box, Typography, IconButton, useTheme } from '@mui/material';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import { NodeData } from '@trii/types/dist/Conversations/Flows';

function TimeWaitUntilCustomNode({ id, data, selected }: NodeProps<NodeData>) {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { nodes, edges, setEdges, setSelectNode, setEditPanelOpen, setNodes } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const timeWaitUntil = data && data?.timeWaitUntil;

  const onNodesDelete = (id: string) => {
    const newNodes = nodes.filter((node) => node.id !== id);
    const newEdges = edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );
    setNodes(newNodes);
    setEdges(newEdges);
    setSelectNode(null);
    setEditPanelOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: shadow.lg,
        border: `1px solid ${
          selected ? theme.palette.primary.light : 'rgba(0,0,0,0.1)'
        }`,
        borderRadius: '.5rem',
        height: 'max-content',
        padding: '0.5rem',
      }}
    >
      <NodeToolbar offset={-10}>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onNodesDelete(id)}
          sx={{
            '& :hover': {
              color: 'text.disabled',
            },
          }}
        >
          <ClearIcon />
        </IconButton>
      </NodeToolbar>
      <Handle type="target" position={Position.Left} id="1" />
      <Typography
        sx={{ color: 'text.primary' }}
        fontWeight={fontWeight.medium}
        fontSize={10}
      >
        {timeWaitUntil?.name
          ? data.timeWaitUntil.name
          : t('editFlowsView.timeWaitUntil.title')}
      </Typography>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Typography
          sx={{ color: 'text.primary', marginRight: '1rem' }}
          fontSize={fontSize.xs}
        >
          {t('editFlowsView.timeWaitUntil.continue')}
        </Typography>
        <Handle type="source" position={Position.Right} id="2" />
      </Box>
    </Box>
  );
}

export default memo(TimeWaitUntilCustomNode);
