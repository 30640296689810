import { useTranslation } from 'react-i18next';
// Types
import { DNSRecord, EmailConfigOutboundStatus } from '@trii/types/dist/Common/Channels';
// Components/ui
import SectionContainer from '../SectionContainer';
import { CopyClipboardButton } from 'components';
import {
  Box,
  Typography,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Alert,
} from '@mui/material';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useParams } from 'react-router-dom';
import { testTrii } from 'redux/features/emailEditSlice/emailEditSlice';
import { useState } from 'react';

interface Props {
  statusDMARC: EmailConfigOutboundStatus;
  dataDMARC: DNSRecord[];
}

const SpfSection = ({ statusDMARC, dataDMARC }: Props) => {
  const { t } = useTranslation();
  const firstRecordValue = dataDMARC.length > 0 ? dataDMARC[0].value : '';
  
  const dispatch = useAppDispatch()
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const { id } = useParams();

  const testAdjust = async () => {
    try {
      const response = await dispatch(testTrii(id));
      
      // Assuming you check response to detect if it's successful or failed
      if (response.meta.requestStatus === 'fulfilled') {
        setSnackbarMessage(t("emailEditView.testConnection.testSuccess"));
        setSnackbarSeverity('success');
      } else if (response.meta.requestStatus === 'rejected') {
        //@ts-ignore
        setSnackbarMessage(t("emailEditView.testConnection.testFailed") + response.error.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('SMTP Test failed:', error);
    } finally {
      setOpen(true);
    }
   };

  return (
    <SectionContainer status={statusDMARC} title="DMARC" testAdjust={testAdjust}>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography variant={"body2"}>
          {t("emailEditView.outgoingEmailPanel.currentDMARCRecord")}
        </Typography>
        <Box
          bgcolor={
            //@ts-ignore
            (theme) => theme.palette.background.mainCenter
          }
          borderRadius={1}
          border={"1px solid"}
          borderColor={
            //@ts-ignore
            (theme) => theme.palette.divider
          }
          p={2}
        >
          {firstRecordValue}
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography variant={"body2"}>
          {t("emailEditView.outgoingEmailPanel.recommendedDMARCRecord")}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.mainCenter,
              }}
            >
              <TableRow>
                <TableCell>Host</TableCell>
                <TableCell>{t("global.type")}</TableCell>
                <TableCell>{t("global.value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataDMARC.map((record, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {record.name}
                  </TableCell>
                  <TableCell>{record.type}</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {record.value} <CopyClipboardButton text={record.value} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={snackbarSeverity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </SectionContainer>
  );
};

export default SpfSection;
