import React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import {
  selectIsEditingName,
  selectUpdateContactInfoStatus,
  setIsEditingName,
  updateContactInfo,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useField from 'hooks/useField';
import { IContact } from '@trii/types/dist/Contacts';
import { Dispatch, SetStateAction } from 'react';

const ContactName = ({
  contactInfo,
  setContactInfo,
}: {
  contactInfo: IContact;
  setContactInfo: Dispatch<SetStateAction<IContact>>;
}) => {
  const dispatch = useAppDispatch();

  const firstNameField = useField('text', contactInfo.firstName);
  const lastNameField = useField('text', contactInfo.lastName);

  const isEditingName = useSelector(selectIsEditingName);
  const updateContactInfoStatus = useSelector(selectUpdateContactInfoStatus);

  async function handleSaveContactName() {
    const data = {
      contactId: contactInfo?.id,
      data: {
        firstName: firstNameField.attributes.value,
        lastName: lastNameField.attributes.value,
      },
    };

    try {
      await dispatch(updateContactInfo(data));

      setContactInfo({
        ...contactInfo,
        name: `${firstNameField.attributes.value} ${lastNameField.attributes.value}`,
        firstName: firstNameField.attributes.value,
        lastName: lastNameField.attributes.value,
      });
      dispatch(setIsEditingName(false));
    } catch (error) {
      console.error(error);
    }
  }

  function handleCancelContactEdit() {
    dispatch(setIsEditingName(false));
    firstNameField.actions.changeValue(contactInfo.firstName);
    lastNameField.actions.changeValue(contactInfo.lastName);
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '90%', position: 'relative'  }}>
      {isEditingName ? (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between'}}>
<Box sx={{ visibility: 'hidden', width: '30px' }}> </Box>
        <Stack spacing={0.5}>
          <TextField {...firstNameField.attributes} variant="standard" />
          <TextField {...lastNameField.attributes} variant="standard" />
        </Stack>

        <Box >
        <Stack direction={'column'} spacing={0.5}>
          {updateContactInfoStatus === 'loading' ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton onClick={handleSaveContactName} size="small">
              <SaveIcon color="primary" />
            </IconButton>
          )}
          <IconButton
            disabled={updateContactInfoStatus === 'loading'}
            onClick={handleCancelContactEdit}
            size="small"
          >
            <CloseIcon color="error" />
          </IconButton>
        </Stack>
      </Box>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between'}}>
           <Box sx={{ visibility: 'hidden', width: '30px' }}>
          </Box>
          <Typography
            variant="h6"
            fontWeight="semi-bold"
            color={'text.primary'}
            sx={{
              wordWrap: 'break-word',
              textAlign: 'center',
            }}
          >
            {contactInfo?.name}
          </Typography>
          <Box>
            <IconButton onClick={() => dispatch(setIsEditingName(true))} size="small">
              <EditIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Box>
        </Box>
      )}

      
    </Box>
  );
};

export default ContactName;
