import React from 'react';
// Components/ui
import { Avatar, Box, Button, Typography, useTheme } from '@mui/material';
import { MessageContact } from '@trii/types/dist/Common/Messages';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
interface contactsProps {
  contactsInfo: MessageContact[]
}


const Contacts = ({contactsInfo}: contactsProps) => {
   const theme = useTheme();
  return (
    <Box>
      {contactsInfo &&
        contactsInfo.length > 0 && contactsInfo.map((info, index) =>{
        return (
          <Box
            key={index}
            sx={{
              fontSize: "13px",
              wordBreak: "break-word",
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              color: (theme) => theme.palette.text.primary,
              backgroundColor: theme.palette.divider,
              p: "5px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            <Box display={"flex"} >
              <Avatar
                alt={info?.name.formattedName}
                src={null}
                sx={{ backgroundColor: theme.palette.primary.light }}
              >
                {info?.name.formattedName.charAt(0).toUpperCase()}
              </Avatar>
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  marginLeft: "5px",
                }}
              >
                <Typography variant="subtitle2">
                  {info.name.formattedName}
                </Typography>

                {info.addresses !== null &&
                  info.addresses.length > 0 &&
                  info.addresses.map((data, index) => (
                    <Typography variant="caption" key={index} sx={{display: "flex", alignItems: 'center', '& svg': {
                      fontSize: '1rem',
                    marginRight: '5px',

                    }}}>
                      <LocationOnIcon /> {data.street}
                    </Typography>
                  ))}
                {info?.birthday !== null && (
                  <Typography variant="caption" sx={{display: "flex", alignItems: 'center', '& svg': {
                    fontSize: '1rem',
                    marginRight: '5px',

                  }}}>
                    <CalendarMonthIcon /> {info?.birthday}
                  </Typography>
                )}
                {info?.org !== null && (
                  <Typography variant="caption" sx={{display: "flex", alignItems: 'center', '& svg': {
                    fontSize: '1rem',
                    marginRight: '5px',
                  }}}>
                    <BusinessIcon /> {info?.org?.company}
                  </Typography>
                )}

                {info.emails !== null &&
                  info.emails.length > 0 &&
                  info.emails.map((data, index) => (
                    <Typography variant="caption" key={index}>
                      {data?.email + (data.type ? (", " + data.type) : "")}
                    </Typography>
                  ))}
                {info.phones !== null &&
                  info.phones.length > 0 &&
                  info.phones.map((data, index) => (
                    <Typography variant="caption" key={index}>
                    {data?.phone + (data.type ? (", " + data.type) : "")}
                  </Typography>
                  ))}
              </Box>
            </Box>
          </Box>
        );
      }
        
      )}
    </Box>
  );
};

export default Contacts;
