import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';

async function getJSONButtonData(jwtToken: string, URL: string) {
  const config = getRequestConfig.basic(jwtToken);

  const response = await axios.get(URL, config);

  return response.data;
}

export default { getJSONButtonData };
