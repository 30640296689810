import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { EmailEditState } from './types/EmailEditState.js';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import {
  EmailConfigOutboundConnectionType,
  EmailConfigOutboundSecurityType,
  Permission,
  EmailConfigFromNameType,
  IChannel,
  EmailConfigOutboundStatus,
} from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
// Service
import emailEditService from './emailEditService';
import { SendVerCodeData } from './types/SendVerCodeData';
import { VerifyEmailData } from './types/VerifyEmailData';
import { VerifyEmailRequestStatus } from './types/VerifyEmailRequestStatus';
import { AssignMethod, ConversationAssigned } from '@trii/types/dist/Conversations';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';
import { UserInfo } from '@trii/types/dist/Users';
import { IGoogleAccountData } from '../googleBusinessSlice/types/IGoogleAccountData';

const initialState: EmailEditState = {
  channel: null,
  emailName: null,
  status: {
    fetch: 'idle',
    sendVerCode: 'idle',
    verifyEmail: 'idle',
    update: 'idle',
  },
};

export const fetchEmail = createAsyncThunk(
  'emailEdit/fetchEmail',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await emailEditService.fetchEmail(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateEmail = createAsyncThunk<IChannel, void, { state: RootState }>(
  'emailEdit/updateEmail',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const email = getState().EmailEdit.channel;

    await emailEditService.updateEmail(jwtToken, URL_CONVERSATIONS, email);
    return email;
  }
);

export const sendVerCode = createAsyncThunk(
  'email/sendVerCode',
  async (data: SendVerCodeData, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await emailEditService.sendVerCode(jwtToken, URL_CONVERSATIONS, data);
  }
);

export const verifyEmail = createAsyncThunk(
  'email/verifyEmail',
  async (data: VerifyEmailData, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { email, code, channelId } = data;

    const res = await emailEditService.verifyEmail(
      jwtToken,
      URL_CONVERSATIONS,
      channelId,
      {
        email,
        code,
      }
    );

    return res;
  }
);
export const testSendEmail = createAsyncThunk(
  'email/testSendEmail',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const res = await emailEditService.testSendEmail(
        jwtToken,
        URL_CONVERSATIONS,
        channelId
      );
      return res.data;
    } catch (error) {
      console.error('Error in thunk:', error);
      throw error;
    }
  }
);
export const testSMTP = createAsyncThunk(
  'email/testSMTP',
  async ({ channelId, smtpData }: { channelId: string, smtpData: any }, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const res = await emailEditService.testSMTP(
        jwtToken,
        URL_CONVERSATIONS,
        channelId,
        smtpData
      );
      return res.data;
    } catch (error) {
      console.error('Error in thunk:', error);
      throw error;
    }
  }
);

export const testTrii = createAsyncThunk(
  'email/testTrii',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const res = await emailEditService.testTrii(
        jwtToken,
        URL_CONVERSATIONS,
        channelId
      );
      return res.data;
    } catch (error) {
      console.error('Error in thunk:', error);
      throw error;
    }
  }
);
export const disconnectGoogle = createAsyncThunk(
  'email/disconnectGoogle',
  async (channelId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const res = await emailEditService.disconnectGoogle(
        jwtToken,
        URL_CONVERSATIONS,
        channelId
      );
      return res.data;
    } catch (error) {
      console.error('Error in thunk:', error);
      throw error;
    }
  }
);
export const connectGoogle = createAsyncThunk(
  'email/connectGoogle',
  async (
    { channelId, data }: { channelId: string; data: IGoogleAccountData },
    { dispatch }
  ) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const res = await emailEditService.connectGoogle(
        jwtToken,
        URL_CONVERSATIONS,
        channelId,
        data
      );
      return res.data;
    } catch (error) {
      console.error('Error in thunk:', error);
      throw error;
    }
  }
);

const emailEditSlice = createSlice({
  name: 'emailEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.email.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.email.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.email.reassignGroupId = action.payload;
    },
    addPermission: {
      prepare: (data: {
        groupId?: string;
        userId?: string;
      }): { payload: Permission } => ({
        payload: {
          groupId: data.groupId || null,
          readMessages: false,
          startConversations: false,
          userId: data.userId || null,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(
      state,
      action: PayloadAction<{ groupId?: string; userId?: string }>
    ) {
      let index = -1;

      if (action.payload.groupId) {
        index = state.channel.permissions.findIndex(
          (permission) => permission.groupId === action.payload.groupId
        );
      } else {
        index = state.channel.permissions.findIndex(
          (permission) => permission.userId === action.payload.userId
        );
      }

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.readMessages = readMessages;
      } else if (permissionByUserId) {
        permissionByUserId.readMessages = readMessages;
      }
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.startConversations = startConversations;
      } else if (permissionByUserId) {
        permissionByUserId.startConversations = startConversations;
      }
    },
    changeConnectedEmail(state, action: PayloadAction<string>) {
      state.channel.email.inboundConnectedEmail = action.payload;
    },
    changeConnectionType(
      state,
      action: PayloadAction<EmailConfigOutboundConnectionType>
    ) {
      state.channel.email.outboundConnectionType = action.payload;
    },
    changeOutboundSmtpServer(state, action: PayloadAction<string>) {
      state.channel.email.outboundSmtpServer = action.payload;
    },
    changeOutboundSmtpUser(state, action: PayloadAction<string>) {
      state.channel.email.outboundSmtpUser = action.payload;
    },
    changeOutboundSmtpPassword(state, action: PayloadAction<string>) {
      state.channel.email.outboundSmtPasword = action.payload;
    },
    changeOutboundSmtpPort(state, action: PayloadAction<number>) {
      state.channel.email.outboundSmtpPort = action.payload;
    },
    changeOutboundSmtpSecurityType(
      state,
      action: PayloadAction<EmailConfigOutboundSecurityType>
    ) {
      state.channel.email.outboundSmtSecurityType = action.payload;
    },
    changeEmailAdress(state, action: PayloadAction<string>) {
      state.channel.email.emailAddress = action.payload;
    },
    changeFromNameType(state, action: PayloadAction<EmailConfigFromNameType>) {
      state.channel.email.emailFromNameType = action.payload;
    },
    changeFromNameCustom(state, action: PayloadAction<string>) {
      state.channel.email.emailFromNameCustom = action.payload;
    },
    changeEmailVerificationStatus(
      state,
      action: PayloadAction<VerifyEmailRequestStatus>
    ) {
      state.status.verifyEmail = action.payload;
    },
    changeAssignMethod(state, action: PayloadAction<AssignMethod>) {
      state.channel.assignMethod = action.payload;
    },
    changeAssignTo(
      state,
      action: PayloadAction<{ group: GroupInfo; user: UserInfo }>
    ) {
      state.channel.assignTo = {
        groupInfo: action.payload.group,
        userInfo: action.payload.user,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmail.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchEmail.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched Email: ', action.payload);
        state.channel = action.payload;
        state.emailName = action.payload.name;
      })
      .addCase(updateEmail.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.emailName = action.payload.name;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      .addCase(verifyEmail.pending, (state) => {
        state.status.verifyEmail = 'loading';
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.status.verifyEmail = action.payload.status;
      })
      .addCase(connectGoogle.fulfilled, (state, action) => {
        state.channel.email.outboundGoogleAccount =
          action.payload.email.outboundGoogleAccount;
      })
      .addCase(disconnectGoogle.fulfilled, (state) => {
        state.channel.email.outboundGoogleAccount = null;
      });
  },
});

const selectEmailEditState = (state: RootState) => state.EmailEdit;
export const selectEmail = createSelector(
  selectEmailEditState,
  (state) => state.channel
);
export const selectEmailName = createSelector(
  selectEmailEditState,
  (state) => state.emailName
);
export const selectEmailFetchStatus = createSelector(
  selectEmailEditState,
  (state) => state.status.fetch
);
export const selectEmailUpdateStatus = createSelector(
  selectEmailEditState,
  (state) => state.status.update
);
export const selectEmailVerifyEmailStatus = createSelector(
  selectEmailEditState,
  (state) => state.status.verifyEmail
);
export const fetchGoogleAccount = createSelector(
  selectEmailEditState,
  (state) => state.channel.email.outboundGoogleAccount
);

export const {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  changeConnectedEmail,
  changeConnectionType,
  changeOutboundSmtpServer,
  changeOutboundSmtpUser,
  changeOutboundSmtpPassword,
  changeOutboundSmtpPort,
  changeOutboundSmtpSecurityType,
  changeEmailAdress,
  changeFromNameType,
  changeFromNameCustom,
  changeEmailVerificationStatus,
  changeAssignMethod,
  changeAssignTo,
} = emailEditSlice.actions;

export default emailEditSlice.reducer;
