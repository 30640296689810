import { useTranslation } from 'react-i18next';
//Types
import { Permission } from '@trii/types/dist/Common/Channels';
import { AddPermissionAction } from '../../redux/types/AddPermissionAction';
import { RemovePermissionAction } from '../../redux/types/RemovePermissionAction';
import { ChangePermissionReadMessagesAction } from '../../redux/types/ChangePermissionReadMessagesAction';
import { PermissionReadMessagesPayload } from '../../redux/types/PermissionReadMessagesPayload';
import { ChangePermissionStartConversationsAction } from '../../redux/types/ChangePermissionStartConversationsAction';
import { PermissionStartConversationsPayload } from '../../redux/types/PermissionStartConversationsPayload';
// Components/ui
import { ChannelPanel } from 'components';
import { GroupCard, GroupSelect } from './components';
import { Box, Divider } from '@mui/material';
import { fetchUsers } from 'redux/features/userSlice/userSlice';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface Props {
  data: Permission[] | null;
  addPermission: (groupId: string) => AddPermissionAction;
  removePermission: (groupId: string) => RemovePermissionAction;
  changePermissionReadMessages: (
    payload: PermissionReadMessagesPayload
  ) => ChangePermissionReadMessagesAction;
  changePermissionStartConversation: (
    payload: PermissionStartConversationsPayload
  ) => ChangePermissionStartConversationsAction;
}

const ChannelPermissionsPanel = ({
  data,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  addPermission,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const GroupCards =
    data &&
    data.map((permission) => (
      <GroupCard
        changePermissionReadMessages={changePermissionReadMessages}
        changePermissionStartConversation={changePermissionStartConversation}
        removePermission={removePermission}
        key={permission.groupId || permission.userId}
        data={permission}
      />
    ));

  const getUsers = async () => {
    await dispatch(fetchUsers());
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <ChannelPanel title={t('channelEditView.permissionsPanel.permissions')}>
      <Box display={'flex'} gap={2} flexDirection={'column'} alignItems={'center'}>
        <GroupSelect addPermission={addPermission} data={data} />
        <Divider sx={{ ml: 2 }} />
        {GroupCards}
      </Box>
    </ChannelPanel>
  );
};

export default ChannelPermissionsPanel;
