import { useState, useContext, useEffect } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { Box, Select, MenuItem, FormControl, Typography } from '@mui/material';
// Interface
import { SqlQueryDbType } from '@trii/types/dist/Conversations/Flows/Nodes';

const SqlEngine = () => {
  const { selectNode, setSqlQueryNode, sqlQueryNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [dbType, setDbType] = useState<SqlQueryDbType>(SqlQueryDbType.NONE);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data.sqlQuery) {
      setDbType(
        selectNode.data.sqlQuery.dbType
          ? selectNode.data.sqlQuery.dbType
          : SqlQueryDbType.NONE
      );
    }
  }, [selectNode]);

  const handleChangeDbType = (dbType: SqlQueryDbType) => {
    setDbType(dbType);
    setSqlQueryNode({
      ...sqlQueryNode,
      dbType,
    });
  };

  return (
    <Box>
      <Typography variant="subtitle2" fontWeight={"bold"} color="text.primary">
        {t("editFlowsView.sqlQuery.engine")}
      </Typography>
      <Box mt={1}>
        <FormControl fullWidth size="small">
          <Select
            labelId="engine"
            id="demo-simple-select"
            value={dbType}
            onChange={(e) =>
              handleChangeDbType(e.target.value as SqlQueryDbType)
            }
            sx={{
              "& legend": {
                maxWidth: "fit-content",
                // padding: '0 10px'
              },
              "& .MuiSvgIcon-root": {
                color: "text.disabled",
              },
            }}
          >
            <MenuItem value={SqlQueryDbType.NONE}>
              {t("editFlowsView.sqlQuery.none")}
            </MenuItem>
            <MenuItem value={SqlQueryDbType.MSSQL}>
              {t("editFlowsView.sqlQuery.mssql")}
            </MenuItem>
            <MenuItem value={SqlQueryDbType.MYSQL}>
              {t("editFlowsView.sqlQuery.mysql")}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SqlEngine;
