import { useState, useEffect, ReactNode } from 'react';
import { SelectChangeEvent } from '@mui/material';

export interface UseSelect {
  actions: {
    resetValue: () => void;
    changeValue: (newValue: string | number) => void;
  };
  attributes: {
    value: string | number;
    onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  };
}

const useSelect = (initialValue: string | number): UseSelect => {
  const [value, setValue] = useState<string | number>(initialValue);
  const onChange = (event) => {
    setValue(event.target.value);
  };

  const changeValue = (newValue: string | number) => {
    setValue(newValue);
  };

  const resetValue = () => {
    setValue(initialValue || '');
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    actions: { resetValue, changeValue },
    attributes: { value, onChange },
  };
};

export default useSelect;
