import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField } from '@mui/material';

const ContactNameFilter = () => {
  const { t } = useTranslation();

  const [contactName, setContactName] = useState<string>('');

  const { setSelectedContactName } = useContext(MyReportContext);

  const handleContactNameChange = (contactName: string) => {
    setContactName(contactName);
    setSelectedContactName(contactName);
  };

  return (
    <TextField
      size="small"
      fullWidth
      value={contactName}
      onChange={(e) => handleContactNameChange(e.target.value)}
      label={t('myReports.contactName')}
      variant="outlined"
      sx={{ marginBottom: 2 }}
    />
  );
};

export default ContactNameFilter;
