import { useCallback, useEffect, useState, useContext } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Components
import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
// Context
import { DashboardContext } from "../../../../context/DashboardContext";
// Slice
import {
  selectGroups,
  selectGroupsFetchStatus,
} from "redux/features/conversationsSlice/conversationsSlice";
// Types
import { IGroup } from "@trii/types/dist/Conversations/Groups/Group";

const GroupSelector = () => {
  const {
    currentGroup,
    setCurrentGroup,
    getGroups,
    getAllDataDashboard,
    setUserDashboard,
  } = useContext(DashboardContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const groups = useSelector(selectGroups);
  const groupsStatus = useSelector(selectGroupsFetchStatus);
  const [listToRender, setListToRender] = useState<IGroup[]>([]);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSelectGroup = (event: SelectChangeEvent<string>) => {
    const selectedGroupId = event.target.value;
    const selectedGroup = groups.find((group) => group.id === selectedGroupId);
    if (selectedGroup) {
      setCurrentGroup(selectedGroup);
      localStorage.setItem("group", selectedGroupId);
      getAllDataDashboard(selectedGroupId);
    }
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    },
    []
  );

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    setUserDashboard((prev) => ({
      ...prev,
      groupId: currentGroup?.id,
    }))
  }, [currentGroup])

  useEffect(() => {
    const savedGroupId = localStorage.getItem("group");
    if (savedGroupId) {
      const group = groups.find((group) => group.id === savedGroupId);
      if (group) {
        setCurrentGroup(group);
      }
    }
    if (groups && groups.length > 0) {
      setListToRender(groups);
      if (!savedGroupId) {
        const { id } = groups[0]
        localStorage.setItem("group", id);
        const group = groups.find((group) => group.id === id);
        if (group) {
          setCurrentGroup(group);
        }
      }
    }
  }, [groups]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        mr: "10px",
      }}
    >
      <Chip
        label={currentGroup ? currentGroup.name : ""}
        variant="outlined"
        onClick={handleClick}
        sx={{ zIndex: 0, cursor: "pointer" }}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disablePortal={false}
        sx={{
          mt: "5px",
        }}
      >
        <Box
          sx={{
            p: 2,
            pt: 1,
            mt: 0,
            boxShadow: "rgb(0 0 0 / 25%) 0px 1px 6px 0px !important",
            width: 200,
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {/* title */}
            <Typography sx={{ color: "text.primary" }}>
              {t("global.group")}:
            </Typography>
            {/* select */}
            <FormControl size="small" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Periodo"
                value={localStorage.getItem("group")}
                onChange={handleSelectGroup}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                {groupsStatus === "succeeded" &&
                  listToRender.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default GroupSelector;
