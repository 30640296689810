import useField from 'hooks/useField';
// Types
import { UseCreateSip } from './types';
import { NewSip } from 'redux/features/sipSlice/types/NewSip';

const useCreateSip = (): UseCreateSip => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewSip = (): NewSip => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewSip,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateSip;
