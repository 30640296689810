import React, { useState, useContext, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
// Icons
import { Add } from '@mui/icons-material';
// Components
import { Button } from './components';

type ButtonComponent = {
  id: string;
  button: React.ReactNode;
};

const ButtonList = () => {
  const { selectNode } = useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [button, setButton] = useState<ButtonComponent[]>([]);

  useEffect(() => {
    if (selectNode?.data?.msgText?.message?.buttons?.length) {
      const newButton = selectNode?.data?.msgText?.message.buttons.map((button) => {
        return {
          id: button.id,
          button: (
            <Button
              handleDelete={() => handleDeleteButton(button.id)}
              id={button.id}
            />
          ),
        };
      });
      setButton(newButton);
    } else {
      setButton([]);
    }
  }, [selectNode]);

  const handleDeleteButton = (id: string) => {
    setButton((prevButton) => {
      const newButton = prevButton?.filter((button) => button.id !== id);
      return newButton;
    });
  };

  const handleAddButton = () => {
    const id = Math.random().toString(36).substr(2, 9);
    const newButton = {
      id: id,
      button: <Button handleDelete={() => handleDeleteButton(id)} id={id}  />,
    };
    setButton([...button, newButton]);
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{marginTop: "10px"}}>
        <Typography variant="subtitle2" fontWeight={'bold'} color={"text.primary"}>
          {t('editFlowsView.text.manualMessage.addButton')}
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={() => handleAddButton()}
        >
          <Add />
        </IconButton>
      </Box>
      <TableContainer sx={{ maxHeight: '10rem',  "& table thead tr th": {
            zIndex: "2",
          }  }}>
        {button.length ? (
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t('editFlowsView.text.manualMessage.buttonType')}
                </TableCell>
                <TableCell align="center">
                  {t('editFlowsView.text.manualMessage.buttonText')}
                </TableCell>
              </TableRow>
            </TableHead>
            {button.map((button) => (
              <TableBody key={button.id}>{button.button}</TableBody>
            ))}
          </Table>
        ) : (
          <></>
        )}
      </TableContainer>
    </>
  );
};

export default ButtonList;
