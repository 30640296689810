import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button } from '@mui/material';

interface Props {
  handleClose: () => void;
  isLoading: boolean;
}

const EndButton = ({ handleClose, isLoading }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        pb: 6,
        justifyContent: 'center',
      }}
    >
      <Button
        variant="contained"
        disabled={isLoading}
        size="small"
        onClick={handleClose}
      >
        {t('global.close')}
      </Button>
    </Box>
  );
};

export default EndButton;
