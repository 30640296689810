// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
// Components
import { Labels } from "components";
// Types
import { IConversation } from "@trii/types/dist/Conversations";

interface TableProps {
  logs: IConversation[];
}

const Table = ({
  logs
}: TableProps) => {
  const { t } = useTranslation()
  return (
    <MuiTable
      sx={{
        width: '100%',
      }}
    >
      <TableHead
        sx={{
          display: 'table-header-group',
          width: '100%',
          backgroundColor: theme => theme.palette.background.default,
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <TableRow>
          <TableCell>
            {t("dashboard.records.chatName")}
          </TableCell>
          <TableCell>
            {t("dashboard.records.channelName")}
          </TableCell>
          <TableCell>
            {t("dashboard.records.remoteAddress")}
          </TableCell>
          <TableCell>
            {t("dashboard.records.assignedTo")}
          </TableCell>
          <TableCell>
            {t("dashboard.records.labels")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody  sx={{
          backgroundColor: theme => theme.palette.background.default,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
        }}>
        {
          logs.map((log) => (
            <TableRow key={log.id}>
              <TableCell>
                {log?.chatName}
              </TableCell>
              <TableCell>
                {log?.channelInfo?.name}
              </TableCell>
              <TableCell>
                {log?.remoteAddress}
              </TableCell>
              <TableCell>
                {log?.assignedTo?.userInfo?.name}
              </TableCell>
              <TableCell>
                <Labels labels={log?.labels} max={2} />
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </MuiTable>
  )
}

export default Table