import { useContext, useEffect, useState } from "react"
// Translations
import { useTranslation } from "react-i18next"
// Context
import { QuickResponseContext } from "features/Views/QuickResponse/context/QuickResponseContext"
import { Box, Typography } from "@mui/material"
import { Category, Response } from "./components"

const EditPanel = () => {
  const { t } = useTranslation()
  const {
    editionType,
    categorySelected,
    quickResponseSelected,
  } = useContext(QuickResponseContext)
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    if (editionType === 'category') {
      if (categorySelected) {
        return setTitle(t('quickResponse.categories.edit'))
      }
      return setTitle(t('quickResponse.categories.add'))
    } else if (editionType === 'quickResponse') {
      if (quickResponseSelected) {
        return setTitle(t('quickResponse.response.edit'))
      }
      return setTitle(t('quickResponse.response.create'))
    }
    return setTitle('')
  }, [editionType, categorySelected])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'inherit',
        justifyContent: 'flex-start',
        alignItems: 'start',
        gap: 1,
        p: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: (theme) => theme.palette.text.primary,
          height: '2rem',
        }}
        fontWeight="bold"
      >
        {title?.toUpperCase()}
      </Typography>
      {
        editionType === 'category' && (
          <Category />
        )
      }
      {
        editionType === 'quickResponse' && (
          <Response />
        )
      }
      {
        !editionType && (
          <Typography
            variant="h6"
            sx={{
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              color: (theme) => theme.palette.text.primary,
            }}
            fontWeight="bold"
          >
            {t('quickResponse.select')}
          </Typography>
        )
      }
    </Box>
  )
}

export default EditPanel