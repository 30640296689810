import { useState, useContext, useEffect } from 'react';
// Utils
import { darken } from 'polished';
import moment from 'moment';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import { Box } from '@mui/material';
import {
  CommentMessage,
  MessageActions,
  MessageStatus,
  Reactions,
} from './components';
import { ReplyMessage } from 'components';
import { Star } from '@mui/icons-material';
// Redux
import {
  getUser,
  selectUser,
  selectUsers,
} from 'redux/features/userSlice/userSlice';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
// Types
import {
  CommentData,
  IMessage,
  MessageDirection,
  MessageImage,
  MessageVideo,
} from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';
import MediaFile from 'features/Views/Conversations/context/MessagesProvider/types/MediaFile';

// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
// DB
import db from 'db/db';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { UserTrii } from '@trii/types/dist/Users';
import Cdr from './components/Cdr';

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;
const FORMAT_DATE = 'DD/MM/YYYY HH:mm';

type TextProps = {
  message: IMessage;
};

const CallType = ({ message }: TextProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const conversationSelected = useSelector(selectConversationSelected);
  const users = useSelector(selectUsers);

  const { getImage } = useContext(conversationsContext);
  const { highligthedMessageId } =
    useContext(messagesContext);

  const { getUserInfo } = useUserInfo();

  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [over, setOver] = useState<boolean>(false);
  const [showReply, setShowReply] = useState<boolean>(true);
  const [userInformation, setUserInformation] = useState<string>('');
  const [newComment, setNewComment] = useState<CommentData>(null);
  const [commentName, setCommentName] = useState<string>('');

  const {
    direction,

    timestamp,

    userId,
    id,

    comment,

    reactions,

    from,
    channelInfo,

    cdr
  } = message;

  const dateHour = moment(timestamp).format('HH:mm');
  
  const handleOver = () => {
    setOver(true);
  };

  const handleOut = () => {
    setOver(false);
  };

  const getUserInformation = async (id: string) => {
    const internalUser = users.find((user) => user.id === from);

    if (internalUser) {
      console.log('internalUser', internalUser);
      return internalUser.name;
    }

    if (user) {
      if (user.uid === id) {
        return t('conversations.message.your');
      }
    }

    const newUser = await getUserInfo(id);

    return newUser?.name;
  };

  const setUser = async () => {
    const name = await getUserInformation(userId);
    setUserInformation(name);
  };

  const setCommentUser = async () => {
    const name = await getUserInformation(comment.userId);
    setCommentName(name);
  };


  useEffect(() => {
    if (userId) {
      setUser();
    }
  }, [userId]);

  useEffect(() => {
    if (comment) {
      setCommentUser();
    }
  }, [comment]);

  useEffect(() => {
      const { icon: newIcon } = getImage(message.channelInfo?.type);
      setIcon(newIcon);
  }, []);

  useEffect(() => {
    if (comment) {
      setNewComment(comment);
    }
  }, [comment]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:
          direction === MessageDirection.OUT ? "flex-end" : "flex-start",
        width: "100%",
      }}
      onMouseOver={handleOver}
      onMouseOut={handleOut}
    >
      <Box
        sx={(theme) => ({
          backgroundColor:
            highligthedMessageId === id
              ? theme.palette.mode === "light"
                ? darken(0.1, "darkgrey")
                : darken(0.1, "lightgrey")
              : direction === MessageDirection.IN
              ? theme.palette.mode === "light"
                ? theme.palette.background.default
                : //@ts-ignore
                  theme.palette.background.dropdownMenu
              : //@ts-ignore
                theme.palette.messageBox,
          transition: "background-color 0.15s ease",
          backgroundImage:
            direction === MessageDirection.IN &&
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0))",
          float: direction === MessageDirection.IN ? "left" : "right",
          marginLeft: direction === MessageDirection.IN ? "5px" : "20px",
          marginRight: direction === MessageDirection.IN ? "20px" : "5px",
          marginBottom: reactions && reactions.length > 0 ? "20px" : "0px",
          borderRadius: `5px 5px ${
            direction === MessageDirection.IN ? "5px 0px" : "0px 5px"
          }`,
          border: "1px solid",
          borderColor:
            message.conversationId === conversationSelected?.id
              ? //@ts-ignore
                (theme) => theme.palette.warning.light
              : "transparent",
          maxWidth: "80%",
          height: "100%",
          padding: "0rem .5rem",
          paddingTop: ".3rem",

          minWidth: "11rem",
          position: "relative",
        })}
      >
        {/* USER INFO */}
        <Box
          sx={{
            display: "flex",
            fontSize: "11px",
            color: (theme) => theme.palette.text.primary,
            justifyContent: "space-between",
            // mt: 1,
          }}
        >
          <Box display="flex" alignItems="center" mb={0.5}>
            <Box
              mr={0.5}
              sx={{
                "& svg": {
                  fontSize: "16px",
                },
              }}
            >
              {icon}
            </Box>
            <Box sx={{ height: "20px", mr: "40px", opacity: 0.5 }}>
              {userInformation}
            </Box>
          </Box>
          <Box>
            <MessageActions
              over={over}
              setOver={setOver}
              message={message}
              showReply={showReply}
            />
          </Box>
          {/* <MessageReactionsSelector
            shardKey={shardKey}
            spaceId={spaceId}
            msgId={id}
          />
          <MessageReactionsTool messageId={id} over={over} direction={direction} /> */}
        </Box>      
        {cdr && (
          <Cdr cdr={cdr}/>
        )}
        {/* END MESSAGE BODY */}
        {comment && (
          <CommentMessage
            message={message}
            commentName={commentName}
            comment={newComment}
          />
        )}
        {/* CHANNEL INFO */}
        <Box
          sx={{
            display: "flex",
            fontSize: "11px",
            color: (theme) => theme.palette.text.primary,
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Box mr={3}>
            <Box>{channelInfo?.name}</Box>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Box>{dateHour}</Box>
            {direction === MessageDirection.OUT && (
              <MessageStatus message={message} />
            )}
            {message.isHighlighted && (
              <Star
                sx={{
                  color: (theme) => theme.palette.primary.dark,
                  fontSize: 15,
                }}
              />
            )}
          </Box>
        </Box>
        {reactions && reactions.length > 0 && (
          <Reactions data={reactions} direction={direction} />
        )}

        {/* END CHANNEL INFO */}
      </Box>
    </Box>
  );
};

export default CallType;
