import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectWebHooksFetchStatus,
  selectAllWebHooks,
} from 'redux/features/webHooksSlice/webHooksSlice';
// Components/ui
import { Box } from '@mui/material';
import { WebHookItemSkeleton, WebHookItem } from './components';

const ListBody = () => {
  const allWebHooks = useSelector(selectAllWebHooks);
  const webHooksFetchStatus: RequestStatus = useSelector(selectWebHooksFetchStatus);

  const WebHooks = allWebHooks.map((webHook) => (
    <WebHookItem key={webHook.id} data={webHook} />
  ));

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {webHooksFetchStatus === 'succeeded' ? WebHooks : <WebHookItemSkeleton />}
    </Box>
  );
};

export default ListBody;
