import React, { createContext, useEffect, useState } from 'react';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
//Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createGroup,
  selectGroupById,
  deleteGroup,
  removeSelectedGroup,
  editGroup,
} from 'redux/features/groupSlice/groupSlice';
// Types
import { GroupContext, GroupProviderProps } from './types';
import { UseFieldType } from 'hooks/useField';
import { Group } from 'redux/features/groupSlice/types';
import { UserInfo } from '@trii/types/dist/Users';

export const InitGroupContext = createContext<GroupContext>({
  deletePanelOpen: false,
  editPanelOpen: false,
  createPanelOpen: false,
  createGroupNameField: fieldInitialDef,
  createGroupDescriptionField: fieldInitialDef,
  createGroupUsers: [],
  setCreateGroupUsers: () => {},
  deleteGroupNameField: fieldInitialDef,
  editGroupDescriptionField: fieldInitialDef,
  editGroupNameField: fieldInitialDef,
  editGroupUsers: [],
  setEditGroupUsers: () => {},
  setDeletePanelOpen: () => {},
  setCreatePanelOpen: () => {},
  openCreatePanel: () => {},
  closeCreatePanel: () => {},
  resetCreateFields: () => {},
  sendGroup: () => {},
  openDeletePanel: () => {},
  closeDeletePanel: () => {},
  sendDeleteGroup: () => {},
  closeEditPanel: () => {},
  setEditPanelOpen: () => {},
  openEditPanel: () => {},
  sendEditedGroup: () => {},
  errorMessage: '',
  errorChannels: [],
  snackbarOpen: false,
  setSnackbarOpen: () => {},
  handleSnackbarClose: () => {},
});
interface DeleteGroupErrorPayload {
  message: string;
  channels: string[];
}

export const GroupProvider = ({ children }: GroupProviderProps) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // Create state
  const [createPanelOpen, setCreatePanelOpen] = useState<boolean>(false);
  const createGroupNameField: UseFieldType = useField('text');
  const createGroupDescriptionField: UseFieldType = useField('text');
  const [createGroupUsers, setCreateGroupUsers] = useState<UserInfo[]>([]);
  // Delete state
  const [deletePanelOpen, setDeletePanelOpen] = useState<boolean>(false);
  const deleteGroupNameField: UseFieldType = useField('text');
    // Estado para el mensaje de error y los canales
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [errorChannels, setErrorChannels] = useState<string[]>([]);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  // Edit state
  const [editPanelOpen, setEditPanelOpen] = useState<boolean>(false);
  const editGroupNameField: UseFieldType = useField('text');
  const editGroupDescriptionField: UseFieldType = useField('text');
  const [editGroupUsers, setEditGroupUsers] = useState<UserInfo[]>([]);

  // Create functions
  const openCreatePanel = () => {
    closeDeletePanel();
    setCreatePanelOpen(true);
  };
  const closeCreatePanel = () => {
    resetCreateFields();
    setCreatePanelOpen(false);
  };
  const resetCreateFields = () => {
    createGroupNameField.actions.resetValue();
    createGroupDescriptionField.actions.resetValue();
  };
  const sendGroup = async () => {
    await dispatch(
      createGroup({
        name: createGroupNameField.attributes.value,
        description: createGroupDescriptionField.attributes.value,
        users: createGroupUsers,
      })
    );
    closeCreatePanel();
  };

  // Delete functions
  const openDeletePanel = (groupId: string) => {
    dispatch(selectGroupById(groupId));
    closeCreatePanel();
    setDeletePanelOpen(true);
  };
  const closeDeletePanel = () => {
    deleteGroupNameField.actions.resetValue();
    dispatch(removeSelectedGroup());
    setDeletePanelOpen(false);
  };
    // Función para cerrar el Snackbar
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
    // Actualizar el Snackbar cuando se produce un error
    useEffect(() => {
      if (errorMessage) {
        setSnackbarOpen(true);
      }
    }, [errorMessage]);
    const sendDeleteGroup = async (groupId: string) => {
      const resultAction = await dispatch(deleteGroup(groupId));
    
      // Verificamos si el resultado es rechazado y si el payload existe
      if (deleteGroup.rejected.match(resultAction) && resultAction.payload) {
        // Aseguramos que el payload tiene el tipo correcto
        const payload = resultAction.payload as DeleteGroupErrorPayload;
        const { message, channels } = payload;
        setErrorMessage(message);
        setErrorChannels(channels);
      } else {
        setErrorMessage(null);
        setErrorChannels([]);
      }
    
      closeDeletePanel();
    };
    
  
  // Edit functions
  const resetEditFields = () => {
    editGroupNameField.actions.resetValue();
    editGroupDescriptionField.actions.resetValue();
    setEditGroupUsers([]);
  };
  const openEditPanel = (group: Group) => {
    editGroupNameField.actions.changeValue(group.name);
    editGroupDescriptionField.actions.changeValue(group.description);
    setEditGroupUsers(group.users);
    closeCreatePanel();
    closeDeletePanel();
    dispatch(selectGroupById(group.id));
    setEditPanelOpen(true);
  };
  const closeEditPanel = () => {
    resetEditFields();
    dispatch(removeSelectedGroup());
    setEditPanelOpen(false);
  };
  const sendEditedGroup = async (groupId: string) => {
    const editedGroup = {
      name: editGroupNameField.attributes.value,
      description: editGroupDescriptionField.attributes.value,
      users: editGroupUsers,
    };
    await dispatch(editGroup({ groupId, editedGroup }));
    closeEditPanel();
  };

  return (
    <InitGroupContext.Provider
      value={{
        sendEditedGroup,
        openEditPanel,
        editPanelOpen,
        setEditPanelOpen,
        closeEditPanel,
        sendDeleteGroup,
        closeDeletePanel,
        deleteGroupNameField,
        openDeletePanel,
        deletePanelOpen,
        setDeletePanelOpen,
        createGroupNameField,
        createGroupDescriptionField,
        createGroupUsers,
        setCreateGroupUsers,
        resetCreateFields,
        sendGroup,
        createPanelOpen,
        setCreatePanelOpen,
        openCreatePanel,
        closeCreatePanel,
        editGroupNameField,
        editGroupDescriptionField,
        editGroupUsers,
        setEditGroupUsers,
        errorMessage,
        errorChannels,
        snackbarOpen,
        setSnackbarOpen,
        handleSnackbarClose,
      }}
    >
      {children}
    </InitGroupContext.Provider>
  );
};

export default GroupProvider;
