import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { selectGroupData } from 'redux/features/groupSlice/groupSlice';
// Components
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  reassignGroupId: string;
  disabled: boolean;
  changeReassignGroupAction: (value: string) => void;
}

const ChannelGroupSelect = ({
  reassignGroupId,
  disabled,
  changeReassignGroupAction,
}: Props) => {
  reassignGroupId = reassignGroupId || '';
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupData = useSelector(selectGroupData);

  const handleGroupChange = (e: SelectChangeEvent<string>) => {
    // @ts-ignore
    dispatch(changeReassignGroupAction(e.target.value));
  };

  const MenuItems = groupData.map((group) => (
    <MenuItem key={group.id} value={group.id}>
      {group.name}
    </MenuItem>
  ));

  return (
    <FormControl
      disabled={disabled}
      sx={{ mb: 1.5, mt: 0, ml: 1.5, width: "55%" }}
    >
      <Select
        value={reassignGroupId}
        size="small"
        onChange={handleGroupChange}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: (theme) => theme.palette.background.panel,
            },
          },
        }}
      >
        {MenuItems}
      </Select>
      <FormHelperText>
        {t("channelEditView.detailsPanel.reasignationTimeHelper1")}
        <a href="">
          {t("channelEditView.detailsPanel.reasignationTimeHelper2")}
        </a>
      </FormHelperText>
    </FormControl>
  );
};

export default ChannelGroupSelect;
