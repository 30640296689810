import React, { useContext, useEffect } from 'react';
import { webChatContext } from 'features/Views/WebChat/context/WebChatProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { CreateButtons } from './components';
import { CrudContainer } from 'components';
import { PanelHeader } from 'components';
import CreateInputs from './components/CreateInputs';

const CreatePanel = () => {
  const { endCreating, createWebChatState } = useContext(webChatContext);
  const { t } = useTranslation();

  useEffect(() => {
    const cleanUp = () => {
      createWebChatState.actions.resetAll();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('webChatsView.addWebChat')}
        handleCloseButton={endCreating}
      />
      <Divider />
      <CreateInputs createWebChatState={createWebChatState} />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <CreateButtons />
    </CrudContainer>
  );
};

export default CreatePanel;
