import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateGoogleBusiness from '../hooks/useCreateGoogleBusiness/useCreateGoogleBusiness';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createGoogleBusinessInitialState from '../hooks/useCreateGoogleBusiness/createGoogleBusinessInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createGoogleBusiness,
  deleteGoogleBusiness,
} from 'redux/features/googleBusinessSlice/googleBusinessSlice';
// Types
import type { CrudPanelState } from './types';
import { GoogleBusinessContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const googleBusinessContext = createContext<GoogleBusinessContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateGoogleBusiness: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedGoogleBusiness: null,
  deleteGoogleBusinessName: fieldInitialDef,
  createGoogleBusinessState: createGoogleBusinessInitialState,
  handleDeleteGoogleBusiness: () => {},
  navigateToEdit: () => {},
});

const GoogleBusinessProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedGoogleBusiness, setSelectedGoogleBusiness] = useState<any>(null);
  // Create state
  const createGoogleBusinessState = useCreateGoogleBusiness();
  // Delete state
  const deleteGoogleBusinessName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateGoogleBusiness = async () => {
    const newGoogleBusiness =
      createGoogleBusinessState.actions.getNewGoogleBusiness();
    await dispatch(createGoogleBusiness(newGoogleBusiness));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createGoogleBusinessState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteGoogleBusiness = async () => {
    const { id } = selectedGoogleBusiness;

    await dispatch(deleteGoogleBusiness(id));
    endDeleting();
  };

  const openDelete = (googleBusiness: any) => {
    setSelectedGoogleBusiness(googleBusiness);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteGoogleBusinessName.actions.resetValue();
    setSelectedGoogleBusiness(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (googleBusinessId: string) => {
    navigate(`/a/conversations/system/googlebusiness/${googleBusinessId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <googleBusinessContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateGoogleBusiness,
        openDelete,
        endDeleting,
        deleteGoogleBusinessName,
        createGoogleBusinessState,
        selectedGoogleBusiness,
        handleDeleteGoogleBusiness,
        navigateToEdit,
      }}
    >
      {children}
    </googleBusinessContext.Provider>
  );
};

export default GoogleBusinessProvider;
