import React, { useEffect, useCallback, useState, useContext } from 'react';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
//components/ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

//Select
import { useTheme } from '@mui/material/styles';

//Popover
import Popover from '@mui/material/Popover';
//RangePicker
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataContext } from 'context/contextApi';
// Redux
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';

toast.configure();
const DateRangePickerComponent = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { isMobile, showPageSideBar } = useContext(DataContext);
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const theme = useTheme();
  const [openPopover, setOpenPopover] = useState(null); //Popover state
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [date, setDate] = useState([
    //RangePicker state
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ]);
  const [selectedDate, setSelectedDate] = useState({
    //Selected date state
    initDate: moment().startOf('month').format('L'),
    endDate: moment().endOf('month').format('L'),
  });
  const id = openPopover ? 'simple-popover' : undefined; //Popover id
  //style
  const mobileStyle = {
    width: isMobile ? '100%' : '',
    flexColumn: isMobile ? 'column' : 'row',
    padding: isMobile ? '10px' : '',
    paddingLeft0: isMobile ? 'pl-0' : '',
    minWidth: isMobile ? 'min-width: calc(100vw - 108px)' : '',
  };
  //style

  //popoverRangePicker
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  //popoverRangePicker

  //date range picker
  const selectRangeDate = useCallback(
    (item) => {
      const fechaInicio = moment(item.selection.startDate).format('L');
      const fechaFin = moment(item.selection.endDate).format('L');
      // const unixInitDate = moment(item.selection.startDate).unix();
      // const unixEndDate = moment(item.selection.endDate).unix();

      // dispatch(setLogDateFilter({ initDate: unixInitDate, endDate: unixEndDate }));
      setDate([item.selection]);

      fechaInicio !== fechaFin && handleClosePopover();
      fechaInicio === selectedDate.initDate && handleClosePopover();

      setSelectedDate({
        ...selectedDate,
        initDate: fechaInicio,
        endDate: fechaFin,
      });
    },
    [selectedDate, dispatch]
  );

  useEffect(() => {
    const initDate = moment().startOf('month').unix();
    const endDate = moment().endOf('month').unix();
    const days = { initDate: initDate, endDate: endDate };

    // dispatch(setLogDateFilter(days));
    // dispatch(fetchLogs());
  }, [dispatch]);

  return (
    <Box>
      <Box
        className="boxOutlined"
        sx={{
          minWidth: !isMd
            ? showPageSideBar
              ? 'calc(100vw - 150px)'
              : 'calc(100vw - 108px)'
            : '',
          borderColor: 'divider',
        }}
        onClick={handleOpenPopover}
      >
        <Typography
          component={'span'}
          sx={{ marginRight: 3, cursor: 'default' }}
          color={'text.primary'}
        >
          {selectedDate.initDate} - {selectedDate.endDate}
        </Typography>

        <DateRangeIcon
          sx={{ cursor: 'pointer', color: 'text.primary' }}
        ></DateRangeIcon>
      </Box>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          onChange={selectRangeDate}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={date}
          direction="horizontal"
          // style={{background: "red"}}
        />
      </Popover>
    </Box>
  );
};

export default DateRangePickerComponent;
