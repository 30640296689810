import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { EmailConfigOutboundSecurityType } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  selectEmail,
  changeOutboundSmtpServer,
  changeOutboundSmtpUser,
  changeOutboundSmtpPassword,
  changeOutboundSmtpPort,
  changeOutboundSmtpSecurityType,
  testSMTP,
} from 'redux/features/emailEditSlice/emailEditSlice';
// Components/ui
import {
  Box,
  TextField,
  Select,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';

const SmtpInputs = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const email = useSelector(selectEmail);
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleOutboundSmtpServerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    dispatch(changeOutboundSmtpServer(value));
  };
  
  const handleOutboundSmtpUserChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    dispatch(changeOutboundSmtpUser(value));
  };
  
  const handleOutboundSmtpPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    dispatch(changeOutboundSmtpPassword(value));
  };
  
  const handleOutboundSmtpPortChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(event.target.value);
    dispatch(changeOutboundSmtpPort(value));
  };
  
  const handleOutboundSmtpSecurityTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as unknown as EmailConfigOutboundSecurityType;
    dispatch(changeOutboundSmtpSecurityType(value));
  };

  useEffect(() => {
    setShow(true);
  }, []);

  const testConnection = async () => {
    const smtpData = {
      outboundSmtSecurityType: email.email.outboundSmtSecurityType,
      outboundSmtpServer: email.email.outboundSmtpServer,
      outboundSmtpPort: email.email.outboundSmtpPort,
      outboundSmtpUser: email.email.outboundSmtpUser,
      outboundSmtPasword: email.email.outboundSmtPasword,
    };
  
    try {
      const response = await dispatch(testSMTP({ channelId: id, smtpData }));
      
      // Assuming you check response to detect if it's successful or failed
      if (response.meta.requestStatus === 'fulfilled') {
        setSnackbarMessage(t("emailEditView.testConnection.testSuccess"));
        setSnackbarSeverity('success');
      } else if (response.meta.requestStatus === 'rejected') {
        //@ts-ignore
        setSnackbarMessage(t("emailEditView.testConnection.testFailed") + response.error.message);
        setSnackbarSeverity('error');

      }
    } catch (error) {
      console.error('SMTP Test failed:', error);
    } finally {
      setOpen(true);
    }
  };

  const openSetBySepInstruction = () => {
    window.open('https://www.google.com.ar/', '_blank');
  };

  return (
    <Fade in={show}>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <TextField
          size="small"
          label={t("emailEditView.outgoingEmailPanel.outgoingServer")}
          onChange={handleOutboundSmtpServerChange}
          value={email.email.outboundSmtpServer}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          size="small"
          label={t("global.username")}
          onChange={handleOutboundSmtpUserChange}
          value={email.email.outboundSmtpUser}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          size="small"
          label={t("global.password")}
          type="password"
          onChange={handleOutboundSmtpPasswordChange}
          value={email.email.outboundSmtPasword}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box display={"flex"} gap={2}>
          <TextField
            sx={{ width: "50%" }}
            size="small"
            label={t("global.port")}
            onChange={handleOutboundSmtpPortChange}
            InputLabelProps={{
              shrink: true,
            }}
            value={email.email.outboundSmtpPort}
          />
          <FormControl fullWidth>
            <InputLabel id="securitySelect">{t("global.security")}</InputLabel>
            <Select
              labelId="securitySelect"
              size="small"
              label={t("global.security")}
              value={email.email.outboundSmtSecurityType}
              onChange={handleOutboundSmtpSecurityTypeChange}
            >
              <MenuItem value={EmailConfigOutboundSecurityType.NONE}>
                {t("global.none")}
              </MenuItem>
              <MenuItem value={EmailConfigOutboundSecurityType.SSL}>
                SSL
              </MenuItem>
              <MenuItem value={EmailConfigOutboundSecurityType.TLS}>
                TLS
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display={"flex"} gap={2} justifyContent={"space-between"}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={testConnection}
          >
            {t("emailEditView.outgoingEmailPanel.testConnection")}
          </Button>
          <Button size="small" variant="text" onClick={openSetBySepInstruction}>
            {t("emailEditView.outgoingEmailPanel.stepByStepInstructions")}
          </Button>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={snackbarSeverity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Fade>
  );
};

export default SmtpInputs;
