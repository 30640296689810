import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, TextField } from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
import { StyleContext } from 'style/styleProvider';

const Variables = () => {
  const { apiNode, setApiNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [variables, setVariables] = useState<string>('');
  const { fontWeight } = useContext(StyleContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.api) {
      const api = selectNode.data.api;
      const defaultVariable = api.variables && (api.variables as string);
      setVariables(defaultVariable);
    }
  }, [selectNode]);

  const handleChangeVariable = (variables: string) => {
    setVariables(variables);
    setApiNode({ ...apiNode, variables });
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        // color={(theme) => theme.palette.text.disabled}
        fontWeight={fontWeight.bold}
        color="text.primary"
      >
        {t('editFlowsView.api.variables.title')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={variables}
        onChange={(e) => handleChangeVariable(e.target.value)}
        multiline
        rows={4}
        helperText={t('editFlowsView.api.variables.helperText')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          "& .MuiFormHelperText-root": {
            variant: "caption",
            fontStyle: "italic",
            opacity: 0.7,
          },
        }}
      />
    </>
  );
};

export default Variables;
