import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeWindowHeight } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import DesignSection from '../DesignSection';
import OptionsContainer from '../OptionsContainer';
import AddIcon from '@mui/icons-material/Add';
import { TextField, Box, Typography } from '@mui/material';

interface Props {
  advanceMarginTop: number;
}

const AdvancedOptionsSection = ({ advanceMarginTop }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleWindowHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeWindowHeight(Number(event.target.value)));
  };

  return (
    <DesignSection
      icon={
        <AddIcon
          sx={{
            fontSize: 17,
            color: 'primary.main',
          }}
        />
      }
      title={t('webChatEditView.designPanel.advancedOptionsSection.title')}
    >
      <OptionsContainer
        title={t(
          'webChatEditView.designPanel.advancedOptionsSection.heightOfWindow'
        )}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          width={'100%'}
          alignItems={'center'}
        >
          <Typography variant="subtitle1">
            {t(
              'webChatEditView.designPanel.advancedOptionsSection.reduceHeightOfWindow'
            )}
          </Typography>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <TextField
              size={'small'}
              variant={'outlined'}
              type={'number'}
              sx={{
                width: '100px',
              }}
              value={advanceMarginTop}
              onChange={handleWindowHeightChange}
            />
            <Typography variant="subtitle1">"px"</Typography>
          </Box>
        </Box>
      </OptionsContainer>
    </DesignSection>
  );
};

export default AdvancedOptionsSection;
