import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseFieldType } from 'hooks/useField';
// Components/ui
import { Box, Card, TextField, Typography } from '@mui/material';

interface GeneralSectionProps {
  nameFieldState: UseFieldType;
}

function GeneralSection({ nameFieldState }: GeneralSectionProps) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        margin: 2,
      }}
    >
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
        py={1}
        px={2}
        sx={{
          //@ts-ignore
          backgroundColor: (color) => color.palette.background.panelHeader,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: (color) => color.palette.text.primary }}
        >
          General
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={"start"}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
        }}
        p={2}
      >
        <TextField
          variant="outlined"
          label={t("global.name")}
          size="small"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          {...nameFieldState.attributes}
          
        />
      </Box>
    </Card>
  );
}

export default GeneralSection;
