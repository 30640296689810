import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
// Redux
import { useSelector } from 'react-redux';
import { selectEmailVerifyEmailStatus } from 'redux/features/emailEditSlice/emailEditSlice';

interface Props {
  handleClose: () => void;
  handleBack: () => void;
  handleNext: () => void;
  activeStep: number;
  emailInputIsEmail: boolean;
}

const Buttons = ({
  handleClose,
  handleBack,
  activeStep,
  handleNext,
  emailInputIsEmail,
}: Props) => {
  const { t } = useTranslation();

  const emailVerifyingStatus = useSelector(selectEmailVerifyEmailStatus);
  const isVerifyLoading = emailVerifyingStatus === 'loading';
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        p: 3,
        alignItems: 'center',
        justifyContent: 'space-between',
        //@ts-ignore
        bgcolor: theme.palette.background.dropdownMenu,
        borderTop: '1px solid',
        borderColor: theme.palette.divider,
      }}
    >
      <Button size="small" color="inherit" onClick={handleClose} sx={{ mr: 1 }}>
        {t('emailEditView.incomingEmailPanel.setUpModal.cancelSetUp')}
      </Button>
      <Box>
        <Button
          size="small"
          color="inherit"
          onClick={handleBack}
          sx={{ mr: 1 }}
          variant="outlined"
          disabled={activeStep === 0}
        >
          {t('emailEditView.incomingEmailPanel.setUpModal.back')}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleNext}
          disabled={
            (activeStep === 0 && !emailInputIsEmail) ||
            (isVerifyLoading && activeStep === 1)
          }
        >
          {activeStep === 0 &&
            t('emailEditView.incomingEmailPanel.setUpModal.sendCode')}
          {activeStep === 1 &&
            (isVerifyLoading ? (
              <CircularProgress size={24} />
            ) : (
              t('emailEditView.incomingEmailPanel.setUpModal.confirm')
            ))}
          {activeStep === 2 && t('global.close')}
        </Button>
      </Box>
    </Box>
  );
};

export default Buttons;
