import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Components/ui
import { Box, Typography } from '@mui/material';
import { StateChip, StateChipSkeleton } from './components';

interface Props {
  mercadoLibreSyncStatus: Date;
}

function Status({ mercadoLibreSyncStatus }: Props) {
  // const Chip = mercadoLibreSyncStatus === 'loading' ? StateChipSkeleton : StateChip;
  const Chip = StateChip;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          mr: 1,
        }}
      >
        Status:
      </Typography>
      <Chip />
    </Box>
  );
}

export default Status;
