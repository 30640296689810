import { EmailConfig, EmailConfigOutboundConnectionType } from '@trii/types/dist/Common/Channels';
import { ChannelPanel, ChannelPanelInnerContainer } from 'components';
import React from 'react'
import { useTranslation } from 'react-i18next';
import IncomingEmailPanel from './components/IncomingEmailPanel/IncomingEmailPanel';
import OutgoingEmailPanel from './components/OutgoingEmailPanel/OutgoingEmailPanel';

interface Props {
  connectedEmail: EmailConfig;
  connectionType: EmailConfigOutboundConnectionType;
}

const ConnectedEmailAddress = ({ connectedEmail, connectionType }: Props) => {
  const {t} = useTranslation() 
  return (
    <ChannelPanel title={t('emailEditView.incomingEmailPanel.connectedEmailAddress')}>
        <ChannelPanelInnerContainer>
          <IncomingEmailPanel connectedEmail={connectedEmail}/>
          <OutgoingEmailPanel connectionType={connectionType} connectedEmail={connectedEmail}/>
        </ChannelPanelInnerContainer>
    </ChannelPanel>
  )
}

export default ConnectedEmailAddress