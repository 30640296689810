// Interface
import { NodeType, NodeData } from '@trii/types/dist/Conversations/Flows';

const getData = (type: NodeType, data: NodeData): NodeData => {
  switch (type) {
    case NodeType.START:
      return { start: { ...data.start } };
    case NodeType.MSG_TEXT:
      return { msgText: { ...data.msgText } };
    case NodeType.MSG_WAIT_RESPONSE:
      return { msgWaitResponse: { ...data.msgWaitResponse } };
    case NodeType.SQL_QUERY:
      return { sqlQuery: { ...data.sqlQuery } };
    case NodeType.SEND_EMAIL:
      return { sendEmail: { ...data.sendEmail } };
    case NodeType.CONVERSATION_ASSIGN_TO:
      return { conversationAssignTo: { ...data.conversationAssignTo } };
    case NodeType.FILL_FORM:
      return { fillForm: { ...data.fillForm } };
    case NodeType.REDIRECT:
      return { redirect: { ...data.redirect } };
    case NodeType.FILL_TICKET:
      return { fillTicket: { ...data.fillTicket } };
    case NodeType.CONTACT_SEARCH:
      return { contactSearch: { ...data.contactSearch } };
    case NodeType.CONTACT_UPDATE:
      return { contactUpdate: { ...data.contactUpdate } };
    case NodeType.CONVERSATION_UPDATE:
      return { conversationUpdate: { ...data.conversationUpdate } };
    case NodeType.CONDITIONAL:
      return { conditional: { ...data.conditional } };
    case NodeType.API:
      return { api: { ...data.api } };
    case NodeType.TIME_SCHEDULE_CONTROL:
      return { timeScheduleControl: { ...data.timeScheduleControl } };
    case NodeType.TIME_TIMER:
      return { timeTimer: { ...data.timeTimer } };
    case NodeType.TIME_WAIT_UNTIL:
      return { timeWaitUntil: { ...data.timeWaitUntil } };
    default:
      return { start: { ...data.start } };
  }
};

export default getData;
