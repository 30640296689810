import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateWebChat from '../hooks/useCreateWebChat/useCreateWebChat';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createWebChatInitialState from '../hooks/useCreateWebChat/createWebChatInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createWebChat,
  deleteWebChat,
} from 'redux/features/webChatSlice/webChatSlice';
// Types
import type { CrudPanelState } from './types';
import { WebChatContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const webChatContext = createContext<WebChatContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateWebChat: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedWebChat: null,
  deleteWebChatName: fieldInitialDef,
  createWebChatState: createWebChatInitialState,
  handleDeleteWebChat: () => {},
  navigateToEdit: () => {},
});

const WebChatProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedWebChat, setSelectedWebChat] = useState<any>(null);
  // Create state
  const createWebChatState = useCreateWebChat();
  // Delete state
  const deleteWebChatName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateWebChat = async () => {
    const newWebChat = createWebChatState.actions.getNewWebChat();
    await dispatch(createWebChat(newWebChat));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createWebChatState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteWebChat = async () => {
    const { id } = selectedWebChat;

    await dispatch(deleteWebChat(id));
    endDeleting();
  };

  const openDelete = (webChat: any) => {
    setSelectedWebChat(webChat);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteWebChatName.actions.resetValue();
    setSelectedWebChat(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (webChatId: string) => {
    navigate(`/a/conversations/system/webchats/${webChatId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <webChatContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateWebChat,
        openDelete,
        endDeleting,
        deleteWebChatName,
        createWebChatState,
        selectedWebChat,
        handleDeleteWebChat,
        navigateToEdit,
      }}
    >
      {children}
    </webChatContext.Provider>
  );
};

export default WebChatProvider;
