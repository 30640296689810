import { forwardRef, useEffect, useState, useContext, RefObject } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// components/ui
import { Box, CircularProgress } from '@mui/material';
import { AddFile, FileSection } from './components';
import { SectionCard } from '../../layout';
// Slice
import { selectUploadMedia } from 'redux/features/messagesSlice/messagesSlice';
import {
  selectFiles,
  selectFilesFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
// Types
import { IFile } from '@trii/types/dist/Contacts';
import { ContactProps } from '../../types/ContactProps';
// Contecxt
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { ContactFile } from 'redux/features/contactInfoSlice/types/ContactFile';

const ContactFiles = forwardRef(
  (props: ContactProps, ref: RefObject<HTMLElement>) => {
    const { t } = useTranslation();
    const { filesToUpload, setFilesToUpload, setNewFile, deleteFile } =
      useContext(conversationsContext);

    const newFile = useSelector(selectUploadMedia);
    const files = useSelector(selectFiles);
    const filesFetchStatus = useSelector(selectFilesFetchStatus);

    const [attachments, setAttachments] = useState<IFile[]>([]);

    const isLoading = filesFetchStatus === 'loading';

    const handleDelete = (id: string) => {
      const filterFiles = attachments.filter((file) => file.id !== id);
      deleteFile(id);
      setAttachments(filterFiles);
    };

    useEffect(() => {
      const handleAttachFile = async (file: ContactFile) => {
        const result = await setNewFile(file);
        function sleep(ms: number) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }
        await sleep(2000);
        return result;
      };


      const updateAttachments = async () => {
        const filesIds = attachments.map((file) => file.id);
        if (newFile && filesIds.includes(newFile.id)) {
          const newFiles = await Promise.all(
            attachments.map(async (file) => {
              if (file.id === newFile.id) {
                const data = {
                  contactId: file.contactId,
                  fileName: file.fileName,
                  mimeType: file.mimeType,
                  size: file.size,
                  url: newFile.url
                } as ContactFile;
                const fileUpload = await handleAttachFile(data);
                return {
                  ...file,
                  id: fileUpload.id,
                  url: newFile.url,
                };
              }
              return file;
            })
          );
          setAttachments(newFiles);
        }
      };


      updateAttachments();
      const filterFiles = filesToUpload.filter((file) => file.id !== newFile.id);

      setFilesToUpload(filterFiles);
    }, [newFile]);

    useEffect(() => {
      if (filesToUpload.length > 0) {
        const filterFiles = filesToUpload.filter(
          (file) => !attachments.map((f) => f.id).includes(file.id)
        );
        const newFiles = filterFiles.map((file) => file);

        setAttachments([...attachments, ...newFiles]);
      }
    }, [filesToUpload]);

    useEffect(() => {
      if (files) {
        setAttachments(files);
      }
    }, [files]);

    return (
      <SectionCard
        title={t('conversations.sidebar.files.title')}
        onClick={props.handleClick}
        tabName="contactFiles"
      >
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <AddFile />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '100%',
            }}
          >
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  margin: 'auto',
                }}
              />
            )}
            {attachments.map((file) => (
              <FileSection
                key={file.id}
                name={file.fileName}
                fileId={file.id}
                url={file.url}
                onDelete={handleDelete}
              />
            ))}
          </Box>
        </Box>
      </SectionCard>
    );
  }
);

export default ContactFiles;
