import useField from 'hooks/useField';
// Types
import { UseCreateWhatsApp } from './types';
import { NewWhatsApp } from 'redux/features/whatsAppSlice/types/NewWhatsApp';

const useCreateWhatsApp = (): UseCreateWhatsApp => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewWhatsApp = (): NewWhatsApp => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewWhatsApp,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateWhatsApp;
