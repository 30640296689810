import { NodeStartTrigger } from "@trii/types/dist/Conversations/Flows/Nodes"

export interface TriggerSelector {
    id: number,
    name: string,
}

const triggerSelector: TriggerSelector[] = [
    {
        id: NodeStartTrigger.NULL,
        name: 'editFlowsView.start.trigger.none'
    },
    {
        id: NodeStartTrigger.CONVERSATION_OPENED,
        name: 'editFlowsView.start.trigger.openConversation'
    },
    {
        id: NodeStartTrigger.CONVERSATION_CLOSED,
        name: 'editFlowsView.start.trigger.closeConversation'
    },
    {
        id: NodeStartTrigger.CONTACT_FIELD_UPDATED,
        name: "editFlowsView.start.trigger.updatedFieldContact"
    },
    {
        id: NodeStartTrigger.CONTACT_TAG_UPDATED,
        name: "editFlowsView.start.trigger.updatedLabelContact"
    },
    {
        id: NodeStartTrigger.CONTACT_FIELD_DATES,
        name: "editFlowsView.start.trigger.dateFieldsContact"
    },
    {
        id: NodeStartTrigger.CONTACT_EVENT_DATE,
        name: "editFlowsView.start.trigger.dateEventsContact"
    }
]

export default triggerSelector