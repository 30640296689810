import useField from 'hooks/useField';
import { useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { createEmail } from 'redux/features/emailSlice/emailSlice';
// Types
import { UseCreateEmail } from './types';
import { NewEmail } from 'redux/features/emailSlice/types/NewEmail';

const useCreateEmail = (): UseCreateEmail => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const [creationError, setCreationError] = useState<boolean>(false);
  const nameField = useField('text');
  const emailField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    setCreationError(false);
    nameField.actions.resetValue();
    emailField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewEmail = (): NewEmail => {
    return {
      name: nameField.attributes.value,
      emailAddress: `${emailField.attributes.value}@${window.location.hostname}`,
      description: descriptionField.attributes.value,
    };
  };
  const sendEmail = async () => {
    const newEmail = getNewEmail();
    const response = await dispatch(createEmail(newEmail));
    // @ts-ignore
    if (!response.payload) {
      setCreationError(true);
    }
  };
  //

  return {
    actions: {
      resetAll,
      getNewEmail,
      sendEmail,
    },
    input: {
      nameField,
      emailField,
      descriptionField,
    },
    attributes: {
      creationError,
    },
  };
};

export default useCreateEmail;
