import { useState, useContext, useEffect } from "react"
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from 'react-redux';
// Context
import { QuickResponseContext } from "features/Views/QuickResponse/context/QuickResponseContext";
// Slice
import {
  // Group 
  selectGroups,
  selectGroupsFetchStatus,
} from "redux/features/conversationsSlice/conversationsSlice";
import {
  selectCreateFetchStatus,
  selectUpdateFetchStatus,
} from "redux/features/quickResponseSlice/quickResponseSlice";
// Components/ui
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material"
// Types
import { GroupInfo } from "@trii/types/dist/Conversations/Groups/Group";
import { CreateCategory } from "redux/features/quickResponseSlice/types/CreateCategory";
import ActionButtons from "../ActionButtons";

const Category = () => {
  const { t } = useTranslation();
  const {
    categorySelected,
    getGroups,
    updateCategory,
    setCategorySelected,
    setEditionType,
    setNewCategory,
  } = useContext(QuickResponseContext);
  const groups = useSelector(selectGroups);
  const groupsFetchStatus = useSelector(selectGroupsFetchStatus);
  const createFetchStatus = useSelector(selectCreateFetchStatus);
  const updateFetchStatus = useSelector(selectUpdateFetchStatus);
  const isCreating = createFetchStatus === 'loading';
  const isUpdating = updateFetchStatus === 'loading';
  const isLoading = groupsFetchStatus === 'loading';
  const [groupList, setGroupList] = useState<GroupInfo[]>([])
  const [name, setName] = useState<string>('')
  const [isPublic, setIsPublic] = useState<boolean>(false)
  const [sharedGroupIds, setSharedGroupIds] = useState<string[]>([])

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target
    if (!checked) {
      setSharedGroupIds([])
    }
    setIsPublic(checked)
  }

  const handleChange = (
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setState(value)
  }

  const handleSelect = (
    setState: React.Dispatch<React.SetStateAction<string[]>>
  ) => (value: SelectChangeEvent<string[]>) => {
    const { value: newValue } = value.target
    setState(
      typeof newValue === 'string' ? newValue.split(',') : newValue
    )
  }

  const getGroupName = (id: string) => {
    const group = groupList.find((group) => group.id === id)
    if (group) {
      return group.name
    }
    return id
  }

  const handleClear = () => {
    setName('')
    setIsPublic(false)
    setSharedGroupIds([])
    setEditionType(null)
  }

  const handleSave = async () => {
    if (categorySelected) {
      const data = {
        ...categorySelected,
        name,
        isPublic,
        sharedGroupIds,
      }
      updateCategory(data)
      return
    }
    const data = {
      name,
      isPublic,
      sharedGroupIds,
    } as CreateCategory
    await setNewCategory(data)
    if (!categorySelected) {
      handleClear()
    }
  }

  useEffect(() => {
    getGroups()
  }, [])

  useEffect(() => {
    if (groups) {
      setGroupList(groups)
    }
  }, [groups])

  useEffect(() => {
    if (categorySelected) {
      setName(categorySelected.name)
      setIsPublic(categorySelected.isPublic)
      setSharedGroupIds(categorySelected.sharedGroupIds)
    } else {
      setName('')
      setIsPublic(false)
      setSharedGroupIds([])
    }
  }, [categorySelected])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          color: (theme) => theme.palette.text.primary,
          gap: 1,
          p: 2,
        }}
      >
        <TextField
          label={t('quickResponse.categories.name')}
          value={name}
          onChange={handleChange(setName)}
          fullWidth
          size="small"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 1,
          }}
        >
          <Select
            displayEmpty
            multiple
            size="small"
            value={sharedGroupIds}
            onChange={handleSelect(setSharedGroupIds)}
            disabled={!isPublic}
            input={<OutlinedInput label="Chip" />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return t('quickResponse.categories.private')
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                  }}
                >
                  {
                    selected.map((value) => (
                      <Chip key={value} label={getGroupName(value)} />
                    ))
                  }
                </Box>
              )
            }}
            MenuProps={{
              sx: {
                maxHeight: 300,
              }
            }}
            sx={{
              width: 'calc(100% - 5rem)',
            }}
          >
            <MenuItem disabled value={t('quickResponse.categories.private')}>
              {t('quickResponse.categories.private')}
            </MenuItem>
            {
              isLoading && (
                <MenuItem disabled>
                  <CircularProgress
                    size={24}
                    sx={{
                      margin: 'auto',
                    }}
                  />
                </MenuItem>
              )
            }
            {
              groupList.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))
            }
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
              />
            }
            onChange={handleCheck}
            sx={{
              width: '5rem'
            }}
            label={t('quickResponse.categories.public')}
          />
        </Box>
      </Box>
      <ActionButtons
        handleClear={handleClear}
        handleSave={handleSave}
        isLoading={isCreating || isUpdating}
      />
    </Box>
  )
}

export default Category