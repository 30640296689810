import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Hooks
import useColor from 'hooks/useColor';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeBackgroundColor,
  changeFontColor,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import DesignSection from '../DesignSection/DesignSection';
import BrushIcon from '@mui/icons-material/Brush';
import { MuiColorInput } from 'mui-color-input';
import { Box, Typography, Checkbox } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
  backgroundColor: string;
  fontColor: string;
}

const AppearanceSection = ({ backgroundColor, fontColor }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const backgroundColorSelect = useColor(backgroundColor);
  const fontColorSelect = useColor(fontColor);

  const handleChangeBackgroundColor = (color: string) => {
    dispatch(changeBackgroundColor(color));
  };
  const handleChangeFontColor = (color: string) => {
    dispatch(changeFontColor(color));
  };
  const handleGreenColorPicker = () => {
    handleChangeBackgroundColor('rgb(22,163,74)');
    backgroundColorSelect.actions.changeValue('rgb(22,163,74)');
  };
  const handleBlueColorPicker = () => {
    handleChangeBackgroundColor('rgb(37,99,235)');
    backgroundColorSelect.actions.changeValue('rgb(37,99,235)');
  };
  const handleRedColorPicker = () => {
    handleChangeBackgroundColor('rgb(220,38,38)');
    backgroundColorSelect.actions.changeValue('rgb(220,38,38)');
  };
  const handlePurpleColorPicker = () => {
    handleChangeBackgroundColor('rgb(147,51,234)');
    backgroundColorSelect.actions.changeValue('rgb(147,51,234)');
  };

  useEffect(() => {
    handleChangeBackgroundColor(backgroundColorSelect.attributes.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundColorSelect.attributes.value]);

  useEffect(() => {
    handleChangeFontColor(fontColorSelect.attributes.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontColorSelect.attributes.value]);

  return (
    <DesignSection
      title={t("webChatEditView.designPanel.appearanceSection.title")}
      icon={<BrushIcon sx={{ fontSize: 17, color: "primary.main" }} />}
    >
      <Box width={"50%"}>
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"column"}
          gap={1}
          alignItems={"center"}
        >
          <Typography variant={"subtitle2"}>
            {t(
              "webChatEditView.designPanel.appearanceSection.predefinedThemes"
            )}
          </Typography>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            <Checkbox
              icon={
                <CircleIcon sx={{ color: "rgb(37,99,235)", fontSize: 40 }} />
              }
              checkedIcon={
                <CircleIcon sx={{ color: "rgb(37,99,235)", fontSize: 40 }} />
              }
              onChange={handleBlueColorPicker}
            />
            <Checkbox
              onChange={handleGreenColorPicker}
              icon={
                <CircleIcon sx={{ color: "rgb(22,163,74)", fontSize: 40 }} />
              }
              checkedIcon={
                <CircleIcon sx={{ color: "rgb(22,163,74)", fontSize: 40 }} />
              }
            />
            <Checkbox
              icon={
                <CircleIcon sx={{ color: "rgb(220,38,38)", fontSize: 40 }} />
              }
              onChange={handleRedColorPicker}
              checkedIcon={
                <CircleIcon sx={{ color: "rgb(220,38,38)", fontSize: 40 }} />
              }
            />
            <Checkbox
              icon={<CircleIcon sx={{ color: "#9333ea", fontSize: 40 }} />}
              onChange={handlePurpleColorPicker}
              checkedIcon={
                <CircleIcon sx={{ color: "#9333ea", fontSize: 40 }} />
              }
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          gap={3}
          alignItems={"center"}
        >
          <Typography variant={"subtitle2"}>
            {t(
              "webChatEditView.designPanel.appearanceSection.personalizedColors"
            )}
          </Typography>
          <MuiColorInput
            label={t(
              "webChatEditView.designPanel.appearanceSection.backgroundColor"
            )}
            fullWidth
            size="small"
            {...backgroundColorSelect.attributes}
            sx={{
              mb: 1,      
            }}
          />
          <MuiColorInput
            label={t("webChatEditView.designPanel.appearanceSection.fontColor")}
            fullWidth
            size="small"
            {...fontColorSelect.attributes}
          />
        </Box>
      </Box>
    </DesignSection>
  );
};

export default AppearanceSection;
