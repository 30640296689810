import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  selectWhatsAppsFetchStatus,
  selectAllWhatsApps,
} from 'redux/features/whatsAppSlice/whatsAppSlice';
import {
  selectWebChatsFetchStatus,
  selectAllWebChats,
} from 'redux/features/webChatSlice/webChatSlice';
import {
  selectFacebooksFetchStatus,
  selectAllFacebooks,
} from 'redux/features/facebookSlice/facebookSlice';
import {
  selectInstagramsFetchStatus,
  selectAllInstagrams,
} from 'redux/features/instagramSlice/instagramSlice';
import {
  responseTitle,
  setTitleResponse,
  setSwitchBusinessHours,
  setSwitchOutOfBusinessHours,
  setSwitchHolidaysHours,
  setInputAutoResponseBusinessHoursMessage,
  setInputAutoResponseOutOfBusinessHoursMessage,
  setInputAutoResponseHolidayMessage,
  setIdResponse,
  responseId,
  getResponses,
} from 'redux/features/responsesSlice/responsesSlice';
import SectionsSkeletons from './SectionsSkeletons/SectionsSkeletons';
import { ChannelType, IChannel } from '@trii/types/dist/Common/Channels';
// import { responseTitle } from "redux/features/responsesSlice/responsesSlice";

const SectionsMenu = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();

  const title = useSelector(responseTitle);
  const idResponse = useSelector(responseId);
  const [idResponseState, setIdResponseState] = useState(idResponse);

  const handleSelectedAllChannels = useCallback(
    (allChannels: any) => {
      setIdResponseState(allChannels.id);
      dispatch(setTitleResponse(allChannels.name));
      dispatch(setIdResponse(allChannels.id));

      //reset
      dispatch(setSwitchBusinessHours(false));
      dispatch(setSwitchOutOfBusinessHours(false));
      dispatch(setSwitchHolidaysHours(false));
      dispatch(setInputAutoResponseBusinessHoursMessage(''));
      dispatch(setInputAutoResponseOutOfBusinessHoursMessage(''));
      dispatch(setInputAutoResponseHolidayMessage(''));

      //from API
      dispatch(getResponses(allChannels.id));
    },
    [dispatch]
  );
  const handleSelectedChannel = useCallback(
    (channelObj: IChannel) => {
      setIdResponseState(channelObj.id);
      dispatch(setTitleResponse(channelObj.name));
      dispatch(setIdResponse(channelObj.id));
      //console.log("channelSelected: ", channelObj)

      //reset
      dispatch(setSwitchBusinessHours(false));
      dispatch(setSwitchOutOfBusinessHours(false));
      dispatch(setSwitchHolidaysHours(false));
      dispatch(setInputAutoResponseBusinessHoursMessage(''));
      dispatch(setInputAutoResponseOutOfBusinessHoursMessage(''));
      dispatch(setInputAutoResponseHolidayMessage(''));

      //from API
      dispatch(getResponses(channelObj.id));

      // let _autoResponseBusinessHours = false;
      // let _autoResponseOutOfBusinessHours = false;
      // let _autoResponseHoliday = false;
      // let _autoResponseBusinessHoursMessage = '';
      // let _autoResponseOutOfBusinessHoursMessage = '';
      // let _autoResponseHolidayMessage = '';

      // switch (channelObj.type) {
      //   case ChannelType.WHATSAPP:
      //     if (channelObj.whatsApp != null) {
      //       _autoResponseBusinessHours = channelObj.whatsApp.autoResponseBusinessHours;
      //       _autoResponseOutOfBusinessHours = channelObj.whatsApp.autoResponseOutOfBusinessHours;
      //       _autoResponseHoliday = channelObj.whatsApp.autoResponseHoliday;

      //       _autoResponseBusinessHoursMessage = channelObj.whatsApp.autoResponseBusinessHoursMessage;
      //       _autoResponseOutOfBusinessHoursMessage = channelObj.whatsApp.autoResponseOutOfBusinessHoursMessage;
      //       _autoResponseHolidayMessage = channelObj.whatsApp.autoResponseHolidayMessage;
      //     }
      //     break;
      //   case ChannelType.FACEBOOK:
      //     if (channelObj.facebook != null) {
      //       _autoResponseBusinessHours = channelObj.facebook.autoResponseBusinessHours;
      //       _autoResponseOutOfBusinessHours = channelObj.facebook.autoResponseOutOfBusinessHours;
      //       _autoResponseHoliday = channelObj.facebook.autoResponseHoliday;

      //       _autoResponseBusinessHoursMessage = channelObj.facebook.autoResponseBusinessHoursMessage;
      //       _autoResponseOutOfBusinessHoursMessage = channelObj.facebook.autoResponseOutOfBusinessHoursMessage;
      //       _autoResponseHolidayMessage = channelObj.facebook.autoResponseHolidayMessage;
      //     }
      //     break;
      //   case ChannelType.INSTAGRAM:
      //     if (channelObj.instagram != null) {
      //       _autoResponseBusinessHours = channelObj.instagram.autoResponseBusinessHours;
      //       _autoResponseOutOfBusinessHours = channelObj.instagram.autoResponseOutOfBusinessHours;
      //       _autoResponseHoliday = channelObj.instagram.autoResponseHoliday;

      //       _autoResponseBusinessHoursMessage = channelObj.instagram.autoResponseBusinessHoursMessage;
      //       _autoResponseOutOfBusinessHoursMessage = channelObj.instagram.autoResponseOutOfBusinessHoursMessage;
      //       _autoResponseHolidayMessage = channelObj.instagram.autoResponseHolidayMessage;
      //     }
      //     break;
      //   case ChannelType.WEBCHAT:
      //     if (channelObj.webChat != null) {
      //       _autoResponseBusinessHours = channelObj.webChat.autoResponseBusinessHours;
      //       _autoResponseOutOfBusinessHours = channelObj.webChat.autoResponseOutOfBusinessHours;
      //       _autoResponseHoliday = channelObj.webChat.autoResponseHoliday;

      //       _autoResponseBusinessHoursMessage = channelObj.webChat.autoResponseBusinessHoursMessage;
      //       _autoResponseOutOfBusinessHoursMessage = channelObj.webChat.autoResponseOutOfBusinessHoursMessage;
      //       _autoResponseHolidayMessage = channelObj.webChat.autoResponseHolidayMessage;
      //     }
      //     break;
      //   default:
      //     break;
      // }

      // dispatch(setSwitchBusinessHours(_autoResponseBusinessHours));
      // dispatch(setSwitchOutOfBusinessHours(_autoResponseOutOfBusinessHours));
      // dispatch(setSwitchHolidaysHours(_autoResponseHoliday));

      // dispatch(setInputAutoResponseBusinessHoursMessage(_autoResponseBusinessHoursMessage == null ? '': _autoResponseBusinessHoursMessage));
      // dispatch(setInputAutoResponseOutOfBusinessHoursMessage(_autoResponseOutOfBusinessHoursMessage == null ? '': _autoResponseOutOfBusinessHoursMessage));
      // dispatch(setInputAutoResponseHolidayMessage(_autoResponseHolidayMessage == null ? '': _autoResponseHolidayMessage));
    },
    [dispatch]
  );

  const allWhatsApps = useSelector(selectAllWhatsApps);
  const allWebChats = useSelector(selectAllWebChats);
  const allFacebooks = useSelector(selectAllFacebooks);
  const allInstagrams = useSelector(selectAllInstagrams);
  const allChannels = [
    {
      id: '*',
      name: t('responsesView.allTheChannels'),
      autoResponseBusinessHoursMessage: '',
      autoResponseOutOfBusinessHoursMessage: '',
      autoResponseHolidayMessage: '',
    },
  ];
  // const selectedChannel = useSelector(responseTitle);
  const whatsAppsFetchStatus = useSelector(selectWhatsAppsFetchStatus);
  const webChatsFetchStatus = useSelector(selectWebChatsFetchStatus);
  const facebooksFetchStatus = useSelector(selectFacebooksFetchStatus);
  const instagramsFetchStatus = useSelector(selectInstagramsFetchStatus);
  const WhatsApps = allWhatsApps.map((channel: IChannel) => (
    <ListItem
      disablePadding
      sx={{ color: (theme) => theme.palette.text.primary }}
      key={channel.id}
    >
      <ListItemButton
        sx={{ py: 0, pl: 3, minHeight: 32 }}
        // onClick={() => dispatch(setTitleResponse(whatsApp.name))}
        onClick={() => handleSelectedChannel(channel)}
        selected={idResponseState === channel.id}
      >
        <ListItemText
          primary={channel.name}
          primaryTypographyProps={{ fontSize: 14 }}
        />
      </ListItemButton>
    </ListItem>
  ));
  const WebChats = allWebChats.map((channel: IChannel) => (
    <ListItem
      disablePadding
      sx={{ color: (theme) => theme.palette.text.primary }}
      key={channel.id}
    >
      <ListItemButton
        sx={{ py: 0, pl: 3, minHeight: 32 }}
        onClick={() => handleSelectedChannel(channel)}
        selected={idResponseState === channel.id}
      >
        <ListItemText
          primary={channel.name}
          primaryTypographyProps={{ fontSize: 14 }}
        />
      </ListItemButton>
    </ListItem>
  ));
  const Facebooks = allFacebooks.map((channel: IChannel) => (
    <ListItem
      disablePadding
      sx={{ color: (theme) => theme.palette.text.primary }}
      key={channel.id}
    >
      <ListItemButton
        sx={{ py: 0, pl: 3, minHeight: 32 }}
        onClick={() => handleSelectedChannel(channel)}
        selected={idResponseState === channel.id}
      >
        <ListItemText
          primary={channel.name}
          primaryTypographyProps={{ fontSize: 14 }}
        />
      </ListItemButton>
    </ListItem>
  ));
  const Instagrams = allInstagrams.map((channel: IChannel) => (
    <ListItem
      disablePadding
      sx={{ color: (theme) => theme.palette.text.primary }}
      key={channel.id}
    >
      <ListItemButton
        sx={{ py: 0, pl: 3, minHeight: 32 }}
        onClick={() => handleSelectedChannel(channel)}
        selected={idResponseState === channel.id}
      >
        <ListItemText
          primary={channel.name}
          primaryTypographyProps={{ fontSize: 14 }}
        />
      </ListItemButton>
    </ListItem>
  ));
  const allChannelsList = allChannels.map((channel: any) => (
    <ListItem
      disablePadding
      sx={{ color: (theme) => theme.palette.text.primary }}
      key={channel.id}
    >
      <ListItemButton
        sx={{ py: 0, pl: 3, minHeight: 32 }}
        onClick={() => handleSelectedAllChannels(channel)}
        selected={idResponseState === channel.id}
      >
        <ListItemText
          primary={channel.name}
          primaryTypographyProps={{ fontSize: 14 }}
        />
      </ListItemButton>
    </ListItem>
  ));

  //Cargar configuracion inicial (Todos los canales)
  useEffect(() => {
    handleSelectedAllChannels(allChannels[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        // maxHeight: "calc(100vh - 191px)",
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        maxHeight: "inherit",
        overflow: "auto",
        width: "15%",
        maxWidth: "300px",
      }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      mr={1}
    >
      <Box sx={{ width: "100%", maxWidth: 360 }}>
        <nav aria-label="secondary mailbox folders">
          <List sx={{padding: 0}}>
            {/* Todos los canales */}
            <ListSubheader sx={{ fontSize: "16px", backgroundColor: 'unset' }}>
              {t("responsesView.messenger")}
            </ListSubheader>
            {allChannelsList}
            {/* <ListItem
              disablePadding
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              <ListItemButton
                sx={{ py: 0, pl: 3, minHeight: 32 }}
                onClick={() => handleSelectedChannel()}
                selected={title === "allChannels"}
              >
                <ListItemText
                  primary={t("responsesView.allTheChannels")}
                  primaryTypographyProps={{ fontSize: 14 }}
                />
              </ListItemButton>
            </ListItem> */}
            {/* WhatsApp */}

            <ListSubheader sx={{ fontSize: "16px", backgroundColor: 'unset' }}>WhatsApp</ListSubheader>
            {whatsAppsFetchStatus === "succeeded" ? (
              WhatsApps
            ) : (
              <SectionsSkeletons />
            )}
            {/* Facebook */}
            <ListSubheader sx={{ fontSize: "16px", backgroundColor: 'unset' }}>Facebook</ListSubheader>
            {facebooksFetchStatus === "succeeded" ? (
              Facebooks
            ) : (
              <SectionsSkeletons />
            )}

            {/* Instagram */}
            <ListSubheader sx={{ fontSize: "16px", backgroundColor: 'unset' }}>Instagram</ListSubheader>
            {instagramsFetchStatus === "succeeded" ? (
              Instagrams
            ) : (
              <SectionsSkeletons />
            )}

            {/* WebChat */}
            <ListSubheader sx={{ fontSize: "16px", backgroundColor: 'unset' }}>WebChat</ListSubheader>
            {webChatsFetchStatus === "succeeded" ? (
              WebChats
            ) : (
              <SectionsSkeletons />
            )}
          </List>
        </nav>
      </Box>
    </Box>
  );
};

export default SectionsMenu;
