import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Checkbox, TextField, Slider, FormControlLabel, IconButton } from '@mui/material';
// Utils
import { dayList } from './utils/dayList';
import { marks } from './utils/marks';
// Types
import { ITimeRange } from '@trii/types/dist/Common/Schedules';
// Context
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';
// Icons
import { Delete } from '@mui/icons-material';

interface Props {
  timeRange: ITimeRange;
}

const ScheduleDays = ({
  timeRange,
}: Props) => {
  const {
    timeRanges,
    setTimeRanges
  } = useContext(InitScheduleEditContext);
  const { t } = useTranslation();
  const [timeValue, setTimeValue] = useState<number[]>([28800, 32400]);
  const [name, setName] = useState<string>('');
  const [monday, setMonday] = useState<boolean>(false);
  const [tuesday, setTuesday] = useState<boolean>(false);
  const [wednesday, setWednesday] = useState<boolean>(false);
  const [thursday, setThursday] = useState<boolean>(false);
  const [friday, setFriday] = useState<boolean>(false);
  const [saturday, setSaturday] = useState<boolean>(false);
  const [sunday, setSunday] = useState<boolean>(false);

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    const newTimes = timeRanges.map((item) => {
      if (item.id === timeRange.id) {
        return {
          ...item,
          startSeconds: newValue[0],
          endSeconds: newValue[1],
        }
      }
      return item;
    })
    setTimeRanges(newTimes);

    if (activeThumb === 0) {
      setTimeValue([Math.min(newValue[0], timeValue[1]), timeValue[1]]);
    } else {
      setTimeValue([timeValue[0], Math.max(newValue[1], timeValue[0])]);
    }
  };

  const handleShowValue = (timeValue: number) => {
    const hour = Math.floor(timeValue / 3600);
    const minute = Math.floor((timeValue % 3600) / 60);
    const hourString = hour < 10 ? `0${hour}` : `${hour}`;
    const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
    return `${hourString}:${minuteString}`;
  }

  const handleCheck = (checked: boolean, day: string) => {
    const newCheck = timeRanges.map((item) => {
      if (item.id === timeRange.id) {
        const newMonday = day === 'scheduleView.monday' ? checked : monday
        const newTuesday = day === 'scheduleView.tuesday' ? checked : tuesday
        const newWednesday = day === 'scheduleView.wednesday' ? checked : wednesday
        const newThursday = day === 'scheduleView.thursday' ? checked : thursday
        const newFriday = day === 'scheduleView.friday' ? checked : friday
        const newSaturday = day === 'scheduleView.saturday' ? checked : saturday
        const newSunday = day === 'scheduleView.sunday' ? checked : sunday
        return {
          ...item,
          monday: newMonday,
          tuesday: newTuesday,
          wednesday: newWednesday,
          thursday: newThursday,
          friday: newFriday,
          saturday: newSaturday,
          sunday: newSunday,
        }
      }
      return item;
    })
    setTimeRanges(newCheck);
  };

  const getChecked = (day: string) => {
    switch (day) {
      case 'scheduleView.monday':
        return monday;
      case 'scheduleView.tuesday':
        return tuesday;
      case 'scheduleView.wednesday':
        return wednesday;
      case 'scheduleView.thursday':
        return thursday;
      case 'scheduleView.friday':
        return friday;
      case 'scheduleView.saturday':
        return saturday;
      case 'scheduleView.sunday':
        return sunday;
    }
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
    const newName = timeRanges.map((item) => {
      if (item.id === timeRange.id) {
        return {
          ...item,
          name: value,
        }
      }
      return item;
    })
    setTimeRanges(newName);
  }

  const handleDelete = () => {
    const newTimeRanges = timeRanges.filter((item) => item.id !== timeRange.id);
    setTimeRanges(newTimeRanges);
  }

  useEffect(() => {
    if (timeRange) {
      setName(timeRange.name);
      setTimeValue([timeRange.startSeconds, timeRange.endSeconds]);
      setMonday(timeRange.monday);
      setTuesday(timeRange.tuesday);
      setWednesday(timeRange.wednesday);
      setThursday(timeRange.thursday);
      setFriday(timeRange.friday);
      setSaturday(timeRange.saturday);
      setSunday(timeRange.sunday);
    }
  }, [timeRange])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        borderRadius: '4px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        boxShadow: (theme) => theme.shadows[1],
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          width: '100%',
        }}
      >
        <TextField
          label={t('scheduleView.scheduleName')}
          size='small'
          value={name}
          onChange={handleChangeName}
          autoComplete='off'
        />
        <IconButton
          size='small'
          onClick={handleDelete}
        >
          <Delete
            sx={{
              fontSize: 20,
              color: 'text.primary',
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
            width: '100%',
          }}
        >
          {
            dayList.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(e) => handleCheck(e.target.checked, item)}
                    checked={getChecked(item)}
                  />
                }
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
                label={t(item)}
              />
            ))
          }
        </Box>
        <Slider
          getAriaLabel={() => t('scheduleView.slider')}
          defaultValue={0}
          value={timeValue}
          onChange={handleChange}
          disableSwap
          valueLabelDisplay="auto"
          min={0}
          max={86400}
          valueLabelFormat={handleShowValue}
          marks={marks}
          sx={{
            width: '95%',

          }}
        />
      </Box>
    </Box>
  );
};

export default ScheduleDays;
