import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, TextField } from '@mui/material';
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';

const Inputs = () => {
  const { t } = useTranslation();
  const {
    scheduleNameField,
    scheduleDescriptionField,
  } = useContext(InitScheduleEditContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('scheduleView.scheduleName')}
        fullWidth
        {...scheduleNameField.attributes}
      />
      <TextField
        className="fadein"
        variant="outlined"
        multiline
        size="small"
        label={t('scheduleView.scheduleDescription')}
        fullWidth
        {...scheduleDescriptionField.attributes}
      />
    </Box>
  );
};

export default Inputs;
