import { useEffect, useState, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// utlis
import { priorityList } from './utils';
// Interface
import { NodeFillTicketPriority } from '@trii/types/dist/Conversations/Flows/Nodes';

const Priority = () => {
  const { fillTicketNode, setFillTicketNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [ticketPriority, setTicketPriority] = useState<number>(
    NodeFillTicketPriority.NORMAL
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.fillTicket) {
      const fillTicket = selectNode.data.fillTicket;
      const defaultTicketPriority =
        fillTicket.ticketPriority && (fillTicket.ticketPriority as number);
      setTicketPriority(defaultTicketPriority);
    }
  }, [selectNode]);

  const handleChangePriority = (ticketPriority: number) => {
    setTicketPriority(ticketPriority);
    setFillTicketNode({ ...fillTicketNode, ticketPriority });
  };
  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="category" shrink={true}>
          {t("editFlowsView.fillTicket.priority.title")}
        </InputLabel>
        <Select
          labelId="category"
          id="demo-simple-select"
          label={t("editFlowsView.fillTicket.priority.title")}
          value={ticketPriority}
          onChange={(e) => handleChangePriority(e.target.value as number)}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              // padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {priorityList.map((priority) => (
            <MenuItem key={priority.id} value={priority.id}>
              {t(priority.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Priority;
