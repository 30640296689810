//Components/ui
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
// Icons
import { Label as LabelIcon } from "@mui/icons-material"
// Types
import { ILabel } from "@trii/types/dist/Conversations";

interface LabelProps {
  labels: ILabel[];
}

const Label = ({
  labels,
}: LabelProps) => (
  <Box
    sx={{
      alignItems: 'center',
      overflow: 'auto',
      overflowX: 'hidden',
      height: '100%',
    }}
  >
    {labels && labels.length > 0 && labels.map((label, i) => (
      <ListItem
        key={label.id}
        sx={{
          padding: 0,
        }}
      >
        <ListItemButton
          sx={{
            borderBottom: (theme) => i === labels.length - 1 ? 'none' : `1px solid ${theme.palette.grey[300]}`,
            '&:hover': {
              cursor: 'default',
            },
          }}
        >
          <ListItemIcon>
            <LabelIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={label.name}
            sx={{
              color: theme => theme.palette.text.primary,
            }}
          />
        </ListItemButton>
      </ListItem>
    ))}
  </Box>
)

export default Label