import { useContext, useEffect } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Divider, IconButton, Typography } from '@mui/material';
// Icons
import { Close } from '@mui/icons-material';
// Components
import { FilterChannels, FilterFavorites, FilterMessageContent } from './components';
// Redux
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';

const SearchMessage = () => {
  const conversationSelected = useSelector(selectConversationSelected);
  const {
    setIsSearching,
    chatFilter: { resetAll },
  } = useContext(messagesContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setIsSearching(false);
  };

  useEffect(() => {
    return () => resetAll();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
      borderLeft={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Box
        sx={{
          width: '100%',
          height: '3rem',
          display: 'flex',
          alignItems: 'center',
          padding: '0 1rem',
          justifyContent: 'space-between',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 'bold',
            fontSize: '1.2rem',
          }}
        >
          {t('conversations.message.search.title')}
        </Typography>
        <IconButton
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
          padding: '0 1rem',
          gap: '1rem',
        }}
      >
        {conversationSelected && conversationSelected?.channelInfo !== null && (
          <FilterChannels />
        )}
        <FilterFavorites />
        <FilterMessageContent />
      </Box>
    </Box>
  );
};

export default SearchMessage;
