import useField from 'hooks/useField';
// Types
import { UseCreateMercadoLibre } from './types';
import { NewMercadoLibre } from 'redux/features/mercadoLibreSlice/types/NewMercadoLibre';

const useCreateMercadoLibre = (): UseCreateMercadoLibre => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewMercadoLibre = (): NewMercadoLibre => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewMercadoLibre,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateMercadoLibre;
