// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Box,
  CircularProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import moment from "moment";
// Types
import { Log } from '@trii/types/dist/Common/Logs/Log';

interface Props {
  list: Log[];
  isLoading: boolean;
}

const Table = ({
  list,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <MuiTable
        sx={{
          width: "100%",
          height: "100%",
          borderCollapse: "collapse",
        }}
      >
        <TableHead
          sx={{
            backgroundColor: theme => theme.palette.background.default,
            color: theme => theme.palette.text.primary,
            width: "100%",
            height: "3rem",
            position: "sticky",
            top: 0,
          }}
        >
          <TableRow>
            <TableCell>
              {t('dashboard.channels.registers.date')}
            </TableCell>
            <TableCell>
              {t('dashboard.channels.registers.status')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            backgroundColor: theme => theme.palette.background.paper,
            color: theme => theme.palette.text.primary,
            width: "100%",
            position: "relative",
          }}
        >
          {
            isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                }}
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              list && list.length > 0 && list.map((item: Log, index: number) => (
                <TableRow
                  key={index}
                >
                  <TableCell>
                    {moment(item.timeStamp).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {item.details}
                  </TableCell>
                </TableRow>
              ))
            )
          }
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table;