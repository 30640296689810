import { NodeMsgTextMessageType } from "@trii/types/dist/Conversations/Flows/Nodes";

type Format = {
    id: number;
    name: string;
}

const formatList: Format[] = [
    {
        id: NodeMsgTextMessageType.TEXT,
        name: 'editFlowsView.text.manualMessage.text'
    },
    {
        id: NodeMsgTextMessageType.IMAGE,
        name: 'editFlowsView.text.manualMessage.image'
    },
    {
        id: NodeMsgTextMessageType.VIDEO,
        name: 'editFlowsView.text.manualMessage.video'
    },
    {
        id: NodeMsgTextMessageType.FILE,
        name: 'editFlowsView.text.manualMessage.file'
    }
]

export default formatList;