import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import {
  changeSmsMasivosUser,
  changeSmsMasivosPass,
} from 'redux/features/smsEditSlice/smsEditSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';

interface Props {
  data: {
    smsmasivosUser: string;
    smsmasivosPass: string;
  };
}

const SmsMasivosInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { smsmasivosUser, smsmasivosPass } = data;

  const handleSmsMasivosUserChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeSmsMasivosUser(event.target.value));
  };

  const handleSmsMasivosPassChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeSmsMasivosPass(event.target.value));
  };

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label={t('global.user')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={smsmasivosUser}
        onChange={handleSmsMasivosUserChange}
      />
      <TextField
        type="password"
        size="small"
        label={t('global.password')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={smsmasivosPass}
        onChange={handleSmsMasivosPassChange}
      />
    </ChannelInputsContainer>
  );
};

export default SmsMasivosInputs;
