import { useEffect } from 'react';
// Context
import { EditFlowsProvider } from 'features/Views/EditFlows/context/EditFlowsContext';
// react-router-dom
import { useParams } from 'react-router-dom';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Slice
import { fetchEditFlow } from 'redux/features/editFlowSlice/editFlowSlice';
// Components
import { Body, Header } from './components';
import { Box } from '@mui/material';

export default function EditFlows() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(fetchEditFlow(id));
  }, [dispatch]);

  return (
    <EditFlowsProvider>
      <Box
        display="flex"
        width="100%"
        height={'100%'}
        // maxHeight={'91vh'}
        flexDirection={'column'}
        className={'conversations-fadeinup'}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Header />
        <Body />
      </Box>
    </EditFlowsProvider>
  );
}
