import React, { useContext } from 'react';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectSelectedGroup } from 'redux/features/groupSlice/groupSlice';
// Types
import { Group } from 'redux/features/groupSlice/types';
import { GroupContext } from 'features/Views/Groups/context/types';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const { t } = useTranslation();
  const { closeDeletePanel } = useContext<GroupContext>(InitGroupContext);
  const selectedGroup: Group = useSelector(selectSelectedGroup);

  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Box display={'flex'} gap={1}>
        <Typography variant="subtitle2" sx={{color: theme=>theme.palette.text.primary}}>
          {t('settingsView.groups.deleteGroup')}
        </Typography>
        <Typography variant="subtitle2" sx={{fontWeight: "bold", color: theme=>theme.palette.text.primary}}>{selectedGroup?.name}</Typography>
      </Box>
      <IconButton onClick={closeDeletePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;
