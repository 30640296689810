import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Box } from '@mui/material';

const ApiKey = () => {
  const { selectNode, setApiNode, apiNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.api && selectNode.data.api.apiAuthApiKey) {
      const defaultKey = Object.keys(selectNode.data.api.apiAuthApiKey.headers)[0];
      setKey(defaultKey);
      setValue(selectNode.data.api.apiAuthApiKey.headers[defaultKey]);
    }
  }, [selectNode]);

  useEffect(() => {
    if (key && value) {
      setApiNode({
        ...apiNode,
        apiAuthApiKey: {
          headers: {
            [key]: value,
          },
        },
      });
    }
  }, [key, value]);

  return (
    <Box display="flex" gap={1}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={key}
        onChange={(e) => setKey(e.target.value)}
        label={t('editFlowsView.api.authType.apiKey.key')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        label={t('editFlowsView.api.authType.apiKey.value')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default ApiKey;
