import React, { useState } from 'react';
// Components/ui
import { Modal, Box, IconButton } from '@mui/material';
// Icons
import { Close } from '@mui/icons-material';

const ImageModal = ({ imageUrl, altText }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <img
                src={imageUrl}
                alt={altText}
                onClick={handleModalOpen}
                width={'10%'}
                height={'10%'}
                style={{ cursor: 'pointer', marginLeft: '1rem' }}
            />
            <Modal open={modalOpen} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: (theme) => theme.palette.background.paper,
                        boxShadow: 10,
                        p: 4,
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <img
                        src={imageUrl}
                        alt={altText}
                        id="img01"
                        width={'50%'}
                        height={'50%'}
                    />

                    <IconButton aria-label="close" onClick={handleModalClose} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <Close />
                    </IconButton>
                </Box>
            </Modal>
        </>
    );
};

export default ImageModal;