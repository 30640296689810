import { useTranslation } from 'react-i18next';
// Types
import { SmsConfig, SmsConectionType } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeConectionType } from 'redux/features/smsEditSlice/smsEditSlice';
// Components/ui
import { ChannelPanel } from 'components';
import { Box, Select, Typography, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  SynwayInputs,
  DinstarInputs,
  NotimationInputs,
  YeastarInputs,
  SmsMasivosInputs,
} from './components';

interface Props {
  data: SmsConfig;
}

const ConectionPanel = ({ data }: Props) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();
  const { conectionType } = data;
  const {
    synwayUser,
    synwayPass,
    synwayUrl,
    synwayPort,
    dinstarUser,
    dinstarPass,
    dinstarUrl,
    dinstarPort,
    yeastarUser,
    yeastarPass,
    yeastarUrl,
    yeastarPort,
    notimationToken,
    notimationServiceId,
    smsmasivosUser,
    smsmasivosPass,
  } = data;
  const synwayData = { synwayUser, synwayPass, synwayUrl, synwayPort };
  const dinstarData = { dinstarUser, dinstarPass, dinstarUrl, dinstarPort };
  const yeastarData = { yeastarUser, yeastarPass, yeastarUrl, yeastarPort };
  const notimationData = { notimationToken, notimationServiceId };
  const smsmasivosData = { smsmasivosUser, smsmasivosPass };

  const handleTypeChange = (event: SelectChangeEvent<SmsConectionType>) => {
    const newValue = event.target.value;

    dispatch(changeConectionType(newValue as SmsConectionType));
  };

  return (
    <ChannelPanel title="Conexion">
      <Box display={'flex'} flexDirection={'column'} gap={4} alignItems={'center'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width="55%"
          ml={2}
        >
          <Typography>{t('smsView.connectionType')}</Typography>
          <Select
            value={conectionType}
            onChange={handleTypeChange}
            sx={{ width: '70%' }}
            size="small"
            MenuProps={{
              PaperProps: {
                sx: {
                  //@ts-ignore
                  backgroundColor: theme => theme.palette.background.panel,
                },
              },
            }}
          >
            <MenuItem value={SmsConectionType.NULL}>{t('global.none')}</MenuItem>
            <MenuItem value={SmsConectionType.SYNWAY}>gatewaySYNWAY</MenuItem>
            <MenuItem value={SmsConectionType.DINSTAR}>gatewayDINSTAR</MenuItem>
            <MenuItem value={SmsConectionType.YEASTAR}>gatewayYEASTAR</MenuItem>
            <MenuItem value={SmsConectionType.NOTIMATION}>NOTIMATION</MenuItem>
            <MenuItem value={SmsConectionType.SMSMASIVOS}>SMSMASIVOS</MenuItem>
          </Select>
        </Box>
        {conectionType === 1 && <SynwayInputs data={synwayData} />}
        {conectionType === 2 && <DinstarInputs data={dinstarData} />}
        {conectionType === 3 && <YeastarInputs data={yeastarData} />}
        {conectionType === 5 && <NotimationInputs data={notimationData} />}
        {conectionType === 6 && <SmsMasivosInputs data={smsmasivosData} />}
      </Box>
    </ChannelPanel>
  );
};

export default ConectionPanel;
