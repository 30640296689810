// Components/ui
import { Box, Typography, useTheme } from '@mui/material';
// Types
import { IContactChangeLog, ContactLogAction } from '@trii/types/dist/Contacts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserNameById } from 'redux/features/userSlice/userSlice';
import { RootState } from 'redux/store';

interface HistoryItemProps {
  data: IContactChangeLog;
}

const HistoryItem = ({ data }: HistoryItemProps) => {
  const theme = useTheme();


  const userName = useSelector((state: RootState) =>
    selectUserNameById(state, data.userId)
  );

  const {t} = useTranslation()

  const dateTime = new Date(data.timeStamp);
  const formattedDate = `${dateTime.getDate()}/${dateTime.getMonth()}/${dateTime.getFullYear()}`;
  const formattedTime = `${dateTime.getHours()}:${dateTime.getMinutes()}`;


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        p: 0.5,
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      }}
    >
      <Typography color="text.primary" fontSize={12} fontWeight="bold">
        {formattedDate} {formattedTime}
      </Typography>
      <Box display="flex" gap={1} mb={0.2} flexDirection={'column'}>
        <Typography color="text.primary" fontSize={12}>
          {userName}
        </Typography>
        <Typography fontSize={12} fontStyle="italic" color="text.primary">
          {t(`conversations.sidebar.history.actions.${data.action}`)}
        </Typography>
        {/* Description */}
        <Typography color="text.primary" fontSize={12}>
          {data.details}
        </Typography>
      </Box>
    </Box>
  );
};

export default HistoryItem;
