import { Dispatch, SetStateAction, useEffect, useState } from "react"
// Redux
import { useSelector } from "react-redux"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Chip, FormControl, InputLabel, Popover } from "@mui/material"
// Icons
import { FilterAlt } from "@mui/icons-material"
// Components
import { CustomDateRange } from "components"
import Selector from "../Selector"
// Selector
import { selectUsers, selectUsersStatus } from "redux/features/userSlice/userSlice"
// Types
import { Dayjs } from "dayjs"
import { UserInfo } from "@trii/types/dist/Users"

interface Props {
  startDate: Dayjs
  endDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
  setUserId: Dispatch<SetStateAction<string>>;
}

const Filters = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setUserId,
}: Props) => {
  const { t } = useTranslation();
  const newUsers = useSelector(selectUsers);
  const usersStatus = useSelector(selectUsersStatus);
  const isLoading = usersStatus === "loading";
  const [users, setUsers] = useState(newUsers);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSelectUser = (user: string) => {
    setUserId(user);
  }

  useEffect(() => {
    if (newUsers && newUsers.length > 0) {
      setUsers(newUsers);
    }
  }, [newUsers])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Chip
        label={`: ${t("dashboard.filters.title")}`}
        icon={<FilterAlt fontSize="small" />}
        variant="outlined"
        onClick={handleClick}
        sx={{
          zIndex: 0,
          cursor: "pointer",
          //@ts-ignore
          bgcolor: (theme) => theme.palette.background.dropdownMenu,
        }}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disablePortal={false}
        sx={{
          mt: "5px",
        }}
      >
        <Box
          sx={{
            p: 2,
            mt: 0,
            boxShadow: "rgb(0 0 0 / 25%) 0px 1px 6px 0px !important",
            width: 200,
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <CustomDateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <FormControl fullWidth sx={{ marginTop: 0.5 }}>
              <InputLabel id="users" shrink={true}>
                {t("dashboard.filters.user")}
              </InputLabel>
              <Selector
                labelId="users"
                label={t("dashboard.filters.user")}
                list={users}
                handleSelect={handleSelectUser}
                isLoading={isLoading}
                width="100%"
              />
            </FormControl>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default Filters