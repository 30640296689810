import React, { useContext } from 'react';
import { Snackbar } from '@mui/material';
import { InitGroupContext } from '../../context/GroupContext';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
const AlertMessage = () => {
  const {
    errorMessage,
    errorChannels,
    snackbarOpen,
    handleSnackbarClose,
  } = useContext(InitGroupContext);

  const { t } = useTranslation();

  // Función para traducir el mensaje si coincide
  const getTranslatedMessage = (message) => {
    switch (message) {
      case "The group is already in use":
        return t('settingsView.groups.groupAlreadyInUse');
      default:
        return message;
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        // autoHideDuration={5000}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      >
          <Alert
    onClose={handleSnackbarClose}
    severity="error"
    variant="filled"
    sx={{ width: '100%' }}
  >
   {/* {errorMessage ? `${errorMessage} - Channels: ${errorChannels.join(', ')}` : ''} */}
   {errorMessage && <div>{getTranslatedMessage(errorMessage)}:</div>}
        {errorChannels.length > 0 && (
          <ul>
            {errorChannels.map((channel, index) => (
              <li key={index}>{channel}</li>
            ))}
          </ul>
        )}
  </Alert>
      </Snackbar>
    </>
  );
};

export default AlertMessage;
