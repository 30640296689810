import { ReactNode } from 'react';
// Components/ui
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

interface Props {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const Item = ({ title, icon, onClick, disabled }: Props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton disabled={disabled} onClick={onClick}>
        <ListItemIcon
          sx={{
            color: 'text.disabled',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

export default Item;
