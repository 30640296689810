import { useTranslation } from 'react-i18next';
// Types
import { EmailConfigFromNameType } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeFromNameType } from 'redux/features/emailEditSlice/emailEditSlice';
// Components/ui
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  emailFromNameType: EmailConfigFromNameType;
}

const FromTypeSelect = ({ emailFromNameType }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleEmailFromNameTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as unknown as EmailConfigFromNameType;
    dispatch(changeFromNameType(newValue));
  };
  return (
    <Box display={'flex'} marginLeft={'11px'} width={'55%'} gap={1}>
      <FormControl fullWidth>
        <InputLabel id="from-name-select">
          {t('emailEditView.detailsPanel.fromName')}
        </InputLabel>
        <Select
          labelId="from-name-select"
          id="demo-simple-select"
          // @ts-ignore
          value={emailFromNameType}
          label={t('emailEditView.detailsPanel.fromName')}
          onChange={handleEmailFromNameTypeChange}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
              },
            },
          }}
        >
          <MenuItem value={EmailConfigFromNameType.CHANNEL_NAME}>
            {t('emailEditView.detailsPanel.channelName')}
          </MenuItem>
          <MenuItem value={EmailConfigFromNameType.USER_NAME}>
            {t('emailEditView.detailsPanel.userName')}
          </MenuItem>
          <MenuItem value={EmailConfigFromNameType.CUSTOM}>
            {t('emailEditView.detailsPanel.custom')}
          </MenuItem>
        </Select>
      </FormControl>
      <Tooltip title={t('emailEditView.detailsPanel.fromNameDescription')}>
        <IconButton color="info" size="small" aria-label="info">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default FromTypeSelect;
