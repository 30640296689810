import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectMercadoLibresFetchStatus,
  selectAllMercadoLibres,
} from 'redux/features/mercadoLibreSlice/mercadoLibreSlice';
// Components/ui
import { Box } from '@mui/material';
import { ListItemSkeleton } from 'components';
import MercadoLibreItem from './components/MercadoLibreItem/MercadoLibreItem';

const ListBody = () => {
  const allMercadoLibres = useSelector(selectAllMercadoLibres);
  const mercadoLibresFetchStatus: RequestStatus = useSelector(
    selectMercadoLibresFetchStatus
  );

  const MercadoLibres = allMercadoLibres.map((mercadoLibre) => (
    <MercadoLibreItem key={mercadoLibre.id} data={mercadoLibre} />
  ));

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {mercadoLibresFetchStatus === 'succeeded' ? (
        MercadoLibres
      ) : (
        <ListItemSkeleton />
      )}
    </Box>
  );
};

export default ListBody;
