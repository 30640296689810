import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Context
import { facebookContext } from 'features/Views/Facebook/context/FacebookProvider';
// Router
import { NavLink } from 'react-router-dom';
// Components/ui
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Login with Facebook
import FacebookLogin, { SuccessResponse } from '@greatsumini/react-facebook-login';
// Slice
import { selectFacebookCreateStatus } from 'redux/features/facebookSlice/facebookSlice';

const PRODUCTION_ID = '358413321728421'; // Facebook App ID for production
const DEVELOPMENT_ID = '1042955903388962'; // Facebook App ID for localhost

const ListHeader = () => {
  const {
    handleCreateFacebook
  } = useContext(facebookContext);
  const location = window.location;
  const { hostname } = location;
  const facebookId = hostname === 'localhost' ? DEVELOPMENT_ID : PRODUCTION_ID;
  const facebookCreateStatus = useSelector(selectFacebookCreateStatus);
  const isLoading = facebookCreateStatus === 'loading';
  const { t } = useTranslation();

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/facebook">
      <Typography color="secondary">{t('facebooksView.facebooks')}</Typography>
    </NavLink>,
  ];

  const responseFacebook = (response: SuccessResponse) => {
    const { accessToken } = response;
    handleCreateFacebook({ accessToken });
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
       // className="m-0"
       gap={'1rem'}
    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <FacebookLogin
        appId={facebookId}
        fields="name,email,picture"
        scope="business_management,pages_show_list,pages_manage_metadata,pages_messaging,pages_read_engagement,public_profile,pages_manage_engagement,pages_manage_posts,pages_read_user_content,pages_read_user_content"
        onSuccess={responseFacebook}
        onFail={(error) => console.log(error)}
        initParams={{
          // @ts-ignore
          version: 'v18.0',
          xfbml: true,
        }}
        render={(renderProps) => (
          <LoadingButton
            sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
            variant="contained"
            size="small"
            onClick={renderProps?.onClick}
            loading={isLoading}
          >
            {t('facebooksView.loginFacebook')}
          </LoadingButton>
        )}
      />
    </Box>
  );
};

export default ListHeader;
