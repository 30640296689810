import { useEffect } from 'react';
// Redux
import { getConversations } from 'redux/features/conversationsSlice/conversationsSlice';
// Context
import ConversationsProvider from './context/ConversationsProvider/ConversationsProvider';
import MessagesProvider from './context/MessagesProvider/MessagesProvider';
// Components
import ConversationsContainer from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FetchConversationsFilter } from 'redux/features/conversationsSlice/types/FetchConversationsFilter';

const Conversations = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const localCondition = localStorage.getItem('conversationFilter');

    if (localCondition) {
      dispatch(getConversations(localCondition as FetchConversationsFilter));
    } else {
      dispatch(getConversations('main'));
    }
  }, [dispatch]);

  return (
    <ConversationsProvider>
      <MessagesProvider>
        <ConversationsContainer />
      </MessagesProvider>
    </ConversationsProvider>
  );
};

export default Conversations;
