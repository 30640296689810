// Components/ui
import { Box, Avatar, Typography } from '@mui/material';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
// Utils
import useSASAvatarURL from 'hooks/useSASAvatarURL';
import { Markdown } from 'functions/Markdown';

const Body = ({ conversation }: { conversation: IConversation }) => {
  const hasImageUrl = conversation?.contactInfo?.imageUrl;

  const imageURLWithAccess = hasImageUrl
    ? useSASAvatarURL(conversation?.contactInfo?.imageUrl)
    : null;

  const markdown = Markdown(conversation?.lastMessage, false);
  const newText = markdown.replace(/\n/g, '');

  return (
    <Box
      display="flex"
      flexGrow={1}
      height="100%"
      width="0"
      margin="0"
      gap={1}
      alignItems="center"
    >
      <Box>
        <Avatar
          alt={conversation?.contactInfo?.name}
          src={imageURLWithAccess}
          sx={{ width: '28px', height: '28px', color: 'text.primary' }}
        />
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1} width="20%">
        <Typography
          variant="body1"
          width="100%"
          noWrap
          fontSize={'0.875rem'}
          color="text.primary"
          fontWeight={(theme) => theme.typography.fontWeightBold}
        >
          {conversation?.contactInfo?.name}
        </Typography>
        <Box display="flex" alignItems="center" gap={1} minHeight={'15px'}>
          <Typography
            variant="body2"
            width="100%"
            noWrap
            fontSize={'0.75rem'}
            color="text.primary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              '& .emoji': {
                backgroundSize: '12px 12px',
              },
            }}
            dangerouslySetInnerHTML={{ __html: newText }} // Usa dangerouslySetInnerHTML para renderizar el HTML del markdown
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Body;
