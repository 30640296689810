import { useContext, useState, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import { TextField, Divider, FormControlLabel, Checkbox } from '@mui/material';
//Components
import {
  ActionButtons,
  Condition,
  AutomaticResponses,
  TimeOut,
  SaveAs,
} from './components';

const EditWaitResponseNode = () => {
  const { msgWaitResponseNode, setMsgWaitResponseNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [enableStatics, setEnableStatics] = useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data.msgWaitResponse) {
      const waitResponse =
        selectNode && selectNode?.data && selectNode?.data?.msgWaitResponse;
      const defaultId = waitResponse.id
        ? waitResponse?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = waitResponse.name ? waitResponse?.name : ('' as string);
      const defaultEnableStatics = waitResponse.enableStatics
        ? waitResponse?.enableStatics
        : (false as boolean);
      setId(defaultId);
      setName(defaultName);
      setEnableStatics(defaultEnableStatics);
    }
  }, [selectNode]);

  const handleChangeName = (value: string) => {
    setName(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      id,
      name: value,
    });
  };

  const handleChangeEnableStatics = (value: boolean) => {
    setEnableStatics(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      enableStatics: value,
    });
  };

  return (
    <>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStatics}
            onChange={(e) => handleChangeEnableStatics(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.enableStatics')}
      />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Condition />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <AutomaticResponses />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <TimeOut />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <SaveAs />
      <ActionButtons />
    </>
  );
};

export default EditWaitResponseNode;
