import { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
import { StyleContext } from 'style/styleProvider';
// Interface
import { AuthType } from '@trii/types/dist/Common/API';
// Utils
import { authType } from './utils';
// Components
import { Basic, BearerToken, ApiKey } from './components';

const AuthorizarionType = () => {
  const { apiNode, setApiNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [apiAuthType, setApiAuthType] = useState<AuthType>(AuthType.NONE);
  const { fontWeight } = useContext(StyleContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.api) {
      const api = selectNode.data.api;
      const defaultContactId = api.apiAuthType && (api.apiAuthType as AuthType);
      setApiAuthType(defaultContactId);
    }
  }, [selectNode]);

  const handleChangeContact = (apiAuthType: AuthType) => {
    setApiAuthType(apiAuthType);
    setApiNode({ ...apiNode, apiAuthType });
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        // color={(theme) => theme.palette.text.disabled}
        fontWeight={fontWeight.bold}
        sx={{
          mb: 1,
        }}
        color="text.primary"
      >
        {t("editFlowsView.api.authType.title")}
      </Typography>
      <FormControl fullWidth size="small" sx={{ mb: 1 }}>
        <InputLabel id="contact" shrink={true}>
          {t("editFlowsView.api.authType.security")}
        </InputLabel>
        <Select
          labelId="contact"
          id="demo-simple-select"
          value={apiAuthType}
          label={t("editFlowsView.api.authType.security")}
          onChange={(e) => handleChangeContact(e.target.value as AuthType)}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {authType.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {apiAuthType === AuthType.BASIC && <Basic />}
      {apiAuthType === AuthType.BEARER && <BearerToken />}
      {apiAuthType === AuthType.APIKEY && <ApiKey />}
    </>
  );
};

export default AuthorizarionType;
