import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeDinstarUser,
  changeDinstarPass,
  changeDinstarUrl,
  changeDinstarPort,
} from 'redux/features/smsEditSlice/smsEditSlice';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';

interface Props {
  data: {
    dinstarUser: string;
    dinstarPass: string;
    dinstarUrl: string;
    dinstarPort: string;
  };
}

const DinstarInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { dinstarUser, dinstarPass, dinstarUrl, dinstarPort } = data;

  const handleDinstarUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeDinstarUser(event.target.value));
  };
  const handleDinstarPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeDinstarPass(event.target.value));
  };
  const handleDinstarUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeDinstarUrl(event.target.value));
  };
  const handleDinstarPortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeDinstarPort(event.target.value));
  };

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label={t('global.user')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={dinstarUser}
        onChange={handleDinstarUserChange}
      />
      <TextField
        size="small"
        label={t('global.password')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={dinstarPass}
        onChange={handleDinstarPassChange}
      />
      <TextField
        size="small"
        label={'URL'}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        onChange={handleDinstarUrlChange}
        value={dinstarUrl}
      />
      <TextField
        size="small"
        label={t('global.port')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        onChange={handleDinstarPortChange}
        value={dinstarPort}
      />
    </ChannelInputsContainer>
  );
};

export default DinstarInputs;
