import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateForm from '../hooks/useCreateForm/useCreateForm';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createFormInitialState from '../hooks/useCreateForm/createFormInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { createForm, deleteForm } from 'redux/features/formSlice/formSlice';
// Types
import type { CrudPanelState } from './types';
import { FormContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const formContext = createContext<FormContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateForm: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedForm: null,
  deleteFormName: fieldInitialDef,
  createFormState: createFormInitialState,
  handleDeleteForm: () => {},
  navigateToEdit: () => {},
});

const FormProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedForm, setSelectedForm] = useState<any>(null);
  // Create state
  const createFormState = useCreateForm();
  // Delete state
  const deleteFormName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateForm = async () => {
    const newForm = createFormState.actions.getNewForm();
    await dispatch(createForm(newForm));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createFormState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteForm = async () => {
    const { id } = selectedForm;

    await dispatch(deleteForm(id));
    endDeleting();
  };

  const openDelete = (form: any) => {
    setSelectedForm(form);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteFormName.actions.resetValue();
    setSelectedForm(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (formId: string) => {
    navigate(`/a/conversations/system/forms/${formId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <formContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateForm,
        openDelete,
        endDeleting,
        deleteFormName,
        createFormState,
        selectedForm,
        handleDeleteForm,
        navigateToEdit,
      }}
    >
      {children}
    </formContext.Provider>
  );
};

export default FormProvider;
