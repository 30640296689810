import React, { useContext, useEffect } from 'react';
import { formContext } from 'features/Views/Form/context/FormProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, InputLabel, TextField, Divider } from '@mui/material';
import { CrudContainer } from 'components';
import { PanelHeader } from 'components';
import { DeleteButtons } from './components';

function DeletePanel() {
  const { t } = useTranslation();
  const { deleteFormName, endDeleting, selectedForm } = useContext(formContext);

  useEffect(() => {
    const cleanUp = () => {
      deleteFormName.actions.resetValue();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('formsView.deletionPanel.title')}
        handleCloseButton={endDeleting}
      />
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'center'}
        p={2}
      >
        <InputLabel
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {t('formsView.deletionPanel.description')}
          <strong style={{ marginLeft: 3 }}>{selectedForm?.name}</strong>
        </InputLabel>
        <TextField
          {...deleteFormName.attributes}
          className="fadein"
          variant="outlined"
          size="small"
          fullWidth
          label={t('global.name')}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            marginTop: '15px',
          }}
        />
      </Box>
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <DeleteButtons />
    </CrudContainer>
  );
}

export default DeletePanel;
