import React, { useEffect } from 'react';
// Types
import { UseCreateWebHook } from 'features/Views/WebHooks/hooks/useCreateWebHook/types.js';
// Components/ui
import { Box } from '@mui/material';
import { GeneralSection, ApiSection } from './components/index.js';

interface CreateEditPanelProps {
  webHookState: UseCreateWebHook;
}

const CreateEditPanel = ({ webHookState }: CreateEditPanelProps) => {
  // const { isEditing } = useContext(webHooksContext);

  useEffect(() => {
    const cleanUp = () => {
      // if (!isEditing) endCreating();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{

      maxHeight: 'calc(100vh - 240px)', overflow: 'auto' }}>
      <GeneralSection nameFieldState={webHookState.input.nameField} />
      <ApiSection
        methodSelectState={webHookState.input.methodSelect}
        urlFieldState={webHookState.input.urlField}
        authTypeSelectState={webHookState.input.authTypeSelect}
        paramsBodyFieldState={webHookState.input.paramsBodyField}
        paramsContentTypeFieldState={webHookState.input.paramsContetTypeField}
        usernameFieldState={webHookState.input.usernameField}
        passwordFieldState={webHookState.input.passwordField}
        fixedTokenFieldState={webHookState.input.fixedTokenField}
        dynamicUrlFieldState={webHookState.input.dynamicUrlField}
        bearerTokenBodyFieldState={webHookState.input.bearerTokenBodyField}
        apiKeyFieldState={webHookState.input.apiKeyField}
        apiValueFieldState={webHookState.input.apiValueField}
      />
    </Box>
  );
};

export default CreateEditPanel;
