import { Box, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { Card as TremorCard, Metric, Title } from "@tremor/react";
import { IChannelInfo } from "@trii/types/dist/Common/Channels";
import getImage from "functions/getImage";

interface Props {
  title: string;
  isLoading: boolean;
  isDetail?: boolean;
  quantity?: number;
  data?: IChannelInfo[];
  handleOpen?: (channels: IChannelInfo[]) => void;
}

const Card = ({
  title,
  isLoading,
  isDetail = false,
  quantity = 0,
  data,
  handleOpen,
}: Props) => {
  return (
    <Box
      component={TremorCard}
      sx={{
        // @ts-ignore
        backgroundColor: theme => theme.palette.background.panel,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        color: theme => theme.palette.text.primary,
      }}
    >
      <Title>{title}</Title>
      {
        isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : isDetail ? (
          <Metric
            className="text-center cursor-pointer text-2xl"
            onClick={isDetail && (() => handleOpen(data || []))}
          >
            <List
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
              }}
            >
              {
                data && data.length > 0 && data.map((item, index) => (
                  <ListItem
                    key={index}
                  >
                    <ListItemIcon>
                      {getImage(item.type).icon}
                    </ListItemIcon>
                    <ListItemText>
                      {item.name}
                    </ListItemText>
                  </ListItem>
                ))
              }
            </List>
          </Metric>
        ) : (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {quantity}
          </Typography>
        )
      }
    </Box >
  )
}

export default Card