// Types
import { AssignMethod } from '@trii/types/dist/Conversations';

export const selectAssignMethodItems = [
  { value: AssignMethod.ALL, label: 'ALL' },
  { value: AssignMethod.ROUND_ROBIN, label: 'ROUND ROBIN' },
  { value: AssignMethod.LEAST_BUSY, label: 'LEAST BUSY' },
  { value: AssignMethod.MOST_BUSY, label: 'MOST BUSY' },
  { value: AssignMethod.RANDOM, label: 'RANDOM' },
];
