import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewFlow } from 'redux/features/flowSlice/types/NewFlow';

const createFlowInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewFlow: () => ({} as NewFlow),
  },
};
export default createFlowInitialState;
