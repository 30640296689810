import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
import { BodyDashboard } from '@trii/types/dist/Conversations';
import { DataDashboard } from './types/DataDashboard';
import { DashboardForm } from './types/DashboardForm';

const postGeneral = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/general`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postRecords = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/records`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postEndings = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/endings`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postChannels = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/channels`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postLabels = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/labels`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postTimes = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/times`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postQuantity = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/quantity`,
    dataJson,
    requestConfig
  );
  return response.data;
};
const postMembers = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(
    `${URL}/Dashboard/members`,
    dataJson,
    requestConfig
  );
  return response.data;
};

export const postAll = async (jwt: string, URL: string, data: BodyDashboard) => {
  const postFunctions = [
    postGeneral,
    postRecords,
    postEndings,
    postMembers,
    postChannels,
    postQuantity,
    postTimes,
    postLabels,
  ];
  const promises = postFunctions.map((postFunction) => postFunction(jwt, URL, data));
  const results = await Promise.all(promises);
  return results;
};

const fetchLogs = async (jwt: string, URL: string, data: DataDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(`${URL}/Dashboard/logs`, dataJson, requestConfig);
  return response.data;
}

// Dashboard Forms
const fetchSummary = async (jwt: string, URL: string, data: DashboardForm) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(`${URL}/DashboardForms/summary`, dataJson, requestConfig);
  return response.data;
}

const fetchSubmittedForms = async (jwt: string, URL: string, data: DashboardForm) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(`${URL}/DashboardForms/submittedforms`, dataJson, requestConfig);
  return response.data;
}

const fetchDownloadForms = async (jwt: string, URL: string, data: DashboardForm) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);

  const response = await axios.post(`${URL}/DashboardForms/downloadsubmittedforms`, dataJson, requestConfig);
  return response.data;
}

// Dashboard Channels
const fetchSummaryChannels = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/DashboardChannels/summary`, requestConfig);
  return response.data;
}

const fetchLogsChannels = async (jwt: string, URL: string, chanelId: string) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  
  const response = await axios.get(`${URL}/DashboardChannels/logs?channelId=${chanelId}`, requestConfig);
  return response.data;
}

const fetchSummaryMessages = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.post(`${URL}/DashboardMessages/summary`, dataJson, requestConfig);
  return response.data;
}
const fetchSummaryGeneral = async (jwt: string, URL: string, data: BodyDashboard) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.post(`${URL}/Dashboard/general`, dataJson, requestConfig);
  return response.data;
}

const fetchLogsMessages = async (jwt: string, URL: string, messageId: string) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);

  const response = await axios.get(`${URL}/DashboardMessages/logs?channelId=${messageId}`, requestConfig);
  return response.data;
}

const dashboardService = {
  postAll,
  postGeneral,
  fetchLogs,
  // Dashboard Channels
  fetchSummary,
  fetchSubmittedForms,
  fetchDownloadForms,
  // Dashboard Channels
  fetchSummaryChannels,
  fetchLogsChannels,
  // Dashboard Messages
  fetchSummaryMessages,
  fetchLogsMessages,
  fetchSummaryGeneral
};
export default dashboardService;