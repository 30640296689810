// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Typography } from "@mui/material"
// Icons
import { Handyman } from "@mui/icons-material"
import { Property } from "@trii/types/dist/Contacts"

interface Props {
  properties: Property[];
}

const Properties = ({
  properties,
}: Props) => {
  const { t } = useTranslation()

  const getProprertyTitle = (title: string) => {
    const newTitle = title.replace(/-/g, ' ')
    return newTitle
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "max-content",
        alignItems: "center",
        gap: 1,
      }}
    >

      <Box
        sx={{
          width: "100%",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.divider,
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="semi-bold"
          color={"text.disabled"}
        >
          {t("conversations.contactInfo.properties")}
        </Typography>
      </Box>
      {
        properties && properties?.length > 0 && properties?.map((property: Property, i) => (
          <Box
            key={i}
            sx={{
              width: "100%",
              height: "max-content",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Handyman
              fontSize="small"
              sx={{
                color: (theme) => theme.palette.primary.dark,
              }}
            />
            <Typography
              variant="subtitle1"
              fontWeight="semi-bold"
              fontSize={14}
              color={"text.disabled"}
            >
              {getProprertyTitle(property.nameKey)}: {property.value}
            </Typography>
          </Box>
        ))
      }
    </Box>
  )
}

export default Properties