// Router
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  CardContent,
  Card as MuiCard,
  Typography
} from "@mui/material";

interface CardProps {
  title: string;
  handleNavigate: () => void;
}

const Card = ({
  title,
  handleNavigate,
}: CardProps) => (
  <MuiCard
    sx={{
      width: '80%',
      height: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //@ts-ignore
      backgroundColor: theme => theme.palette.background.panelHeader
    }}
  >
    <CardActionArea
      sx={{
        width: '100%',
        height: '100%',
      }}
      onClick={handleNavigate}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </CardActionArea>
  </MuiCard>
)

export default Card;