// Components/ui
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

interface MenuItemProps {
  onClick: (event: React.MouseEvent) => void;
  icon: React.ReactNode;
  text: string;
}

const MenuItem = ({ onClick, icon, text }: MenuItemProps) => (
  <ListItem sx={{ padding: 0 }}>
    <ListItemButton onClick={onClick}>
      <ListItemIcon sx={{ color: 'text.disabled' }}>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItemButton>
  </ListItem>
);

export default MenuItem;
