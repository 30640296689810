import { Box, CircularProgress, Typography } from '@mui/material';
import { MessageLocation } from '@trii/types/dist/Common/Messages';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import useSAS from 'hooks/useSAS';
import { useEffect, useState } from 'react';
interface Props {
  locations: MessageLocation[];
  messageId: string;
}
const Map = ({ locations, messageId }: Props) => {
  const [imageURLWithAccess, setImageURLWithAccess] = useState('');
  const openGoogleMap = () => {
    if (locations[0]?.lat && locations[0]?.lng) {
      const googleMapsUrl = `https://www.google.com/maps/place/${locations[0].lat},${locations[0].lng}`;

      window.open(googleMapsUrl, '_blank');
    }
  };

  const URLHandler = useSAS();

  if (!locations) {
    // Puedes mostrar un mensaje de carga o un indicador mientras se obtiene la ubicación
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  useEffect(() => {
    if (locations[0]?.url) {
      URLHandler.getURLWithAccessToken(locations[0].url).then((url) => {
        setImageURLWithAccess(url);
      });
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '270px',
        }}
      >
        {/* {
          (isLoading || (isLoaded !== undefined && !isLoaded))
          && uploadImgStatus !== 'succeeded' && (
            <Loader
              handleTryAgain={handleTryAgain}
              isLoading={isLoading}
              messageId={messageId}
              isLoaded={isLoaded}
            />
          )
        } */}
        <a target="_blank" rel="noopener noreferrer" onClick={openGoogleMap}>
          <img
            src={imageURLWithAccess}
            alt="imagenLocation"
            width={270}
            height={270}
            style={{ borderRadius: '5px', cursor: 'pointer' }}
          />
        </a>
        <Typography
          sx={{
            fontSize: '11px',
            color: (theme) => theme.palette.text.primary,
            mt: 1,
          }}
        >
          {locations[0]?.title || ''}
        </Typography>
        <Typography
          sx={{
            fontSize: '11px',
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {locations[0]?.description || ''}
        </Typography>
      </Box>
      {/* {
        files.length <= 1 && (
          <Typography
            variant="caption"
            sx={{
              fontSize: "13px",
              wordBreak: "break-word",
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'start',
              color: (theme) => theme.palette.text.primary 
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )
      } */}
    </Box>
  );
};

export default Map;
