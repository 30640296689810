import React, { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { StyleContext } from 'style/styleProvider';
//components/ui
import { Box, Typography, useTheme } from '@mui/material';
// Utils
import { serviceSelector } from '../utils';

const TagList = () => {
  const { fontSize } = useContext(StyleContext);
  const { t } = useTranslation();
  const onDragStart = (event, type) => {
    event.dataTransfer.setData('application/reactflow', type);
    event.dataTransfer.effectAllowed = 'move';
  };
  const theme = useTheme()
  
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {serviceSelector?.map((service) => (
        <Typography
          key={service.id}
          sx={{
            margin: '3px',
            padding: '3px',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '0.5rem',
            cursor: 'pointer',
            color: theme.palette.text.primary
          }}
          onDragStart={(event) => onDragStart(event, service.type)}
          fontSize={fontSize.xs}
          variant="body2"
          draggable
          width="100%"
        >
          {t(service.name)}
        </Typography>
      ))}
    </Box>
  );
};

export default TagList;
