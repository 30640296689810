import { useTranslation } from 'react-i18next';
// Components/ui
import mailboxImage from './utils/mailbox.png';
import StepContainer from '../StepContainer';
import { Box, Typography, TextField, useTheme } from '@mui/material';
// Types
import { UseFieldType } from 'hooks/useField';

type FirstStepProps = {
  emailInput: UseFieldType;
};

const FirstStep = ({ emailInput }: FirstStepProps) => {
  const { t } = useTranslation();
  const theme = useTheme()
  
  return (
    <StepContainer>
      <Box display={"flex"} flexDirection={"column"} gap={5}>
        <Box textAlign={"center"} display={"flex"} flexDirection={"column"}>
          <Typography variant="h6" fontSize={18}>
            {t("emailEditView.incomingEmailPanel.setUpModal.enterEmailTitle")}
          </Typography>
          <Typography variant="subtitle1">
            {t(
              "emailEditView.incomingEmailPanel.setUpModal.enterEmailSubtitle"
            )}
          </Typography>
        </Box>
        <TextField {...emailInput.attributes} size="small" fullWidth />
        <Box
          display={"flex"}
          justifyContent={"space-evenly"}
          py={2}
          sx={{
            //@ts-ignore
            backgroundColor: theme.palette.background.dropdownMenu,
          }}
        >
          <img src={mailboxImage} alt="mailbox" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            py={1}
            width={"75%"}
          >
            <Typography variant="subtitle1" fontWeight={500}>
              {t("emailEditView.incomingEmailPanel.setUpModal.oneInboxToShare")}
            </Typography>
            <Typography variant="body2">
              {t(
                "emailEditView.incomingEmailPanel.setUpModal.emailsSentToThisAddress"
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StepContainer>
  );
};

export default FirstStep;
