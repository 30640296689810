import { useContext, useEffect, useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import { Box, Typography, useTheme } from "@mui/material";
// Components
import { Card, LogModal } from "./components";
// Context
import { DashboardContext } from "../../context/DashboardContext";
// Selector
import { dashboardMessagesSummary, dashboardMessagesSummaryStatus } from "redux/features/dashboardSlice/dashboardSlice";
// Types
import { DashBoardMessagesSummaryInfo } from "@trii/types/dist/Conversations";

const Messages = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getSummaryMessages } = useContext(DashboardContext);
  const newMessagesSummary = useSelector(dashboardMessagesSummary);
  const messagesSummaryStatus = useSelector(dashboardMessagesSummaryStatus);
  const isLoading = messagesSummaryStatus === "loading";
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [messagesSummary, setMessagesSummary] = useState<DashBoardMessagesSummaryInfo>();
  const [channelId, setChannelId] = useState<string>('');

  const handleClose = () => {
    setOpenModal(false);
  }

  const handleSelectChannel = (channelId: string) => {
    console.log('channelId', channelId)
    setChannelId(channelId);
    setOpenModal(true);
  }


  useEffect(() => {
    if (newMessagesSummary) {
      setMessagesSummary(newMessagesSummary);
    }
  }, [newMessagesSummary]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 4rem)",
        overflow: "auto",
        width: "100%",
        alignItems: "flex-start",
        p: 2,
        gap: '1rem',
      }}
    >
      {
        messagesSummary && messagesSummary?.records?.length > 0 && messagesSummary?.records.map((record, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '.5rem',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'center',
              boxShadow: theme.shadows[1],
              borderRadius: '1rem',
              backgroundColor: theme.palette.background.default,
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              {record.channelName}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: "repeat(4, minmax(200px, 1fr))",
                gridColumnGap: "1rem",
                width: '100%',
              }}
            >
              <Card
                key={index}
                title={t('dashboard.messages.credits')}
                quantity={record.credits}
                color={theme.palette.text.primary}
                isLoading={isLoading}
                handleSelect={() => handleSelectChannel(record.channelId)}
              />
              <Card
                key={index}
                title={t('dashboard.messages.delivered')}
                quantity={record.delivered}
                color={theme.palette.grey[500]}
                isLoading={isLoading}
                handleSelect={() => handleSelectChannel(record.channelId)}
              />
              <Card
                key={index}
                title={t('dashboard.messages.sent')}
                quantity={record.sent}
                color={theme.palette.success.main}
                isLoading={isLoading}
                handleSelect={() => handleSelectChannel(record.channelId)}
              />
              <Card
                key={index}
                title={t('dashboard.messages.failed')}
                quantity={record.failed}
                color={theme.palette.error.main}
                isLoading={isLoading}
                handleSelect={() => handleSelectChannel(record.channelId)}
              />
            </Box>
          </Box>
        ))
      }
      <LogModal
        open={openModal}
        onClose={handleClose}
        channelId={channelId}
      />
    </Box>
  )
}

export default Messages;