import { useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { ConfirmationModal } from './components';
import { IContact } from '@trii/types/dist/Contacts';
import {
  fetchContactInfo,
  fetchConvertBusinessToContact,
  fetchConvertContactToBusiness,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  contactInfo: IContact;
  setContactInfo: (contactInfo: IContact) => void;
}

type Action = 'changeBusiness' | 'joinContact' | 'changeContact' | null;

const MoreActions = ({
  open,
  anchorEl,
  handleClose,
  contactInfo,
  setContactInfo,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [actionType, setActionType] = useState<Action>(null);

  const handleModal = (type: Action) => {
    setOpenModal(!openModal);
    setActionType(type);
    handleClose();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setActionType(null);
  };
  const handleTransformToBusiness = async () => {
    if (contactInfo) {
      try {
        const data = {
          id: contactInfo.id,
          isBusiness: true,
        };
        await dispatch(fetchConvertContactToBusiness(data));

        const updatedContact = await dispatch(fetchContactInfo(contactInfo.id));
        const updatedContactJSON = JSON.parse(
          JSON.stringify(updatedContact.payload)
        );

        setContactInfo(updatedContactJSON);
        setOpenModal(false);
        setActionType(null);
      } catch (error) {
        console.error('Error converting to contact:', error);
      }
    }
  };
  const handleTransformToContact = async () => {
    if (contactInfo) {
      try {
        const data = {
          id: contactInfo.id,
          isBusiness: false,
        };
        await dispatch(fetchConvertBusinessToContact(data));
        const updatedContact = await dispatch(fetchContactInfo(contactInfo.id));
        const updatedContactJSON = JSON.parse(
          JSON.stringify(updatedContact.payload)
        );

        setContactInfo(updatedContactJSON);
        setOpenModal(false);
        setActionType(null);
      } catch (error) {
        console.error('Error converting to business:', error);
      }
    }
  };

  const navigateToDuplicates = () => {
    navigate(`/a/contacts/duplicates?contactId=${contactInfo.id}`);
  };

  const handleListItemClick = () => {
    navigateToDuplicates();
    handleClose();
  };

  const handleIconButtonClick = (event) => {
    event.stopPropagation();
    window.open(`/a/contacts/duplicates?contactId=${contactInfo.id}`, '_blank');
    handleClose();
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List
          disablePadding
          sx={{
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
          }}
        >
          <ListItem disablePadding divider>
            {
              //@ts-ignore
              contactInfo?.isBusiness ? (
                <ListItemButton onClick={() => handleModal('changeContact')}>
                  <ListItemText
                    primary={t('conversations.contactInfo.more.changeContact')}
                  />
                </ListItemButton>
              ) : (
                <ListItemButton onClick={() => handleModal('changeBusiness')}>
                  <ListItemText
                    primary={t('conversations.contactInfo.more.changeBusiness')}
                  />
                </ListItemButton>
              )
            }
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleListItemClick}>
              <ListItemText
                primary={t('conversations.contactInfo.more.joinContact')}
              />
              <IconButton edge="end" onClick={handleIconButtonClick}>
                <OpenInNewIcon />
              </IconButton>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <ConfirmationModal
        title={
          actionType === 'changeBusiness'
            ? t('conversations.contactInfo.more.confirmAction.changeBusiness')
            : actionType === 'changeContact'
            ? t('conversations.contactInfo.more.confirmAction.changeContact')
            : t('conversations.contactInfo.more.confirmAction.joinContact', {
                contact: 'contact name',
                contactToJoin: 'contact to join',
              })
        }
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={
          actionType === 'changeBusiness'
            ? () => handleTransformToBusiness()
            : actionType === 'changeContact'
            ? () => handleTransformToContact()
            : null
        }
      />
    </>
  );
};

export default MoreActions;
