import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeTwilioAccountSid,
  changeTwilioAuthToken,
  changeTwilioNumber,
} from 'redux/features/whatsAppEditSlice/whatsAppEditSlice';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';

interface Props {
  data: {
    twilioAccountSid: string;
    twilioAuthToken: string;
    twilioNumber: string;
  };
}

const TwiloInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { twilioAccountSid, twilioAuthToken, twilioNumber } = data;

  const handleTwilioAccountSidChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeTwilioAccountSid(event.target.value));
  };
  const handleTwilioAuthTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeTwilioAuthToken(event.target.value));
  };
  const handlechangeTwilioNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeTwilioNumber(event.target.value));
  };

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label={t('whatsAppEditView.conectionsPanel.accountId')}
        InputLabelProps={{
          shrink: true,
        }}
        value={twilioAccountSid}
        sx={inputFieldStyle}
        onChange={handleTwilioAccountSidChange}
      />
      <TextField
        size="small"
        label="Token"
        InputLabelProps={{
          shrink: true,
        }}
        value={twilioAuthToken}
        sx={inputFieldStyle}
        onChange={handleTwilioAuthTokenChange}
      />
      <TextField
        size="small"
        label={t('global.number')}
        InputLabelProps={{
          shrink: true,
        }}
        value={twilioNumber}
        sx={inputFieldStyle}
        onChange={handlechangeTwilioNumberChange}
      />
    </ChannelInputsContainer>
  );
};

export default TwiloInputs;
