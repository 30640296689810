// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
// Icons
import { Campaign, Star, StarBorder } from '@mui/icons-material';
// Types
import { useEffect, useState } from 'react';
import { ModalSubscription } from './components';
import { IContact, IContactAddress } from '@trii/types/dist/Contacts';
import { SetFavoriteAddressData } from 'redux/features/contactInfoSlice/types/SetFavoriteAddressData';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setFavoriteAddress } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { Dispatch, SetStateAction } from 'react';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import axios from 'axios';
interface ContactProps {
  contactList: IContactAddress[];
  icon: React.ReactNode;
  title: string;
  showCampaign?: boolean;
  showTitle?: boolean;
  contactId: string;
  setContactInfo: Dispatch<SetStateAction<IContact>>;
  contactInfo: IContact;
  type: ChannelType;
}

const Contact = ({
  contactList,
  icon,
  title,
  showCampaign = false,
  showTitle = true,
  contactId,
  setContactInfo,
  contactInfo,
  type,
}: ContactProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showModalSubs, setShowModalSubs] = useState(false);
  const [contactDataModal, setContactDataModal] = useState<IContactAddress>();

  const iconStyle = { color: 'text.disabled' };

  const openModalSubs = (data: IContactAddress) => {
    setContactDataModal(data);
    setShowModalSubs(true);
  };
  const closeModalSubs = () => {
    setShowModalSubs(false);
  };

  const handleCallContact = (address: string) => {
    if (!address) return;

    const event = new CustomEvent('call-contact-conversations', {
      detail: { phoneAddress: address },
    });

    window.dispatchEvent(event);
  };

  const handleSetFavorite = async (contactAddressId: string, value: boolean) => {
    const data: SetFavoriteAddressData = {
      contactId,
      contactAddressId,
      value,
    };

    const res = await dispatch(setFavoriteAddress(data));

    if (res.payload === 'OK') {
      switch (type) {
        case ChannelType.PHONE:
          const newPhones = contactInfo.phones.map((phone) => {
            if (phone.id === contactAddressId) {
              return { ...phone, isFavorite: value };
            }
            return phone;
          });

          setContactInfo({
            ...contactInfo,
            phones: newPhones,
          });
          break;

        case ChannelType.EMAIL:
          const newEmails = contactInfo.emails.map((email) => {
            if (email.id === contactAddressId) {
              return { ...email, isFavorite: value };
            }
            return email;
          });

          setContactInfo({
            ...contactInfo,
            emails: newEmails,
          });
          break;

        case ChannelType.WHATSAPP:
          const newWhatsapp = contactInfo.ims_whatsapp.map((whatsapp) => {
            if (whatsapp.id === contactAddressId) {
              return { ...whatsapp, isFavorite: value };
            }
            return whatsapp;
          });

          setContactInfo({
            ...contactInfo,
            ims_whatsapp: newWhatsapp,
          });
          break;

        case ChannelType.FACEBOOK:
          const newFacebook = contactInfo.ims_facebook.map((facebook) => {
            if (facebook.id === contactAddressId) {
              return { ...facebook, isFavorite: value };
            }
            return facebook;
          });

          setContactInfo({
            ...contactInfo,
            ims_facebook: newFacebook,
          });
          break;

        case ChannelType.INSTAGRAM:
          const newInstagram = contactInfo.ims_instagram.map((instagram) => {
            if (instagram.id === contactAddressId) {
              return { ...instagram, isFavorite: value };
            }
            return instagram;
          });

          setContactInfo({
            ...contactInfo,
            ims_instagram: newInstagram,
          });
          break;

        default:
          break;
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {showTitle && (
        <Box
          sx={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="semi-bold"
            color={'text.disabled'}
          >
            {t(title)}
          </Typography>
        </Box>
      )}
      {contactList.length > 0 &&
        contactList.map((contact: IContactAddress) => (
          <Box
            key={contact.id}
            sx={{
              width: '100%',
              height: 'max-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: 'max-content',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                maxWidth: '60%',
              }}
            >
              {type === ChannelType.PHONE ? (
                <IconButton onClick={() => handleCallContact(contact.address)}>
                  {icon}
                </IconButton>
              ) : (
                icon
              )}
              <Tooltip title={contact.address} arrow>
                <Typography
                  variant="subtitle1"
                  fontWeight="semi-bold"
                  fontSize={14}
                  color={'text.disabled'}
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    cursor: 'default',
                  }}
                >
                  {contact.address}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: 'max-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {showCampaign && (
                <Tooltip
                  title={t('conversations.contactInfo.suscriptions')}
                  placement="top"
                  arrow
                >
                  <IconButton size="small" onClick={() => openModalSubs(contact)}>
                    <Campaign sx={iconStyle} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={() => handleSetFavorite(contact.id, !contact.isFavorite)}
                size="small"
              >
                {contact.isFavorite ? (
                  <Star
                    sx={{
                      color: 'yellow',
                    }}
                  />
                ) : (
                  <StarBorder sx={iconStyle} />
                )}
              </IconButton>
            </Box>
          </Box>
        ))}
      <ModalSubscription
        openModal={showModalSubs}
        onClose={closeModalSubs}
        icon={icon}
        contactAddress={contactDataModal}
      />
    </Box>
  );
};

export default Contact;
