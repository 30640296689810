import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';

// Component/ui
import { TextField } from '@mui/material';

const LongAnswerInput = () => {
  const { t } = useTranslation();

  return (
    <TextField
      disabled
      sx={{
        width: '85%',
        '& .MuiInputLabel-root': {
          fontSize: '0.8rem',
          color: 'text.secondary',
        },
        mt: 1,
      }}
      variant="standard"
      defaultValue={t('formEditView.designPanel.paragraphText')}
      size="small"
    />
  );
};

export default LongAnswerInput;
