import React, { useContext } from 'react';
import { webHooksContext } from 'features/Views/WebHooks/context/WebHooksProvider';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectWebHookUpdateStatus } from 'redux/features/webHooksSlice/webHooksSlice';
// Components/ui
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';

function EditButtons() {
  const { t } = useTranslation();
  const { endEditing, handleEditWebHook } = useContext(webHooksContext);
  const webHookEditStatus = useSelector(selectWebHookUpdateStatus);
  const isLoading = webHookEditStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      width="100%"
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Box display={'flex'}>
        <Button
          variant="outlined"
          size="small"
          sx={{
            marginRight: '15px',
          }}
          onClick={endEditing}
        >
          {t('global.cancel')}
        </Button>
        <LoadingButton
          startIcon={<SaveAsIcon />}
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          size="small"
          onClick={handleEditWebHook}      
        >
          {t('global.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default EditButtons;
