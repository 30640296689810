import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreatePbx from '../hooks/useCreatePbx/useCreatePbx';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createPbxInitialState from '../hooks/useCreatePbx/createPbxInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { createPbx, deletePbx } from 'redux/features/pbxSlice/pbxSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Types
import type { CrudPanelState } from './types';
import { PbxContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const pbxContext = createContext<PbxContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreatePbx: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedPbx: null,
  deletePbxName: fieldInitialDef,
  createPbxState: createPbxInitialState,
  handleDeletePbx: () => {},
  navigateToEdit: () => {},
});

const PbxProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedPbx, setSelectedPbx] = useState<any>(null);
  // Create state
  const createPbxState = useCreatePbx();
  // Delete state
  const deletePbxName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreatePbx = async () => {
    const newPbx = createPbxState.actions.getNewPbx();
    await dispatch(createPbx(newPbx));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createPbxState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeletePbx = async () => {
    const { id } = selectedPbx;

    await dispatch(deletePbx(id));
    endDeleting();
  };

  const openDelete = (pbx: any) => {
    setSelectedPbx(pbx);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deletePbxName.actions.resetValue();
    setSelectedPbx(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (pbxId: string) => {
    navigate(`/a/conversations/system/pbx/${pbxId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <pbxContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreatePbx,
        openDelete,
        endDeleting,
        deletePbxName,
        createPbxState,
        selectedPbx,
        handleDeletePbx,
        navigateToEdit,
      }}
    >
      {children}
    </pbxContext.Provider>
  );
};

export default PbxProvider;
