import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography, Box, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// Icons
import InfoIcon from '@mui/icons-material/Info';

const Body = () => {
  const { selectNode, setSendEmailNode, sendEmailNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [body, setBody] = useState<string>('');
  const TooltipPers = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // @ts-ignore
      backgroundColor: theme.palette.background.tooltip,
    },
    [`& .${tooltipClasses.arrow}`]: {
      // @ts-ignore
      color: theme.palette.background.tooltip,
    },
  })) as typeof Tooltip;
  useEffect(() => {
    if (selectNode && selectNode.data.sendEmail) {
      setBody(selectNode.data.sendEmail.body ? selectNode.data.sendEmail.body : '');
    }
  }, [selectNode]);

  const handleChangeQuery = (body: string) => {
    setBody(body);
    setSendEmailNode({
      ...sendEmailNode,
      body,
    });
  };

  return (
    <>
      <Box display="flex" gap={0.2} alignItems="center">
        <TooltipPers
          arrow
          title={t('editFlowsView.sendEmail.bodyTooltip')}
          placement="top"
         
        >
          <InfoIcon
            style={{ fontSize: 'medium' }}
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </TooltipPers>
        <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
          {t('editFlowsView.sendEmail.body')}
        </Typography>
      </Box>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={body}
        onChange={(e) => handleChangeQuery(e.target.value)}
        multiline
        rows={4}
      />
    </>
  );
};

export default Body;
