import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
//Types
import { FormEditState } from './types/FormEditState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../store';
import {
  FormItem,
  FormItemType,
  IChannel,
  Permission,
} from '@trii/types/dist/Common/Channels';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import { ChangeQuestionItemPayload } from './types/ChangeQuestionItemPayload';
import { ChangeQuestionItemTypePayload } from './types/ChangeQuestionItemTypePayload';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import { ChangeQuestionLinearScaleInputPayload } from './types/ChangeQuestionLinearScaleInputPayload';
import { ChangeQuestionLinearScaleTitleInputPayload } from './types/ChangeQuestionLinearScaleTitleInputPayload';
//Service
import formEditService from './formEditService';
import { AssignMethod } from '@trii/types/dist/Conversations';
import { UserInfo } from '@trii/types/dist/Users';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';

const initialState: FormEditState = {
  channel: null,
  channelName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchForm = createAsyncThunk(
  'formEdit/fetchForm',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await formEditService.fetchForm(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateForm = createAsyncThunk<IChannel, void, { state: RootState }>(
  'formEdit/updateForm',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const form = getState().FormEdit.channel;
    await formEditService.updateForm(jwtToken, URL_CONVERSATIONS, form);

    return form;
  }
);

const formEditSlice = createSlice({
  name: 'formEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.form.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.form.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.form.reassignGroupId = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.channel.form.schedule = schedule;
    },
    addPermission: {
      prepare: (data: {
        groupId?: string;
        userId?: string;
      }): { payload: Permission } => ({
        payload: {
          groupId: data.groupId || null,
          readMessages: false,
          startConversations: false,
          userId: data.userId || null,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(
      state,
      action: PayloadAction<{ groupId?: string; userId?: string }>
    ) {
      let index = -1;

      if (action.payload.groupId) {
        index = state.channel.permissions.findIndex(
          (permission) => permission.groupId === action.payload.groupId
        );
      } else {
        index = state.channel.permissions.findIndex(
          (permission) => permission.userId === action.payload.userId
        );
      }

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.readMessages = readMessages;
      } else if (permissionByUserId) {
        permissionByUserId.readMessages = readMessages;
      }
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.startConversations = startConversations;
      } else if (permissionByUserId) {
        permissionByUserId.startConversations = startConversations;
      }
    },
    changeFormTitle(state, action: PayloadAction<string>) {
      state.channel.form.title = action.payload;
    },
    changeFormDescription(state, action: PayloadAction<string>) {
      state.channel.form.subTitle = action.payload;
    },
    changeFormSuccessMessage(state, action: PayloadAction<string>) {
      state.channel.form.successMessage = action.payload;
    },
    changeFormColor(state, action: PayloadAction<string>) {
      state.channel.form.color = action.payload;
    },
    changeAssignMethod(state, action: PayloadAction<AssignMethod>) {
      state.channel.assignMethod = action.payload;
    },
    addQuestionItem(state) {
      const newQuestionItem: FormItem = {
        id: Math.random().toString(36).substr(2, 9),
        order: state.channel.form.items.length,
        question: '',
        type: FormItemType.SHORT_ANSWER,
        required: false,
        checkboxList: [],
        dropdownList: [],
        severalOptionList: [],
        linearScale: {
          from: 0,
          fromTitle: '',
          to: 2,
          toTitle: '',
          style: 1,
        },
      };

      state.channel.form.items.push(newQuestionItem);
    },
    changeQuestionItem(state, action: PayloadAction<ChangeQuestionItemPayload>) {
      const { id, newQuestionData } = action.payload;
      const { key, data } = newQuestionData;
      const questionItem = state.channel.form.items.find((item) => item.id === id);

      if (key === 'order' && typeof newQuestionData.data === 'number') {
        const index = state.channel.form.items.findIndex((item) => item.id === id);
        const newIndex = newQuestionData.data;

        state.channel.form.items.splice(index, 1);
        state.channel.form.items.splice(newIndex, 0, questionItem);
        state.channel.form.items.forEach((item, index) => {
          item.order = index;
        });
      }
      if (key === 'question' && typeof data === 'string') {
        questionItem.question = data;
      }
      if (key === 'required' && typeof data === 'boolean') {
        questionItem.required = data;
      }
    },
    changeAssignTo(
      state,
      action: PayloadAction<{ group: GroupInfo; user: UserInfo }>
    ) {
      state.channel.assignTo = {
        groupInfo: action.payload.group,
        userInfo: action.payload.user,
      };
    },
    deleteQuestionItem(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.channel.form.items.findIndex((item) => item.id === id);

      state.channel.form.items.splice(index, 1);
      state.channel.form.items.forEach((item, index) => {
        item.order = index;
      });
    },
    changeQuestionItemType(
      state,
      action: PayloadAction<ChangeQuestionItemTypePayload>
    ) {
      const { id, newType } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);

      questionItem.type = newType;
    },
    changeQuestionLinearScaleInputFrom(
      state,
      action: PayloadAction<ChangeQuestionLinearScaleInputPayload>
    ) {
      const { id, value } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);
      questionItem.linearScale.from = value;
    },
    changeQuestionLinearScaleInputTo(
      state,
      action: PayloadAction<ChangeQuestionLinearScaleInputPayload>
    ) {
      const { id, value } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);
      questionItem.linearScale.to = value;
    },
    changeQuestionLinearScaleInputFromTitle(
      state,
      action: PayloadAction<ChangeQuestionLinearScaleTitleInputPayload>
    ) {
      const { id, value } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);
      questionItem.linearScale.fromTitle = value;
    },
    changeQuestionLinearScaleInputToTitle(
      state,
      action: PayloadAction<ChangeQuestionLinearScaleTitleInputPayload>
    ) {
      const { id, value } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);
      questionItem.linearScale.toTitle = value;
    },
    addMultipleOptionsOption(
      state,
      action: PayloadAction<{ id: string; option: string }>
    ) {
      const { id, option } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);

      questionItem.severalOptionList.push({
        id: Math.random().toString(36).substr(2, 9),
        value: option,
      });
    },
    removeMultipleOptionsOption(
      state,
      action: PayloadAction<{ questionId: string; optionId: string }>
    ) {
      const { questionId, optionId } = action.payload;
      const questionItem = state.channel.form.items.find(
        (item) => item.id === questionId
      );
      const index = questionItem.severalOptionList.findIndex(
        (option) => option.id === optionId
      );

      questionItem.severalOptionList.splice(index, 1);
    },
    addCheckboxOption(state, action: PayloadAction<{ id: string; option: string }>) {
      const { id, option } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);

      questionItem.checkboxList.push({
        id: Math.random().toString(36).substr(2, 9),
        value: option,
      });
    },
    removeCheckboxOption(
      state,
      action: PayloadAction<{ questionId: string; optionId: string }>
    ) {
      const { questionId, optionId } = action.payload;
      const questionItem = state.channel.form.items.find(
        (item) => item.id === questionId
      );
      const index = questionItem.checkboxList.findIndex(
        (option) => option.id === optionId
      );

      questionItem.checkboxList.splice(index, 1);
    },
    addDropdownOption(state, action: PayloadAction<{ id: string; option: string }>) {
      const { id, option } = action.payload;
      const questionItem = state.channel.form.items.find((item) => item.id === id);

      questionItem.dropdownList.push({
        id: Math.random().toString(36).substr(2, 9),
        value: option,
      });
    },
    removeDropdownOption(
      state,
      action: PayloadAction<{ questionId: string; optionId: string }>
    ) {
      const { questionId, optionId } = action.payload;
      const questionItem = state.channel.form.items.find(
        (item) => item.id === questionId
      );
      const index = questionItem.dropdownList.findIndex(
        (option) => option.id === optionId
      );

      questionItem.dropdownList.splice(index, 1);
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchForm.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchForm.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log(action.payload);
        state.channel = action.payload;
        state.channelName = action.payload.name;
      })
      .addCase(updateForm.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateForm.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.channelName = action.payload.name;
      });
  },
});

const selectFormState = (state: RootState) => state.FormEdit;
export const selectForm = createSelector(
  selectFormState,
  (formState) => formState.channel
);
export const selectFormName = createSelector(
  selectFormState,
  (formState) => formState.channelName
);
export const selectFetchFormStatus = createSelector(
  selectFormState,
  (formState) => formState.status.fetch
);
export const selectUpdateFormStatus = createSelector(
  selectFormState,
  (formState) => formState.status.update
);
export const {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeSchedule,
  addPermission,
  removePermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  changeFormTitle,
  changeFormDescription,
  changeFormSuccessMessage,
  changeFormColor,
  addQuestionItem,
  changeQuestionItem,
  deleteQuestionItem,
  changeQuestionItemType,
  changeQuestionLinearScaleInputFrom,
  changeQuestionLinearScaleInputTo,
  changeQuestionLinearScaleInputFromTitle,
  changeQuestionLinearScaleInputToTitle,
  addMultipleOptionsOption,
  removeMultipleOptionsOption,
  changeAssignMethod,
  addCheckboxOption,
  removeCheckboxOption,
  addDropdownOption,
  removeDropdownOption,
  changeAssignTo,
} = formEditSlice.actions;

export default formEditSlice.reducer;
