export const style = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  secondaryContainer: {
    backgroundImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0))',
    float: 'left',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '20px',
    borderRadius: `5px 5px 5px 0px `,
    border: '1px solid',
    borderColor: 'transparent',
    width: '100%',
    height: '100%',
    padding: '.5rem',
    minWidth: '80%',
    minHeight: '200px',
    position: 'relative',
    overflow: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    fontSize: '11px',
    color: (theme) => theme.palette.text.primary,
    justifyContent: 'space-between',
    mt: 1,
    width: '100%',
  },
  whatsappIcon: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: 0,
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.25rem',
    color: 'text.primary',
    opacity: '0.58',
  },
};
