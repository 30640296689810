import React, { useEffect } from 'react';
import FormProvider from './context/FormProvider';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchForms } from 'redux/features/formSlice/formSlice';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, CrudPanel } from './components/index.js';

const FormContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  return (
    <FormProvider>
      <Box display={'flex'}>
        <AppPanel />
        <CrudPanel />
      </Box>
    </FormProvider>
  );
};

export default FormContainer;
