import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeQuestionItem,
  deleteQuestionItem,
} from 'redux/features/formEditSlice/formEditSlice';
// Types
import { FormItem } from '@trii/types/dist/Common/Channels';
// Components/ui
import {
  Box,
  TextField,
  Card,
  Fade,
  IconButton,
  Switch,
  Divider,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AnswerSelector, AnswerTypeInput, OrderArrows } from './components';

interface Props {
  questionData: FormItem;
  isLast: boolean;
}

const QuestionItem = ({ questionData, isLast }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const orderUpButtonDisabled = questionData.order === 0;
  const orderDownButtonDisabled = isLast;

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeQuestionItem({
        id: questionData.id,
        newQuestionData: { key: 'question', data: e.target.value },
      })
    );
  };
  const handleObligatoryQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      changeQuestionItem({
        id: questionData.id,
        newQuestionData: { key: 'required', data: e.target.checked },
      })
    );
  };
  const handleChangeQuestionOrderUp = () => {
    console.log({
      id: questionData.id,
      newQuestionData: { key: 'order', data: questionData.order - 1 },
    });
    dispatch(
      changeQuestionItem({
        id: questionData.id,
        newQuestionData: { key: 'order', data: questionData.order - 1 },
      })
    );
  };
  const handleChangeQuestionOrderDown = () => {
    dispatch(
      changeQuestionItem({
        id: questionData.id,
        newQuestionData: { key: 'order', data: questionData.order + 1 },
      })
    );
  };
  const handleDeleteQuestionItem = () => {
    dispatch(deleteQuestionItem(questionData.id));
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Card
        sx={{
          width: '100%',
          p: 2,
          border: '1px solid',
          borderColor: theme => theme.palette.divider,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme => theme.palette.background.default,
        }}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            size="small"
            label={t('formEditView.designPanel.question')}
            sx={{ width: '60%' }}
            value={questionData.question}
            onChange={handleQuestionChange}
            variant="filled"
          />
          <AnswerSelector
            questionId={questionData.id}
            answerType={questionData.type}
          />
          <OrderArrows
            orderDownButtonDisabled={orderDownButtonDisabled}
            orderUpButtonDisabled={orderUpButtonDisabled}
            handleChangeQuestionOrderUp={handleChangeQuestionOrderUp}
            handleChangeQuestionOrderDown={handleChangeQuestionOrderDown}
          />
        </Box>
        <AnswerTypeInput questionData={questionData} />
        <Divider sx={{ my: 1 }} />
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <IconButton onClick={handleDeleteQuestionItem} color="error" size="small">
            <DeleteIcon sx={{ fontSize: 21 }} />
          </IconButton>
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Switch onChange={handleObligatoryQuestionChange} size="small" />
            }
            label={t('formEditView.designPanel.obligatoryQuestion')}
            checked={questionData.required}
          />
        </Box>
      </Card>
    </Fade>
  );
};

export default QuestionItem;
