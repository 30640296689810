import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectEndingsFetchStatus,
  selectAllEndings,
} from 'redux/features/endingSlice/endingSlice';
// Components/ui
import { Box } from '@mui/material';
import { EndingItemSkeleton, EndingItem } from './components';

const ListBody = () => {
  const allEndings = useSelector(selectAllEndings);
  const endingsFetchStatus: RequestStatus = useSelector(selectEndingsFetchStatus);

  const Endings = allEndings.map((ending) => (
    <EndingItem key={ending.id} data={ending} />
  ));

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {endingsFetchStatus === 'succeeded' ? Endings : <EndingItemSkeleton />}
    </Box>
  );
};

export default ListBody;
