import { useTranslation } from 'react-i18next';
import { RefObject, forwardRef } from 'react';
// components/ui
import { Box, Button, CircularProgress } from '@mui/material';
import HistorySection from './components/HistorySection/HistorySection';
import HistoryItem from './components/HistoryItem/HistoryItem';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { SectionCard } from '../../layout';
// Types
import { ContactProps } from '../../types/ContactProps';
import {
  selectHistoryLogs,
  selectHistoryLogsFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
// Redux
import { useSelector } from 'react-redux';

const ContactHistory = forwardRef(
  (props: ContactProps, ref: RefObject<HTMLElement>) => {
    const { t } = useTranslation();

    const historyLogs = useSelector(selectHistoryLogs);
    const historyLogsFetchStatus = useSelector(selectHistoryLogsFetchStatus);

    const History = historyLogs?.map((history, i) => (
      <TimelineItem key={i}>
        <TimelineContent>
          <HistoryItem data={history} />
        </TimelineContent>
      </TimelineItem>
    ));

    return (
      <SectionCard
        title={t('conversations.sidebar.history.title')}
        onClick={props.handleClick}
        tabName="contactHistory"
      >
        <Box ref={ref}>
          <HistorySection>
            {historyLogsFetchStatus === 'loading' ? (
              <CircularProgress />
            ) : (
              <Timeline
                sx={{
                  marginY: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  padding: 0,        
                  '& li div.MuiTimelineContent-root': {
                    padding: 0,
                  },
                }}
              >
                {History}
              </Timeline>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 1,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                startIcon={<KeyboardDoubleArrowDownIcon />}
                size="small"
              >
                Ver más
              </Button>
            </Box>
          </HistorySection>
        </Box>
      </SectionCard>
    );
  }
);

export default ContactHistory;
