import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const IFrameButton = ({ url }: { url: string }) => {
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== 'http://localhost:9000') {
        // Ignore messages from unexpected origins
        return;
      }

      if (event.data === 'iframeLoaded') {
        let iframe = document.getElementById(
          'contactEditIframe'
        ) as HTMLIFrameElement;
        let innerDoc = iframe?.contentDocument || iframe?.contentWindow.document;

        const observer = new MutationObserver((mutationsList, observer) => {
          for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
              let div = innerDoc?.getElementById('js-page-content');

              if (div) {
                // Move all children of the div to its parent
                while (div.firstChild) {
                  div.parentNode.insertBefore(div.firstChild, div);
                }

                // Remove the div
                div.parentNode.removeChild(div);

                // Disconnect the observer to stop observing
                observer.disconnect();
                break;
              }
            }
          }
        });

        // Start observing the document with the configured parameters
        observer.observe(innerDoc, { childList: true, subtree: true });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (iframeContainerRef.current) {
      observer.observe(iframeContainerRef.current);
    }

    return () => {
      if (iframeContainerRef.current) {
        observer.unobserve(iframeContainerRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={iframeContainerRef}
      sx={{
        width: '100%',
        height: '350px',
        bgcolor: 'background.paper',
        boxShadow: 24,
      }}
    >
      {isInView && (
        <iframe
          title="Modal Content"
          id="contactEditIframe"
          src={url}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          onLoad={() => {
            const iframe = document.getElementById(
              'contactEditIframe'
            ) as HTMLIFrameElement;
            iframe.contentWindow.postMessage(
              'iframeLoaded',
              'http://localhost:9000'
            );
          }}
        />
      )}
    </Box>
  );
};

export default IFrameButton;
