import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, TextField } from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
import { StyleContext } from 'style/styleProvider';

const Parameters = () => {
  const { apiNode, setApiNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [apiBody, setApiBody] = useState<string>('');
  const [apiContentType, setApiContentType] = useState<string>('');
  const { fontWeight } = useContext(StyleContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.api) {
      const api = selectNode.data.api;
      const defaultContactId = api.apiBody && (api.apiBody as string);
      const defaultContentType =
        api.apiContentType && (api.apiContentType as string);
      setApiContentType(defaultContentType);
      setApiBody(defaultContactId);
    }
  }, [selectNode]);

  const handleChangeBody = (apiBody: string) => {
    setApiBody(apiBody);
    setApiNode({ ...apiNode, apiBody });
  };

  const handleChangeContentType = (apiContentType: string) => {
    setApiContentType(apiContentType);
    setApiNode({ ...apiNode, apiContentType });
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        // color={(theme) => theme.palette.text.disabled}
        fontWeight={fontWeight.bold}
        color="text.primary"
      >
        {t('editFlowsView.api.parameters.title')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label={t('editFlowsView.api.parameters.body')}
        value={apiBody}
        onChange={(e) => handleChangeBody(e.target.value)}
        multiline
        rows={4}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          mt: 1,
          mb: 2,
        }}
      />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label={t('editFlowsView.api.parameters.contentType')}
        value={apiContentType}
        onChange={(e) => handleChangeContentType(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default Parameters;
