import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { flowContext } from 'features/Views/Flow/context/FlowProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectFlowCreateStatus } from 'redux/features/flowSlice/flowSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

function CreateButtons() {
  const flowCreateStatus = useSelector(selectFlowCreateStatus);
  const { t } = useTranslation();
  const { endCreating, handleCreateFlow } = useContext(flowContext);
  const isLoading = flowCreateStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endCreating}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        disabled={isLoading}
        loading={isLoading}
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        onClick={handleCreateFlow}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
