import { JsReportDataTimeRange } from 'redux/features/reportsSlice/types/JsReportData';

export const periodList: {
  value: JsReportDataTimeRange | 'custom';
  label: string;
}[] = [
  {
    value: 1,
    label: 'popperDays.day',
  },
  {
    value: 7,
    label: 'popperDays.week',
  },
  {
    value: 30,
    label: 'popperDays.month',
  },
  {
    value: 365,
    label: 'popperDays.year',
  },
  {
    value: 77,
    label: 'popperDays.last7Days',
  },
  {
    value: 1515,
    label: 'popperDays.last15Days',
  },
  {
    value: 3030,
    label: 'popperDays.last30Days',
  },
  {
    value: 60,
    label: 'popperDays.last60Days',
  },
  {
    value: 0,
    label: 'popperDays.custom',
  },
];
