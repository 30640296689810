import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
// Types
import { ISchedule } from '@trii/types/dist/Common/Schedules';
// Components/ui
import { Box, Typography, Fade } from '@mui/material';
import { ActionButtons } from './components';
// Translation
import { useTranslation } from 'react-i18next';
// moment
import moment from 'moment';

const FORMAT_DATE = 'YYYY-MM-DD';

interface ScheduleItemProps {
  data: ISchedule;
}

const ScheduleItem = ({ data }: ScheduleItemProps) => {
  const [show, setShow] = useState<boolean>(false);
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { t } = useTranslation();
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Box
        className="conversations-panel-inner my-0"
        display={'flex'}
        boxShadow={shadow.md}
        borderRadius={1}
        px={{ md: '1rem', lg: '1rem' }}
        sx={{
          color: (theme) => theme.palette.text.primary,
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
        }}
      >
        <Box className="conversations-d-flex conversations-justify-content-between conversations-w-100">
          <Box className="d-inline-block conversations-w-100 py-1 pr-4">
            <Typography
              fontWeight={fontWeight.semibold}
              fontSize={fontSize.sm}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {data?.name}
            </Typography>
            <br />

            <Box display="flex" justifyContent="space-between">
              <Typography
                fontSize={fontSize.sm}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                {t('global.createdAt')} {moment(data.createdAt).format(FORMAT_DATE)}
              </Typography>
            </Box>
          </Box>
          <ActionButtons schedule={data} />
        </Box>
      </Box>
    </Fade>
  );
};

export default ScheduleItem;
