import { useTranslation } from "react-i18next";
// Types
import { EmailConfigOutboundStatus } from "@trii/types/dist/Common/Channels";
// Components/ui
import { Box, Divider, Typography, Chip, Button, Snackbar, Alert } from "@mui/material";
import { useAppDispatch } from "hooks/useAppDispatch";
import { testSendEmail } from "redux/features/emailEditSlice/emailEditSlice";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  status: EmailConfigOutboundStatus;
}

const SectionContainer = ({ title, children, status }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const handleTestSendEmail = async () => {
    try {
      const response = await dispatch(testSendEmail(id));;
      
      // Assuming you check response to detect if it's successful or failed
      if (response.meta.requestStatus === 'fulfilled') {
        setSnackbarMessage(t("emailEditView.testConnection.testSuccess"));
        setSnackbarSeverity('success');
      } else if (response.meta.requestStatus === 'rejected') {
        //@ts-ignore
        setSnackbarMessage(t("emailEditView.testConnection.testFailed") + response.error.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('SMTP Test failed:', error);
    } finally {
      setOpen(true);
    }
  };
  const openSetBySepInstruction = () => {
    window.open('https://www.google.com.ar/', '_blank')
  };

  const StatusChip =
    status === EmailConfigOutboundStatus.SUCCESS ? (
      <Chip size="small" label={t("global.active")} color="success" />
    ) : (
      <Chip size="small" label={t("global.inactive")} color="error" />
    );

  return (
    <Box>
      <Box pt={1} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body1" sx={{fontSize: '1.2rem'}}>{title}</Typography>
        {StatusChip}
      </Box>
      <Divider />
      <Box pb={4} display={"flex"} flexDirection={"column"} gap={2}>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          {children}
        </Box>
        <Box display={"flex"} gap={2} justifyContent={"space-between"}>
          <Button size="small" variant="outlined" onClick={handleTestSendEmail}>
            {t("emailEditView.outgoingEmailPanel.testSettings")}
          </Button>
          <Button size="small" variant={"text"} onClick={openSetBySepInstruction}>
            {t("emailEditView.outgoingEmailPanel.stepByStepInstructions")}
          </Button>
        </Box>
      </Box>
      <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={snackbarSeverity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
    </Box>
  );
};

export default SectionContainer;
