import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { GroupContext } from 'features/Views/Groups/context/types';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { deleteGroupNameField } = useContext<GroupContext>(InitGroupContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <Typography
        variant="subtitle2"
        color={'text.primary'}
        sx={{ marginBottom: '15px' }}
      >
        {t('settingsView.groups.deleteGroupLabel')}
      </Typography>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('settingsView.groups.groupName')}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          marginBottom: '15px',
        }}
        {...deleteGroupNameField.attributes}
      />
    </Box>
  );
};

export default Inputs;
