import { SocialWallsProvider } from './context/SocialWallsProvider';
// Components/ui
import Box from '@mui/material/Box';
import { PublicationContainer } from './components';

const Walls = () => {
  return (
    <SocialWallsProvider>
      <Box
        display="flex"
        height={'100%'}
        width="100%"
      >
        <>
          <PublicationContainer />
        </>
      </Box>
    </SocialWallsProvider>
  );
};

export default Walls;
