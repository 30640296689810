// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import { Walls, SystemMVC } from './features/Views';
import Conversations from './features/Views/Conversations';
import Groups from './features/Views/Groups';
import WebHooksContainer from './features/Views/WebHooks';
import ScheduleContainer from './features/Views/Schedules';
import EndingContainer from './features/Views/Ending';
import LabelContainer from './features/Views/Label';
import FlowContainer from './features/Views/Flow';
import EditFlows from './features/Views/EditFlows';
import WebChatContainer from './features/Views/WebChat';
import WebChatEditContainer from './features/Views/WebChatEdit';
import EmailContainer from './features/Views/Email';
import EmailEditContainer from './features/Views/EmailEdit';
import SmsContainer from './features/Views/Sms';
import FormContainer from './features/Views/Form';
import FormEditContainer from './features/Views/FormEdit';
import PbxContainer from './features/Views/Pbx';
import WhatsAppContainer from './features/Views/WhatsApp';
import FacebookContainer from './features/Views/Facebook';
import FacebookEditContainer from './features/Views/FacebookEdit';
import InstagramContainer from './features/Views/Instagram';
import InstagramEditContainer from './features/Views/InstagramEdit';
import MercadoLibreContainer from './features/Views/MercadoLibre';
import MercadoLibreEditContainer from './features/Views/MercadoLibreEdit';
import GoogleBusinessContainer from './features/Views/GoogleBusiness';
import GoogleBusinessEditContainer from './features/Views/GoogleBusinessEdit';
import SipContainer from './features/Views/Sip';
import WhatsAppEditContainer from './features/Views/WhatsAppEdit';
import SmsEditContainer from './features/Views/SmsEdit';
import { Dashboard } from 'features/Views/Dashboard';
import ResponsesContainer from './features/Views/Responses';
import MyReports from './features/Views/MyReports/MyReports';
import { ReportDetails } from './features/Views/MyReports/components';
import SchedulesEditContainer from 'features/Views/SchedulesEdit';
import QuickResponseContainer from 'features/Views/QuickResponse/QuickResponseContainer';
import TemplatesContainer from 'features/Views/Templates/TemplatesContainer';
import DateTimeProvider from 'context/DateTime/DateTimeProvider';
// Context
import { useContext, useEffect } from 'react';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Types
import { IUserUpdate } from '@trii/types/dist/Common/EventsQueue/UserUpdate';
import { selectUser, updateUser } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
// IFRAME
import DashboardIFRAME from 'features/Views/DashboardIFRAME';
import ConversationsIFRAME from 'features/Views/ConversationsIFRAME';
import MyReportsIFRAME from 'features/Views/MyReportsIFRAME';
import SystemMVCFRAME from 'features/Views/SystemMVCFRAME';

const Routes = () => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  useEffect(() => {
    const handleUserUpdate = (updatedUserData: IUserUpdate) => {
      dispatch(updateUser(updatedUserData));

      if (updatedUserData.userId === user.uid) {
        localStorage.setItem('userStatus', JSON.stringify(updatedUserData.status));
      }
    };

    if (socketConnection) {
      subscribeEvent('user_update', handleUserUpdate);
    }

    return () => {
      unsubscribeEvent('user_update');
    };
  }, [socketConnection]);

  return (
    <DateTimeProvider>
      <MainLayout>
        <ReactRoutes>
        <Route
            path="/a/conversations"
            element={<Navigate to="/a/conversations/Conversations" replace />}
          />
          <Route
            path="/a/conversations/*"
            element={<Conversations />}
          />
          {/* IFRAME */}
          <Route
            path="/a/conversations/Dashboard/:dashboardType?"
            element={<Dashboard />}
            caseSensitive
          />
          <Route path="/a/conversations/socialwalls" element={<Walls />} />
          {/* <Route
            path="/a/conversations/conversations/:contactId?"
            element={<ConversationsIFRAME />}
            caseSensitive
          /> */}
          {/* IFRAME */}
          <Route
            path="/a/conversations/Conversations/:contactId?"
            element={<Conversations />}
            // caseSensitive
          />
          <Route
            path="/a/conversations/quick-responses"
            element={<QuickResponseContainer />}
          />
          <Route
            path="/a/conversations/templates"
            element={<TemplatesContainer />}
          />
          <Route
            path="/a/conversations/System"
            //            caseSensitive
            element={<SystemMVC />}
          />
          <Route path="/a/conversations/webhooks" element={<WebHooksContainer />} />
          <Route
            path="/a/conversations/System/endings"
            element={<EndingContainer />}
          />
          <Route
            path="/a/conversations/system/labels"
            element={<LabelContainer />}
          />
          <Route path="/a/conversations/system/groups" element={<Groups />} />
          <Route
            path="/a/conversations/system/schedule"
            element={<ScheduleContainer />}
          />
          <Route
            path="/a/conversations/system/schedule/:id"
            element={<SchedulesEditContainer />}
          />
          <Route path="/a/conversations/system/flows" element={<FlowContainer />} />
          <Route path="/a/conversations/system/flows/:id" element={<EditFlows />} />
          <Route
            path="/a/conversations/system/emails"
            element={<EmailContainer />}
          />
          <Route
            path="/a/conversations/system/emails/:id"
            element={<EmailEditContainer />}
          />
          <Route
            path="/a/conversations/system/webchats"
            element={<WebChatContainer />}
          />
          <Route
            path="/a/conversations/system/webchats/:id"
            element={<WebChatEditContainer />}
          />
          <Route path="/a/conversations/system/forms" element={<FormContainer />} />
          <Route
            path="/a/conversations/system/forms/:id"
            element={<FormEditContainer />}
          />
          <Route path="/a/conversations/system/pbx" element={<PbxContainer />} />
          <Route
            path="/a/conversations/system/whatsapp"
            element={<WhatsAppContainer />}
          />
          <Route
            path="/a/conversations/system/whatsapp/:id"
            element={<WhatsAppEditContainer />}
          />
          <Route
            path="/a/conversations/system/facebook"
            element={<FacebookContainer />}
          />
          <Route
            path="/a/conversations/system/facebook/:id"
            element={<FacebookEditContainer />}
          />
          <Route
            path="/a/conversations/system/instagram"
            element={<InstagramContainer />}
          />
          <Route
            path="/a/conversations/system/instagram/:id"
            element={<InstagramEditContainer />}
          />
          <Route
            path="/a/conversations/system/mercadolibre"
            element={<MercadoLibreContainer />}
          />{' '}
          <Route
            path="/a/conversations/system/mercadolibre/:id"
            element={<MercadoLibreEditContainer />}
          />
          <Route path="/a/conversations/system/sms" element={<SmsContainer />} />
          <Route
            path="/a/conversations/system/sms/:id"
            element={<SmsEditContainer />}
          />
          <Route
            path="/a/conversations/system/googlebusiness"
            element={<GoogleBusinessContainer />}
          />
          <Route
            path="/a/conversations/system/googlebusiness/:id"
            element={<GoogleBusinessEditContainer />}
          />
          <Route path="/a/conversations/system/sip" element={<SipContainer />} />
          <Route
            path="/a/conversations/system/responses"
            element={<ResponsesContainer />}
          />
          <Route path="/a/conversations/schedule" element={<ScheduleContainer />} />
          <Route
            path="/a/conversations/MyReports"
            element={<MyReports />}
            caseSensitive
          />
          <Route
            path="/a/conversations/myReports/view"
            element={<ReportDetails />}
          />
        </ReactRoutes>
      </MainLayout>
    </DateTimeProvider>
  );
};

export default Routes;
