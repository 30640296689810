import React, { useContext } from "react";
// Context
import { StyleContext } from "style/styleProvider";
// Components/ui
import { Box, Typography } from "@mui/material";
// reactflow
import { Handle, Position } from "reactflow";


const OutputResponse = ({ handleId, label }) => {
    const { fontSize } = useContext(StyleContext);

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <Typography
                sx={{ color: "text.primary", marginRight: '1rem' }}
                fontSize={fontSize.xs}
            >
                {label}
            </Typography>
            <Handle type="source" position={Position.Right} id={handleId} />
        </Box>
    );
}

export default OutputResponse;