export const style = {
  sidePanelReport: {
    "& .MuiCollapse-root": {
      height: "100%",
    },
  },
  container: {
    backgroundColor: (theme) => theme.palette.background.default,
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    boxShadow: "0px 1px 2px rgba(0,0,0,0.2)",
    borderRadius: "2px",
    p: 2,
    minWidth: "250px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
    position: "relative"
  },
};
