// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { LoadingButton } from "@mui/lab"
import { Box, Button, ButtonGroup } from "@mui/material"

interface ActionButtonsProps {
  handleClear: () => void,
  handleSave: () => void,
  isLoading: boolean,
}

const ActionButtons = ({
  handleClear,
  handleSave,
  isLoading,
}: ActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        gap: '.5rem',
      }}
    >
      <Button
        variant="outlined"
        color="error"
        size="small"

        onClick={handleClear}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={isLoading}
        size="small"

        onClick={handleSave}
      >
        {t('global.save')}
      </LoadingButton>
    </Box>
  )
}

export default ActionButtons