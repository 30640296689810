import { useTranslation } from 'react-i18next';
// Types
import { EmailConfig, EmailConfigOutboundConnectionType } from '@trii/types/dist/Common/Channels';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeConnectionType } from 'redux/features/emailEditSlice/emailEditSlice';
// Components/ui
import { ChannelPanel, ChannelPanelInnerContainer } from 'components';
import { SmtpInputs, TriiInputs } from './components';
import { Select, Typography, Box, MenuItem, Divider } from '@mui/material';
import GmailAccount from './components/GmailAccount/GmailAccount';

interface Props {
  connectionType: EmailConfigOutboundConnectionType;
  connectedEmail: EmailConfig;
}

const OutgoingEmailPanel = ({ connectionType,connectedEmail }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleSendMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target
      .value as unknown as EmailConfigOutboundConnectionType;
    dispatch(changeConnectionType(selectedValue));
  };
  return (
    <Box>
          <Box pt={1} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body1" sx={{fontSize: '1.2rem'}}>{t('emailEditView.outgoingEmailPanel.title')}</Typography>
      </Box>
      <Divider />
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={2}>
          <Typography variant="subtitle1">
            {t('emailEditView.outgoingEmailPanel.sendMethod')}
          </Typography>
          <Select
            size="small"
            sx={{ width: '530px' }}
            // @ts-ignore
            value={connectionType}
            onChange={handleSendMethodChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  //@ts-ignore
                  backgroundColor: (theme) => theme.palette.background.panel,
                },
              },
            }}
          >
            <MenuItem value={EmailConfigOutboundConnectionType.NULL}>
              {t('emailEditView.outgoingEmailPanel.none')}
            </MenuItem>
            <MenuItem value={EmailConfigOutboundConnectionType.TRII}>Trii</MenuItem>
            <MenuItem value={EmailConfigOutboundConnectionType.SMTP}>
              {t('emailEditView.outgoingEmailPanel.personalizedSMTP')}
            </MenuItem>
            <MenuItem value={EmailConfigOutboundConnectionType.GOOGLE}>
              {t('emailEditView.outgoingEmailPanel.gmailAccount')}
            </MenuItem>
          </Select>
        </Box>
        <Divider sx={{ mt: 1, mb: 3 }} />
        {connectionType === EmailConfigOutboundConnectionType.TRII && <TriiInputs outboundTrii={connectedEmail.outboundTrii}/>}
        {connectionType === EmailConfigOutboundConnectionType.SMTP && <SmtpInputs />}
        {connectionType === EmailConfigOutboundConnectionType.GOOGLE && <GmailAccount outboundGoogleAccount={connectedEmail.outboundGoogleAccount} outboundGoogleStatus={connectedEmail.outboundGoogleStatus}/>}
    </Box>

  );
};

export default OutgoingEmailPanel;
