import { useState, useContext, useEffect } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';
// Slice
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
// Components/ui
import { Box, useTheme } from '@mui/material';
import { ContactInfoSkeleton, PanelView, TabsMenu } from './components';
// Custom hooks
import useSectionRefs from './hooks/useSectionRefs';
// Provider
import ActivitiesCardContextProvider from './context/ActivitiesCardContextProvider';
import {
  fetchFiles,
  fetchHistoryLogs,
  fetchNotes,
  selectFiles,
  selectNotes,
  selectNotesFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import {
  fetchButtons,
  selectButtons,
} from 'redux/features/buttonsSlice/buttonsSlice';

import { useMemo } from 'react';

const ChatDetailsSidebar = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { contactInfo, getConversationsOfContact } =
    useContext(conversationsContext);

  const [value, setValue] = useState(0);
  const [isClicked, setIsClicked] = useState(false);

  const conversationSelected = useSelector(selectConversationSelected);
  const buttons = useSelector(selectButtons);
  const notesStatus = useSelector(selectNotes);
  const filesStatus = useSelector(selectFiles);
  const conversationsStatus = useSelector(selectButtons);
  const eventStatus = useSelector(selectButtons);

  const sectionRefs = useSectionRefs(buttons);

  const contactId = conversationSelected?.contactInfo?.id;
  



  
  // useMemo para prevenir múltiples ejecuciones de las acciones si ya se tiene la información
  const fetchAllData = useMemo(() => {
    if (contactId) {
      dispatch(fetchNotes(contactId));
      dispatch(fetchFiles(contactId));
      dispatch(fetchHistoryLogs(contactId));
      dispatch(fetchActivities(contactId));
      getConversationsOfContact({ contactId, status: 'active' });
    }
  }, [dispatch, contactId, contactInfo ]);

  useEffect(() => {
    if (contactId) {
      fetchAllData;
    }
  }, [contactId, fetchAllData]);

  useEffect(() => {
    dispatch(fetchButtons());
  }, [dispatch]);

  const handleTabChange = (newValue: number) => {
    setValue(newValue);
    // Ya no es necesario hacer dispatch aquí porque los datos se cargan al montar el componente
  };

  return (
    <ActivitiesCardContextProvider>
      <Box
        height="100%"
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          bgcolor: theme.palette.background.default,
        }}
        display={'flex'}
      >
        {!contactInfo ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            padding={2}
          >
            <ContactInfoSkeleton />
          </Box>
        ) : (
          <>
            <Box
              flexGrow={1}
              display={'flex'}
              flexDirection={'column'}
              width={'calc(100% - 4rem)'}
            >
              <PanelView
                sectionRefs={sectionRefs}
                value={value}
                setValue={setValue}
                isClicked={isClicked}
              />
            </Box>
            <TabsMenu
              sectionRefs={sectionRefs}
              value={value}
              handleChange={handleTabChange}
              setIsClicked={setIsClicked}
            />
          </>
        )}
      </Box>
    </ActivitiesCardContextProvider>
  );
};

export default ChatDetailsSidebar;
