// Components/ui
import { Box } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import { TemplatesList } from 'features/Views/Templates/context/types/Lists';
import { Text } from './components';
import { style } from './style';

interface MessageProps {
  selectedTemplate: TemplatesList | null;
}

const TemplateMessageViewer: React.FC<MessageProps> = ({ selectedTemplate }) => {
  return (
    <Box sx={style.container}>
      <Box
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.messageBox,
          ...style.secondaryContainer,
        }}
      >
        <Box sx={style.titleContainer}>
          <Box display="flex" gap={0.5} alignItems="center" mb={0.5}>
            <WhatsApp sx={style.whatsappIcon} />
            <Box sx={{ width: '100%' }}>{selectedTemplate?.title || ''}</Box>
          </Box>
        </Box>
        <Text selectedTemplate={selectedTemplate} />
      </Box>
    </Box>
  );
};

export default TemplateMessageViewer;
