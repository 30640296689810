import { NodeMsgWaitResponseConditionSource } from "@trii/types/dist/Conversations/Flows/Nodes";

type ConditionSource = {
    id: number;
    label: string;
};

const conditionSource: ConditionSource[] = [
    {
        id: NodeMsgWaitResponseConditionSource.TEXT,
        label: "editFlowsView.waitResponse.text"
    },
    {
        id: NodeMsgWaitResponseConditionSource.CAPTION,
        label: "editFlowsView.waitResponse.caption"
    },
    {
        id: NodeMsgWaitResponseConditionSource.TEXT_AND_CAPTION,
        label: "editFlowsView.waitResponse.textAndCaption"
    },
    {
        id: NodeMsgWaitResponseConditionSource.MIMETYPE,
        label: "editFlowsView.waitResponse.mimeType"
    }
];

export default conditionSource;