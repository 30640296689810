import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewSms } from 'redux/features/smsSlice/types/NewSms';

const createSmsInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewSms: () => ({} as NewSms),
  },
};
export default createSmsInitialState;
