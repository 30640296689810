import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
// Interfaces
import { NodeMsgWaitResponseTimeType } from '@trii/types/dist/Conversations/Flows/Nodes';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';

const TimeOut = () => {
  const { msgWaitResponseNode, setMsgWaitResponseNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [continueAfterEnabled, setContinueAfterEnabled] = useState<boolean>(false);
  const [continueAfterTimeType, setContinueAfterTimeType] =
    useState<NodeMsgWaitResponseTimeType>(NodeMsgWaitResponseTimeType.MINUTES);
  const [minute, setMinute] = React.useState<number | null>(0);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  

  useEffect(() => {
    if (selectNode) {
      const { msgWaitResponse } = selectNode.data;
      if (msgWaitResponse) {
        setContinueAfterEnabled(
          msgWaitResponse.continueAfterEnabled
            ? msgWaitResponse.continueAfterEnabled
            : false
        );
        setContinueAfterTimeType(
          msgWaitResponse.continueAfterTimeType
            ? msgWaitResponse.continueAfterTimeType
            : NodeMsgWaitResponseTimeType.MINUTES
        );
        setMinute(
          msgWaitResponse.continueAfterTime ? msgWaitResponse.continueAfterTime : 0
        );
      }
    }
  }, [selectNode]);

  const handleChangeContinueAfterEnabled = (value: boolean) => {
    setContinueAfterEnabled(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      continueAfterEnabled: value,
    });
  };

  const handleChangeMinute = (minute: any) => {
    // REGEX: only numbers
    const regex = /^[0-9\b]+$/;
    if (minute === '' || regex.test(minute)) {
      setError(false);
      setMinute(minute);
      setMsgWaitResponseNode({
        ...msgWaitResponseNode,
        continueAfterTime: minute,
      });
    } else {
      setError(true);
    }
  };

  const handleChangeTimeType = (value: NodeMsgWaitResponseTimeType) => {
    setContinueAfterTimeType(value);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      continueAfterTimeType: value,
    });
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={continueAfterEnabled}
            size="small"
            onChange={(e) => handleChangeContinueAfterEnabled(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.waitResponse.continueAfterEnabled')}
      />
      <Box display="flex" gap={1}>
        <TextField
          size="small"
          variant="outlined"
          label={t('editFlowsView.waitResponse.inputTime')}
          value={minute}
          onChange={(e) => handleChangeMinute(e.target.value)}
          error={error && continueAfterEnabled}
          helperText={
            error && continueAfterEnabled
              ? t('editFlowsView.waitResponse.inputTimeError')
              : ''
          }
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!continueAfterEnabled}
          sx={{
            "& .MuiFormHelperText-root": {
              variant: "caption",
              fontStyle: "italic",
              opacity: 0.7,
            },
          }}
        />
        <FormControl fullWidth size="small" disabled={!continueAfterEnabled}>
          <InputLabel id="channel" shrink={true}>
            {t('editFlowsView.waitResponse.timeRange')}
          </InputLabel>
          <Select
            labelId="channel"
            id="demo-simple-select"
            value={continueAfterTimeType}
            label={t('editFlowsView.waitResponse.timeRange')}
            onChange={(e) =>
              handleChangeTimeType(e.target.value as NodeMsgWaitResponseTimeType)
            }
            sx={{
              '& legend' : {
                maxWidth: "fit-content",
                // padding: '0 10px'
              },
              "& .MuiSvgIcon-root": {
                color: 'text.disabled'            
              }
            }}
          >
            <MenuItem value={NodeMsgWaitResponseTimeType.MINUTES}>
              {t('editFlowsView.waitResponse.minutes')}
            </MenuItem>
            <MenuItem value={NodeMsgWaitResponseTimeType.HOURS}>
              {t('editFlowsView.waitResponse.hours')}
            </MenuItem>
            <MenuItem value={NodeMsgWaitResponseTimeType.DAYS}>
              {t('editFlowsView.waitResponse.days')}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default TimeOut;
