import { ReactNode, useContext, useEffect, useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// components/ui
import { Avatar, AvatarGroup, Box, Button, Fade, Tooltip, Typography, useTheme } from "@mui/material";
// Types
import { IConversation } from "@trii/types/dist/Conversations";
// Context
import { conversationsContext } from "features/Views/Conversations/context/ConversationsProvider/ConversationsProvider";
import { useSelector } from "react-redux";
import { selectConversationSelected } from "redux/features/conversationsSlice/conversationsSlice";
import { Label } from "@mui/icons-material";

interface ActiveConversationProps {
  conversation: IConversation;
}

const FORMAT_DATE = "DD/MM/YYYY HH:mm";

const ActiveConversation = ({ conversation }: ActiveConversationProps) => {
  const { t } = useTranslation();
  const { handleSelectConversation, getImage } = useContext(conversationsContext);

  const conversationSelected = useSelector(selectConversationSelected);

  const theme = useTheme();
  const [assignedToName, setAssignedToName] = useState("" as string);

  const handleClick = () => {
    if (conversationSelected?.id === conversation.id) return;
    handleSelectConversation(conversation);
  };

  useEffect(() => {
    if (conversationSelected && conversationSelected.assignedTo) {
      const { userInfo, groupInfo } = conversationSelected.assignedTo;
      if (groupInfo) {
        setAssignedToName(groupInfo.name);
      } else {
        setAssignedToName(userInfo.name);
      }
    }
  }, [conversationSelected]);

  const ending = conversation?.ending?.name;
  const labels = conversation?.labels;
  const channelName = conversation?.channelInfo?.name;

  const userInfoName = conversation?.assignedTo?.userInfo?.name;
  const groupInfoName = conversation?.assignedTo?.groupInfo?.name;

  const image = getImage(conversation?.channelInfo?.type);
  const icon: ReactNode = image.icon;

  return (
    <Button
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: conversationSelected?.id === conversation.id ? theme.palette.action.active : "transparent",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        gap: 1,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        maxWidth: "100%",
        width: "auto",
      }}
      onClick={handleClick}
      disabled={conversationSelected?.id === conversation.id}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tooltip title={conversation.contactInfo.name}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
              color: "text.primary",
              maxWidth: "70%",
              minWidth: "20%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textAlign: "left",
            }}
          >
            {conversation.contactInfo.name}
          </Typography>
        </Tooltip>
        <Box display="flex">
          {labels && labels.length > 0 && labels.map((label) => (
            <Fade key={label.id} in={labels.length > 0}>
              <Label
                fontSize="small"
                sx={{
                  fontSize: "13px",
                  color: label.color,
                }}
              />
            </Fade>
          ))}
          <Typography
            sx={{
              fontSize: 10,
              color: "text.primary",
              fontStyle: "italic",
            }}
          >
            {moment(conversation.createdAt).format(FORMAT_DATE)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ width: '100%', maxWidth: '65%' }}
        >
          <Box display="flex" alignItems="center" gap={0.5} width="100%">
            <Box
              className="iconLastMessage"
              sx={{
                color: "text.primary",
                marginTop: "-1px",
                "& svg": {
                  fontSize: "14px",
                  marginTop: "-1px",
                },
              }}
            >
              {icon}
            </Box>
            <Typography
              variant="subtitle2"
              noWrap
              fontSize="9px"
              fontWeight="bold"
              color="text.disabled"
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {channelName}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            noWrap
            fontSize="10px"
            color="text.disabled"
          >
            {groupInfoName || "-"} / {userInfoName || "-"}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            fontSize="10px"
            color="text.disabled"
          >
            {ending}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{ width: { xs: "100%", sm: "auto" }, justifyContent: { xs: "center", sm: "flex-start" }, overflow: 'hidden' }}
        >
          <AvatarGroup
            sx={{
              "& .MuiAvatar-root": {
                width: "1.5rem",
                height: "1.5rem",
                fontSize: "0.75rem",
              },
            }}
          >
            {conversation?.participants?.map((participant) => (
              <Tooltip title={participant.name} arrow key={participant.id}>
                <Avatar
                  alt={participant.name.charAt(0).toUpperCase() + participant.name.slice(1)}
                  src={participant.imageUrl || "/"}
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        </Box>
      </Box>
    </Button>
  );
};

export default ActiveConversation;
