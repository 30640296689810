import { useContext, useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import { Box, IconButton, Modal, useTheme } from "@mui/material"
// Icons
import { Close } from "@mui/icons-material"
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
// Selectors
import { dashboardChannelsLogs, dashboardChannelsLogsStatus } from "redux/features/dashboardSlice/dashboardSlice";
// Components
import { Selector, Table } from "./components"
// Types
import { IChannelInfo } from "@trii/types/dist/Common/Channels";
import { Log } from '@trii/types/dist/Common/Logs/Log';

interface Props {
  open: boolean;
  channels: IChannelInfo[];
  onClose: () => void;
}

const RegisterModal = ({
  open,
  channels,
  onClose,
}: Props) => {
  const { getLogsChannels } = useContext(DashboardContext);
  const newLogs = useSelector(dashboardChannelsLogs)
  const logStatus = useSelector(dashboardChannelsLogsStatus)
  const isLoading = logStatus === "loading"
  const [channelSelected, setChannelSelected] = useState<string>("")
  const [logs, setLogs] = useState<Log[]>([]);
  const theme = useTheme()

  const handleSelect = (id: string) => {
    setChannelSelected(id)
    getLogsChannels(id)
  }

  useEffect(() => {
    if (channels.length > 0) {
      const channelId = channels[0].id
      setChannelSelected(channelId)
      getLogsChannels(channelId)
    }
  }, [channels])

  useEffect(() => {
    if (newLogs) {
      setLogs(newLogs)
    }
  }, [newLogs])

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: theme.palette.background.default,
          width: "80vw",
          height: "70vh",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          p: "1rem",
          borderRadius: 1,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[4],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            p: 1,
            height: "4rem",
          }}
        >
          <Selector
            list={channels}
            isLoading={false}
            handleSelect={handleSelect}
            value={channelSelected}
          />
          <IconButton
            onClick={onClose}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
            maxHeight: "calc(100% - 5rem)",
            height: isLoading ? "100%" : "auto",
          }}
        >
          <Table
            list={logs}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default RegisterModal