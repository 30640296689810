import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeHomeMultipleConversationStatus } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import OptionsContainer from '../../../OptionsContainer';
import { FormControlLabel, Switch } from '@mui/material';

interface Props {
  homeMultiConversationsEnabled: boolean;
}

const MultipleConversationsOptions = ({ homeMultiConversationsEnabled }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleHomeMultipleConversationStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log('event.target.checked', event.target.checked);
    dispatch(changeHomeMultipleConversationStatus(event.target.checked));
  };

  return (
    <OptionsContainer
      title={t('webChatEditView.designPanel.homeSection.multipleConversations')}
    >
      <FormControlLabel
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
        control={<Switch checked={homeMultiConversationsEnabled} />}
        label={t('global.enable')}
        labelPlacement="start"
        onChange={handleHomeMultipleConversationStatusChange}
      />
    </OptionsContainer>
  );
};

export default MultipleConversationsOptions;
