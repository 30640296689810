import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { pbxContext } from 'features/Views/Pbx/context/PbxProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectPbxCreateStatus } from 'redux/features/pbxSlice/pbxSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

function CreateButtons() {
  const pbxCreateStatus = useSelector(selectPbxCreateStatus);
  const { t } = useTranslation();
  const { endCreating, handleCreatePbx } = useContext(pbxContext);
  const isLoading = pbxCreateStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endCreating}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        disabled={isLoading}
        loading={isLoading}
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        onClick={handleCreatePbx}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
