import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export type UseInfoMessagesHook = {
  showMessages: boolean;
  setShowMessages: Dispatch<SetStateAction<boolean>>;
  toggleShowMessages: () => void;
};

export const useInfoMessagesInitialState = {
  showMessages: true,
  setShowMessages: () => {},
  toggleShowMessages: () => {},
};

const useInfoMessages = (): UseInfoMessagesHook => {
  const initialShowMessages = localStorage.getItem('showMessages') !== 'false';
  const [showMessages, setShowMessages] = useState<boolean>(initialShowMessages);

  useEffect(() => {
    localStorage.setItem('showMessages', String(showMessages));
  }, [showMessages]);

  function toggleShowMessages() {
    setShowMessages((prev) => !prev);
  }

  return {
    showMessages,
    setShowMessages,
    toggleShowMessages,
  };
};

export default useInfoMessages;
