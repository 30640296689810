import React, { createContext, useEffect, useRef, useState } from 'react';
// Redux
import { initRequestData } from 'redux/functions/initRequestData';
// Socket.IO
import { io, Socket } from 'socket.io-client';
// Types
import SocketContextType from './types/SocketContextType';
import SocketConnectionStatus from './types/SocketConnectionStatus';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
// Selector
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { getConversations } from 'redux/features/conversationsSlice/conversationsSlice';
import { FetchConversationsFilter } from 'redux/features/conversationsSlice/types/FetchConversationsFilter';

export const socketContext = createContext<SocketContextType>({
  socketConnection: null,
  connectionStatus: 'offline',
  subscribeEvent: () => {},
  unsubscribeEvent: () => {},
  emitEvent: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const SocketProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const [socketConnection, setSocketConnection] = useState<Socket | null>(null);
  const [connectionStatus, setConnectionStatus] =
    useState<SocketConnectionStatus>('offline');
  const [hasSocketConnectedOnce, setHasSocketConnectedOnce] = useState(false);

  const hasSocketConnectedOnceRef = useRef(hasSocketConnectedOnce);

  // Event functions
  const subscribeEvent = (eventName: string, callback: (...args: any[]) => void) => {
    if (socketConnection) {
      console.log('Trii Conversations - Subscribing to socket event: ', eventName);
      socketConnection.on(eventName, callback);
    } else {
      console.log(
        'Trii Conversations - SocketConnection is null to subscribe to event: ',
        eventName
      );
    }
  };
  const unsubscribeEvent = (eventName: string) => {
    if (socketConnection) {
      socketConnection.off(eventName);
    }
  };
  const emitEvent = (eventName: string, ...args: any[]) => {
    if (socketConnection) {
      socketConnection.emit(eventName, ...args);
    }
  };

  useEffect(() => {
    hasSocketConnectedOnceRef.current = hasSocketConnectedOnce;
  }, [hasSocketConnectedOnce]);

  useEffect(() => {
    if (socketConnection) return;

    const waitForSocketConnection = (attempts: number, delay: number) => {
      if (typeof window !== 'undefined' && (window as any).socketConnection) {
        const socket = (window as any).socketConnection;

        setSocketConnection(socket);

        const handleConnect = () => {
          console.log('Conversations Connected to Socket.IO server');

          setConnectionStatus('online');

          if (
            hasSocketConnectedOnceRef.current &&
            location.pathname
              .toLowerCase()
              .startsWith('/a/conversations/conversations')
          ) {
            const localCondition = localStorage.getItem('conversationFilter');

            if (localCondition) {
              dispatch(getConversations(localCondition as FetchConversationsFilter));
            } else {
              dispatch(getConversations('main'));
            }
          }

          setHasSocketConnectedOnce(true);
        };

        socket.on('connect', handleConnect);

        // If the socket is already connected, manually call the 'connect' event handler
        if (socket.connected) {
          handleConnect();
        }

        socket.on('disconnect', () => {
          setConnectionStatus('offline');
          console.log('Conversations Disconnected from Socket.IO server');
        });
      } else if (attempts > 0) {
        setTimeout(() => waitForSocketConnection(attempts - 1, delay), delay);
      } else {
        console.error('Failed to initialize socket connection');
      }
    };

    waitForSocketConnection(10, 500);
  }, [dispatch]);

  return (
    <socketContext.Provider
      value={{
        subscribeEvent,
        unsubscribeEvent,
        emitEvent,
        socketConnection,
        connectionStatus,
      }}
    >
      {children}
    </socketContext.Provider>
  );
};

export default SocketProvider;
