import React, { useState, useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TextField, Typography } from '@mui/material';

const SaveAs = () => {
  const { selectNode, setContactSearchNode, contactSearchNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [saveAs, setSaveAs] = useState<string>('');

  useEffect(() => {
    if (selectNode && selectNode.data.contactSearch) {
      setSaveAs(
        selectNode.data.contactSearch.saveAs
          ? selectNode.data.contactSearch.saveAs
          : ''
      );
    }
  }, [selectNode]);

  const handleChange = (saveAs: string) => {
    setSaveAs(saveAs);
    setContactSearchNode({
      ...contactSearchNode,
      saveAs,
    });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.contactSearch.saveAs')}
      </Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={saveAs}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};

export default SaveAs;
