import { UseSelect } from 'hooks/useSelect';

export const selectInitialDef: UseSelect = {
  attributes: {
    value: '',
    onChange: () => {},
  },
  actions: {
    resetValue: () => {},
    changeValue: () => {},
  },
};
