import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
import { v4 as uuidv4 } from 'uuid';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { WebChatEditState } from './types/WebChatEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import {
  IChannel,
  WebChatWidgetPosition,
  WebChatWidgetShape,
  WebChatChannel,
  Permission,
} from '@trii/types/dist/Common/Channels';
// Service
import webChatEditService from './webChatEditService';
import { AssignMethod } from '@trii/types/dist/Conversations';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';
import { UserInfo } from '@trii/types/dist/Users';

const initialState: WebChatEditState = {
  channel: null,
  webChatName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchWebChat = createAsyncThunk(
  'webChatEdit/fetchWebChat',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await webChatEditService.fetchWebChat(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateWebChat = createAsyncThunk<IChannel, void, { state: RootState }>(
  'webChatEdit/updateWebChat',
  async (_, { getState, dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const webChat = getState().WebChatEdit.channel;
    console.log(webChat);
    await webChatEditService.updateWebChat(jwtToken, URL_CONVERSATIONS, webChat);
    return webChat;
  }
);

export const uploadWebChatImage = createAsyncThunk(
  'webChatEdit/uploadWebChatImage',
  async (image: File, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    console.log(URL_MEDIA);
    const response = await webChatEditService.uploadImage(
      jwtToken,
      URL_MEDIA,
      image
    );

    return response;
  }
);

const webChatEditSlice = createSlice({
  name: 'webChatEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.webChat.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.webChat.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.webChat.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.webChat.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.webChat.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.channel.webChat.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.channel.webChat.schedule = schedule;
    },
    addPermission: {
      prepare: (data: {
        groupId?: string;
        userId?: string;
      }): { payload: Permission } => ({
        payload: {
          groupId: data.groupId || null,
          readMessages: false,
          startConversations: false,
          userId: data.userId || null,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(
      state,
      action: PayloadAction<{ groupId?: string; userId?: string }>
    ) {
      let index = -1;

      if (action.payload.groupId) {
        index = state.channel.permissions.findIndex(
          (permission) => permission.groupId === action.payload.groupId
        );
      } else {
        index = state.channel.permissions.findIndex(
          (permission) => permission.userId === action.payload.userId
        );
      }

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.readMessages = readMessages;
      } else if (permissionByUserId) {
        permissionByUserId.readMessages = readMessages;
      }
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.startConversations = startConversations;
      } else if (permissionByUserId) {
        permissionByUserId.startConversations = startConversations;
      }
    },
    changeStatusShowWidget(state, action: PayloadAction<boolean>) {
      state.channel.webChat.statusShowWidget = action.payload;
    },
    changeBackgroundColor(state, action: PayloadAction<string>) {
      state.channel.webChat.styleBackgroundColor = action.payload;
    },
    changeFontColor(state, action: PayloadAction<string>) {
      state.channel.webChat.styleFontColor = action.payload;
    },
    changeWidgetPosition(state, action: PayloadAction<WebChatWidgetPosition>) {
      state.channel.webChat.widgetPosition = action.payload;
    },
    changeWidgetShape(state, action: PayloadAction<WebChatWidgetShape>) {
      state.channel.webChat.widgetShape = action.payload;
    },
    changeWidgetTitle(state, action: PayloadAction<string>) {
      state.channel.webChat.widgetTitle = action.payload;
    },
    changeWidgetSubtitle(state, action: PayloadAction<string>) {
      state.channel.webChat.widgetSubTitle = action.payload;
    },
    changeWidgetCalloutStatus(state, action: PayloadAction<boolean>) {
      state.channel.webChat.widgetCalloutEnabled = action.payload;
    },
    changeWidgetCalloutTimer(state, action: PayloadAction<number>) {
      state.channel.webChat.widgetCalloutTimer = action.payload;
    },
    changeWidgetCalloutTitle(state, action: PayloadAction<string>) {
      state.channel.webChat.widgetCalloutTitle = action.payload;
    },
    changeWidgetCalloutMessage(state, action: PayloadAction<string>) {
      state.channel.webChat.widgetCalloutMessage = action.payload;
    },
    changeWidgetCalloutIcon(state, action: PayloadAction<string>) {
      state.channel.webChat.widgetCalloutIcon = action.payload;
    },
    changeHomeLogoStatus(state, action: PayloadAction<boolean>) {
      state.channel.webChat.homeLogoEnabled = action.payload;
    },
    changeHomeLogoUrl(state, action: PayloadAction<string>) {
      state.channel.webChat.homeLogoUrl = action.payload;
    },
    changeAssignTo(
      state,
      action: PayloadAction<{ group: GroupInfo; user: UserInfo }>
    ) {
      state.channel.assignTo = {
        groupInfo: action.payload.group,
        userInfo: action.payload.user,
      };
    },
    changeHomeTitle(state, action: PayloadAction<string>) {
      state.channel.webChat.homeTitle = action.payload;
    },
    changeHomeSubtitle(state, action: PayloadAction<string>) {
      state.channel.webChat.homeSubTitle = action.payload;
    },
    changeHomeMultipleConversationStatus(state, action: PayloadAction<boolean>) {
      state.channel.webChat.homeMultiConversationsEnabled = action.payload;
    },
    changeSurveyStatus(state, action: PayloadAction<boolean>) {
      state.channel.webChat.surveyEnabled = action.payload;
    },
    changeSurveyFormId(state, action: PayloadAction<string>) {
      state.channel.webChat.surveyFormId = action.payload;
    },
    changeWindowHeight(state, action: PayloadAction<number>) {
      state.channel.webChat.advanceMarginTop = action.payload;
    },
    changePreChatUserInfoEnabled(state, action: PayloadAction<boolean>) {
      state.channel.webChat.preChatUserInfoEnabled = action.payload;
    },
    changePreChatUserInfoEmailTitle(state, action: PayloadAction<string>) {
      state.channel.webChat.preChatUserInfoEmailTitle = action.payload;
    },
    changePreChatUserInfoNameTitle(state, action: PayloadAction<string>) {
      state.channel.webChat.preChatUserInfoNameTitle = action.payload;
    },
    changePreChatDataPrivacyPolicyEnabled(state, action: PayloadAction<boolean>) {
      state.channel.webChat.preChatDataPrivacyPolicyEnabled = action.payload;
    },
    changePreChatDataPrivacyPolicyDescription(state, action: PayloadAction<string>) {
      state.channel.webChat.preChatDataPrivacyPolicyDescription = action.payload;
    },
    changePreChatDataPrivacyPolicyUrl(state, action: PayloadAction<string>) {
      state.channel.webChat.preChatDataPrivacyPolicyLink = action.payload;
    },
    changeAssignMethod(state, action: PayloadAction<AssignMethod>) {
      state.channel.assignMethod = action.payload;
    },
    addHomeChannelsWhatsApp(state) {
      const whatsAppHomeChannel: WebChatChannel = {
        id: uuidv4(),
        order: 0,
        imageUrl: '',
        title: '',
        detail: '',
        address: '',
      };
      state.channel.webChat.homeChannelsWhatsApp.push(whatsAppHomeChannel);
    },
    removeHomeChannelsWhatsApp(state, action: PayloadAction<string>) {
      const channelId = action.payload;
      const index = state.channel.webChat.homeChannelsWhatsApp.findIndex(
        (channel) => channel.id === channelId
      );

      state.channel.webChat.homeChannelsWhatsApp.splice(index, 1);
    },
    changeHomeChannelsWhatsAppName(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsWhatsApp.find(
        (channel) => channel.id === channelId
      );

      channel.title = newValue;
    },
    changeHomeChannelsWhatsAppDetail(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsWhatsApp.find(
        (channel) => channel.id === channelId
      );

      channel.detail = newValue;
    },
    changeHomeChannelsWhatsAppNumber(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsWhatsApp.find(
        (channel) => channel.id === channelId
      );

      channel.address = newValue;
    },
    changeHomeChannelsWhatsAppImageUrl(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsWhatsApp.find(
        (channel) => channel.id === channelId
      );

      channel.imageUrl = newValue;
    },
    addHomeChannelsMessenger(state) {
      const messengerHomeChannel: WebChatChannel = {
        id: uuidv4(),
        order: 0,
        imageUrl: '',
        title: '',
        detail: '',
        address: '',
      };
      state.channel.webChat.homeChannelsMessenger.push(messengerHomeChannel);
    },
    removeHomeChannelsMessenger(state, action: PayloadAction<string>) {
      const channelId = action.payload;
      const index = state.channel.webChat.homeChannelsMessenger.findIndex(
        (channel) => channel.id === channelId
      );

      state.channel.webChat.homeChannelsMessenger.splice(index, 1);
    },
    changeHomeChannelsMessengerName(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsMessenger.find(
        (channel) => channel.id === channelId
      );

      channel.title = newValue;
    },
    changeHomeChannelsMessengerDetail(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsMessenger.find(
        (channel) => channel.id === channelId
      );

      channel.detail = newValue;
    },
    changeHomeChannelsMessengerFacebookId(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsMessenger.find(
        (channel) => channel.id === channelId
      );

      channel.address = newValue;
    },
    changeHomeChannelsMessengerImageUrl(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsMessenger.find(
        (channel) => channel.id === channelId
      );

      channel.imageUrl = newValue;
    },
    addHomeChannelsPhone(state) {
      const cellphoneHomeChannel: WebChatChannel = {
        id: uuidv4(),
        order: 0,
        imageUrl: '',
        title: '',
        detail: '',
        address: '',
      };
      state.channel.webChat.homeChannelsPhone.push(cellphoneHomeChannel);
    },
    removeHomeChannelsPhone(state, action: PayloadAction<string>) {
      const channelId = action.payload;
      const index = state.channel.webChat.homeChannelsPhone.findIndex(
        (channel) => channel.id === channelId
      );

      state.channel.webChat.homeChannelsPhone.splice(index, 1);
    },
    changeHomeChannelsPhoneName(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsPhone.find(
        (channel) => channel.id === channelId
      );

      channel.title = newValue;
    },
    changeHomeChannelsPhoneDetail(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsPhone.find(
        (channel) => channel.id === channelId
      );

      channel.detail = newValue;
    },
    changeHomeChannelsPhoneNumber(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsPhone.find(
        (channel) => channel.id === channelId
      );

      channel.address = newValue;
    },
    changeHomeChannelsPhoneImageUrl(
      state,
      action: PayloadAction<{ channelId: string; newValue: string }>
    ) {
      const { channelId, newValue } = action.payload;
      const channel = state.channel.webChat.homeChannelsPhone.find(
        (channel) => channel.id === channelId
      );

      channel.imageUrl = newValue;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWebChat.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchWebChat.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched WebChat: ', action.payload);
        state.channel = action.payload;
        state.webChatName = action.payload.name;
      })
      .addCase(updateWebChat.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateWebChat.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.webChatName = action.payload.name;
      })
      .addCase(updateWebChat.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      });
  },
});

const selectWebChatEditState = (state: RootState) => state.WebChatEdit;
export const selectWebChat = createSelector(
  selectWebChatEditState,
  (state) => state.channel
);
export const selectWebChatName = createSelector(
  selectWebChatEditState,
  (state) => state.webChatName
);
export const selectWebChatFetchStatus = createSelector(
  selectWebChatEditState,
  (state) => state.status.fetch
);
export const selectWebChatUpdateStatus = createSelector(
  selectWebChatEditState,
  (state) => state.status.update
);

export const {
  changeName,
  changeToken,
  changeAssignMethod,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  changeStatusShowWidget,
  changeBackgroundColor,
  changeFontColor,
  changeAssignTo,
  changeWidgetPosition,
  changeWidgetShape,
  changeWidgetTitle,
  changeWidgetSubtitle,
  changeWidgetCalloutStatus,
  changeWidgetCalloutTimer,
  changeWidgetCalloutTitle,
  changeWidgetCalloutMessage,
  changeHomeLogoStatus,
  changeHomeLogoUrl,
  changeHomeTitle,
  changeHomeSubtitle,
  changeHomeMultipleConversationStatus,
  changeSurveyStatus,
  changeSurveyFormId,
  changeWindowHeight,
  changePreChatUserInfoEnabled,
  changePreChatUserInfoEmailTitle,
  changePreChatUserInfoNameTitle,
  changePreChatDataPrivacyPolicyEnabled,
  changePreChatDataPrivacyPolicyDescription,
  changePreChatDataPrivacyPolicyUrl,
  addHomeChannelsWhatsApp,
  removeHomeChannelsWhatsApp,
  changeHomeChannelsWhatsAppName,
  changeHomeChannelsWhatsAppDetail,
  changeHomeChannelsWhatsAppNumber,
  addHomeChannelsMessenger,
  removeHomeChannelsMessenger,
  changeHomeChannelsMessengerName,
  changeHomeChannelsMessengerDetail,
  changeHomeChannelsMessengerFacebookId,
  addHomeChannelsPhone,
  removeHomeChannelsPhone,
  changeHomeChannelsPhoneName,
  changeHomeChannelsPhoneDetail,
  changeHomeChannelsPhoneNumber,
  changeWidgetCalloutIcon,
  changeHomeChannelsWhatsAppImageUrl,
  changeHomeChannelsMessengerImageUrl,
  changeHomeChannelsPhoneImageUrl,
} = webChatEditSlice.actions;

export default webChatEditSlice.reducer;
