import { Box } from '@mui/material'
import { ItemDetails } from './components'

const PublicationSidePanel = () => {
  return (
    <Box
      bgcolor="background.default"
      color="text.primary"
      width="30%"
      height="100%"
      sx={{
        borderTop: theme => `1px solid ${theme.palette.divider}`,
        overflow: "hidden",
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        borderLeft: theme => `1px solid ${theme.palette.divider}`,
        '@media (max-width: 1024px)': {
          width: '100%',
        },
      }}
    >
      <ItemDetails />
    </Box>
  );
}

export default PublicationSidePanel