// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import {
  Box,
  Paper,
  Typography,
  styled
} from "@mui/material";

interface Props {
  contactId: string;
}

const Duplicates = ({
  contactId,
}: Props) => {
  const { t } = useTranslation();
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    lineHeight: "60px",
    background: "#fcac34",
    width: "100%",
    // borderRadius: "5px",
    display: "flex",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "5px",
    paddingLeft: "5px",
  }));

  const handleNavigateToDuplicates = (event: React.MouseEvent) => {
    if (event.button === 1 || event.button === 0 ) {
      window.open(`/a/contacts/duplicates?contactId=${contactId}`, "_blank");
    }
  }

  return (
    <Item elevation={1}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography
          sx={{ textWrap: "nowrap", cursor: "default" }}
          variant="subtitle2"
          fontWeight="semi-bold"
          color={"text.disabled"}
        >
          {t('conversations.sidebar.duplicates.title')}
        </Typography>
        <Typography
          sx={{
            textWrap: "nowrap", cursor: "pointer",
            "&:hover": {
              textDecoration: "underline"
            }
          }}
          variant="subtitle2"
          color={"text.primary"}
          onMouseDown={(e) => handleNavigateToDuplicates(e)}
        >
          {t('conversations.sidebar.duplicates.view').toUpperCase()}
        </Typography>
      </Box>
    </Item>
  );
};

export default Duplicates;
