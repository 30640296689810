// Redux
import { useSelector } from "react-redux";
// Selector
import { selectScheduleStatus } from "redux/features/scheduleSlice/scheduleSlice";
//Components/ui
import { ChannelEditBodyContainer } from "components";
import { Divider } from "@mui/material";
// Components
import {
  HolidaysList,
  Inputs,
  ScheduleList,
  Skeleton,
} from "./components";

interface Props {
  className: string;
}

const Body = ({ className }: Props) => {
  const scheduleStatus = useSelector(selectScheduleStatus);
  const isLoading = scheduleStatus === "loading";

  return (
    <ChannelEditBodyContainer className={className}>
      {
        isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Inputs />
            <Divider />
            <ScheduleList />
            <Divider />
            <HolidaysList />
          </>
        )
      }
    </ChannelEditBodyContainer>
  );
};

export default Body;
