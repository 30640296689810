import Box from '@mui/material/Box';
import PublicationContent from './PublicationContent';
import PublicationHeader from './PublicationHeader';

export default function PublicationPanel() {
  return (
    <Box
      display="flex"
      height="100%"
      maxHeight={'100%'}
      flexGrow={1}
      width="76.6%"
      sx={{
        '@media (max-width: 1024px)': {
          width: 'auto',
        },
      }}
      flexDirection="column"
    >
      <PublicationHeader />
      <PublicationContent />
    </Box>
  );
}
