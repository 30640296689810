import { Box, useTheme } from '@mui/material'
import ChatContainer from './components/ChatContainer';

const PublicationChat = () => {
  const theme = useTheme()
  return (
    <Box
      bgcolor="background.default"
      color="text.primary"
      width="70%"
      height="100%"
      sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: "0px",
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        '@media (max-width: 1024px)': {
          minWidth: '330px',
        },
      }}
    >
      <ChatContainer />
    </Box>
  );
}

export default PublicationChat