import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseFieldType } from 'hooks/useField';
import { UseSelect } from 'hooks/useSelect';
// Components/ui
import {
  Box,
  Card,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@mui/material';

interface ApiSectionProps {
  urlFieldState: UseFieldType;
  methodSelectState: UseSelect;
  authTypeSelectState: UseSelect;
  usernameFieldState: UseFieldType;
  passwordFieldState: UseFieldType;
  fixedTokenFieldState: UseFieldType;
  dynamicUrlFieldState: UseFieldType;
  bearerTokenBodyFieldState: UseFieldType;
  apiKeyFieldState: UseFieldType;
  apiValueFieldState: UseFieldType;
  paramsBodyFieldState: UseFieldType;
  paramsContentTypeFieldState: UseFieldType;
}

const ApiSection = ({
  urlFieldState,
  methodSelectState,
  authTypeSelectState,
  usernameFieldState,
  passwordFieldState,
  fixedTokenFieldState,
  dynamicUrlFieldState,
  bearerTokenBodyFieldState,
  apiKeyFieldState,
  apiValueFieldState,
  paramsBodyFieldState,
  paramsContentTypeFieldState,
}: ApiSectionProps) => {
  const { t } = useTranslation();
  const authType = authTypeSelectState.attributes.value;

  const handleAuthTypeChange = () => {
    usernameFieldState.actions.resetValue();
    passwordFieldState.actions.resetValue();
    fixedTokenFieldState.actions.resetValue();
    dynamicUrlFieldState.actions.resetValue();
    bearerTokenBodyFieldState.actions.resetValue();
    apiKeyFieldState.actions.resetValue();
    apiValueFieldState.actions.resetValue();
  };

  useEffect(() => {
    handleAuthTypeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authType]);

  return (
    <Card
      sx={{
        margin: 2,
      }}
    >
      <Box
        display={'flex'}
        justifyContent="space-between"
        alignItems={'center'}
        py={1}
        px={2}
        sx={{
          //@ts-ignore
          backgroundColor: (color) => color.palette.background.panelHeader,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: (color) => color.palette.text.primary }}
        >
          API
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'start'}
        p={2}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        }}
      >
        <FormControl
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        >
          <InputLabel>{t('webHooksView.creationPanel.method')}</InputLabel>
          {/* @ts-ignore */}
          <Select label="Metodo" {...methodSelectState.attributes}>
            <MenuItem value={1}>GET</MenuItem>
            <MenuItem value={2}>POST</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="fadein"
          variant="outlined"
          label="URL"
          size="small"
          {...urlFieldState.attributes}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            marginBottom: '15px',
          }}
        />
        <Divider
          sx={{
            marginBottom: '10px',
            width: '100%',
          }}
        ></Divider>
        <Typography
          sx={{
            marginBottom: '10px',
          }}
        >
          Authorization type
        </Typography>
        <FormControl
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        >
          <InputLabel>{t('webHooksView.creationPanel.security')}</InputLabel>
          {/* @ts-ignore */}
          <Select {...authTypeSelectState.attributes} label="Seguridad">
            <MenuItem value={0}>Ninguna</MenuItem>
            <MenuItem value={1}>Basic</MenuItem>
            <MenuItem value={2}>API-key</MenuItem>
            <MenuItem value={3}>Bearer Token</MenuItem>
          </Select>
        </FormControl>
        {authType === 1 ? (
          <>
            <TextField
              {...usernameFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Basic - Username"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              {...passwordFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              label="Basic - Password"
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
          </>
        ) : authType === 2 ? (
          <Box display={'flex'} gap={1}>
            <TextField
              className="fadein"
              variant="outlined"
              {...apiKeyFieldState.attributes}
              size="small"
              // fullWidth

              label="Key"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                marginBottom: '15px',
                width: '50%',
              }}
            ></TextField>
            <TextField
              className="fadein"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              {...apiValueFieldState.attributes}
              size="small"
              // fullWidth

              label="Value"
              sx={{
                marginBottom: '15px',
                width: '50%',
              }}
            ></TextField>
          </Box>
        ) : authType === 3 ? (
          <>
            <TextField
              {...fixedTokenFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Fix Token"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              {...dynamicUrlFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Dynamic URL"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              {...bearerTokenBodyFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Body"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
          </>
        ) : null}
        <Divider
          sx={{
            marginBottom: '10px',
            width: '100%',
          }}
        ></Divider>
        <Typography
          sx={{
            marginBottom: '10px',
          }}
        >
          {t('webHooksView.creationPanel.parameters')}
        </Typography>
        <TextField
          multiline
          label={'body'}
          InputLabelProps={{
            shrink: true,
          }}
          rows={4}
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
          {...paramsBodyFieldState.attributes}
        />
        <TextField
          className="fadein"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          {...paramsContentTypeFieldState.attributes}
          label="ContentType"
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        />
      </Box>
    </Card>
  );
};

export default ApiSection;
