import { Card, Metric, Text, Title, Flex } from "@tremor/react";
import { useTheme } from '@mui/material/styles';
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


const GroupUserCard = ({generalData}) => {

  const theme = useTheme();
  const cardStyle = {
    //@ts-ignore
    backgroundColor: theme.palette.background.panel,
    minWidth: "200px"
  };
  const {t} = useTranslation()
  
  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      }}
    >
      <Title style={{ color: theme.palette.text.primary }}>
        {t('dashboard.generalDistribution')}
      </Title>
      {/* <Grid numCols={listMock.length} className="mt-3 gap-x-10">
        {listMock.map((item, index) => (
          <Col numColSpan={1} key={index}>
            <Card className="max-w-md" key={item.name}>
              <div className="truncate">
                <img
                  src={item.img}
                  alt=""
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "50%", display: "initial" }}
                />
                <Text className="text-center">{item.name}</Text>
                <Metric className="mt-2 text-center">{item.cant}</Metric>
              </div>
            </Card>
          </Col>
        ))}
      </Grid> */}
      <Flex
        className="gap-6 mt-4 overflow-x-auto pb-1.5"
        style={{
          maxWidth: "calc(100vw - 120px)",
          paddingRight: "5px",
          paddingLeft: "5px",
        }}
      >
        {generalData &&
          generalData.map((item, index) => (
            <Card
              key={index}
              decoration="top"
              className="p-2 max-w-xs width-sm"
              style={cardStyle}
            >
              <Flex justifyContent="start" className="space-x-4">
                {/* <img
                  src={`${__webpack_public_path__}${item.imageUrl}`}
                  alt=""
                  width="40px"
                  height="40px"
                  style={{ borderRadius: "50%", display: "initial" }}
                /> */}
                <Avatar
                  alt={item?.name}
                  src={item?.imageUrl}
                  style={{ width: '40px', height: '40px', borderRadius: "50%" }}
                >
                  {item?.imageUrl
                    ? null
                    : item?.name
                    ? item.name.charAt(0).toUpperCase()
                    : ""}
                </Avatar>
                <div
                  className="truncate"
                  style={{ color: theme.palette.text.primary }}
                >
                  <Text>{item.name}</Text>
                  <Metric className="truncate text-2xl">{item.quantity}</Metric>
                </div>
              </Flex>
            </Card>
          ))}
      </Flex>
    </Card>
  );
};

export default GroupUserCard;
