import { ChangeEvent, createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchTemplates } from 'redux/features/templatesSlice/templatesSlice';
import {
  setIsReplyingEmail,
  uploadMedia,
} from 'redux/features/messagesSlice/messagesSlice';

import {
  createConversation,
  fetchChannels,
  fetchGroups,
  fetchOpenInFooter,
  fetchNewChat,
  finalizeConversation,
  getContactInfo,
  inConversation,
  modifyConversationLabel,
  outConversation,
  transferConversation,
  fetchChats,
  fetchConversationsOfContact,
  selectConversationSelected,
  setConversationSelected,
  readConversation,
  selectConversations,
  addNewConversation,
  setChats,
  setNewConversations,
  selectContactInfo,
} from 'redux/features/conversationsSlice/conversationsSlice';
import {
  fetchEndings,
  selectEndingsFetchStatus,
} from 'redux/features/endingSlice/endingSlice';
import { fetchLabels } from 'redux/features/labelSlice/labelSlice';
import {
  fetchContactInfo,
  fetchDeleteFile,
  fetchDeleteNote,
  fetchFiles,
  fetchHistoryLogs,
  fetchNewFile,
  fetchNewNote,
  fetchNotes,
  fetchUpdateNote,
  resetContacts,
  setIsEditingName,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';
import { fetchUsers } from 'redux/features/userSlice/userSlice';
// Dexie
import db, { dbWorker } from 'db/db';
import { useLiveQuery } from 'dexie-react-hooks';
// Types
import { ContactNotes } from 'redux/features/contactInfoSlice/types/ContactNotes';
import { ConversationsContext } from './types/ConversationsContext';
import { ConversationsProviderProps } from './types/ConversationsProviderProps';
import {
  ChatType,
  ConversationSatus,
  IChatMemeber,
  IConversation,
} from '@trii/types/dist/Conversations';
import { IMessage, MessageImage } from '@trii/types/dist/Common/Messages';
import { ImageIcon } from '../../../../../types/ImageIcon';
import { TransferConversationData } from 'redux/features/conversationsSlice/types/TransferConversationData';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { IBusiness, IContact, IContactInfo, IFile } from '@trii/types/dist/Contacts';
import { OpenInFooter } from 'redux/features/conversationsSlice/types/OpenInFooter';
import { ContactFile } from 'redux/features/contactInfoSlice/types/ContactFile';
import { ConversationsOfContact } from 'redux/features/conversationsSlice/types/ConversationsOfContact';
import { AssignedTo, CreateConversation } from './types/CreateConversation';
import { ModifyConversationLabelData } from 'redux/features/conversationsSlice/types/ModifyConversationLabelData';
import { Chat } from 'redux/features/conversationsSlice/types/Chat';
// Translations
import { useTranslation } from 'react-i18next';
// Functions
import getImage from 'functions/getImage';
// ID
import { v4 as uuidv4 } from 'uuid';
import { UploadURLParams } from 'redux/features/messagesSlice/types/UploadURLParams';

export const conversationsContext = createContext<ConversationsContext>({
  conversationNotFound: false,
  setConversationNotFound: (boolean: boolean) => {},
  loadContactDetails: () => {},
  handleOpenForwardModal: () => {},
  conversationSelected: null,
  handleEscapeConversation: () => {},
  conversations: [],
  conversationsDb: [],
  handleSelectConversation: (
    conversation: IConversation,
    incognitoMode: boolean = false
  ) => {},
  selectMessagesMode: false,
  setSelectMessagesMode: () => {},
  selectedMessages: [],
  setSelectedMessages: (messages: IMessage[]) => {},
  openImageViewer: false,
  setOpenImageViewer: (boolean: boolean) => {},
  imageViewerSrc: [],
  setImageViewerSrc: (imageList: MessageImage[]) => {},
  currentIndex: 0,
  setCurrentIndex: (index: number) => {},
  openModalList: false,
  setOpenModalList: (boolean: boolean) => {},
  handleFinalizeConversation: (conversationId: string) => {},
  // openActionContact: false,
  // setOpenActionContact: (boolean: boolean) => {},
  conversationId: '',
  setConversationId: (conversationId: string) => {},
  modalTitle: '',
  setModalTitle: (title: string) => {},
  handleTransferTo: (data: TransferConversationData) => {},
  channel: null,
  setChannel: (channel: ImageIcon) => {},
  getImage: (type: number) => {
    return null;
  },
  isEmailMode: false,
  setIsEmailMode: (boolean: boolean) => {},
  getUserInfo: (userId: string) => {},
  getChannelList: (channel: ChannelType) => {},
  contactInfo: null,
  businessInfo: null,
  setContactInfo: (contactInfo: IContact) => {},
  setBusinessInfo: (businessInfo: IBusiness) => {},
  handleOpenInFooter: (data: OpenInFooter) => {},
  expandedExternal: true,
  setExpandedExternal: (boolean: boolean) => {},
  expandedInternal: false,
  setExpandedInternal: (boolean: boolean) => {},
  handleChangeAccordion: (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    state: boolean
  ) => {},
  getContact: (contactId: string) => {},
  getEndings: () => {},
  getUsers: () => {},
  getGroups: () => {},
  getLabels: () => {},
  // Internal Chat
  internalGroupInfo: [],
  setInternalGroupInfo: (internalGroupInfo: IChatMemeber[]) => {},
  setConversation: (data: CreateConversation) => {
    return new Promise(() => {});
  },
  originSelected: '',
  setOriginSelected: (originSelected: string) => {},
  destinationSelected: [],
  setDestinationSelected: (destinationSelected: string[]) => {},
  assignedTo: null,
  setAssignedTo: (assignedTo: AssignedTo) => {},
  contactSelected: null,
  setContactSelected: (contactSelected: IContactInfo) => {},
  handleExitConversation: () => {},
  handleAddLabel: (data: ModifyConversationLabelData) => {},
  // Internal Chat
  setInternalChat: (data: Chat) => {},
  getInternalChats: () => {},
  // Notes
  getNotes: (contactId: string) => {},
  setNewNote: (data: ContactNotes) => {},
  updateNote: (data: ContactNotes) => {},
  deleteNote: (data: ContactNotes) => {},
  // Files
  getFiles: (contactId: string) => {},
  filesToUpload: [],
  setFilesToUpload: (files: IFile[]) => {},
  handleUploadFile: (event: ChangeEvent<HTMLInputElement>) => {},
  setNewFile: (data: ContactFile) => {
    return new Promise(() => {});
  },
  deleteFile: (fileId: string) => {},
  // Activities
  getActivities: (contactId: string) => {},
  // Conversations of Contact
  conversationsActiveOfContact: [],
  setConversationsActiveOfContact: (conversations: IConversation[]) => {},
  conversationsFinalizedOfContact: [],
  setConversationsFinalizedOfContact: (conversations: IConversation[]) => {},
  getConversationsOfContact: (data: ConversationsOfContact) => {},
  // Alert
  error: false,
  setError: (boolean: boolean) => {},
  errorMessage: '',
  setErrorMessage: (message: string) => {},
  handleCloseAlert: () => {},
});

const ConversationsProvider = ({ children }: ConversationsProviderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const contactId = searchParams.get('contactId');

  const conversationSelected = useSelector(selectConversationSelected);
  const conversations = useSelector(selectConversations);
  const spaceInfo = useSelector(selectSpaceInfo);
  const user = useSelector(selectUser);
  const fetchStatus = useSelector(selectEndingsFetchStatus);
  const selectedContactInfo = useSelector(selectContactInfo);

  // Internal Chat
  const [internalGroupInfo, setInternalGroupInfo] = useState<IChatMemeber[]>([]);
  const [selectMessagesMode, setSelectMessagesMode] = useState<boolean>(false);
  const [selectedMessages, setSelectedMessages] = useState<IMessage[]>([]);
  const [openImageViewer, setOpenImageViewer] = useState<boolean>(false);
  const [imageViewerSrc, setImageViewerSrc] = useState<MessageImage[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openModalList, setOpenModalList] = useState(false);
  // const [openActionContact, setOpenActionContact] = useState(false);
  const [conversationId, setConversationId] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [channel, setChannel] = useState<ImageIcon>(null);
  const [isEmailMode, setIsEmailMode] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<IContact>(null);
  const [businessInfo, setBusinessInfo] = useState<IBusiness>(null);
  const [expandedExternal, setExpandedExternal] = useState<boolean>(true);
  const [expandedInternal, setExpandedInternal] = useState<boolean>(false);
  const [originSelected, setOriginSelected] = useState<string>('');
  const [destinationSelected, setDestinationSelected] = useState<string[]>(null);
  const [assignedTo, setAssignedTo] = useState<AssignedTo>(null);
  // Conversations of contact
  const [contactSelected, setContactSelected] = useState<IContactInfo>(null);
  const [conversationsActiveOfContact, setConversationsActiveOfContact] = useState<
    IConversation[]
  >([]);
  const [conversationsFinalizedOfContact, setConversationsFinalizedOfContact] =
    useState<IConversation[]>([]);
  // Files
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  // Alert
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [conversationNotFound, setConversationNotFound] = useState(false);

  const conversationsDb = useLiveQuery(
    () => db.conversations.where('spaceId').equals(spaceInfo.id).toArray(),
    [spaceInfo]
  );

  const isLoading = fetchStatus === 'loading';

  async function loadContactDetails(contactId: string) {
    getContact(contactId);
    getUsers();
  }

  const handleSelectConversation = async (
    conversation: IConversation,
    incognitoMode: boolean = false
  ) => {
    navigate(`/a/conversations/conversations`);

    setConversationNotFound(false);

    dispatch(setIsEditingName(false));
    dispatch(setConversationSelected(conversation));
    dispatch(setIsReplyingEmail(false));

    if (conversation?.channelInfo?.type === ChannelType.EMAIL) {
      setIsEmailMode(true);
    } else {
      setIsEmailMode(false);
    }

    if (conversation?.contactInfo?.id !== contactInfo?.id) {
      setContactInfo(null);
    }

    if (!incognitoMode) {
      dispatch(inConversation(conversation.id));
    }

    if (conversation?.newMessagesCount > 0) {
      dispatch(
        readConversation({
          conversationId: conversation.id,
          type: 'external',
        })
      );
    }

    const type = getImage(conversation?.channelInfo?.type);

    setChannel(type);

    if (conversation?.contactInfo?.id) {
      const contactId = conversation.contactInfo.id;
      await loadContactDetails(contactId);
    }

    if (
      conversation.type !== ChatType.DIRECT &&
      conversation.type !== ChatType.GROUP
    ) {
      dispatch(fetchTemplates(conversation.channelInfo.id));
    }

    // Select chat input and focus
    const conversationInput = document.getElementById('conversation-input');

    if (conversationInput && !contactId) {
      conversationInput.focus();
    }
  };

  const handleFinalizeConversation = async (conversationId: string) => {
    if (isLoading) return;

    setModalTitle(t('endingsView.endings'));
    setConversationId(conversationId);

    const endingsFetch = await dispatch(fetchEndings());
    const endings = endingsFetch.payload;

    if (endings && endings.length > 0) {
      setOpenModalList(true);
    } else {
      dispatch(
        finalizeConversation({
          conversationId,
        })
      );
      const getConversation = conversations.find(
        (conversation) => conversation.id === conversationId
      );
      if (getConversation) {
        const newConversation = [
          ...conversationsFinalizedOfContact,
          {
            ...getConversation,
            finalizedAt: new Date(),
          },
        ];
        setConversationsFinalizedOfContact(newConversation);
      }
      const filterConversations = conversationsActiveOfContact.filter(
        (conversation) => conversation.id !== conversationId
      );
      setConversationsActiveOfContact(filterConversations);
      if (conversationSelected && conversationSelected?.id === conversationId) {
        dispatch(setConversationSelected(null));
      }
    }
  };

  const handleTransferTo = (data: TransferConversationData) => {
    dispatch(transferConversation(data));
    if (conversationSelected && conversationSelected?.id === conversationId) {
      dispatch(setConversationSelected(null));
    }
  };

  // Files
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);

    if (documents.length === 0) {
      return;
    }

    // const URLParams: UploadURLParams = {
    //   module: 'messages',
    //   folderType: 'files',
    // };
    const createdBy = {
      id: user.uid,
      name: user.display_name,
      imageUrl: user.imageUrl,
      isActive: user.isActive,
      userType: user.userType,
      verifiedAccount: user.verifiedAccount,
      email: user.email,
      phone: user.phone,
      status: user.status,
      sipNumber: user.sipExtensionConfig.extension,
    };
    const contactId = conversationSelected.contactInfo
      ? conversationSelected.contactInfo.id
      : user.uid;

    documents.forEach(async (file) => {
      const id = uuidv4();
      const { type, name, size } = file;
      const data: IFile = {
        id,
        spaceId: spaceInfo.id,
        contactId,
        fileName: name,
        mimeType: type,
        size: size,
        url: '',
        createdAt: new Date(),
        //@ts-ignore
        createdBy,
      };

      setFilesToUpload((prevFiles) => [...prevFiles, data]);

      const formData = new FormData();

      formData.append('file', file);

      const URLParams: UploadURLParams = {
        module: 'contacts',
        folderType: 'files',
      };

      await dispatch(
        uploadMedia({
          file: formData,
          name: id,
          id: id,
          URLParams,
        })
      );
    });
  };

  const handleAddLabel = (data: ModifyConversationLabelData) => {
    dispatch(modifyConversationLabel(data));
  };

  const getContact = async (contactId: string) => {
    await dispatch(getContactInfo(contactId));
  };

  const getEndings = async () => {
    await dispatch(fetchEndings());
  };

  const getGroups = async () => {
    await dispatch(fetchGroups());
  };

  const getUsers = async () => {
    await dispatch(fetchUsers());
  };

  const getLabels = async () => {
    await dispatch(fetchLabels());
  };

  const getUserInfo = async (userId: string) => {
    await dispatch(fetchContactInfo(userId));
  };

  const getChannelList = async (channelType: ChannelType) => {
    await dispatch(fetchChannels(channelType));
  };

  const setInternalChat = async (data: Chat) => {
    const response = await dispatch(fetchNewChat(data));
    if (response.payload) {
      const chat = response.payload;
      const newChats = [...conversationsDb, chat];

      dbWorker.postMessage({
        action: 'updateChats',
        data: newChats,
      });
      setExpandedInternal(true);
    }
  };

  // Internal Chat
  const getInternalChats = async () => {
    const response = await dispatch(fetchChats('chat'));
    if (response.payload) {
      const chats = response.payload;

      dbWorker.postMessage({
        action: 'updateChats',
        data: chats,
      });
    }
  };

  const handleOpenInFooter = (data: OpenInFooter) => {
    dispatch(fetchOpenInFooter(data));
  };

  const handleExitConversation = () => {
    if (conversationSelected) {
      dispatch(outConversation(conversationSelected.id));
      dispatch(setConversationSelected(null));
    }
  };

  const handleEscapeConversation = () => {
    if (conversationSelected) {
      dispatch(setConversationSelected(null));
    }
  };

  const handleChangeAccordion = (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    state: boolean
  ) => {
    setState(!state);
  };

  const handleCloseAlert = () => {
    setError(false);
    setErrorMessage('');
  };

  const setConversation = async (data: CreateConversation): Promise<boolean> => {
    const response = await dispatch(createConversation(data));
    if (response.payload.status === 409) {
      const { name } = response.payload?.data?.contactInfo;
      setError(true);
      setErrorMessage(
        t('conversations.createConversation.error.alreadyExists', { contact: name })
      );
      return false;
    } else {
      const newCoverstation = response.payload;
      setConversationsActiveOfContact([
        ...conversationsActiveOfContact,
        newCoverstation,
      ]);
      // setConversations([...conversationsDb, newCoverstation]);
      dispatch(addNewConversation(newCoverstation));

      // Inside conversation
      handleSelectConversation(newCoverstation);
      return true;
    }
  };

  // Notes
  const getNotes = async (contactId: string) => {
    await dispatch(fetchNotes(contactId));
  };

  const setNewNote = async (data: ContactNotes) => {
    await dispatch(fetchNewNote(data));
  };

  const updateNote = async (data: ContactNotes) => {
    await dispatch(fetchUpdateNote(data));
  };

  const deleteNote = async (data: ContactNotes) => {
    await dispatch(fetchDeleteNote(data));
  };

  // Files
  const getFiles = async (contactId: string) => {
    await dispatch(fetchFiles(contactId));
  };

  const setNewFile = async (data: ContactFile): Promise<IFile> => {
    const response = await dispatch(fetchNewFile(data));
    return response.payload;
  };

  const deleteFile = async (fileId: string) => {
    await dispatch(
      fetchDeleteFile({
        contactId: conversationSelected.contactInfo.id,
        fileId,
      })
    );
  };

  // Activities
  const getActivities = async (contactId: string) => {
    await dispatch(fetchActivities(contactId));
  };

  // Conversations of Contact
  const getConversationsOfContact = async (data: ConversationsOfContact) => {
    await dispatch(fetchConversationsOfContact(data));
  };

  // Conversation modal
  const handleOpenForwardModal = () => {
    setModalTitle(t('conversations.message.actions.forward'));
    setOpenModalList(true);
  };

  useEffect(() => {
    if (selectedContactInfo) {
      setContactInfo(selectedContactInfo);
    }
  }, [selectedContactInfo]);

  useEffect(() => {
    if (conversationsDb) {
      dispatch(setNewConversations(conversationsDb));
    }
  }, [conversationsDb]);

  useEffect(() => {
    dispatch(resetContacts());
  }, [openModalList]);

  useEffect(() => {
    return () => {
      dispatch(setConversationSelected(null));
    };
  }, []);

  return (
    <conversationsContext.Provider
      value={{
        conversationNotFound,
        setConversationNotFound,
        loadContactDetails,
        handleOpenForwardModal,
        handleEscapeConversation,
        conversations,
        conversationsDb,
        conversationSelected,
        // setConversations,
        handleSelectConversation,
        selectMessagesMode,
        setSelectMessagesMode,
        selectedMessages,
        setSelectedMessages,
        openImageViewer,
        setOpenImageViewer,
        imageViewerSrc,
        setImageViewerSrc,
        currentIndex,
        setCurrentIndex,
        openModalList,
        setOpenModalList,
        handleFinalizeConversation,
        // openActionContact,
        // setOpenActionContact,
        conversationId,
        setConversationId,
        modalTitle,
        setModalTitle,
        handleTransferTo,
        channel,
        setChannel,
        getImage,
        isEmailMode,
        setIsEmailMode,
        getUserInfo,
        getChannelList,
        contactInfo,
        setContactInfo,
        businessInfo,
        setBusinessInfo,
        handleOpenInFooter,
        expandedExternal,
        setExpandedExternal,
        expandedInternal,
        setExpandedInternal,
        handleChangeAccordion,
        getContact,
        getEndings,
        getGroups,
        getUsers,
        getLabels,
        setConversation,
        originSelected,
        setOriginSelected,
        destinationSelected,
        setDestinationSelected,
        assignedTo,
        setAssignedTo,
        contactSelected,
        setContactSelected,
        handleExitConversation,
        handleAddLabel,
        // Internal Chat
        internalGroupInfo,
        setInternalGroupInfo,
        setInternalChat,
        getInternalChats,
        // Notes
        getNotes,
        setNewNote,
        updateNote,
        deleteNote,
        // Files
        getFiles,
        filesToUpload,
        setFilesToUpload,
        handleUploadFile,
        setNewFile,
        deleteFile,
        // Activities
        getActivities,
        // Conversations of Contact
        conversationsActiveOfContact,
        setConversationsActiveOfContact,
        conversationsFinalizedOfContact,
        setConversationsFinalizedOfContact,
        getConversationsOfContact,
        // Alert
        error,
        setError,
        errorMessage,
        setErrorMessage,
        handleCloseAlert,
      }}
    >
      {children}
    </conversationsContext.Provider>
  );
};

export default ConversationsProvider;
