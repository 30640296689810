import React, { useContext, useState, useEffect } from 'react';
// Dispatch
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Service
import { fetchFlows } from 'redux/features/editFlowSlice/editFlowSlice';
// components/ui
import { TextField, Divider, Box } from '@mui/material';
//Components
import { ActionButtons, Flows } from './components';

const EditRedirectNode = () => {
  const { redirectNode, setRedirectNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchFlows());
  }, [dispatch]);

  useEffect(() => {
    if (selectNode && selectNode.data.redirect) {
      const redirect = selectNode && selectNode?.data && selectNode?.data?.redirect;
      const defaultId = redirect.id
        ? redirect?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = redirect.name ? redirect?.name : ('' as string);
      setId(defaultId);
      setName(defaultName);
    }
  }, [selectNode]);

  const handleChangeName = (name: string) => {
    setName(name);
    setRedirectNode({
      ...redirectNode,
      id,
      name,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Divider sx={{ margin: '1rem 0rem 1.5rem 0rem' }} />
      <Flows />
      <ActionButtons />
    </Box>
  );
};

export default EditRedirectNode;
