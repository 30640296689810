import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateWhatsApp from '../hooks/useCreateWhatsApp/useCreateWhatsApp';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createWhatsAppInitialState from '../hooks/useCreateWhatsApp/createWhatsAppInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createWhatsApp,
  deleteWhatsApp,
} from 'redux/features/whatsAppSlice/whatsAppSlice';
// Types
import type { CrudPanelState } from './types';
import { WhatsAppContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const whatsAppContext = createContext<WhatsAppContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateWhatsApp: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedWhatsApp: null,
  deleteWhatsAppName: fieldInitialDef,
  createWhatsAppState: createWhatsAppInitialState,
  handleDeleteWhatsApp: () => {},
  navigateToEdit: () => {},
});

const WhatsAppProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedWhatsApp, setSelectedWhatsApp] = useState<any>(null);
  // Create state
  const createWhatsAppState = useCreateWhatsApp();
  // Delete state
  const deleteWhatsAppName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateWhatsApp = async () => {
    const newWhatsApp = createWhatsAppState.actions.getNewWhatsApp();
    await dispatch(createWhatsApp(newWhatsApp));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createWhatsAppState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteWhatsApp = async () => {
    const { id } = selectedWhatsApp;

    await dispatch(deleteWhatsApp(id));
    endDeleting();
  };

  const openDelete = (whatsApp: any) => {
    setSelectedWhatsApp(whatsApp);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteWhatsAppName.actions.resetValue();
    setSelectedWhatsApp(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (whatsAppId: string) => {
    navigate(`/a/conversations/system/whatsapp/${whatsAppId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <whatsAppContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateWhatsApp,
        openDelete,
        endDeleting,
        deleteWhatsAppName,
        createWhatsAppState,
        selectedWhatsApp,
        handleDeleteWhatsApp,
        navigateToEdit,
      }}
    >
      {children}
    </whatsAppContext.Provider>
  );
};

export default WhatsAppProvider;
