import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
// Types
import { MercadoLibreConfig, MercadoLibreConfigAccount } from '@trii/types/dist/Common/Channels';
// Components/ui
import { Box, Typography, Avatar, Card, Fade } from '@mui/material';

interface Props {
  userData: MercadoLibreConfigAccount;
  mlConfig: MercadoLibreConfig
}

const MercadoLibreAccountCard = ({ userData, mlConfig }: Props) => {
  const { fontSize } = useContext(StyleContext);
  const [show, setShow] = useState(false);
  const { imageUrl, name, email, phone } = userData;
  const { mlUserId } = mlConfig;
  console.log('userData', userData)
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Card
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          padding: 2,
          width: 350,
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
        }}
      >
        <Avatar
          sx={{
            width: 48,
            height: 48,
            backgroundColor: (theme) => theme.palette.primary.light,
            color: "white",
            mr: 2,
          }}
          src={imageUrl}
          alt={name}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </Avatar>
        <Box>
          <Typography fontSize={fontSize.sm} sx={{ fontWeight: "bold" }}>
            {name} - userId: {mlUserId}
          </Typography>
          <Typography fontSize={fontSize.xs} color="textSecondary">
            {email} {phone !=="" && `- ${phone}` }
          </Typography>
        </Box>
      </Card>
    </Fade>
  );
};

export default MercadoLibreAccountCard;
