import { SxProps, Theme } from '@mui/material/styles';

interface EmailWriterStylesProps {
  isMaximized: boolean;
}

export const overlayStyles: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1299, // Justo debajo del contenido maximizado
};

export const containerStyles = (isMaximized: boolean): SxProps<Theme> => ({
  width: isMaximized ? '90vw' : '100%',
  height: isMaximized ? '90vh' : '100%',
  position: isMaximized ? 'fixed' : 'relative',
  top: isMaximized ? '50%' : 'auto',
  left: isMaximized ? '50%' : 'auto',
  transform: isMaximized ? 'translate(-50%, -50%)' : 'none',
  zIndex: isMaximized ? 1300 : 'auto',
  backgroundColor: (theme) => theme.palette.background.default,
  borderRadius: isMaximized ? '8px' : '1rem',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: (theme) => (isMaximized ? theme.shadows[5] : 'none'),
  paddingX: isMaximized ? 2 : 0,
  paddingTop: isMaximized ? 1 : 0,
  paddingBottom: 0,
});
