import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewWebChat } from 'redux/features/webChatSlice/types/NewWebChat';

const createWebChatInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewWebChat: () => ({} as NewWebChat),
  },
};
export default createWebChatInitialState;
