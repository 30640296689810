import React, { useContext, useState, useEffect } from 'react';
// Dispatch
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Service
import { fetchEmailChannels } from 'redux/features/editFlowSlice/editFlowSlice';
// components/ui
import { TextField, Divider, FormControlLabel, Checkbox, Box } from '@mui/material';
//Components
import { ActionButtons, SendTo, Body, Channel, Subject } from './components';

const EditSendEmailNode = () => {
  const { sendEmailNode, setSendEmailNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [enableStatics, setEnableStatics] = useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchEmailChannels());
  }, [dispatch]);

  useEffect(() => {
    if (selectNode && selectNode.data.sendEmail) {
      const sendEmail =
        selectNode && selectNode?.data && selectNode?.data?.sendEmail;
      const defaultId = sendEmail.id
        ? sendEmail?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = sendEmail.name ? sendEmail?.name : ('' as string);
      const defaultEnableStatics = sendEmail.enableStatics
        ? sendEmail?.enableStatics
        : (false as boolean);
      setId(defaultId);
      setName(defaultName);
      setEnableStatics(defaultEnableStatics);
    }
  }, [selectNode]);

  const handleChangeName = (name: string) => {
    setName(name);
    setSendEmailNode({
      ...sendEmailNode,
      id,
      name,
    });
  };

  const handleChangeEnableStatics = (enableStatics: boolean) => {
    setEnableStatics(enableStatics);
    setSendEmailNode({
      ...sendEmailNode,
      enableStatics,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStatics}
            onChange={(e) => handleChangeEnableStatics(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.enableStatics')}
      />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Channel />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <SendTo />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Subject />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Body />
      <ActionButtons />
    </Box>
  );
};

export default EditSendEmailNode;
