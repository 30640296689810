import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  fetchWhatsApp,
  selectWhatsAppFetchStatus,
  selectWhatsApp,
  selectWhatsAppUpdateStatus,
  updateWhatsApp,
  selectWhatsAppName,
} from 'redux/features/whatsAppEditSlice/whatsAppEditSlice';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { ViewContainer, ChannelEditHeader } from 'components';
import { Body } from './components';
import { Box, Divider } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppEditContainer = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const whatsAppFetchStatus = useSelector(selectWhatsAppFetchStatus);
  const whatsAppUpdateStatus = useSelector(selectWhatsAppUpdateStatus);
  const whatsAppName = useSelector(selectWhatsAppName);
  const allFetched =
    groupFetchStatus === 'succeeded' &&
    scheduleFetchStatus === 'succeeded' &&
    whatsAppFetchStatus === 'succeeded';
  const whatsApp = useSelector(selectWhatsApp);

  useEffect(() => {
    dispatch(fetchWhatsApp(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ViewContainer>
      <Box >
        <ChannelEditHeader
          channelUpdateStatus={whatsAppUpdateStatus}
          channelName={whatsAppName}
          channelFetchStatus={whatsAppFetchStatus}
          updateChannel={updateWhatsApp}
        >
          <WhatsAppIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>
        <Divider />
        <Body data={whatsApp} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default WhatsAppEditContainer;
