import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateEmail from '../hooks/useCreateEmail/useCreateEmail';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createEmailInitialState from '../hooks/useCreateEmail/createEmailInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  deleteEmail,
  changeEmailCreateStatus,
} from 'redux/features/emailSlice/emailSlice';
// Types
import type { CrudPanelState } from './types';
import { EmailContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const emailContext = createContext<EmailContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateEmail: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedEmail: null,
  deleteEmailName: fieldInitialDef,
  createEmailState: createEmailInitialState,
  handleDeleteEmail: () => {},
  navigateToEdit: () => {},
});

const EmailProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  // Create state
  const createEmailState = useCreateEmail();
  // Delete state:
  const deleteEmailName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateEmail = async () => {
    await createEmailState.actions.sendEmail();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createEmailState.actions.resetAll();
    dispatch(changeEmailCreateStatus('idle'));
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteEmail = async () => {
    const { id } = selectedEmail;

    await dispatch(deleteEmail(id));
    endDeleting();
  };

  const openDelete = (email: any) => {
    setSelectedEmail(email);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteEmailName.actions.resetValue();
    setSelectedEmail(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (emailId: string) => {
    navigate(`/a/conversations/system/emails/${emailId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <emailContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateEmail,
        openDelete,
        endDeleting,
        deleteEmailName,
        createEmailState,
        selectedEmail,
        handleDeleteEmail,
        navigateToEdit,
      }}
    >
      {children}
    </emailContext.Provider>
  );
};

export default EmailProvider;
