import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { selectAllForms } from 'redux/features/formSlice/formSlice';
import {
  changeSurveyFormId,
  changeSurveyStatus,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import DesignSection from '../DesignSection';
import PollIcon from '@mui/icons-material/Poll';
import OptionsContainer from '../OptionsContainer';
import {
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

interface Props {
  surveyEnabled: boolean;
  surveyFormId: string;
}

const SurveySection = ({ surveyEnabled, surveyFormId }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const forms = useSelector(selectAllForms);

  const handleSurveyEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSurveyStatus(event.target.checked));
  };
  const handleSurveyFormIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSurveyFormId(event.target.value));
  };

  const FormsMenuItems = React.useMemo(() => {
    return forms.map((form) => (
      <MenuItem key={form.id} value={form.id}>
        {form.name}
      </MenuItem>
    ));
  }, [forms]);

  return (
    <DesignSection
      icon={
        <PollIcon
          sx={{
            fontSize: 17,
            color: 'primary.main',
          }}
        />
      }
      title={t('webChatEditView.designPanel.surveySection.title')}
    >
      <OptionsContainer
        title={t('webChatEditView.designPanel.surveySection.enableSurvey')}
      >
        <FormControlLabel
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          control={
            <Switch checked={surveyEnabled} onChange={handleSurveyEnabledChange} />
          }
          label={t('webChatEditView.designPanel.surveySection.surveyDescription')}
          labelPlacement="start"
        />
        <FormControl fullWidth >
          <InputLabel >
            {t('webChatEditView.designPanel.surveySection.selectForm')}
          </InputLabel>
          <Select
            value={surveyFormId}
            label={t('webChatEditView.designPanel.surveySection.selectForm')}
            onChange={handleSurveyFormIdChange}
            size="small"
            fullWidth
            MenuProps={{
              PaperProps: {
                sx: {
                  //@ts-ignore
                  backgroundColor: theme => theme.palette.background.panel,
                },
              },
            }}
          >
            {FormsMenuItems}
          </Select>
        </FormControl>
      </OptionsContainer>
    </DesignSection>
  );
};

export default SurveySection;
