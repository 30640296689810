import { useContext, useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
// Components/ui
import Box from '@mui/material/Box';
// Context
import socialWallsContext from '../../context/SocialWallsContext';
// Components
import { PublicationList, PublicationPanel, SelectPostScreen } from './components';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
import { Post, WallMessage } from '@trii/types/dist/Conversations';
import {
  setNewComments,
  updatePost,
} from 'redux/features/socialWallSlice/socialWallSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

export default function PublicationContainer() {
  const dispatch = useAppDispatch();

  const { postSelected, messages, setIsNewMessage, updateMessage, getPosts } =
    useContext(socialWallsContext);
  const [showComments, setShowComments] = useState<boolean>(false);
  // WebSocket
  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);
  const postSelectedRef = useRef(postSelected);
  const messagesRef = useRef(messages);

  const receiveWebsocketMessage = (data: WallMessage) => {
    if (
      messagesRef &&
      messagesRef?.current.length > 0 &&
      postSelectedRef &&
      postSelectedRef?.current.id === data.postId
    ) {
      setIsNewMessage(true);
      if (data.parentId === postSelectedRef?.current.id) {
        dispatch(setNewComments([...messagesRef.current, data]));
      } else {
        const newMessages = updateMessage(messagesRef.current, data);
        dispatch(setNewComments(newMessages));
      }
    }
  };

  const handleWebsocketPostUpdate = (data: Post) => {
    dispatch(updatePost(data));
  };

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    if (socketConnection) {
      subscribeEvent('postcomment_add', receiveWebsocketMessage);
      subscribeEvent('post_update', handleWebsocketPostUpdate);
    }
    return () => {
      unsubscribeEvent('postcomment_add');
      unsubscribeEvent('post_update');
    };
  }, [socketConnection]);

  useEffect(() => {
    if (postSelected) {
      postSelectedRef.current = postSelected;
      setShowComments(true);
    } else {
      setShowComments(false);
    }
  }, [postSelected]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      messagesRef.current = messages;
    }
  }, [messages]);

  return (
    <Box
      display="flex"
      width="100%"
      height={'100%'}
      flexDirection={'column'}
      gap="0.5rem"
      className={'conversations-fadeinup'}
      overflow="auto"
      borderRadius={1}
      sx={{
        '@media (max-width: 1024px)': {
          overflow: 'auto',
          maxWidth: 'calc(100% - 70px)',
        },
      }}
    >
      <Box display="flex" height={'100%'} width="100%">
        <PublicationList />
        {showComments ? <PublicationPanel /> : <SelectPostScreen />}
      </Box>
    </Box>
  );
}
