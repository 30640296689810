import { useContext, useState, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
// Icons
import { Done, Lock, Logout } from '@mui/icons-material';
//Context
import socialWallsContext from 'features/Views/Walls/context/SocialWallsContext';
// Moment
import moment from 'moment';
// Types
import { PostAction } from 'redux/features/socialWallSlice/types/PostAction';
import {
  deletePost,
  setNewPosts,
} from 'redux/features/socialWallSlice/socialWallSlice';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { dbWorker } from 'db/db';

const FORMAT_DATE = 'DD/MM/YYYY';

export default function PublicationHeader() {
  const dispatch = useAppDispatch();
  const { postSelected, posts, fetchAction, setPostSelected } =
    useContext(socialWallsContext);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [date, setDate] = useState('');
  const { t } = useTranslation();

  const handleFinalizePost = (action: 'out' | 'finalize') => {
    const postId = postSelected.id;
    const data = {
      postId,
      action,
    } as PostAction;

    if (action === 'finalize') {
      dispatch(deletePost(postId));

      dbWorker.postMessage({
        action: 'deletePost',
        data: postId,
      });
    }

    setPostSelected(null);
    fetchAction(data);
  };

  useEffect(() => {
    if (postSelected && postSelected.counters) {
      const { likes, comments } = postSelected.counters;
      setLikes(likes);
      setComments(comments);
      const newDate = moment(postSelected.createdAt).format(FORMAT_DATE);
      setDate(newDate);
    }
  }, [postSelected]);

  return (
    <Box
      component={Card}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="background.default"
      color="text.primary"
      sx={{
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      }}
      height="3rem"
      px={3}
      py={1}
    >
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar
              alt="Remy Sharp"
              src={postSelected?.imageUrl}
              sx={{
                display: 'flex',
                border: (theme) => `1px solid ${theme.palette.text.primary}`,
                padding: '2px',
                width: 30,
                height: 30,
                '& .MuiAvatar-img': {
                  borderRadius: '50%',
                },
              }}
            />
            <Box maxWidth="40vw">
              <Typography
                variant="subtitle2"
                ml={2}
                mb={'-5px'}
                className="conversations-ellipsified"
              >
                {postSelected?.details}
              </Typography>
              <Typography variant="caption" ml={2}>
                {t('socialWalls.publicationHeader', { likes, comments, date })}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
            <IconButton aria-label="out" onClick={() => handleFinalizePost('out')}>
              <Tooltip title={t('socialWalls.actions.out')} arrow placement="left">
                <Logout
                  fontSize="small"
                  sx={{
                    transform: 'rotate(180deg)',
                    color: (theme) => theme.palette.text.disabled,
                  }}
                />
              </Tooltip>
            </IconButton>
            <Button
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.primary.dark,
                },
                height: '25px',
                paddingRight: '10px',
              }}
              onClick={() => handleFinalizePost('finalize')}
            >
              <Done fontSize="small" />
              <Typography fontSize="small" color="inherit">
                {t('socialWalls.actions.done')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
