import useField from 'hooks/useField';
// Types
import { UseCreateFlow } from './types';
import { NewFlow } from 'redux/features/flowSlice/types/NewFlow';

const useCreateFlow = (): UseCreateFlow => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewFlow = (): NewFlow => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewFlow,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateFlow;
