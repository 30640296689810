import { useContext, useEffect, useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import { Box } from "@mui/material";
// Components
import { Card, RegisterModal } from "./components";
// Context
import { DashboardContext } from "../../context/DashboardContext";
// Selector
import { dashboardChannelsSummary, dashboardChannelsSummaryStatus } from "redux/features/dashboardSlice/dashboardSlice";
import { DashBoardChannelsSummaryInfo } from "@trii/types/dist/Conversations";
import { IChannelInfo } from "@trii/types/dist/Common/Channels";

const Channels = () => {
  const { t } = useTranslation();
  const { getSummaryChannels } = useContext(DashboardContext);
  const newChannelsSummary = useSelector(dashboardChannelsSummary) as DashBoardChannelsSummaryInfo;
  const channelsSummaryStatus = useSelector(dashboardChannelsSummaryStatus);
  const isLoading = channelsSummaryStatus === "loading";
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [channelsSummary, setChannelsSummary] = useState<DashBoardChannelsSummaryInfo>();
  const [channels, setChannels] = useState<IChannelInfo[]>([]);

  const handleOpen = (channelsSelected: IChannelInfo[]) => {
    setOpenModal(true);
    setChannels(channelsSelected);
  }

  const handleClose = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    getSummaryChannels();
  }, [])

  useEffect(() => {
    if (newChannelsSummary) {
      setChannelsSummary(newChannelsSummary);
    }
  }, [newChannelsSummary]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(200px, 1fr))",
        gridColumnGap: "1rem",
        overflow: "auto",
        width: "100%",
        placeItems: "center",
        alignItems: "flex-start",
        p: 2,
        pb: 6
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        height="100%"
      >
        <Card
          title={t("dashboard.channels.normal.title")}
          isLoading={isLoading}
          quantity={channelsSummary?.normal?.length || 0}
        />
        <Card
          title={t("dashboard.channels.normal.quantity")}
          isLoading={isLoading}
          isDetail
          data={channelsSummary?.normal || []}
          handleOpen={handleOpen}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        height="100%"
      >
        <Card
          title={t("dashboard.channels.error.title")}
          isLoading={isLoading}
          quantity={channelsSummary?.error?.length || 0}
        />
        <Card
          title={t("dashboard.channels.error.quantity")}
          isLoading={isLoading}
          isDetail
          data={channelsSummary?.error || []}
          handleOpen={handleOpen}
        />
      </Box>
      <RegisterModal
        open={openModal}
        onClose={handleClose}
        channels={channels}
      />
    </Box>
  )
}

export default Channels;