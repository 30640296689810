// Components
import {
  Select as MuiSelect,
  MenuItem,
  CircularProgress,
  Box
} from "@mui/material"
// Types
import { FormItem, IChannel } from "@trii/types/dist/Common/Channels"
import { UserInfo } from "@trii/types/dist/Users";

interface Props {
  list: IChannel[] | FormItem[] | UserInfo[];
  isLoading: boolean;
  width: string;
  handleSelect: (id: string) => void;
  value?: string;
  label?: string;
  labelId?: string;
}

const Selector = ({
  list,
  isLoading,
  width,
  handleSelect,
  value,
  label,
  labelId,
}: Props) => {
  const getLabel = (item: IChannel | FormItem | UserInfo) => {
    if (item.hasOwnProperty('name')) {
      const channel = item as IChannel
      return channel.name
    } else if (item.hasOwnProperty('question')) {
      const question = item as FormItem
      return question.question
    }
  }

  return (
    <MuiSelect
      size="small"
      maxRows={1}
      labelId={labelId && labelId}
      label={label && label}
      sx={{
        maxWidth: width,
        minWidth: width,
        color: theme => theme.palette.text.primary,
        "& legend": {
          maxWidth: "fit-content",
          //   padding: '0 10px'
        },
        "& .MuiSvgIcon-root": {
          color: "text.disabled",
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '20rem',
            width: 'max-content',
            overflowY: 'auto',
        marginTop: 1,

          }

        }
      }}
      onChange={(e) => handleSelect(e.target.value as string)}
      defaultValue={value}
      value={value}
    >
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : list && list.length > 0 && list.map((item: IChannel | FormItem | UserInfo) => (
          //@ts-ignore
          <MenuItem value={item.id} key={item.id}>{getLabel(item)}</MenuItem>
        ))
      }
    </MuiSelect>
  )
}

export default Selector