import { fieldInitialDef } from 'utils/fieldInitialDef';
import { selectInitialDef } from 'utils/selectInitialDef';
import { NewWebHook } from 'redux/features/webHooksSlice/types/NewWebHook';
import { IWebhook } from '@trii/types/dist/Conversations';

export const createWebHookInitialState = {
  input: {
    nameField: fieldInitialDef,
    urlField: fieldInitialDef,
    methodSelect: selectInitialDef,
    usernameField: fieldInitialDef,
    passwordField: fieldInitialDef,
    fixedTokenField: fieldInitialDef,
    dynamicUrlField: fieldInitialDef,
    bearerTokenBodyField: fieldInitialDef,
    apiKeyField: fieldInitialDef,
    apiValueField: fieldInitialDef,
    authTypeSelect: selectInitialDef,
    paramsBodyField: fieldInitialDef,
    paramsContetTypeField: fieldInitialDef,
  },
  action: {
    resetAll: () => {},
    getNewWebHook: () => ({} as NewWebHook),
    setToBeEditedWebHook: (webHook: IWebhook) => {},
  },
};
