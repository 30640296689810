import { Avatar, Badge, ListItemAvatar } from '@mui/material';
// Types
import { UserInfo, UserStatus } from '@trii/types/dist/Users';

type StatusUserAvatarProps = {
  data: UserInfo;
};

const StatusUserAvatar = ({ data }: StatusUserAvatarProps) => {
  const getBadgeColor = () => {
    switch (data.status) {
      case UserStatus.ONLINE:
        return 'success';
      case UserStatus.AWAY:
        return 'warning';
      case UserStatus.BUSY:
        return 'error';
      case UserStatus.OFFLINE:
      default:
        return 'default';
    }
  };

  return (
    <ListItemAvatar>
      <Badge
        color={getBadgeColor()}
        overlap="circular"
        badgeContent=" "
        variant="dot"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar alt={data?.name} src={data.imageUrl}>
          {data ? null : data?.name ? data.name.charAt(0).toUpperCase() : ''}
        </Avatar>
      </Badge>
    </ListItemAvatar>
  );
};

export default StatusUserAvatar;
