import React, { useState, useEffect } from 'react';
// Components/ui
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

interface Props {
  children: React.ReactNode;
}

const StepContainer = ({ children }: Props) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <Fade in={isMounted}>
      <Box pt={6} pb={4} px={12} height={390}>
        {children}
      </Box>
    </Fade>
  );
};

export default StepContainer;
