import { useState } from 'react';
// Components/ui
import {
  FirstStep,
  FourthStep,
  SecondStep,
  ThirdStep,
  Buttons,
  EndButton,
} from './components';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  changeConnectedEmail,
  changeEmailVerificationStatus,
  selectEmail,
  sendVerCode,
  verifyEmail,
} from 'redux/features/emailEditSlice/emailEditSlice';
import { useSelector } from 'react-redux';
// Types
import { SendVerCodeData } from 'redux/features/emailEditSlice/types/SendVerCodeData';
import { VerifyEmailStatus } from 'redux/features/emailEditSlice/types/VerifyEmailStatus';
// Hooks
import useField from 'hooks/useField';

const steps = [0, 1];

interface Props {
  handleClose: () => void;
}

const emailRegex = /\S+@\S+\.\S+/;

function SetUpStepper({ handleClose }: Props) {
  const dispatch = useAppDispatch();

  const selectedEmail = useSelector(selectEmail);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [otp, setOtp] = useState('');

  const emailInput = useField('text');

  const isEmail = emailRegex.test(emailInput.attributes.value);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // On step 0
    if (activeStep === 0) {
      const sendVerCodeData: SendVerCodeData = {
        email: emailInput.attributes.value,
        channelId: selectedEmail.id,
      };

      dispatch(sendVerCode(sendVerCodeData));

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // On step 1
    else if (activeStep === 1) {
      const verificationResult: any = await dispatch(
        verifyEmail({
          email: emailInput.attributes.value,
          code: Number(otp),
          channelId: selectedEmail.id,
        })
      );
      const fulfilledRequest = verificationResult.meta.requestStatus === 'fulfilled';
      const verificationResultPayload = verificationResult.payload
        .status as VerifyEmailStatus;

      if (fulfilledRequest && verificationResultPayload === 'VALID') {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        dispatch(changeEmailVerificationStatus('idle'));
        dispatch(changeConnectedEmail(emailInput.attributes.value));
      }
    }

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        sx={{
          '& .MuiStepConnector-line': {
            borderWidth: 2,
            borderRadius: 1,
          },
        }}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel />
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 2 ? (
        <>
          <FourthStep newEmail={emailInput.attributes.value} />
          <EndButton handleClose={handleClose} />
        </>
      ) : (
        <>
          {activeStep === 0 && <FirstStep emailInput={emailInput} />}
          {activeStep === 1 && (
            <SecondStep
              newEmail={emailInput.attributes.value}
              otp={otp}
              setOtp={setOtp}
            />
          )}
          {/* {activeStep === 2 && <ThirdStep newEmail={emailInput.attributes.value} />} */}
          <Buttons
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleClose={handleClose}
            emailInputIsEmail={isEmail}
          />
        </>
      )}
    </Box>
  );
}

export default SetUpStepper;
