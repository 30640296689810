import React, { ReactNode } from 'react';
// Components/ui
import { Box } from '@mui/material';

interface IPanelContainer {
  children: ReactNode;
}

const PanelContainer = ({ children }: IPanelContainer) => {
  return (
    <Box
      sx={{
        width: '25%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '25%',
        },
        // marginLeft: '20px',
        // marginTop: '5px',
        height: 'calc(100vh - 60px)',
        position: 'relative',
        //@ts-ignore
        backgroundColor: (theme) => theme.palette.dropdownBox,
      }}
    >
      {children}
    </Box>
  );
};

export default PanelContainer;
