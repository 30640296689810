// Types
import { FormItem } from '@trii/types/dist/Common/Channels';
// Components/ui
import { QuestionItem } from './components';
import { Box } from '@mui/material';

interface Props {
  questions: FormItem[];
}

const Questions = ({ questions }: Props) => {
  return (
    <Box
      width={'100%'}
      mt={2}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={2}
    >
      {questions.map((question, i) => (
        <QuestionItem
          key={i}
          questionData={question}
          isLast={i === questions.length - 1}
        />
      ))}
    </Box>
  );
};

export default Questions;
