import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeYeastarUser,
  changeYeastarPass,
  changeYeastarUrl,
  changeYeastarPort,
} from 'redux/features/smsEditSlice/smsEditSlice';
// Components/ui
import { TextField } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';

interface Props {
  data: {
    yeastarUser: string;
    yeastarPass: string;
    yeastarUrl: string;
    yeastarPort: string;
  };
}

const YeastarInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { yeastarUser, yeastarPass, yeastarUrl, yeastarPort } = data;

  const handleYeastarUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeYeastarUser(event.target.value));
  };
  const handleYeastarPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeYeastarPass(event.target.value));
  };
  const handleYeastarUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeYeastarUrl(event.target.value));
  };
  const handleYeastarPortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeYeastarPort(event.target.value));
  };

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label={t('global.user')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={yeastarUser}
        onChange={handleYeastarUserChange}
      />
      <TextField
        size="small"
        label={t('global.password')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={yeastarPass}
        onChange={handleYeastarPassChange}
      />
      <TextField
        size="small"
        label={'URL'}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        onChange={handleYeastarUrlChange}
        value={yeastarUrl}
      />
      <TextField
        size="small"
        label={t('global.port')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        onChange={handleYeastarPortChange}
        value={yeastarPort}
      />
    </ChannelInputsContainer>
  );
};

export default YeastarInputs;
