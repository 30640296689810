import React, { useEffect } from 'react'

import { Card, Title, BarChart } from "@tremor/react";
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';


const dataFormatter = (number) => {
  return new Intl.NumberFormat("us").format(number).toString();
};

const GraphicFinalsCard = ({chartData}) => {
  const theme = useTheme();
  const {t} = useTranslation()


  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      }}
    >
      <Title style={{ color: theme.palette.text.primary }}>{t("dashboard.endings")}</Title>
      <Box
        sx={{
          width: "100%",
          "& div div div div .bg-white": {
            //@ts-ignore
            backgroundColor: theme.palette.background.panel,
          },
        }}
      >
        <BarChart
          className="mt-6"
          data={chartData}
          index="name"
          categories={["quantity"]}
          colors={["blue"]}
          valueFormatter={dataFormatter}
          yAxisWidth={48}
          style={{ color: theme.palette.text.primary }}
        />
      </Box>
    </Card>
  );
}

export default GraphicFinalsCard