import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
// Types
import { Group } from 'redux/features/groupSlice/types';
// Components/ui
import { Box, Typography, Fade, AvatarGroup, Tooltip, Avatar } from '@mui/material';
import { ActionButtons } from './components';
// Translation
import { useTranslation } from 'react-i18next';
// moment
import moment from 'moment';

const FORMAT_DATE = 'YYYY-MM-DD';

interface GroupItemProps {
  data: Group;
}

const GroupItem = ({ data }: GroupItemProps) => {
  const [show, setShow] = useState<boolean>(false);
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { t } = useTranslation();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Box
        sx={{
          color: (theme) => theme.palette.text.primary,
          display: 'flex',
          boxShadow: shadow.md,
          borderRadius: 1,
          px: { md: '1rem', lg: '1rem' },
          my: 0,
          //@ts-ignore
          bgcolor: (theme) => theme.palette.containerBox.default,
          transition: 'all 0.3s ease',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              width: '100%',
              py: 1,
              pr: 4,
            }}
          >
            <Typography
              fontWeight={fontWeight.semibold}
              fontSize={fontSize.sm}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {data?.name}
            </Typography>
            <br />
            {
              data.users ?
                <AvatarGroup max={10} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  {
                    data.users.map((user, index) => (
                      <Tooltip key={index} title={user.name}>
                        <Avatar sx={{ width: "24px", height: "24px" }} src={user.imageUrl}></Avatar>
                      </Tooltip>
                    ))}
                </AvatarGroup> : <></>
            }
            <Box display='flex' justifyContent='space-between'>
              <Typography
                fontWeight={fontWeight.semibold}
                fontSize={fontSize.sm}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                {data.users ? data.users.length : 0} {t('settingsView.groups.contacts')}
              </Typography>
              <Typography
                fontSize={fontSize.sm}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                {t('settingsView.groups.createdAt')} {moment(data.createdAt).format(FORMAT_DATE)}
              </Typography>
            </Box>
          </Box>
          <ActionButtons group={data} />
        </Box>
      </Box>
    </Fade>
  );
};

export default GroupItem;
