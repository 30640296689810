import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Selector
import { useSelector } from 'react-redux';
// Slice
import {
  selectFlows,
  selectFlowsStatus,
} from 'redux/features/editFlowSlice/editFlowSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Box,
} from '@mui/material';

const Flows = () => {
  const { redirectNode, setRedirectNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const flows = useSelector(selectFlows);
  const editStatus = useSelector(selectFlowsStatus);
  const isLoading: boolean = editStatus === 'loading';

  useEffect(() => {
    if (
      selectNode &&
      selectNode.data &&
      selectNode.data.redirect &&
      selectNode.data.redirect.flowId
    ) {
      const defaultChannelId = selectNode.data.redirect.nodeId as string;
      setValue(defaultChannelId);
    }
  }, [selectNode]);

  const handleChangeGroup = (nodeId: string) => {
    setValue(nodeId);
    const flowId = flows.find((flow) =>
      flow.nodes.find((node) => node.id === nodeId)
    )?.id;
    const nodeName = flows
      .find((flow) => flow.nodes.find((node) => node.id === nodeId))
      ?.nodes.find((node) => node.id === nodeId)?.name;
    setRedirectNode({
      ...redirectNode,
      flowId,
      nodeId,
      nodeName,
    });
  };

  const renderSelectGroup = (group) => {
    const items = group.nodes.map((node) => {
      return (
        <MenuItem key={node.id} value={node.id}>
          {node.name}
        </MenuItem>
      );
    });
    return [<ListSubheader>{group.name}</ListSubheader>, items];
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="group" shrink={true}>
          {t("editFlowsView.assignTo.groupSelector")}
        </InputLabel>
        <Select
          labelId="group"
          id="demo-simple-select"
          value={value}
          label={t("editFlowsView.assignTo.groupSelector")}
          onChange={(e) => handleChangeGroup(e.target.value)}
          MenuProps={{
            sx: {
              ".MuiMenu-list": {
                maxHeight: "20rem",
                overflowY: "auto",
                paddingTop: "0",
              },
            },
          }}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {flows && flows?.map((group) => renderSelectGroup(group))}
        </Select>
      </FormControl>
    </>
  );
};

export default Flows;
