// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
// Icons
import { EditPanel, TreeCategories } from './components';

const Templates = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 3rem)',
        height: 'calc(100% - 3rem)',
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        p: 2,
        margin: '1.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          height: 'calc(100vh - 11.5rem)',
          gridTemplateColumns: '30% 70%',
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <TreeCategories />
        <EditPanel />
      </Box>
    </Box>
  );
};

export default Templates;
