import { useState, useContext, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Select,
  MenuItem,
  IconButton,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';
// Icons
import { Delete } from '@mui/icons-material';
import {
  NodeMsgTextMessageButtonType,
  NodeMsgTextMessageButton,
} from '@trii/types/dist/Conversations/Flows/Nodes';

const Button = ({
  id,
  handleDelete,
}: {
  id: string;
  handleDelete: (id: string) => void;
}) => {
  const { textNode, setTextNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [type, setType] = useState<NodeMsgTextMessageButtonType>(
    NodeMsgTextMessageButtonType.REPLY
  );
  const [title, setTitle] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const buttons = selectNode?.data?.msgText?.message.buttons?.find(
      (button: NodeMsgTextMessageButton) => button.id === id
    );
    if (buttons) {
      const defaultType = buttons.type
        ? buttons.type
        : NodeMsgTextMessageButtonType.REPLY;
      const defaultTitle = buttons.title ? buttons.title : '';
      setType(defaultType);
      setTitle(defaultTitle);
    }
  }, [id, selectNode]);

  useEffect(() => {
    if (type && title) {
      const button: NodeMsgTextMessageButton = {
        id,
        type,
        title,
      };
      setTextNode((prevTextNode) => {
        if (prevTextNode?.message?.buttons?.length) {
          const index = prevTextNode.message.buttons.findIndex(
            (button: NodeMsgTextMessageButton) => button.id === id
          );
          if (index !== -1) {
            const newButtons = [...prevTextNode.message.buttons];
            newButtons[index] = button;
            return {
              ...prevTextNode,
              message: { ...prevTextNode?.message, buttons: newButtons },
            };
          } else {
            return {
              ...prevTextNode,
              message: {
                ...prevTextNode?.message,
                buttons: [...prevTextNode?.message?.buttons, button],
              },
            };
          }
        } else {
          if (prevTextNode && prevTextNode.message && prevTextNode.message.buttons) {
            return {
              ...prevTextNode,
              message: {
                ...prevTextNode?.message,
                buttons: [...prevTextNode?.message?.buttons, button],
              },
            };
          } else {
            return {
              ...prevTextNode,
              message: { ...prevTextNode?.message, buttons: [button] },
            };
          }
        }
      });
    }
  }, [type, title, id]);

  const handleDeleteButton = () => {
    if (textNode && textNode.message && textNode.message.buttons) {
      setTextNode({
        ...textNode,
        message: {
          ...textNode.message,
          buttons: textNode.message.buttons.filter(
            (button: NodeMsgTextMessageButton) => button.id !== id
          ),
        },
      });
    }
    handleDelete(id);
  };

  return (
    <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell  >
        <Select
          sx={{ width: 100,  "& .MuiSvgIcon-root": {
            color: "text.disabled",
          } }}
          size="small"
          value={type}
          defaultValue={type}
          onChange={(e) => setType(e.target.value as number)}
        >
          <MenuItem value={NodeMsgTextMessageButtonType.REPLY} key={1}>
            {t('editFlowsView.text.manualMessage.reply')}
          </MenuItem>
          <MenuItem value={NodeMsgTextMessageButtonType.URL} key={2}>
            {t('editFlowsView.text.manualMessage.url')}
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right">
        <TextField
          sx={{ width: 100 }}
          size="small"
          multiline
          maxRows={1}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </TableCell>
      <TableCell align="right" >
        <IconButton onClick={() => handleDeleteButton()} sx={{color: "text.disabled"}}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Button;
