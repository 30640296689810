import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';
// Components/ui
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  useTheme,
  Alert,
  Snackbar,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/system';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '12px',
  boxShadow: 'none',
  marginBottom: '5px',
  backgroundColor: theme.palette.background.panel,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  top: '-10px',
  left: '10px',
  padding: '2px 6px',
  color: theme.palette.text.primary,
  fontSize: '12px',
  zIndex: 1,
  marginBottom: '5px',
}));

const FlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 1,
});

const StyledButton = styled(Button)({
  variant: 'outlined',
  size: 'small',
});

const StyledSnackbar = styled(Snackbar)({
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
});

const Header = () => {
  const { t } = useTranslation();
  const { setHolidays, openPanelNewHoliday, handleClosePanelNewHoliday } =
    useContext(InitScheduleEditContext);
  const [addHoliday, setAddHoliday] = useState<boolean>(false);
  const [holidayName, setHolidayName] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'));
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const theme = useTheme();

  const handleHolidayName = (value: string | null) => {
    setHolidayName(value);
  };

  useEffect(() => {
    if (startDate.isAfter(endDate) && !startDate.isSame(endDate, 'minute')) {
      setError(t('scheduleView.errorDate'));
      setIsError(true);
      setAddHoliday(false);
      return;
    }
    if (
      startDate &&
      endDate &&
      holidayName &&
      (startDate.isBefore(endDate) || startDate.isSame(endDate, 'minute'))
    ) {
      setIsError(false);
      setAddHoliday(true);
    } else {
      setAddHoliday(false);
    }
  }, [startDate, endDate, holidayName]);

  const handleAddHoliday = () => {
    const initDateValue = dayjs(startDate.format(DATE_FORMAT), DATE_FORMAT);
    const endDateValue = dayjs(endDate.format(DATE_FORMAT), DATE_FORMAT);
    const newHolidays = {
      description: holidayName,
      datetimeStart: initDateValue,
      datetimeEnd: endDateValue,
    };
    setHolidays((prev: any) => [...prev, newHolidays]);
    setHolidayName('');
    setStartDate(dayjs());
    setEndDate(dayjs());
  };

  const handleClose = () => {
    setIsError(false);
  };

  return (
    <>
      {openPanelNewHoliday && (
        <StyledCard>
          <StyledTypography variant="body1">
            {t('scheduleView.newHoliday')}
          </StyledTypography>
          <FlexBox>
            <FlexBox>
              <TextField
                size="small"
                label={t('scheduleView.addHolidayName')}
                className="smallInput"
                InputLabelProps={{ shrink: true }}
                value={holidayName}
                onChange={(e) => handleHolidayName(e.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  format={DATE_FORMAT}
                  ampm={false}
                  slotProps={{
                    textField: () => ({
                      label: t('scheduleView.from'),
                      size: 'small',
                      className: 'smallInput',
                      sx: {
                        borderColor: isError
                          ? theme.palette.error.main
                          : theme.palette.divider,
                      },
                    }),
                  }}
                  value={startDate}
                  timeSteps={{ minutes: 1 }}
                  onChange={(newDate) => setStartDate(newDate)}
                />
                <Typography
                  variant="body1"
                  sx={{
                    marginX: '8px',
                    marginTop: '-15px',
                    color: 'text.primary',
                  }}
                >
                  _
                </Typography>
                <DateTimePicker
                  format={DATE_FORMAT}
                  ampm={false}
                  slotProps={{
                    textField: () => ({
                      label: t('scheduleView.to'),
                      size: 'small',
                      className: 'smallInput',
                    }),
                  }}
                  value={endDate}
                  timeSteps={{ minutes: 1 }}
                  onChange={(newDate) => setEndDate(newDate)}
                />
              </LocalizationProvider>
            </FlexBox>
            <FlexBox>
              <StyledButton onClick={handleClosePanelNewHoliday}>
                {t('global.cancel')}
              </StyledButton>
              <Button
                variant="contained"
                disabled={!addHoliday}
                onClick={handleAddHoliday}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
              >
                {t('global.add')}
              </Button>
            </FlexBox>
          </FlexBox>
        </StyledCard>
      )}
      <StyledSnackbar open={isError}>
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%' }}
          variant="filled"
        >
          {error}
        </Alert>
      </StyledSnackbar>
    </>
  );
};

export default Header;
