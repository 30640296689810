import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
import {
  fetchMercadoLibre,
  selectMercadoLibreName,
  selectMercadoLibreUpdateStatus,
  selectMercadoLibreFetchStatus,
  updateMercadoLibre,
  selectMercadoLibre,
} from 'redux/features/mercadoLibreEditSlice/mercadoLibreEditSlice';
// Components/ui
import { Body } from './components';
import { Divider, Box } from '@mui/material';
import { ViewContainer, ChannelEditHeader } from 'components';
import MercadoLibreIcon from 'utils/icons/MercadoLibreIcon';

const MercadoLibreEditContainer = () => {
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const { id } = useParams();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const mercadoLibre = useSelector(selectMercadoLibre);
  const mercadoLibreName = useSelector(selectMercadoLibreName);
  const mercadoLibreUpdateStatus = useSelector(selectMercadoLibreUpdateStatus);
  const mercadoLibreFetchStatus = useSelector(selectMercadoLibreFetchStatus);

  useEffect(() => {
    dispatch(fetchMercadoLibre(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      mercadoLibreFetchStatus === 'succeeded' &&
      setAllFetched(true);
  }, [groupFetchStatus, scheduleFetchStatus, mercadoLibreFetchStatus]);

  return (
    <ViewContainer>
      <Box boxShadow={1}>
        <ChannelEditHeader
          channelName={mercadoLibreName}
          channelUpdateStatus={mercadoLibreUpdateStatus}
          updateChannel={updateMercadoLibre}
          channelFetchStatus={mercadoLibreFetchStatus}
        >
          <MercadoLibreIcon size={20} />
        </ChannelEditHeader>
        <Divider />
        <Body data={mercadoLibre} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default MercadoLibreEditContainer;
