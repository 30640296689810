import { useTranslation } from 'react-i18next';
//Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeQuestionItemType } from 'redux/features/formEditSlice/formEditSlice';
// Types
import { FormItemType } from '@trii/types/dist/Common/Channels';
// Components/ui
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  answerType: FormItemType;
  questionId: string;
}

const AnswerSelector = ({ answerType, questionId }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleFormTypeChange = (e: SelectChangeEvent<FormItemType>) => {
    const newValue = e.target.value;
    if (typeof newValue === 'number') {
      dispatch(
        changeQuestionItemType({
          id: questionId,
          newType: newValue,
        })
      );
    }
  };

  return (
    <FormControl sx={{ width: '30%' }}>
      <InputLabel>{t('formEditView.designPanel.answerType')}</InputLabel>
      <Select
        value={answerType}
        size="small"
        label={t('formEditView.designPanel.answerType')}
        onChange={handleFormTypeChange}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
            },
          },
        }}
      >
        <MenuItem value={FormItemType.SHORT_ANSWER}>
          {t('formEditView.designPanel.shortAnswer')}
        </MenuItem>
        <MenuItem value={FormItemType.PARAGRAPH}>
          {t('formEditView.designPanel.paragraph')}
        </MenuItem>
        <MenuItem value={FormItemType.SEVERAL_OPTIONS}>
          {t('formEditView.designPanel.severalOptions')}
        </MenuItem>
        <MenuItem value={FormItemType.CHECKBOXES}>
          {t('formEditView.designPanel.checkboxes')}
        </MenuItem>
        <MenuItem value={FormItemType.DROPDOWN}>
          {t('formEditView.designPanel.dropdown')}
        </MenuItem>
        <MenuItem value={FormItemType.LINEARSCALE}>
          {t('formEditView.designPanel.linearScale')}
        </MenuItem>
        <MenuItem value={FormItemType.EMAIL}>{t('global.email')}</MenuItem>
        <MenuItem value={FormItemType.DATE}>{t('global.date')}</MenuItem>
        <MenuItem value={FormItemType.TIME}>{t('global.time')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AnswerSelector;
