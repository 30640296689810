import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewForm } from 'redux/features/formSlice/types/NewForm';

const createFormInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewForm: () => ({} as NewForm),
  },
};
export default createFormInitialState;
