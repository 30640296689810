import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
// @ts-ignore
import { ScheduleContext } from 'features/Views/Schedules/context/types';
// Components/ui
import { Box, TextField } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { createScheduleNameField, createScheduleDescriptionField } =
    useContext<ScheduleContext>(InitScheduleContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('scheduleView.scheduleName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        {...createScheduleNameField.attributes}
      />
      <TextField
        className="fadein"
        variant="outlined"
        multiline
        size="small"
        label={t('scheduleView.scheduleDescription')}
        fullWidth
        rows={8}
        InputLabelProps={{
          shrink: true,
        }}
        {...createScheduleDescriptionField.attributes}
      />
    </Box>
  );
};

export default Inputs;
