import { useContext, useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Components/ui
import { Box, IconButton, Modal } from "@mui/material"
// Icons
import { Close } from "@mui/icons-material"
// Context
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
// Selectors
import { dashboardMessagesLogs, dashboardMessagesLogsStatus } from "redux/features/dashboardSlice/dashboardSlice";
// Components
import { Table } from "./components"
// Types
import { Log } from '@trii/types/dist/Common/Logs/Log';

interface Props {
  open: boolean;
  channelId: string;
  onClose: () => void;
}

const LogModal = ({
  open,
  channelId,
  onClose,
}: Props) => {
  const { getLogsMessages } = useContext(DashboardContext);
  const newLogs = useSelector(dashboardMessagesLogs)
  const logStatus = useSelector(dashboardMessagesLogsStatus)
  const isLoading = logStatus === "loading"
  const [logs, setLogs] = useState<Log[]>([]);

  useEffect(() => {
    if (channelId) {
      getLogsMessages(channelId)
    }
  }, [channelId])

  useEffect(() => {
    if (newLogs) {
      setLogs(newLogs)
    }
  }, [newLogs])

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: theme => theme.palette.background.paper,
          width: "80vw",
          height: "70vh",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          p: "1rem",
          borderRadius: 1,
          color: theme => theme.palette.text.primary,
          boxShadow: theme => theme.shadows[4],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            p: 1,
            height: "4rem",
          }}
        >
          <IconButton
            onClick={onClose}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
            maxHeight: "calc(100% - 5rem)",
            height: isLoading ? "100%" : "auto",
          }}
        >
          <Table
            list={logs}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default LogModal