import { useState, useContext, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
} from '@mui/material';
// Icons
import { Add, Delete, Edit } from '@mui/icons-material';
// Components
import { AddCondition } from './components';
// Interface
import {
  NodeMsgWaitResponseCondition,
  NodeMsgWaitResponseConditionOperator,
  NodeMsgWaitResponseConditionSource,
} from '@trii/types/dist/Conversations/Flows/Nodes';
// Utils
import { conditionSource } from './utils';

const Conditions = () => {
  const { selectNode, setMsgWaitResponseNode, msgWaitResponseNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [conditions, setCondition] = useState<NodeMsgWaitResponseCondition[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [source, setSource] = useState<NodeMsgWaitResponseConditionSource>(
    NodeMsgWaitResponseConditionSource.TEXT
  );
  const [outputValue, setOutputValue] = useState<string>('');
  const [operator, setOperator] = useState<NodeMsgWaitResponseConditionOperator>(
    NodeMsgWaitResponseConditionOperator.NULL
  );
  const [valueList, setValueList] = useState<string[]>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [conditionId, setConditionId] = useState<string>('');

  useEffect(() => {
    if (selectNode?.data?.msgWaitResponse?.conditions?.length) {
      setCondition(selectNode.data.msgWaitResponse.conditions);
    } else {
      setCondition([]);
    }
  }, [selectNode]);

  const resetValues = () => {
    setSource(NodeMsgWaitResponseConditionSource.TEXT);
    setOutputValue('');
    setOperator(NodeMsgWaitResponseConditionOperator.NULL);
    setValueList([]);
  };

  const handleDeleteCondition = (id: string) => {
    const newCondition = conditions.filter((condition) => condition.id !== id);
    setCondition(newCondition);
    setMsgWaitResponseNode({
      ...msgWaitResponseNode,
      conditions: newCondition,
    });
  };

  const handleSaveCondition = (id: string) => {
    const newCondition: NodeMsgWaitResponseCondition = {
      id: id && editing ? id : Math.random().toString(36).substr(2, 9),
      source,
      operator,
      valueList,
      outputValue,
    };
    if (editing) {
      const addCondition = conditions.map((condition) => {
        if (condition.id === id) {
          return {
            id: id,
            source,
            operator,
            valueList,
            outputValue,
          };
        }
        return condition;
      });
      setCondition(addCondition);
      setMsgWaitResponseNode({ ...msgWaitResponseNode, conditions: addCondition });
      setEditing(false);
    } else {
      setCondition([...conditions, newCondition]);
      setMsgWaitResponseNode({
        ...msgWaitResponseNode,
        conditions: [...conditions, newCondition],
      });
    }
    resetValues();
    setOpen(false);
  };

  const handleCancel = () => {
    resetValues();
    setOpen(false);
  };

  const handleEditCondition = (id: string) => {
    const condition = conditions.find((condition) => condition.id === id);
    if (condition) {
      setConditionId(condition.id);
      setSource(condition.source);
      setOutputValue(condition.outputValue);
      setOperator(condition.operator);
      setValueList(condition.valueList);
      setEditing(true);
      setOpen(true);
    }
  };

  const handleAddCondition = () => {
    setOpen(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="subtitle2"
          fontWeight={"bold"}
          color="text.primary"
        >
          {t("editFlowsView.waitResponse.addCondition")}
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={() => handleAddCondition()}
        >
          <Add />
        </IconButton>
      </Box>
      <TableContainer
        sx={{
          maxHeight: "10rem",
          "& table thead tr th": {
            zIndex: "2",
          },
        }}
      >
        {conditions.length ? (
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t("editFlowsView.waitResponse.source")}
                </TableCell>
                <TableCell align="center">
                  {t("editFlowsView.waitResponse.saveResponseAs")}
                </TableCell>
                <TableCell align="center">{t("global.actions")}</TableCell>
              </TableRow>
            </TableHead>
            {conditions.map((condition) => (
              <TableBody key={condition.id}>
                <TableRow>
                  <TableCell align="center">
                    {conditionSource.map((source) => {
                      if (source.id === condition.source) {
                        return t(source.label);
                      }
                      return "";
                    })}
                  </TableCell>
                  <TableCell align="center">{condition.outputValue}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditCondition(condition.id)}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteCondition(condition.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        ) : (
          <></>
        )}
      </TableContainer>
      <AddCondition
        operator={operator}
        outputValue={outputValue}
        setOperator={setOperator}
        setOutputValue={setOutputValue}
        setSource={setSource}
        source={source}
        valueList={valueList}
        setValueList={setValueList}
        handleSaveCondition={handleSaveCondition}
        conditionId={conditionId}
        handleCancel={() => handleCancel()}
        open={open}
      />
    </>
  );
};

export default Conditions;
