import axios from 'axios';
import { NewGoogleBusiness } from './types/NewGoogleBusiness';
import getRequestConfig from '../../functions/getRequestConfig';
import { IGoogleAccountData } from './types/IGoogleAccountData';

const fetchGoogleBusiness = async (jwt: string, URL: string) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(`${URL}/channels/googleBusiness`, config);
  return response.data;
};
const createGoogleBusiness = async (
  jwt: string,
  URL: string,
  googleBusiness: NewGoogleBusiness
) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const googleBusinessJson = JSON.stringify(googleBusiness);
  const response = await axios.post(
    `${URL}/channels/googleBusiness`,
    googleBusinessJson,
    config
  );
  return response.data;
};
const deleteGoogleBusiness = async (jwt: string, URL: string, id: string) => {
  const config = getRequestConfig.basic(jwt);
  await axios.delete(`${URL}/channels/googleBusiness/${id}`, config);

  return id;
};
const updateGoogleBusiness = async (
  jwt: string,
  URL: string,
  googleBusiness: any
) => {
  const config = getRequestConfig.contentTypeJson(jwt);
  const googleBusinessJson = JSON.stringify(googleBusiness);

  const response = await axios.put(
    `${URL}/channels/googleBusiness/${googleBusiness.id}`,
    googleBusinessJson,
    config
  );
  return response.data;
};
const setAccountData = async (
  jwt: string,
  URL: string,
  data: IGoogleAccountData
) => {
  const config = getRequestConfig.contentTypeJson(jwt);

  await axios.post(`${URL}/channels/googleBusiness/`, data, config);
};

const googleBusinessService = {
  fetchGoogleBusiness,
  createGoogleBusiness,
  deleteGoogleBusiness,
  updateGoogleBusiness,
  setAccountData,
};

export default googleBusinessService;
