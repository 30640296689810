import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Tooltip } from '@mui/material';
import { CollapseAnimation, EditNode } from '../SidebarSelector/components';
import { Close } from '@mui/icons-material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { useContext } from 'react';

const EditeNodePanel = () => {
  const { t } = useTranslation();

  const { editPanelOpen, setEditPanelOpen, setNodes, selectNode, setSelectNode } =
    useContext(InitEditFlowsContext);

  const handleClose = () => {
    setEditPanelOpen(false);
    if (selectNode) {
      setNodes((prevNodes) => {
        const newNodes = prevNodes.map((node) => {
          if (node.id === selectNode.id) {
            return {
              ...node,
              selected: false,
            };
          }
          return node;
        });
        return newNodes;
      });
      setSelectNode(null);
    }
  };

  return (
    <CollapseAnimation childrenType="node" openPanel={editPanelOpen}>
      <Box
        display="flex"
        justifyContent="end"
        width={'100%'}
        sx={{ marginTop: '5px', paddingRight: '5px' }}
      >
        <Tooltip title={t('editFlowsView.close')}>
          <IconButton
            size="small"
            onClick={() => handleClose()}
            sx={{ color: 'text.disabled' }}
          >
            <Close sx={{ height: '1.2rem', width: '1.2rem' }} />
          </IconButton>
        </Tooltip>
      </Box>

      <Box display="flex" flexDirection="column" p={2} pb={1} height="calc(100% - 2rem)">
        <EditNode />
      </Box>
    </CollapseAnimation>
  );
};

export default EditeNodePanel;
