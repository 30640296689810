// Redux
import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { initRequestData } from 'redux/functions/initRequestData';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { RootState } from 'redux/store';
// Types
import { SocialWallState } from './types/SocialWallState';
import { Post, WallMessage } from '@trii/types/dist/Conversations';
import { SendMessage } from './types/SendMessage';
import { PostAction } from './types/PostAction';
// Service
import socialWallService from './SocialWallService';
import { dbWorker } from 'db/db';

const initialState: SocialWallState = {
  status: {
    comments: 'idle',
    send: 'idle',
    posts: 'idle',
    action: 'idle',
  },
  comments: [],
  posts: [],
};

export const fetchPosts = createAsyncThunk(
  'conversations/fetchPosts',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await socialWallService.fetchPosts(jwtToken, URL_CONVERSATIONS);

    await dbWorker.postMessage({
      action: 'updatePosts',
      data: response,
    });

    return response;
  }
);

export const fetchComments = createAsyncThunk(
  'conversations/fetchComments',
  async (postId: string, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await socialWallService.fetchComments(
      jwtToken,
      URL_MESSAGES,
      postId
    );

    await dbWorker.postMessage({
      action: 'updateComments',
      data: response,
    });

    return response;
  }
);

export const markAsAnswered = createAsyncThunk(
  'conversations/markAsAnswered',
  async (data: { commentId: string; postId: string }, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await socialWallService.markAsAnswered(
      jwtToken,
      URL_MESSAGES,
      data
    );
    return data;
  }
);

export const setMessage = createAsyncThunk<
  WallMessage,
  SendMessage,
  { state: RootState }
>('conversations/setMessage', async (data, { dispatch }) => {
  const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const response = await socialWallService.setMessage(data, jwtToken, URL_MESSAGES);
  return response;
});

export const postAction = createAsyncThunk(
  'conversations/postAction',
  async (data: PostAction, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await socialWallService.postAction(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);

const socialWallSlice = createSlice({
  name: 'socialWall',
  initialState,
  reducers: {
    setNewPosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload;
    },
    setNewComments: (state, action: PayloadAction<WallMessage[]>) => {
      state.comments = action.payload;
    },
    deletePost: (state, action: PayloadAction<string>) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
    },
    updatePost: (state, action: PayloadAction<Post>) => {
      const findPost = state.posts.find((post) => post.id === action.payload.id);
      // If the post does not exist, add it to the list
      if (!findPost) {
        console.log('add NEW post: ', action.payload);
        state.posts.push(action.payload);
      } else {
        state.posts = state.posts.map((post) =>
          post.id === action.payload.id ? action.payload : post
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status.posts = 'loading';
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status.posts = 'rejected';
        console.log('fetchPosts rejected: ', action.payload);
      })
      .addCase(fetchPosts.fulfilled, (state, action: PayloadAction<Post[]>) => {
        state.status.posts = 'succeeded';
        state.posts = action.payload;
        console.log('fetchPosts succeeded: ', action.payload);
      })
      .addCase(fetchComments.pending, (state) => {
        state.status.comments = 'loading';
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.status.comments = 'rejected';
        console.log('fetchComments rejected: ', action.payload);
      })
      .addCase(
        fetchComments.fulfilled,
        (state, action: PayloadAction<WallMessage[]>) => {
          state.status.comments = 'succeeded';
          state.comments = action.payload;
          console.log('fetchComments succeeded: ', action.payload);
        }
      )
      .addCase(setMessage.pending, (state) => {
        state.status.send = 'loading';
      })
      .addCase(setMessage.rejected, (state, action) => {
        state.status.send = 'rejected';
        console.log('setMessage rejected: ', action.payload);
      })
      .addCase(setMessage.fulfilled, (state, action) => {
        state.status.send = 'succeeded';
        console.log('setMessage succeeded: ', action.payload);
      })
      .addCase(postAction.pending, (state) => {
        state.status.action = 'loading';
      })
      .addCase(postAction.rejected, (state, action) => {
        state.status.action = 'rejected';
        console.log('postAction rejected: ', action.payload);
      })
      .addCase(postAction.fulfilled, (state, action) => {
        state.status.action = 'succeeded';
        console.log('postAction succeeded: ', action.payload);
      })
      .addCase(markAsAnswered.fulfilled, (state, action) => {
        const { commentId } = action.payload;

        const messages = state.comments.map((message) => {
          if (message.id === commentId) {
            message.readed = true;
          }
          return message;
        });
        state.comments = messages;
      });
  },
});

export const { setNewPosts, setNewComments, updatePost, deletePost } =
  socialWallSlice.actions;

const socialWallState = (state: RootState) => state.SocialWall;
// Selectors
export const selectPosts = createSelector(
  socialWallState,
  (conversations) => conversations.posts
);
export const selectPostsStatus = createSelector(
  socialWallState,
  (conversations) => conversations.status.posts
);
export const selectComments = createSelector(
  socialWallState,
  (conversations) => conversations.comments
);
export const selectCommentsStatus = createSelector(
  socialWallState,
  (conversations) => conversations.status.comments
);
export const selectActionStatus = createSelector(
  socialWallState,
  (conversations) => conversations.status.action
);

export default socialWallSlice.reducer;
