import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, FormControlLabel, Checkbox } from '@mui/material';

const Notifications = () => {
  const { selectNode, setFillTicketNode, fillTicketNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [ticketSendEmailToContact, setTicketSendEmailToContact] =
    useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data.fillTicket) {
      const fillTicket = selectNode.data.fillTicket;
      const defaultTicketSendEmailToContact =
        fillTicket.ticketSendEmailToContact &&
        (fillTicket.ticketSendEmailToContact as boolean);
      setTicketSendEmailToContact(defaultTicketSendEmailToContact);
    }
  }, [selectNode]);

  const handleChangeTicketSendEmailToContact = (
    ticketSendEmailToContact: boolean
  ) => {
    setTicketSendEmailToContact(ticketSendEmailToContact);
    setFillTicketNode({ ...fillTicketNode, ticketSendEmailToContact });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.fillTicket.notifications')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={ticketSendEmailToContact}
            onChange={(e) => handleChangeTicketSendEmailToContact(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: 'caption',
            color: 'text.primary',
          },
        }}
        label={t('editFlowsView.fillTicket.notificationsDescription')}
      />
    </>
  );
};

export default Notifications;
