import { useTranslation } from 'react-i18next';
// Types
import { EmailConfigOutboundStatus } from '@trii/types/dist/Common/Channels';
// Components/ui
import { Box, Divider, Typography, Chip, Button } from '@mui/material';

interface Props {
  title: string;
  children: React.ReactNode;
  status: EmailConfigOutboundStatus;
  testAdjust: () => void;  
}

const SectionContainer = ({ title, status, children, testAdjust }: Props) => {
  const { t } = useTranslation();

  const StatusChip =
    status === EmailConfigOutboundStatus.SUCCESS ? (
      <Chip size="small" label={t('global.active')} color="success" />
    ) : (
      <Chip size="small" label={t('global.inactive')} color="error" />
    );
    const openSetBySepInstruction = () => {
      window.open('https://www.google.com.ar/', '_blank')

    };
  return (
    <Box>
      <Box py={1} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="subtitle2">{title}</Typography>
        {StatusChip}
      </Box>
      <Divider />
      <Box p={2} pb={4} display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {children}
        </Box>
        <Box display={'flex'} gap={2} justifyContent={'space-between'}>
          <Button size="small" variant="outlined" onClick={testAdjust}>
            {t('emailEditView.outgoingEmailPanel.testSettings')}
          </Button>
          <Button size="small" variant={'text'} onClick={openSetBySepInstruction}>
            {t('emailEditView.outgoingEmailPanel.stepByStepInstructions')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionContainer;
