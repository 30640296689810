import React from 'react';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectSipsFetchStatus,
  selectAllSips,
} from 'redux/features/sipSlice/sipSlice';
// Components/ui
import { Box } from '@mui/material';
import SipItem from './components/SipItem/SipItem';
import { ListItemSkeleton } from 'components';

const ListBody = () => {
  const allSips = useSelector(selectAllSips);
  const sipsFetchStatus: RequestStatus = useSelector(selectSipsFetchStatus);

  const Sips = allSips.map((sip) => <SipItem key={sip.id} data={sip} />);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 185px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        pb: 1,
      }}
    >
      {sipsFetchStatus === 'succeeded' ? Sips : <ListItemSkeleton />}
    </Box>
  );
};

export default ListBody;
