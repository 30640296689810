import { 
  Box,
  Skeleton as MuiSkeleton,
 } from "@mui/material"

const Item = () => {
  return (
    <Box>
      <MuiSkeleton
        variant="rectangular"
        width="100%"
        height={50}
        sx={{
          borderRadius: '5px',
        }}
        animation="wave"
      />
    </Box>
  )
}

export default Item