import { createContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// Dayjs
import dayjs, { Dayjs } from "dayjs";
// Redux
import { useSelector } from "react-redux";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Context
import { DashboardContaxtState } from "./types/DashboardContaxtState";
import { DataDashboard } from "redux/features/dashboardSlice/types/DataDashboard";
// Slice
import { fetchDownloadForms, fetchLogs, fetchLogsChannels, fetchLogsMessages, fetchSubmittedForms, fetchSummary, fetchSummaryChannels, fetchSummaryGeneral, fetchSummaryMessages, postAll } from "redux/features/dashboardSlice/dashboardSlice";
import { fetchGroups } from "redux/features/conversationsSlice/conversationsSlice";
import { IGroup } from "@trii/types/dist/Conversations/Groups/Group";
import { fetchUsers, selectUser } from "redux/features/userSlice/userSlice";
import { fetchForms } from "redux/features/formSlice/formSlice";
import { fetchForm } from "redux/features/formEditSlice/formEditSlice";
import { DashboardForm } from "redux/features/dashboardSlice/types/DashboardForm";
// Types
import { BodyDashboard, DashBoardChannelsSummaryInfo, TimeRange } from "@trii/types/dist/Conversations";
import { IChannel } from "@trii/types/dist/Common/Channels";

const DashboardContext = createContext<DashboardContaxtState>({
  // Group
  currentGroup: null,
  setCurrentGroup: () => { },
  // Card State
  cardConversationsCant: 0,
  setCardConversationsCant: () => { },
  cardInLineCant: 0,
  setInLineCant: () => { },
  cardInProgressCant: 0,
  setInProgressCant: () => { },
  cardExpiredCant: 0,
  setExpiredCant: () => { },
  cardContactsCant: 0,
  setContactsCant: () => { },
  cardFinishedCant: 0,
  setFinishedCant: () => { },
  cardOnlineAgentsCant: 0,
  setOnlineAgentsCant: () => { },
  cardAgentsGroupCant: 0,
  setAgentsGroupCant: () => { },
  cardAverageFirstAnsCant: '',
  setAverageFirstAnsCant: () => { },
  cardAverageFinishedCant: '',
  setAverageFinishedCant: () => { },
  // Modal State
  modalRegisterOpen: false,
  setModalRegisterOpen: () => { },
  modalRegisterTitle: '',
  setModalRegisterTitle: () => { },
  modalRegisterList: [],
  setModalRegisterList: () => { },
  // General Funcions
  obtainRegister: () => { },
  getUsers: () => { },
  // Groups
  getGroups: () => { },
  // Logs
  getLogs: () => { },
  // All Data
  getAllDataDashboard: (selectedGroupId: string) => { },
  // User Dashboard
  userDashboard: null,
  setUserDashboard: (data: BodyDashboard) => { },
  // Forms
  forms: [],
  setForms: () => { },
  form: null,
  setForm: () => { },
  startDate: dayjs(),
  setStartDate: () => { },
  endDate: dayjs(),
  formIndex: 0,
  setFormIndex: () => { },
  completedForms: 0,
  setCompletedForms: () => { },
  printRef: null,
  setEndDate: () => { },
  getForms: () => { },
  getForm: (formId: string) => { },
  getSummary: (data: DashboardForm) => { },
  getSubmittedForms: (data: DashboardForm) => { },
  getDownloadUrl: (data: DashboardForm) => { return new Promise(() => { }) },
  // Dashboard Channels
  getSummaryChannels: () => { },
  getLogsChannels: () => { },
  // Dashboard Messages
  getSummaryMessages: () => { },
  getLogsMessages: (channelId: string) => { },
  getSummaryGeneral: () => { },
});

const DashboardProvider = (props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  // Group
  const [currentGroup, setCurrentGroup] = useState<IGroup>(null);
  // User Dashboard
  const [userDashboard, setUserDashboard] = useState<BodyDashboard>({
    groupId: null,
    userId: null,
    timeRange: TimeRange.DAY,
    timeStart: null,
    timeEnd: null,
    memberId: null,
  })
  // Forms
  const [forms, setForms] = useState<IChannel[]>([]);
  const [form, setForm] = useState<IChannel>();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [formIndex, setFormIndex] = useState<number>(0);
  const [completedForms, setCompletedForms] = useState<number>(0);
  const printRef = useRef<HTMLDivElement>(null);
  const [cardConversationsCant, setCardConversationsCant] = useState<number>();
  const [cardInLineCant, setInLineCant] = useState<number>();
  const [cardInProgressCant, setInProgressCant] = useState<number>();
  const [cardExpiredCant, setExpiredCant] = useState<number>();

  const [cardContactsCant, setContactsCant] = useState<number>();
  const [cardFinishedCant, setFinishedCant] = useState<number>();
  const [cardOnlineAgentsCant, setOnlineAgentsCant] = useState<number>();
  const [cardAgentsGroupCant, setAgentsGroupCant] = useState<number>();

  const [cardAverageFirstAnsCant, setAverageFirstAnsCant] = useState<string>("0. 00:00:01")
  const [cardAverageFinishedCant, setAverageFinishedCant] = useState<string>("1. 19:21:21")


  const [modalRegisterOpen, setModalRegisterOpen] = useState<boolean>(false);
  const [modalRegisterTitle, setModalRegisterTitle] = useState<string>("");
  const [modalRegisterList, setModalRegisterList] = useState([]);

  const obtainRegister = (type: string) => {
    if (type === "dashboard.cardFinished") {
      setModalRegisterList(
        [
          {
            channel: "TriiFinished",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardConversations") {
      setModalRegisterList(
        [
          {
            channel: "TriiConversations",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardNewsInline") {
      setModalRegisterList(
        [
          {
            channel: "TriiNewsInline",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardInProgressInAgents") {
      setModalRegisterList(
        [
          {
            channel: "TriicardInProgressInAgents",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardExpired") {
      setModalRegisterList(
        [
          {
            channel: "TriiExpired",
            cant: "31/12/2023",
          },
        ],
      )
    }
  };

  //Groups
  const getGroups = async () => {
    await dispatch(fetchGroups());
  };

  // Logs
  const getLogs = async (data: DataDashboard) => {
    await dispatch(fetchLogs(data));
  }

  // All Data
  const getAllDataDashboard = async (selectedGroupId: string) => {
    dispatch(
      postAll({
        ...userDashboard,
        groupId: selectedGroupId,
      })
    );
  }

  // Form List
  const getForms = async () => {
    await dispatch(fetchForms());
  }

  const getForm = async (formId: string) => {
    await dispatch(fetchForm(formId));
  }

  const getSummary = async (data: DashboardForm) => {
    await dispatch(fetchSummary(data))
  }

  const getSubmittedForms = async (data: DashboardForm) => {
    await dispatch(fetchSubmittedForms(data))
  }

  const getDownloadUrl = async (data: DashboardForm) => {
    const response = await dispatch(fetchDownloadForms(data))
    return response.payload;
  }

  const getUsers = async () => {
    await dispatch(fetchUsers());
  }

  // Dashboard Channels
  const getSummaryChannels = async () => {
    await dispatch(fetchSummaryChannels());
  }

  const getLogsChannels = async (channelId: string) => {
    await dispatch(fetchLogsChannels(channelId));
  }

  // Dashboard Messages
  const getSummaryMessages = async ({ timeRange, timeStart, timeEnd }: BodyDashboard) => {
    await dispatch(
      fetchSummaryMessages({
        userId: userDashboard.userId,
        timeRange,
        timeStart,
        timeEnd,
        groupId: '',
        memberId: ''

      })
    );
  };
  const getSummaryGeneral = async ({ timeRange, timeStart, timeEnd }: BodyDashboard) => {
    await dispatch(
      postAll({
        userId: userDashboard.userId,
        timeRange,
        timeStart,
        timeEnd,
        groupId: '',
        memberId: ''
      })
    );
  };
  
  const getLogsMessages = async (channelId: string) => {
    await dispatch(fetchLogsMessages(channelId));
  }

  useEffect(() => {
    if (user) {
      setUserDashboard((prev) => ({
        ...prev,
        userId: user.uid,
      }));
    }
  }, [user]);

  return (
    <DashboardContext.Provider
      value={{
        cardConversationsCant,
        setCardConversationsCant,
        cardInLineCant,
        setInLineCant,
        cardInProgressCant,
        setInProgressCant,
        cardExpiredCant,
        setExpiredCant,
        cardContactsCant,
        setContactsCant,
        cardFinishedCant,
        setFinishedCant,
        cardOnlineAgentsCant,
        setOnlineAgentsCant,
        cardAgentsGroupCant,
        setAgentsGroupCant,
        cardAverageFirstAnsCant,
        setAverageFirstAnsCant,
        cardAverageFinishedCant,
        setAverageFinishedCant,
        obtainRegister,
        modalRegisterTitle,
        setModalRegisterTitle,
        modalRegisterList,
        setModalRegisterList,
        modalRegisterOpen,
        setModalRegisterOpen,
        // Groups
        currentGroup,
        setCurrentGroup,
        getGroups,
        // Logs
        getLogs,
        // All Data
        getAllDataDashboard,
        // User Dashboard
        userDashboard,
        setUserDashboard,
        // Forms
        forms,
        setForms,
        form,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        formIndex,
        setFormIndex,
        completedForms,
        setCompletedForms,
        printRef,
        setForm,
        getForms,
        getForm,
        getSummary,
        getSubmittedForms,
        getDownloadUrl,
        getUsers,
        // Dashboard Channels
        getSummaryChannels,
        getLogsChannels,
        // Dashboard Messages
        getSummaryMessages,
        getSummaryGeneral,
        getLogsMessages,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
DashboardProvider.propTypes = {
  children: PropTypes.node,
};

export { DashboardContext, DashboardProvider };
