import { useState, useEffect, useContext } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from '../../../../../../../../../../context/EditFlowsContext';
import { EditFlowsContext } from '../../../../../../../../../../context/types';
// Slice
import {
  selectForms,
  selectFormsStatus,
} from '../../../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';
// Selector
import { useSelector } from 'react-redux';
// Components/ui
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  TextField,
} from '@mui/material';
// Interface
import { FillFormKeyValue } from '@trii/types/dist/Conversations/Flows/Nodes';

const Forms = () => {
  const { t } = useTranslation();
  const { fillFormNode, setFillFormNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const forms = useSelector(selectForms);
  const formsStatus = useSelector(selectFormsStatus);
  const isLoading = formsStatus === 'loading';
  const [formId, setFormId] = useState<string>('');
  const [response, setResponse] = useState<FillFormKeyValue[]>([]);

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.fillForm) {
      const fillForm = selectNode && selectNode?.data && selectNode?.data?.fillForm;
      const defaultFormId = fillForm.formId ? fillForm?.formId : ('' as string);
      const defaultResponse = fillForm.responses
        ? fillForm.responses
        : ([] as FillFormKeyValue[]);
      setFormId(defaultFormId);
      setResponse(defaultResponse);
    }
  }, [selectNode]);

  const handleChangeForm = (formId: string) => {
    const newForm = forms
      .find((form) => form.id === formId)
      ?.form?.items?.map((question) => ({
        questionId: question.id,
        question: question.question,
        responseValue: '',
      })) as FillFormKeyValue[];
    setResponse(newForm);
    setFormId(formId);
    setFillFormNode({ ...fillFormNode, formId });
  };

  const handleChangeResponse = (value: string, questionId: string) => {
    const newResponse = response.map((res) =>
      res.questionId === questionId ? { ...res, responseValue: value } : res
    );
    setResponse(newResponse);
    setFillFormNode({ ...fillFormNode, responses: newResponse });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.fillForm.formSelector')}
      </Typography>
      <Select
        id="demo-simple-select"
        value={formId ? formId : ('' as string)}
        onChange={(e) => handleChangeForm(e?.target?.value as string)}
        disabled={isLoading}
        size="small"
        sx={{
          "& .MuiSvgIcon-root": {
            color: "text.disabled",
          },
          '& legend' : {
            maxWidth: "fit-content",
          //   padding: '0 10px'
          },
        }}
      >
        {forms &&
          forms?.length !== 0 &&
          forms?.map((form) => (
            <MenuItem value={form?.id} key={form?.id}>
              {form?.name}
            </MenuItem>
          ))}
      </Select>
      {response && response.length ? (
        response.map((res) => (
          <Box key={res.questionId}>
            <Typography variant="subtitle2" color="text.primary">{res.question}</Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={res ? res?.responseValue : ('' as string)}
              onChange={(e) =>
                handleChangeResponse(e?.target?.value, res?.questionId)
              }
            />
          </Box>
        ))
      ) : (
        <> </>
      )}
    </>
  );
};

export default Forms;
