import { useContext } from 'react';
// Context
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';
// Types
import { ISchedule } from '@trii/types/dist/Common/Schedules';
// Components/ui
import { Box, Stack, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IActionButtonsProps {
  schedule: ISchedule;
}

const ActionButtons = ({ schedule }: IActionButtonsProps) => {
  const { openDeletePanel, navigateToEdit } = useContext(InitScheduleContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      justifyContent="space-evenly"
    >
      <Stack direction="row">
        <IconButton
          sx={{ color: (theme) => theme.palette.primary.main }}
          onClick={() => navigateToEdit(schedule.id)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => openDeletePanel(schedule.id)}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ActionButtons;
