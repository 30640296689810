import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip, IconButton, Modal, Typography, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
// Types
import { MessageEmail } from '@trii/types/dist/Common/Messages';
// Components
import { ActionButtons, EmailDestination } from './components';
import useSAS from 'hooks/useSAS';

interface Props {
  email: MessageEmail;
  open: boolean;
  onClose: () => void;
  date: string;
}

const EmailDetails = ({ email, open, onClose, date }: Props) => {
  const { t } = useTranslation();
  const [emailHeight, setEmailHeight] = useState(6);
  const [formattedBody, setFormattedBody] = useState('');
  const URLHandler = useSAS();
  const theme = useTheme();

  const handleDownload = async (url: string) => {
    const link = document.createElement('a');
    const fileName = url.split('/').pop();
    const accessURL = await URLHandler.getURLWithAccessToken(url);

    link.download = fileName || '';
    link.href = accessURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    let height = 0;
    if (email && email.cc && email.cc.length > 0) {
      height += 2;
    }
    if (email && email.bcc && email.bcc.length > 0) {
      height += 2;
    }
    if (email && email.attachments && email.attachments.length > 0) {
      height += 2;
    }
    setEmailHeight(emailHeight + height);
  }, [email]);

  useEffect(() => {
    if (open && email) {
      const body =
        email.bodyHtml === ''
          ? email.bodyText.replace(/(?:\r\n|\r|\n)/g, '<br>')
          : email.bodyHtml;
      const formatted = body;
      setFormattedBody(formatted);
    }
  }, [open, email]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'grid',
        placeItems: 'center',
        '& .MuiBackdrop-root': {
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        p={2}
        paddingX={2}
        paddingTop={1}
        paddingBottom={0}
        sx={{
          width: '90vw',
          height: '90vh',
          backgroundColor: theme.palette.background.default,
          borderRadius: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            height: '2xrem',
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <IconButton onClick={onClose} size="small">
            <Close
              sx={{
                color: theme.palette.text.disabled,
              }}
            />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          height="calc(100% - 2rem)"
          width="100%"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '2rem',
            }}
          >
            <EmailDestination
              title="conversations.channel.email.from"
              list={email?.from}
            />
            <ActionButtons email={email} date={date} onClose={onClose} />
          </Box>
          <EmailDestination
            title="conversations.channel.email.to"
            list={email?.to}
          />
          {email?.cc && email?.cc.length > 0 && (
            <EmailDestination
              title="conversations.channel.email.cc"
              list={email?.cc}
            />
          )}
          {email?.bcc && email?.bcc.length > 0 && (
            <EmailDestination
              title="conversations.channel.email.bcc"
              list={email?.bcc}
            />
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: '0.5rem',
              height: '2rem',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {t('conversations.channel.email.subject')}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                maxWidth: 200,
                color: theme.palette.text.primary,
                whiteSpace: 'nowrap',
              }}
            >
              {email?.subject}
            </Typography>
          </Box>
          {email?.attachments && email?.attachments.length > 0 && (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              sx={{
                padding: '0.5rem 0',
                height: '2rem',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {t('conversations.channel.email.attachments')}
              </Typography>
              <Box display="flex" gap={1} flexWrap={'wrap'}>
                {email?.attachments.map((attachment) => {
                  return (
                    <Chip
                      size="small"
                      key={attachment.id}
                      onClick={() => handleDownload(attachment.url)}
                      label={attachment.filename}
                      sx={{
                        width: 'max-content',
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
          )}
          <Typography
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              height: `calc(100% - ${emailHeight}rem)`,
              width: '100%',
              wordBreak: 'break-word',
              fontSize: '0.8rem',
              color: theme.palette.text.primary,
              borderTop: `1px solid ${theme.palette.divider}`,
              pt: 1,
            }}
            dangerouslySetInnerHTML={{ __html: formattedBody }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailDetails;
