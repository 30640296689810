import React, { useContext, useState, useRef, useEffect } from 'react';
//Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Selector
import { useSelector } from 'react-redux';
// Slice
import { saveEditFlowStatus } from 'redux/features/editFlowSlice/editFlowSlice';
// Router
import { useNavigate, useParams } from 'react-router-dom';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import {
  IconButton,
  Box,
  ButtonGroup,
  Button,
  Typography,
  Modal,
  useTheme,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// Icons
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Header() {
  const {
    flowName,
    setShowService,
    showService,
    editPanelOpen,
    nodes,
    setNodes,
    selectNode,
    selectNodesId,
    selectEdgesId,
    sendEditFlows,
    resetEditFields,
    edges,
    setEdges,
    setSelectNode,
  } = useContext<EditFlowsContext>(InitEditFlowsContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openModalExit, setOpenModalExit] = useState<boolean>(false);
  const [openModalDeleteNodes, setOpenModalDeleteNodes] = useState<boolean>(false);
  const [deleteNodes, setDeleteNodes] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const editStatus = useSelector(saveEditFlowStatus);
  const isLoading: boolean = editStatus === 'loading';
  const { t } = useTranslation();
  const options = [t('editFlowsView.saveAndExit'), t('editFlowsView.save')];

  useEffect(() => {
    if (selectNodesId) {
      setDeleteNodes(selectNodesId.length <= 1);
    }
  }, [selectNodesId]);

  const handleShowService = () => {
    // if (editPanelOpen) {
    //   // setEditPanelOpen(false);
    //   setShowService(true);
    //   if (selectNode) {
    //     setNodes((prevNodes) => {
    //       const newNodes = prevNodes.map((node) => {
    //         if (node.id === selectNode.id) {
    //           return {
    //             ...node,
    //             selected: false,
    //           };
    //         }
    //         return node;
    //       });
    //       return newNodes;
    //     });
    //   }
    // } else {
    //   setShowService(!showService);
    // }
    setShowService(!showService);
  };

  const handleClick = () => {
    if (selectedIndex === 0) {
      sendEditFlows(id);
      resetEditFields();
      navigate(-1);
    }
    if (selectedIndex === 1) {
      sendEditFlows(id);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleOpenModalExit = () => {
    setOpenModalExit(true);
  };

  const handleOpenModalDeleteNodes = () => {
    setOpenModalDeleteNodes(true);
  };

  const handleCloseModal = () => {
    setOpenModalExit(false);
    setOpenModalDeleteNodes(false);
  };

  const handleClickModal = () => {
    if (openModalExit) {
      setOpenModalExit(false);
      resetEditFields();
      navigate(-1);
    }
    if (openModalDeleteNodes) {
      setOpenModalDeleteNodes(false);
      const newNodes = nodes.filter((node) => !selectNodesId.includes(node.id));
      const newEdges = edges.filter((edge) => !selectEdgesId.includes(edge.id));
      setNodes(newNodes);
      setEdges(newEdges);
      setSelectNode(null);
    }
  };
  const theme = useTheme();
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: theme.palette.background.default,
    backgroundImage:
      'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  return (
    <Box
      display="flex"
      width="100%"
      border={`1px solid ${theme.palette.divider}`}
      p={1}
      borderRadius={'0.2rem'}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} gap={1}>
        <IconButton
          sx={{ color: 'text.disabled' }}
          onClick={() => handleShowService()}
        >
          <MenuIcon />
        </IconButton>
        <TextField
          label={t('global.name')}
          {...flowName.attributes}
          variant="outlined"
          size="small"
          sx={{ width: '20rem' }}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin="0 1rem"
        gap="1rem"
      >
        <Button
          onClick={() => handleOpenModalExit()}
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIcon />}
        >
          {t('global.exit')}
        </Button>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          size="small"
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            variant="contained"
            startIcon={selectedIndex === 0 ? <SaveAsIcon /> : <SaveIcon />}
            size="small"
            sx={{
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
            onClick={() => handleClick()}
          >
            {options[selectedIndex]}
          </LoadingButton>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id="split-button-menu"
                    autoFocusItem
                    sx={{
                      //@ts-ignore
                      backgroundColor: theme.palette.dropdownBox,
                    }}
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          onClick={() => handleOpenModalDeleteNodes()}
          variant="contained"
          size="small"
          startIcon={<DeleteIcon />}
          sx={{
            backgroundColor: (theme) => theme.palette.error.main,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.error.dark,
            },
          }}
          disabled={deleteNodes}
        >
          {t('editFlowsView.deleteNodes')}
        </Button>
      </Box>
      <Modal
        open={openModalExit || openModalDeleteNodes}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" mb={2} color="text.primary">
            {t('editFlowsView.safetyExit')}
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClickModal()}
            >
              {openModalExit ? t('global.exit') : t('editFlowsView.confirmDelete')}
            </Button>
            <Button variant="contained" onClick={() => handleCloseModal()}>
              {t('global.cancel')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
