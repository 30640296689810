// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Typography } from "@mui/material"
// Icons
import { LocationOn } from "@mui/icons-material"
import { IContactPostalAddress } from "@trii/types/dist/Contacts"

interface Props {
  address: IContactPostalAddress;
  showTitle?: boolean;
}

const Address = ({
  address,
  showTitle = true,
}: Props) => {
  const { t } = useTranslation()
  const {
    street,
    city,
    state,
    zipcode,
    country,
  } = address || {}

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "max-content",
        alignItems: "center",
        gap: 1,
      }}
    >
      {
        showTitle && (
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="semi-bold"
              color={"text.disabled"}
            >
              {t("conversations.contactInfo.address")}
            </Typography>
          </Box>
        )
      }
      {
        street && city && state && zipcode && country && (
          <Box
            sx={{
              width: "100%",
              height: "max-content",
              display: "flex",
              alignItems: "start",
              gap: 1,
            }}
          >
            <LocationOn
              fontSize="small"
              sx={{
                mt: 0.5,
                color: (theme) => theme.palette.primary.dark,
              }}
            />
            <Typography
              variant="subtitle1"
              fontWeight="semi-bold"
              fontSize={14}
              color={"text.disabled"}
            >
              {`${street}, ${city}, ${state}, ${zipcode}, ${country}`}
            </Typography>
          </Box>
        )
      }
    </Box>
  )
}

export default Address