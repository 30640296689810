import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Selector
import { useSelector } from 'react-redux';
// Slice
import {
  selectEmailChannels,
  selectEmailsStatus,
} from 'redux/features/editFlowSlice/editFlowSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

const Channel = () => {
  const { sendEmailNode, setSendEmailNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [channelId, setChannelId] = useState<string>('');
  const channels = useSelector(selectEmailChannels);
  const editStatus = useSelector(selectEmailsStatus);
  const isLoading: boolean = editStatus === 'loading';

  useEffect(() => {
    if (
      selectNode &&
      selectNode.data &&
      selectNode.data.sendEmail &&
      selectNode.data.sendEmail.channelId
    ) {
      const defaultChannelId = selectNode.data.sendEmail.channelId as string;
      setChannelId(defaultChannelId);
    }
  }, [selectNode]);

  const handleChangeChannel = (channelId: string) => {
    setChannelId(channelId);
    setSendEmailNode({ ...sendEmailNode, channelId });
  };

  return (
    <Box>
      <FormControl fullWidth size="small">
        <InputLabel id="channel" shrink={true}>
          {t("editFlowsView.sendEmail.channel")}
        </InputLabel>
        <Select
          labelId="channel"
          id="demo-simple-select"
          value={channelId}
          label={t("editFlowsView.sendEmail.channel")}
          onChange={(e) => handleChangeChannel(e.target.value)}
          disabled={isLoading}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {channels &&
            channels?.map((channel) => (
              <MenuItem key={channel.id} value={channel.id}>
                {channel.email.emailAddress}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Typography variant="caption" fontStyle="italic" sx={{ opacity: 0.7 }} color="text.primary">
        {t("editFlowsView.sendEmail.channelDescription")}
      </Typography>
    </Box>
  );
};

export default Channel;
