import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Selector
import { useSelector } from 'react-redux';
// Slice
import {
  selectGroups,
  selectGroupsStatus,
} from '../../../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const Groups = () => {
  const { assignToNode, setAssignToNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [groupId, setGroupId] = useState<string>('');
  const groups = useSelector(selectGroups);
  const editStatus = useSelector(selectGroupsStatus);
  const isLoading: boolean = editStatus === 'loading';

  useEffect(() => {
    if (
      selectNode &&
      selectNode.data &&
      selectNode.data.conversationAssignTo &&
      selectNode.data.conversationAssignTo.groupId
    ) {
      const defaultChannelId = selectNode.data.conversationAssignTo
        .groupId as string;
      setGroupId(defaultChannelId);
    }
  }, [selectNode]);

  const handleChangeGroup = (groupId: string) => {
    setGroupId(groupId);
    setAssignToNode({ ...assignToNode, groupId });
  };

  return (
    <>
      <Box mt={1}>
        <FormControl fullWidth size="small">
          <InputLabel id="group" shrink={true}>
            {t("editFlowsView.assignTo.groupSelector")}
          </InputLabel>
          <Select
            labelId="group"
            id="demo-simple-select"
            value={groupId}
            label={t("editFlowsView.assignTo.groupSelector")}
            onChange={(e) => handleChangeGroup(e.target.value)}
            disabled={isLoading}
            sx={{
              "& legend": {
                maxWidth: "fit-content",
                // padding: '0 10px'
              },
              "& .MuiSvgIcon-root": {
                color: "text.disabled",
              },
            }}
          >
            {groups &&
              groups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default Groups;
