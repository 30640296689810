import React, { useContext, useEffect } from 'react';
import { mercadoLibreContext } from 'features/Views/MercadoLibre/context/MercadoLibreProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { CreateButtons } from './components';
import { CrudContainer } from 'components';
import { PanelHeader } from 'components';
import CreateInputs from './components/CreateInputs';

const CreatePanel = () => {
  const { endCreating, createMercadoLibreState } = useContext(mercadoLibreContext);
  const { t } = useTranslation();

  useEffect(() => {
    const cleanUp = () => {
      createMercadoLibreState.actions.resetAll();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('mercadoLibreView.addMercadoLibre')}
        handleCloseButton={endCreating}
      />
      <Divider />
      <CreateInputs createMercadoLibreState={createMercadoLibreState} />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <CreateButtons />
    </CrudContainer>
  );
};

export default CreatePanel;
