import { useContext, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
// Utils
import { channelList } from './utils/channelList';
import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';
import { useTheme } from '@mui/material/styles';

const FilterChannels = () => {
  const { t } = useTranslation();
  const [channels, setChannels] = useState<number[]>([]);

  const { setSelectedChannels } = useContext(MyReportContext);

  const handleSelect = (channelIds: number[]) => {
    setChannels(channelIds);
    setSelectedChannels(channelIds);
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 2,
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel id="search-message-filter-channels" shrink={true}>
          {t('myReports.channels')}
        </InputLabel>
        <Select
          labelId="search-message-filter-channels"
          value={channels}
          label={t('myReports.channels')}
          onChange={(e) => handleSelect(e.target.value as number[])}
          multiple
          sx={{
            '& legend': {
              maxWidth: '100%',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: theme.palette.background.panel,
              },
            },
          }}
        >
          {channelList.map((channel) => (
            <MenuItem key={channel?.id} value={channel?.id}>
              {t(channel?.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterChannels;
