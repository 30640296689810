import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { changeHomeSubtitle } from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import OptionsContainer from '../../../OptionsContainer';
import { TextField } from '@mui/material';

interface Props {
  homeSubtitle: string;
}

const TitleOptions = ({ homeSubtitle }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleHomeSubtitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeHomeSubtitle(event.target.value));
  };

  return (
    <OptionsContainer title={t('global.subtitle')}>
      <TextField
        fullWidth
        size="small"
        label={t('webChatEditView.designPanel.homeSection.addSubtitle')}
        value={homeSubtitle}
        onChange={handleHomeSubtitleChange}
      />
    </OptionsContainer>
  );
};

export default TitleOptions;
