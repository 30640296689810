import React, { useEffect } from 'react';
import FacebookProvider from './context/FacebookProvider';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchFacebooks } from 'redux/features/facebookSlice/facebookSlice';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, DeletePanel } from './components';

const FacebookContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchFacebooks());
  }, [dispatch]);

  return (
    <FacebookProvider>
      <Box display={'flex'}>
        <AppPanel />
        <DeletePanel />
      </Box>
    </FacebookProvider>
  );
};

export default FacebookContainer;
