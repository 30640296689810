import { useContext } from 'react';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { useTranslation } from 'react-i18next';
// Types
import { GroupContext } from 'features/Views/Groups/context/types';
// Components/ui
import { Button, Box, Typography, Breadcrumbs } from '@mui/material';
import { NavLink } from 'react-router-dom';

export default function GroupHeader() {
  const { t } = useTranslation();
  const { openCreatePanel, createPanelOpen } =
    useContext<GroupContext>(InitGroupContext);

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/groups">
      <Typography color="secondary">{t('settingsView.groups.groups')}</Typography>
    </NavLink>,
  ];
  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      sx={{ paddingRight: 2 }}
      borderRadius={'4px'}
      height="50px"
       // className="m-0"
       gap={'1rem'}

    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={openCreatePanel}
        disabled={createPanelOpen}
      >
        {t('settingsView.groups.createGroup')}
      </Button>
    </Box>
  );
}
