import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from 'redux/functions/initRequestData';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { RootState } from 'redux/store';
import { ReportsSlice } from './types/ReportsSlice';
import { BodyDashboard } from '@trii/types/dist/Conversations';
import { UserDataReports } from './types/UserDataReports';
import reportsService from './reportsServices';
import { JsReportData } from './types/JsReportData';

const initialState: ReportsSlice = {
  userData: {
    contactId: '651d54d26a67007d483b465d',
    timeRange: 1,
    timeStart: '',
    timeEnd: '',
  },
  jsReport: null,
  registersDetailsData: {},
  conversationsTrendsData: {},
  timeDistributionData: {},
  userPerformanceData: {},
  responseTimesData: {},
  detailsByUserGroupData: {},
  endingsData: {},
  summaryData: {},
  detailsPerChannelData: {},
  flowStatisticsData: {},
  satisfactionSurveyData: {},
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const postRecords = createAsyncThunk(
  'dashboard/postRecords',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postRecords(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postConversationsTrends = createAsyncThunk(
  'dashboard/postConversationsTrends',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postConversationsTrends(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postTimeDistribution = createAsyncThunk(
  'dashboard/postTimeDistribution',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postTimeDistribution(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);

export const postUserPerformance = createAsyncThunk(
  'dashboard/postUserPerformance',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postUserPerformance(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postResponseTimes = createAsyncThunk(
  'dashboard/postResponseTimes',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postResponseTimes(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postDetailsByUserGroup = createAsyncThunk(
  'dashboard/postDetailsByUserGroup',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postDetailsByUserGroup(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postEndings = createAsyncThunk(
  'dashboard/postEndings',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postEndings(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postSummary = createAsyncThunk(
  'dashboard/postSummary',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postSummary(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postChannelDetail = createAsyncThunk(
  'dashboard/postChannelDetail',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postChannelDetail(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postStatistics = createAsyncThunk(
  'dashboard/postStatistics',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postStatistics(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);
export const postSatisfactionSurvey = createAsyncThunk(
  'dashboard/postSatisfactionSurvey',
  async (data: UserDataReports, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await reportsService.postSatisfactionSurvey(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );
    return response;
  }
);

export const fetchJsReport = createAsyncThunk(
  'dashboard/fetchJsReport',
  async (data: JsReportData, { dispatch }) => {
    const { jwtToken, URL_REPORTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await reportsService.fetchJsReport(jwtToken, URL_REPORTS, data);
    return response;
  }
);

const reportsSlice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    // setSelectedDate: (state, action: PayloadAction<Date[]>) => {
    //   state.userData.timeStart = action.payload[0];
    //   state.userData.timeEnd = action.payload[1];
    // },
    // setDateOption: (state, action) => {
    //   state.userData.timeRange = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder
      // POST RECORDS DATA
      .addCase(postRecords.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postRecords.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.registersDetailsData = action.payload;
        console.log('registersDetailsData', state.registersDetailsData);
      })
      .addCase(postRecords.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST CONVERSATIONS DATA
      .addCase(postConversationsTrends.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postConversationsTrends.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.conversationsTrendsData = action.payload;
        console.log('conversationsTrendsData', state.conversationsTrendsData);
      })
      .addCase(postConversationsTrends.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST TIME DISTRIBUTION DATA
      .addCase(postTimeDistribution.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postTimeDistribution.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.timeDistributionData = action.payload;
        console.log('timeDistributionData', state.timeDistributionData);
      })
      .addCase(postTimeDistribution.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST USER PERFORMANCE DATA
      .addCase(postUserPerformance.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postUserPerformance.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.userPerformanceData = action.payload;
        console.log('userPerformanceData', state.userPerformanceData);
      })
      .addCase(postUserPerformance.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST RESPONSE TIMES DATA
      .addCase(postResponseTimes.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postResponseTimes.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.responseTimesData = action.payload;
        console.log('responseTimesData', state.responseTimesData);
      })
      .addCase(postResponseTimes.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST DETAILS BY USER GROUP DATA
      .addCase(postDetailsByUserGroup.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postDetailsByUserGroup.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.detailsByUserGroupData = action.payload;
        console.log('detailsByUserGroupData', state.detailsByUserGroupData);
      })
      .addCase(postDetailsByUserGroup.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST ENDINGS DATA
      .addCase(postEndings.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postEndings.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.endingsData = action.payload;
        console.log('endingsData', state.endingsData);
      })
      .addCase(postEndings.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST SUMMARY DATA
      .addCase(postSummary.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postSummary.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.summaryData = action.payload;
        console.log('summaryData', state.summaryData);
      })
      .addCase(postSummary.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST CHANNEL DETAIL DATA
      .addCase(postChannelDetail.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postChannelDetail.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.detailsPerChannelData = action.payload;
        console.log('detailsPerChannelData', state.detailsPerChannelData);
      })
      .addCase(postChannelDetail.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST STATISTICS DATA
      .addCase(postStatistics.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postStatistics.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.flowStatisticsData = action.payload;
        console.log('flowStatisticsData', state.flowStatisticsData);
      })
      .addCase(postStatistics.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // POST SATISFACTION SURVEY DATA
      .addCase(postSatisfactionSurvey.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(postSatisfactionSurvey.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        // state.data = action.payload;
        state.satisfactionSurveyData = action.payload;
        console.log('satisfactionSurveyData', state.satisfactionSurveyData);
      })
      .addCase(postSatisfactionSurvey.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      })
      // FETCH JS REPORT
      // .addCase(fetchJsReport.pending, (state) => {
      //   state.status.fetch = 'loading';
      // })
      .addCase(fetchJsReport.fulfilled, (state, action) => {
        state.jsReport = action.payload;

        // var reader = new FileReader();
        // reader.readAsDataURL(action.payload);
        // reader.onload = function (e) {
        //   console.log('e.target.result', e.target.result);
        // };

        // Convert Blob to Base64
        // blobToBase64(action.payload).then((base64) => {
        //   console.log('base64', base64);
        // });
      });
  },
});

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const { actions, reducer } = reportsSlice;
const reportsState = (state: RootState) => state.Reports;

export const reportsUserData = createSelector(
  reportsState,
  (reports) => reports.userData
);
export const selectJsReport = createSelector(
  reportsState,
  (reports) => reports.jsReport
);
export const reportsStatus = createSelector(
  reportsState,
  (reports) => reports.status.fetch
);
export const selectRegistersDetailsData = createSelector(
  reportsState,
  (reports) => reports.registersDetailsData
);

export const selectConversationsTrendsData = createSelector(
  reportsState,
  (reports) => reports.conversationsTrendsData
);

export const selectTimeDistributionData = createSelector(
  reportsState,
  (reports) => reports.timeDistributionData
);

export const selectUserPerformanceData = createSelector(
  reportsState,
  (reports) => reports.userPerformanceData
);

export const selectResponseTimesData = createSelector(
  reportsState,
  (reports) => reports.responseTimesData
);

export const selectDetailsByUserGroupData = createSelector(
  reportsState,
  (reports) => reports.detailsByUserGroupData
);

export const selectEndingsData = createSelector(
  reportsState,
  (reports) => reports.endingsData
);

export const selectSummaryData = createSelector(
  reportsState,
  (reports) => reports.summaryData
);

export const selectDetailsPerChannelData = createSelector(
  reportsState,
  (reports) => reports.detailsPerChannelData
);

export const selectFlowStatisticsData = createSelector(
  reportsState,
  (reports) => reports.flowStatisticsData
);

export const selectSatisfactionSurveyData = createSelector(
  reportsState,
  (reports) => reports.satisfactionSurveyData
);
//
// Actions
// export const { setSelectedDate, setDateOption } = reportsSlice.actions;
export default reportsSlice.reducer;
