import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
import { flowContext } from 'features/Views/Flow/context/FlowProvider';
// Types
import { IFlow } from '@trii/types/dist/Conversations/Flows';
// Components/ui
import { IconButton, Stack, Box, Fade, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

interface EndingItemProps {
  data: IFlow;
}

const EndingItem = ({ data }: EndingItemProps) => {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { openDelete, navigateToEdit } = useContext(flowContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          display={'flex'}
          bgcolor="dropdownBox"
          boxShadow={shadow.md}
          borderRadius={1}
          color="text.primary"
          sx={{ width: '100%', px: 1, py: 0.5 }}
        >
          <Box
            className="conversations-d-flex conversations-justify-content-between conversations-w-100"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              paddingLeft={"8px"}
            >
              <AccountTreeIcon
                sx={{
                  color: 'primary.main',
                  fontSize: '1.5rem',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 'auto',
                  py: 1,
                  pr: 4,
                  pl: 2,
                }}
              >
                <Box display={'flex'} alignItems="center">
                  <Typography fontWeight={fontWeight.normal} fontSize={fontSize.sm}>
                    {data.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              justifyContent="space-evenly"
            >
              <Stack direction="row">
                <IconButton
                  onClick={() => navigateToEdit(data.id)}
                  sx={{ color: 'primary.main' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'error.main' }}
                  onClick={() => openDelete(data)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default EndingItem;
