import { AuthType } from '@trii/types/dist/Common/API';

type AuthTypeList = {
  id: number;
  label: string;
};

const authTypeList: AuthTypeList[] = [
  {
    id: AuthType.NONE,
    label: 'editFlowsView.api.authType.none',
  },
  {
    id: AuthType.BASIC,
    label: 'editFlowsView.api.authType.basic.title',
  },
  {
    id: AuthType.BEARER,
    label: 'editFlowsView.api.authType.bearer.title',
  },
  {
    id: AuthType.APIKEY,
    label: 'editFlowsView.api.authType.apiKey.title',
  },
];

export default authTypeList;
