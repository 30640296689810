import React, { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
import { facebookContext } from 'features/Views/Facebook/context/FacebookProvider';
// Types
// Components/ui
import { IconButton, Stack, Box, Fade, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FacebookIcon from '@mui/icons-material/Facebook';

interface FacebookItemProps {
  data: any;
}

const FacebookItem = ({ data }: FacebookItemProps) => {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { openDelete, navigateToEdit } = useContext(facebookContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          display={'flex'}
          justifyContent="space-between"
          bgcolor="dropdownBox"
          boxShadow={shadow.md}
          borderRadius={1}
          color="text.primary"
          sx={{ width: '100%', px: 1, py: 0.5 }}
        >
          <Box
             display="flex"
             alignItems={"center"}
            paddingLeft={"8px"}

          >
            <FacebookIcon
              sx={{
                color: 'primary.main',
                fontSize: '1.5rem',
              }}
            />
            <Box
              className="mr-auto py-1 pr-4 pl-2"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box display={'flex'} alignItems="center">
                <Typography fontWeight={fontWeight.normal} fontSize={fontSize.sm}>
                  {data.name}
                </Typography>
              </Box>
            </Box>
          </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              justifyContent="space-evenly"
            >
              <Stack direction="row">
                <IconButton
                  onClick={() => navigateToEdit(data.id)}
                  sx={{ color: 'primary.main' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'error.main' }}
                  onClick={() => openDelete(data)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default FacebookItem;
