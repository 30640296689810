import React from 'react';
// Redux
import { useSelector } from 'react-redux';
import {
  selectGroupData,
  selectGroupStatus,
} from 'redux/features/groupSlice/groupSlice';
// Components/ui
import { Box } from '@mui/material';
import { GroupItem, GroupItemSkeleton } from './components';

export default function GroupContent() {
  const groupData = useSelector(selectGroupData);
  const groupStatus = useSelector(selectGroupStatus);
  const Group =
    groupStatus === 'succeeded' ? (
      groupData.map((group) => <GroupItem key={group.id} data={group} />)
    ) : (
      <GroupItemSkeleton />
    );

  return (
    <Box sx={{ maxHeight: '90%' }}>
      <Box
        sx={{
          py: 2,
          px: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          maxHeight: 'calc(90vh - 64px - 48px)',
          overflowY: 'auto',
        }}
      >
        {Group}
      </Box>
    </Box>
  );
}
