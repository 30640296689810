import { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Interface
import { NodeContactUpdateAction } from '@trii/types/dist/Conversations/Flows/Nodes';

const Action = () => {
  const { contactUpdateNode, setContactUpdateNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [action, setAction] = useState<NodeContactUpdateAction>(
    NodeContactUpdateAction.REPLACE
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.contactUpdate) {
      const contactUpdate = selectNode.data.contactUpdate;
      const defaultAction =
        contactUpdate.action && (contactUpdate.action as NodeContactUpdateAction);
      setAction(defaultAction);
    }
  }, [selectNode]);

  const handleChangeAction = (value: NodeContactUpdateAction) => {
    setAction(value);
    setContactUpdateNode({ ...contactUpdateNode, action: value });
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="action" shrink={true}>
          {t("editFlowsView.contactUpdate.action.title")}
        </InputLabel>
        <Select
          labelId="action"
          id="demo-simple-select"
          value={action}
          label={t("editFlowsView.contactUpdate.action.title")}
          onChange={(e) =>
            handleChangeAction(e.target.value as NodeContactUpdateAction)
          }
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          <MenuItem
            key={NodeContactUpdateAction.REPLACE}
            value={NodeContactUpdateAction.REPLACE}
          >
            {t("editFlowsView.contactUpdate.action.replace")}
          </MenuItem>
          <MenuItem
            key={NodeContactUpdateAction.ADD}
            value={NodeContactUpdateAction.ADD}
          >
            {t("editFlowsView.contactUpdate.action.add")}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default Action;
