import { useTranslation } from 'react-i18next';
// Types
import { FormItem } from '@trii/types/dist/Common/Channels';
// Redux
import {
  addMultipleOptionsOption,
  removeMultipleOptionsOption,
  addCheckboxOption,
  removeCheckboxOption,
  addDropdownOption,
  removeDropdownOption,
} from 'redux/features/formEditSlice/formEditSlice';
// Components/ui
import { Box } from '@mui/material';
import {
  BasicAnswerInput,
  LongAnswerInput,
  LinearScaleInput,
  MultipleOptionsInput,
} from './components';
import EmailIcon from '@mui/icons-material/Email';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import NumbersIcon from '@mui/icons-material/Numbers';

interface Props {
  questionData: FormItem;
}

const AnswerTypeInput = ({ questionData }: Props) => {
  const { t } = useTranslation();
  const { type, id } = questionData;

  return (
    <Box width={'100%'} pt={1} pb={4}>
      {type === 1 && (
        <BasicAnswerInput label={t('formEditView.designPanel.shortAnwserText')} />
      )}
      {type === 2 && <LongAnswerInput />}
      {type === 3 && (
        <MultipleOptionsInput
          questionId={id}
          addOption={addMultipleOptionsOption}
          removeOption={removeMultipleOptionsOption}
          optionsData={questionData.severalOptionList}
          icon={<CircleOutlinedIcon sx={{ fontSize: 20, color: 'primary.main' }} />}
        />
      )}
      {type === 4 && (
        <MultipleOptionsInput
          questionId={id}
          addOption={addCheckboxOption}
          removeOption={removeCheckboxOption}
          optionsData={questionData.checkboxList}
          icon={
            <CheckBoxOutlineBlankIcon sx={{ fontSize: 20, color: 'primary.main' }} />
          }
        />
      )}
      {type === 5 && (
        <MultipleOptionsInput
          questionId={id}
          addOption={addDropdownOption}
          removeOption={removeDropdownOption}
          optionsData={questionData.dropdownList}
          icon={<NumbersIcon sx={{ fontSize: 20, color: 'primary.main' }} />}
        />
      )}
      {type === 6 && (
        <LinearScaleInput
          questionId={id}
          linearScaleData={questionData.linearScale}
        />
      )}
      {type === 7 && (
        <BasicAnswerInput label={t('global.email')} icon={<EmailIcon />} />
      )}
      {type === 8 && (
        <BasicAnswerInput label={t('global.date')} icon={<DateRangeIcon />} />
      )}
      {type === 9 && (
        <BasicAnswerInput label={t('global.time')} icon={<AccessTimeIcon />} />
      )}
    </Box>
  );
};

export default AnswerTypeInput;
