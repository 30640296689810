import React, { useContext } from 'react';
import { emailContext } from '../../context/EmailProvider';
// Components/ui
import { CreatePanel, DeletePanel } from './components/index.js';

function CrudPanel() {
  const { crudPanelState } = useContext(emailContext);

  return (
    <>
      {crudPanelState === 'create' && <CreatePanel />}
      {crudPanelState === 'delete' && <DeletePanel />}
    </>
  );
}

export default CrudPanel;
