import { useContext, useState, useEffect } from 'react';
// Dispatch
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
// Utils
import { triggerSelector } from './utils';
//Components
import { FieldsCondition, ActionButtons } from './components';
// Interface
import { NodeStartTrigger } from '@trii/types/dist/Conversations/Flows/Nodes';
// Service
import {
  fetchChannels,
  fetchEndings,
  fetchSearchBy,
  fetchLabels,
} from 'redux/features/editFlowSlice/editFlowSlice';

const EditStartNode = () => {
  const { startNode, setStartNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [trigger, setTrigger] = useState<number>(NodeStartTrigger.NULL);
  const [onlyOnePerContact, setOnlyOnePerContact] = useState<boolean>(false);
  const [onlyOnePerContactAddress, setOnlyOnePerContactAddress] =
    useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchChannels());
    dispatch(fetchEndings());
    dispatch(fetchSearchBy('custom'));
    dispatch(fetchLabels());
  }, [dispatch]);

  useEffect(() => {
    if (selectNode) {
      setId(
        selectNode.data?.start
          ? selectNode.data.start.id
          : (Math.random().toString(36).substr(2, 9) as string)
      );
      setName(selectNode.data?.start ? selectNode.data.start.name : ('' as string));
      setTrigger(
        selectNode.data?.start ? selectNode.data.start.trigger : ('' as string)
      );
      setOnlyOnePerContact(
        selectNode.data?.start
          ? selectNode.data.start.onlyOnePerContact
          : (false as boolean)
      );
      setOnlyOnePerContactAddress(
        selectNode.data?.start
          ? selectNode.data.start.onlyOnePerContactAddress
          : (false as boolean)
      );
    }
  }, [selectNode]);

  useEffect(() => {
    setStartNode({
      ...startNode,
      id,
      name,
      trigger,
      onlyOnePerContact,
      onlyOnePerContactAddress,
    });
  }, [name, trigger, onlyOnePerContact, onlyOnePerContactAddress, id]);

  const handleSelectTrigger = (value: number) => {
    setTrigger(value);
    setStartNode({ ...startNode, filters: [] });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t("editFlowsView.name")}
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Divider sx={{ margin: "1rem 0rem" }} />
      <FormControl size="small" fullWidth>
        <InputLabel id="triggerSelector" shrink={true}>
          {t("editFlowsView.start.trigger.title")}
        </InputLabel>
        <Select
          label={t("editFlowsView.start.trigger.title")}
          id="triggerSelector"
          onChange={(e) => handleSelectTrigger(e.target.value as number)}
          value={trigger}
          sx={{
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
            '& legend': {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: theme => theme.palette.background.panel,
              },
            },
          }}
        >
          {triggerSelector.map((item) => (
            <MenuItem key={item.name} value={item.id}>
              {t(item.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider sx={{ margin: "1rem 0rem" }} />
      <FieldsCondition typeTrigger={trigger} />
      <Divider sx={{ margin: "1rem 0rem" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: "600", color: "text.primary" }}>
          {t("editFlowsView.start.otherConfigurations")}
        </Typography>
        <FormControlLabel
          slotProps={{
            typography: {
              variant: "caption",
              color: "text.primary"
            },
          }}
          control={
            <Checkbox
              checked={onlyOnePerContact}
              onChange={(e) => setOnlyOnePerContact(e.target.checked)}
            />
          }
          label={t("editFlowsView.start.executePerContact")}
        />
        <FormControlLabel
          slotProps={{
            typography: {
              variant: "caption",
              color: "text.primary"
            },
          }}
          control={
            <Checkbox
              checked={onlyOnePerContactAddress}
              onChange={(e) => setOnlyOnePerContactAddress(e.target.checked)}
            />
          }
          label={t("editFlowsView.start.executePerContactAddress")}
        />
      </Box>
      <ActionButtons />
    </Box>
  );
};

export default EditStartNode;
