// Types
import { IChannel } from '@trii/types/dist/Common/Channels';
// Redux
import {
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeSchedule,
  addPermission,
  removePermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  changeAutoResponseBusinessHours,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeAssignMethod,
} from 'redux/features/googleBusinessEditSlice/googleBusinessEditSlice';
//Components/ui
import { ChannelEditBodyContainer, ChannelPermissionsPanel } from 'components';
import { DetailsPanel, PanelsSkeleton } from './components';

interface Props {
  allFetched: boolean;
  data: IChannel;
}

const Body = ({ allFetched, data }: Props) => {
  const isLoading = data === null || !allFetched;

  return (
    <ChannelEditBodyContainer>
      {isLoading ? (
        <PanelsSkeleton />
      ) : (
        <>
          <DetailsPanel
            changeName={changeName}
            changeToken={changeToken}
            assignTo={data.assignTo}
            changeReassign={changeReassign}
            changeReassignGroup={changeReassignGroup}
            changeSchedule={changeSchedule}
            changeAutoResponseBusinessHours={changeAutoResponseBusinessHours}
            changeAutoResponseOutOfBusinessHours={
              changeAutoResponseOutOfBusinessHours
            }
            changeAutoResponseHoliday={changeAutoResponseHoliday}
            data={data.googleBusiness}
            id={data.id}
            name={data.name}
            changeAssignMethod={changeAssignMethod}
            assignMethod={data.assignMethod}
          />
          <ChannelPermissionsPanel
            addPermission={addPermission}
            removePermission={removePermission}
            changePermissionReadMessages={changePermissionReadMessages}
            changePermissionStartConversation={changePermissionStartConversation}
            data={data.permissions}
          />
        </>
      )}
    </ChannelEditBodyContainer>
  );
};

export default Body;
