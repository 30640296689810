import GoogleAccountCard from "./components/GoogleAccountCard/GoogleAccountCard";
import GoogleLogOutButton from "./components/GoogleLogOutButton/GoogleLogOutButton";
import { EmailConfigOutboundStatus, GoogleAccountInfo } from "@trii/types/dist/Common/Channels";

interface Props {
  outboundGoogleAccount: GoogleAccountInfo;
  outboundGoogleStatus: EmailConfigOutboundStatus;
}

const CloseSessionView = ({outboundGoogleAccount, outboundGoogleStatus}: Props) => {

    
  return (
    <>
        <GoogleAccountCard outboundGoogleAccount={outboundGoogleAccount} />
        <GoogleLogOutButton outboundGoogleStatus={outboundGoogleStatus}/>
    </>
  );
}

export default CloseSessionView