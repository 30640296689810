import { useContext } from 'react';
// @ts-ignore
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// @ts-ignore
import { selectedSchedule } from 'redux/features/scheduleSlice/scheduleSlice';
// @ts-ignore
import { ScheduleContext } from 'features/Views/Schedules/context/types';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const { t } = useTranslation();
  const { closeDeletePanel } = useContext<ScheduleContext>(InitScheduleContext);
  const schedule = useSelector(selectedSchedule);

  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Box display={'flex'} gap={1}>
        <Typography variant="subtitle2" color="text.primary">
          {t('scheduleView.deleteSchedule')}
        </Typography>
        <Typography variant="subtitle2" color="text.primary">{schedule.name}</Typography>
      </Box>
      <IconButton onClick={closeDeletePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;
