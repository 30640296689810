import React from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';
import { CopyClipboardButton } from './component';

interface Props {
  boxLabel: string;
}

const CopyBox = ({ boxLabel }: Props) => {
  const codeSnippet = `
    <script type="application/javascript">
      window.triiSettings =
      {
        token: 'b3da2d0a-475b-49fd-9fc0-a15a7d717262',
      };
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id; js.async = true;
        js.src = "https://webchat.trii.app/launch.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'trii-widget'));
    </script>
  `;

  return (
    <Box
      px={2}
      py={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      border={"1px solid"}
      borderColor={(theme) => theme.palette.divider}
      borderRadius={2}
    >
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignContent={"center"}
        >
          <Typography variant="subtitle2">{boxLabel}</Typography>
          <CopyClipboardButton />
        </Box>
      </Box>
      <Box
        px={3}
        py={5}
        border={"1px solid"}
        borderRadius={2}
        borderColor={(theme) => theme.palette.divider}
        //@ts-ignore
        bgcolor={(theme) => theme.palette.background.dropdownMenu}
      >
        <Typography variant="subtitle2" fontWeight={400}>
          {codeSnippet}
        </Typography>
      </Box>
    </Box>
  );
};

export default CopyBox;
