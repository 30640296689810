import { useContext } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// components/ui
import { Box, Typography } from '@mui/material';
// Components
import {
  EditApiNode,
  EditAssignToNode,
  EditConditionalNode,
  EditContactSearchNode,
  EditContactUpdateNode,
  EditConversationUpdateNode,
  EditEndNode,
  EditFillFormNode,
  EditFillTicketNode,
  EditRedirectNode,
  EditSendEmailNode,
  EditSqlQueryNode,
  EditStartNode,
  EditTextNode,
  EditTimeScheduleControlNode,
  EditTimeTimerNode,
  EditTimeWaitUntilNode,
  EditWaitResponseNode,
} from './components';
// Interfaces
import { NodeType } from '@trii/types/dist/Conversations/Flows';

const EditNode = () => {
  const { selectNode } = useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();

  return (
    <Box height={'100%'}>
      {selectNode && Number(selectNode.type) === NodeType.START && <EditStartNode />}
      {selectNode && Number(selectNode.type) === NodeType.MSG_TEXT && (
        <EditTextNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.MSG_WAIT_RESPONSE && (
        <EditWaitResponseNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.SQL_QUERY && (
        <EditSqlQueryNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.SEND_EMAIL && (
        <EditSendEmailNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.CONVERSATION_ASSIGN_TO && (
        <EditAssignToNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.FILL_FORM && (
        <EditFillFormNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.REDIRECT && (
        <EditRedirectNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.FILL_TICKET && (
        <EditFillTicketNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.CONTACT_SEARCH && (
        <EditContactSearchNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.CONTACT_UPDATE && (
        <EditContactUpdateNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.CONVERSATION_UPDATE && (
        <EditConversationUpdateNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.CONDITIONAL && (
        <EditConditionalNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.API && <EditApiNode />}
      {selectNode && Number(selectNode.type) === NodeType.TIME_SCHEDULE_CONTROL && (
        <EditTimeScheduleControlNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.TIME_TIMER && (
        <EditTimeTimerNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.TIME_WAIT_UNTIL && (
        <EditTimeWaitUntilNode />
      )}
      {selectNode && Number(selectNode.type) === NodeType.CONVERSATION_FINALIZE && (
        <EditEndNode />
      )}
      {!selectNode && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography
            variant="caption"
            color={(theme) => theme.palette.text.disabled}
            fontSize={(theme) => theme.typography.pxToRem(20)}
          >
            {t('editFlowsView.selectNode')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditNode;
