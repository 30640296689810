import { useState, useEffect, useContext } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Slice
import {
  selectFileUrl,
  fetchChargeFile,
  chargeFileStatus,
} from 'redux/features/editFlowSlice/editFlowSlice';
// Components/ui
import { TextField, IconButton, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// Component Emoji
import EmojiPicker from 'emoji-picker-react';
// Components
import { ImageModal } from './components';
// Icons
import { CompressOutlined, InsertEmoticon } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// Interface
import { NodeMsgTextMessageType } from '@trii/types/dist/Conversations/Flows/Nodes';

const ImageFormat = ({
  title,
  chargeLabel,
  fieldLabel,
}: {
  title: string;
  chargeLabel: string;
  fieldLabel: string;
}) => {
  const { textNode, setTextNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [caption, setCaption] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [chargeFile, setChargeFile] = useState<boolean>(false);
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const editStatus = useSelector(chargeFileStatus);
  const isLoading: boolean = editStatus === 'loading';
  const url = useSelector(selectFileUrl);

  useEffect(() => {
    if (selectNode) {
      const msgText =
        selectNode.data.msgText && selectNode.data.msgText
          ? selectNode.data.msgText
          : ({} as any);
      const message = msgText.message ? msgText.message : ({} as any);
      if (
        message.type === NodeMsgTextMessageType.IMAGE ||
        message.type === NodeMsgTextMessageType.VIDEO
      ) {
        const defaultCaption = message.caption ? message.caption : ('' as string);
        const defaultFilename = message.filename ? message.filename : ('' as string);
        const defaultFileUrl = message.fileUrl ? message.fileUrl : ('' as string);
        setCaption(defaultCaption);
        setFileUrl(defaultFileUrl);
        setFilename(defaultFilename);
      }
    }
  }, [selectNode]);

  useEffect(() => {
    if (textNode.message.fileUrl !== fileUrl) {
      setTextNode({ ...textNode, message: { ...textNode.message, fileUrl } });
    }
    if (textNode.message.filename !== filename) {
      setTextNode({ ...textNode, message: { ...textNode.message, filename } });
    }
  }, [filename, fileUrl]);

  useEffect(() => {
    if (chargeFile && url && url !== fileUrl) {
      setFileUrl(url);
      setChargeFile(false);
    }
  }, [url]);

  const handleChange = (value: string) => {
    setCaption(value);
    setTextNode({ ...textNode, message: { ...textNode.message, caption: value } });
  };

  const handleCharge = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const newFormData = new FormData();
      newFormData.append('photo', file, fileName);
      setFilename(fileName);
      await dispatch(fetchChargeFile(newFormData));
      setChargeFile(true);
    }
  };

  return (
    <>
      {showEmojiPicker && (
        <Box
          sx={{
            display: 'block',
            position: 'absolute',
            bottom: '73%',
            right: '0',
            zIndex: 1,
          }}
        >
          <EmojiPicker height={300} onEmojiClick={(e) => console.log(e)} />
        </Box>
      )}
      <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
        {title}
      </Typography>
      <Box
        display="flex"
        sx={{ alignItems: 'center', maxHeight: '4rem', height: '4rem' }}
      >
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          size="small"
          color="success"
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
            height: 'max-content',
          }}
          component="label"
        >
          {chargeLabel}
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => handleCharge(e)}
          />
        </LoadingButton>
        {fileUrl && <ImageModal imageUrl={fileUrl} altText={filename} />}
      </Box>
      <TextField
        multiline
        maxRows={5}
        fullWidth
        size="small"
        variant="outlined"
        sx={{
          alignItems: 'center',
          marginTop: 1,
        }}
        value={caption}
        label={fieldLabel}
        onChange={(e) => handleChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton
              size="small"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              sx= {{
                color: 'text.disabled'            
              }}
            >
              <InsertEmoticon />
            </IconButton>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default ImageFormat;
