import { useEffect, useState } from 'react';
import useSAS from 'hooks/useSAS';
// Components/ui
import { Avatar, useTheme } from '@mui/material';

const SASUserAvatar = ({ imageUrl }: { imageUrl: string }) => {
  const theme = useTheme();

  const [imageURLWithAccess, setImageURLWithAccess] = useState('');

  const URLHandler = useSAS();

  useEffect(() => {
    if (imageUrl) {
      URLHandler.getURLWithAccessToken(imageUrl).then((url) => {
        setImageURLWithAccess(url);
      });
    }
  }, []);

  return (
    <Avatar
      src={imageURLWithAccess}
      sx={{
        color: theme.palette.text.disabled,
      }}
    />
  );
};

export default SASUserAvatar;
