import { useEffect, useState } from "react";
// Translations
import { useTranslation } from "react-i18next";
// Router
import { useNavigate } from "react-router-dom";
// Components/ui
import { Box, Checkbox, FormControlLabel } from "@mui/material";
// Components
import { Card } from "./components";

const LOCAL_STORAGE_KEY = 'keepSelection';
const LOCAL_STORAGE_KEY_STATUS = 'keepSelectionStatus';

const DashboardSelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [keepSelection, setKeepSelection] = useState<boolean>(false);

  const handleNavigate = (action: string) => {
    if (keepSelection) {
      localStorage.setItem(LOCAL_STORAGE_KEY, action);
      localStorage.setItem(LOCAL_STORAGE_KEY_STATUS, 'true');
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      localStorage.removeItem(LOCAL_STORAGE_KEY_STATUS);
    }
    navigate(`/a/conversations/Dashboard/${action}`);
  }

  const handleKeepSelection = () => {
    setKeepSelection(!keepSelection);
  }

  useEffect(() => {
    const keepSelection = localStorage.getItem(LOCAL_STORAGE_KEY);
    const keepSelectionStatus = localStorage.getItem(LOCAL_STORAGE_KEY_STATUS);
    if (keepSelectionStatus) {
      setKeepSelection(true);
    } else {
      setKeepSelection(false);
    }
    if (keepSelection) {
      navigate(`/a/conversations/Dashboard/${keepSelection}`);
    }
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 20%)',
          gridTemplateRows: 'repeat(2, 50%)',
          placeItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          gridGap: '1rem',
          height: '75%',
          width: '100%',
        }}
      >
        <Card title={t('dashboard.select.general')} handleNavigate={() => handleNavigate('general')} />
        <Card title={t('dashboard.select.messages')} handleNavigate={() => handleNavigate('messages')} />
        <Card title={t('dashboard.select.forms')} handleNavigate={() => handleNavigate('forms')} />
        <Card title={t('dashboard.select.channels')} handleNavigate={() => handleNavigate('channels')} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '25%',
          width: '100%',
          backgroundColor: 'transparent'
        }}
      >
        <FormControlLabel
          label={t('dashboard.keepSelection')}
          control={<Checkbox checked={keepSelection} size="small" />}
          sx={{
            color: theme => theme.palette.text.primary,
            fontWeight: 'bold',
          }}
          onChange={handleKeepSelection}
        />
      </Box>
    </Box>
  )
}

export default DashboardSelector;