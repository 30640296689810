import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ReactNode,
  useContext,
  forwardRef,
  RefObject,
  useState,
  useEffect,
} from 'react';
// Context
import { ActivitiesCardContext } from '../../../../context/ActivitiesCardContext';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
// Types
import { TabName } from '../../../../context/types/TabName';
import { IContact } from '@trii/types/dist/Contacts';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchFiles,
  fetchHistoryLogs,
  fetchNotes,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

type SectionCardProps = {
  children: ReactNode;
  title: string;
  defaultExpanded?: boolean;
  onClick: () => void;
  tabName: TabName;
  contactInfo?: IContact;
};

const SectionCard = forwardRef(
  (
    { children, title, defaultExpanded = false, onClick, tabName }: SectionCardProps,
    ref: RefObject<HTMLElement>
  ) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { tabsShowed, setTabsShowed } = useContext(ActivitiesCardContext);

    const { contactInfo, getConversationsOfContact } =
      useContext(conversationsContext);

    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);

    const conversationSelected = useSelector(selectConversationSelected);

    const handleClick = (event: React.SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        event.stopPropagation();
        onClick();

        // Fetch data when any panel is SELECTED (expanded)
        // Similar switch statement as in the SectionCard.tsx component
        switch (tabName) {
          case 'contactConversation':
            getConversationsOfContact({
              contactId,
              status: 'active',
            });
            break;
          case 'contactNotes':
            dispatch(fetchNotes(contactId));
            break;
          case 'contactFiles':
            dispatch(fetchFiles(contactId));
            break;
          case 'contactHistory':
            dispatch(fetchHistoryLogs(contactId));
            break;
          case 'contactEvent':
            dispatch(fetchActivities(conversationSelected?.contactInfo?.id));
            break;
        }
      }

      if (tabsShowed.includes(tabName)) {
        const filterTabs = tabsShowed.filter((tab) => tab !== tabName);
        setTabsShowed(filterTabs);
      } else {
        setTabsShowed([...tabsShowed, tabName]);
      }
    };

    useEffect(() => {
      if (defaultExpanded) {
        setTabsShowed([...tabsShowed, tabName]);
      }
      return () => {
        setTabsShowed([]);
      };
    }, []);

    //@ts-ignore
    const isBusiness = contactInfo?.isBusiness;
    const contactId = contactInfo?.id;

    const handleNavigateToContacts = (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const url = isBusiness
        ? `/a/contacts/business/${contactId}`
        : `/a/contacts/contacts/${contactId}`;
      if (event.button === 1) {
        // Middle mouse button
        window.open(url, '_blank');
      } else if (event.button === 0) {
        // Left mouse button
        navigate(url);
      }
    };

    return (
      <Accordion
        defaultExpanded={defaultExpanded}
        onChange={(event, expanded) => handleClick(event, expanded)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: (theme) => theme.shadows[3],
        }}
        expanded={tabsShowed.includes(tabName)}
      >
        <AccordionSummary
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              margin: '0 !important',
              justifyContent: 'space-between',
            },
            '&.Mui-expanded': {
              minHeight: '3rem',
            },
          }}
        >
          <Typography
            fontWeight={600}
            fontSize={16}
            color="text.primary"
            sx={{
              userSelect: 'none',
            }}
          >
            {title}
          </Typography>
          {tabName === 'contactData' && isHovered && (
            <IconButton size="small" onMouseDown={handleNavigateToContacts}>
              <Tooltip title={t('conversations.sidebar.seeInContacts')}>
                <EastIcon />
              </Tooltip>
            </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails ref={ref}>{children}</AccordionDetails>
      </Accordion>
    );
  }
);

export default SectionCard;
