import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { mercadoLibreContext } from 'features/Views/MercadoLibre/context/MercadoLibreProvider';
// Components/ui
import { NavLink } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { MercadoLibreButton } from './components';

const ListHeader = () => {
  const { crudPanelState, openCreate } = useContext(mercadoLibreContext);
  const { t } = useTranslation();

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/mercadoLibre">
      <Typography color="secondary">{t('mercadoLibreView.mercadoLibre')}</Typography>
    </NavLink>,
  ];

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
      gap={'1rem'}
    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <MercadoLibreButton />
    </Box>
  );
};

export default ListHeader;
