import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { emailContext } from 'features/Views/Email/context/EmailProvider';
// Components/ui
import { NavLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const ListHeader = () => {
  const { crudPanelState, openCreate } = useContext(emailContext);
  const { t } = useTranslation();
  const disabled = crudPanelState === 'create';

  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary" >{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/emails">
      <Typography color="secondary">{t('emailsView.emails')}</Typography>
    </NavLink>,
  ];

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
       // className="m-0"
       gap={'1rem'}
    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Button
        sx={{ alignSelf: 'center', backgroundColor: theme => theme.palette.primary.main }}
        variant="contained"
        size="small"
        onClick={openCreate}
        disabled={disabled}
      >
        {t('emailsView.addEmail')}
      </Button>
    </Box>
  );
};

export default ListHeader;
