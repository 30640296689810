import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  deleteConversationById,
  selectConversationSelected,
  selectConversations,
  selectConversationsFetchStatus,
  setConversationSelected,
  updateConversation,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import { Box, CircularProgress, SpeedDial } from '@mui/material';
// Icons
import { ChatBubbleOutline } from '@mui/icons-material';
import { ConversationList, Filter, OrderBy } from './components';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
// DB
import { dbWorker } from 'db/db';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
const ExternalChat = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    expandedExternal,
    expandedInternal,
    setOpenModalList,
    setModalTitle,
    handleSelectConversation,
    setConversationId,
  } = useContext(conversationsContext);
  const { subscribeEvent, socketConnection } = useContext(socketContext);
  const { endFileSelectorMode } = useContext(messagesContext);

  const conversationSelected = useSelector(selectConversationSelected);
  const fetchStatus = useSelector(selectConversationsFetchStatus);
  const user = useSelector(selectUser);

  const conversationSelectedIdRef = useRef(conversationSelected?.id);

  const isLoading = fetchStatus === 'loading';

  const [showSpeedDial, setShowSpeedDial] = useState<boolean>(false);

  const clientConversations = useSelector(selectConversations);

  // Order
  const [orderingByLastMessageDate, setOrderingByLastMessageDate] =
    useState<boolean>(true);
  const [orderingByStartConversationDate, setOrderingByStartConversationDate] =
    useState<boolean>(false);
  // Order -> type
  const [isLastMessageAscending, setIsLastMessageAscending] =
    useState<boolean>(false);
  const [isStartConversationAscending, setIsStartConversationAscending] =
    useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModalList(true);
    setModalTitle(t('conversations.createConversation.title'));
  };

  const shortConversations = useMemo(() => {
    let sortedConversations = [...clientConversations];

    if (orderingByLastMessageDate) {
      sortedConversations.sort((a, b) => {
        const aDate = a.updatedAt ? moment(a.updatedAt) : moment();
        const bDate = b.updatedAt ? moment(b.updatedAt) : moment();
        return isLastMessageAscending ? aDate.diff(bDate) : bDate.diff(aDate);
      });
    }

    if (orderingByStartConversationDate) {
      sortedConversations.sort((a, b) => {
        const aDate = a.createdAt ? moment(a.createdAt) : moment();
        const bDate = b.createdAt ? moment(b.createdAt) : moment();
        return isStartConversationAscending ? aDate.diff(bDate) : bDate.diff(aDate);
      });
    }

    sortedConversations.sort((a, b) => {
      if (a.pinned && !b.pinned) {
        return -1;
      }
      if (!a.pinned && b.pinned) {
        return 1;
      }
      return 0;
    });

    return sortedConversations;
  }, [
    clientConversations,
    user.uid,
    isLastMessageAscending,
    isStartConversationAscending,
    orderingByLastMessageDate,
    orderingByStartConversationDate,
  ]);

  const handleOrderByLastMessage = () => {
    setIsStartConversationAscending(false);
    setOrderingByLastMessageDate(true);
    setOrderingByStartConversationDate(false);

    if (orderingByLastMessageDate) {
      setIsLastMessageAscending(!isLastMessageAscending);
      localStorage.setItem(
        'isLastMessageAscending',
        JSON.stringify(!isLastMessageAscending)
      );
    }

    localStorage.setItem('isStartConversationAscending', JSON.stringify(false));
    localStorage.setItem('orderingByLastMessageDate', JSON.stringify(true));
    localStorage.setItem('orderingByStartConversationDate', JSON.stringify(false));
  };

  const handleOrderByStartConversation = () => {
    setOrderingByLastMessageDate(false);
    setOrderingByStartConversationDate(true);
    setIsLastMessageAscending(false);

    if (orderingByStartConversationDate) {
      setIsStartConversationAscending(!isStartConversationAscending);
      localStorage.setItem(
        'isStartConversationAscending',
        JSON.stringify(!isStartConversationAscending)
      );
    }

    localStorage.setItem('isLastMessageAscending', JSON.stringify(false));
    localStorage.setItem('orderingByLastMessageDate', JSON.stringify(false));
    localStorage.setItem('orderingByStartConversationDate', JSON.stringify(true));
  };

  useEffect(() => {
    conversationSelectedIdRef.current = conversationSelected?.id;
  }, [conversationSelected?.id]);

  const exitConversation = (
    conversationId: string,
    conversationSelectedId: string
  ) => {
    if (conversationSelectedId === conversationId) {
      dispatch(setConversationSelected(null));
      setConversationId('');
    }
  };

  function selectConversationHandler(
    conversation: IConversation,
    incognito: boolean
  ) {
    if (conversationSelectedIdRef.current !== conversation.id) {
      endFileSelectorMode();
    }

    handleSelectConversation(conversation, incognito);
  }

  useEffect(() => {
    const handleDocumentKeyDown = (event: KeyboardEvent) => {
      const focusedElement = document.activeElement as HTMLElement;

      if (
        !(
          focusedElement?.tagName === 'INPUT' ||
          focusedElement?.tagName === 'TEXTAREA' ||
          focusedElement?.tagName === 'SELECT' ||
          focusedElement?.isContentEditable
        ) &&
        (event.key === 'N' || event.key === 'n')
      ) {
        event.preventDefault();
        handleOpenModal();
      }
    };

    document.addEventListener('keydown', handleDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  // Set conversations order
  useEffect(() => {
    const storedIsLastMessageAscending = localStorage.getItem(
      'isLastMessageAscending'
    );
    const storedIsStartConversationAscending = localStorage.getItem(
      'isStartConversationAscending'
    );
    const storedOrderingByLastMessageDate = localStorage.getItem(
      'orderingByLastMessageDate'
    );
    const storedOrderingByStartConversationDate = localStorage.getItem(
      'orderingByStartConversationDate'
    );

    if (storedIsLastMessageAscending) {
      setIsLastMessageAscending(JSON.parse(storedIsLastMessageAscending));
    }
    if (storedIsStartConversationAscending) {
      setIsStartConversationAscending(
        JSON.parse(storedIsStartConversationAscending)
      );
    }
    if (storedOrderingByLastMessageDate) {
      setOrderingByLastMessageDate(storedOrderingByLastMessageDate === 'true');
    }
    if (storedOrderingByStartConversationDate) {
      setOrderingByStartConversationDate(
        storedOrderingByStartConversationDate === 'true'
      );
    }
  }, []);

  // Subscribe to events
  useEffect(() => {
    if (socketConnection) {
      subscribeEvent('conversation_finalize', async (data: IConversation) => {
        exitConversation(data.id, conversationSelectedIdRef.current);
        dispatch(deleteConversationById(data.id));

        dbWorker.postMessage({
          action: 'deleteConversation',
          data: data.id,
        });

        dbWorker.postMessage({
          action: 'deleteMessagesByContactId',
          data: data.contactInfo.id,
        });
      });

      subscribeEvent('conversation_update', async (data) => {
        dispatch(updateConversation(data.id));

        dbWorker.postMessage({
          action: 'updateConversation',
          data: data,
        });
      });
      subscribeEvent('conversation_new', async (data) => {
        dispatch(updateConversation(data.id));

        dbWorker.postMessage({
          action: 'setConversation',
          data: data,
        });
      });
    }
  }, [socketConnection]);

  // Show speed dial
  useEffect(() => {
    if (expandedExternal) {
      const time = setTimeout(() => {
        setShowSpeedDial(expandedExternal);
      }, 1500);
      return () => clearTimeout(time);
    } else {
      setShowSpeedDial(false);
    }
  }, [expandedExternal]);

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        maxHeight: `${
          !expandedExternal
            ? '3rem'
            : `calc(100% - ${!expandedInternal ? '3rem' : '20rem'})`
        }`,
        height: '100%',
        transition: 'max-height 0.5s ease-in-out',
        position: 'relative',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        sx={{
          maxHeight: '3rem',
          height: '100%',
        }}
        alignItems="center"
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box>
          <Filter />
        </Box>
        {isLoading && <CircularProgress size={20} />}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
          }}
        >
          <Box>
            <OrderBy
              orderingByLastMessageDate={orderingByLastMessageDate}
              orderingByStartConversationDate={orderingByStartConversationDate}
              handleOrderByLastMessage={handleOrderByLastMessage}
              handleOrderByStartConversation={handleOrderByStartConversation}
              isLastMessageAscending={isLastMessageAscending}
              isStartConversationAscending={isStartConversationAscending}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: expandedExternal ? '100%' : '0',
        }}
      >
        <ConversationList
          shortConversations={shortConversations}
          handleSelectConversation={selectConversationHandler}
          clientConversations={clientConversations}
        />
      </Box>
      <SpeedDial
        ariaLabel="SpeedDial Create Chat"
        sx={{
          display: showSpeedDial ? 'flex' : 'none',
          position: 'absolute',
          bottom: '-2rem',
          right: '2rem',
          '-webkit-animation': 'zoomin 0.3s ease-in-out',
          animation: 'zoomin 0.3s ease-in-out',
          '@keyframes zoomin': {
            '0%': {
              transform: 'scale(0)',
            },
            '50%': {
              transform: 'scale(1.1)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
        FabProps={{
          size: 'small',
        }}
        onClick={handleOpenModal}
        icon={<ChatBubbleOutline fontSize="small" />}
      />
    </Box>
  );
};

export default ExternalChat;
