import { useEffect, useState, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// Slice
import {
  selectChannels,
  selectChannelsFetchStatus,
} from 'redux/features/conversationsSlice/conversationsSlice';
// Components/ui
import { Box } from '@mui/material';
// Components
import { Card, NonCrossOriginCard } from './components';
// Types
import { ChannelType, IChannelInfo } from '@trii/types/dist/Common/Channels';
import { IContactAddress } from '@trii/types/dist/Contacts';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

interface ChannelCardProps {
  channelSelected: ChannelType;
  list: IContactAddress[];
}

const ChannelCard = ({ channelSelected, list }: ChannelCardProps) => {
  const { t } = useTranslation();

  const {
    originSelected,
    setOriginSelected,
    destinationSelected,
    setDestinationSelected,
  } = useContext(conversationsContext);

  const newChannels = useSelector(selectChannels);
  const channelsFetchStatus = useSelector(selectChannelsFetchStatus);

  const [channels, setChannels] = useState<IChannelInfo[]>([]);

  const isLoading = channelsFetchStatus === 'loading';
  const crossOriginDestinyChannel =
    channelSelected !== ChannelType.FACEBOOK &&
    channelSelected !== ChannelType.INSTAGRAM &&
    channelSelected !== ChannelType.WEBCHAT;

  const handleSelectOrigin = (originId: string[]) => {
    setOriginSelected(originId[0]);
  };

  const handleSelectDestination = (destination: string[]) => {
    if (destination instanceof Array) {
      setDestinationSelected(destination);
    } else {
      setDestinationSelected([destination]);
    }
  };

  useEffect(() => {
    if (newChannels) {
      setChannels(newChannels);
    }
  }, [newChannels]);

  useEffect(() => {
    setOriginSelected(null);
    setDestinationSelected(null);
    setChannels([]);
  }, [channelSelected, list]);

  return (
    <Box
      sx={{
        ...{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '1rem',
        },
        width: '100%',
        height: '100%',
      }}
    >
      {crossOriginDestinyChannel ? (
        <>
          <Box width="50%" height="100%">
            <Card
              title={t('conversations.createConversation.origin')}
              isOrigin={true}
              channelSelected={channelSelected}
              onSelect={handleSelectOrigin}
              selected={[originSelected]}
              list={channels}
              isLoading={isLoading}
            />
          </Box>
          <Box width="50%" height="100%">
            <Card
              title={t('conversations.createConversation.destination')}
              isOrigin={false}
              channelSelected={channelSelected}
              list={list}
              onSelect={handleSelectDestination}
              selected={destinationSelected}
              isLoading={false}
            />
          </Box>
        </>
      ) : (
        <NonCrossOriginCard
          list={list}
          channelSelectedType={channelSelected}
          onSelect={handleSelectDestination}
          selected={destinationSelected}
          isLoading={false}
        />
      )}
    </Box>
  );
};

export default ChannelCard;
