// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  LinearProgress,
  Card as MuiCard,
  styled,
  Typography,
} from '@mui/material';
// Types
import { ChannelType } from '@trii/types/dist/Common/Channels';
// Components
import { NonCrossOriginListSelector } from './components';
import { IContactAddress } from '@trii/types/dist/Contacts';

const StyledCard = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '.5rem',
  flexDirection: 'column',
}));

const LoadingBarContainer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
});

const HeaderBox = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '3rem',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  padding: '.5rem',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
});

const StyledTitleContainer = styled(Typography)({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
});

const StyledTypography = styled(Typography)({
  color: 'text.secondary',
  fontSize: '.75rem',
  width: '100%',
});

type CardProps = {
  list: IContactAddress[];
  onSelect: (selector: string[]) => void;
  selected: string[];
  isLoading: boolean;
  channelSelectedType:
    | ChannelType.FACEBOOK
    | ChannelType.INSTAGRAM
    | ChannelType.WEBCHAT;
};

const NonCrossOriginCard = ({
  list,
  selected,
  onSelect,
  isLoading,
  channelSelectedType,
}: CardProps) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <HeaderBox>
        {isLoading && (
          <LoadingBarContainer>
            <LinearProgress sx={{ borderRadius: 1 }} />
          </LoadingBarContainer>
        )}
        <StyledTitleContainer>
          <Typography variant="body2">
            {t('conversations.createConversation.destination')}
          </Typography>
        </StyledTitleContainer>
        <StyledTypography variant="body2">
          {t('conversations.createConversation.userChannel')}
        </StyledTypography>
      </HeaderBox>
      <NonCrossOriginListSelector
        list={list}
        selectedId={selected?.length > 0 ? selected[0] : ''}
        onSelect={onSelect}
        channelSelectedType={channelSelectedType}
      />
    </StyledCard>
  );
};

export default NonCrossOriginCard;
