import React, { useEffect, useState, useContext } from 'react';
// Components/ui
import { Box, Typography, IconButton, Button, Fade, CircularProgress } from '@mui/material';
// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Hook
import useSAS from 'hooks/useSAS';

interface FileSectionProps {
  name: string;
  fileId: string;
  url: string;
  onDelete: (id: string) => void;
}

const FileSection = ({ name, fileId, url, onDelete }: FileSectionProps) => {
  const { filesToUpload } = useContext(conversationsContext);
  const [isLoading, setIsLoading] = useState(false);

  const { getURLWithAccessToken } = useSAS();

  const handleDownloadFile = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  
    const secureURL = await getURLWithAccessToken(url);
  
    const link = document.createElement('a');
    link.download = name;
    link.href = secureURL;
    link.target = '_blank';  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDeleteFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(fileId);
  };

  useEffect(() => {
    const filesIds = filesToUpload.map((file) => file.id);
    if (filesIds.includes(fileId)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [filesToUpload, fileId]);

  return (
    <Fade in={true} timeout={1000}>
      <Button
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#2d3748' : '#e2e8f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '2.5rem',
          width: '-webkit-fill-available',
          transition: 'height 0.5s ease-in-out',
        }}
        disabled={isLoading}
        onClick={handleDownloadFile}
      >
        <Typography
          sx={{
            userSelect: 'none',
            fontSize: 14,
            color: (theme) =>
              theme.palette.mode === 'dark' ? '#e2e8f0' : '#475569',
            overflowY: 'auto',
            maxHeight: '100%',
            textTransform: 'none',
            textAlign: 'justify',
          }}
          variant="body1"
          noWrap
        >
          {name}
        </Typography>
        {isLoading ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            width={20}
          >
            <CircularProgress size={19} />
          </Box>
        ) : (
          <IconButton size="small" onClick={handleDeleteFile}>
            <DeleteOutlineOutlinedIcon
              color="error"
              sx={{
                fontSize: 19,
              }}
            />
          </IconButton>
        )}
      </Button>
    </Fade>
  );
};

export default FileSection;
