import React, { useContext, useState, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import { TextField, Box } from '@mui/material';
// Components
import { ActionButtons } from './components';

const EditEndNode = () => {
  const { endNode, setEndNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data.conversationFinalize) {
      const conversationFinalize =
        selectNode && selectNode?.data && selectNode?.data?.conversationFinalize;
      const defaultId = conversationFinalize.id
        ? conversationFinalize?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = conversationFinalize.name
        ? conversationFinalize?.name
        : ('' as string);
      setId(defaultId);
      setName(defaultName);
    }
  }, [selectNode]);

  const handleChangeName = (name: string) => {
    setName(name);
    setEndNode({
      ...endNode,
      id,
      name,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <ActionButtons />
    </Box>
  );
};

export default EditEndNode;
