import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import { ChannelPanel } from 'components';
import { CopyBox } from './components';

const InstalationPanel = () => {
  const { t } = useTranslation();
  return (
    <ChannelPanel title={t('formEditView.installationPanel.name')}>
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h5" marginBottom={7}>
            {t('formEditView.installationPanel.title')}
          </Typography>
          <Typography variant="subtitle1">
            {t('webChatEditView.installationPanel.description')}
          </Typography>
        </Box>
        <CopyBox boxLabel={'Script:'} />
      </Box>
    </ChannelPanel>
  );
};

export default InstalationPanel;
