import { fieldInitialDef } from 'utils/fieldInitialDef';
import { NewWhatsApp } from 'redux/features/whatsAppSlice/types/NewWhatsApp';

const createWhatsAppInitialState = {
  input: {
    nameField: fieldInitialDef,
    descriptionField: fieldInitialDef,
  },
  actions: {
    resetAll: () => {},
    getNewWhatsApp: () => ({} as NewWhatsApp),
  },
};
export default createWhatsAppInitialState;
