import React, { useContext, useState, useEffect } from 'react';
// Dispatch
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Context
import { InitEditFlowsContext } from '../../../../../../../../context/EditFlowsContext';
import { EditFlowsContext } from '../../../../../../../../context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Service
import {
  fetchGroups,
  fetchSearchBy,
} from '../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';
// components/ui
import { TextField, Divider, FormControlLabel, Checkbox, Box } from '@mui/material';
// Components
import {
  ActionButtons,
  AssociateMessages,
  Category,
  Contact,
  Description,
  Groups,
  Notifications,
  Priority,
  SaveAs,
  Status,
} from './components';

const EditFillTicketNode = () => {
  const { fillTicketNode, setFillTicketNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [enableStatics, setEnableStatics] = useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchSearchBy('isSearchKey'));
  }, [dispatch]);

  useEffect(() => {
    if (selectNode && selectNode.data.fillTicket) {
      const fillTicket =
        selectNode && selectNode?.data && selectNode?.data?.fillTicket;
      const defaultId = fillTicket.id
        ? fillTicket?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = fillTicket.name ? fillTicket?.name : ('' as string);
      const defaultEnableStatics = fillTicket.enableStatics
        ? fillTicket?.enableStatics
        : (false as boolean);
      setId(defaultId);
      setName(defaultName);
      setEnableStatics(defaultEnableStatics);
    }
  }, [selectNode]);

  const handleChangeName = (name: string) => {
    setName(name);
    setFillTicketNode({
      ...fillTicketNode,
      id,
      name,
    });
  };

  const handleChangeEnableStatics = (enableStatics: boolean) => {
    setEnableStatics(enableStatics);
    setFillTicketNode({
      ...fillTicketNode,
      enableStatics,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStatics}
            onChange={(e) => handleChangeEnableStatics(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.enableStatics')}
      />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Contact />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Groups />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Status />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Category />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Priority />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Notifications />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Description />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <SaveAs />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <AssociateMessages />
      <ActionButtons />
    </Box>
  );
};

export default EditFillTicketNode;
