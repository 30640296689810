import { useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Components/ui
import { TagList, CollapseAnimation } from './components';
import { Box } from '@mui/material';

const SidebarSelector = () => {
  const { showService } = useContext<EditFlowsContext>(InitEditFlowsContext);

  return (
    <CollapseAnimation childrenType="service" openPanel={showService}>
      <Box display="flex" flexDirection="column" p={2} height="calc(100% - 2rem)">
        <TagList />
      </Box>
    </CollapseAnimation>
  );
};

export default SidebarSelector;
