// Components/ui
import { GroupSelectorFilter, Title, UserSelectorFilter } from './components';
import { Box } from '@mui/material';
import FilterChannels from './components/FilterChannels/FilterChannels';
import Period from './components/Period';
import { ContactNameFilter } from './components';
const AdvancedFilter = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 225px)',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <Title />
      <Period />
      <FilterChannels />
      <ContactNameFilter />
      <UserSelectorFilter />
      <GroupSelectorFilter />
    </Box>
  );
};

export default AdvancedFilter;
