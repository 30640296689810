import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// Router
import { useParams, useSearchParams } from 'react-router-dom';
// Context
import FilePreviewerProvider from '../context/FilePreviewerProvider/FilePreviewerProvider';
import { conversationsContext } from '../context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from '../context/MessagesProvider/MessagesProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Components/ui
import Box from '@mui/material/Box';
import {
  Conversation,
  SidebarContactList,
  ChatDetailsSidebar,
  SelectConversationScreen,
  FilePreviewer,
  ImageViewer,
  ModalList,
  SearchMessage,
} from './components';
import { Slide } from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectConversationSelected,
  setConversationSelected,
  setSelectedContactInfo,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
// Hooks
import useNotificationSound from '../hooks/useNotificationSound';
import { useOpenCallPanelWidth } from 'features/Layout/MainLayout/utils/useOpenCallPanelWidth';
import { IConversation } from '@trii/types/dist/Conversations';
import { setIsEditingName } from 'redux/features/contactInfoSlice/contactInfoSlice';
import { getMessages } from 'redux/features/messagesSlice/messagesSlice';
import { GetMessagesData } from 'redux/features/messagesSlice/types/GetMessagesData';
const ConversationsContainer = () => {
  const dispatch = useAppDispatch();

  const {
    conversations,
    handleSelectConversation,
    setContactInfo,
    loadContactDetails,
    conversationNotFound,
    setConversationNotFound,
  } = useContext(conversationsContext);
  const { isSearching, fileSelectorMode } = useContext(messagesContext);
  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  const conversationSelected = useSelector(selectConversationSelected);

  const notificationSound = useNotificationSound();
  const [searchParams] = useSearchParams();

  const containerRef = useRef(null);

  const contactId = searchParams.get('contactId');
  const conversationId = searchParams.get('conversationId');

  const handleKeyPress = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dispatch(setConversationSelected(null));
        dispatch(setSelectedContactInfo(null));

        setContactInfo(null);
      }
    },
    [conversationSelected]
  );

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    const originalFavicon = favicon?.getAttribute('href');
    const newFavicon = '/favicon/TriiNotification.svg';

    const setActiveFavicon = () => {
      favicon?.setAttribute('href', originalFavicon);
    };

    const setInactiveFavicon = () => {
      favicon?.setAttribute('href', newFavicon);
    };

    const handleInactiveNotification = () => {
      if (document.hidden) {
        setInactiveFavicon();
        notificationSound.playNotification();
      }
    };

    if (socketConnection) {
      subscribeEvent('message_new', handleInactiveNotification);
      subscribeEvent('conversation_finalize', handleInactiveNotification);
      subscribeEvent('conversation_update', handleInactiveNotification);
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        setActiveFavicon();
      }
    });

    return () => {
      unsubscribeEvent('message_new');
      unsubscribeEvent('conversation_finalize');
      unsubscribeEvent('conversation_update');
      unsubscribeEvent('conversation_new');

      document.removeEventListener('visibilitychange', () => {
        favicon.setAttribute('href', originalFavicon);
      });
    };
  }, [socketConnection]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [conversationSelected, contactId]);

  useEffect(() => {
    const selectConversationById = async (
      paramName: string,
      getId: (id: string) => (conversation: IConversation) => boolean
    ) => {
      if (searchParams.has(paramName)) {
        const id = searchParams.get(paramName);
        if (id) {
          const conversation = conversations.find(getId(id));
          if (conversation && paramName === 'conversationId') {
            handleSelectConversation(conversation);
          } else if (paramName === 'contactId') {
            const getMessagesData: GetMessagesData = {
              conversationId: '',
              contactId: id,
            };

            loadContactDetails(id);

            await dispatch(getMessages(getMessagesData));

            // setMessages(response.payload);
            dispatch(setConversationSelected(null));
            setConversationNotFound(true);
          }
        }
      } else {
        setConversationNotFound(false);
      }
    };

    if (contactId) {
      selectConversationById(
        'contactId',
        (id) => (conversation) => conversation?.contactInfo?.id === id
      );
    }

    if (conversationId) {
      selectConversationById(
        'conversationId',
        (id) => (conversation) => conversation.id === id
      );
    }
  }, [searchParams]);

  const { boxChatWidth } = useOpenCallPanelWidth();

  return (
    <Box
      display="flex"
      margin="1rem"
      sx={{
        height: 'calc(100% - 2rem)',
        width: 'calc(100vw - 5rem)',
        backgroundColor: (theme) => theme.palette.background.default,
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        maxWidth: boxChatWidth,
      }}
      component="div"
    >
      <Box display="flex" width={'100%'} height={'100%'} ref={containerRef}>
        <SidebarContactList />
        {conversationSelected || contactId ? (
          <>
            <Conversation
              notFound={conversationNotFound}
              conversationSelected={conversationSelected}
              contactId={contactId}
            />
            <FilePreviewerProvider>
              {fileSelectorMode && <FilePreviewer />}
            </FilePreviewerProvider>
            <ImageViewer />
            <Box
              sx={{
                minWidth: '25%',
                maxWidth: '25%',
                height: '100%',
              }}
            >
              {isSearching ? (
                <Slide
                  direction="left"
                  in={isSearching}
                  mountOnEnter
                  unmountOnExit
                  container={containerRef.current}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <SearchMessage />
                  </Box>
                </Slide>
              ) : (
                <ChatDetailsSidebar />
              )}
            </Box>
          </>
        ) : (
          <SelectConversationScreen />
        )}
      </Box>
      <ModalList />
    </Box>
  );
};

export default ConversationsContainer;
