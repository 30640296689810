import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// Selector
import { useSelector } from 'react-redux';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Slice
import {
  selectSchedules,
  selectSchedulesStatus,
} from 'redux/features/editFlowSlice/editFlowSlice';

const Schedule = () => {
  const {
    timeScheduleControlNode,
    selectNode,
    setTimeScheduleControlNode,
  } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [scheduleId, setScheduleId] = useState<string>('');
  const schedules = useSelector(selectSchedules);
  const editStatus = useSelector(selectSchedulesStatus);
  const isLoading: boolean = editStatus === 'loading';
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.timeScheduleControl) {
      const timeScheduleControl = selectNode.data.timeScheduleControl;
      const defaultScheduleId =
        timeScheduleControl.scheduleId && (timeScheduleControl.scheduleId as string);
      setScheduleId(defaultScheduleId);
    }
  }, [selectNode]);

  const handleChangeSchedule = (scheduleId: string) => {
    setScheduleId(scheduleId);
    setTimeScheduleControlNode({ ...timeScheduleControlNode, scheduleId });
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="schedule" shrink={true}>
          {t('editFlowsView.timeScheduleControl.hourSelector')}
        </InputLabel>
        <Select
          labelId="schedule"
          id="demo-simple-select"
          value={scheduleId}
          label={t('editFlowsView.timeScheduleControl.hourSelector')}
          onChange={(e) => handleChangeSchedule(e.target.value)}
          disabled={isLoading}
          sx={{
            '& legend': {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: 'text.disabled'
            }
          }}
        >
          {schedules &&
            schedules?.map((schedule) => (
              <MenuItem key={schedule.id} value={schedule.id}>
                {schedule.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Schedule;
