import { useState, useContext, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  TableContainer,
  Typography
} from "@mui/material";
// Icons
import CloseIcon from "@mui/icons-material/Close";
// Context
import { DashboardContext } from '../../../../context/DashboardContext';
// Selector
import { dashboardLogs, dashboardLogsStatus } from "redux/features/dashboardSlice/dashboardSlice";
// Components
import { Table } from './components';
// Types
import { IConversation } from '@trii/types/dist/Conversations';

export default function RegisterModal() {
  const { t } = useTranslation()
  const newLogs = useSelector(dashboardLogs);
  const status = useSelector(dashboardLogsStatus);
  const isLoading = status === 'loading';
  const [logs, setLogs] = useState<IConversation[]>(newLogs);
  const {
    modalRegisterOpen,
    modalRegisterTitle,
    setModalRegisterOpen,
    setModalRegisterTitle
  } = useContext(DashboardContext);

  const handleClose = () => {
    setModalRegisterOpen(false);
    setLogs([]);
    setModalRegisterTitle('')
  };

  useEffect(() => {
    if (newLogs && newLogs.length > 0) {
      setLogs(newLogs);
    }
  }, [newLogs]);

  return (
    <Modal
      open={modalRegisterOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.default,
          maxWidth: '80vw',
          width: '80vw',
          borderRadius: '10px',
          boxShadow: theme => theme.shadows[5],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            p: 2,
          }}
        >
          <Typography
            color={theme => theme.palette.text.primary}
            variant={'h6'}
          >
            {t("dashboard.logs", { title: t(`${modalRegisterTitle}`) })}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon
              sx={{
                fontSize: "20px",
                color: theme => theme.palette.text.disabled,
              }}
            />
          </IconButton>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: '60vh',
            height: '60vh',
            overflow: 'auto',
            //@ts-ignore
            backgroundColor: theme => theme.palette.background.default,
          }}
        >
          {
            isLoading ? (
              <CircularProgress
                size={20}
              />
            ) : (
              <Table logs={logs} />
            )
          }
        </TableContainer>
      </Box>
    </Modal>
  );
}
