import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography, useTheme } from '@mui/material';
import StepContainer from '../StepContainer';
import OtpInput from 'react-otp-input';
// Redux
import { useSelector } from 'react-redux';
import { selectEmailVerifyEmailStatus } from 'redux/features/emailEditSlice/emailEditSlice';

type SecondStepProps = {
  otp: string;
  setOtp: (otp: string) => void;
  newEmail: string;
};

const SecondStep = ({ otp, setOtp, newEmail }: SecondStepProps) => {
  const { t } = useTranslation();
  const emailVerifyStatus = useSelector(selectEmailVerifyEmailStatus);
  const theme = useTheme()
  const getErrorMessage = () => {
    switch (emailVerifyStatus) {
      case 'INVALID':
        return t('emailEditView.incomingEmailPanel.setUpModal.invalidCode');
      case 'EXPIRED':
        return t('emailEditView.incomingEmailPanel.setUpModal.expiredCode');
      case 'CHANNEL_NOT_FOUND':
        return t('emailEditView.incomingEmailPanel.setUpModal.channelNotFound');
      default:
        return null;
    }
  };

  return (
    <StepContainer>
      <Box display={'flex'} flexDirection={'column'} gap={5}>
        <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
          <Typography variant="h6" fontSize={18}>
            {t('emailEditView.incomingEmailPanel.setUpModal.lookForEmail')}
          </Typography>
          <Typography variant="subtitle1">
            {t('emailEditView.incomingEmailPanel.setUpModal.enterCode')}
            <strong>{newEmail}</strong>
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={3}>
          {getErrorMessage() && (
            <Typography variant="body1" color="error" fontWeight="bold">
              {getErrorMessage()}
            </Typography>
          )}
          <OtpInput
            inputStyle={{
              width: '3rem',
              height: '3rem',
              margin: '20px 1rem',
              fontSize: '1rem',
              borderRadius: 4,
              border: '2px solid rgba(0,0,0,0.3)',
              //@ts-ignore
              backgroundColor: theme.palette.background.panel,
              backgroundImage:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0))',
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          <Typography
            variant="subtitle1"
            color={theme.palette.text.primary}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.light',
              },
            }}
          >
            {t('emailEditView.incomingEmailPanel.setUpModal.resendCode')}
          </Typography>
        </Box>
      </Box>
    </StepContainer>
  );
};

export default SecondStep;
