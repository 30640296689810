import React, { useContext } from 'react';
import { labelContext } from 'features/Views/Label/context/LabelProvider';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectLabelUpdateStatus } from 'redux/features/labelSlice/labelSlice';
// Components/ui
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';

function EditButtons() {
  const { t } = useTranslation();
  const { endEditing, handleEditLabel } = useContext(labelContext);
  const webHookEditStatus = useSelector(selectLabelUpdateStatus);
  const isLoading = webHookEditStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endEditing}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        startIcon={<SaveAsIcon />}
        loading={isLoading}
        disabled={isLoading}
        variant="contained"
        size="small"
        onClick={handleEditLabel}    
      >
        {t('global.save')}
      </LoadingButton>
    </Box>
  );
}

export default EditButtons;
