import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { endingContext } from 'features/Views/Ending/context/EndingProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectEndingDeleteStatus } from 'redux/features/endingSlice/endingSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteButtons() {
  const { t } = useTranslation();
  const { endDeleting, selectedEnding, deleteEndingName, handleDeleteEnding } =
    useContext(endingContext);
  const deleteStatus = useSelector(selectEndingDeleteStatus);
  const isDisabled =
    deleteStatus === 'loading' ||
    selectedEnding?.name !== deleteEndingName.attributes.value;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endDeleting}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        startIcon={<DeleteIcon />}
        disabled={isDisabled}
        onClick={handleDeleteEnding}
        loading={deleteStatus === 'loading'}
        variant="contained"
        size="small"
        color="error"
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
}
export default DeleteButtons;
