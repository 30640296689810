import { useState, useContext, useEffect } from 'react';
import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  useTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// Dayjs
import dayjs, { Dayjs } from 'dayjs';
// Utils
import { periodList } from './utils/periodList';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import {
  DateRange,
  DateTimeRangeValidationError,
  MultiInputDateTimeRangeField,
} from '@mui/x-date-pickers-pro';
import { FieldChangeHandler } from '@mui/x-date-pickers/internals';
// Types
import { JsReportDataTimeRange } from 'redux/features/reportsSlice/types/JsReportData';

const Period = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { setSelectedPeriodContext, setSelectedPeriodType, appliedPeriod } =
    useContext(MyReportContext);

  const [grupo, setGrupo] = useState<JsReportDataTimeRange>(7);
  const [value, setValue] = useState<DateRange<Dayjs>>([dayjs(), dayjs()]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MyMultiInputDateTimeRangeField = styled(MultiInputDateTimeRangeField)`
    && .MuiTypography-root {
      display: none;
    }
  `;

  const handleChange = (event: SelectChangeEvent<JsReportDataTimeRange>) => {
    const selectedValue = event.target.value as JsReportDataTimeRange;

    setGrupo(selectedValue);
    setSelectedPeriodType(selectedValue);

    if (selectedValue !== 0) {
      // Lógica para establecer la fecha según la opción seleccionada
      const currentDate = dayjs(); // Fecha actual
      // export type JsReportDataTimeRange = 1 | 7 | 30 | 365 | 77 | 1515 | 3030 | 60 | 0;

      switch (selectedValue) {
        case 1:
          setSelectedPeriodContext([currentDate, currentDate]);
          break;
        case 7:
          const dayOfWeek = currentDate.day();
          const startOfWeek = currentDate.subtract(
            dayOfWeek === 0 ? 6 : dayOfWeek - 1,
            'day'
          );
          setSelectedPeriodContext([startOfWeek, currentDate]);
          break;
        case 30:
          setSelectedPeriodContext([currentDate.startOf('month'), currentDate]);
          break;
        case 365:
          setSelectedPeriodContext([currentDate.startOf('year'), currentDate]);
          break;
        case 77:
          setSelectedPeriodContext([currentDate.subtract(7, 'days'), currentDate]);
          break;
        case 1515:
          setSelectedPeriodContext([currentDate.subtract(15, 'days'), currentDate]);
          break;
        case 3030:
          setSelectedPeriodContext([currentDate.subtract(30, 'days'), currentDate]);
          break;
        case 60:
          setSelectedPeriodContext([currentDate.subtract(60, 'days'), currentDate]);
          break;
        default:
          break;
      }
    }
    handleClose();
  };

  let timeoutId: NodeJS.Timeout;

  const handleDateChange: FieldChangeHandler<
    DateRange<Date>,
    DateTimeRangeValidationError
  > = (newValue) => {
    if (newValue) {
      const [startDate, endDate] = newValue;
      if (startDate && endDate) {
        clearTimeout(timeoutId); // Limpiar el timeout anterior
        timeoutId = setTimeout(() => {
          setValue([dayjs(startDate), dayjs(endDate)]); // Almacenar el valor final después de un retardo
          setSelectedPeriodContext([dayjs(startDate), dayjs(endDate)]);
        }, 1000); // Retardo de 1 segundo
      }
    }
  };

  useEffect(() => {
    const newTitle = periodList.find((item) => item.value === grupo)?.label;
    setTitle(newTitle);
  }, [grupo]);

  useEffect(() => {
    if (appliedPeriod) {
      setValue([dayjs(appliedPeriod[0]), dayjs(appliedPeriod[1])]);
    }
  }, [appliedPeriod]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        flexDirection: 'column',
        gap: 1,
        marginBottom: 2,
      }}
    >
      {/* select */}
      <FormControl size="small" fullWidth>
        <InputLabel id="search-message-filter-channels" shrink={true}>
          {t('myReports.period')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={grupo}
          label={t('myReports.period')}
          onChange={handleChange}
          sx={{
            '& legend': {
              maxWidth: '100%',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: theme.palette.background.panel,
              },
            },
          }}
        >
          {periodList.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {grupo === 0 ? (
        <Box sx={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                'SingleInputDateTimeRangeField',
                'MultiInputDateTimeRangeField',
              ]}
            >
              <MyMultiInputDateTimeRangeField
                slotProps={{
                  textField: ({ position }) => ({
                    label:
                      position === 'start'
                        ? t('conversationsTrends.from')
                        : t('conversationsTrends.until'),

                    shrink: true,
                    size: 'small',
                  }),
                }}
                useFlexGap={true}
                format={'DD/MM/YYYY HH:mm'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
                onChange={handleDateChange}
                value={value}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      ) : null}
    </Box>
  );
};

export default Period;
