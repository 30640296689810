import {
  Card,
  Text,
  Flex,
  Title,
  Grid,
  Metric,
  Divider,
  SelectBox,
  SelectBoxItem,
} from "@tremor/react";

import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const MembersCard = ({title, data}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const optionsData = data?.optionsList
  const valueData = data?.values

  const [selectedOption, setSelectedOption] = useState(() => localStorage.getItem('selectedOption') || "");
  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    const filteredValueData = valueData.find(item => item.key === selectedOption);
    setFilteredData(filteredValueData);
  }, [valueData, selectedOption]);
  const categories = filteredData ? [
    {
      title: t("dashboard.active"),
      metric: filteredData.activeConversations,
    },
    {
      title: t("dashboard.expired"),
      metric: filteredData.expiredConversations,
    },
    {
      title: t("dashboard.inLine"),
      metric: filteredData.inProgressConversations,
    },
    {
      title: t("dashboard.finished"),
      metric: filteredData.endingConversations,
    },
  ] : [];
  const promedios = filteredData ? [
    {
      title: t("dashboard.avr1stAnswer"),
      metric: filteredData.averageFirstResponseTime,
    },
    {
      title: t("dashboard.totalTime"),
      metric: filteredData.totalTime,
    },
    {
      title: t("dashboard.loginTime"),
      metric: filteredData.loginTime,
    },
  ] : [];
  const handleChange = (value: string) => {
    setSelectedOption(value);
    localStorage.setItem('selectedOption', value);
  };
  return (
    data && (
      //@ts-ignore
      <Card
        className="p-4"
        style={{
          //@ts-ignore
          backgroundColor: theme.palette.background.panel,
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
          minHeight: "565px",
        }}
      >
        <Title style={{ color: theme.palette.text.primary }}>{title}</Title>
        <>
          <Flex className="justify-start items-center space-x-0 truncate-none mt-5 ">
            {/* <img
              src={`${__webpack_public_path__}${e.img}`}
              alt=""
              width="40px"
              height="40px"
              style={{
                borderRadius: "50%",
                display: "initial",
                marginRight: "5px",
              }}
            />
            <Text>{e.name}</Text> */}
            <Box
              sx={{
                width: "100%",
                "& div button": {
                  //@ts-ignore
                  backgroundColor: theme.palette.background.panel,
                },
                "& div button:hover": {
                  //@ts-ignore
                  backgroundColor: theme.palette.background.panel,
                  background:
                    "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                },
              }}
            >
              <SelectBox
                onValueChange={handleChange}
                value={selectedOption}
                style={{ color: theme.palette.text.primary }}
              >
                {optionsData.map((e, index) => {
                  return (
                    <SelectBoxItem
                      key={index}
                      value={e.key}
                      text={e.name}
                      color={
                        //@ts-ignore
                        theme.palette.background.panel
                      }
                      style={{
                        color: theme.palette.text.primary,
                        backgroundColor:
                          //@ts-ignore
                          theme.palette.background.panel,
                      }}
                    />
                  );
                })}
              </SelectBox>
            </Box>
          </Flex>
          {selectedOption && (
            <>
              <Grid numCols={2} className="mt-4 gap-2">
                {categories.map((item, index) => (
                  <Card
                    key={index}
                    className="shadow-none p-3"
                    style={{
                      color: theme.palette.text.primary,
                      backgroundColor:
                        //@ts-ignore
                        theme.palette.background.panel,
                    }}
                  >
                    <Text>{item.title}</Text>
                    <Flex justifyContent="end">
                      <Metric className="truncate mt-2 text-2xl">
                        {item.metric}
                      </Metric>
                    </Flex>
                  </Card>
                ))}
              </Grid>
              <Divider />
              <Grid numCols={2} className="mt-4 gap-2">
                {promedios.map((item, index) => (
                  <Card
                    key={index}
                    className="shadow-none p-3"
                    style={{
                      color: theme.palette.text.primary,
                      backgroundColor:
                        //@ts-ignore
                        theme.palette.background.panel,
                    }}
                  >
                    <Text>{item.title}</Text>
                    <Metric className="truncate mt-2 text-2xl">
                      {item.metric}
                    </Metric>
                  </Card>
                ))}
              </Grid>
            </>
          )}
        </>
      </Card>
    )
  );
};

export default MembersCard;
