import { useState, useEffect, useContext } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Slice
import {
  selectFileUrl,
  fetchChargeFile,
  chargeFileStatus,
} from 'redux/features/editFlowSlice/editFlowSlice';
// Components/ui
import {
  TextField,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Interface
import { NodeMsgTextMessageType } from '@trii/types/dist/Conversations/Flows/Nodes';

const FileFormat = () => {
  const { textNode, setTextNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [filename, setFilename] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const editStatus = useSelector(chargeFileStatus);
  const isLoading: boolean = editStatus === 'loading';
  const url = useSelector(selectFileUrl);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode) {
      const message =
        selectNode.data.msgText && selectNode.data.msgText.message
          ? selectNode.data.msgText.message
          : ({} as any);
      if (message.type === NodeMsgTextMessageType.FILE) {
        const name =
          selectNode.data.msgText && selectNode.data.msgText.message
            ? selectNode.data.msgText.message.filename
            : ('' as string);
        const url =
          selectNode.data.msgText && selectNode.data.msgText.message
            ? selectNode.data.msgText.message.fileUrl
            : ('' as string);
        setFileUrl(url);
        setFilename(name);
      }
    }
  }, [selectNode]);

  useEffect(() => {
    setTextNode({
      ...textNode,
      message: { ...textNode.message, filename, fileUrl },
    });
  }, [filename, fileUrl]);

  useEffect(() => {
    if (url) {
      setFileUrl(url);
    }
  }, [dispatch, url]);

  const handleCharge = async (e: any) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const newFormData = new FormData();
    newFormData.append('photo', file, fileName);
    if (file) {
      setFilename(fileName);
      await dispatch(fetchChargeFile(newFormData));
    }
  };

  return (
    <>
      <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
        {t('editFlowsView.text.manualMessage.selectFile')}
      </Typography>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        size="small"
        color="success"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.dark,
          },
        }}
        component="label"
      >
        {t('editFlowsView.text.manualMessage.charge')}
        <input hidden type="file" onChange={(e) => handleCharge(e)} />
      </LoadingButton>
      {filename && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
          <TextField
            sx={{ width: '100%' }}
            variant="outlined"
            size="small"
            value={filename}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      window.open(fileUrl, '_blank');
                    }}
                    edge="end"
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default FileFormat;
