import React from 'react';
// components/ui
import { Box, Typography, Divider } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface EventSectionProps {
  title: string;
  children: React.ReactNode;
}

const EventSection = ({ title, children }: EventSectionProps) => {
  return (
    <Box marginBottom={2.5}>
      <Box display={'flex'} alignItems={'center'} gap={0.5}>
        <CalendarMonthIcon sx={{ fontSize: 16, color: 'text.primary' }} />
        <Typography
          sx={{ fontWeight: 600, fontSize: 13, color: 'text.primary' }}
        >
          {title}
        </Typography>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box display={'flex'} gap={0.5} flexDirection={'column'} px={0.7}>
        {children}
      </Box>
    </Box>
  );
};

export default EventSection;
