import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';
// dayjs
import dayjs from 'dayjs';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';

const Schedule = () => {
  const { timeTimerNode, setTimeTimerNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [day, setDay] = useState<number>(0);
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.timeTimer) {
      const timeTimer = selectNode.data.timeTimer;
      const defaultDay = timeTimer.days ? timeTimer.days : (0 as number);
      const defaultHour = timeTimer.hours ? timeTimer.hours : (0 as number);
      const defaultMinute = timeTimer.minutes ? timeTimer.minutes : (0 as number);
      const defaultSecond = timeTimer.seconds ? timeTimer.seconds : (0 as number);
      setDay(defaultDay);
      setHour(defaultHour);
      setMinute(defaultMinute);
      setSecond(defaultSecond);
    }
  }, [selectNode]);

  const handleChangeDay = (day: string) => {
    // REGEX: only numbers
    const regex = /^[0-9\b]+$/;
    if (regex.test(day.toString())) {
      const newDay = parseInt(day);
      setError(false);
      setDay(newDay);
      setTimeTimerNode({
        ...timeTimerNode,
        days: newDay,
      });
    } else {
      setError(true);
    }
  };

  const handleChangeHours = (hour: any) => {
    console.log(hour);
    const newHour = hour?.$H || 0;
    setHour(newHour);
    setTimeTimerNode({
      ...timeTimerNode,
      hours: newHour,
    });
  };
  const handleChangeMinutes = (minute: any) => {
    const newMinute = minute?.$m || 0;
    setMinute(newMinute);
    setTimeTimerNode({
      ...timeTimerNode,
      minutes: newMinute,
    });
  };
  const handleChangeSeconds = (seconds: any) => {
    const newSecond = seconds?.$s || 0;
    setSecond(newSecond);
    setTimeTimerNode({
      ...timeTimerNode,
      seconds: newSecond,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <TextField
        size="small"
        variant="outlined"
        label={t('editFlowsView.timeTimer.inputDay')}
        value={day}
        onChange={(e) => handleChangeDay(e.target.value as string)}
        error={error}
        helperText={error ? t('editFlowsView.timeTimer.inputTimeError') : ''}
        sx={{
          "& .MuiFormHelperText-root": {
            variant: "caption",
            fontStyle: "italic",
            opacity: 0.7,
          },
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          label={t('editFlowsView.timeTimer.inputHours')}
          size="small"
          ampm={false}
          sx={{ width: '100%', mb: '.1rem' }}
          defaultValue={hour ? dayjs().set('hour', hour) : dayjs().set('hour', 0)}
          value={dayjs().set('hour', hour)}
          format="HH"
          onChange={(e) => handleChangeHours(e)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          label={t('editFlowsView.timeTimer.inputMinutes')}
          size="small"
          ampm={false}
          sx={{ width: '100%', mb: '.1rem' }}
          defaultValue={
            minute ? dayjs().set('minute', minute) : dayjs().set('minute', 0)
          }
          value={dayjs().set('minute', minute)}
          format="mm"
          onChange={(e) => handleChangeMinutes(e)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          label={t('editFlowsView.timeTimer.inputSeconds')}
          size="small"
          ampm={false}
          sx={{ width: '100%', mb: '.1rem' }}
          defaultValue={
            second ? dayjs().set('second', second) : dayjs().set('second', 0)
          }
          value={dayjs().set('second', second)}
          format="ss"
          onChange={(e) => handleChangeSeconds(e)}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default Schedule;
