import { createContext, useState, ReactNode } from 'react';
// Hooks
import useField from 'hooks/useField';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Slice
import {
  createEnding,
  deleteEnding,
  updateEnding,
} from 'redux/features/endingSlice/endingSlice';
import { fetchGroup } from 'redux/features/groupSlice/groupSlice';
// Types
import type { CrudPanelState } from './types';
import { EndingContext } from './types';
import { IEnding } from '@trii/types/dist/Conversations';
import { UseFieldType } from 'hooks/useField';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';

export const endingContext = createContext<EndingContext>({
  crudPanelState: 'closed',
  endCreating: () => { },
  openCreate: () => { },
  handleCreateEnding: () => { },
  openDelete: () => { },
  endDeleting: () => { },
  selectedEnding: null,
  deleteEndingName: fieldInitialDef,
  editEndingName: fieldInitialDef,
  createEndingName: fieldInitialDef,
  handleDeleteEnding: () => { },
  endEditing: () => { },
  handleEditEnding: () => { },
  openEdit: () => { },
  // General
  getGroups: () => { },
  selectedGroup: [],
  setSelectedGroup: () => { },
});

const EndingProvider = ({ children }: { children: ReactNode }) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedEnding, setSelectedEnding] = useState<IEnding>(null);
  const [selectedGroup, setSelectedGroup] = useState<GroupInfo[]>([]);
  // Create state
  const createEndingName = useField('text');
  // Edit state
  const editEndingName = useField('text');
  // Delete state
  const deleteEndingName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // General Functions
  const getGroups = async () => {
    await dispatch(fetchGroup());
  }
  // end General Functions

  // Create functions
  const handleCreateEnding = async () => {
    await dispatch(createEnding({
      name: createEndingName.attributes.value,
      groups: selectedGroup,
    }));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createEndingName.actions.resetValue();
    closeCrudPanel();
  };
  // end Create functions

  // Edit functions
  const openEdit = (ending: IEnding) => {
    editEndingName.actions.changeValue(ending.name);
    setSelectedEnding(ending);
    setCrudPanelState('edit');
    setSelectedGroup(ending.groups);
  };
  const handleEditEnding = async () => {
    const editedEnding = {
      name: editEndingName.attributes.value,
      groups: selectedGroup,
    };
    const editedEndingWithId: IEnding = {
      ...editedEnding,
      id: selectedEnding.id,
    };

    await dispatch(updateEnding(editedEndingWithId));
    endEditing();
  };
  const endEditing = () => {
    editEndingName.actions.resetValue();
    setSelectedGroup([]);
    closeCrudPanel();
  };
  // end Edit functions

  // Delete functions
  const handleDeleteEnding = async () => {
    const { id } = selectedEnding;

    await dispatch(deleteEnding(id));
    endDeleting();
  };

  const openDelete = (ending: IEnding) => {
    setSelectedEnding(ending);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteEndingName.actions.resetValue();
    setSelectedEnding(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <endingContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateEnding,
        openDelete,
        endDeleting,
        deleteEndingName,
        editEndingName,
        createEndingName,
        selectedEnding,
        handleDeleteEnding,
        endEditing,
        handleEditEnding,
        openEdit,
        // General
        getGroups,
        selectedGroup,
        setSelectedGroup,
      }}
    >
      {children}
    </endingContext.Provider>
  );
};

export default EndingProvider;
