import { NodeConditionalOperator } from "@trii/types/dist/Conversations/Flows/Nodes";

type Operator = {
    id: number;
    label: string;
}

const operatorList: Operator[] = [
    {
        id: NodeConditionalOperator.NULL,
        label: 'editFlowsView.conditional.operator.null'
    },
    {
        id: NodeConditionalOperator.EQUAL,
        label: 'editFlowsView.conditional.operator.equal'
    },
    {
        id: NodeConditionalOperator.GREATER_THAN,
        label: 'editFlowsView.conditional.operator.greaterThan'
    },
    {
        id: NodeConditionalOperator.GREATER_THAN_EQUAL,
        label: 'editFlowsView.conditional.operator.greaterThanEqual'
    },
    {
        id: NodeConditionalOperator.LESS_THAN,
        label: 'editFlowsView.conditional.operator.lessThan'
    },
    {
        id: NodeConditionalOperator.LESS_THAN_EQUAL,
        label: 'editFlowsView.conditional.operator.lessThanEqual'
    },
    {
        id: NodeConditionalOperator.START_WITH,
        label: 'editFlowsView.conditional.operator.startWith'
    },
    {
        id: NodeConditionalOperator.END_WITH,
        label: 'editFlowsView.conditional.operator.endWith'
    },
    {
        id: NodeConditionalOperator.CONTAINS,
        label: 'editFlowsView.conditional.operator.contains'
    },
    {
        id: NodeConditionalOperator.NOT_CONTAINS,
        label: 'editFlowsView.conditional.operator.notContains'
    },
    {
        id: NodeConditionalOperator.ANY_VALUE,
        label: 'editFlowsView.conditional.operator.anyValue'
    }
]

export default operatorList;