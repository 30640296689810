import { useContext } from 'react';
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// Redux
import { useSelector } from 'react-redux';
import {
  selectScheduleDeleteStatus,
  selectedSchedule,
} from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeDeletePanel, sendDeleteSchedule, deleteScheduleNameField } =
    useContext(InitScheduleContext);
  const deleteStatus: RequestStatus = useSelector(selectScheduleDeleteStatus);
  const schedule = useSelector(selectedSchedule);
  const isLoading = deleteStatus === 'loading';
  const isDisabled = deleteStatus === 'loading' ||
    deleteScheduleNameField.attributes.value !== schedule.name;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeDeletePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<DeleteIcon />}
        variant="contained"
        size="small"
        color="error"
        sx={{ backgroundColor: (theme) => theme.palette.error.main }}
        disabled={isDisabled}
        onClick={() => sendDeleteSchedule(schedule.id)}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
