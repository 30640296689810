import React from 'react'
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const Category = () => {
    const { t } = useTranslation();
    return (
        <>
            <FormControl fullWidth size='small'>
                <InputLabel id="category" shrink={true}>{t('editFlowsView.fillTicket.category')}</InputLabel>
                <Select
                    labelId="category"
                    id="demo-simple-select"
                    label={t('editFlowsView.fillTicket.category')}
                    disabled
                    sx={{
                        '& legend' : {
                          maxWidth: "fit-content",
                          // padding: '0 10px'
                        }, "& .MuiSvgIcon-root": {
                            color: "text.disabled",
                          }
                      }}
                >
                    <MenuItem key={1} value={1}>Próximamente</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

export default Category