import { Box, CircularProgress, } from "@mui/material"
import { Card as TremorCard, Metric, Title } from "@tremor/react";
import { Handle } from "reactflow";

interface Props {
  title: string;
  quantity: number;
  color: string;
  isLoading: boolean;
  handleSelect: () => void;
}

const Card = ({
  title,
  quantity,
  color,
  isLoading,
  handleSelect
}: Props) => {
  return (
    <Box
      component={TremorCard}
      sx={{
        // @ts-ignore
        backgroundColor: theme => theme.palette.background.panel,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        color: theme => theme.palette.text.primary,
      }}
    >
      <Title>{title}</Title>
      <Box
        sx={{
          color: color,
        }}
      >
        {
          isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Metric
              className="text-center cursor-pointer text-2xl"
              onClick={handleSelect}
            >
              {quantity}
            </Metric>
          )
        }
      </Box>
    </Box >
  )
}

export default Card