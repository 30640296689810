// Types
import { WebChatConfig } from '@trii/types/dist/Common/Channels';
// Components/ui
import DesignSection from '../DesignSection';
import HomeIcon from '@mui/icons-material/Home';
import {
  LogoOptions,
  SubtitleOptions,
  TitleOptions,
  MultipleConversationsOptions,
} from './components';

interface Props {
  webChatData: WebChatConfig;
}

const HomeSection = ({ webChatData }: Props) => {
  return (
    <DesignSection
      icon={<HomeIcon sx={{ color: 'primary.main', fontSize: 17 }} />}
      title={'Home'}
    >
      <LogoOptions
        homeLogoEnabled={webChatData.homeLogoEnabled}
        homeLogoUrl={webChatData.homeLogoUrl}
      />
      <TitleOptions homeTitle={webChatData.homeTitle} />
      <SubtitleOptions homeSubtitle={webChatData.homeSubTitle} />
      <MultipleConversationsOptions
        homeMultiConversationsEnabled={webChatData.homeMultiConversationsEnabled}
      />
    </DesignSection>
  );
};

export default HomeSection;
