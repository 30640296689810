import { useContext, useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  selectGroupData,
  selectGroupStatus,
} from 'redux/features/groupSlice/groupSlice';
// Context
import { endingContext } from 'features/Views/Ending/context/EndingProvider';
// Componets/ui
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
// Icons
import { Add, Check } from '@mui/icons-material';
// Types
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';

interface GroupListProps {
  groups?: GroupInfo[];
}

const GroupList = ({ groups }: GroupListProps) => {
  const { t } = useTranslation();
  const { selectedGroup, setSelectedGroup } = useContext(endingContext);

  const groupList = useSelector(selectGroupData);
  const groupStatus = useSelector(selectGroupStatus);
  const isLoading = groupStatus === 'loading';

  const handleSelectGroup = (group: GroupInfo) => {
    if (selectedGroup.some((selected) => selected.id === group.id)) {
      setSelectedGroup(selectedGroup.filter((selected) => selected.id !== group.id));
      return;
    }
    setSelectedGroup([...selectedGroup, group]);
  };

  useEffect(() => {
    if (groups && groups.length > 0) {
      setSelectedGroup(groups);
    }

    return () => {
      setSelectedGroup([]);
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: 'calc(100% - 10.5rem)',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          padding: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          height: '2.5rem',
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        {t('endingsView.groups')}
      </Typography>
      <List
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: 'calc(100% - 2.5rem)',
          overflowY: 'auto',
        }}
      >
        {isLoading ? (
          <CircularProgress size={30} />
        ) : (
          groupList.map((group) => (
            <ListItem
              key={group.id}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              disablePadding
            >
              <ListItemButton
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleSelectGroup(group)}
              >
                <ListItemText
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {group.name}
                </ListItemText>
                <ListItemAvatar sx={{ minWidth: '0px' }}>
                  {selectedGroup.some((selected) => selected.id === group.id) ? (
                    <Check color="primary" />
                  ) : (
                    <Add color="primary" />
                  )}
                </ListItemAvatar>
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default GroupList;
