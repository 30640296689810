import { useEffect, useState } from "react";
// Components/ui
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material"
import { FormItemType } from "@trii/types/dist/Common/Channels";
import { BarChart } from "@tremor/react";
import { Actions } from "./components";

interface Props {
  title: string;
  subTitle: string;
  color?: string;
  isTitle?: boolean;
  isQuestion?: boolean;
  questionType?: FormItemType;
  showGraphic?: boolean;
  list?: any;
  showActions?: boolean;
}

interface ChartData {
  [key: string]: string | number;
}

const Card = ({
  title,
  subTitle,
  color = null,
  isTitle = false,
  isQuestion = false,
  questionType,
  showGraphic = false,
  list = [],
  showActions = false,
}: Props) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    if (questionType === FormItemType.LINEARSCALE && list && list.length > 0 && showGraphic) {
      const data = list.map((item: any) => {
        return {
          name: item.responseTitle,
          [title]: item.count
        }
      })
      setChartData(data)
    }
  }, [questionType])

  

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "max-content",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        borderTop: theme => isTitle && `10px solid ${color || theme.palette.primary.main}`,
        borderLeft: theme => (isQuestion && !isTitle) && `10px solid ${color || theme.palette.primary.main}`,
        //@ts-ignore
        backgroundColor: theme.palette.background.dropdownMenu,
        borderRadius: "1rem",
        boxShadow: theme => theme.shadows[2],
        p: 2,
      }}
    >
      {
        questionType === FormItemType.LINEARSCALE && showGraphic ? (
          <BarChart
            data={chartData}
            index="name"
            categories={[title]}
            yAxisWidth={50}
            style={{
              color: theme.palette.text.primary,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >        
              <Typography
                 sx={{
                  color: theme => theme.palette.text.primary,
                  '@media print' : {
                    color: 'black'
                  }
                }}
                variant={isTitle ? "h6" : "body1"}
                noWrap
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: theme => theme.palette.text.primary,
                  '@media print' : {
                    color: 'black'
                  }
                }}
                variant="body1"
                fontSize={isTitle ? "1rem" : ".75rem"}
                noWrap
              >
                {subTitle}
              </Typography>
            </Box>
            {
              showActions && (
                <Actions />
              )
            }
          </Box>
        )
      }
    </Box>
  )
}

export default Card