import { useContext, useMemo } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import { Box } from '@mui/material';
// Interface
import { IConversation } from '@trii/types/dist/Conversations';
// Components
import { Body, Header, Footer } from './components';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';

function ConversationCard({ conversation }: { conversation: IConversation }) {
  const conversationSelected = useSelector(selectConversationSelected);

  const isSelected = conversation.id === conversationSelected?.id;

  const memoizedCard = useMemo(
    () => (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        maxWidth="100%"
        width="auto"
        p={1}
        py={0.5}
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: isSelected
              ? (theme) => theme.palette.action.active
              : (theme) => theme.palette.action.hover,
            "& .showIconButton": {
              display: "flex !important",
            },
          },
          backgroundColor: isSelected
            ? (theme) => theme.palette.action.active
            : (theme) => theme.palette.background.default,
          borderLeft: isSelected ? "1px solid rgb(0 0 0 / 5%)" : null,
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        }}
      >
        <Box
          display="flex"
          flexGrow={1}
          height="100%"
          width="100%"
          margin="0"
          gap={1}
          alignItems="center"
        >
          <Body conversation={conversation} />
          <Header
            pinned={conversation.pinned}
            transferTo={conversation.transferTo}
            transferBy={conversation.transferBy}
            expired={conversation.expired}
            updatedAt={conversation.updatedAt}
            labels={conversation.labels}
            conversation={conversation}
          />
        </Box>
        <Footer conversation={conversation} />
      </Box>
    ),
    [conversation, isSelected]
  );

  return memoizedCard;
}
export default ConversationCard;
