import React, { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
// Selector
import { useSelector } from 'react-redux';
// Context
import { InitEditFlowsContext } from '../../../../../../../../../../context/EditFlowsContext';
import { EditFlowsContext } from '../../../../../../../../../../context/types';
import { StyleContext } from '../../../../../../../../../../../../../style/styleProvider';
// Slice
import {
  selectSearchBy,
  selectSearchByStatus,
} from '../../../../../../../../../../../../../redux/features/editFlowSlice/editFlowSlice';

const Contact = () => {
  const { t } = useTranslation();

  const { contactSearchNode, setContactSearchNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { fontWeight } = useContext(StyleContext);

  const [contactFieldId, setContactFieldId] = useState<string>('');
  const [valueToSearch, setValueToSearch] = useState<string>('');

  const contacts = useSelector(selectSearchBy);
  const searchByStatus = useSelector(selectSearchByStatus);

  const isLoading: boolean = searchByStatus === 'loading';

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.contactSearch) {
      const contactSearch = selectNode.data.contactSearch;
      const defaultContactId =
        contactSearch.contactFieldId && (contactSearch.contactFieldId as string);
      const defaultContactValue =
        contactSearch.valueToSearch && (contactSearch.valueToSearch as string);
      setContactFieldId(defaultContactId);
      setValueToSearch(defaultContactValue);
    }
  }, [selectNode]);

  const handleChangeContact = (contactFieldId: string) => {
    setContactFieldId(contactFieldId);
    setContactSearchNode({ ...contactSearchNode, contactFieldId });
  };
  const handleChangeValue = (valueToSearch: string) => {
    setValueToSearch(valueToSearch);
    setContactSearchNode({ ...contactSearchNode, valueToSearch });
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        // color={(theme) => theme.palette.text.disabled}
        fontWeight={fontWeight.bold}
        sx={{ marginBottom: '10px' }}
        color="text.primary"
      >
        {t('editFlowsView.contactSearch.contact')}
      </Typography>
      <FormControl fullWidth size="small">
        <InputLabel id="contact" shrink={true}>
          {t('editFlowsView.contactSearch.searchBy')}
        </InputLabel>
        <Select
          labelId="contact"
          id="demo-simple-select"
          value={contactFieldId}
          label={t('editFlowsView.contactSearch.searchBy')}
          onChange={(e) => handleChangeContact(e.target.value)}
          disabled={isLoading}
          sx={{
            '& legend': {
              maxWidth: 'fit-content',
              //   padding: '0 10px'
            },
            '& .MuiSvgIcon-root': {
              color: 'text.disabled',
            },
          }}
        >
          {contacts &&
            contacts?.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.contactSearch.valueToSearch')}
        fullWidth
        value={valueToSearch}
        onChange={(e) => handleChangeValue(e.target.value as string)}
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default Contact;
