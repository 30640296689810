export const style = {
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingY: '10px',
    paddingX: '15px',
    gap: '7px',
  },
  dataContainer: {
    paddingY: '10px',
    paddingX: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};
