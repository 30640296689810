import { useContext, useState, useEffect } from 'react';
// Context
import { InitScheduleEditContext } from "../../context/ScheduleEditContext";
// Translations
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { IconButton, Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { selectScheduleEditStatus, selectScheduleById } from 'redux/features/scheduleSlice/scheduleSlice';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const Header = () => {
  const { t } = useTranslation();
  const {
    sendSchedule,
    scheduleNameField,
    closeEditPanel,
  } = useContext(InitScheduleEditContext);
  const [name, setName] = useState('');
  const space = localStorage.getItem('space');
  const spaceId = space ? JSON.parse(space).id : null;
  const schedule = useSelector(selectScheduleById);
  const editStatus = useSelector(selectScheduleEditStatus);
  const isLoading = editStatus === 'loading';
  const isDisabled = editStatus === 'loading' || !scheduleNameField.attributes.value;

  useEffect(() => {
    if (schedule) {
      setName(schedule.name);
    }
  }, [schedule]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      bgcolor={"dropdownBox"}
      px={2}
      py={1.5}
      justifyContent={"space-between"}
    >
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <IconButton size="small" onClick={closeEditPanel}>
          <ArrowBackIcon
            sx={{
              fontSize: 20,
              color: "text.primary",
            }}
          />
        </IconButton>
        <Box display={"flex"} gap={0.5} alignItems={"center"}>
          <AccessTimeIcon
            sx={{
              fontSize: 20,
              color: "primary.main",
            }}
          />
          <Typography color={'text.primary'}>{name}</Typography>
        </Box>
      </Box>
      <Box display={"flex"} gap={1}>
        <Button variant="outlined" size="small" onClick={closeEditPanel}>
          {t("global.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          loadingPosition="start"
          loading={isLoading}
          startIcon={<SaveAsIcon />}
          disabled={isDisabled}
          onClick={() => sendSchedule(schedule.id, spaceId)}
        >
          {t("global.save")}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Header;
