import React from "react";
//components/ui
import {
  Box,
  Typography,
  IconButton,
} from "@mui/material";
// Icons
import { Add, Delete } from "@mui/icons-material";

interface BranchProps {
  title: string;
  hideAdd?: boolean;
  handleAdd?: () => void;
  handleDelete: () => void;
}

const Branch = ({
  title,
  hideAdd = false,
  handleAdd,
  handleDelete,
}: BranchProps) => {
  const handleClick = (handler: () => void) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handler();
  }
  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "inherit",
          flexGrow: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
      <Box className="profile-showButtonsRoles">
        {
          !hideAdd && (
            <IconButton
              style={{ alignSelf: "center", marginRight: "5px" }}
              color="primary"
              size={"small"}
              onClick={handleClick(handleAdd)}
            >
              <Add fontSize="inherit" />
            </IconButton>
          )
        }
        <IconButton
          style={{ alignSelf: "center", marginRight: "5px" }}
          color="error"
          size={"small"}
          onClick={handleClick(handleDelete)}
        >
          <Delete fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Branch;
