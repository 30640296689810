import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { MercadoLibreConfig, MercadoLibreConfigAccount } from '@trii/types/dist/Common/Channels';
// Components/ui
import { ChannelPanel } from 'components';
import { Status, MercadoLibreAccountCard } from './components';
import { Box } from '@mui/material';

interface Props {
  mercadoLibreAccount: MercadoLibreConfigAccount;
  mlConfig: MercadoLibreConfig;
}

const ConnectionPanel = ({ mercadoLibreAccount, mlConfig }: Props) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  //   const mercadoLibreSyncStatus = useSelector();:
  const mercadoLibreSyncStatus = mlConfig.mlRefreshTokenExpiration;
  
  useEffect(() => {
    if (mercadoLibreAccount && wrapperRef.current) {
      wrapperRef.current.style.height = '140px';
    } else {
      wrapperRef.current.style.height = '25px';
    }
  }, [mercadoLibreAccount]);

  return (
    <ChannelPanel title={t('global.connection')}>
      <Box
        ref={wrapperRef}
        width="100%"
        sx={{
          transition: 'all 0.2s ease',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Status mercadoLibreSyncStatus={mercadoLibreSyncStatus} />
        </Box>
        {mercadoLibreAccount && (
          <MercadoLibreAccountCard userData={mercadoLibreAccount} mlConfig={mlConfig}/>
        )}
      </Box>
    </ChannelPanel>
  );
};

export default ConnectionPanel;
