import React from 'react'
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const Status = () => {
    const { t } = useTranslation();
    return (
      <>
        <FormControl fullWidth size="small">
          <InputLabel id="category" shrink={true}>
            {t("editFlowsView.fillTicket.status")}
          </InputLabel>
          <Select
            labelId="category"
            id="demo-simple-select"
            label={t("editFlowsView.fillTicket.status")}
            disabled
            sx={{
              "& legend": {
                maxWidth: "fit-content",
                // padding: '0 10px'
              },
              "& .MuiSvgIcon-root": {
                color: "text.disabled",
              },
            }}
          >
            <MenuItem key={1} value={1}>
              Próximamente
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
}

export default Status