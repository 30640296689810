// Components ui
import {
  Box,
  Divider,
  Skeleton as MuiSkeleton,
} from '@mui/material'
// Components
import { Item } from './components'

export const Table = () => {
  const list = [1, 2, 3, 4, 5, 6, 7]
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '95%',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <MuiSkeleton
        variant="text"
        width={300}
        sx={{
          fontSize: '2rem',
        }}
        animation="wave"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {
          list.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Item />
              {
                index === list.length - 1 ? null : <Divider />
              }
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

export default Table