import { memo, useContext } from 'react';
import { Handle, Position, NodeToolbar, NodeProps } from 'reactflow';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
import { StyleContext } from 'style/styleProvider';
// Components/ui
import { Box, Typography, IconButton, useTheme } from '@mui/material';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import { NodeData } from '@trii/types/dist/Conversations/Flows';

const Response = ({ value, handleId }) => {
  const { fontSize } = useContext(StyleContext);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Typography sx={{ color: 'text.primary', marginRight: '1rem' }} fontSize={10}>
        {value}
      </Typography>
      <Handle type="source" position={Position.Right} id={handleId} />
    </Box>
  );
};

function WaitResponseCustomNode({ id, data, selected }: NodeProps<NodeData>) {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { nodes, edges, setEdges, setSelectNode, setEditPanelOpen, setNodes } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const msgWaitResponse = data && data?.msgWaitResponse;

  const onNodesDelete = (id: string) => {
    const newNodes = nodes.filter((node) => node.id !== id);
    const newEdges = edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );
    setNodes(newNodes);
    setEdges(newEdges);
    setSelectNode(null);
    setEditPanelOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: shadow.lg,
        border: `1px solid ${selected ? theme.palette.primary.light : 'rgba(0,0,0,0.1)'
          }`,
        borderRadius: '.5rem',
        height: 'max-content',
        padding: '0.5rem',
      }}
    >
      <NodeToolbar offset={-10}>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onNodesDelete(id)}
          sx={{
            '& :hover': {
              color: 'text.disabled',
            },
          }}
        >
          <ClearIcon />
        </IconButton>
      </NodeToolbar>
      <Handle type="target" position={Position.Left} id="1" />
      <Typography
        sx={{ color: 'text.primary' }}
        fontWeight={fontWeight.medium}
        fontSize={10}
      >
        {msgWaitResponse?.name
          ? data.msgWaitResponse.name
          : t('editFlowsView.waitResponse.title')}
      </Typography>
      {msgWaitResponse?.conditions?.length !== 0 &&
        msgWaitResponse?.conditions.map((condition) => (
          <Response
            key={condition.id}
            value={condition.outputValue}
            handleId={condition.id}
          />
        ))}

      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Typography sx={{ color: 'text.primary', marginRight: '1rem' }} fontSize={10}>
          {t('editFlowsView.waitResponse.onTimeOut')}
        </Typography>
        <Handle type="source" position={Position.Right} id='editFlowsView.waitResponse.onTimeOut' />
      </Box>

    </Box>
  );
}

export default memo(WaitResponseCustomNode);
