import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import { initRequestData } from '../../functions/initRequestData';
// Types
import { GoogleBusinessEditState } from './types/googleBusinessEditState';
import { PermissionReadMessagesPayload } from 'redux/types/PermissionReadMessagesPayload';
import { PermissionStartConversationsPayload } from 'redux/types/PermissionStartConversationsPayload';
import { Permission } from '@trii/types/dist/Common/Channels';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import { IChannel } from '@trii/types/dist/Common/Channels';
// Service
import googleBusinessEditService from './googleBusinessEditService';
import { AssignMethod } from '@trii/types/dist/Conversations';
import { GroupInfo } from '@trii/types/dist/Conversations/Groups/Group';
import { UserInfo } from '@trii/types/dist/Users';

const initialState: GoogleBusinessEditState = {
  channel: null,
  googleBusinessName: null,
  status: {
    fetch: 'idle',
    update: 'idle',
  },
};

export const fetchGoogleBusiness = createAsyncThunk(
  'googleBusinessEdit/fetchgoogleBusiness',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await googleBusinessEditService.fetchGoogleBusiness(
      jwtToken,
      URL_CONVERSATIONS,
      id
    );

    return response;
  }
);

export const updateGoogleBusiness = createAsyncThunk<
  IChannel,
  void,
  { state: RootState }
>('googleBusinessEdit/updategoogleBusiness', async (_, { getState, dispatch }) => {
  const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const googleBusiness = getState().GoogleBusinessEdit.channel;
  console.log(googleBusiness);
  await googleBusinessEditService.updateGoogleBusiness(
    jwtToken,
    URL_CONVERSATIONS,
    googleBusiness
  );
  return googleBusiness;
});

const googleBusinessEditSlice = createSlice({
  name: 'googleBusinessEdit',
  initialState,
  reducers: {
    changeName(state, action: PayloadAction<string>) {
      state.channel.name = action.payload;
    },
    changeToken(state, action: PayloadAction<string>) {
      state.channel.googleBusiness.token = action.payload;
    },
    changeReassign(state, action: PayloadAction<boolean>) {
      state.channel.googleBusiness.reassign = action.payload;
    },
    changeReassignGroup(state, action: PayloadAction<string>) {
      state.channel.googleBusiness.reassignGroupId = action.payload;
    },
    changeAutoResponseBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.googleBusiness.autoResponseBusinessHours = action.payload;
    },
    changeAutoResponseOutOfBusinessHours(state, action: PayloadAction<boolean>) {
      state.channel.googleBusiness.autoResponseOutOfBusinessHours = action.payload;
    },
    changeAutoResponseHoliday(state, action: PayloadAction<boolean>) {
      state.channel.googleBusiness.autoResponseHoliday = action.payload;
    },
    changeSchedule(state, action: PayloadAction<ISchedule>) {
      const schedule = action.payload;

      state.channel.googleBusiness.schedule = schedule;
    },
    changeAssignTo(
      state,
      action: PayloadAction<{ group: GroupInfo; user: UserInfo }>
    ) {
      state.channel.assignTo = {
        groupInfo: action.payload.group,
        userInfo: action.payload.user,
      };
    },
    addPermission: {
      prepare: (data: {
        groupId?: string;
        userId?: string;
      }): { payload: Permission } => ({
        payload: {
          groupId: data.groupId || null,
          readMessages: false,
          startConversations: false,
          userId: data.userId || null,
        },
      }),
      reducer: (state, action: PayloadAction<Permission>) => {
        state.channel.permissions.push(action.payload);
      },
    },
    removePermission(
      state,
      action: PayloadAction<{ groupId?: string; userId?: string }>
    ) {
      let index = -1;

      if (action.payload.groupId) {
        index = state.channel.permissions.findIndex(
          (permission) => permission.groupId === action.payload.groupId
        );
      } else {
        index = state.channel.permissions.findIndex(
          (permission) => permission.userId === action.payload.userId
        );
      }

      state.channel.permissions.splice(index, 1);
    },
    changePermissionReadMessages(
      state,
      action: PayloadAction<PermissionReadMessagesPayload>
    ) {
      const { permissionGroupId, readMessages, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.readMessages = readMessages;
      } else if (permissionByUserId) {
        permissionByUserId.readMessages = readMessages;
      }
    },
    changePermissionStartConversation(
      state,
      action: PayloadAction<PermissionStartConversationsPayload>
    ) {
      const { permissionGroupId, startConversations, permissionContactId } =
        action.payload;

      const permissionByGroupId = state.channel.permissions.find(
        (permission) => permission.groupId === permissionGroupId
      );
      const permissionByUserId = state.channel.permissions.find(
        (permission) => permission.userId === permissionContactId
      );

      if (permissionByGroupId) {
        permissionByGroupId.startConversations = startConversations;
      } else if (permissionByUserId) {
        permissionByUserId.startConversations = startConversations;
      }
    },
    changeAssignMethod(state, action: PayloadAction<AssignMethod>) {
      state.channel.assignMethod = action.payload;
    },
    resetChannel(state) {
      state.channel = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGoogleBusiness.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchGoogleBusiness.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('Fetched googleBusiness: ', action.payload);
        state.channel = action.payload;
        state.googleBusinessName = action.payload.name;
      })
      .addCase(updateGoogleBusiness.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateGoogleBusiness.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        state.googleBusinessName = action.payload.name;
      })
      .addCase(updateGoogleBusiness.rejected, (state, action) => {
        console.log('Error: ', action.error.message);
      });
  },
});

const selectgoogleBusinessEditState = (state: RootState) => state.GoogleBusinessEdit;
export const selectGoogleBusiness = createSelector(
  selectgoogleBusinessEditState,
  (state) => state.channel
);
export const selectGoogleBusinessName = createSelector(
  selectgoogleBusinessEditState,
  (state) => state.googleBusinessName
);
export const selectGoogleBusinessFetchStatus = createSelector(
  selectgoogleBusinessEditState,
  (state) => state.status.fetch
);
export const selectGoogleBusinessUpdateStatus = createSelector(
  selectgoogleBusinessEditState,
  (state) => state.status.update
);

export const {
  resetChannel,
  changeName,
  changeToken,
  changeReassign,
  changeReassignGroup,
  changeAutoResponseBusinessHours,
  changeAssignTo,
  changeAutoResponseOutOfBusinessHours,
  changeAutoResponseHoliday,
  changeSchedule,
  addPermission,
  changePermissionReadMessages,
  changePermissionStartConversation,
  removePermission,
  changeAssignMethod,
} = googleBusinessEditSlice.actions;

export default googleBusinessEditSlice.reducer;
