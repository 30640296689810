import React, { useEffect, useState } from 'react';
// Types
// Components/ui
import { Box, Typography, Avatar, Card, Fade } from '@mui/material';
import { GoogleAccountInfo } from '@trii/types/dist/Common/Channels';

interface IGoogleAccountCardProps {
  outboundGoogleAccount: GoogleAccountInfo | null; // Asegúrate de permitir que userData sea null
}

const GoogleAccountCard = ({ outboundGoogleAccount }: IGoogleAccountCardProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  if (!outboundGoogleAccount) {
    return null; // Si userData es null, no renderizamos nada
  }

  const { imageUrl, name, email } = outboundGoogleAccount;

  return (
    <Fade in={show}>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          width: 290,
          maxWidth: 330,
          backgroundColor: "dropdownBox"
        }}
      >
        <Avatar
          sx={{
            width: 48,
            height: 48,
            backgroundColor: (theme) => theme.palette.action.disabledBackground,
            color: 'white',
            mr: 2,
          }}
          src={imageUrl ? imageUrl : ''}
          alt={name.slice(0, 1)}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography color="text.secondary">
            {email}
          </Typography>
        </Box>
      </Card>
    </Fade>
  );
};

export default GoogleAccountCard;
