import { memo, useState } from 'react';
// Context
import { ActivitiesCardContext } from './ActivitiesCardContext';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  addActivity,
  updateActivity,
  deleteActivity,
  endActivity,
  removeDeletingActivityStatus,
  removeEndingActivityStatus,
  setDeletingActivityStatus,
  setEndingActivityStatus,
  sortActivitiesByColumn,
} from 'redux/features/activitiesSlice/activitiesSlice';
// Types
import { FC, ReactNode } from 'react';
import { ActivitiesCardContextType } from './types/ActivitiesCardContextType';
import { ActivitiesTableColumn } from './types/ActivitiesTableColumn';
import { ActivitiesTableColumnName } from 'redux/features/activitiesSlice/types/ActivitiesTableColumnName';
import { IEvent } from '@trii/types/dist/Calendar';
// Utils
import { activitiesTableColumns } from './utils/columns';
import { getOrderedColumns } from '../utils/getOrderedColumns';
import { useCreateActivity } from '../hooks/useCreateActivity';
// Context
import { TabName } from './types/TabName';

const ActivitiesCardContextProvider: FC<{ children: ReactNode }> = memo(
  ({ children }) => {
    const dispatch = useAppDispatch();
    const [isCreatePopUpOpen, setIsCreatePopUpOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [columns, setColumns] = useState<ActivitiesTableColumn[]>(
      activitiesTableColumns
    );
    const [selectedActivity, setSelectedActivity] = useState<IEvent>();
    const [tabsShowed, setTabsShowed] = useState<TabName[]>([]);
    const newActivity = useCreateActivity(selectedActivity);

    function openEditActivityPopUp(event: IEvent) {
      setSelectedActivity(event);
      setShowDetails(true);
      setIsCreatePopUpOpen(true);
    }

    function openCreateActivityPopUp() {
      setIsEditing(false);
      setShowDetails(false);
      setIsCreatePopUpOpen(true);
    }

    function closeActivityPopUp() {
      newActivity.action.resetFields();
      setIsCreatePopUpOpen(false);
      setIsEditing(false);
      setIsEditing(false);
      setSelectedActivity(null);
    }

    function sortByColumn(
      columnName: ActivitiesTableColumnName,
      sortOrder: 'ASC' | 'DESC'
    ) {
      const newColumns: ActivitiesTableColumn[] = getOrderedColumns(
        columns,
        columnName,
        sortOrder
      );

      setColumns(newColumns);

      dispatch(sortActivitiesByColumn({ columnName, sortOrder }));
    }

    async function handleCreateActivity() {
      const activity = newActivity.action.getActivity();
      await dispatch(addActivity(activity));
      closeActivityPopUp();
    }

    async function handleDeleteActivity(activityId: string) {
      dispatch(setDeletingActivityStatus(activityId));
      await dispatch(deleteActivity(activityId));
      dispatch(removeDeletingActivityStatus(activityId));
      setShowDetails(false);
      setIsEditing(false);
      setIsCreatePopUpOpen(false);
      setSelectedActivity(null);
    }

    async function handleEndActivity(activityId: string) {
      dispatch(setEndingActivityStatus(activityId));
      await dispatch(endActivity(activityId));
      dispatch(removeEndingActivityStatus(activityId));
      setShowDetails(false);
      setIsEditing(false);
      setIsCreatePopUpOpen(false);
      setSelectedActivity(null);
    }
    async function handleUpdateActivity() {
      const activity = newActivity.action.getActivity();
      await dispatch(
        updateActivity({
          updatedActivity: activity,
          activityId: selectedActivity.id,
        })
      );
      closeActivityPopUp();
    }

    const value: ActivitiesCardContextType = {
      sortByColumn,
      columns,
      openCreateActivityPopUp,
      closeActivityPopUp,
      openEditActivityPopUp,
      isCreatePopUpOpen,
      newActivity,
      handleCreateActivity,
      handleUpdateActivity,
      handleDeleteActivity,
      handleEndActivity,
      isEditing,
      setIsEditing,
      showDetails,
      setShowDetails,
      selectedActivity,
      tabsShowed,
      setTabsShowed,
    };

    return (
      <ActivitiesCardContext.Provider value={value}>
        {children}
      </ActivitiesCardContext.Provider>
    );
  }
);

export default ActivitiesCardContextProvider;
