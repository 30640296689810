import { useCallback, useContext, useEffect, useMemo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Background,
  Edge,
  Connection,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
  OnConnect,
  MiniMap,
  Controls,
  MarkerType,
  NodeTypes,
  DefaultEdgeOptions,
  Node,
  useOnSelectionChange,
} from 'reactflow';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Interface
import { NodeType, NodeData } from '@trii/types/dist/Conversations/Flows';
// Components
import { Box } from '@mui/material';
import {
  ApiCustomNode,
  AssignToCustomNode,
  ConditionalCustomNode,
  ContactSearchCustomNode,
  ContactUpdateCustomNode,
  ConversationUpdateCustomNode,
  EmailCustomNode,
  EndCustomNode,
  FillFormCustomNode,
  FillTicketCustomNode,
  RedirectCustomNode,
  SqlQueryCustomNode,
  StartCustomNode,
  TextCustomNode,
  TimeScheduleControlCustomNode,
  TimeTimerCustomNode,
  TimeWaitUntilCustomNode,
  WaitResponseCustomNode,
} from './components';
import 'reactflow/dist/style.css';
// Utils
import { getData } from './utils';

const minimapStyle = {
  height: 120,
};

const getId = () => `${Math.random().toString(36).substr(2, 9)}`;

const FlowContext = () => {
  const nodeTypes: NodeTypes = useMemo(
    () => ({
      [NodeType.START]: StartCustomNode,
      [NodeType.MSG_TEXT]: TextCustomNode,
      [NodeType.MSG_WAIT_RESPONSE]: WaitResponseCustomNode,
      [NodeType.SQL_QUERY]: SqlQueryCustomNode,
      [NodeType.SEND_EMAIL]: EmailCustomNode,
      [NodeType.CONVERSATION_ASSIGN_TO]: AssignToCustomNode,
      [NodeType.FILL_FORM]: FillFormCustomNode,
      [NodeType.CONVERSATION_FINALIZE]: EndCustomNode,
      [NodeType.REDIRECT]: RedirectCustomNode,
      [NodeType.FILL_TICKET]: FillTicketCustomNode,
      [NodeType.CONTACT_SEARCH]: ContactSearchCustomNode,
      [NodeType.CONTACT_UPDATE]: ContactUpdateCustomNode,
      [NodeType.CONVERSATION_UPDATE]: ConversationUpdateCustomNode,
      [NodeType.CONDITIONAL]: ConditionalCustomNode,
      [NodeType.API]: ApiCustomNode,
      [NodeType.TIME_SCHEDULE_CONTROL]: TimeScheduleControlCustomNode,
      [NodeType.TIME_TIMER]: TimeTimerCustomNode,
      [NodeType.TIME_WAIT_UNTIL]: TimeWaitUntilCustomNode,
    }),
    []
  );
  const defaultEdgeOptions: DefaultEdgeOptions = {
    markerEnd: { type: MarkerType.Arrow },
  };
  const {
    nodes,
    setNodes,
    edges,
    setEdges,
    reactFlowWrapper,
    reactFlowInstance,
    setReactFlowInstance,
    setSelectNode,
    setShowService,
    setEditPanelOpen,
    editPanelOpen,
    resetStartField,
    setStartNode,
    startNode,
    setTextNode,
    resetTextField,
    textNode,
    resetMsgWaitResponseField,
    setMsgWaitResponseNode,
    msgWaitResponseNode,
    resetSqlQueryField,
    setSqlQueryNode,
    sqlQueryNode,
    resetSendEmailField,
    setSendEmailNode,
    sendEmailNode,
    resetAssignToField,
    setAssignToNode,
    assignToNode,
    resetFillFormField,
    setFillFormNode,
    fillFormNode,
    resetRedirectField,
    setRedirectNode,
    redirectNode,
    resetFillTicketField,
    setFillTicketNode,
    fillTicketNode,
    resetContactSearchField,
    setContactSearchNode,
    contactSearchNode,
    resetContactUpdateField,
    setContactUpdateNode,
    contactUpdateNode,
    resetConversationUpdateField,
    setConversationUpdateNode,
    conversationUpdateNode,
    resetConditionalField,
    setConditionalNode,
    conditionalNode,
    resetApiField,
    setApiNode,
    apiNode,
    resetTimeScheduleControlField,
    setTimeScheduleControlNode,
    timeScheduleControlNode,
    resetTimeTimerField,
    setTimeTimerNode,
    timeTimerNode,
    timeWaitUntilNode,
    setTimeWaitUntilNode,
    resetTimeWaitUntilField,
    setSelectNodesId,
    setSelectEdgesId,
    resetEndField,
    setEndNode,
    endNode,
  } = useContext<EditFlowsContext>(InitEditFlowsContext);

  useOnSelectionChange({
    onChange: ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }): void => {
      if (!nodes.length) {
        setSelectNode(null);
      }
      const nodesId = nodes.map((node) => node.id);
      const edgesId = edges.map((edge) => edge.id);
      setSelectNodesId(nodesId);
      setSelectEdgesId(edgesId);
    },
  });

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect: OnConnect = useCallback(
    (params: Edge | Connection) => {
      setEdges((els) => addEdge(params, els));
    },
    [setEdges]
  );
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const id = getId();
      const dataInfo = {
        start: startNode,
        msgText: textNode,
        msgWaitResponse: msgWaitResponseNode,
        sqlQuery: sqlQueryNode,
        sendEmail: sendEmailNode,
        conversationAssignTo: assignToNode,
        fillForm: fillFormNode,
        redirect: redirectNode,
        fillTicket: fillTicketNode,
        contactSearch: contactSearchNode,
        contactUpdate: contactUpdateNode,
        conversationUpdate: conversationUpdateNode,
        conditional: conditionalNode,
        api: apiNode,
        timeScheduleControl: timeScheduleControlNode,
        timeTimer: timeTimerNode,
        timeWaitUntil: timeWaitUntilNode,
        conversationFinalize: endNode,
      };
      const dataType = getData(Number(type), dataInfo);
      const newNode = {
        id,
        type,
        position,
        data: dataType,
      };
      resetStartField();
      resetTextField();
      resetMsgWaitResponseField();
      resetSqlQueryField();
      resetSendEmailField();
      resetAssignToField();
      resetFillFormField();
      resetRedirectField();
      resetFillTicketField();
      resetContactSearchField();
      resetContactUpdateField();
      resetConversationUpdateField();
      resetConditionalField();
      resetApiField();
      resetTimeScheduleControlField();
      resetTimeTimerField();
      resetTimeWaitUntilField();
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes, reactFlowWrapper]
  );

  const handleSelectNode = (node: Node<NodeData>) => {
    setSelectNode(node);
    if (node && Number(node.type) === NodeType.START) {
      resetStartField();
      setStartNode({ ...node.data.start });
    }
    if (node && Number(node.type) === NodeType.MSG_TEXT) {
      resetTextField();
      setTextNode({ ...node.data.msgText });
    }
    if (node && Number(node.type) === NodeType.MSG_WAIT_RESPONSE) {
      resetMsgWaitResponseField();
      setMsgWaitResponseNode({ ...node.data.msgWaitResponse });
    }
    if (node && Number(node.type) === NodeType.SQL_QUERY) {
      resetSqlQueryField();
      setSqlQueryNode({ ...node.data.sqlQuery });
    }
    if (node && Number(node.type) === NodeType.SEND_EMAIL) {
      resetSendEmailField();
      setSendEmailNode({ ...node.data.sendEmail });
    }
    if (node && Number(node.type) === NodeType.CONVERSATION_ASSIGN_TO) {
      resetAssignToField();
      setAssignToNode({ ...node.data.conversationAssignTo });
    }
    if (node && Number(node.type) === NodeType.FILL_FORM) {
      resetFillFormField();
      setFillFormNode({ ...node.data.fillForm });
    }
    if (node && Number(node.type) === NodeType.REDIRECT) {
      resetRedirectField();
      setRedirectNode({ ...node.data.redirect });
    }
    if (node && Number(node.type) === NodeType.FILL_TICKET) {
      resetFillTicketField();
      setFillTicketNode({ ...node.data.fillTicket });
    }
    if (node && Number(node.type) === NodeType.CONTACT_SEARCH) {
      resetContactSearchField();
      setContactSearchNode({ ...node.data.contactSearch });
    }
    if (node && Number(node.type) === NodeType.CONTACT_UPDATE) {
      resetContactUpdateField();
      setContactUpdateNode({ ...node.data.contactUpdate });
    }
    if (node && Number(node.type) === NodeType.CONVERSATION_UPDATE) {
      resetConversationUpdateField();
      setConversationUpdateNode({ ...node.data.conversationUpdate });
    }
    if (node && Number(node.type) === NodeType.CONDITIONAL) {
      resetConditionalField();
      setConditionalNode({ ...node.data.conditional });
    }
    if (node && Number(node.type) === NodeType.API) {
      resetApiField();
      setApiNode({ ...node.data.api });
    }
    if (node && Number(node.type) === NodeType.TIME_SCHEDULE_CONTROL) {
      resetTimeScheduleControlField();
      setTimeScheduleControlNode({ ...node.data.timeScheduleControl });
    }
    if (node && Number(node.type) === NodeType.TIME_TIMER) {
      resetTimeTimerField();
      setTimeTimerNode({ ...node.data.timeTimer });
    }
    if (node && Number(node.type) === NodeType.TIME_WAIT_UNTIL) {
      resetTimeWaitUntilField();
      setTimeWaitUntilNode({ ...node.data.timeWaitUntil });
    }
    if (node && Number(node.type) === NodeType.CONVERSATION_FINALIZE) {
      resetEndField();
      setEndNode({ ...node.data.conversationFinalize });
    }
    if (!editPanelOpen) {
      setEditPanelOpen(true);
    }
  };

  return (
    <>
      <Box
        ref={reactFlowWrapper}
        className="flowPattern"
        sx={{
          '&.flowPattern + div div.react-flow__controls button': {
            //@ts-ignore
            backgroundColor: (theme) => theme.palette.background.dropdownMenu,
          },
          '&.flowPattern + div div.react-flow__controls button svg': {
            //@ts-ignore
            fill: (theme) => theme.palette.text.primary,
          },
          '&.flowPattern + div div.react-flow__minimap svg': {
            //@ts-ignore
            background: (theme) => theme.palette.background.mainCenter,
          },
          '&.flowPattern + div div.react-flow__minimap svg rect': {
            //@ts-ignore
            fill: (theme) => theme.palette.text.disabled,
          },
          '&.flowPattern + div div div div div.react-flow__node div': {
            //@ts-ignore
            backgroundColor: (theme) => theme.palette.background.dropdownMenu,
          },
        }}
      />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        fitView
        nodeTypes={nodeTypes}
        onNodeClick={(event, element) => handleSelectNode(element)}
        defaultEdgeOptions={defaultEdgeOptions}
        // deleteKeyCode={null}
      >
        <MiniMap style={minimapStyle} zoomable pannable />
        <Controls />
        <Background />
      </ReactFlow>
    </>
  );
};

const Flow = () => {
  return (
    <ReactFlowProvider>
      <FlowContext />
    </ReactFlowProvider>
  );
};

export default Flow;
