import { useContext } from 'react';
// Redux
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
// Types
import { ScheduleContext } from 'features/Views/Schedules/context/types';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components/index';

const Panel = () => {
  const { createPanelOpen, deletePanelOpen } =
    useContext<ScheduleContext>(InitScheduleContext);

  return (
    <>
      {
        (createPanelOpen && <CreatePanel />) ||
        (deletePanelOpen && <DeletePanel />)
      }
    </>
  );
};

export default Panel;
