import { useContext, useEffect, useMemo } from 'react';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { Box } from '@mui/material';
// Components
import { ConversationItem } from './components';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

type ConversationListProps = {
  handleSelectConversation: (
    conversation: IConversation,
    incognito?: boolean
  ) => void;
  shortConversations: IConversation[];
  clientConversations: IConversation[];
};

const ConversationList = ({
  handleSelectConversation,
  shortConversations,
  clientConversations,
}: ConversationListProps) => {
  const { setIsSearching, setIsReplying, setMessage } = useContext(messagesContext);
  const { conversationsDb } = useContext(conversationsContext);

  const { getUserInfo } = useUserInfo();

  const handleSelect = (conversation: IConversation, incognito: boolean) => {
    handleSelectConversation(conversation, incognito);
    setIsSearching(false);
    setIsReplying(false);
    setMessage(null);
  };

  const removeDuplicates = (arr: string[]) => {
    return arr?.filter((item, index) => arr.indexOf(item) === index);
  };

  useEffect(() => {
    const usersIds = conversationsDb
      ?.map((conversation) => conversation.participants.map((user) => user.id))
      .flat();

    if (!usersIds) return;
    const uniqueUsers = removeDuplicates(usersIds);

    uniqueUsers.map((userId) => getUserInfo(userId));
  }, [conversationsDb]);

  if (!clientConversations) return null;

  return useMemo(
    () => (
      <Box
        width="100%"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
          position: 'relative',
          paddingBottom: '70px', // Espacio al final de la lista
        }}
      >
        {shortConversations.map((conversation, index) => (
          <ConversationItem
            key={index}
            conversation={conversation}
            handleSelectConversation={handleSelect}
          />
        ))}
      </Box>
    ),
    [shortConversations, handleSelect]
  );
};

export default ConversationList;
