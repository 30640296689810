import { useState, useEffect, useRef, useContext } from 'react';
// Context
import { DateTimeContext } from 'context/DateTime/DateTimeContext';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { AudioMessagesProvider } from './context/AudioMessagesProvider/AudioMessagesProvider';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
import {
  getTenorGifts,
  getStickers,
} from 'redux/features/conversationSlice/conversationSlice';
//Components/ui
import { Box, Slide, IconButton } from '@mui/material';
import {
  Close,
  Gif as GifIcon,
  Mood,
  Mic,
  PhotoFilter,
  Send,
} from '@mui/icons-material';
import { AttachMediaDial, AudioBox, Gif, Stickers } from './components';
import { Emojis, Input, ReplyMessage } from 'components';
// Types
import {
  MessageAck,
  MessageDirection,
  MessageType,
} from '@trii/types/dist/Common/Messages';
// ID
import ObjectID from 'bson-objectid';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { dbWorker } from 'db/db';

type Props = {
  closeAction: boolean;
  setCloseAction: (value: boolean) => void;
  disabled?: boolean;
};

const ChatMode = ({ closeAction, setCloseAction, disabled }: Props) => {
  const {
    isReplying,
    recordAudioMode,
    recordAudioStream,
    messageReply,
    showActions,
    endRecordAudioMode,
    sendMessage,
    setIsReplying,
    setMessageReply,
    setShowActions,
    startRecordAudioMode,
    handleSaveTextDraftInDB,
    textDraft,
  } = useContext(messagesContext);
  const { datetime } = useContext(DateTimeContext);

  const conversationSelected = useSelector(selectConversationSelected);

  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const [value, setValue] = useState<string>('');
  const [showEmoji, setShowEmoji] = useState<boolean>(true);
  const [showGif, setShowGif] = useState<boolean>(false);
  const [showStickers, setShowStickers] = useState<boolean>(false);
  const [newEmoji, setNewEmoji] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const spaceInfo = useSelector(selectSpaceInfo);
  const userInfo = useSelector(selectUser);

  const valueRef = useRef(value);

  const handleCloseReplying = () => {
    setIsReplying(false);
    setMessageReply(null);
  };

  const handleShowGif = () => {
    setShowGif(true);
    setShowEmoji(false);
    setShowStickers(false);
  };

  const handleShowStickers = () => {
    setShowStickers(true);
    setShowEmoji(false);
    setShowGif(false);
  };

  const handleEmojiSelect = (emoji) => {
    setNewEmoji(emoji.native);
  };

  const handleShowActions = () => {
    if (!showEmoji && !showActions) {
      setShowActions(true);
    } else if (!showEmoji && showActions) {
      setShowEmoji(true);
      setShowGif(false);
      setShowStickers(false);
    } else if (showEmoji && !showActions) {
      setShowActions(true);
    }
  };

  const handleSendMessage = (value: string) => {
    if (!value) return;

    if (showEmoji) setShowEmoji(false);

    // Delete draft sending the message "deleteDraftForConversation"
    dbWorker.postMessage({
      action: 'deleteDraftForConversation',
      data: conversationSelected.id,
    });

    const messageReference = {
      messageId: '',
      externalId: '',
      channelId: '',
    };

    if (messageReply) {
      messageReference.messageId = messageReply.id;
      messageReference.externalId = messageReply.externalId;
      messageReference.channelId = messageReply.channelInfo?.id;
    }

    const newMessage = {
      id: ObjectID().toString(),
      spaceId: spaceInfo.id,
      conversationId: conversationSelected.id,
      timestamp: datetime.getDateTime(),
      userId: userInfo.uid,
      channelInfo: conversationSelected.channelInfo,
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      mentions: null,
      direction: MessageDirection.OUT,
      ack: MessageAck.ACK_PENDING,
      ackLogs: [],
      forwarded: false,
      remoteDeleted: false,
      type: MessageType.CHAT,
      text: {
        body: value,
        previewUrl: false,
      },
      audio: null,
      messageReference: messageReply ? messageReference : null,
      deleted: false,
      isLoaded: true,
      isHighlighted: false,
    };

    sendMessage(newMessage);
    setMessageReply(null);
    setIsReplying(false);
    setValue('');
  };

  useEffect(() => {
    setValue('');
  }, [conversationSelected]);

  useEffect(() => {
    if (textDraft) {
      setValue(textDraft);
    }
  }, [textDraft]);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (value !== '') {
      const timer = setTimeout(async () => {
        await handleSaveTextDraftInDB(conversationSelected.id, value);
      }, 5000);

      return () => clearTimeout(timer);
    } else if (value === '' && textDraft !== '') {
      dbWorker.postMessage({
        action: 'deleteDraft',
        data: conversationSelected.id,
      });
    }
  }, [value]);

  useEffect(() => {
    return () => {
      handleSaveTextDraftInDB(conversationSelected.id, valueRef.current);
    };
  }, []);

  useEffect(() => {
    if (closeAction) {
      setShowActions(false);
      setCloseAction(false);
    }
  }, [closeAction]);

  return (
    <Box width="100%" maxWidth="100%" ref={containerRef}>
      {recordAudioMode ? (
        <AudioMessagesProvider
          recordAudioStream={recordAudioStream}
          endRecordAudioMode={endRecordAudioMode}
        >
          <Slide
            direction="right"
            in={recordAudioMode}
            container={containerRef.current}
          >
            <Box>
              <AudioBox />
            </Box>
          </Slide>
        </AudioMessagesProvider>
      ) : (
        <Box display="flex" flexDirection="column" gap="0.5rem">
          {showActions && (
            <Box display="flex" width="100%" maxHeight="20rem">
              <Slide
                direction="up"
                in={showActions}
                container={containerRef.current}
              >
                <Box height="100%" width="100%" maxHeight="inherit">
                  {showEmoji && <Emojis handleEmojiSelect={handleEmojiSelect} />}
                  {showGif && <Gif />}
                  {showStickers && <Stickers />}
                </Box>
              </Slide>
            </Box>
          )}
          {isReplying && (
            <Slide direction="up" in={isReplying} container={containerRef.current}>
              <Box
                height="100%"
                width="100%"
                maxHeight="inherit"
                display="flex"
                alignItems="center"
              >
                <ReplyMessage
                  messageReply={messageReply}
                  handleClose={handleCloseReplying}
                  isReplying={isReplying}
                />
              </Box>
            </Slide>
          )}
          <Box display="flex" alignItems="end" width="100%">
            {showActions && (
              <IconButton
                onClick={() => setShowActions(false)}
                sx={{ color: 'text.disabled' }}
              >
                <Close />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleShowActions()}
              sx={{ color: 'text.disabled' }}
            >
              <Mood color={showEmoji && showActions ? 'primary' : 'inherit'} />
            </IconButton>
            {showActions && (
              <>
                <IconButton
                  onClick={() => handleShowGif()}
                  sx={{ color: 'text.disabled' }}
                >
                  <GifIcon color={showGif && showActions ? 'primary' : 'inherit'} />
                </IconButton>
                <IconButton
                  sx={{ color: 'text.disabled' }}
                  onClick={() => handleShowStickers()}
                >
                  <PhotoFilter
                    color={showStickers && showActions ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </>
            )}
            <AttachMediaDial disabled={disabled} />
            <Box
              display="flex"
              alignItems="end"
              maxWidth="100%"
              width="inherit"
              flexWrap="wrap"
              margin="auto"
            >
              <Input
                value={value}
                newEmoji={newEmoji}
                setValue={setValue}
                setNewEmoji={setNewEmoji}
                handleSendMessage={handleSendMessage}
                conversationId={conversationSelected?.id}
                contactId={conversationSelected?.contactInfo?.id}
              />
            </Box>
            {value ? (
              <IconButton
                onClick={() => handleSendMessage(value)}
                sx={{ color: 'text.disabled' }}
              >
                <Send />
              </IconButton>
            ) : (
              <IconButton
                onClick={startRecordAudioMode}
                sx={{ color: 'text.disabled' }}
              >
                <Mic />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatMode;
