import { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Componets/ui
import { Box, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
// Components
import { TextFormat, ImageFormat, FileFormat, ButtonList } from './components';
// utils
import formatList from './utils';
// MessageType
import { NodeMsgTextMessageType } from '@trii/types/dist/Conversations/Flows/Nodes';

const ManualMessage = () => {
  const { textNode, setTextNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [type, setType] = useState<number>(NodeMsgTextMessageType.TEXT);

  useEffect(() => {
    if (selectNode) {
      const { msgText } = selectNode.data;
      if (msgText && msgText.message && msgText.message.type) {
        const defaultType = msgText.message.type
          ? msgText.message.type
          : NodeMsgTextMessageType.TEXT;
        setType(defaultType);
      }
    }
  }, [selectNode]);

  useEffect(() => {
    setTextNode({
      ...textNode,
      message: { ...textNode.message, type },
    });
  }, []);

  const handleChangeFormat = (value: number) => {
    setType(value);
    setTextNode({
      ...textNode,
      message: { ...textNode.message, type: value },
    });
  };

  return (
    <Box marginTop={3}>
      <FormControl fullWidth size="small">
        <InputLabel id="format" shrink={true}>
          {t('editFlowsView.text.manualMessage.format')}
        </InputLabel>
        <Select
          labelId="format"
          id="demo-simple-select"
          value={type}
          label={t('editFlowsView.text.manualMessage.format')}
          onChange={(e) => handleChangeFormat(e.target.value as number)}
          sx={{
            "& .MuiSvgIcon-root": {
              color: 'text.disabled'            
            }
          }}
        >
          {formatList.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {t(item.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box marginTop={2} position="relative">
        {type === NodeMsgTextMessageType.TEXT && <TextFormat />}
        {type === NodeMsgTextMessageType.IMAGE && (
          <ImageFormat
            title={t('editFlowsView.text.manualMessage.selectImage')}
            chargeLabel={t('editFlowsView.text.manualMessage.charge')}
            fieldLabel={t('editFlowsView.text.manualMessage.imageText')}
          />
        )}
        {type === NodeMsgTextMessageType.VIDEO && (
          <ImageFormat
            title={t('editFlowsView.text.manualMessage.selectVideo')}
            chargeLabel={t('editFlowsView.text.manualMessage.charge')}
            fieldLabel={t('editFlowsView.text.manualMessage.videoText')}
          />
        )}
        {type === NodeMsgTextMessageType.FILE && <FileFormat />}
        <ButtonList />
      </Box>
    </Box>
  );
};

export default ManualMessage;
