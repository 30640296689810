import React from 'react';
import { useTranslation } from 'react-i18next';
import { icons } from './utils/icons';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeWidgetCalloutTimer,
  changeWidgetCalloutTitle,
  changeWidgetCalloutMessage,
  changeWidgetCalloutIcon,
} from 'redux/features/webChatEditSlice/webChatEditSlice';
// Components/ui
import OptionsContainer from '../../../OptionsContainer';
import { TextField, Select, MenuItem } from '@mui/material';

interface Props {
  widgetCalloutIcon: string | null;
  widgetCalloutMessage: string;
  widgetCalloutTitle: string;
  widgetCalloutTimer: number;
}

const TimerOptions = ({
  widgetCalloutIcon,
  widgetCalloutMessage,
  widgetCalloutTitle,
  widgetCalloutTimer,
}: Props) => {
  const { t } = useTranslation();

  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const handleWidgetCalloutTimerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeWidgetCalloutTimer(Number(event.target.value)));
  };
  const handleWidgetCalloutTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeWidgetCalloutTitle(event.target.value));
  };
  const handleWidgetCalloutMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeWidgetCalloutMessage(event.target.value));
  };
  const handleWidgetCalloutIconChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeWidgetCalloutIcon(event.target.value));
  };

  return (
    <OptionsContainer
      title={t('webChatEditView.designPanel.widgetOptionSection.timer')}
    >
      <TextField
        size="small"
        fullWidth
        label={t('webChatEditView.designPanel.widgetOptionSection.time')}
        type="number"
        value={widgetCalloutTimer}
        onChange={handleWidgetCalloutTimerChange}
      />
      <TextField
        size="small"
        fullWidth
        label={t('webChatEditView.designPanel.widgetOptionSection.calloutTitle')}
        value={widgetCalloutTitle}
        onChange={handleWidgetCalloutTitleChange}
      />
      <TextField
        size="small"
        fullWidth
        label={t('webChatEditView.designPanel.widgetOptionSection.calloutMessage')}
        value={widgetCalloutMessage}
        onChange={handleWidgetCalloutMessageChange}
      />
      <Select
        fullWidth
        onChange={handleWidgetCalloutIconChange}
        value={widgetCalloutIcon}
        size="small"
        MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: theme => theme.palette.background.panel,
              },
            },
          }}
      >
        {icons.map((icon) => (
          <MenuItem key={icon.value} value={icon.value}>
            {icon.name}
          </MenuItem>
        ))}
      </Select>
    </OptionsContainer>
  );
};

export default TimerOptions;
