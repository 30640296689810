import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CognitoRefreshToken,
  CookieStorage,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
// Types
import { RootState } from '../../rootReducer';
// Helper
import sessionHelper from './utils/sessionHelper';

const initialState = {
  session: null,
  status: {
    setSession: 'idle',
  },
};

export const setSession = createAsyncThunk<
  CognitoUserSession,
  void,
  { state: RootState }
>('session/setSession', async (_, { getState }) => {
  return new Promise((resolve, reject) => {
    const { pool } = getState().Pool;
    const user = pool.getCurrentUser();

    if (user) {
      user.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(sessionHelper.checkSessionValidity(user, session));
        }
      });
    } else {
      reject(false);
    }
  });
});

export const logout = createAsyncThunk<void, void, { state: RootState }>(
  'session/logout',
  async (_, { getState }) => {
    const { pool } = getState().Pool;
    const user = pool.getCurrentUser();

    if (user) {
      user.signOut();

      window.location.href = `${
        window.location.protocol
      }//${window.location.host.replace('account.', '')}/signin`;
    }
  }
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setSession.pending, (state) => {
        state.status.setSession = 'loading';
      })
      .addCase(setSession.fulfilled, (state, action) => {
        const session = action.payload;
        // console.log('success fetching session: ', session);

        state.status.setSession = 'succeeded';
        state.session = session;
      })
      .addCase(setSession.rejected, (state, action) => {
        console.log('error fetching session: ', action.error);
        state.status.setSession = 'failed';
        const encodedUrlPath = encodeURIComponent(window.location.pathname);

        window.location.href = `${
          window.location.protocol
        }//${window.location.host.replace(
          'account.',
          ''
        )}/signin?redirect=${encodedUrlPath}`;
      });
  },
});

//selectors
export const selectSession = (state: RootState) => state.Session.session;
export const getSessionStatus = (state: RootState) =>
  state.Session.status.setSession;

export default sessionSlice.reducer;
