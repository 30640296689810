import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseCreateWhatsApp } from 'features/Views/WhatsApp/hooks/useCreateWhatsApp/types';
// Components/ui
import { Box, TextField } from '@mui/material';

interface CreateInputsProps {
  createWhatsAppState: UseCreateWhatsApp;
}

const CreateInputs = ({ createWhatsAppState }: CreateInputsProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
      gap={2}
    >
      <TextField
        {...createWhatsAppState.input.nameField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        label={t('global.name')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        {...createWhatsAppState.input.descriptionField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={8}
        label={t('global.description')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default CreateInputs;
