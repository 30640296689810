// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import { Dispatch, SetStateAction } from "react"

import { AddBox } from "@mui/icons-material"
const DATE_FORMAT = "DD/MM/YYYY"

interface Props {
  startDate: Dayjs
  endDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
}

const CustomDateRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  const { t } = useTranslation()

  const handleChangeDate = (setState: Dispatch<SetStateAction<Dayjs>>) => (newDate: Dayjs) => {
    setState(newDate)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        gap: "1rem",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format={DATE_FORMAT}
          slotProps={{
            textField: () => ({
              label: t("global.from"),
              size: "small",
              className: "smallInput",
            }),
          }}
          value={startDate}
          onChange={handleChangeDate(setStartDate)}
        />
        <DatePicker
          format={DATE_FORMAT}
          slotProps={{
            textField: () => ({
              label: t("global.to"),
              size: "small",
              className: "smallInput",
              width: "50%"
            }),
          }}
          value={endDate}
          onChange={handleChangeDate(setEndDate)}
        />
      </LocalizationProvider>
    </Box>
  )
}

export default CustomDateRange