import { useContext, useEffect, useState } from 'react';
// Context
import { TemplatesContext } from './TemplatesContext';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Types
import { EditionType } from './types/EditionType';
import { ChannelList, TemplatesList } from './types/Lists';
import { FocusType } from './types/FocusType';
import { TemplateVar, TemplateVarButton } from '@trii/types/dist/Common/Messages';
// Redux
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { postTemplate } from 'redux/features/templatesSlice/templatesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { dbWorker } from 'db/db';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { NodeMsgTextTemplate } from '@trii/types/dist/Conversations/Flows/Nodes';

interface TemplatesProviderProps {
  children: React.ReactNode;
}

export const TemplatesProvider: React.FC<TemplatesProviderProps> = ({
  children,
}) => {
  const [focusType, setFocusType] = useState<FocusType>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editionType, setEditionType] = useState<EditionType>(null);
  const [channel, setChannel] = useState(null);
  const [template, setTemplate] = useState(null);
  const [channelList, setChannelList] = useState<ChannelList[]>([]);
  const [templateList, setTemplateList] = useState<TemplatesList[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<ChannelList | null>(null);
  // Template
  const [selectedTemplate, setSelectedTemplate] = useState<TemplatesList | null>(
    null
  );
  const [localBodyVariables, setLocalBodyVariables] = useState<TemplateVar[]>(null);
  const [localHeaderVariables, setLocalHeaderVariables] =
    useState<TemplateVar[]>(null);
  const [localButtons, setLocalButtons] = useState<TemplateVarButton[]>(null);

  const updateChannel = () => {};
  const updateTemplate = (id: string, data: TemplatesList) => {
    const updatedTemplateList = templateList.map((template) =>
      template.id === id ? { ...template, ...data } : template
    );

    setTemplateList(updatedTemplateList);

    setSelectedTemplate(data);
  };

  function convertBodyHeadVarsNameMinusOne(vars: TemplateVar[] | null) {
    if (!vars || vars.length === 0) {
      return [];
    }

    return vars.map((variable) => {
      return {
        var: Number(variable.name.replace(/{{|}}/g, '')),
        value: variable.value,
      };
    });
  }

  function convertButtonsVars(buttonsVars: TemplateVarButton[] | null) {
    if (!buttonsVars || buttonsVars.length === 0) {
      return [];
    }

    return buttonsVars.flatMap((button) => {
      return button.vars.map((variable) => {
        return {
          btn: button.btnNumber - 1,
          var: Number(variable.name.replace(/{{|}}/g, '')),
          value: variable.value,
        };
      });
    });
  }
  //   export declare class NodeMsgTextTemplate {
  //     id: string | null;
  //     headerVars?: templateVariable[] | null;
  //     bodyVars?: templateVariable[] | null;
  //     buttonVars?: templateVariable[] | null;
  // }
  // export declare class templateVariable {
  //   var?: number;
  //   value: string;
  //   btn?: number;
  // }
  function getEditedTemplate() {
    const template: NodeMsgTextTemplate = {
      id: selectedTemplate.id,
      headerVars: convertBodyHeadVarsNameMinusOne(localHeaderVariables),
      bodyVars: convertBodyHeadVarsNameMinusOne(localBodyVariables),
      buttonVars: convertButtonsVars(localButtons),
    };
    return template;
  }

  function cleanUp() {
    setSelectedTemplate(null);
  }

  useEffect(() => {
    setLocalBodyVariables(selectedTemplate?.varsBody);
    setLocalHeaderVariables(selectedTemplate?.varsHeader);
    setLocalButtons(selectedTemplate?.varsButtons);
  }, [selectedTemplate]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <TemplatesContext.Provider
      value={{
        editionType,
        setEditionType,
        updateChannel,
        updateTemplate,
        channelList,
        setChannelList,
        channel,
        setChannel,
        templateList,
        setTemplateList,
        template,
        setTemplate,
        selectedChannel,
        setSelectedChannel,
        selectedTemplate,
        setSelectedTemplate,
        focusType,
        setFocusType,
        isEditing,
        setIsEditing,
        // Template state
        localBodyVariables,
        setLocalBodyVariables,
        localHeaderVariables,
        setLocalHeaderVariables,
        localButtons,
        setLocalButtons,
        getEditedTemplate,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};
