import { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import { UseFieldType } from 'hooks/useField';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createFacebook,
  deleteFacebook,
} from 'redux/features/facebookSlice/facebookSlice';
// Types
import { FacebookContext } from './types';
import { NewFacebook } from 'redux/features/facebookSlice/types/NewFacebook';

export const facebookContext = createContext<FacebookContext>({
  deletePanelState: false,
  handleCreateFacebook: (data: NewFacebook) => { },
  openDelete: () => { },
  endDeleting: () => { },
  selectedFacebook: null,
  deleteFacebookName: fieldInitialDef,
  handleDeleteFacebook: () => { },
  navigateToEdit: () => { },
});

const FacebookProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedFacebook, setSelectedFacebook] = useState<any>(null);
  // Delete state
  const deleteFacebookName: UseFieldType = useField('text');
  // Drawer state
  const [deletePanelState, setDeletePanelState] = useState<boolean>(false);

  // Create functions
  const handleCreateFacebook = async (data: NewFacebook) => {
    await dispatch(createFacebook(data));
  };
  // end Create functions

  // Delete functions
  const handleDeleteFacebook = async () => {
    const { id } = selectedFacebook;

    await dispatch(deleteFacebook(id));
    endDeleting();
  };

  const openDelete = (facebook: any) => {
    setSelectedFacebook(facebook);
    setDeletePanelState(true);
  };
  const endDeleting = () => {
    deleteFacebookName.actions.resetValue();
    setSelectedFacebook(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (facebookId: string) => {
    navigate(`/a/conversations/system/facebook/${facebookId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setDeletePanelState(false);
  };
  // end Drawer functions

  return (
    <facebookContext.Provider
      value={{
        deletePanelState,
        handleCreateFacebook,
        openDelete,
        endDeleting,
        deleteFacebookName,
        selectedFacebook,
        handleDeleteFacebook,
        navigateToEdit,
      }}
    >
      {children}
    </facebookContext.Provider>
  );
};

export default FacebookProvider;
