import { useTranslation } from 'react-i18next';
// Types
import { WebChatConfig } from '@trii/types/dist/Common/Channels';
// Components
import { Box } from '@mui/material';
import DesignSection from '../DesignSection/DesignSection';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AlignWidgetOptions,
  WidgetDesignOptions,
  EnableCalloutOptions,
  TimerOptions,
} from './components';

interface Props {
  webChatData: WebChatConfig;
}

const WidgetOptionsSection = ({ webChatData }: Props) => {
  const { t } = useTranslation();

  return (
    <DesignSection
      title={t('webChatEditView.designPanel.widgetOptionSection.title')}
      icon={<SettingsIcon sx={{ color: 'primary.main', fontSize: 17 }} />}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={3}
        width={'100%'}
      >
        <AlignWidgetOptions widgetPosition={webChatData.widgetPosition} />
        <WidgetDesignOptions
          widgetShape={webChatData.widgetShape}
          widgetTitle={webChatData.widgetTitle}
          widgetSubtitle={webChatData.widgetSubTitle}
        />
        <EnableCalloutOptions calloutEnabled={webChatData.widgetCalloutEnabled} />
        <TimerOptions
          widgetCalloutIcon={webChatData.widgetCalloutIcon}
          widgetCalloutMessage={webChatData.widgetCalloutMessage}
          widgetCalloutTitle={webChatData.widgetCalloutTitle}
          widgetCalloutTimer={webChatData.widgetCalloutTimer}
        />
      </Box>
    </DesignSection>
  );
};

export default WidgetOptionsSection;
