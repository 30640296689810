import React from 'react';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PanelHeaderProps {
  title: string;
  handleCloseButton: () => void;
}

function PanelHeader({ title, handleCloseButton }: PanelHeaderProps) {
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Typography variant="subtitle2" sx={{color: (color) => color.palette.text.primary}}>{title}</Typography>
      <IconButton onClick={handleCloseButton}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
}

export default PanelHeader;
