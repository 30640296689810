import React from 'react';
// Components/ui
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  padding: number;
}

const ViewContainer = ({ children, padding }: Props) => {
  return (
    <Box
      className={'conversations-fadeinup '}
      p={0}
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      width={'100%'}
      sx={{backgroundColor: 'background.default'}}
      
    >
      {children}
    </Box>
  );
};

export default ViewContainer;
