import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { LoadingButton } from '@mui/lab';
import MercadoLibreLogoIcon from './components/MercadoLibreLogoIcon';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { createMercadoLibre } from 'redux/features/mercadoLibreSlice/mercadoLibreSlice';

const MercadoLibreButton = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleInitMercadoLibre = async () => {
    try {
      const responseUrl = await dispatch(createMercadoLibre(null));
      window.open(responseUrl.payload, '_self').focus();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      sx={{
        backgroundColor: 'transparent',
        color: (theme) => theme.palette.text.primary,
        boxShadow: 'none',
        border: '0.5px solid',
        borderColor: (theme) => theme.palette.divider,
        '&:hover': {
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
          boxShadow: 'none',
          border: '0.5px solid #dbeafe',
        },
        textTransform: 'none',
        fontSize: 14,
        fontFamily: 'Roboto',
      }}
      startIcon={<MercadoLibreLogoIcon size={22} />}
      onClick={handleInitMercadoLibre}
    >
      {t('mercadoLibreEditView.conectionPanel.connectWithMercadoLibre')}
    </LoadingButton>
  );
};

export default MercadoLibreButton;
