import React, { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { fetchGroup, fetchContacts } from 'redux/features/groupSlice/groupSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components
import GroupProvider from './context/GroupContext';
import { Box, Divider } from '@mui/material';
import { GroupContent, GroupHeader, SidePanel } from './components/index';
import AlertMessage from './components/AlertMessage/AlertMessage';

export default function Groups() {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchGroup());
      await dispatch(fetchContacts());
    };

    fetchData();
  }, [dispatch]);

  return (
    <GroupProvider>
      <Box display={'flex'} height={'100%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'90%'}
          width={'100%'}
          className={'conversations-fadeinup conversations-paddingContent'}
          borderRadius={2}
          mt={1}
          position="relative"
        >
          <GroupHeader />
          <Divider sx={{ marginTop: '10px', marginX: '10px' }} />
          <GroupContent />
        </Box>
        <SidePanel />
        <AlertMessage />
      </Box>
    </GroupProvider>
  );
}
