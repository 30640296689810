import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import { ChannelPanel } from 'components';
import { CopyBox } from './components';
import { FormConfig } from '@trii/types/dist/Common/Channels';

interface Props {
  Form_id: string;
}

const InstalationPanel = ({Form_id}: Props) => {
  const { t } = useTranslation();
  return (
    <ChannelPanel title={t('formEditView.installationPanel.name')}>
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h5" marginBottom={7}>
            {t('formEditView.installationPanel.title')}
          </Typography>
          <Typography variant="subtitle1">
            {t('formEditView.installationPanel.description')}
          </Typography>
        </Box>
        <CopyBox
          boxLabel={'Iframe:'}
          content={`<iframe src="https://agent.trii.app/forms/${Form_id}" style="width: 100%; height: 100%;" frameborder="0" marginwidth="0" marginheight="0">Cargando...<iframe>`}
        />
        <CopyBox
          boxLabel={t('formEditView.installationPanel.accessLink')}
          content={`https://agent.trii.app/forms/${Form_id}`}
        />
      </Box>
    </ChannelPanel>
  );
};

export default InstalationPanel;
