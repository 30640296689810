import { memo, useContext, useEffect, useState } from 'react';
import { Handle, Position, NodeToolbar, NodeProps } from 'reactflow';
// Translate
import { useTranslation } from 'react-i18next';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
import { StyleContext } from 'style/styleProvider';
// Components/ui
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  LinearProgress,
  TextField,
} from '@mui/material';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import { NodeData } from '@trii/types/dist/Conversations/Flows';
// Components
import { OutputResponse } from './components';
import { ITimeRange } from '@trii/types/dist/Common/Schedules';
const outputResponse = [
  'editFlowsView.timeScheduleControl.afterHours',
  'editFlowsView.timeScheduleControl.holiday',
];

function TimeScheduleControlCustomNode({ id, data, selected }: NodeProps<NodeData>) {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const {
    nodes,
    edges,
    setEdges,
    setSelectNode,
    setEditPanelOpen,
    setNodes,
    getScheduleById,
  } = useContext<EditFlowsContext>(InitEditFlowsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeRanges, setTimeRanges] = useState<ITimeRange[]>([]);
  const theme = useTheme();
  const { t } = useTranslation();
  const timeScheduleControl = data && data?.timeScheduleControl;

  const onNodesDelete = (id: string) => {
    const newNodes = nodes.filter((node) => node.id !== id);
    const newEdges = edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );
    setNodes(newNodes);
    setEdges(newEdges);
    setSelectNode(null);
    setEditPanelOpen(false);
  };

  const getTimeRange = async (scheduleId: string) => {
    setIsLoading(true);
    const response = await getScheduleById(scheduleId);
    const timeRanges = response?.timeRanges;
    setTimeRanges(timeRanges);
    setIsLoading(false);
  };

  useEffect(() => {
    if (timeScheduleControl) {
      getTimeRange(timeScheduleControl.scheduleId);
    }
  }, [timeScheduleControl]);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: shadow.lg,
        border: `1px solid ${
          selected ? theme.palette.primary.light : 'rgba(0,0,0,0.1)'
        }`,
        borderRadius: '.5rem',
        height: 'max-content',
        padding: '0.5rem',
        position: 'relative',
      }}
    >
      <NodeToolbar offset={-10}>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onNodesDelete(id)}
          sx={{
            '& :hover': {
              color: 'text.disabled',
            },
          }}
        >
          <ClearIcon />
        </IconButton>
      </NodeToolbar>
      <Handle type="target" position={Position.Left} id="1" />
      <Typography
        sx={{
          color: 'text.primary',
          height: '2rem',
        }}
        fontWeight={fontWeight.medium}
        fontSize={10}
      >
        {timeScheduleControl?.name
          ? data.timeScheduleControl.name
          : t('editFlowsView.timeScheduleControl.title')}
      </Typography>
      {isLoading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: '2rem',
            left: 0,
            width: '100%',
            height: '1px',
          }}
        />
      )}
      {timeRanges && timeRanges.length > 0 ? (
        timeRanges.map((timeRange) => (
          <OutputResponse
            key={timeRange.id}
            handleId={timeRange.id}
            label={timeRange.name}
          />
        ))
      ) : (
        <Typography
          variant="caption"
          sx={{
            color: 'text.disabled',
            height: '2rem',
          }}
          fontWeight={fontWeight.medium}
          fontSize={fontSize.sm}
        >
          {t('editFlowsView.timeScheduleControl.noTimeRanges')}
        </Typography>
      )}
      {outputResponse.map((label) => (
        <OutputResponse key={label} handleId={label} label={t(label)} />
      ))}
    </Box>
  );
}

export default memo(TimeScheduleControlCustomNode);
