import { useEffect, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import {
  DatePicker,
  DescriptionTextField,
  ReminderNotifications,
  ShareUsersSelect,
  TitleTextField,
  LinkContactsSelect,
  ActionButtons,
} from './components';
// Custom hooks
import { useActivitiesCardContext } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/hooks/useActivitiesCardContext';

const createEvent = () => {
  const { t } = useTranslation();
  const {
    isCreatePopUpOpen,
    closeActivityPopUp,
    newActivity,
    selectedActivity,
    isEditing,
    showDetails,
  } = useActivitiesCardContext();
  const [dialogTitle, setDialogTitle] = useState('');
  const {
    description,
    title,
    date: {
      state: { selectedEndDate, selectedStartDate, error },
      action: { handleSelect, setError, setSelectedEndDate, setSelectedStartDate },
    },
    notifications,
    shareWithUsers,
    linkContact,
  } = newActivity.field;

  useEffect(() => {
    if (
      selectedStartDate.isAfter(selectedEndDate) &&
      !selectedStartDate.isSame(selectedEndDate, 'minute')
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (showDetails && !isEditing) {
      setDialogTitle(t('conversations.sidebar.events.showEvent'));
    } else if (showDetails && isEditing) {
      setDialogTitle(t('conversations.sidebar.events.editEvent'));
    } else {
      setDialogTitle(t('conversations.sidebar.events.createEvent'));
    }
  }, [showDetails, isEditing]);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '50%' },
        }}
        maxWidth={false}
        open={isCreatePopUpOpen}
        onClose={closeActivityPopUp}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TitleTextField titleField={title} />
          {/* Event select field  */}
          <DescriptionTextField descriptionField={description} />
          <DatePicker
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            handleSelectStart={handleSelect(setSelectedStartDate)}
            handleSelectEnd={handleSelect(setSelectedEndDate)}
            isError={error}
          />
          {/* Reminder notifications */}
          <ReminderNotifications notificationsField={notifications} />
          {/* Calendar select */}
          <LinkContactsSelect linkContactsHandler={linkContact} />
          <ShareUsersSelect shareWithUsersField={shareWithUsers} />
        </DialogContent>
        <ActionButtons isFinalized={selectedActivity?.finalized} />
      </Dialog>
    </>
  );
};

export default createEvent;
