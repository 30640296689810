import { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Select, Box, MenuItem, FormControl, InputLabel } from '@mui/material';
// Interface
import { NodeMsgTextTemplate } from '@trii/types/dist/Conversations/Flows/Nodes';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectTemplateSelectValue,
  setTemplateSelectValue,
} from 'redux/features/editFlowSlice/editFlowSlice';
import { useSelector } from 'react-redux';
import {
  selectTemplateById,
  selectTemplates,
} from 'redux/features/templatesSlice/utils/selectors';
import { TemplateMessageViewer } from './components';
import { TemplatesContext } from '../../../../context/TemplatesContext';

const TemplateMessage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { textNode, setTextNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { setSelectedTemplate } = useContext(TemplatesContext);

  const templateSelectValue = useSelector(selectTemplateSelectValue);
  const templates = useSelector(selectTemplates);
  const selectedTemplate = useSelector(selectTemplateById(templateSelectValue));

  useEffect(() => {
    if (selectNode) {
      const { msgText } = selectNode.data;
      if (msgText && msgText.template) {
        const newTemplate = msgText.template;
        // setTemplate({
        //   id: newTemplate.id,
        //   namespace: newTemplate.namespace,
        // });
      }
    }
  }, [selectNode]);

  // useEffect(() => {
  //   setTextNode({ ...textNode, template });
  // }, [template]);

  const handleChange = (id: string) => {
    dispatch(setTemplateSelectValue(id));
    // setTextNode({ ...textNode, template: { id } });
  };

  useEffect(() => {
    if (selectedTemplate) {
      setSelectedTemplate(selectedTemplate);
    }
  }, [selectedTemplate]);

  return (
    <Box marginTop={3}>
      <FormControl fullWidth size="small">
        <InputLabel id="template" shrink={true}>
          {t('editFlowsView.text.templateMessage.selectTemplate')}
        </InputLabel>
        <Select
          size="small"
          fullWidth
          labelId="template"
          defaultValue={templateSelectValue}
          value={templateSelectValue}
          label={t('editFlowsView.text.templateMessage.selectTemplate')}
          onChange={(e) => handleChange(e.target.value)}
          sx={{
            '& legend': {
              maxWidth: 'fit-content',
              // padding: '0 10px'
            },
            '& .MuiSvgIcon-root': {
              color: 'text.disabled',
            },
          }}
        >
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedTemplate && (
        <TemplateMessageViewer selectedTemplate={selectedTemplate} />
      )}
    </Box>
  );
};

export default TemplateMessage;
