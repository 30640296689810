import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Selector
import { useSelector } from 'react-redux';
// Slice
import {
  selectGroups,
  selectGroupsStatus,
} from 'redux/features/editFlowSlice/editFlowSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Groups = () => {
  const { fillTicketNode, setFillTicketNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const { t } = useTranslation();
  const [ticketGroupId, setTicketGroupId] = useState<string>('');
  const groups = useSelector(selectGroups);
  const editStatus = useSelector(selectGroupsStatus);
  const isLoading: boolean = editStatus === 'loading';

  useEffect(() => {
    if (
      selectNode &&
      selectNode.data &&
      selectNode.data.fillTicket &&
      selectNode.data.fillTicket.ticketGroupId
    ) {
      const defaultGroupId = selectNode.data.fillTicket.ticketGroupId as string;
      setTicketGroupId(defaultGroupId);
    }
  }, [selectNode]);

  const handleChangeGroup = (ticketGroupId: string) => {
    setTicketGroupId(ticketGroupId);
    setFillTicketNode({ ...fillTicketNode, ticketGroupId });
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="group" shrink={true}>
          {t("editFlowsView.fillTicket.groupSelector")}
        </InputLabel>
        <Select
          labelId="group"
          id="demo-simple-select"
          value={ticketGroupId}
          label={t("editFlowsView.fillTicket.groupSelector")}
          onChange={(e) => handleChangeGroup(e.target.value)}
          disabled={isLoading}
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              // padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          {groups &&
            groups?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Groups;
