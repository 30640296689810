import React, { useContext } from 'react';
import { endingContext } from 'features/Views/Ending/context/EndingProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { EditButtons } from './components';
import CrudContainer from '../CrudContainer';
import PanelHeader from '../PanelHeader';
import CreateEditPanel from '../CreateEditPanel';
import GroupList from '../GroupList';

const EditPanel = () => {
  const { endEditing, editEndingName } = useContext(endingContext);
  const { t } = useTranslation();

  return (
    <CrudContainer>
      <PanelHeader
        title={t('endingsView.editPanel.title')}
        handleCloseButton={endEditing}
      />
      <CreateEditPanel endingNameState={editEndingName} />
      <GroupList />
      <EditButtons />
    </CrudContainer>
  );
};

export default EditPanel;
