// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { AvatarGroup, Box, Chip, Tooltip, Typography } from '@mui/material';
// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
import { useEffect, useState } from 'react';

interface Props {
  title: string;
  list: IContactAddress[] | string;
}

const EmailDestination = ({ list, title }: Props) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<string>('');

  useEffect(() => {
    if (list && typeof list !== 'string' && list.length > 4) {
      const restOfEmails = list.slice(4, list.length);
      const emailList = restOfEmails.map((to) => to.address).join(', ');
      setEmails(emailList);
    }
  }, []);

  return (
    <Box display="flex" gap={1} alignItems="center" flexWrap={'wrap'} height="2rem">
      <Typography variant="body2" color="textSecondary">
        {t(title)}
      </Typography>
      <Tooltip title={emails} arrow placement="right">
        <AvatarGroup
          max={5}
          sx={{
            '& .MuiAvatar-root': {
              width: 20,
              height: 20,
              fontSize: '.8rem',
            },
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          spacing="medium"
        >
          {list &&
            (typeof list === 'string' ? (
              <Chip size="small" label={list} />
            ) : (
              list.map((to) =>
                !to ? null : <Chip key={to.id} size="small" label={to?.address} />
              )
            ))}
        </AvatarGroup>
      </Tooltip>
    </Box>
  );
};

export default EmailDestination;
