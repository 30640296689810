import { useState, useContext, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Interface
import { NodeContactUpdateConverFields } from '@trii/types/dist/Conversations/Flows/Nodes';

const FieldUpdate = () => {
  const { conversationUpdateNode, setConversationUpdateNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [fieldId, setFieldId] = useState<NodeContactUpdateConverFields>(
    NodeContactUpdateConverFields.TAG
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data && selectNode.data.conversationUpdate) {
      const conversationUpdate = selectNode.data.conversationUpdate;
      const defaultContactId =
        conversationUpdate.fieldId &&
        (conversationUpdate.fieldId as NodeContactUpdateConverFields);
      setFieldId(defaultContactId);
    }
  }, [selectNode]);

  const handleChangeContact = (fieldId: NodeContactUpdateConverFields) => {
    setFieldId(fieldId);
    setConversationUpdateNode({ ...conversationUpdateNode, fieldId });
  };

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="contact" shrink={true}>
          {t("editFlowsView.conversationUpdate.updateField")}
        </InputLabel>
        <Select
          labelId="contact"
          id="demo-simple-select"
          value={fieldId}
          label={t("editFlowsView.conversationUpdate.updateField")}
          onChange={(e) =>
            handleChangeContact(e.target.value as NodeContactUpdateConverFields)
          }
          sx={{
            "& legend": {
              maxWidth: "fit-content",
              //   padding: '0 10px'
            },
            "& .MuiSvgIcon-root": {
              color: "text.disabled",
            },
          }}
        >
          <MenuItem
            key={NodeContactUpdateConverFields.TAG}
            value={NodeContactUpdateConverFields.TAG}
          >
            {t("editFlowsView.conversationUpdate.tag")}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default FieldUpdate;
