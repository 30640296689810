// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, Button, ButtonGroup, IconButton, Modal, Typography } from "@mui/material"
// Iconst
import { Close } from "@mui/icons-material"

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({
  title,
  open,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '40vw',
          backgroundColor: theme => theme.palette.background.default,
          padding: 2,
          borderRadius: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: theme => theme.palette.text.primary,
            fontWeight: 600,
            marginBottom: '1rem',
          }}
        >
          {title}
        </Typography>
        <ButtonGroup
          size="small"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            gap: '1rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            {t('global.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {t('global.confirm')}
          </Button>
        </ButtonGroup>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal