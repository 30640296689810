import { useEffect, useMemo, useState } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Components/ui
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
// Utils
import conversationFilters from './utils/conversationFilters';
// Types
import { ConversationFilters } from '../../../../../../../context/ConversationsProvider/types/ConversationFilters';
// Slice
import { getConversations } from '../../../../../../../../../../redux/features/conversationsSlice/conversationsSlice';
const Filter = () => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [condition, setCondition] = useState<ConversationFilters>(
    conversationFilters[0]
  );

  const openMenu = Boolean(anchorEl);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConditionChange = (condition: ConversationFilters) => {
    setCondition(condition);
    dispatch(getConversations(condition.value));

    localStorage.setItem('conversationFilter', condition.value);
  };

  // Set the condition from the local storage if it exists on first load
  useEffect(() => {
    const localCondition = localStorage.getItem('conversationFilter');
    if (localCondition) {
      const condition = conversationFilters.find(
        (filter) => filter.value === localCondition
      );
      if (condition) {
        setCondition(condition);
      }
    }
  }, []);

  const menuItems = useMemo(
    () =>
      conversationFilters.map((filter) => (
        <MenuItem key={filter.id} onClick={() => handleConditionChange(filter)}>
          <ListItemIcon sx={{ color: 'text.disabled' }}>{filter.icon}</ListItemIcon>
          {/* @ts-ignore */}
          <ListItemText >
            {t(filter.label)}
          </ListItemText>
        </MenuItem>
      )),
    [conversationFilters, handleConditionChange, t]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        height: '100%',
      }}
    >
      <IconButton
        onClick={handleOpen}
        aria-describedby={'popupPrincipal'}
        size="small"
        aria-controls={openMenu ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        sx={{
          borderRadius: '25px',
          padding: '10px',
          paddingY: '5px',
          color: 'text.disabled',
        }}
      >
        {condition && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            {condition.icon}
            <Typography>{t(condition.label)}</Typography>
          </Box>
        )}
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openMenu}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
          },
        }}
      >
        {menuItems}
      </Menu>
    </Box>
  );
};

export default Filter;
