import React, { useState, useEffect, useContext } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, FormControlLabel, Checkbox } from '@mui/material';

const AssociateMessages = () => {
  const { selectNode, setFillTicketNode, fillTicketNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [ticketAssociateMessages, setTicketAssociateMessages] =
    useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data.fillTicket) {
      const fillTicket = selectNode.data.fillTicket;
      const defaultTicketAssociateMessages =
        fillTicket.ticketAssociateMessages &&
        (fillTicket.ticketAssociateMessages as boolean);
      setTicketAssociateMessages(defaultTicketAssociateMessages);
    }
  }, [selectNode]);

  const handleChangeTicketAssociateMessages = (ticketAssociateMessages: boolean) => {
    setTicketAssociateMessages(ticketAssociateMessages);
    setFillTicketNode({ ...fillTicketNode, ticketAssociateMessages });
  };

  return (
    <>
      <Typography variant="subtitle2" fontWeight={'bold'} color="text.primary">
        {t('editFlowsView.fillTicket.associateMessages')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={ticketAssociateMessages}
            onChange={(e) => handleChangeTicketAssociateMessages(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.fillTicket.associateMessagesDescription')}
      />
    </>
  );
};

export default AssociateMessages;
