import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { StyleContext } from 'style/styleProvider';
// Components/ui
import {
	Typography,
	Divider,
} from '@mui/material';
// Components
import {
	ApiMethod,
	ApiUrl,
	AuthorizarionType,
} from './components';


const ApiSection = () => {
	const { fontWeight } = useContext(StyleContext);

	const { t } = useTranslation();

	return (
		<>
			<Typography
				// variant='caption'
				variant='subtitle2'
				// color={(theme) => theme.palette.text.disabled}
				fontWeight={fontWeight.bold}
				color="text.primary"
				sx={{
					mb: 1,
				}}
			>
				{t('editFlowsView.api.title')}
			</Typography>
			<ApiMethod />
			<ApiUrl />
			<Divider sx={{ margin: "1rem 0rem" }} />
			<AuthorizarionType />
		</>
	);
};

export default ApiSection;
