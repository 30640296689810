import { createContext } from 'react';
import { WallsContext } from './types/WallsContext';
import { Post, WallMessage } from '@trii/types/dist/Conversations';
import { SendMessage } from 'redux/features/socialWallSlice/types/SendMessage';
import { PostAction } from 'redux/features/socialWallSlice/types/PostAction';
import { CreateConversation } from 'features/Views/Conversations/context/ConversationsProvider/types/CreateConversation';

const socialWallsContext = createContext<WallsContext>({
  // Posts
  posts: [],
  getPosts: () => {},
  postSelected: null,
  setPostSelected: (wall: Post) => {},
  // Comments Wall state
  messages: [],
  isNewMessage: false,
  setIsNewMessage: (isNewMessage: boolean) => {},
  getComments: (postId: string) => {},
  updateMessage: (messageList: WallMessage[], newMessage: WallMessage) => {
    return [];
  },
  sendMessage: (data: SendMessage) => {},
  // Action
  fetchAction: (data: PostAction) => {},
  // Create Private Conversation
  commentToConversationId: '',
  setCommentToConversationId: (conversationId: string) => {},
  createNewConversation: (data: CreateConversation, commentId: string) => {},
  backgroundImage: '',
});

export default socialWallsContext;
