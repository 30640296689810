import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CloseSessionView from "./components/CloseSessionView/CloseSessionView";
import SignInButton from "./components/SignInButton/SignInButton";
import { EmailConfigOutboundStatus, GoogleAccountInfo } from "@trii/types/dist/Common/Channels";

interface Props {
  outboundGoogleAccount: GoogleAccountInfo;
  outboundGoogleStatus: EmailConfigOutboundStatus;
}

const GmailAccount = ({ outboundGoogleStatus, outboundGoogleAccount }: Props) => {
  const [currentStatus, setCurrentStatus] = useState(outboundGoogleStatus);
  const [currentAccount, setCurrentAccount] = useState(outboundGoogleAccount);
  

  useEffect(() => {
    setCurrentStatus(outboundGoogleStatus);
    setCurrentAccount(outboundGoogleAccount);
  }, [outboundGoogleStatus, outboundGoogleAccount]);

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
      my={'2rem'}
      gap={'1rem'}
    >
      {currentStatus === EmailConfigOutboundStatus.NOT_CONFIGURED || !currentAccount ? (
        <SignInButton />
      ) : (
        <CloseSessionView outboundGoogleAccount={currentAccount} outboundGoogleStatus={currentStatus}/>
      )}
    </Box>
  );
};

export default GmailAccount;
