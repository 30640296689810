import React from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SelectPostScreen = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      height={'100%'}
      width="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: 'background.default',
        '@media (max-width: 1024px)': {
          minWidth: '100vw',
        },
      }}
    >
      <Typography variant="h6" component="div" gutterBottom sx={{ color: "text.primary" }}>
        {t('socialWalls.emptyPost')}
      </Typography>
    </Box>
  );
};

export default SelectPostScreen;
