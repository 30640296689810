// Components/ui
import WebIcon from '@mui/icons-material/Web';
import Badge from '@mui/material/Badge';
// DB
import { useLiveQuery } from 'dexie-react-hooks';
import db from 'db/db';
// Redux
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';

const PostsIcon = () => {
  const spaceInfo = useSelector(selectSpaceInfo);

  const postsDb = useLiveQuery(
    () =>
      db.posts
        .where('spaceId')
        .equals(spaceInfo.id)
        .and((post) => post.newMessagesCount > 0)
        .toArray(),
    [spaceInfo, db]
  );

  const hasNewMessages = postsDb && postsDb.length > 0;

  return (
    <Badge color="warning" variant="dot" invisible={!hasNewMessages}>
      <WebIcon />
    </Badge>
  );
};

export default PostsIcon;
