import React from 'react';
// Components
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const DesignSection = ({ title, icon, children }: Props) => {
  const theme = useTheme()
  return (
    <Box
      borderRadius={"3px"}
      
    >
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        //@ts-ignore
        bgcolor={theme.palette.background.accordionHeader}
        p={1.5}
        borderRadius={"3px"}
        border={"1px solid"}
        borderColor={theme.palette.divider}
      >
        {icon}
        <Typography>{title}</Typography>
      </Box>
      <Box
        px={1.5}
        pt={2}
        pb={3}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        alignItems={"center"}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DesignSection;
