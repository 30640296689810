// Interface
import { NodeFillTicketPriority } from "@trii/types/dist/Conversations/Flows/Nodes"

type PriorityList = {
    id: number;
    name: string;
}

const priorityList: PriorityList[] = [
    {
        id: NodeFillTicketPriority.LOW,
        name: 'editFlowsView.fillTicket.priority.low'
    },
    {
        id: NodeFillTicketPriority.NORMAL,
        name: 'editFlowsView.fillTicket.priority.normal'
    },
    {
        id: NodeFillTicketPriority.HIGH,
        name: 'editFlowsView.fillTicket.priority.high'
    },
    {
        id: NodeFillTicketPriority.CRITICAL,
        name: 'editFlowsView.fillTicket.priority.critical'
    }
] 

export default priorityList