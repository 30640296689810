import { useEffect, useState, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectGroups } from 'redux/features/conversationsSlice/conversationsSlice';
import { selectUser, selectUsers } from 'redux/features/userSlice/userSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  ListSubheader,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { IGroup } from '@trii/types/dist/Conversations/Groups/Group';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

const AssignTo = () => {
  const { setAssignedTo } = useContext(conversationsContext);
  const currentUser = useSelector(selectUser);

  const [assignTo, setAssignTo] = useState<UserInfo | IGroup | any>({
    ...currentUser,
    id: currentUser.uid,
    name: currentUser.display_name,
    isActive: true,
    sipNumber: currentUser.sipExtensionConfig?.extension,
  });
  const theme = useTheme();
  const users = useSelector(selectUsers);
  const groups = useSelector(selectGroups);
  const { t } = useTranslation();

  const handleSelect = (data: UserInfo | IGroup, isUser: boolean) => {
    if (isUser) {
      setAssignTo(data as UserInfo);
      setAssignedTo({
        userId: data.id,
        groupId: '',
      });
    } else {
      setAssignTo(data as IGroup);
      setAssignedTo({
        userId: '',
        groupId: data.id,
      });
    }
  };

  useEffect(() => {
    if (assignTo) {
      setAssignedTo({
        userId: currentUser.uid,
        groupId: '',
      });
    }
  }, []);

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {t('conversations.createConversation.assignTo')}
      </Typography>
      <Select
        id="grouped-select"
        size="small"
        fullWidth
        value={assignTo?.id}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '20rem',
              overflow: 'auto',
              backgroundColor:
                //@ts-ignore
                theme.palette.background.panel,
              '& ul': {
                paddingY: '0',
              },
            },
          },
        }}
      >
        <ListSubheader
          sx={{
            backgroundColor:
              //@ts-ignore
              theme.palette.background.panel,
          }}
        >
          {t('global.users')}{' '}
        </ListSubheader>
        {users &&
          users.length > 0 &&
          users.map((user: UserInfo) => (
            <MenuItem
              key={user.id}
              value={user.id}
              onClick={() => handleSelect(user, true)}
            >
              {user.name}
            </MenuItem>
          ))}
        <ListSubheader
          sx={{
            backgroundColor:
              //@ts-ignore
              theme.palette.background.panel,
          }}
        >
          {t('settingsView.groups.groups')}
        </ListSubheader>
        {groups &&
          groups.length > 0 &&
          groups.map((group: IGroup) => (
            <MenuItem
              key={group.id}
              value={group.id}
              onClick={() => handleSelect(group, false)}
            >
              {group.name}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default AssignTo;
