import React from 'react';
// Components/ui
import { Box, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface Props {
  orderUpButtonDisabled: boolean;
  orderDownButtonDisabled: boolean;
  handleChangeQuestionOrderUp: () => void;
  handleChangeQuestionOrderDown: () => void;
}

const OrderArrows = ({
  orderUpButtonDisabled,
  orderDownButtonDisabled,
  handleChangeQuestionOrderUp,
  handleChangeQuestionOrderDown,
}: Props) => {
  return (
    <Box>
      <IconButton
        onClick={handleChangeQuestionOrderUp}
        color="primary"
        size="small"
        disabled={orderUpButtonDisabled}
      >
        <ArrowUpwardIcon sx={{ fontSize: 21 }} />
      </IconButton>
      <IconButton
        onClick={handleChangeQuestionOrderDown}
        color="primary"
        size="small"
        disabled={orderDownButtonDisabled}
      >
        <ArrowDownwardIcon sx={{ fontSize: 21 }} />
      </IconButton>
    </Box>
  );
};

export default OrderArrows;
