import { Business } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { IContact } from '@trii/types/dist/Contacts';
// import { IBusinessInfo } from "@trii/types/dist/Contacts";
import { useNavigate } from 'react-router';

interface Props {
  business: IContact;
}

const BusinessMember = ({ business }: Props) => {
  // const navigate = useNavigate();
  // const handleBusinessNavigate = () => {
  //   navigate(`/a/conversations/conversations/${business.id}`);
  // };

  return (
    // <Button variant="text" size="small" onClick={handleBusinessNavigate}>
      <Box
        sx={{
          width: '100%',
          height: 'max-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '10px'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'max-content',
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1,
            maxWidth: '100%',
            justifyContent: 'center',
          }}
        >
          <Business sx={{fontSize: '1.25rem'}}/>
          <Tooltip title={business.businessName} arrow>
            <Typography
              variant="subtitle1"
              fontWeight="semi-bold"
              fontSize={14}
              color={'text.disabled'}
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                cursor: 'default'
              }}
            >
              {business.businessName}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
  );
};

export default BusinessMember;
