import { useState } from "react";
// Components/ui
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CustomModal from "components/CustomModal";
import UserGroupSelector from "components/UserGroupSelector";
// Redux
import { useAppDispatch } from "hooks/useAppDispatch";
// Types
import {
  AssignMethod,
  ConversationAssigned,
} from "@trii/types/dist/Conversations";
import { IGroup } from "@trii/types/dist/Conversations/Groups/Group";
import { UserInfo } from "@trii/types/dist/Users";
// Utils
import { selectAssignMethodItems } from "./utils/selectAssignMethodItems";

type ChannelNewConversationsConfigProps = {
  changeAssignMethod: (method: AssignMethod) => void;
  assignMethodValue: AssignMethod;
  handleUserGroupSelectorAccept: (
    user: UserInfo | null,
    group: IGroup | null
  ) => void;
  assignToValue: ConversationAssigned;
};

const ChannelNewConversationsConfig = ({
  changeAssignMethod,
  assignMethodValue,
  handleUserGroupSelectorAccept,
  assignToValue,
}: ChannelNewConversationsConfigProps) => {
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          width: "55%",
          marginLeft: "12px",
        }}
      >
        <Typography color="textPrimary" variant="h6">
          Asignar a nuevas conversaciones
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography color="textPrimary" sx={{ marginRight: "10px" }}>
              Asignar a:
            </Typography>
            <Typography color="textPrimary">
              <b>{assignToValue?.groupInfo?.name}</b>{" "}
              {assignToValue?.groupInfo?.name &&
                assignToValue?.userInfo?.name &&
                "-"}{" "}
              <b>{assignToValue?.userInfo?.name}</b>
            </Typography>
          </Box>
          <IconButton onClick={handleOpenModal} color="primary">
            <EditIcon />
          </IconButton>
        </Box>
        {assignToValue != null &&
          assignToValue?.groupInfo != null &&
          assignToValue?.userInfo == null && (
            <FormControl fullWidth>
              <InputLabel id="method-distribution-select-label">
                Metodo distribucion
              </InputLabel>
              <Select
                id="method-distribution-select"
                labelId="method-distribution-select-label"
                label="Metodo distribucion"
                size="small"
                value={assignMethodValue}
                // @ts-ignore
                onChange={(e) => dispatch(changeAssignMethod(e.target.value))}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      //@ts-ignore
                      backgroundColor: (theme) => theme.palette.background.panel,
                    },
                  },
                }}
              >
                {selectAssignMethodItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      </Box>
      <CustomModal
        handleClose={handleCloseModal}
        open={openModal}
        maxWidth="30rem"
        modalTitle={"Asignar nuevas conversaciones"}
      >
        <UserGroupSelector
          handleCloseModal={handleCloseModal}
          onAccept={handleUserGroupSelectorAccept}
        />
      </CustomModal>
    </>
  );
};

export default ChannelNewConversationsConfig;
