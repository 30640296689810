import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
// @ts-ignore
import { ScheduleContext } from 'features/Views/Schedules/context/types';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { deleteScheduleNameField } =
    useContext<ScheduleContext>(InitScheduleContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <Typography
        variant="subtitle2"
        color={'text.primary'}
        sx={{ marginBottom: '15px' }}
      >
        {t('scheduleView.deleteScheduleLabel')}
      </Typography>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('scheduleView.scheduleName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...deleteScheduleNameField.attributes}
      />
    </Box>
  );
};

export default Inputs;
