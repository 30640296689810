import React, { useContext, useState, useEffect } from 'react';
// Context
import { InitEditFlowsContext } from 'features/Views/EditFlows/context/EditFlowsContext';
import { EditFlowsContext } from 'features/Views/EditFlows/context/types';
// Translate
import { useTranslation } from 'react-i18next';
// components/ui
import { TextField, Divider, FormControlLabel, Checkbox, Box } from '@mui/material';
// Components
import { Action, ActionButtons, FieldUpdate, SaveAs } from './components';

const EditConversationUpdateNode = () => {
  const { conversationUpdateNode, setConversationUpdateNode, selectNode } =
    useContext<EditFlowsContext>(InitEditFlowsContext);
  const [name, setName] = useState<string>('');
  const [enableStatics, setEnableStatics] = useState<boolean>(false);
  const [id, setId] = useState<string>(Math.random().toString(36).substr(2, 9));
  const { t } = useTranslation();

  useEffect(() => {
    if (selectNode && selectNode.data.conversationUpdate) {
      const conversationUpdate =
        selectNode && selectNode?.data && selectNode?.data?.conversationUpdate;
      const defaultId = conversationUpdate.id
        ? conversationUpdate?.id
        : (Math.random().toString(36).substr(2, 9) as string);
      const defaultName = conversationUpdate.name
        ? conversationUpdate?.name
        : ('' as string);
      const defaultEnableStatics = conversationUpdate.enableStatics
        ? conversationUpdate?.enableStatics
        : (false as boolean);
      setId(defaultId);
      setName(defaultName);
      setEnableStatics(defaultEnableStatics);
    }
  }, [selectNode]);

  const handleChangeName = (name: string) => {
    setName(name);
    setConversationUpdateNode({
      ...conversationUpdateNode,
      id,
      name,
    });
  };

  const handleChangeEnableStatics = (enableStatics: boolean) => {
    setEnableStatics(enableStatics);
    setConversationUpdateNode({
      ...conversationUpdateNode,
      enableStatics,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('editFlowsView.name')}
        fullWidth
        value={name}
        onChange={(e) => handleChangeName(e.target.value as string)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStatics}
            onChange={(e) => handleChangeEnableStatics(e.target.checked)}
          />
        }
        slotProps={{
          typography: {
            variant: "caption",
            color: "text.primary"
          },
        }}
        label={t('editFlowsView.enableStatics')}
      />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <FieldUpdate />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <Action />
      <Divider sx={{ margin: '1rem 0rem' }} />
      <SaveAs />
      <ActionButtons />
    </Box>
  );
};

export default EditConversationUpdateNode;
