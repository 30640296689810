import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateSms from '../hooks/useCreateSms/useCreateSms';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createSmsInitialState from '../hooks/useCreateSms/createSmsInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { createSms, deleteSms } from 'redux/features/smsSlice/smsSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Types
import type { CrudPanelState } from './types';
import { SmsContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const smsContext = createContext<SmsContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateSms: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedSms: null,
  deleteSmsName: fieldInitialDef,
  createSmsState: createSmsInitialState,
  handleDeleteSms: () => {},
  navigateToEdit: () => {},
});

const SmsProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedSms, setSelectedSms] = useState<any>(null);
  // Create state
  const createSmsState = useCreateSms();
  // Delete state
  const deleteSmsName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateSms = async () => {
    const newSms = createSmsState.actions.getNewSms();
    await dispatch(createSms(newSms));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createSmsState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteSms = async () => {
    const { id } = selectedSms;

    await dispatch(deleteSms(id));
    endDeleting();
  };

  const openDelete = (sms: any) => {
    setSelectedSms(sms);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteSmsName.actions.resetValue();
    setSelectedSms(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (smsId: string) => {
    navigate(`/a/conversations/system/sms/${smsId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <smsContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateSms,
        openDelete,
        endDeleting,
        deleteSmsName,
        createSmsState,
        selectedSms,
        handleDeleteSms,
        navigateToEdit,
      }}
    >
      {children}
    </smsContext.Provider>
  );
};

export default SmsProvider;
