// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { StyledButton } from './styles';
import AddIcon from '@mui/icons-material/Add';
// Custom hooks
import { useActivitiesCardContext } from 'features/Views/Conversations/components/components/ChatDetailsSidebar/hooks/useActivitiesCardContext';

const AddEvent = () => {
  const { t } = useTranslation();
  const { openCreateActivityPopUp } = useActivitiesCardContext();

  return (
    <StyledButton
      variant="contained"
      color="primary"
      size="small"
      startIcon={<AddIcon />}
      onClick={openCreateActivityPopUp}
    >
      {t('conversations.sidebar.events.createEvent')}
    </StyledButton>
  );
};

export default AddEvent;
