import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
import {
  fetchFacebook,
  selectFacebookName,
  selectFacebookUpdateStatus,
  selectFacebookFetchStatus,
  updateFacebook,
  selectFacebook,
} from 'redux/features/facebookEditSlice/facebookEditSlice';
// Components/ui
import { Body } from './components';
import { Divider, Box } from '@mui/material';
import { ViewContainer, ChannelEditHeader } from 'components';
import FacebookIcon from '@mui/icons-material/Facebook';

const FacebookEditContainer = () => {
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const { id } = useParams();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const facebook = useSelector(selectFacebook);
  const facebookName = useSelector(selectFacebookName);
  const facebookUpdateStatus = useSelector(selectFacebookUpdateStatus);
  const facebookFetchStatus = useSelector(selectFacebookFetchStatus);

  useEffect(() => {
    dispatch(fetchFacebook(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      facebookFetchStatus === 'succeeded' &&
      setAllFetched(true);
  }, [groupFetchStatus, scheduleFetchStatus, facebookFetchStatus]);

  return (
    <ViewContainer>
      <Box boxShadow={1}>
        <ChannelEditHeader
          channelName={facebookName}
          channelUpdateStatus={facebookUpdateStatus}
          updateChannel={updateFacebook}
          channelFetchStatus={facebookFetchStatus}
        >
          <FacebookIcon
            sx={{
              fontSize: 20,
              color: 'primary.main',
            }}
          />
        </ChannelEditHeader>
        <Divider />
        <Body data={facebook} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default FacebookEditContainer;
