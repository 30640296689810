import React, { RefObject, forwardRef } from 'react';
// components/ui
import { Box } from '@mui/material';
import RepairSection from './components/RepairSection/RepairSection';
import AddButton from '../../../AddButton/index.js';
import { SectionCard } from '../../layout';
// Types
import { ContactProps } from '../../types/ContactProps';
import { useTranslation } from 'react-i18next';

const ContactRepairs = forwardRef(
  (props: ContactProps, ref: RefObject<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <SectionCard
        title={t('conversations.sidebar.repairs.title')}
        onClick={props.handleClick}
        tabName="contactRepairs"
      >
        <Box ref={ref}>
          <AddButton label="Añadir un equipo" />
          <RepairSection title="Reparaciones en curso">
            Este contacto no tiene equipos aun
          </RepairSection>
          <RepairSection title="Historial"></RepairSection>
        </Box>
      </SectionCard>
    );
  }
);

export default ContactRepairs;
