import { createContext, useState, ReactNode } from 'react';
import useCreateWebHook from '../hooks/useCreateWebHook/useCreateWebHook';
// Hooks
import useField from 'hooks/useField';
// Utils
import { createWebHookInitialState } from '../hooks/useCreateWebHook/createWebHookInitialState';
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createWebHook,
  deleteWebHook,
  updateWebHook,
} from 'redux/features/webHooksSlice/webHooksSlice';
// Types
import type { CrudPanelState } from './types';
import { WebHooksContext } from './types';
import { IWebhook } from '@trii/types/dist/Conversations';
import { UseFieldType } from 'hooks/useField';

export const webHooksContext = createContext<WebHooksContext>({
  crudPanelState: 'closed',
  createWebHookState: createWebHookInitialState,
  editWebHookState: createWebHookInitialState,
  endCreating: () => {},
  openCreate: () => {},
  handleCreateWebHook: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedWebHook: null,
  deleteField: fieldInitialDef,
  handleDeleteWebHook: () => {},
  endEditing: () => {},
  handleEditWebHook: () => {},
  openEdit: () => {},
});

const WebHooksProvider = ({ children }: { children: ReactNode }) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedWebHook, setSelectedWebHook] = useState<IWebhook>(null);
  // Create state
  const createWebHookState = useCreateWebHook();
  // Edit state
  const editWebHookState = useCreateWebHook();
  // Delete state
  const deleteField: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateWebHook = async () => {
    const newWebHook = createWebHookState.action.getNewWebHook();
    await dispatch(createWebHook(newWebHook));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createWebHookState.action.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Edit functions
  const openEdit = (webHook: IWebhook) => {
    editWebHookState.action.setToBeEditedWebHook(webHook);
    setSelectedWebHook(webHook);
    setCrudPanelState('edit');
  };
  const handleEditWebHook = async () => {
    const editedWebHook = editWebHookState.action.getNewWebHook();
    const editedWebHookWithId: IWebhook = {
      ...editedWebHook,
      id: selectedWebHook.id,
    };

    await dispatch(updateWebHook(editedWebHookWithId));
    endEditing();
  };
  const endEditing = () => {
    editWebHookState.action.resetAll();
    closeCrudPanel();
  };
  // end Edit functions

  // Delete functions
  const handleDeleteWebHook = async () => {
    const { id } = selectedWebHook;

    await dispatch(deleteWebHook(id));
    endDeleting();
  };

  const openDelete = (webhook: IWebhook) => {
    setSelectedWebHook(webhook);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteField.actions.resetValue();
    setSelectedWebHook(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <webHooksContext.Provider
      value={{
        crudPanelState,
        endCreating,
        createWebHookState,
        openCreate,
        handleCreateWebHook,
        openDelete,
        endDeleting,
        deleteField,
        selectedWebHook,
        handleDeleteWebHook,
        editWebHookState,
        endEditing,
        handleEditWebHook,
        openEdit,
      }}
    >
      {children}
    </webHooksContext.Provider>
  );
};

export default WebHooksProvider;
