import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
// Components/ui
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// Redux
import { useSelector } from 'react-redux';
import { selectUsers } from 'redux/features/userSlice/userSlice';
import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';

const UserSelectorFilter = () => {
  const { t } = useTranslation();

  const usersSelector = useSelector(selectUsers);

  const { setSelectedUsers } = useContext(MyReportContext);

  const [users, setUsers] = useState([]);

  const handleChange = (event) => {
    setSelectedUsers(event.target.value);
    setUsers(event.target.value);
  };

  return (
    <FormControl sx={{ marginBottom: 2 }} fullWidth>
      <InputLabel shrink={true} id="user-selector-label">
        {t('global.users')}{' '}
      </InputLabel>
      <Select
        labelId="user-selector-label"
        label={t('global.users')}
        id="user-selector"
        multiple
        value={users}
        onChange={handleChange}
        size="small"
        fullWidth
        sx={{
          '& legend': {
            maxWidth: '100%',
          },
        }}
      >
        {usersSelector?.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UserSelectorFilter;
