import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  changeApiApiqrId,
  changeApiqrToken,
  resetApiQrConnection,
  selectResetApiQrConnectionStatus,
  selectUnlinkWhatsAppStatus,
  selectWhatsApp,
  unlinkWhatsApp,
  updateWhatsAppPairStatus,
} from 'redux/features/whatsAppEditSlice/whatsAppEditSlice';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import InputsContainer from '../InputsContainer/InputsContainer';
import { LoadingButton } from '@mui/lab';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';

interface Props {
  data: {
    apiqrId: string;
    apiqrToken: string;
  };
  status: string;
}

const ApiInputs = ({ data, status }: Props) => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  const whatsApp = useSelector(selectWhatsApp);
  const resetApiQrConnectionStatus = useSelector(selectResetApiQrConnectionStatus);
  const unlinkWhatsAppStatus = useSelector(selectUnlinkWhatsAppStatus);

  const { apiqrId, apiqrToken } = data;

  const resetApiQrConnectionLoading = resetApiQrConnectionStatus === 'loading';
  const unlinkWhatsAppLoading = unlinkWhatsAppStatus === 'loading';

  const handleApiApiqrIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeApiApiqrId(event.target.value));
  };
  const handleApiqrTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeApiqrToken(event.target.value));
  };

  const handleResetApiQrConnection = () => {
    dispatch(resetApiQrConnection(whatsApp.id));
  };

  const handleUnlinkWhatsApp = () => {
    dispatch(unlinkWhatsApp(whatsApp.id));
  };

  useEffect(() => {
    if (socketConnection) {
      subscribeEvent('channel_updatestatus', (data) => {
        const { channelId, status } = data;

        if (channelId === whatsApp.id) {
          dispatch(updateWhatsAppPairStatus(status));
        }
      });
    }
    return () => {
      unsubscribeEvent('channel_updateStatus');
    };
  }, [socketConnection]);

  return (
    <InputsContainer>
      <TextField
        size="small"
        label={t('whatsAppEditView.conectionsPanel.accountId')}
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={apiqrId}
        onChange={handleApiApiqrIdChange}
      />
      <TextField
        size="small"
        label="Token"
        InputLabelProps={{
          shrink: true,
        }}
        sx={inputFieldStyle}
        value={apiqrToken}
        onChange={handleApiqrTokenChange}
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          pt: 2,
        }}
      >
        <Typography>{t('global.status')}:</Typography>
        <Typography fontWeight={'bold'}>
          {status === '' ? 'undefined' : status}
        </Typography>
        <LoadingButton
          disabled={resetApiQrConnectionLoading}
          loading={resetApiQrConnectionLoading}
          onClick={handleResetApiQrConnection}
          variant="outlined"
          size="small"
        >
          {t('whatsAppEditView.conectionsPanel.resetChannelConnection')}
        </LoadingButton>
        <LoadingButton
          disabled={unlinkWhatsAppLoading}
          loading={unlinkWhatsAppLoading}
          onClick={handleUnlinkWhatsApp}
          variant="outlined"
          size="small"
        >
          {t('whatsAppEditView.conectionsPanel.unlink')}
        </LoadingButton>
      </Box>
      {status === 'UNPAIRED' && (
        <img
          src={`https://trii.blob.core.windows.net/messagesmedia/whatsapp/${apiqrId}_${apiqrToken}/qr.jpg?t=${new Date().getTime()}`}
          alt="QR Code"
        />
      )}
    </InputsContainer>
  );
};

export default ApiInputs;
