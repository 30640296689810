import React, { useContext } from 'react';
import { InitGroupContext } from 'features/Views/Groups/context/GroupContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
import { GroupContext } from 'features/Views/Groups/context/types';
import { Group } from 'redux/features/groupSlice/types';
// Redux
import { useSelector } from 'react-redux';
import {
  selectGroupDeleteStatus,
  selectSelectedGroup,
} from 'redux/features/groupSlice/groupSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeDeletePanel, sendDeleteGroup, deleteGroupNameField } =
    useContext<GroupContext>(InitGroupContext);
  const deleteStatus: RequestStatus = useSelector(selectGroupDeleteStatus);
  const selectedGroup: Group = useSelector(selectSelectedGroup);
  const isLoading: boolean = deleteStatus === 'loading';
  const isDisabled: boolean =
    deleteStatus === 'loading' ||
    deleteGroupNameField.attributes.value !== selectedGroup?.name;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeDeletePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<DeleteIcon />}
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (color) => color.palette.error.main,
          '&:hover': {
            backgroundColor: (color) => color.palette.error.dark,
          },
        }}
        disabled={isDisabled}
        onClick={() => sendDeleteGroup(selectedGroup.id)}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
