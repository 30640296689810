import { useTranslation } from 'react-i18next';
// Types
import { WebChatConfig } from '@trii/types/dist/Common/Channels';
// Components/ui
import { ChannelPanel } from 'components';
import {
  AppearanceSection,
  WidgetOptionsSection,
  WidgetStateSection,
  HomeSection,
  AdvancedOptionsSection,
  SurveySection,
  PreChatSection,
  HomeOtherChannelsSection,
} from './components';

interface Props {
  webChatData: WebChatConfig;
}

const DesignPanel = ({ webChatData }: Props) => {
  const { t } = useTranslation();
  return (
    <ChannelPanel title={t('formEditView.designPanel.title')}>
      <WidgetStateSection webChatShowWidget={webChatData.statusShowWidget} />
      <AppearanceSection
        backgroundColor={webChatData.styleBackgroundColor}
        fontColor={webChatData.styleFontColor}
      />
      <WidgetOptionsSection webChatData={webChatData} />
      <HomeSection webChatData={webChatData} />
      <HomeOtherChannelsSection
        homeChannelsWhatsApp={webChatData.homeChannelsWhatsApp}
        homeChannelsMessenger={webChatData.homeChannelsMessenger}
        homeChannelsPhone={webChatData.homeChannelsPhone}
      />
      <PreChatSection
        preChatUserInfoEnabled={webChatData.preChatUserInfoEnabled}
        preChatUserInfoEmailTitle={webChatData.preChatUserInfoEmailTitle}
        preChatUserInfoNameTitle={webChatData.preChatUserInfoNameTitle}
        preChatDataPrivacyPolicyEnabled={webChatData.preChatDataPrivacyPolicyEnabled}
        preChatDataPrivacyPolicyDescription={
          webChatData.preChatDataPrivacyPolicyDescription
        }
        preChatDataPrivacyPolicyLink={webChatData.preChatDataPrivacyPolicyLink}
      />
      <SurveySection
        surveyEnabled={webChatData.surveyEnabled}
        surveyFormId={webChatData.surveyFormId}
      />
      <AdvancedOptionsSection advanceMarginTop={webChatData.advanceMarginTop} />
    </ChannelPanel>
  );
};

export default DesignPanel;
