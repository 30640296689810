import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MyReportContext } from 'features/Views/MyReports/context/MyReportContext';

const ApplyFilterButton = () => {
  const { t } = useTranslation();
  const { applyFilters } = useContext(MyReportContext);

  return (
    <Box
      mb={'12px'}
      display="flex"
      justifyContent="end"
      sx={{ position: 'absolute', right: '16px', bottom: 0 }}
    >
      <Button variant="contained" size="small" onClick={applyFilters}>
        {t('myReports.applyFilter')}
      </Button>
    </Box>
  );
};

export default ApplyFilterButton;
