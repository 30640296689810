import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import useField from 'hooks/useField';
import useCreateSip from '../hooks/useCreateSip/useCreateSip';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createSipInitialState from '../hooks/useCreateSip/createSipInitialState';
// Redux
import { useDispatch } from 'react-redux';
import { createSip, deleteSip } from 'redux/features/sipSlice/sipSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Types
import type { CrudPanelState } from './types';
import { SipContext } from './types';
import { UseFieldType } from 'hooks/useField';

export const sipContext = createContext<SipContext>({
  crudPanelState: 'closed',
  endCreating: () => {},
  openCreate: () => {},
  handleCreateSip: () => {},
  openDelete: () => {},
  endDeleting: () => {},
  selectedSip: null,
  deleteSipName: fieldInitialDef,
  createSipState: createSipInitialState,
  handleDeleteSip: () => {},
  navigateToEdit: () => {},
});

const SipProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedSip, setSelectedSip] = useState<any>(null);
  // Create state
  const createSipState = useCreateSip();
  // Delete state
  const deleteSipName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateSip = async () => {
    const newSip = createSipState.actions.getNewSip();
    await dispatch(createSip(newSip));
    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createSipState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Delete functions
  const handleDeleteSip = async () => {
    const { id } = selectedSip;

    await dispatch(deleteSip(id));
    endDeleting();
  };

  const openDelete = (sip: any) => {
    setSelectedSip(sip);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteSipName.actions.resetValue();
    setSelectedSip(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Edit functions
  const navigateToEdit = (sipId: string) => {
    navigate(`/a/conversations/system/sip/${sipId}`);
  };

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <sipContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateSip,
        openDelete,
        endDeleting,
        deleteSipName,
        createSipState,
        selectedSip,
        handleDeleteSip,
        navigateToEdit,
      }}
    >
      {children}
    </sipContext.Provider>
  );
};

export default SipProvider;
