import useField from 'hooks/useField';
// Types
import { UseCreateWebChat } from './types';
import { NewWebChat } from 'redux/features/webChatSlice/types/NewWebChat';

const useCreateWebChat = (): UseCreateWebChat => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewWebChat = (): NewWebChat => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewWebChat,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateWebChat;
