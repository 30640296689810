import { useEffect, useState } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setAccountData } from 'redux/features/googleBusinessSlice/googleBusinessSlice';
// Types
import { IGoogleAccountData } from 'redux/features/googleBusinessSlice/types/IGoogleAccountData';
import { GoogleAccountInfo } from '@trii/types/dist/Contacts';
// Components/ui
import { Fade } from '@mui/material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import GoogleIcon from './google-icon.png';

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4285F4',
  color: '#fff',
  textTransform: 'none',
  fontSize: 15,
  fontWeight: 'bold',
  padding: '2px 10px 2px 2px',
  border: 'none',
  borderRadius: 3,
  boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
  '&:hover': {
    backgroundColor: '#357ae8',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 3,
  padding: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function GoogleLogInButton() {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  const CLIENT_ID =
    '243763149644-8s1804pp8594vcpntn44pe0862vpm5bm.apps.googleusercontent.com';

  async function handleCallback(response: any) {
    // Manejar la respuesta del código de autorización aquí
    console.log('Authorization code: ', response.code);
    console.log('response: ', response);
    // Aquí puedes enviar el código a tu servidor para intercambiarlo por un token

    //const { name, picture, email }: IUserObject = jwtDecode(response.credential);
    const googleAccount: GoogleAccountInfo = {
      name: '', //name,
      imageUrl: '', //picture,
      email: '', //email,
    };
    const googleAccountData: IGoogleAccountData = {
      googleAccount,
      code: response.code,
    };

    await dispatch(setAccountData(googleAccountData));
  }

  useEffect(() => {
    /* global google */
    const initializeGoogleSignIn = () => {
      const google: any = window.google;
      const client = google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: 'email profile https://www.googleapis.com/auth/business.manage',
        ux_mode: 'popup',
        callback: handleCallback,
      });

      document.getElementById('google-button').addEventListener('click', () => {
        client.requestCode();
      });
    };

    if (window.google) {
      initializeGoogleSignIn();
      setShow(true);
    } else {
      window.addEventListener('google-loaded', initializeGoogleSignIn);
    }

    return () => {
      window.removeEventListener('google-loaded', initializeGoogleSignIn);
    };
  }, [window.google]);

  return (
    <Fade in={show}>
      <GoogleButton id="google-button">
        <IconWrapper>
          <img src={GoogleIcon} alt="Google logo" width="24" height="24" />
        </IconWrapper>
        Sign in with Google
      </GoogleButton>
    </Fade>
  );
}

export default GoogleLogInButton;
