import { useContext, useEffect, useState } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Icons
import { Close } from '@mui/icons-material';
// Components/ui
import { Box, IconButton, Modal, Slide, Typography } from '@mui/material';
// Components
import {
  AddComment,
  AddParticipant,
  CreateConversation,
  EndConversations,
  Label,
  MessageStatus,
  NewInternalChat,
  ForwardMessage,
  TransferTo,
  BotAnswer,
} from './components';
import AddForm from './components/AddForm';
import SendLocation from './components/SendLocation';
import { CustomModal } from 'components';

const ModalList = () => {
  const { t } = useTranslation();
  const { openModalList, setOpenModalList, modalTitle, getUsers, getGroups } =
    useContext(conversationsContext);
  const [width, setWidth] = useState('30rem');

  const handleClose = () => {
    setOpenModalList(false);
  };

  useEffect(() => {
    if (modalTitle === t('conversations.createConversation.title')) {
      setWidth('80rem');
      getUsers();
      getGroups();
    } else {
      setWidth('30rem');
    }
  }, [modalTitle]);

  return (
    <CustomModal
      handleClose={handleClose}
      open={openModalList}
      modalTitle={modalTitle}
      maxWidth={width}
    >
      {modalTitle === t('endingsView.endings') && <EndConversations />}
      {modalTitle === t('conversations.actions.label') && <Label />}
      {modalTitle === t('conversations.actions.transfer') && <TransferTo />}
      {modalTitle === t('conversations.message.actions.addComment') && (
        <AddComment />
      )}
      {modalTitle === t('conversations.message.actions.forward') && (
        <ForwardMessage />
      )}
      {modalTitle === t('conversations.message.status.title') && <MessageStatus />}
      {modalTitle === t('conversations.createConversation.title') && (
        <CreateConversation />
      )}
      {modalTitle === t('conversations.internalChat.newInternalChat') && (
        <NewInternalChat />
      )}
      {modalTitle === t('conversations.internalChat.addParticipant') && (
        <AddParticipant />
      )}
      {modalTitle === t('conversations.message.actions.selectForm') && <AddForm />}
      {modalTitle === t('conversations.message.actions.sendLocation') && (
        <SendLocation />
      )}
      {modalTitle === t('conversations.botAnswers') && <BotAnswer />}
    </CustomModal>
  );
};

export default ModalList;
