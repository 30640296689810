// Provider
import { QuickResponseProvider } from "./context/QuickResponseProvider"
// Components
import { QuickResponse } from "./components"

const QuickResponseContainer = () => {
  return (
    <QuickResponseProvider>
      <QuickResponse />
    </QuickResponseProvider>
  )
}

export default QuickResponseContainer