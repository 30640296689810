import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Typography } from '@mui/material';
// Style
import { StyleContext } from 'style/styleProvider';
// Context
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';
// Components
import Holidays from './components';

const HolidaysList = () => {
  const { t } = useTranslation();
  const {
    handleOpenPanelNewHoliday,
    openPanelNewHoliday
  } = useContext(InitScheduleEditContext);
  const { fontSize } = useContext(StyleContext);
  return (
    <Box
      sx={{
        padding: "15px",
      }}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <Typography
          variant="subtitle2"
          fontSize={fontSize.sm}
          sx={{ color: "text.primary" }}
        >
          {t("scheduleView.holidays")}
        </Typography>
        <Button disabled={openPanelNewHoliday} variant="outlined" size="small" sx={{ textTransform: "none" }} onClick={handleOpenPanelNewHoliday}>
          {t("scheduleView.addHoliday")}
        </Button>
      </Box>
      <Holidays />
    </Box>
  );
};

export default HolidaysList;
