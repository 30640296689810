import { useTranslation } from 'react-i18next';
// Types
import { DNSRecord, EmailConfigOutboundStatus } from '@trii/types/dist/Common/Channels';
// Components/ui
import SectionContainer from '../SectionContainer';
import { CopyClipboardButton } from 'components';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { testTrii } from 'redux/features/emailEditSlice/emailEditSlice';
import { useState } from 'react';

interface Props {
  statusDKIM: EmailConfigOutboundStatus;
  dataDKIM: DNSRecord[];
}

const DkimSection = ({ statusDKIM, dataDKIM }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const { id } = useParams();

  const testAdjust = async () => {
    try {
      const response = await dispatch(testTrii(id));
      
      // Assuming you check response to detect if it's successful or failed
      if (response.meta.requestStatus === 'fulfilled') {
        setSnackbarMessage(t("emailEditView.testConnection.testSuccess"));
        setSnackbarSeverity('success');
      } else if (response.meta.requestStatus === 'rejected') {
        //@ts-ignore
        setSnackbarMessage(t("emailEditView.testConnection.testFailed") + response.error.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('SMTP Test failed:', error);
    } finally {
      setOpen(true);
    }
   };

  return (
    <SectionContainer
      status={statusDKIM}
      title={"DKIM"}
      testAdjust={testAdjust}
    >
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Typography variant="body2">
          {t("emailEditView.outgoingEmailPanel.createTwoCnames")}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.mainCenter,
              }}
            >
              <TableRow>
                <TableCell>Host</TableCell>
                <TableCell>{t("global.type")}</TableCell>
                <TableCell>{t("global.value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataDKIM &&
                dataDKIM.map((record, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {record.name}
                    </TableCell>
                    <TableCell>{record.type}</TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {record.value} <CopyClipboardButton text={record.value} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={snackbarSeverity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </SectionContainer>
  );
};

export default DkimSection;
