import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RootState } from '../../store';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { MessagesState } from './types/MessagesState';
import { Media } from './types/Media';
import { UploadMedia } from './types/UploadMedia';
import { NextMessages } from './types/NextMessages';
import { Variable } from './types/Variable';
import { ForwardMessage } from './types/ForwardMessage';
import { Comment } from './types/Comment';
import { HighlightMessage } from './types/HighlightMessage';
// Service
import messagesService from './messagesService';
import { IMessage } from '@trii/types/dist/Common/Messages';
// DB
import db, { dbWorker } from 'db/db';

import { FormattedFormMessage } from './types/FormattedFormMessage';
import { ReactionData } from './types/ReactionData';
import { SearchMessageData } from './types/SearchMessageData';
import { GetMessagesData } from './types/GetMessagesData';
import { EmailMessageStatus } from './types/EmailMessageStatus';
import { UploadURLParams } from './types/UploadURLParams';
import { OpenConversationOnFloatingWindowData } from './types/OpenConversationOnFloatingWindowData';

const initialState: MessagesState = {
  messages: null,
  searchMessages: [],
  status: {
    fetch: 'idle',
    send: 'idle',
    media: 'idle',
    nextMessages: 'idle',
    variable: 'idle',
    forwardMessage: 'idle',
    addComment: 'idle',
    deleteComment: 'idle',
    highlighted: 'idle',
    search: 'idle',
    emailMessage: 'idle',
  },
  media: null,
  variable: '',
  messagesOnError: [],
  isReplyingEmail: false,
};

export const getMessages = createAsyncThunk(
  'conversation/getMessages',
  async (getMessagesData: GetMessagesData, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { contactId } = getMessagesData;

    const response = await messagesService.fetchMessages(
      jwtToken,
      URL_MESSAGES,
      getMessagesData,
      '0'
    );

    await dbWorker.postMessage({
      action: 'updateMessages',
      data: { messages: response, contactId },
    });

    return response;
  }
);

export const openConversationOnFloatingWindow = createAsyncThunk(
  'conversation/openConversationOnFloatingWindow',
  async (_, { dispatch, getState }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const state = getState() as RootState;

    const data: OpenConversationOnFloatingWindowData = {
      uid: state.User.user.uid,
      conversationId: state.Conversations.conversationSelected.id,
      chatId: '',
    };

    const response = await messagesService.openConversationOnFloatingWindow(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const searchMessage = createAsyncThunk(
  'conversation/searchMessage',
  async (data: SearchMessageData, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.searchMessage(
      jwtToken,
      URL_MESSAGES,
      data
    );

    return response;
  }
);

export const reactToMessage = createAsyncThunk(
  'conversation/reactToMessage',
  async (data: ReactionData, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.sendReaction(
      jwtToken,
      URL_MESSAGES,
      data
    );

    return response;
  }
);

export const getNextMessages = createAsyncThunk(
  'conversation/getNextMessages',
  async (data: NextMessages, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { conversationId, pos, contactId } = data;

    const response = await messagesService.fetchMessages(
      jwtToken,
      URL_MESSAGES,
      { conversationId, contactId },
      pos
    );

    return { data: response as IMessage[], contactId, conversationId };
  }
);

const convertDataUrlToBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

export const sendMessageThunk = createAsyncThunk(
  'conversation/sendMessageThunk',
  async (message: IMessage | FormattedFormMessage, { dispatch }) => {
    const { jwtToken, URL_MESSAGES, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    if (message && 'audio' in message && message.audio?.url) {
      const blob = convertDataUrlToBlob(message.audio.url);
      const formData = new FormData();
      const urlParams: UploadURLParams = {
        folderType: 'audios',
        module: 'messages',
      };

      formData.append('file', blob);

      const response = await messagesService.uploadAudio(
        jwtToken,
        URL_MEDIA,
        formData,
        '',
        '',
        urlParams
      );

      message.audio.url = response.url;
    }

    const response = await messagesService.sendMessages(
      jwtToken,
      URL_MESSAGES,
      message
    );

    return response;
  }
);

export const uploadMedia = createAsyncThunk(
  'conversation/uploadMedia',
  async (data: UploadMedia, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { name, file, id, URLParams } = data;
    const response = await messagesService.uploadMedia(
      jwtToken,
      URL_MEDIA,
      file,
      name,
      id,
      URLParams
    );

    return response;
  }
);

export const fetchVariables = createAsyncThunk<string, Variable>(
  'contacts/fetchVariables',
  async (data, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { variable, contactId, conversationId } = data;

    const response = await messagesService.fetchVariables(
      jwtToken,
      URL_MESSAGES,
      variable,
      contactId,
      conversationId
    );

    return response;
  }
);

export const fetchForwardMessage = createAsyncThunk(
  'conversation/fetchForwardMessage',
  async (data: ForwardMessage, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.fetchForwardMessage(
      jwtToken,
      URL_MESSAGES,
      data
    );

    return response;
  }
);

export const fetchAddComment = createAsyncThunk(
  'conversation/fetchAddComment',
  async (data: Comment, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.fetchAddComment(
      jwtToken,
      URL_MESSAGES,
      data
    );

    return response;
  }
);

export const fetchDeleteComment = createAsyncThunk(
  'conversation/fetchDeleteMessage',
  async (data: Comment, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.fetchDeleteComment(
      jwtToken,
      URL_MESSAGES,
      data
    );

    return response;
  }
);

export const fetchHighlightMessage = createAsyncThunk(
  'conversation/fetchHighlightMessage',
  async (data: HighlightMessage, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.fetchHighlightMessage(
      jwtToken,
      URL_MESSAGES,
      data
    );

    return response;
  }
);

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setIsReplyingEmail(state, action: PayloadAction<boolean>) {
      state.isReplyingEmail = action.payload;
    },
    setEmailMessageStatus(state, action: PayloadAction<EmailMessageStatus>) {
      state.status.emailMessage = action.payload;
    },
    setMessagesOnError(state, action: PayloadAction<string>) {
      state.messagesOnError.push(action.payload);
    },
    updateMessage(state, action: PayloadAction<IMessage>) {
      const message = action.payload;
      const messages = state.messages;
      if (messages) {
        const index = messages.findIndex((message) => message.id === message.id);
        if (index !== -1) {
          messages[index] = message;
        }
      }
    },
    resetSearchMessages(state) {
      state.searchMessages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(getMessages.fulfilled, (state, action: PayloadAction<IMessage[]>) => {
        state.messages = action.payload;
        state.status.fetch = 'succeeded';
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.status.fetch = 'rejected';
        console.log('getMessages rejected: ', action.error.message);
      })
      .addCase(searchMessage.pending, (state) => {
        state.status.search = 'loading';
      })
      .addCase(
        searchMessage.fulfilled,
        (state, action: PayloadAction<IMessage[]>) => {
          state.searchMessages = action.payload;
          state.status.search = 'succeeded';
        }
      )
      .addCase(sendMessageThunk.pending, (state) => {
        state.status.send = 'loading';
      })
      .addCase(
        sendMessageThunk.fulfilled,
        (state, action: PayloadAction<IMessage>) => {
          const message = action.payload;
          state.status.send = 'succeeded';
          state.messagesOnError = state.messagesOnError.filter(
            (msg) => msg !== message.id
          );
          console.log('sendMessageThunk succeeded: ', message);
        }
      )
      .addCase(sendMessageThunk.rejected, (state, action) => {
        state.status.send = 'rejected';
        console.log('sendMessage rejected: ', action.meta.arg, action.error.message);
      })
      .addCase(uploadMedia.pending, (state) => {
        state.status.media = 'loading';
      })
      .addCase(uploadMedia.fulfilled, (state, action: PayloadAction<Media>) => {
        const media = action.payload;
        state.status.media = 'succeeded';
        state.media = media;
      })
      .addCase(uploadMedia.rejected, (state, action) => {
        state.status.media = 'rejected';
      })
      .addCase(getNextMessages.pending, (state) => {
        state.status.nextMessages = 'loading';
      })
      .addCase(getNextMessages.fulfilled, (state, action) => {
        const { contactId, conversationId, data } = action.payload;
        // const data = action.payload;
        state.status.nextMessages = 'succeeded';

        if (conversationId !== '') {
          state.messages = data;
        }

        // if (contactId !== '') {
        //   state.messages = [...state.messages!, ...data];
        // }
      })
      .addCase(getNextMessages.rejected, (state, action) => {
        state.status.nextMessages = 'rejected';
      })
      .addCase(fetchVariables.pending, (state) => {
        state.status.variable = 'loading';
      })
      .addCase(fetchVariables.fulfilled, (state, action) => {
        const variable = action.payload;
        state.status.variable = 'succeeded';
        state.variable = variable;
      })
      .addCase(fetchVariables.rejected, (state, action) => {
        state.status.variable = 'rejected';
      })
      .addCase(fetchForwardMessage.pending, (state) => {
        state.status.forwardMessage = 'loading';
      })
      .addCase(fetchForwardMessage.fulfilled, (state, action) => {
        state.status.forwardMessage = 'succeeded';
      })
      .addCase(fetchForwardMessage.rejected, (state, action) => {
        state.status.forwardMessage = 'rejected';
      })
      .addCase(fetchAddComment.pending, (state) => {
        state.status.addComment = 'loading';
      })
      .addCase(fetchAddComment.fulfilled, (state, action) => {
        state.status.addComment = 'succeeded';
        const newMessage = action.payload;
        updateMessage(newMessage);
      })
      .addCase(fetchAddComment.rejected, (state, action) => {
        state.status.addComment = 'rejected';
      })
      .addCase(fetchDeleteComment.pending, (state) => {
        state.status.deleteComment = 'loading';
      })
      .addCase(fetchDeleteComment.fulfilled, (state, action) => {
        state.status.deleteComment = 'succeeded';
        const newMessage = action.payload;
        const messages = state.messages;
        const filteredMessages = messages?.filter(
          (message) => message.id !== newMessage.id
        );
        state.messages = filteredMessages;
      })
      .addCase(fetchDeleteComment.rejected, (state, action) => {
        state.status.deleteComment = 'rejected';
      })
      .addCase(fetchHighlightMessage.pending, (state) => {
        state.status.highlighted = 'loading';
      })
      .addCase(
        fetchHighlightMessage.fulfilled,
        (state, action: PayloadAction<IMessage>) => {
          state.status.highlighted = 'succeeded';
          const messageUpdated = action.payload;
          updateMessage(messageUpdated);
        }
      )
      .addCase(fetchHighlightMessage.rejected, (state, action) => {
        state.status.highlighted = 'rejected';
      })
      .addCase(reactToMessage.fulfilled, (state, action) => {
        console.log('reactToMessage.fulfilled: ', action.payload);
      });
  },
});

// Actions
export const {
  setMessagesOnError,
  updateMessage,
  resetSearchMessages,
  setEmailMessageStatus,
  setIsReplyingEmail,
} = messagesSlice.actions;

const messagesState = (state: RootState) => state.Messages;

// Selectors
export const selectMessages = createSelector(
  messagesState,
  (state) => state.messages
);
export const selectIsReplyingEmail = createSelector(
  messagesState,
  (state) => state.isReplyingEmail
);
export const selectMessagesFetchStatus = createSelector(
  messagesState,
  (state) => state.status.fetch
);
export const selectSearchMessages = createSelector(
  messagesState,
  (state) => state.searchMessages
);
export const selectSearchMessagesStatus = createSelector(
  messagesState,
  (state) => state.status.search
);
export const selectUploadMediaStatus = createSelector(
  messagesState,
  (state) => state.status.media
);
export const selectUploadMedia = createSelector(
  messagesState,
  (state) => state.media
);
export const selectNextMessagesFetchStatus = createSelector(
  messagesState,
  (state) => state.status.nextMessages
);
export const selectVariableFetchStatus = createSelector(
  messagesState,
  (state) => state.status.variable
);
export const selectVariable = createSelector(
  messagesState,
  (state) => state.variable
);
export const selectSendMessageStatus = createSelector(
  messagesState,
  (state) => state.status.send
);
export const selectMessagesOnError = createSelector(
  messagesState,
  (state) => state.messagesOnError
);
export const selectForwardMessageStatus = createSelector(
  messagesState,
  (state) => state.status.forwardMessage
);
export const selectAddCommentStatus = createSelector(
  messagesState,
  (state) => state.status.addComment
);
export const selectDeleteCommentStatus = createSelector(
  messagesState,
  (state) => state.status.deleteComment
);
export const selectHighlightedStatus = createSelector(
  messagesState,
  (state) => state.status.highlighted
);
export const selectEmailMessageStatus = createSelector(
  messagesState,
  (state) => state.status.emailMessage
);

export default messagesSlice.reducer;
