import React, { useContext } from 'react';
// @ts-ignore
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'redux/types/RequestStatus';
// @ts-ignore
import { ScheduleContext } from 'features/Views/Schedules/context/types';
// Redux
import { useSelector } from 'react-redux';
// @ts-ignore
import { selectScheduleCreateStatus } from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeCreatePanel, sendSchedule, createScheduleNameField } =
    useContext<ScheduleContext>(InitScheduleContext);
  const createStatus: RequestStatus = useSelector(selectScheduleCreateStatus);
  const isLoading: boolean = createStatus === 'loading';
  const isDisabled: boolean =
    createStatus === 'loading' || !createScheduleNameField.attributes.value;
  return (
    <Box
      display={"flex"}
      justifyContent="flex-end"
      alignItems={"center"}
      p={2}
      paddingRight={'3rem'}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          marginRight: "15px",
        }}
        onClick={closeCreatePanel}
      >
        {t("global.cancel")}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        // sx={{ backgroundColor: (theme) => theme.palette.success.main }}
        disabled={isDisabled}
        onClick={sendSchedule}
      >
        {t("global.create")}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
