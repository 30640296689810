import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchGroup, selectGroupStatus } from 'redux/features/groupSlice/groupSlice';
import {
  fetchSchedules,
  selectScheduleStatus,
} from 'redux/features/scheduleSlice/scheduleSlice';
import {
  fetchGoogleBusiness,
  selectGoogleBusinessName,
  selectGoogleBusinessUpdateStatus,
  selectGoogleBusinessFetchStatus,
  updateGoogleBusiness,
  selectGoogleBusiness,
  resetChannel,
} from 'redux/features/googleBusinessEditSlice/googleBusinessEditSlice';
// Components/ui
import { Body } from './components';
import { Divider, Box } from '@mui/material';
import { ViewContainer, ChannelEditHeader } from 'components';
import GoogleBusinessIcon from 'utils/icons/GoogleBusinessIcon';

const GoogleBusinessEditContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const [allFetched, setAllFetched] = useState<boolean>(false);

  const { id } = useParams();

  const groupFetchStatus = useSelector(selectGroupStatus);
  const scheduleFetchStatus = useSelector(selectScheduleStatus);
  const googleBusiness = useSelector(selectGoogleBusiness);
  const googleBusinessName = useSelector(selectGoogleBusinessName);
  const googleBusinessUpdateStatus = useSelector(selectGoogleBusinessUpdateStatus);
  const googleBusinessFetchStatus = useSelector(selectGoogleBusinessFetchStatus);

  useEffect(() => {
    dispatch(fetchGoogleBusiness(id));
    dispatch(fetchGroup());
    dispatch(fetchSchedules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    groupFetchStatus === 'succeeded' &&
      scheduleFetchStatus === 'succeeded' &&
      googleBusinessFetchStatus === 'succeeded' &&
      setAllFetched(true);
  }, [groupFetchStatus, scheduleFetchStatus, googleBusinessFetchStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetChannel());
    };
  }, []);

  return (
    <ViewContainer>
      <Box boxShadow={1}>
        <ChannelEditHeader
          channelName={googleBusinessName}
          channelUpdateStatus={googleBusinessUpdateStatus}
          updateChannel={updateGoogleBusiness}
          channelFetchStatus={googleBusinessFetchStatus}
        >
          <GoogleBusinessIcon size={20} />
        </ChannelEditHeader>
        <Divider />
        <Body data={googleBusiness} allFetched={allFetched} />
      </Box>
    </ViewContainer>
  );
};

export default GoogleBusinessEditContainer;
