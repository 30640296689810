import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Context
import { InitScheduleEditContext } from 'features/Views/SchedulesEdit/context/ScheduleEditContext';
// Components/ui
import { IconButton, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { IHoliday } from "@trii/types/dist/Common/Schedules";
//icons
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";

const DATE_FORMAT = "DD/MM/YYYY HH:mm";

const ScheduleTable = () => {
  const { t } = useTranslation();
  const {
    holidays,
    setHolidays
  } = useContext(InitScheduleEditContext);

  const handleDeleteHoliday = (index: number) => {
    const newHolidays = holidays.filter(
      (item: IHoliday, i: number) => i !== index
    );
    setHolidays(newHolidays);
  };
  return (
    <TableContainer sx={{ maxHeight: "8rem" }}>
      {holidays?.length ? (
        <Table aria-label="simple table" stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("global.name")}</TableCell>
              <TableCell align="center">{t("scheduleView.date")}</TableCell>
              <TableCell align="center">{t("global.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holidays.map((item: IHoliday, index: number) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{item.description}</TableCell>
                <TableCell align="center">{`${dayjs(item.datetimeStart).format(
                  DATE_FORMAT
                )} - ${dayjs(item.datetimeEnd).format(
                  DATE_FORMAT
                )}`}</TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => handleDeleteHoliday(index)}
                    sx={{ color: (theme) => theme.palette.error.main }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table aria-label="simple table" stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("global.name")}</TableCell>
              <TableCell align="center">{t("scheduleView.date")}</TableCell>
              <TableCell align="center">{t("global.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} sx={{ width: "100%" }}>
                <Typography
                  color="text.primary"
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  {t("scheduleView.noRegisteredHolidays")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ScheduleTable;
