import useField from 'hooks/useField';
// Types
import { UseCreateSms } from './types';
import { NewSms } from 'redux/features/smsSlice/types/NewSms';

const useCreateSms = (): UseCreateSms => {
  const nameField = useField('text');
  const descriptionField = useField('text');

  // Actions
  const resetAll = () => {
    nameField.actions.resetValue();
    descriptionField.actions.resetValue();
  };
  const getNewSms = (): NewSms => {
    return {
      name: nameField.attributes.value,
      description: descriptionField.attributes.value,
    };
  };
  //

  return {
    actions: {
      resetAll,
      getNewSms,
    },
    input: {
      nameField,
      descriptionField,
    },
  };
};

export default useCreateSms;
