import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import StepContainer from '../StepContainer';
import emailLoadingImage from './utils/emailLoadingImage.png';
import emailReadyImage from './utils/emailReadyImage.png';

interface Props {
  newEmail: string;
}

const FourthStep = ({ newEmail }: Props) => {
  const { t } = useTranslation();

  const Image = emailReadyImage;

  const Title = (
    <Typography variant="h6">
      {t('emailEditView.incomingEmailPanel.setUpModal.allDone')}
    </Typography>
  );
  const Subtitle = (
    <Typography variant="subtitle1">
      {
        <>
          <strong>{newEmail}</strong>
          {t('emailEditView.incomingEmailPanel.setUpModal.isYourNewMailbox')}
          <strong>
            {t('emailEditView.incomingEmailPanel.setUpModal.emailSettings')}
          </strong>
        </>
      }
    </Typography>
  );

  return (
    <StepContainer>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100%'}
        gap={4}
      >
        <img width={140} src={Image} alt="email loading" />
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          gap={1}
          textAlign={'center'}
        >
          {Title}
          {Subtitle}
        </Box>
      </Box>
    </StepContainer>
  );
};

export default FourthStep;
