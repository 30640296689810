import React, { useContext, useEffect } from 'react';
import { facebookContext } from 'features/Views/Facebook/context/FacebookProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, InputLabel, TextField, Divider, Slide } from '@mui/material';
import { PanelHeader } from 'components';
import { DeleteButtons } from './components';

function DeletePanel() {
  const { t } = useTranslation();
  const {
    deleteFacebookName,
    endDeleting,
    selectedFacebook,
    deletePanelState,
  } = useContext(facebookContext);

  useEffect(() => {
    const cleanUp = () => {
      deleteFacebookName.actions.resetValue();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Slide
      direction="left"
      in={deletePanelState}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          width: '25%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '25%',
          },
          marginLeft: '20px',
          // marginTop: '5px',
          height: 'calc(100vh - 60px)',
          position: 'relative',
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.dropdownBox,
        }}
        boxShadow={1}
      >
        <PanelHeader
          title={t('facebooksView.deletionPanel.title')}
          handleCloseButton={endDeleting}
        />
        <Box
          display={'flex'}
          flexDirection="column"
          justifyContent="space-between"
          alignItems={'center'}
          p={2}
        >
          <InputLabel
            sx={{
              whiteSpace: 'break-spaces',
            }}
          >
            {t('facebooksView.deletionPanel.description')}
            <strong style={{ marginLeft: 3 }}>{selectedFacebook?.name}</strong>
          </InputLabel>
          <TextField
            {...deleteFacebookName.attributes}
            className="fadein"
            variant="outlined"
            size="small"
            fullWidth
            label={t('global.name')}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              marginTop: '15px',
            }}
          />
        </Box>
        <Box position={'absolute'} bottom={'65px'} width="100%">
          <Divider />
        </Box>
        <DeleteButtons />
      </Box>
    </Slide>
  );
}

export default DeletePanel;
