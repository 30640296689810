// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Chip, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
// Types
import { MessageEmail } from '@trii/types/dist/Common/Messages';
// Components
import { EmailDetails } from './components';
import { useState } from 'react';
import useSAS from 'hooks/useSAS';

interface EmailProps {
  email: MessageEmail;
  date: string;
}

const Email = ({ email, date }: EmailProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const URLHandler = useSAS();

  const handleDownload = async (url: string) => {
    const link = document.createElement('a');
    const fileName = url.split('/').pop();
    const accessURL = await URLHandler.getURLWithAccessToken(url);

    link.download = fileName || '';
    link.href = accessURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {t('conversations.channel.email.from')}
        </Typography>
        <Chip
          size="small"
          label={email?.from}
          sx={{
            fontWeight: 'bold',
            backgroundColor: 'unset',
          }}
        />
      </Box>
      <Box display="flex" gap={1} alignItems="flex-start">
        <Typography variant="body2" color="textSecondary">
          {t('conversations.channel.email.to')}
        </Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {email?.to?.map((to) =>
            to ? (
              <Chip
                key={to.id}
                size="small"
                label={to.address}
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: 'unset',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: 'auto',
                }}
              />
            ) : null
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {t('conversations.channel.email.subject')}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="bold"
            noWrap
            sx={{
              maxWidth: 200,
              color: theme.palette.text.primary,
            }}
          >
            {email?.subject}
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Tooltip title={t('conversations.channel.email.fullPage')}>
            <IconButton size="small" onClick={toggleModal}>
              <UnfoldMore
                fontSize="small"
                sx={{
                  transform: 'rotate(45deg)',
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {email?.attachments && email?.attachments.length > 0 && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2" color="textSecondary">
            {t('conversations.channel.email.attachments')}
          </Typography>
          <Box display="flex" gap={1} flexWrap={'wrap'}>
            {email?.attachments.map((attachment) => (
              <Chip
                key={attachment.id}
                size="small"
                label={attachment.filename}
                onClick={() => handleDownload(attachment.url)}
                sx={{
                  width: 'max-content',
                }}
              />
            ))}
          </Box>
        </Box>
      )}
      <EmailDetails email={email} open={open} onClose={toggleModal} date={date} />
    </Box>
  );
};

export default Email;
