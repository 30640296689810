import { createContext } from "react";
import { QuickResponseState } from "./types/QuickResponseState";

export const QuickResponseContext = createContext<QuickResponseState>({
  // General State
  editionType: null,
  setEditionType: () => { },
  // Quick Response State
  quickResponseList: [],
  setQuickResponseList: () => { },
  quickResponse: null,
  setQuickResponse: () => { },
  quickResponseSelected: null,
  setQuickResponseSelected: () => { },
  // Category State
  categoryList: [],
  setCategoryList: () => { },
  category: null,
  setCategory: () => { },
  categorySelected: null,
  setCategorySelected: () => { },
  // Groups State
  groupList: [],
  setGroupList: () => { },
  // Quick Response Actions
  getQuickResponses: () => { },
  getQuickResponseById: () => { },
  setNewQuickResponse: () => { return new Promise(() => { }) },
  updateQuickResponse: () => { },
  deleteQuickResponse: () => { },
  // Category Actions
  getCategories: () => { },
  getCategoryById: () => { },
  setNewCategory: () => { return new Promise(() => { }) },
  updateCategory: () => { },
  deleteCategory: () => { },
  // Group Actions
  getGroups: () => { },
});