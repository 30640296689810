import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  changeCloudApiNameSpace,
  changeCloudApiSystemUserAccessToken,
  changeCloudApiAppToken,
  changeCloudApiWhatsAppBusinessAccountId,
  changeCloudApiMetaDeveloperAppId,
  changeCloudApiWhatsAppPhoneNumberId,
  syncWhatsAppCloudApiTemplates,
  selectSyncWhatsAppCloudApiTemplatesStatus,
  selectWhatsApp,
} from 'redux/features/whatsAppEditSlice/whatsAppEditSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import { Divider, TextField, Typography } from '@mui/material';
import { inputFieldStyle } from '../../../../styles';
import { ChannelInputsContainer } from 'components';
import { LoadingButton } from '@mui/lab';
interface Props {
  data: {
    cloudApiAppToken: string;
    cloudApiMetaDeveloperAppId: string;
    cloudApiNameSpace: string;
    cloudApiSystemUserAccessToken: string;
    cloudApiWhatsAppBusinessAccountId: string;
    cloudApiWhatsAppPhoneNumberId: string;
  };
}

const WhatsAppCloudAPIInputs = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const whatsApp = useSelector(selectWhatsApp);
  const syncWhatsAppCloudApiTemplatesStatus = useSelector(
    selectSyncWhatsAppCloudApiTemplatesStatus
  );

  const isLoading = syncWhatsAppCloudApiTemplatesStatus === 'loading';

  const {
    cloudApiAppToken,
    cloudApiMetaDeveloperAppId,
    cloudApiNameSpace,
    cloudApiSystemUserAccessToken,
    cloudApiWhatsAppBusinessAccountId,
    cloudApiWhatsAppPhoneNumberId,
  } = data;

  const handleCloudApiAppTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeCloudApiAppToken(event.target.value));
  };

  const handleCloudApiMetaDeveloperAppIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeCloudApiMetaDeveloperAppId(event.target.value));
  };

  const handleCloudApiNameSpaceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeCloudApiNameSpace(event.target.value));
  };

  const handleCloudApiSystemUserAccessTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeCloudApiSystemUserAccessToken(event.target.value));
  };

  const handleCloudApiWhatsAppBusinessAccountIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeCloudApiWhatsAppBusinessAccountId(event.target.value));
  };

  const handleCloudApiWhatsAppPhoneNumberIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(changeCloudApiWhatsAppPhoneNumberId(event.target.value));
  };
  async function handleSync() {
    await dispatch(syncWhatsAppCloudApiTemplates(whatsApp.id));
  }

  return (
    <ChannelInputsContainer>
      <TextField
        size="small"
        label="App Token"
        InputLabelProps={{ shrink: true }}
        value={cloudApiAppToken}
        sx={inputFieldStyle}
        onChange={handleCloudApiAppTokenChange}
      />
      <TextField
        size="small"
        label="Meta Developer App Id"
        InputLabelProps={{ shrink: true }}
        value={cloudApiMetaDeveloperAppId}
        sx={inputFieldStyle}
        onChange={handleCloudApiMetaDeveloperAppIdChange}
      />
      <TextField
        size="small"
        label="Name Space"
        InputLabelProps={{ shrink: true }}
        value={cloudApiNameSpace}
        sx={inputFieldStyle}
        onChange={handleCloudApiNameSpaceChange}
      />
      <TextField
        size="small"
        label="System User Access Token"
        InputLabelProps={{ shrink: true }}
        value={cloudApiSystemUserAccessToken}
        sx={inputFieldStyle}
        onChange={handleCloudApiSystemUserAccessTokenChange}
      />
      <TextField
        size="small"
        label="WhatsApp Business Account Id"
        InputLabelProps={{ shrink: true }}
        value={cloudApiWhatsAppBusinessAccountId}
        sx={inputFieldStyle}
        onChange={handleCloudApiWhatsAppBusinessAccountIdChange}
      />
      <TextField
        size="small"
        label="WhatsApp Phone Number Id"
        InputLabelProps={{ shrink: true }}
        value={cloudApiWhatsAppPhoneNumberId}
        sx={inputFieldStyle}
        onChange={handleCloudApiWhatsAppPhoneNumberIdChange}
      />
      <Divider />
      {typeof syncWhatsAppCloudApiTemplatesStatus === 'object' && (
        <Typography variant="body2" color="error">
          {'Error sync'}
        </Typography>
      )}
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        onClick={handleSync}
        size="small"
        variant="contained"
      >
        {t('templates.syncTemplates')}
      </LoadingButton>
    </ChannelInputsContainer>
  );
};

export default WhatsAppCloudAPIInputs;
