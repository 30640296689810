// Components/ui
import { Box } from '@mui/material';
import { DkimSection, SpfSection } from './components';
import {  EmailDomain } from '@trii/types/dist/Common/Channels';

interface Props {
  outboundTrii: EmailDomain;
}

const TriiInputs = ({ outboundTrii }: Props) => {
  if (!outboundTrii) {
    return null;
  }
  return (
    <Box>
      <DkimSection statusDKIM={outboundTrii.outboundTriiDkimStatus} dataDKIM={outboundTrii.outboundTriiDkimRecords} />
      <SpfSection statusDMARC={outboundTrii.outboundTriiDmarcStatus} dataDMARC={outboundTrii.outboundTriiDmarcRecords} />
    </Box>
  );
};

export default TriiInputs;
